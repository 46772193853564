import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BootstrapThemeProp, BootstrapThemeService, BootstrapThemeType } from '@app/core/services/bootstrap-theme.service';

@Component({
    selector: 'modal-hoc',
    templateUrl: './modal-hoc.component.html',
    styleUrls: ['./modal-hoc.component.scss']
})
export class ModalHocComponent implements OnInit {
    @Input() public modalTheme: BootstrapThemeProp = 'primary';
    themeType: BootstrapThemeType;
    @Input() public modalTitle: string = 'Modal Title';

    @Input() public submitButton: boolean = true;
    @Input() public secondaryButton: boolean = false;
    @Input() public cancelButton: boolean = true;

    @Input() public submitText: string = 'Submit';
    @Input() public secondaryText: string = 'Share / Copy';
    @Input() public cancelText: string = 'Cancel';

    @Input() public secondaryButtonClasses: string[] = ['modal-secondary', 'me-auto', 'btn-link']

    @Input() public submitDisabled: boolean = false;
    @Input() public secondaryDisabled: boolean = false;
    @Input() public cancelDisabled: boolean = false;
    @Input() public closeDisabled: boolean = false;

    @Input() public isBeta: boolean = false;

    @Output() public readonly modalSubmit: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
    @Output() public readonly modalSecondary: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

    /**
     * The modal has been canceled by clicking either the X button in the header or the cancel button in the footer
     */
    @Output() public readonly modalCancel: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
    /**
     * The modal has been canceled by clicking the X button in the header
     */
    @Output() public readonly modalCancelByX: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
    /**
     * The modal has been canceled by clicking the cancel button in the footer
     */
    @Output() public readonly modalCancelByButton: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

    constructor(
        private readonly bootstrapThemeService: BootstrapThemeService,
    ) {}

    ngOnInit(): void {
        this.themeType = this.bootstrapThemeService.getThemeType(this.modalTheme);
    }

    public get modalHeaderClasses(): string {
        return `fdx-header-bg-${this.modalTheme}`;
    }

    public get hasFooter(): boolean {
        return this.submitButton || this.secondaryButton || this.cancelButton;
    }

    public get submitButtonClass(): string {
        return `btn-${this.modalTheme}`;
    }

    public onModalSubmit(e: MouseEvent): void {
        this.modalSubmit.emit(e);
    }

    public onModalSecondary(e: MouseEvent): void {
        this.modalSecondary.emit(e);
    }

    public onModalXClick(e: MouseEvent): void {
        this.modalCancelByX.emit(e);
        this.modalCancel.emit(e);
    }

    public onModalCancel(e: MouseEvent): void {
        this.modalCancelByButton.emit(e);
        this.modalCancel.emit(e);
    }
}
