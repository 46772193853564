import { Injectable } from '@angular/core';
import { AggregatedDbFieldsType } from '@app/modules/inputs/types/aggregated-db-fields.type';

export type AggregateDbFieldsParameter = {
    displayName: string
}

@Injectable({
    providedIn: 'root'
})
export class DatabasesUtilitiesService {
    reduceToAggregate(allDbFields: AggregateDbFieldsParameter[]): AggregatedDbFieldsType {
        return allDbFields.reduce((allFields, field) => {
            allFields.autocompleteFields.push(`[${field.displayName}]`);
            allFields.validFields.push(field.displayName);

            return allFields;
        }, { autocompleteFields: [], validFields: [] });
    }
}
