import FdxUI from '@ajs/services/fdxUI';
import { Component, OnInit } from '@angular/core';
import { AppMenuTab } from '@app/core/models/enums/app-menu-tab.enum';
import { KeyboardShortcutConfigurationItem } from '@app/keyboard-shortcuts/interfaces/keyboard-shortcut-configuration-item';
import { KeyboardShortcutService } from '@app/keyboard-shortcuts/services/keyboard-shortcut.service';

@Component({
    selector: 'fdx-keyboard-shortcuts-page',
    templateUrl: './keyboard-shortcuts-page.component.html',
    styleUrls: ['./keyboard-shortcuts-page.component.scss']
})
export class KeyboardShortcutsPageComponent implements OnInit {

    appMenuTab: AppMenuTab = AppMenuTab.None;
    title: string = 'Keyboard Shortcuts';

    constructor(
        private readonly keyboardShortcutService: KeyboardShortcutService,
        private readonly fdxUI: FdxUI
    ) {}

    ngOnInit(): void {
        this.fdxUI.setTitle(this.title);
        this.fdxUI.setActiveTab(this.appMenuTab);
    }

    getShortcuts(): KeyboardShortcutConfigurationItem[] {
        return this.keyboardShortcutService.keyboardShortcuts;
    }

    trackByKeyboardShortcut(_index: number, configuration: KeyboardShortcutConfigurationItem): string {
        return configuration.combination;
    }
}
