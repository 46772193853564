<fdx-form-section>

    <div class="d-flex align-items-center mb-1">
        <h3 class="m-0">Schedule</h3>
        <span class="text-muted mx-1">|</span>
        <fdx-schedule-cron-viewer [cron]="cron" [cronTimezone]="cronTimezone" >
        </fdx-schedule-cron-viewer>
    </div>

    <form [formGroup]="form" #formRef="ngForm" >

        <fdx-alert *ngIf="scheduleUpdateError" theme="danger" [body]="scheduleUpdateError"></fdx-alert>

        <fdx-alert
            *ngIf="exportItem.hasFtpTrigger"
            theme="info"
            title="Heads up!">
            This export is scheduled by an <a [routerLink]="['/', databaseId, 'ftp-triggers']">FTP Trigger</a>.
        </fdx-alert>

        <div class="row">
            <label class="col-sm-4 col-form-label text-end">Day</label>
            <div class="col-sm-8">
                <ng-select [items]="exportItem?._schedules?.days"
                           [multiple]="true"
                           (change)="validate($event, 'day')"
                           bindLabel="display_name"
                           bindValue="value"
                           formControlName="day"
                           placeholder="Unscheduled">
                </ng-select>
            </div>
        </div>

        <div class="row mt-3">
            <label class="col-sm-4 col-form-label text-end">Time</label>
            <div class="col-sm-8">
                <div class="row">
                    <div class="col-6">
                        <ng-select [items]="exportItem?._schedules?.hours"
                                   [multiple]="true"
                                   (change)="validate($event, 'hour')"
                                   bindLabel="display_name"
                                   bindValue="value"
                                   formControlName="hour"
                                   placeholder="Unscheduled">
                        </ng-select>
                    </div>

                    <div class="col-6">
                        <ng-select [items]="exportItem?._schedules?.minutes"
                                   [multiple]="true"
                                   (change)="validate($event, 'minute')"
                                   bindLabel="display_name"
                                   bindValue="value"
                                   formControlName="minute"
                                   placeholder="Unscheduled">
                        </ng-select>
                    </div>
                </div>
            </div>
        </div>

        <fdx-form-field
            [classes]="{row:true,'mt-3':true}"
            formControlName="timezone"
            [form]="formRef">
            <fdx-label class="col-4 text-end">Time zone</fdx-label>
            <fdx-input-wrapper class="col-8">
                <ng-select
                    id="timezone"
                    formControlName="timezone"
                    placeholder="Select / search for a time zone"
                    [items]="timeZonesList"
                    bindValue="value"
                    bindLabel="label"
                    groupBy="group">
                    <ng-template ng-label-tmp let-item="item">
                        <span class="city-country-name">{{ item.city }}, {{item.country}}</span>
                        <span>&nbsp;</span>
                        <span class="timezone-info">({{item.abbreviation}}, UTC {{item.utcOffset}})</span>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                        <span class="city-country-name">{{ item.city }}, {{item.country}}</span>
                        <span>&nbsp;</span>
                        <span class="timezone-info" >({{item.abbreviation}}, UTC {{item.utcOffset}})</span>
                    </ng-template>
                </ng-select>
            </fdx-input-wrapper>
        </fdx-form-field>

        <div *ngIf="form.dirty" class="row mt-4">
            <div class="col-md-12 text-center">
                <button (click)="openUpdateScheduleModal()" class="btn btn-primary">Update Schedule</button>
            </div>
        </div>
    </form>
</fdx-form-section>
