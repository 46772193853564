<form
    [formGroup]="transformerForm"
    #form="ngForm"
    *ngIf="formReady; else notReady" >
    <div class="fdx-drop-list d-flex gap-3 align-items-start flex-wrap" cdkDropListGroup>
        <div *ngFor="let field of fieldArray.controls; let i = index; let last = last; trackBy: trackByValue"
            class="d-flex gap-3 align-items-start"
            cdkDropList
            [cdkDropListData]="i"
            cdkDropListOrientation="horizontal" >
            <fdx-basic-transformer-field
                class="fdx-drop-list-handle bg-white fdx-expand-wrapper"
                cdkDrag
                [cdkDragData]="i"
                (cdkDragEntered)="entered($event)"
                (cdkDragStarted)="dragging = true"
                (cdkDragEnded)="dragging = false"
                [cdkDragPreviewContainer]="cdkDragPreviewContainer"
                [dragging]="isDragging"
                [dbFields]="dbFields"
                [fieldGroup]="controlAsFormGroup(field)"
                (removeFieldClick)="removeFieldClick(i)"
                [disabled]="disabled" >
            </fdx-basic-transformer-field>
            <div *ngIf="!last; else isLast" class="my-2"><fa-icon [icon]="iconPlus"></fa-icon></div>
            <ng-template #isLast>
                <button
                    type="button"
                    class="btn btn-outline-primary"
                    (click)="addNewField()"
                    [disabled]="disabled" >
                    <fa-icon [icon]="iconPlus" class="no-margin"></fa-icon>
                </button>
            </ng-template>
        </div>
    </div>
</form>
<ng-template #notReady>
    <fdx-spinner></fdx-spinner>
</ng-template>
