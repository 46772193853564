import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PaginationBarComponent } from '@app/modules/pagination/components/pagination-bar/pagination-bar.component';
import { PaginationComponent } from '@app/modules/pagination/components/pagination/pagination.component';
import { TablePagerComponent } from '@app/modules/pagination/components/table-pager/table-pager.component';
import { NgSelectModule } from '@feedonomics/ng-select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [
        PaginationBarComponent,
        PaginationComponent,
        TablePagerComponent
    ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        FormsModule,
        NgbPaginationModule,
        NgSelectModule
    ],
    exports: [
        PaginationBarComponent,
        PaginationComponent,
        TablePagerComponent
    ]
})
export class PaginationModule { }
