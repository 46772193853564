import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_BASE_URL } from '@app/core/models/constants/app-constants';
import { FdxApiResponse } from '@app/core/responses/fdx-api.response';
import { DataGovernanceQueryDataModel, DataGovernanceQueryPostModel } from '@app/data-governance/models/data-governance-query.model';
import { ExportSettingsGetResponseInterface } from '@app/exports/models/interfaces/export-settings-get-response.interface';
import { ExportSettingsUpdateRequestInterface } from '@app/exports/models/interfaces/export-settings-update-request.interface';
import { ExportSettingsUpdateResponseInterface } from '@app/exports/models/interfaces/export-settings-update-response.interface';
import { ExportUpdateScheduleRequestInterface } from '@app/exports/models/interfaces/export-update-schedule-request.interface';
import { Observable } from 'rxjs';

// Requests
import { DownloadSampleRequest } from '@app/exports/services/requests/download-sample.request';
import { GetExportsRequest } from '@app/exports/services/requests/get-exports.request';
import { RunParallelExportRequest } from '@app/exports/services/requests/run-parallel-export.request';

// Responses
import { ExportModel } from '@app/exports/models/export.model';
import { DownloadSampleResponse } from '@app/exports/services/responses/download-sample.response';
import { GetExportsResponse } from '@app/exports/services/responses/get-exports.response';
import { RunParallelExportResponse } from '@app/exports/services/responses/run-parallel-export.response';

@Injectable({
    providedIn: 'root'
})
export class ExportsDataService {

    private readonly baseUrl: string = `${API_BASE_URL}/dbs`;

    constructor(
        private readonly http: HttpClient
    ) { }

    getExports(databaseId: string, requestParams: GetExportsRequest = {}): Observable<GetExportsResponse> {
        let params = new HttpParams();
        if (requestParams.destination_metadata) {
            params = params.append('destination_metadata', requestParams.destination_metadata);
        }
        return this.http.get<GetExportsResponse>(`/api.php/dbs/${databaseId}/exports`, {params: params});
    }

    downloadSample(databaseId: string, exportId: number, params: DownloadSampleRequest): Observable<DownloadSampleResponse> {
        return this.http.post<any>(`/api.php/dbs/${databaseId}/exports/${exportId}/download_sample`, params);
    }

    addDataGovernanceQuery(databaseId: string, exportId: string, dataGovernanceQuery: DataGovernanceQueryPostModel): Observable<DataGovernanceQueryDataModel> {
        return this.http.post<DataGovernanceQueryDataModel>(`${this.baseUrl}/${databaseId}/exports/${exportId}/data_governance_queries`, dataGovernanceQuery);
    }

    deleteDataGovernanceQuery(databaseId: string, exportId: string, dataGovernanceQueryId: string): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/${databaseId}/exports/${exportId}/data_governance_queries/${dataGovernanceQueryId}`);
    }

    getDataGovernanceQueries(databaseId: string, exportId: string): Observable<DataGovernanceQueryDataModel[]> {
        return this.http.get<DataGovernanceQueryDataModel[]>(`${this.baseUrl}/${databaseId}/exports/${exportId}/data_governance_queries`)
    }

    updateDataGovernanceQuery(databaseId: string, exportId: string, dataGovernanceQuery: DataGovernanceQueryDataModel): Observable<DataGovernanceQueryDataModel> {
        return this.http.put<DataGovernanceQueryDataModel>(`${this.baseUrl}/${databaseId}/exports/${exportId}/data_governance_queries/${dataGovernanceQuery.id}`, dataGovernanceQuery)
    }

    getDesignatedHosts(databaseId: string, url: string): Observable<FdxApiResponse<string[]>> {
        return this.http.post<FdxApiResponse<string[]>>(`/api.php/dbs/${databaseId}/exports/designated_hosts`, {
            url
        });
    }

    updateSchedule(databaseId: string, exportId: string, params: ExportUpdateScheduleRequestInterface): Observable<{ cron: string; }> {
        return this.http.put<{ cron: string; }>(`/api.php/dbs/${databaseId}/exports/${exportId}/schedule`, params);
    }

    addExport(databaseId: string, newExport: any): Observable<ExportModel> {
        return this.http.post<ExportModel>(`/api.php/dbs/${databaseId}/exports`, newExport);
    }

    runParallelExport(databaseId: string, exportId: string, params: RunParallelExportRequest): Observable<RunParallelExportResponse> {
        return this.http.post<RunParallelExportResponse>(`/api.php/dbs/${databaseId}/exports/${exportId}/run_parallel_export`, params );
    }

    deleteExport(databaseId: string, exportId: string): Observable<void> {
        return this.http.delete<void>(`/api.php/dbs/${databaseId}/exports/${exportId}`);
    }

    resetExport(databaseId: string, exportId: string): Observable<{ status: string; }> {
        return this.http.put<{ status: string; }>(`/api.php/dbs/${databaseId}/exports/${exportId}/reset_running`, {});
    }

    updateExport(databaseId: string, exportId: string, newExport: any): Observable<ExportModel> {
        return this.http.put<ExportModel>(`/api.php/dbs/${databaseId}/exports/${exportId}`, newExport);
    }

    getExportSettings(databaseId: string, exportId: string): Observable<ExportSettingsGetResponseInterface> {
        return this.http.get<ExportSettingsGetResponseInterface>(`${this.baseUrl}/${databaseId}/exports/${exportId}/export_settings`);
    }

    updateExportSettings(databaseId: string, exportId: string, payload: ExportSettingsUpdateRequestInterface): Observable<unknown> {
        return this.http.put<ExportSettingsUpdateResponseInterface>(`${this.baseUrl}/${databaseId}/exports/${exportId}/export_settings`, payload);
    }

}
