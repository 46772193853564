import AppStateService from '@ajs/services/AppStateService';
import fdxUI from '@ajs/services/fdxUI';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { AppMenuTab } from '@app/core/models/enums/app-menu-tab.enum';
import { BasePageComponent } from '@app/modules/page/abstract/base-page.component';
import { BulkTransformersService } from '@app/transformers/services/bulk-transformers.service';
import { finalize, takeUntil } from 'rxjs';

type Delimiter = ',' | '\t' | ';' | '|' | '~';
type Enclosure = '"';
type Escape = '"' | '\\' | '\n';

interface CsvBulkImport {
    file: File;
    options: {
        delimiter: Delimiter;
        enclosure: Enclosure;
        escape: Escape;
    }
}

interface CsvBulkImportDropdownLists {
    enclosures: CsvBulkImportDropdown<Enclosure>[];
    escapes: CsvBulkImportDropdown<Escape>[];
    delimiters: CsvBulkImportDropdown<Delimiter>[];
}

interface CsvBulkImportDropdown<T> {
    display: string;
    value: T;
}

@Component({
    selector: 'fdx-transformers-bulk-import-page',
    styleUrls: ['./transformers-bulk-import-page.component.scss'],
    templateUrl: './transformers-bulk-import-page.component.html'
})
export class TransformersBulkImportPageComponent extends BasePageComponent {
    appMenuTab = AppMenuTab.Transformers;
    error = '';
    csv: CsvBulkImport = {
        file: null,
        options: {
            delimiter: ',',
            enclosure: '"',
            escape: '"'
        }
    };
    dropdowns: CsvBulkImportDropdownLists = {
        enclosures: [
            {
                display: '"',
                value: '"'
            }
        ],
        escapes: [
            {
                display: '"',
                value: '"'
            },
            {
                display: '\\',
                value: '\\'
            },
            {
                display: 'No',
                value: '\n'
            }
        ],
        delimiters: [
            {
                display: ',',
                value: ','
            },
            {
                display: 'tab',
                value: '\t'
            },
            {
                display: ';',
                value: ';'
            },
            {
                display: '|',
                value: '|'
            },
            {
                display: '~',
                value: '~'
            }
        ]
    };
    raw = {
        placeholder: `google_product_category\t[title] contains 'dress'\t'Dress'\t1\ngoogle_product_category\t[title] contains 'gown'\t'Gown'\t1\ngoogle_product_category\t[title] contains 'shirt'\t'T-Shirt'\t1\n`,
        value: ''
    };
    title = 'Transformers Bulk Import';

    @ViewChild('fileInput') fileInput: ElementRef;


    get databaseId(): string {
        return this.appStateService.getDatabaseId();
    }

    constructor(
        private appStateService: AppStateService,
        private bulkTransformersService: BulkTransformersService,
        fdxUI: fdxUI
    ) {
        super(fdxUI);
    }

    onFileSelected(event: Event): void {
        const file: File = (event.target as HTMLInputElement).files[0];

        if (file) {
            this.csv.file = file;
        }
    }

    submit(): void {
        this.clearError();

        this.fdxUI.startBlockUI();
        this.fdxUI.showToastInfo('Saving transformers');

        if (this.csv.file !== null) {
            this.bulkTransformersService.addCsvBulkTransformer(this.databaseId, this.csv.file, this.csv.options)
                .pipe(
                    finalize(() => {
                        this.fdxUI.stopBlockUI();
                    }),
                    takeUntil(this.unsubscribe$)
                )
                .subscribe(
                    () => {
                        this.fdxUI.showToastSuccess('Transformers added!');
                        this.clearCsvFile();
                    },
                    this.handleHttpError.bind(this)
                );
        } else {
            this.bulkTransformersService.addRawBulkTransformer(this.databaseId, this.raw.value)
                .pipe(
                    finalize(() => {
                        this.fdxUI.stopBlockUI();
                    }),
                    takeUntil(this.unsubscribe$)
                )
                .subscribe(
                    () => {
                        this.fdxUI.showToastSuccess('Transformers added!');
                    },
                    this.handleHttpError.bind(this)
                );
        }
    }

    private clearError(): void {
        this.error = '';
    }

    private clearCsvFile(): void {
        this.csv.file = null;
        this.fileInput.nativeElement.value = '';
    }

    private handleHttpError(error: HttpErrorResponse): void {
        this.fdxUI.showToastError('Failed to add transformers');
        this.error = error.error;
    }
}
