/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ElementRef, OnInit } from '@angular/core';
import { LogService } from '@app/core/services/log.service';
import { BaseInputComponent } from '@app/modules/inputs/components/base-input/base-input.component';
import { InputsUtilitiesService } from '@app/modules/inputs/services/inputs-utilities.service';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'fdx-input-checkbox',
    providers: [{
        provide: BaseInputComponent,
        useExisting: InputCheckboxComponent
    }],
    templateUrl: './input-checkbox.component.html',
    styleUrls: ['./input-checkbox.component.scss']
})
export class InputCheckboxComponent extends BaseInputComponent implements OnInit {

    constructor(
        protected readonly element: ElementRef<HTMLElement>,
        protected readonly inputsUtilitiesService: InputsUtilitiesService,
        protected readonly logService: LogService,
        protected readonly ngbDateParserFormatter: NgbDateParserFormatter
    ) {
        super(element, inputsUtilitiesService, logService, ngbDateParserFormatter);
        this.inputType = 'checkbox';
    }

    ngOnInit(): void {
        if (this.classes['btn-check']) {
            this.initClasses['form-check-input'] = false;
        } else if (this.classes['form-check-input'] === undefined) {
            this.initClasses['form-check-input'] = true;
        }
    }

    onChange($event: string): void {
        super.onChange($event);
        this.onTouched();   // Automatically trigger touch when changed
    }
}
