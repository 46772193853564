export default class StoresDataService {
  static $inject = ['$http', '$q'];

  constructor(private $http, private $q) {
  }

  retrieveStores(accountId: number, effectiveUserId: number){

      const def = this.$q.defer();

      let requestUrl = '';
      let requestParams = {};

      if(effectiveUserId>0) {
        requestUrl = `/api.php/accounts/${accountId}/analyst_db_groups`;
        requestParams['effective_user_id']= effectiveUserId;
      }
      else{
        requestUrl = `/api.php/accounts/${accountId}/billing_db_groups`;
      }

      const promise = this.$http.get(requestUrl, {params:requestParams});

      promise.then(
          (response) => {
              def.resolve(response.data);
          },
          (response) => {
              def.reject(response.data);
          }
      );

      return def.promise;

  }

}