<div class="container">
    <ng-container *ngIf="isAuthorized else unauthorized">
        <fdx-page-title>Choose Report Date Range</fdx-page-title>

        <form [formGroup]="reportsSkuForm" #form="ngForm" (ngSubmit)="joinPerformanceReport()">
            <fdx-well>
                <fdx-alert *ngIf="error" theme="danger" [body]="error"></fdx-alert>

                <fdx-form-field
                    formControlName="channel"
                    [classes]="{'row': true}"
                    [form]="form" >
                    <fdx-label class="col-2 text-end">Channel</fdx-label>
                    <fdx-input-wrapper
                        class="col-10">
                        <ng-select
                            formControlName="channel"
                            id="channel"
                            [clearable]="false" >
                            <ng-option value="google_shopping">Google Shopping</ng-option>
                        </ng-select>
                    </fdx-input-wrapper>
                </fdx-form-field>

                <fdx-form-field
                    formControlName="idFieldName"
                    [classes]="{'row': true, 'mt-3': true}"
                    [form]="form" >
                    <fdx-label class="col-2 text-end">ID Field Name</fdx-label>
                    <fdx-input-wrapper
                        class="col-10">
                        <ng-select
                            formControlName="idFieldName"
                            id="idFieldName"
                            [items]="dbFields"
                            bindValue="field_name"
                            bindLabel="field_name"
                            placeholder="Select / Search for a Field" >
                        </ng-select>
                    </fdx-input-wrapper>
                </fdx-form-field>

                <fdx-form-field
                    formControlName="dateType"
                    [classes]="{'row': true, 'mt-3': true}"
                    [form]="form" >
                    <fdx-label class="col-2 text-end">Date Type</fdx-label>
                    <fdx-input-wrapper
                        class="col-10">
                        <ng-select
                            formControlName="dateType"
                            id="dateType"
                            [clearable]="false" >
                            <ng-option value="static">Static</ng-option>
                            <ng-option value="dynamic">Dynamic</ng-option>
                        </ng-select>
                    </fdx-input-wrapper>
                </fdx-form-field>

                <div class="row mt-3" *ngIf="isShowingStatic()">
                    <fdx-form-field
                        formControlName="staticDateRangeStart"
                        [form]="form"
                        class="col-6"
                        [classes]="{'row': true}" >
                        <fdx-label class="col-4 text-end">Date Range Start</fdx-label>
                        <fdx-datepicker
                            class="col-8"
                            placeholder="yyyy-mm-dd" >
                        </fdx-datepicker>
                    </fdx-form-field>
                    <fdx-form-field
                        formControlName="staticDateRangeEnd"
                        [form]="form"
                        class="col-6"
                        [classes]="{'row': true}" >
                        <fdx-label class="col-4 text-end">Date Range End</fdx-label>
                        <fdx-datepicker
                            class="col-8"
                            placeholder="yyyy-mm-dd" >
                        </fdx-datepicker>
                    </fdx-form-field>
                </div>

                <fdx-form-field *ngIf="isShowingDynamic()"
                    formControlName="dynamicDate"
                    [classes]="{'row': true, 'mt-3': true}"
                    [form]="form" >
                    <fdx-label class="col-2 text-end">Date Range</fdx-label>
                    <fdx-input-wrapper
                        class="col-10">
                        <ng-select
                            formControlName="dynamicDate"
                            id="dynamicDate"
                            [items]="dynamicDateRanges"
                            bindValue="value"
                            bindLabel="label"
                            placeholder="Search / Select a Date Range" >
                        </ng-select>
                    </fdx-input-wrapper>
                </fdx-form-field>

                <div class="row mt-3" *ngIf="isShowingDynamicCustom()">
                    <fdx-form-field
                        formControlName="customDateRangeStart"
                        [form]="form"
                        class="col-6"
                        [classes]="{'row': true}" >
                        <fdx-label class="col-4 text-end">Custom Date Start</fdx-label>
                        <fdx-input
                            class="col-8"
                            aria-label="Custom Date Range Start"
                            placeholder="-31 day" >
                        </fdx-input>
                    </fdx-form-field>
                    <fdx-form-field
                        formControlName="customDateRangeEnd"
                        [form]="form"
                        class="col-6"
                        [classes]="{'row': true}" >
                        <fdx-label class="col-4 text-end">Custom Date End</fdx-label>
                        <fdx-input
                            class="col-8"
                            aria-label="Custom Date Range End"
                            placeholder="-1 day" >
                        </fdx-input>
                    </fdx-form-field>
                </div>
                <fdx-well-footer>
                    <button type="submit" [disabled]="!form.valid"class="btn btn-primary">Join Report To Data</button>
                </fdx-well-footer>
            </fdx-well>
        </form>
    </ng-container>
    <ng-template #unauthorized>
        <fsc-empty-state
            class="fdx-center-empty-state"
            [imageType]="emptyStateImageType.Custom"
            [bodyTextWidth]="emptyStateBodyTextSize.Large"
            [showButtonIcon]="false"
            headerText="Authorize Google Ads account"
            bodyText="SKU reports allow you to pull performance data from channels like Google Shopping. Authorize Google Ads to get started."
            buttonText="Authorize"
            (buttonClick)="navigateToAuthorization()">
            <img src="/dist/app/assets/images/no_sku_report.svg" alt="No SKU report image"/>
        </fsc-empty-state>
    </ng-template>
</div>
