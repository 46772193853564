import { IHttpService, IQService, IPromise, IDeferred } from 'angular';

import { ReleaseNoteDataModel } from '../models/release_notes/ReleaseNoteDataModel';
import { LatestReleaseNoteDataModel } from '../models/release_notes/LatestReleaseNoteDataModel';

import FdxError from '../models/FdxError';

export default class ReleaseNotesDataService {
	static $inject = ['$http', '$q'];

	constructor(private $http: IHttpService, private $q: IQService) { }

	public getReleaseNotes(): IPromise<Array<ReleaseNoteDataModel>> {
		const def: IDeferred<Array<ReleaseNoteDataModel>> = this.$q.defer();

		const promise = this.$http.get<Array<ReleaseNoteDataModel>>('/api.php/release_notes');

		promise.then(
			(response) => {
				def.resolve(response.data);
			},
			(response) => {
				if (response.data === null) {
					def.reject(new FdxError('UNKNOWN_ERROR', 'Error getting release notes'));
					return;
				}

				def.reject(new FdxError(response.data.message, response.data.data.error));
			}
		);

		return def.promise;
	}

	public getLatestReleaseNoteDate(): IPromise<LatestReleaseNoteDataModel> {
		const def: IDeferred<LatestReleaseNoteDataModel> = this.$q.defer();

		const promise = this.$http.get<LatestReleaseNoteDataModel>('/api.php/release_notes/latest');

		promise.then(
			(response) => {
				def.resolve(response.data);
			},
			(response) => {
				if (response.data === null) {
					def.reject(new FdxError('UNKNOWN_ERROR', 'Error getting latest release note date'));
					return;
				}

				def.reject(new FdxError(response.data.message, response.data.data.error));
			}
		);

		return def.promise;
	}
}
