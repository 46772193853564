<modal-hoc
    modalTitle="Exports FAQ"
    [submitButton]="false"
    [cancelButton]="false"
    (modalCancel)="onModalCancel($event)">
	<div class="card mb-3">
		<div class="card-header h5">
			Schedule
		</div>
		<div class="card-body">
			This is when we will export your product feed.<br>
			If there is an import scheduled at the same time, the import will finish, the database will refresh, and
			then your export will begin.
		</div>
	</div>
	<div class="card mb-3">
		<div class="card-header h5">
			Credentials
		</div>
		<div class="card-body">
			<div class="card mb-3">
				<div class="card-header h5">
					Export name
				</div>
				<div class="card-body">
					This is a name that's just for you, so make sure it reflects what the Export is doing!
				</div>
			</div>
			<div class="card mb-3">
				<div class="card-header h5">
					File name
				</div>
				<div class="card-body">
					This is more accurately the file path where we'll be pushing the file to.<br>
					If the file should be a pushed to a particular folder, you can do so by specifying it with / as
					the path separator:<br>
					<strong>Example:</strong> incoming_folder/my_special_directory/file_name.csv
				</div>
			</div>
			<div class="card mb-3">
				<div class="card-header h5">
					Host
				</div>
				<div class="card-body">
					This is the host name of the location we'll be pushing the export to.<br>
					It should not begin with ftp:// or ftps:// or sftp:// (that's specified by the Protocol
					field!)<br>
					<br>
					If a non-standard port is required, this can be done at the end of the field<br>
					For example, to specify port 1234, do the following: app.feedonomics.com:1234
				</div>
			</div>
		</div>
	</div>
	<div class="card mb-3">
		<div class="card-header h5">
			Export if
		</div>
		<div class="card-body">
			This is your opportunity to exclude products. Only products for which this If condition is true will be
			present in the export<br><br>
			<strong>Example 1: </strong>[availability] equal 'in stock'<br>
			<strong>Example 2: </strong>[availability] equal 'in stock' AND ([clicks]&lt;100 OR ([clicks]&gt;=100
			AND
			[roas]&gt;3))<br>
			Example 2 will export only products that are in stock that also have:<br>
			Less than 100 clicks<br>
			OR &gt;=100 clicks with a return on ad spend greater than 3.
		</div>
	</div>
	<div class="card mb-3">
		<div class="card-header h5">
			Options
		</div>
		<div class="card-body">
			<div class="card mb-3">
				<div class="card-header h5">
					Dynamic variable substitution (dates, row counts, and more!)
				</div>
				<div class="card-body">
					<strong>Dates</strong>
					<div class="pad-left">
						You can use the following syntax to <strong>specify dates in the export file name and the
							export
							header/footer:</strong><br>
						<span ngNonBindable>{{feedonomics::date:Ymd}}</span><br>
						<br>
						If you need alternate date format, you can construct them using the characters
						below:<br><br>
						<strong>Year/Month/Day</strong><br>
						Y - A full numeric representation of a year, 4 digits Examples: 1999 or 2003<br>
						m - Numeric representation of a month, with leading zeros 01 through 12<br>
						d - Day of the month, 2 digits with leading zeros 01 to 31<br>
						<br>
						<strong>Time</strong><br>
						a - Lowercase Ante meridiem and Post meridiem am or pm<br>
						A - Uppercase Ante meridiem and Post meridiem AM or PM<br>
						B - Swatch Internet time 000 through 999<br>
						g - 12-hour format of an hour without leading zeros 1 through 12<br>
						G - 24-hour format of an hour without leading zeros 0 through 23<br>
						h - 12-hour format of an hour with leading zeros 01 through 12<br>
						H - 24-hour format of an hour with leading zeros 00 through 23<br>
						i - Minutes with leading zeros 00 to 59<br>
						s - Seconds, with leading zeros 00 through 59<br>
						<br>
						Example: <span ngNonBindable>{{feedonomics::date:Y-m-d H:i:s}}</span> will output something
						like 2016-07-25
						23:59:34<br>
					</div>
					<br>
					<strong>Row counts</strong>
					<div class="pad-left">
						You can use the following syntax to specify row counts in the export header/footer:<br>
						<span ngNonBindable>{{feedonomics::total_count}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</modal-hoc>
