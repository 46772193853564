import { Inject, Injectable } from '@angular/core';
import { LocalStorageKey } from '@app/core/models/enums/local-storage-key.enum';
import { WINDOW } from '@app/core/providers/window.provider';
import { FdxUtilsService } from '@app/core/services/fdx-utils.service';

export type TLocalStorageKey = `${number}_${LocalStorageKey}`;
export type TOtherLocalStorageKey = `${number}_${number}_${LocalStorageKey}`;
export type LocalStorageKeys = TLocalStorageKey | TOtherLocalStorageKey | LocalStorageKey;

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    constructor(
        @Inject(WINDOW) private readonly window: Window,
        private readonly fdxUtils: FdxUtilsService
    ) {
        this.clean();
    }

    /**
     * This method looks dangerous, let's revisit the purpose of this
     * and if we still need it. If we do it should be refactored to be
     * safer and only delete specific keys from the `LocalStorageKey`
     * enum once we have completely moved all code to use the
     * `LocalStorageKey` enum.
     * RK 2023-09-13
     */
    clean(): void {
        const items = { ...this.window.localStorage };

        Object.keys(items).forEach((key) => {
            let needsCleaning = false;
            let data = items[key];

            try {
                data = JSON.parse(data);
            } catch (e) {
                return;
            }

            if (!this.fdxUtils.isObject(data)) {
                return;
            }

            if ('current_column_headers' in data) {
                // remove deprecated option
                delete data.current_column_headers;
                needsCleaning = true;
            } else if ('viewable_columns' in data) {
                // remove deprecated option
                delete data.viewable_columns;
                needsCleaning = true;
            } else if ('column_select' in data) {
                // remove deprecated option
                delete data.column_select;
                needsCleaning = true;
            }

            if (needsCleaning) {
                this.setItem(key, JSON.stringify(data));
            }
        });
    }

    setItem(key: LocalStorageKeys | string, value: string): void {
        this.window.localStorage.setItem(key, value);
    }

    /**
     * `JSON.stringify()` a value and save it to the specified
     * `LocalStorageKey` enum key in local storage.
     * @param key `LocalStorageKey` enum key
     * @param value `T` generic-typed value
     */
    serializeItem<T>(key: LocalStorageKeys | string, value: T): void {
        const serialized: string = JSON.stringify(value);

        this.window.localStorage.setItem(key, serialized);
    }

    getItem(key: LocalStorageKeys | string): string | null {
        return this.window.localStorage.getItem(key);
    }

    /**
     * Retreives a value from the specified `LocalStorageKey` enum
     * key from local storage and `JSON.parse()` the value.
     * @param key `LocalStorageKey` enum key
     * @returns value `T` generic-typed value
     */
    deserializeItem<T>(key: LocalStorageKeys | string): T | null {
        const value: string | null = this.window.localStorage.getItem(key);

        if (!value) {
            return null;
        }

        const deserialized: T = JSON.parse(value) as T;

        return deserialized;
    }

    removeItem(key: LocalStorageKeys | string): void {
        this.window.localStorage.removeItem(key);
    }

    getKeys(): Array<string> {
        return Object.keys({ ...this.window.localStorage });
    }

    clear(): void {
        this.window.localStorage.clear();
    }
}
