import { Injectable } from '@angular/core';
import {
    IconDefinition,
    faCircleCheck, faCircleExclamation, faCircleInfo, faFlask,
    faSparkles,
    faSpinner, faTriangleExclamation
} from '@fortawesome/pro-solid-svg-icons';

export type BootstrapThemeProp =
    'default' |
    'primary' | 
    'secondary' |
    'success' | 
    'danger' | 
    'warning' |
    'info' |
    'light' |
    'dark' |
    'discovery' |
    'downloading';

export type BootstrapThemeType = {
    name: string,
    icon: IconDefinition | null
}

const BOOTSTRAP_THEMES: Record<BootstrapThemeProp, BootstrapThemeType> = {
    'default': {
        name: '',
        icon: null
    },
    'primary': {
        name: 'primary',
        icon: null
    },
    'secondary': {
        name: 'secondary',
        icon: faCircleInfo
    },
    'success': {
        name: 'success',
        icon: faCircleCheck
    },
    'danger': {
        name: 'danger',
        icon: faCircleExclamation
    },
    'warning': {
        name: 'warning',
        icon: faTriangleExclamation
    },
    'info': {
        name: 'info',
        icon: faCircleInfo
    },
    'light': {
        name: 'light',
        icon: null
    },
    'dark': {
        name: 'dark',
        icon: faFlask /* Use for "revert to the old version" banners */
    },
    'discovery': {
        name: 'discovery',
        icon: faSparkles
    },
    
    /** notification popover style **/
    'downloading': {
        name: '',
        icon: faSpinner
    },
    /** notification popover style **/
};

@Injectable({
    providedIn: 'root'
})
export class BootstrapThemeService {
    getThemeType(themeName: BootstrapThemeProp): BootstrapThemeType {
        return BOOTSTRAP_THEMES[themeName] ?? null;
    }
} 
