import * as angular from 'angular';
import * as moment from 'moment';
import { MODULE_NAME } from '../config/constants';

angular.module(MODULE_NAME).controller('FeedAlertsController', ['$scope', '$state', '$http', '$filter', 'AppStateService', 'CSVService', 'fdxUI', function($scope, $state, $http, $filter, AppStateService, CSVService, fdxUI) {
	fdxUI.setTitle('FeedAlerts Dashboard');
	fdxUI.setActiveTab('dashboards');

    $scope.account = AppStateService.getAccount();

	// Sorts by servability [disapproved first], then number of items [greater first]
	$scope.error_sort = function (a,b) {
		const servability_map = {'unaffected' : 1, 'demoted' : 0, 'disapproved': -1}
		const servability = servability_map[a.servability] - servability_map[b.servability];
		return servability || b.numItems - a.numItems;
	};

	$scope.download_in_progress = false;

	const human_friendly_errors = {
		general_spam_policy: 'Automatic item disapprovals due to policy violation',
		generic_landing_page_redirect: 'Redirect to generic landing page',
		image_link_broken: 'Invalid images',
		image_link_generic: 'Generic images',
		image_link_missing: 'Missing images',
		image_link_roboted: 'Images cannot be crawled because of robots.txt restriction',
		image_link_slow_crawl: 'Ensure that Google can access and crawl your images',
		image_link_too_small: 'Images too small',
		landing_page_error: 'Product pages cannot be accessed',
		landing_page_roboted: 'Product pages cannot be crawled because of robots.txt restriction',
		missing_required_gtin_error: 'Item requires a GTIN',
		mobile_landing_page_error: 'Product pages cannot be accessed from a mobile device',
		mobile_landing_page_roboted: 'Product pages cannot be crawled with a mobile user-agent because of robots.txt restriction',
		forbidden_bulk_gtin_error: 'Item uses a GTIN reserved for bulk products when a valid GTIN is required',
		image_link_small_warning: 'Image too small',
		missing_required_gtin_warning: 'Item requires a GTIN',
		voila_hammer_dangerous_knives: 'Items preemptively disapproved for a policy violation (Dangerous products: dangerous knives)',
		voila_hammer_other_weapons: 'Items preemptively disapproved for a policy violation (Dangerous products: other weapons)',
		wrong_tax: 'Incorrect tax information',
		forbidden_coupon_gtin_error: 'Item uses a coupon GTIN when a valid GTIN is required',
		forbidden_coupon_gtin_warning: 'Coupon code GTIN warning',
		invalid_mime_type: 'Item landing page does not render properly',
		missing_required_gtin_future_: 'Missing Required GTIN',
		mismatching_brand_gtin_prefix_: 'Mismatching brand, gtin_',
		price_mismatch: 'Temporary item disapprovals due to incorrect prices',
		strong_id_inaccurate: 'Incorrect product identifiers',
		title_all_caps: 'Capitalized titles',
		image_link_missing_local_offer: 'Local items - missing image',
		local_missing_description: 'Missing description',
		local_offer_unmatchable: 'Local offer not matching any catalog or online offer',
		availability_mismatch: 'Temporary item disapprovals due to incorrect availability',
		missing_required_item_color: 'Missing Color',
		missing_required_item_size: 'Missing Size',
		multiple_sizes: 'Multiple sizes for one item',
		wrong_shipping: 'Incorrect shipping costs',
		availability_updated: 'Incorrect microdata availability information',
		dq_pid_no_fresh_crawl: 'Preemptive disapproval for price',
		dq_pid_price_no_extraction: 'Preemptive disapproval for price',
		non_normalizable_sizes: 'Size not recognised',
		title_long: 'Long titles'
	}

	$scope.data = {
		'_loading': false,
		'_logs': [],
		'_filter': {
			'name': ''
		},
		'_search': function(row) {
			let found = true;

			// Check if name exists in the DB name
			if ($scope.data._filter['name']) {
				const search_name = $scope.data._filter.name.toLowerCase();

				found = row.name.toLowerCase().indexOf(search_name) >= 0;
			}

			return found;
		},
		'get_logs': function() {
			$scope.data._loading = true;

			$http.get('/api.php/accounts/' + AppStateService.getAccountId() + '/feed_alerts_dashboard' ).then((response) => {
                $scope.data._logs = response.data;

				for (let n=0; n<$scope.data._logs.length; n++) {
					// format time of event
					$scope.data._logs[n]['time'] = Date.parse($scope.data._logs[n].time.replace(/-/g,'/'));

					const payload_json = JSON.parse($scope.data._logs[n]['payload_json']);
					$scope.data._logs[n]['total_products'] = '';
					$scope.data._logs[n]['total_errors'] = '';

					$scope.data._logs[n]['errors'] = [];

					$scope.data._logs[n]['exception_json'] = false;
					if ('error' in payload_json) {
						$scope.data._logs[n]['exception_json'] = payload_json.error.errors;
						delete payload_json.error;
					}

					$scope.data._logs[n]['total_errors'] = '0';
					if ('overall' in payload_json) {
						$scope.data._logs[n]['total_errors'] = payload_json.overall.count;
					}

					if ('total_products' in payload_json) {
						$scope.data._logs[n]['total_products'] = payload_json['total_products']['count'];
						Object.keys(payload_json).forEach((key) => {
							const value = payload_json[key];
							let human_friendly_error = key;
							if (key in human_friendly_errors) {
								human_friendly_error = human_friendly_errors[key];
							}
							$scope.data._logs[n]['errors'].push({
								'message': human_friendly_error,
								'status': 'status' in value ? value.status : 'unset',
								'count': value.count
							});
						});
					}

					let raw_response;

					try {
						raw_response = JSON.parse($scope.data._logs[n]['raw_response']);
					} catch (e) {
						console.log($scope.data._logs[n]['raw_response']);
						raw_response = {};
					}

					$scope.data._logs[n]['raw_response'] = raw_response;

					// If it's a new error
					if ('account_status' in raw_response) {
						if (raw_response.account_status.products !== null) {
							const count_map = {};

							['disapproved', 'active', 'expiring', 'pending'].forEach((key) => {
								count_map[key] = raw_response.account_status.products[0]?.statistics[key];

								if (isNaN(count_map[key])) {
									count_map[key] = 0;
								}
							});

							$scope.data._logs[n]['total_errors'] = count_map['disapproved'];
							$scope.data._logs[n]['total_products'] =
								parseInt(count_map['active'], 10)
								+ parseInt(count_map['disapproved'], 10)
								+ parseInt(count_map['expiring'], 10)
								+ parseInt(count_map['pending'], 10)
							;
						}
					}
				}
				$scope.data._loading = false;
			});
		}
	};

	$scope.toggle = function(obj, attr, val) {
		obj[attr] = val;
	};

	$scope.data.get_logs();

	$scope.download_detailed_report = function(logs) {
		$scope.download_in_progress = true;
		const rows = [];
		let db_row = [];
		rows.push([
			'Database Name',
			'Threshold',
			'Last Run Time (EST)',
			'Total Products',
			'Total Errors',
			'Error Rate',
			'Internal Code',
			'Servability',
			'Attribute',
			'Description',
			'Detail',
			'Documentation',
			'Count'
		]);

        for (const db of logs) {
			db_row = [
				db.name,
				$scope.describe_threshold(db.message),
				$filter('moment')(db.time, 'YYYY-MM-DD HH:mm:ss'),
				$filter('number')(db.total_products, 1),
				$filter('number')(db.total_errors, 1),
				$filter('number')(db.total_errors / db.total_products * 100, 1) + '%'
			];

            const product = db.raw_response.account_status.products[0];

			if (product?.itemLevelIssues?.length) {
                const sorted = product.itemLevelIssues.toSorted($scope.error_sort);

                for (const issue of sorted) {
					const row = [...db_row];

					row.push(issue.code);
					row.push(issue.servability);
					row.push(issue.attributeName);
					row.push(issue.description);
					row.push(issue.detail);
					row.push(issue.documentation);
					row.push(issue.numItems);

					rows.push(row);
                }
			} else {
                rows.push(db_row);
            }
		}

		CSVService.download('FeedAlertsDetailedReport_' + moment().format('DD-MM-YYYY-hhmmss').toString() + '.csv', rows);
		$scope.download_in_progress = false;
	}

	$scope.download_simple_report = function(logs) {
		$scope.download_in_progress = true;
		const rows = [];
		rows.push([
			'Database Name',
			'Threshold',
			'Last Run Time (EST)',
			'Total Products',
			'Total Errors',
			'Error Rate'
		]);
		logs.forEach((db) =>{
			rows.push([
				db.name,
				$scope.describe_threshold(db.message),
				$filter('moment')(db.time, 'YYYY-MM-DD HH:mm:ss'),
				$filter('number')(db.total_products, 1),
				$filter('number')(db.total_errors, 1),
				$filter('number')(db.total_errors / db.total_products * 100, 1) + '%'
			]);
		});
		CSVService.download('FeedAlertsSimpleReport_' + moment().format('DD-MM-YYYY-hhmmss').toString() + '.csv', rows);
		$scope.download_in_progress = false;
	}

	$scope.describe_threshold = function (message) {
		switch (message) {
			case 'threshold_passed':
				return 'Passed';
			case 'threshold_failed':
				return 'Failed';
			case 'google_exception_thrown':
			case 'google_service_exception_thrown':
				return 'Exception Thrown';
		}
	}

    $scope.goToNewFeedAlertsPage = () => {
        $state.go('app.feed-alerts-dashboard', { account_id: AppStateService.getAccountId() });
    }

}]);
