import { InjectionToken } from '@angular/core';

export const CONSOLE = new InjectionToken<Console>(
    'Console',
    {
        providedIn: 'root',
        factory(): Console {
            return console;
        }
    }
);