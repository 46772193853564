import { Component } from '@angular/core';
import { BootstrapThemeProp } from '@app/core/services/bootstrap-theme.service';
import { AggregatedDbFieldsType } from '@app/modules/inputs/types/aggregated-db-fields.type';
import { Modal } from '@app/modules/modals/modals.module';
import { TransformerDbFieldModel } from '@app/transformers/models/transformer-db-field.model';
import { TransformerDisplayModel } from '@app/transformers/models/transformer-display.model';
import { environment } from '@environments/environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

interface ResolverInterface {
	currentField: TransformerDbFieldModel,
	transformer: TransformerDisplayModel,
    dbFields: AggregatedDbFieldsType
}

@Component({
    selector: 'fdx-transformers-delete-modal',
    templateUrl: './transformers-delete-modal.component.html',
    styleUrls: ['./transformers-delete-modal.component.scss']
})
export class TransformersDeleteModalComponent implements Modal {
    public modalTheme: BootstrapThemeProp = 'danger';
    public title: string = 'Confirm Delete Transformer';
    public submitText: string = 'Delete';
    public cancelText: string = 'Cancel';

    currentField: TransformerDbFieldModel;
    deactivateCodeMirror = environment.deactivateCodeMirror;
    transformer: TransformerDisplayModel;
    dbFields: AggregatedDbFieldsType;

    constructor(
        private modal: NgbActiveModal
    ) { }

    public onModalInit(resolve: ResolverInterface): void {
        this.transformer = resolve.transformer;
        this.currentField = resolve.currentField;
        this.dbFields = resolve.dbFields;
    }

    public onModalSubmit(event: Event): void {
        this.modal.close();
    }

    public onModalCancel(event: Event): void {
        this.modal.dismiss();
    }
}
