import * as angular from 'angular';
import { MODULE_NAME } from '../config/constants';

angular.module(MODULE_NAME).controller('FeedWizardController', ['$scope', '$stateParams', '$location', 'fdxUI', function($scope, $stateParams, $location, fdxUI) {
	fdxUI.setTitle('FeedWizard');
	$scope.wizard = $stateParams.wizard;

	switch($stateParams.wizard) {
		case 'ebaymotors': $scope.wizard = 'eBay Motors'; break;
		case 'woocommerce': $scope.wizard = 'WooCommerce'; break;
	}

	if (!$scope.wizard) {
		$location.path('/');
	}
}]);