import { Component, EventEmitter, Input, Output } from '@angular/core';
import { QueryButtonState } from '@app/modules/query/enums/query-button-state.enum';

@Component({
    selector: 'fdx-query-buttons',
    templateUrl: './query-buttons.component.html',
    styleUrls: ['./query-buttons.component.scss']
})
export class QueryButtonsComponent {
    @Input() mainButtonState: QueryButtonState = QueryButtonState.Search;

    @Output() readonly clickSearchButton: EventEmitter<void> = new EventEmitter<void>();
    @Input() disableSearchButton: boolean = false;

    @Output() readonly clickClearButton: EventEmitter<void> = new EventEmitter<void>();
    @Input() disableClearButton: boolean = false;

    @Output() readonly clickCancelButton: EventEmitter<void> = new EventEmitter<void>();

    public get mainButtonClass(): string {
        switch (this.mainButtonState) {
            case QueryButtonState.Cancel:
                return 'btn-secondary';

            default:
                return 'btn-primary';
        }
    }

    public get mainButtonText(): string {
        switch (this.mainButtonState) {
            case QueryButtonState.Cancel:
                return 'Cancel';

            default:
                return 'Search';
        }
    }

    onSearch(): void {
        switch (this.mainButtonState) {
            case (QueryButtonState.Cancel):
                this.clickCancelButton.emit();
                break;

            default:
                this.clickSearchButton.emit();
                break;
        }
    }

    onClear(): void {
        this.clickClearButton.emit();
    }
}
