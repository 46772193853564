/* eslint-disable angular/document-service */
import { isEmpty } from '@app/core/functions/isEmpty';

export default class fdxUtils {

    // Services
    $q = null;
    $window = null;

    constructor($q, $window) {
        this.$q = $q;
        this.$window = $window;
    }

    isEmpty(value) {
        return typeof value === 'undefined' || value === null || value === '';
    }

    isObject(value) {
        return typeof(value) === 'object' && value !== null && !Array.isArray(value);
    }

    isBoolean(value) {
        return value === true || value === false;
    }

    // https://itnext.io/create-date-from-mysql-datetime-format-in-javascript-912111d57599
    getDateObj(dateTime) {
        if (typeof dateTime !== 'string' || dateTime === '000-00-00 00:00:00') {
            return new Date();
        }
        const dateTimeParts = dateTime.split(/[- :]/); // regular expression split that creates array with: year, month, day, hour, minutes, seconds values
        dateTimeParts[1]--; // monthIndex begins with 0 for January and ends with 11 for December so we need to decrement by one

        return new Date(...dateTimeParts); // our Date object
    }

    getDateAsISO(date) {

        if (date && date.toISOString) {
            return date.toISOString().substr(0, 10);
        }

        return null;
    }

    isValidEmail(email) {
        const regExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regExp.test(String(email).toLowerCase());
    }

    isValidPassword(password) {
        const regExp = /^(?=.*\d)(?=.*[a-z])(?!.* )(?=.*[^a-zA-Z0-9]).{8,}$/m;
        return regExp.test(password);
    }

    clone(data) {
        return JSON.parse(JSON.stringify(data));
    }

    escapeHTML(str) {
        const pre = document.createElement('pre');
        const textNode = document.createTextNode(str);
        pre.appendChild(textNode);
        return pre.innerHTML;
    }

    removeEmptyValues(arr) {
        for (const key in arr) {
            if (Object.prototype.hasOwnProperty.call(arr, key) && isEmpty(arr[key])) {
                delete arr[key];
            }
        }
        return arr;
    }

    downloadURL(href, file_name = null) {
        // H/T: https://ourcodeworld.com/articles/read/189/how-to-create-a-file-and-generate-a-download-with-javascript-in-the-browser-without-a-server
        const element = document.createElement('a');
        element.setAttribute('href', href);
        if (file_name) {
            element.setAttribute('download', file_name);
        }
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    isUrl(url_string) {
        if (typeof url_string === 'string') {
            const matches = url_string.match(/^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/g);

            return matches !== null;
        }

        return false;
    }

    normalizeURL(url) {
        // for local testing
        if (url.indexOf('localhost/') >= 0) {
            const pos = url.indexOf('localhost/');
            url = this.$window.location.protocol + '//' + this.$window.location.host + '/' + url.slice(pos + 10);
        }
        if (!url.startsWith('http')) {
            url = '//' + url;
        }
        return url;
    }

    copyTextToClipboard(text) {
        const def = this.$q.defer();

        navigator.clipboard.writeText(text).then(
            () => {
                def.resolve();
            },
            () => {
                def.reject();
            }
        );

        return def.promise;
    }

    getFileExtensionFromDelimiter(delimiter) {
        switch (delimiter) {
            case 'comma':
                return 'csv';
            case 'tab':
                return 'tsv';
            default:
                return 'txt';
        }
    }

    isInternalUsername(username) {
        if (username) {
            return username.includes('@feedonomics.com') || username === 'admin';
        }
        return false;
    }

    isInternalUser(user) {
        if (user) {
            if (user.settings && user.settings['user.demo_client_view']) {
                const inDemoView = user.settings['user.demo_client_view'];
                if (inDemoView) {
                    return false;
                }
            }

            if (user.impersonated_user_id !== '') {
                return false;
            }

            return this.isInternalUsername(user.user_name);
        }
        return false;
    }

    /**
     * Reverse the result of a comparison function.
     *
     * Replace with TS version when this file is upgraded:
     * reverseComparison<T>(compareFn: (a: T, b: T) => number): (a: T, b: T) => number {
     *    return (a: T, b: T) => {
     *        const comparison = compareFn(a, b);
     *
     *        if (comparison === 0) {
     *            return 0;
     *        }
     *
     *        return -1 * comparison;
     *    };
     * }
     *
     * @param compareFn a comparison function that would be used for Array.prototype.sort, e.g. [].sort(compareFn)
     */
    reverseComparison(compareFn)  {
        return (a, b) => {
            const comparison = compareFn(a, b);

            if (comparison === 0) {
                return 0;
            }

            return -1 * comparison;
        };
    }

    hex2bin(hex) {
        let string;
        try {
            // https://stackoverflow.com/questions/15481059/javascript-hexadecimal-to-binary-using-utf8
            const encodedURI = hex.replace( /../g, '%$&' );
            string = decodeURIComponent( encodedURI );
        } catch (e) {
            // fallback to "decode what we can" when it's not a valid UTF-16 hex string
            const bytes = [];
            for(let i=0; i< hex.length-1; i+=2){
                bytes.push(parseInt(hex.substr(i, 2), 16));
            }
            string = String.fromCharCode.apply(String, bytes);
        }
        return string;
    }

}

fdxUtils.$inject = ['$q', '$window'];

export { fdxUtils };
