import { BootstrapThemeProp } from '@app/core/services/bootstrap-theme.service';

interface ResolverInterface {
	modalTheme: BootstrapThemeProp,
	title: string,
	body: string,
    bodyHTML: string
}

export default class FdxInfoModal {

	// from uibmodal
	private close: (result?: { $value }) => void;
	private dismiss: (result?: { $value: string }) => void;
	private resolve: ResolverInterface;

	public modalTheme: BootstrapThemeProp = 'primary';
	public title: string = 'Info';
	public body: string = '';
    public bodyHTML: string = '';

	static $inject = [];

	constructor() { }

	$onInit() {
		if (!this.resolve) {
			return;
		}

		if (this.resolve.modalTheme) {
			this.modalTheme = this.resolve.modalTheme;
		}

		if (this.resolve.title) {
			this.title = this.resolve.title;
		}

		if (this.resolve.body) {
			this.body = this.resolve.body;
		}

        if (this.resolve.bodyHTML) {
			this.bodyHTML = this.resolve.bodyHTML;
		}
	}

	public onCancel(): void {
		this.dismiss();
	}
}
