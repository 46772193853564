import CSVService from './CSVService';

import { DelimiterType } from '../models/data_download/DelimiterType';

/**
 * TODO: Upgrade service to Angular 14. This is no longer used in any AngularJS components.
 */

export default class DataDownloadService {

    static $inject: string[] = [
        'CSVService'
    ];

    constructor(
        private readonly csvService: CSVService
    ) { }

    public downloadCSV(
        filename: string,
        delimiterType: DelimiterType,
        header: Array<string>,
        data: Array<Array<string>>
    ): void {
        // prepare csv data
        const csvData: Array<Array<string>> = [header, ...data];

        // get delimeter
        const delimiter: string = this.delimiterTypeToString(
            delimiterType
        );

        // download csv
        this.csvService.download(
            filename,
            csvData,
            delimiter
        );
    }

    private delimiterTypeToString(delimiterType: DelimiterType): string {
        switch (delimiterType) {
            case DelimiterType.Tab:
                return '\t';

            case DelimiterType.Pipe:
                return '|';

            case DelimiterType.Semicolon:
                return ';'

            case DelimiterType.Comma:
            default:
                return ',';
        }
    }
}
