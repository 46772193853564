/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';
import { BaseComponent } from '@app/core-legacy/abstract/base.component';
import { LogService } from '@app/core/services/log.service';
import { BaseInputComponent } from '@app/modules/inputs/components/base-input/base-input.component';
import { InputCheckboxComponent } from '@app/modules/inputs/components/input-checkbox/input-checkbox.component';
import { InputRadioComponent } from '@app/modules/inputs/components/input-radio/input-radio.component';
import { takeUntil, tap } from 'rxjs';

/**
 * Currently does not support the following cases:
 * Input groups with button with dropdown addons https://getbootstrap.com/docs/5.0/forms/input-group/#buttons-with-dropdowns
 * Input groups with segmented buttons https://getbootstrap.com/docs/5.0/forms/input-group/#segmented-buttons
 * Input groups custom forms https://getbootstrap.com/docs/5.0/forms/input-group/#custom-forms
 */

@Component({
    selector: 'fdx-label',
    templateUrl: './label.component.html',
    styleUrls: ['./label.component.scss']
})
export class LabelComponent extends BaseComponent implements AfterViewInit {

    @Input() labelText: string;   // The label text. Unless defined as something different, will be equal to string value of ng-content, so the label should not be styled within the fdx-label tag without passing in a plain text labelText value.

    /** The input associated with this label, passed in from form-field.component. Used for styling the label. */
    public input: BaseInputComponent;

    /** classes handles the following cases:
     * https://getbootstrap.com/docs/5.0/forms/checks-radios/#toggle-buttons and https://getbootstrap.com/docs/5.0/forms/checks-radios/#radio-toggle-buttons (will remove .form-check-label if 'btn' is included)
     * https://getbootstrap.com/docs/5.0/forms/checks-radios/#outlined-styles
     * https://getbootstrap.com/docs/5.0/forms/input-group/#basic-example
     * https://getbootstrap.com/docs/5.0/forms/input-group/#wrapping
     * https://getbootstrap.com/docs/5.0/forms/input-group/#sizing
     * https://getbootstrap.com/docs/5.0/forms/floating-labels/#example
     */
    @Input() classes: Record<string, boolean> = {};
    @Input() styles: Record<string, any> = {};

    private initClasses: Record<string, boolean> = {};

    constructor(
        private readonly element: ElementRef<HTMLElement>,
        private readonly logService: LogService
    ) {
        super();
    }

    ngAfterViewInit(): void {
        this.validateConfiguration();

        if (!this.labelText) {
            this.labelText = this.element.nativeElement.children[0].innerHTML;
        }

        setTimeout(() => {
            this.input.labelText = this.labelText;
        });

        if (this.input.inputType === 'checkbox' || this.input.inputType === 'radio') {
            if (this.classes['btn']) {   // Must subscribe to changes to update label styles when using toggle buttons
                if (this.input instanceof InputCheckboxComponent || this.input instanceof InputRadioComponent) {
                    this.bindSetActive(this.input);
                }
            } else {
                this.initClasses['form-check-label'] = true;
            }
        } else if (this.classes['col-form-label'] === undefined) {
            this.initClasses['col-form-label'] = true;
        }
    }

    private isActive(input: InputCheckboxComponent | InputRadioComponent): boolean {
        return input.control.value === true || (input instanceof InputRadioComponent && input.control.value === input.controlValue);
    }

    private bindSetActive(input: InputCheckboxComponent | InputRadioComponent): void {
        this.classes['active'] = this.isActive(input);

        input.control.valueChanges.pipe(
            tap(() => {
                this.classes['active'] = this.isActive(input);
            }),
            takeUntil(this.unsubscribe$)
        ).subscribe();
    }

    private validateConfiguration(): string[] {
        const errors: string[] = [];

        if (!this.input) {
            errors.push('No associated input has been passed in to this label');
        }

        errors.forEach((error) => {
            this.logService.error(error);
        });

        return errors;
    }


    get labelClasses(): Record<string, boolean> {
        return {
            ...this.initClasses,
            ...this.classes,
            'disabled': this.input.control?.disabled
        };
    }

    get for(): string {
        return this.input.id;
    }

    get title(): string {
        return this.input.title;
    }
}
