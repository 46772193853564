<div class="container">
    <fdx-page-title>Add Bulk Transformers</fdx-page-title>
    <p class="my-2">Please select your preferred method to import transformers</p>
    <fdx-alert *ngIf="error" theme="danger" [body]="error"></fdx-alert>

    <form>
        <ngb-accordion>
            <ngb-panel>
                <ng-template ngbPanelTitle>
                    <span class="fw-bold">Submit transformers via CSV file</span>
                </ng-template>

                <ng-template ngbPanelContent>
                    <div>
                        <p class="fw-bold">The CSV must have the following fields:</p>
                        <pre class="col-6">
                            field_name,export_id,if,then,enabled
                            "sku",0,"[sku] not_contains [brand]","[brand] '-' [sku]",1
                        </pre>
                    </div>

                    <div class="row my-4">
                        <label class="col-1 col-form-label text-end">Separator</label>
                        <div class="col-1">
                            <ng-select [(ngModel)]="csv.options.delimiter" [clearable]="false" name="delimiter">
                                <ng-option *ngFor="let option of dropdowns.delimiters" [value]="option.value">{{option.display}}</ng-option>
                            </ng-select>
                        </div>

                        <label class="col-1 col-form-label text-end">Enclosure</label>
                        <div class="col-1">
                            <ng-select [(ngModel)]="csv.options.enclosure" [clearable]="false" name="enclosure">
                                <ng-option *ngFor="let option of dropdowns.enclosures" [value]="option.value">{{option.display}}</ng-option>
                            </ng-select>
                        </div>

                        <label class="col-1 col-form-label text-end">Escape</label>
                        <div class="col-1">
                            <ng-select [(ngModel)]="csv.options.escape" [clearable]="false" name="escape">
                                <ng-option *ngFor="let option of dropdowns.escapes" [value]="option.value">{{option.display}}</ng-option>
                            </ng-select>
                        </div>
                    </div>

                    <div>
                        <p class="fw-bold">Upload CSV File:</p>
                        <input #fileInput (change)="onFileSelected($event)" type="file">
                    </div>
                </ng-template>
            </ngb-panel>
        </ngb-accordion>

        <ngb-accordion class="d-block mt-4">
            <ngb-panel>
                <ng-template ngbPanelTitle>
                    <span class="fw-bold">Submit Raw Transformers</span>
                </ng-template>

                <ng-template ngbPanelContent>
                    <div>
                        <p class="fw-bold">Raw Transformer Instructions:</p>
                        <p>Submit your bulk transformers below with one per line separated by tabs with no enclosure or escape characters.</p>
                        <ul>
                            <li>The first column is the field name under which the transformer will be added.</li>
                            <li>The second column is the If condition.</li>
                            <li>The third column is the Then statement</li>
                            <li>The fourth column is if the transformer is Enabled (1 = enabled; 0 = disabled)</li>
                        </ul>
                        <p>Tabs cannot be directly entered into the input below, but you can copy/paste from excel.</p>
                    </div>

                    <textarea [(ngModel)]="raw.value"
                            [minRows]="10"
                            autosize
                            class="form-control mt-5"
                            placeholder="{{raw.placeholder}}"
                            name="raw"></textarea>
                </ng-template>
            </ngb-panel>
        </ngb-accordion>

        <div class="mt-4">
            <button type="button" (click)="submit()" class="btn btn-primary">Submit Bulk Transformers</button>
        </div>
    </form>
</div>
