import AppStateService from '@ajs/services/AppStateService';
import { Injectable } from '@angular/core';
import { TransformerDbFieldModel } from '@app/transformers/models/transformer-db-field.model';
import { DbFieldsTransformerCountResponse } from '@app/transformers/services/responses/db-fields-transformer-count.response';
@Injectable({
    providedIn: 'root'
})
export class TransformerUtilitiesService {

    constructor(
        private appStateService: AppStateService,
    ) {}

    get userId(): string {
        return this.appStateService.getUserId();
    }
    
    get databaseId(): string {
        return this.appStateService.getDatabaseId();
    }
    
    get variableSelectionKey(): string {
        return `${this.userId}_${this.databaseId}_variable_selection`;
    }
    
    getDownloadUrl(databaseId: string, sortType: 'alphabetical' | 'field_execution_order'): string {
        return `/api.php/dbs/${databaseId}/transformers/download?sort=${sortType}`;
    }

    transformerDbFieldModelSearchFn(term: string, item: TransformerDbFieldModel): boolean {
        return item.displayName.toLowerCase().includes(term.toLowerCase());
    }
    
    mapDbFieldToModel(dbField: DbFieldsTransformerCountResponse, index: number): TransformerDbFieldModel {
        return {
            name: `field_${dbField.sort_order}`,
            displayName: dbField.field_name,
            offset: index + 1,
            sortOrder: +dbField.sort_order,
            transformerCount: dbField.transformer_count,
            title: this.genTitle(dbField.field_name, dbField.transformer_count),
            label: this.genLabel(dbField.field_name, dbField.transformer_count)
        };
    }
        
    genLabel(displayName: string, transformerCount: number): string {
        return `${displayName} (${transformerCount})`;
    }
    
    genTitle(displayName: string, transformerCount: number): string {
        return `Variable [${displayName}] has ${transformerCount} existing transformers`;
    }
}
