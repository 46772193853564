<div [formGroup]="fGroup" [ngClass]="{'fdx-removable-div': removable, 'fdx-expand-wrapper': removable}" (focus)="hovering = true" (mouseover)="hovering = true" (mouseout)="hovering = false">
    <ng-select #select
        [formControlName]="controlName"
        [ngClass]="inputClasses"
        [ngStyle]="styles"
        [id]="id"
        [attr.title]="title ? title : null"
        [attr.aria-label]="ariaLabel ? labelText: null"
        [attr.required]="control.errors?.['required'] ? 'required' : null"

        [items]="items"
        bindLabel="display"
        bindValue="display"
        [placeholder]="placeholder"
        [addTag]="true"
        groupBy="group"
        [clearable]="multiple"
        [multiple]="multiple"
        [editableSearchTerm]="true"
        (change)="patchSelection($event)"
        (search)="searchEvent($event)"
        (blur)="blurEvent()"
        (open)="openEvent()"
        (keydown)="keydownEvent($event)"
        (paste)="onPaste($event)">
        <ng-template ng-tag-tmp>
            <div *ngIf="noFieldResults">
                <div class="ng-option-disabled ng-optgroup p-0">Field Not Found</div>
            </div>
            <div *ngIf="!searchingField">
                <div class="ng-option-disabled ng-optgroup p-0">Value</div>
                <div class="ng-option ng-option-child"><span class="fdx-search-term">{{ select.searchTerm }}</span></div>
            </div>
        </ng-template>
        <ng-template ng-label-tmp let-item="item" let-clear="clear">
            <fdx-select-multiple-label-template
                [item]="item"
                [clear]="clear"
                [ngClass]="{'fdx-select-field': isField(item.display)}">
                {{ item.display }}
            </fdx-select-multiple-label-template>
        </ng-template>
    </ng-select>
    <span [hidden]="shouldHideRemoveField" class="fdx-clear-badge badge" (click)="clickedClear()">
        <fa-icon [icon]="xIcon" size="lg"></fa-icon>
    </span>
    <fdx-validation [control]="control"
        [labelText]="labelText"
        [customValidationText]="customValidationText"
        [submitted]="form?.submitted"
        [ngClass]="{'invalid-feedback': !tooltipValidation, 'invalid-tooltip': tooltipValidation}"
    ></fdx-validation>
</div>
