<fdx-form-section header="Export if">
    <form [formGroup]="form">
        <div class="input-group mb-2">
            <span class="input-group-text">Product count ></span>
            <input type="text" class="form-control" formControlName="threshold">
            <span class="input-group-text">AND</span>
        </div>

        <fdx-advanced-query
            controlName="exportSelector"
            [cheatSheet]="false"
            [codeMirrorOptions]="codeMirrorOptions"
            [dbFields]="dbFields"
            [queryForm]="form">
        </fdx-advanced-query>

    </form>
</fdx-form-section>
