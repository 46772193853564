import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ScheduleStatusService } from '@app/core/services/schedule-status.service';
import { ExportConstants } from '@app/exports/constants/exports.constants';
import { EDRTSUtilsService } from '@app/exports/services/edrts-utils.service';
import { Export } from '@app/exports/services/responses/get-exports.response';
import { OrderByPipe } from '@app/modules/pipes/pipes/order-by/order-by.pipe';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { Subject, pairwise, startWith, takeUntil } from 'rxjs';

@Component({
    selector: 'fdx-export-select',
    templateUrl: './export-select.component.html',
    styleUrls: ['./export-select.component.scss']
})
export class ExportSelectComponent implements OnInit, OnChanges, OnDestroy {

    @Input() formGroup: UntypedFormGroup;
    @Input() controlName: string;

    @Input() exports: Export[];
    @Output() readonly exportsChange: EventEmitter<Export[]> = new EventEmitter<Export[]>();
    @Input() triggers?: { export_id: string; }[];

    @Input() multiple?: boolean = false;
    @Input() placeholder?: string;
    @Input() allowBlank?: boolean = false;

    @Input() tabIndex?: number = null;
    @Input() isEBSDatabase: boolean = false;

    allExports: Export = ExportConstants.allExports;

    orderedExports: Export[] = [];

    private exportsWithTriggers?: Set<string>;

    private readonly unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        readonly edrtsUtilsService: EDRTSUtilsService,
        private readonly orderByPipe: OrderByPipe,
        private readonly scheduleStatusService: ScheduleStatusService
    ) { }

    ngOnInit(): void {
        if (this.multiple && !this.allowBlank) {
            this.initFallbackValue();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.exports) {
            if (this.multiple) {
                this.addContainsAll();
            }

            this.orderedExports = this.orderByPipe.transform(this.exports, ['id == 0', 'name']);
        }

        if (changes.triggers) {
            if (this.triggers) {
                this.exportsWithTriggers = new Set(
                    this.triggers.map(
                        (t) => t.export_id
                    )
                );
            } else {
                this.exportsWithTriggers = undefined;
            }
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    initFallbackValue(): void {
        this.formGroup.controls[this.controlName].valueChanges.pipe(
            startWith(this.formGroup.value),
            pairwise(),
            takeUntil(this.unsubscribe$)
        ).subscribe(
            ([prev, next]: [Export[], Export[]]) => {
                const nextContainsAllIndex = this.indexOfContainsAll(next);

                if (nextContainsAllIndex !== -1) {
                    if (prev && this.indexOfContainsAll(prev) === -1) {
                        this.patchControlValue([this.allExports]);
                    } else {
                        next.splice(nextContainsAllIndex, 1);
                        this.patchControlValue(next);
                    }
                }
                if (next.length === 0) {
                    this.patchControlValue([this.allExports], true);
                }
            }
        );
    }

    patchControlValue(array: Export[], emit: boolean = false): void {
        this.formGroup.patchValue({
            [this.controlName]: array
        }, { emitEvent: emit });
    }

    get exportsDropdownPlaceholder(): string {
        if (this.exports?.length > 0) {
            if (this.placeholder) {
                return this.placeholder;
            }
            return this.multiple ? 'Choose exports' : 'Choose an export';
        }
        return 'This database has no exports yet';
    }

    indexOfContainsAll(array: Export[]): number {
        let allExportsIndex = -1;

        for (let i = 0; i < array.length; i++) {
            const exprt = array[i];

            if (exprt.id === '0') {
                allExportsIndex = i;
                break;
            }
        }

        return allExportsIndex;
    }

    addContainsAll(): void {
        if (this.exports && this.indexOfContainsAll(this.exports) === -1) {
            this.exports?.unshift(this.allExports);
            this.exportsChange.emit(this.exports);
        }
    }

    exportHasSchedule(exprt: Export): boolean {
        return Boolean(exprt.cron);
    }

    exportHasFtpTrigger(exprt: Export): boolean {
        if (exprt && this.exportsWithTriggers) {
            return this.exportsWithTriggers.has(exprt.id);
        }

        return false;
    }

    exportIsPaused(exprt: Export): boolean {
        return exprt.paused === '1';
    }

    getExportIcon(exprt: Export): IconDefinition {
        return this.scheduleStatusService.getStatusIcon(
            this.exportHasSchedule(exprt),
            this.exportHasFtpTrigger(exprt),
            this.exportIsPaused(exprt)
        );
    }

    getExportIconClass(exprt: Export): string {
        return this.scheduleStatusService.getStatusIconClass(
            this.exportHasSchedule(exprt),
            this.exportHasFtpTrigger(exprt),
            this.exportIsPaused(exprt)
        );
    }

    protected exportSearch(term: string, exprt: Export): boolean {
        const normalized = term.toLowerCase();
        return exprt.name.toLowerCase().includes(normalized);
    }

    protected trackExport(exprt: Export): string {
        return exprt.id;
    }

    protected edrtsIcon(exprt: Export): string {
        if (exprt.export_triggered) {
            return '/dist/app/assets/icons/edrts_contrast.svg';
        }
        return '/dist/app/assets/icons/edrts_grayscale.svg';
    }
}
