import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ValidationText } from '@app/modules/inputs/enums/validation-text.enum';
import { IconDefinition, faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';

@Component({
    selector: 'fdx-validation',
    templateUrl: './validation.component.html',
    styleUrls: ['./validation.component.scss']
})
export class ValidationComponent {

    @Input() control: AbstractControl;          // control reference passed in from the template of the input
    @Input() labelText: string;                 // label text passed in from the template of the input
    @Input() customValidationText?: string;     // Custom error string for when validation is not met (will overwrite defaults)
    @Input() submitted: boolean;                // Whether or not the parent form has been submitted
    @Input() showIcon?: boolean = false;        // Whether or not we should show the exclamation mark icon next to the text (only for checkboxes and radios)

    iconExclamation: IconDefinition = faExclamationCircle;

    get validationText(): typeof ValidationText {
        return ValidationText;
    }
}
