<fdx-logs-filters
    [formGroup]="filters"
    [logType]="logType"
    (submitFilters)="filterChanged()">
    <div class="col-12 col-md-6 col-lg-2">
        <fsc-multiselect
            formControlName="action"
            inputId="action"
            label="Action"
            labelClass="col-form-label pt-0"
            [items]="actions"
            [clearable]="true"
            (dataSelected)="patchFromMultiselect('action', $event)">
        </fsc-multiselect>
    </div>
    <div class="col-12 col-md-6 col-lg-2">
        <fsc-multiselect
            formControlName="field"
            inputId="field"
            label="Field"
            labelClass="col-form-label pt-0"
            [items]="fieldNames"
            [clearable]="true"
            (dataSelected)="patchFromMultiselect('field', $event)">
        </fsc-multiselect>
    </div>
    <div class="col-12 col-md-6 col-lg-2">
        <fsc-multiselect
            formControlName="user"
            inputId="user"
            label="User"
            labelClass="col-form-label pt-0"
            [items]="users"
            [clearable]="true"
            (dataSelected)="patchFromMultiselect('user', $event)">
        </fsc-multiselect>
    </div>
</fdx-logs-filters>

<div class="card mt-3">
    <div class="card-header d-flex align-items-center gap-3">
        <div class="ms-auto">
            <button
                type="button"
                class="btn btn-outline-secondary"
                (click)="fireRequest(true)"
                popoverTitle="Download logs"
                ngbPopover="Limited to 10,000 rows">
                <fa-icon [icon]="downloadIcon" class="no-margin"></fa-icon>
            </button>
        </div>
    </div>
    <div class="card-body p-0">
        <ag-grid-angular
            class="ag-theme-alpine border-0 rounded-bottom-lg remove-bottom-border"
            [modules]="modules"
            [gridOptions]="gridOptions"
            (gridReady)="onGridReady($event)"
            (filterChanged)="showOrHideNoRowsOverlay()"
        />
    </div>
</div>
