/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Injectable({
    providedIn: 'root'
})
export class FdxBlockUIService {
    @BlockUI() blockUI: NgBlockUI;

    start(message?: any): void {
        this.blockUI.start(message);
    }

    stop(): void {
        this.blockUI.stop();
    }
}
