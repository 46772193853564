import AppStateService from '@ajs/services/AppStateService';
import { Injectable } from '@angular/core';
import { isEmpty } from '@app/core/functions/isEmpty';
import { LocalStorageService } from '@app/core/services/local-storage.service';
import { BasicQueryRow } from '@app/modules/inputs/classes/basic-query-row.class';
import { BasicTransformerField } from '@app/modules/inputs/classes/basic-transformer-field.class';
import { OperatorConstants } from '@app/modules/inputs/constants/operator.constants';
import { QueryType } from '@app/modules/inputs/enums/query-type.enum';
import { BasicQueryUtilities } from '@app/modules/inputs/services/basic-query-utilities.service';
import { BasicQueryData } from '@app/modules/inputs/types/basic-query-data.type';
import { OperatorType } from '@app/modules/inputs/types/operator.type';
import { TokenizerSelectorTokenEnum } from '@app/transformers/enums/tokenizer-selector-token.enum';
import { TokenizerTransformerTokenEnum } from '@app/transformers/enums/tokenizer-transformer-token.enum';
import { TokenizerSelector } from '@app/transformers/models/tokenizer-selector.type';
import { TokenizerTransformer } from '@app/transformers/models/tokenizer-transformer.type';
import { TokenizeTransformerResponse } from '@app/transformers/services/responses/tokenize-transformer.response';
import { TransformersDataService } from '@app/transformers/services/transformers-data.service';
import { Observable, catchError, map, of } from 'rxjs';

/**
 * Must be a provided by each instance of QueryInputsComponent so that the initialization code works as expected
 */
@Injectable({
    providedIn: null
})
export class BasicQueryService {

    debug: boolean = false;

    get databaseId(): string {
        return this.appStateService.getDatabaseId();
    }

    defaultBasicQueryRow: BasicQueryRow[][] = [[new BasicQueryRow()]];
    defaultTransformerQueryRow: BasicTransformerField[] = [new BasicTransformerField(null)];

    initializing: boolean;
    preferredQueryType: QueryType = QueryType.Advanced;
    transformerMode: boolean = true;

    tokenizerValueTypes: TokenizerSelectorTokenEnum[] = [TokenizerSelectorTokenEnum.String, TokenizerSelectorTokenEnum.Num, TokenizerSelectorTokenEnum.Variable];
    tokenizerValidMultipleTypes: TokenizerSelectorTokenEnum[] = [...this.tokenizerValueTypes, TokenizerSelectorTokenEnum.OpenParen, TokenizerSelectorTokenEnum.Comma, TokenizerSelectorTokenEnum.CloseParen];

    constructor(
        private appStateService: AppStateService,
        private basicQueryUtilities: BasicQueryUtilities,
        private localStorageService: LocalStorageService,
        private transformersDataService: TransformersDataService
    ) {}

    getDefaultBasicQueryData(queryType: QueryType): BasicQueryData {
        return {
            selector: this.defaultBasicQueryRow,
            transformer: this.defaultTransformerQueryRow,
            error: false,
            tooComplex: false,
            loading: false,
            queryType: queryType,
            transformerMode: this.transformerMode,
            selectorColumns: []
        }
    }

    getBasicQueryData(queryString: string, transformerString: string, queryType: QueryType): Observable<BasicQueryData> {
        if (this.initializing === undefined) {
            this.initializing = true;
            this.transformerMode = transformerString !== undefined;

            const preferredType = this.localStorageService.getItem(
                this.transformerMode ? this.basicQueryUtilities.transformerTypeKey : this.basicQueryUtilities.queryTypeKey
            );
            if (preferredType && Object.values(QueryType).includes(preferredType as QueryType)) {
                this.preferredQueryType = preferredType as QueryType;
            }
            queryType = this.preferredQueryType;
        }

        if (transformerString === undefined) {
            transformerString = '';
        }

        // queryString = this.simplifyQueryString(queryString);
        /* if (transformerMode) {
            transformerString = this.simplifyTransformerString(transformerString);
        } */

        const queryEmpty: boolean = isEmpty(queryString);
        const transformerEmpty: boolean = isEmpty(transformerString);

        if (queryEmpty && transformerEmpty) {
            this.initializing = false;
            return of(this.getDefaultBasicQueryData(queryType));
        }

        return this.transformersDataService.tokenize(this.databaseId, {
            selector: queryString,
            transformer: transformerString
        }).pipe(
            map((response: TokenizeTransformerResponse) => this.handleTokenizerResponse(response, queryEmpty, transformerEmpty, queryType)),
            catchError(() => {
                this.initializing = false;
                return of({
                    selector: this.defaultBasicQueryRow,
                    transformer: this.defaultTransformerQueryRow,
                    error: true,
                    tooComplex: false,
                    loading: false,
                    queryType: queryType,
                    transformerMode: this.transformerMode,
                    selectorColumns: []
                })
            })
        );
    }

    private handleTokenizerResponse(response: TokenizeTransformerResponse, queryEmpty: boolean, transformerEmpty: boolean, queryType: QueryType): BasicQueryData {
        if (this.debug) {
            console.log('selector:', response.selector);
            console.log('transformer:', response.transformer);
        }

        // TODO: Check how tokenizer handles comments

        let rowData: BasicQueryRow = new BasicQueryRow();
        let innerData: BasicQueryRow[] = [];
        const selectorData: BasicQueryRow[][] = [];

        const transformerData: BasicTransformerField[] = [];

        let tooComplex: boolean = false;
        let error: boolean;
        if (!this.transformerMode) {
            error = !queryEmpty && response.selector.length === 0;
        } else {
            error = (!queryEmpty && response.selector.length === 0) || (!transformerEmpty && response.transformer.length === 0);
        }

        let unterminatedParenthesisCount: number = 0;

        let decodingMultiple: boolean = false;
        let multipleStructure: TokenizerSelector[] = [];

        const selectorColumns: Set<string> = new Set();

        response.selector.forEach((tokenizer: TokenizerSelector) => {
            const token: TokenizerSelectorTokenEnum = tokenizer.token;
            const value: string = tokenizer.value;
            const op: OperatorType = OperatorConstants.allOperators[value];

            if (this.debug) {
                console.log('starting rowData:', rowData);
                console.log('starting innerData:', innerData);

                console.log('token:', token);
                console.log('value:', value);
            }

            /**
             * Before we look at this token, check if we're decodingMultiple. If we are, and this token isn't one that's valid for the mutliple structure, OR
             * if the token is specifically a close parentheses, but we didn't start this multiple structure with an open parenthesis, then we're good to
             * assume we're finishing up the multiple structure. Now it's time to test if the current multiple structure we have is valid. If it is, we probably
             * have a scenario similar to one of the following:
             * [title] contains_any 'test' AND ... (values for contains_any are not wrapped in parenthesis)
             * ([title] contains_any 'test') ... (values for contains_any are not wrapped in parenthesis and we started with a parenthesis earlier that isn't the
             *                                    one which is supposed to close this multiple structure)
            */
            const tokenIsCloseParen: boolean = token === TokenizerSelectorTokenEnum.CloseParen;
            const startedWithOpenParen: boolean = multipleStructure.length > 0 && multipleStructure[0].token === TokenizerSelectorTokenEnum.OpenParen;
            if (decodingMultiple && (!this.tokenizerValidMultipleTypes.includes(token) || (tokenIsCloseParen && !startedWithOpenParen))) {
                if (this.checkMultipleStructure(multipleStructure)) {   // After determining if we've finished the structure by either of the scenarios discussed above
                    rowData.value = this.getMultipleStructureValue(multipleStructure);  // Go ahead and finish up the previous row and reset the multiple structure

                    if (rowData.isDefined) {
                        innerData.push(rowData);
                        rowData = new BasicQueryRow();
                    }

                    decodingMultiple = false;
                    multipleStructure = [];
                } else {
                    error = true;
                }
            }

            switch (token) {
                case TokenizerSelectorTokenEnum.OpenParen: {
                    unterminatedParenthesisCount++;
                    if (decodingMultiple) {
                        multipleStructure.push(tokenizer);
                    }
                    break;
                }
                case TokenizerSelectorTokenEnum.FunctionPrefix: {
                    tooComplex = true;
                    break;
                }
                case TokenizerSelectorTokenEnum.BLiteral: {
                    if (value === 'true' && response.selector.length === 2 && this.transformerMode) {
                        rowData.allFields = true;
                        innerData.push(rowData);
                        rowData = new BasicQueryRow();
                    } else {
                        tooComplex = true;
                    }
                    break;
                }
                case TokenizerSelectorTokenEnum.Variable: {
                    if (rowData.field === undefined) {
                        rowData.field = `[${value}]`;
                        selectorColumns.add(value);
                    } else {
                        if (decodingMultiple) {
                            tokenizer.value = `[${value}]`;
                            multipleStructure.push(tokenizer);
                        } else {
                            rowData.value = `[${value}]`;
                            if (rowData.isDefined) {
                                innerData.push(rowData);
                                rowData = new BasicQueryRow();
                            } else {
                                error = true;
                            }
                        }
                    }
                    break;
                }
                case TokenizerSelectorTokenEnum.Relop:
                case TokenizerSelectorTokenEnum.FunctionInfix: {
                    if (op) {
                        rowData.operator = value;
                        decodingMultiple = op.multiple;     // Set "multiple" state for later use
                    } else {
                        tooComplex = true;
                    }
                    break;
                }
                case TokenizerSelectorTokenEnum.FunctionOneside: {
                    if (op) {
                        rowData.operator = value;
                        if (op.hideValue) {
                            if (rowData.isDefined) {
                                innerData.push(rowData);
                                rowData = new BasicQueryRow();
                            } else {
                                error = true;
                            }
                        }
                    } else {
                        tooComplex = true;
                    }
                    break;
                }
                case TokenizerSelectorTokenEnum.Comma: {
                    if (decodingMultiple) {
                        multipleStructure.push(tokenizer);
                    } else {
                        tooComplex = true;
                    }
                    break;
                }
                case TokenizerSelectorTokenEnum.String:
                case TokenizerSelectorTokenEnum.Num: {
                    if (decodingMultiple) {
                        multipleStructure.push(tokenizer);
                    } else {
                        rowData.value = value;
                        if (rowData.isDefined) {
                            innerData.push(rowData);
                            rowData = new BasicQueryRow();
                        } else {
                            error = true;
                        }
                    }
                    break;
                }
                case TokenizerSelectorTokenEnum.And: {
                    if (rowData.isDefined) {
                        innerData.push(rowData);
                        rowData = new BasicQueryRow();
                    }
                    break;
                }
                case TokenizerSelectorTokenEnum.Or: {
                    if (unterminatedParenthesisCount > 0) {
                        tooComplex = true;
                        return;
                    }
                    if (rowData.isDefined) {
                        innerData.push(rowData);
                        rowData = new BasicQueryRow();
                    }
                    if (innerData.length === 0) {
                        error = true;
                    }
                    selectorData.push(innerData);
                    innerData = [];
                    break;
                }
                case TokenizerSelectorTokenEnum.CloseParen: {
                    // If we're in the middle of decoding multiple values, ensure we're adding a parenthesis only if we started with one
                    if (decodingMultiple) {
                        multipleStructure.push(tokenizer);                                      // Add this token, then
                        if (this.checkMultipleStructure(multipleStructure)) {                   // check that the structure is correct.
                            rowData.value = this.getMultipleStructureValue(multipleStructure);  // If it is, set the value data to be added to the row below
                        } else {
                            error = true;
                        }
                    }

                    if (rowData.isDefined) {
                        innerData.push(rowData);
                        rowData = new BasicQueryRow();
                    }
                    unterminatedParenthesisCount--;

                    if (decodingMultiple) {         // Don't forget to unset decodingMultiple state and multipleStructure data
                        decodingMultiple = false;
                        multipleStructure = [];
                    }
                    break;
                }
                case TokenizerSelectorTokenEnum.End: {
                    if (rowData.isDefined) {
                        innerData.push(rowData);
                        rowData = new BasicQueryRow();
                    }
                    if (innerData.length === 0 || rowData.isPartiallyDefined || unterminatedParenthesisCount !== 0) {
                        error = true;
                    }
                    selectorData.push(innerData);
                    innerData = [];
                    break;
                }
                case TokenizerSelectorTokenEnum.Error: {
                    error = true;
                    break;
                }
            }

            if (this.debug) {
                console.log('ending rowData:', rowData);
                console.log('ending innerData:', innerData);
                console.log(' ');
            }

            if (unterminatedParenthesisCount > 2) {
                tooComplex = true;
            }
        });

        if (this.transformerMode) {
            response.transformer.forEach((tokenizer: TokenizerTransformer) => {

                const token: TokenizerTransformerTokenEnum = tokenizer.token;
                const value: string = tokenizer.value;

                if (this.debug) {
                    console.log('starting transformerData:', transformerData);

                    console.log('token:', token);
                    console.log('value:', value);
                }

                switch (token) {
                    case TokenizerTransformerTokenEnum.Function:
                    case TokenizerTransformerTokenEnum.OpenParen:
                    case TokenizerTransformerTokenEnum.Comma:
                    case TokenizerTransformerTokenEnum.Addop:
                    case TokenizerTransformerTokenEnum.Mulop:
                    case TokenizerTransformerTokenEnum.CloseParen: {
                        tooComplex = true;
                        break;
                    }
                    case TokenizerTransformerTokenEnum.End: {
                        // Do nothing
                        break;
                    }
                    case TokenizerTransformerTokenEnum.Num:
                    case TokenizerTransformerTokenEnum.String: {
                        transformerData.push(new BasicTransformerField(value));
                        break;
                    }
                    case TokenizerTransformerTokenEnum.Variable: {
                        transformerData.push(new BasicTransformerField(`[${value}]`));
                        break;
                    }
                }

                if (this.debug) {
                    console.log('ending transformerData:', transformerData);
                    console.log(' ');
                }
            });
        }

        if (this.debug) {
            console.log('selectorData:', selectorData);
            console.log('transformerData:', transformerData);
        }

        if (tooComplex || error) {
            this.initializing = false;
            return {
                selector: this.defaultBasicQueryRow,
                transformer: this.defaultTransformerQueryRow,
                error: error,
                tooComplex: tooComplex,
                loading: false,
                queryType: queryType,
                transformerMode: this.transformerMode,
                selectorColumns: Array.from(selectorColumns)
            };
        }

        if (selectorData.length === 0) {
            selectorData.push([new BasicQueryRow()]);
        }
        if (this.transformerMode && transformerData.length === 0) {
            transformerData.push(new BasicTransformerField(null));
        }
        this.initializing = false;
        return {
            selector: selectorData,
            transformer: transformerData,
            error: error,
            tooComplex: tooComplex,
            loading: false,
            queryType: queryType,
            transformerMode: this.transformerMode,
            selectorColumns: Array.from(selectorColumns)
        };
    }

    /**
     * A function to check whether an array of tokenizers follow the expected structure for a "multiple" type operator.
     * Given a "ValueType" that could be either a string, number, or field...
     * structure should follow [OpenParen, ValueType, Commma, ValueType, Comma, ValueType, CloseParen], or only one ValueType
     * @param multipleStructure array of TokenizerSelectors to test against
     * @returns Whether or not the given array matches the expected structure
     */
    checkMultipleStructure(multipleStructure: TokenizerSelector[]): boolean {
        if (multipleStructure.length < 1 || multipleStructure.length === 2) {
            return false;
        } else if (multipleStructure.length === 1) {
            return this.tokenizerValueTypes.includes(multipleStructure[0].token);
        }
        return multipleStructure.every((tokenizer: TokenizerSelector, index: number, array: TokenizerSelector[]) => {
            if (!this.tokenizerValidMultipleTypes.includes(tokenizer.token)) {
                return false;   // Automatically reject anything that isn't one of the valid token types (isn't necessarily needed, but a good sanity check)
            }

            if (index === 0) {
                return tokenizer.token === TokenizerSelectorTokenEnum.OpenParen;
            } else if (index === array.length - 1) {
                return tokenizer.token === TokenizerSelectorTokenEnum.CloseParen;
            } else if (index % 2 !== 0) {   // Odd indices should be strings, numbers, or variables
                return this.tokenizerValueTypes.includes(tokenizer.token);
            } else {    // Even indices should all be commas to separate the values
                return tokenizer.token === TokenizerSelectorTokenEnum.Comma && index !== array.length - 2; // Values inside of parenthesis shouldn't end in a comma
            }
        });
    }

    getMultipleStructureValue(multipleStructure: TokenizerSelector[]): string[] {
        return multipleStructure
            .filter((el: TokenizerSelector, index: number, arr: TokenizerSelector[]) => arr.length === 1 ? true : index % 2 !== 0)  // Only convert "ValueType" indices, or first index if the length is 1
            .map((tokenizer: TokenizerSelector) => tokenizer.value);            // Map only value
    }

    simplifyQueryString(queryString: string): string {
        const unsimplified: string = queryString;

        Array.from(unsimplified).forEach((char: string) => {

        });

        return unsimplified;
    }

    simplifyTransformerString(transformerString: string): string {
        const unsimplified: string = transformerString;

        Array.from(unsimplified).forEach((char: string) => {

        });

        return unsimplified;
    }

}
