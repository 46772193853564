export default class FeedSupportStateService {
    static $inject = ['$cookies', '$q', '$log',  'fdxUtils',  'StoresDataService'];

    // Services
    $cookies = null;
    $q = null;
    $log = null;
    fdxUtils = null;
    StoresDataService = null;

    // Local Attributes
    stores = {};
    definitions = {};
    showNavigationSetting = true;
    singleTicketForbiddenFlag: boolean = false;

    constructor($cookies, $q, $log, fdxUtils, StoresDataService) {
        this.$cookies = $cookies;
        this.$q = $q;
        this.$log = $log;
        this.fdxUtils = fdxUtils;
        this.StoresDataService = StoresDataService;
    }

    getStores(accountId, effectiveUserId, forceClearCache) {
        const def = this.$q.defer();

        if(this.stores && this.stores[accountId] && !forceClearCache){
            def.resolve(this.stores[accountId]);
            return def.promise;
        }
        else{
            
            const promise = this.StoresDataService.retrieveStores(accountId, effectiveUserId);

            promise.then(
                (stores) => {
                    this.setStoreDefintion(accountId, stores[0]);
                    this.setStores(accountId, stores[1]);
                    def.resolve(this.stores[accountId]);
                },
                () => {
                    this.setStores(accountId, null);
                    def.reject(null);
                }

            );

            return def.promise;
        }
       
    }

    setStores(accountId, value) {
        this.stores[accountId] = value;
    }

    getStoreDefinition(accountId) {
        const def = this.$q.defer();

        if(this.definitions && this.definitions[accountId]){
            def.resolve(this.definitions[accountId]);
            return def.promise;
        }
        else{
            const promise = this.getStores(accountId, 0, true);

            promise.then(
                (stores) => {
                    def.resolve(this.definitions[accountId]);
                },
                () => {
                    def.reject(null);
                }
            );
            return def.promise;

        }

    }

    setStoreDefintion(accountId, value) {
        this.definitions[accountId] = value;
    }

    getTicketForbidden(): boolean {
        return this.singleTicketForbiddenFlag;
    }

    setTicketForbidden(value: boolean): void {
        this.singleTicketForbiddenFlag = value;
    }

}