import { IHttpService, IPromise } from 'angular';
import FdxError from '../models/FdxError';

interface FtpTriggersResponseDataModel {
    export_id: string;
    export_name: string;
    import_id: string;
    import_id_to_trigger: string;
    import_name: string;
    import_name_to_trigger: string;
    trigger_id: string;
}

export default class FtpTriggersDataService {
    static $inject = ['$http'];

    constructor(private $http: IHttpService) {}

    getTriggers(databaseId: string): IPromise<FtpTriggersResponseDataModel>  {
        return this.$http.get<FtpTriggersResponseDataModel>(`/api.php/dbs/${databaseId}/ftp_triggers`)
            .then(({ data }) => {
                return data;
            })
            .catch(({ data }) => {
                throw new FdxError(data.status, data.message);
            });
    }
}