import { OperatorConstants } from '@app/modules/inputs/constants/operator.constants';

export class BasicQueryRow {

    constructor() {}

    field: string;
    operator: string;
    value: string | string[];

    allFields: boolean = false;

    private get hideValue(): boolean {
        return OperatorConstants.allOperators[this.operator]?.hideValue;
    }

    private get multiple(): boolean {
        return OperatorConstants.allOperators[this.operator]?.multiple;
    }

    get isDefined(): boolean {
        if (this.hideValue) {
            return this.field !== undefined && this.operator !== undefined;
        }
        return this.field !== undefined && this.operator !== undefined && (this.multiple ? (this.value as string[]).length > 0 : this.value !== undefined);
    }

    get isPartiallyDefined(): boolean {
        if (this.hideValue) {
            return this.field !== undefined || this.operator !== undefined;
        }
        return this.field !== undefined || this.operator !== undefined || (this.multiple ? (this.value as string[]).length > 0 : this.value !== undefined);
    }
}
