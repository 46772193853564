/* eslint-disable @typescript-eslint/no-explicit-any */

export function prettyJSON(data: unknown): any {

    // If it's a string, then JSON parse
    if (typeof data === 'string') {
        try {
            data = JSON.parse(data);
        } catch(e) {
            // do nothing
        }
    }

    // If it's not an object at this point, then skip processing
    if (data === null || typeof data !== 'object') {
        return data;
    }

    let display: string = '';
    Object.keys(data).forEach((key: string) => {
        let value = data[key];
        if (typeof data[key] === 'object') {
            value = JSON.stringify(data[key]);
        }
        display = `${display}<strong>${key}</strong>: ${value}<br>`;
    });

    return display;
}
