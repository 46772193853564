import { ActivatedRouteSnapshot, BaseRouteReuseStrategy, Params, Route } from '@angular/router';

export type ReusableRoute = Omit<Route, 'data'> & {
    data?: {
        shouldReuseRoute?: boolean | ((a: ActivatedRouteSnapshot, b: ActivatedRouteSnapshot) => boolean);
    };
};

export class FdxRouteReuseStrategy extends BaseRouteReuseStrategy {
    /**
     * Only reuse the route if it is the same exact route and only the
     * query parameters are changing.
     * 
     * There has been bugs with changing url parameters with
     * page components not handling the mutating url parameters correctly.
     * 
     * Having the page completely remount if route parameters change
     * prevents those bugs.
     * 
     * However, it is not efficient to completely remount a page
     * component only if the query parameters change and it can lead
     * to bad user experience.
     * 
     * For this reason, if only the query parameters are changing, then
     * reuse the route and handle the mutating query params in the
     * component.
     * 
     * @param future future route snapshot
     * @param current current route snapshot
     * @returns boolean
     */
    public shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
        // child routes aren't the same - DO NOT reuse
        if (future.children.length !== current.children.length) {
            return false;
        }

        // check all the child routes
        for (let i = 0; i < future.children.length; i++) {
            const futureChildRoute = future.children[i];

            if (!futureChildRoute) {
                return false;
            }

            const currentChildRoute = current.children[i];

            if (!currentChildRoute) {
                return false;
            }

            if (this.shouldReuseRoute(futureChildRoute, currentChildRoute)) {
                return true;
            }
        }

        // these are not even the same route - definitely NO DO NOT reuse
        if (!this.sameRouteConfig(future, current)) {
            return false;
        }

        // if route is configured then use its config
        if ('shouldReuseRoute' in future.data && typeof (future.data.shouldReuseRoute) === 'boolean') {
            return future.data.shouldReuseRoute;
        }
        
        // if route has custom function to check then run it
        if ('shouldReuseRoute' in future.data && typeof (future.data.shouldReuseRoute) === 'function') {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
            return future.data.shouldReuseRoute(future, current);
        }

        // if route params are exactly the same & some query param exists then YES DO reuse
        return this.sameRouteParams(future, current) &&
            (
                this.hasQueryParam(future) ||
                this.hasQueryParam(current)
            );
    }

    private sameRouteConfig(a: ActivatedRouteSnapshot, b: ActivatedRouteSnapshot): boolean {
        return a.routeConfig === b.routeConfig;
    }

    private sameRouteParams(a: ActivatedRouteSnapshot, b: ActivatedRouteSnapshot): boolean {
        return this.paramsEqual(a.params, b.params);
    }

    private hasQueryParam(a: ActivatedRouteSnapshot): boolean {
        return a.queryParams && Object.entries(a.queryParams).length > 0;
    }

    private paramsEqual(a: Params, b: Params): boolean {
        if (a === b) {
            return true;
        }

        const aEntries = Object.entries(a);
        const bEntries = Object.entries(b);

        if (aEntries.length !== bEntries.length) {
            return false;
        }

        for (const [key, value] of aEntries) {
            if (b[key] !== value) {
                return false;
            }
        }

        return true;
    }
}
