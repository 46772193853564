export default class UserAccessService {

    static $inject = [
        '$http',
        '$q',
    ];

    constructor(
        private $http,
        private $q
    ) { }

    getUserById(idAccount: number, idUser: number) {

        const def = this.$q.defer();

        const promise = this.$http.get(`/api.php/accounts/${idAccount}/user_account_permissions/${idUser}`);

        promise.then(
            (response) => {
                def.resolve(response.data);
            },
            (response) => {
                def.reject(response);
            }
        );

        return def.promise;
    }

}