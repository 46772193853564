<ng-container [formGroup]="group">
    <fdx-form-field
        [classes]="{'d-flex mb-2': true}"
        formControlName="channel"
        [form]="form" >
        <fdx-label [ngClass]="labelClass" labelText="Channel" [classes]="{'keep-padding': true}">Channel<span class="text-danger ps-1">*</span></fdx-label>
        <fdx-input-wrapper [ngClass]="inputClass">
            <ng-select
                formControlName="channel"
                id="channel"
                labelForId="channel"
                placeholder="Select"
                [items]="channels$ | async"
                bindLabel="display"
                [clearable]="false">
            </ng-select>
        </fdx-input-wrapper>
    </fdx-form-field>
    <fdx-form-field
        [hidden]="countries.length <= 1"
        [classes]="{'d-flex mb-2': true}"
        formControlName="country"
        [form]="form" >
        <fdx-label [ngClass]="labelClass" labelText="Country" [classes]="{'keep-padding': true}">Country<span class="text-danger ps-1">*</span></fdx-label>
        <fdx-input-wrapper [ngClass]="inputClass">
            <ng-select
                #countrySelect
                formControlName="country"
                id="country"
                labelForId="country"
                placeholder="Select"
                [items]="countries"
                bindLabel="countryName"
                [clearable]="false">
                <ng-template ng-option-tmp let-item="item">
                    {{ item.countryName }}
                    <span class="text-secondary" *ngIf="item.displayLanguage">({{ item.languageName }})</span>
                </ng-template>
            </ng-select>
        </fdx-input-wrapper>
    </fdx-form-field>
</ng-container>
