/* eslint-disable @typescript-eslint/no-explicit-any */

import { Inject, Injectable } from '@angular/core';
import { CONSOLE } from '@app/core/providers/console.provider';

@Injectable({
    providedIn: 'root'
})
export class LogService {
    constructor(@Inject(CONSOLE) private readonly console: Console) { }

    public log(message?: any, ...optionalParams: any[]): void {
        this.console.log(message, optionalParams);
    }

    public info(message?: any, ...optionalParams: any[]): void {
        this.console.info(message, optionalParams);
    }

    public warn(message?: any, ...optionalParams: any[]): void {
        this.console.warn(message, optionalParams);
    }

    public error(message?: any, ...optionalParams: any[]): void {
        this.console.error(message, optionalParams);
    }

    public debug(message?: any, ...optionalParams: any[]): void {
        this.console.debug(message, optionalParams);
    }
}
