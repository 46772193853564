// TODO: consider making Popup it's own service if used for more than one thing.
class Popup {
  
  _popup = null;
  open(url) {
    this.close();

    this._popup = window.open(url || '', 'social', 'width=640, height=480, left=0, top=0, toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no');
    if (!url) {
      this._popup.document.body.innerHTML = '<h1>Processing...</h1><h3>Please do not close this window!</h3>';
    }
  }
  close() {
    if (this._popup) {
      this._popup.close();
      this._popup = null;
    }
  }
  navigate(url) {
    if (this._popup) {
      this._popup.location.href = url;
      this._popup.focus();
    }
  }
}
export default class FeedWizardService {

  popup = new Popup();

  hex2bin(hex) {
    var bytes = [];

    for(var i=0; i< hex.length-1; i+=2) {
      bytes.push(parseInt(hex.substr(i, 2), 16));
    }

    return String.fromCharCode.apply(String, bytes);
  }

}

FeedWizardService.$inject = [];