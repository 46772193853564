<fdx-style-wrapper>
    <block-ui>
        <div
            class='app'
            [ngClass]="{ 'view-height': viewHeight }">
            <fdx-app-main-menu *ngIf="!showEbayUI; else ebay"></fdx-app-main-menu>

            <ng-template #ebay>
                <fdx-app-ebay-menu></fdx-app-ebay-menu>
            </ng-template>

            <router-outlet></router-outlet>
        </div>
        <div id="bs5-container"></div>
    </block-ui>
</fdx-style-wrapper>
