<!-- eslint-disable @angular-eslint/template/no-inline-styles -->
<div [formGroup]="readonlyTransformerForm" #form="ngForm" [ngClass]="{'d-flex': hasFieldName, 'gap-3': hasFieldName, 'row': !hasFieldName}">
    <fdx-form-field
        formControlName="selector"
        [form]="form"
        [ngStyle]="formFieldStyle"
        [ngClass]="{'fdx-flex-1': hasFieldName, 'col-6': !hasFieldName}"
        [classes]="formFieldClasses" >
        <fdx-label [classes]="{'text-nowrap': true}">If</fdx-label>
        <fdx-input-wrapper
            [ngStyle]="inputWrapperStyle"
            [ngClass]="{'fdx-flex-1': hasFieldName}"
            [classes]="{'form-control': false}" >
            <fdx-collapsible-code
                [content]="transformer.selector" [readonly]="true">
                <fdx-code-input
                    *ngIf="!deactivateCodeMirror"
                    [fields]="dbFields"
                    [options]="codeMirrorOptions"
                    id="selector"
                    formControlName="selector"
                    className="readonly" >
                </fdx-code-input>
            </fdx-collapsible-code>
        </fdx-input-wrapper>
    </fdx-form-field>
    <fdx-form-field
        formControlName="transformer"
        [form]="form"
        [ngStyle]="formFieldStyle"
        [ngClass]="{'fdx-flex-1': hasFieldName, 'col-6': !hasFieldName}"
        [classes]="formFieldClasses" >
        <fdx-label [classes]="{'text-nowrap': true}"><span [innerHtml]="thenLabel"></span></fdx-label>
        <fdx-input-wrapper
            labelText="Then"
            [ngStyle]="inputWrapperStyle"
            [ngClass]="{'fdx-flex-1': hasFieldName}"
            [classes]="{'form-control': false}">
            <fdx-collapsible-code
                [content]="transformer.transformer" [readonly]="true">
                <fdx-code-input
                    *ngIf="!deactivateCodeMirror"
                    [fields]="dbFields"
                    [options]="codeMirrorOptions"
                    id="transformer"
                    formControlName="transformer"
                    className="readonly" >
                </fdx-code-input>
            </fdx-collapsible-code>
        </fdx-input-wrapper>
    </fdx-form-field>
</div>
