import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreLegacyModule } from '@app/core-legacy/core-legacy.module';
import { AlertsModule } from '@app/modules/alerts/alerts.module';
import { CheatSheetModule } from '@app/modules/cheat-sheet/cheat-sheet.module';
import { DirectivesModule } from '@app/modules/directives/directives.module';
import { InputsModule } from '@app/modules/inputs/inputs.module';
import { ModalsModule } from '@app/modules/modals/modals.module';
import { OffcanvasModule } from '@app/modules/offcanvas/offcanvas.module';
import { PageModule } from '@app/modules/page/page.module';
import { PaginationModule } from '@app/modules/pagination/pagination.module';
import { PipesModule } from '@app/modules/pipes/pipes.module';
import { StickyHeaderComponent } from '@app/standalone-components/sticky-header/sticky-header.component';
import { TransformerFormModule } from '@app/transformer-form/transformer-form.module';
import { TransformersDeleteModalComponent } from '@app/transformers/components/transformers-delete-modal/transformers-delete-modal.component';
import { TransformersDownloadModal } from '@app/transformers/components/transformers-download-modal/transformers-download-modal.component';
import { TransformersFaqModalComponent } from '@app/transformers/components/transformers-faq-modal/transformers-faq-modal.component';
import { TransformersFieldDependencyTreeModalComponent } from '@app/transformers/components/transformers-field-dependency-tree-modal/transformers-field-dependency-tree-modal.component';
import { TransformersBulkImportPageComponent } from '@app/transformers/pages/transformers-bulk-import-page/transformers-bulk-import-page.component';
import { TransformersFieldOrderPageComponent } from '@app/transformers/pages/transformers-field-order-page/transformers-field-order-page.component';
import { TransformersPageComponent } from '@app/transformers/pages/transformers-page/transformers-page.component';
import { EmptyStateModule } from '@feedonomics/frontend-components';
import { NgSelectModule } from '@feedonomics/ng-select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbAccordionModule, NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AutosizeModule } from 'ngx-autosize';

@NgModule({
    declarations: [
        TransformersBulkImportPageComponent,
        TransformersDeleteModalComponent,
        TransformersDownloadModal,
        TransformersFaqModalComponent,
        TransformersFieldDependencyTreeModalComponent,
        TransformersFieldOrderPageComponent,
        TransformersPageComponent
    ],
    imports: [
        AlertsModule,
        AutosizeModule,
        CheatSheetModule,
        CommonModule,
        CoreLegacyModule,
        DirectivesModule,
        DragDropModule,
        EmptyStateModule,
        FontAwesomeModule,
        FormsModule,
        InputsModule,
        ModalsModule,
        NgbAccordionModule,
        NgbDropdownModule,
        NgbTooltipModule,
        NgSelectModule,
        OffcanvasModule,
        PageModule,
        PaginationModule,
        PipesModule,
        ReactiveFormsModule,
        StickyHeaderComponent,
        TransformerFormModule
    ],
    exports: [
        TransformersBulkImportPageComponent,
        TransformersDeleteModalComponent,
        TransformersDownloadModal,
        TransformersFaqModalComponent,
        TransformersFieldDependencyTreeModalComponent,
        TransformersFieldOrderPageComponent,
        TransformersPageComponent
    ]
})
export class TransformersModule { }
