import { OperatorType } from '@app/modules/inputs/types/operator.type';

enum OperatorGroups {
    Empty = 'Empty',
    Equal = 'Equal',
    Contains = 'Contains',
    BeginsWith = 'Begins With',
    EndsWith = 'Ends With',
    Operations = 'Operations'
}

export class OperatorConstants {

    /* Start Empty */
    public static is_empty: OperatorType = {
        group: OperatorGroups.Empty,
        operator: 'is_empty',
        hideValue: true
    };
    public static is_not_empty: OperatorType = {
        group: OperatorGroups.Empty,
        operator: 'is_not_empty',
        hideValue: true
    };
    /* End Empty */

    /* Start Equal */
    public static equal: OperatorType = {
        group: OperatorGroups.Equal,
        operator: 'equal'
    };

    public static equal_any: OperatorType = {
        group: OperatorGroups.Equal,
        operator: 'equal_any',
        multiple: true,
    };

    public static equal_s: OperatorType = {
        group: OperatorGroups.Equal,
        operator: 'equal_s',
        caseSensitive: true
    };

    public static not_equal: OperatorType = {
        group: OperatorGroups.Equal,
        operator: 'not_equal'
    };
    /* End Equal */


    /* Start Contains */
    public static contains: OperatorType = {
        group: OperatorGroups.Contains,
        operator: 'contains'
    };

    public static contains_any: OperatorType = {
        group: OperatorGroups.Contains,
        operator: 'contains_any',
        multiple: true,
    };

    public static contains_all: OperatorType = {
        group: OperatorGroups.Contains,
        operator: 'contains_all',
        multiple: true,
    };

    public static contains_s: OperatorType = {
        group: OperatorGroups.Contains,
        operator: 'contains_s',
        caseSensitive: true
    };

    public static contains_word: OperatorType = {
        group: OperatorGroups.Contains,
        operator: 'contains_word'
    };

    public static contains_words: OperatorType = {
        group: OperatorGroups.Contains,
        operator: 'contains_words'
    };

    public static not_contains: OperatorType = {
        group: OperatorGroups.Contains,
        operator: 'not_contains'
    };

    public static not_contains_s: OperatorType = {
        group: OperatorGroups.Contains,
        operator: 'not_contains_s',
        caseSensitive: true
    };
    /* End Contains */


    /* Start Begins With */
    public static begins_with: OperatorType = {
        group: OperatorGroups.BeginsWith,
        operator: 'begins_with'
    };

    public static not_begins_with: OperatorType = {
        group: OperatorGroups.BeginsWith,
        operator: 'not_begins_with'
    };
    /* End Begins With */


    /* Start Ends With */
    public static ends_with: OperatorType = {
        group: OperatorGroups.EndsWith,
        operator: 'ends_with'
    };

    public static not_ends_with: OperatorType = {
        group: OperatorGroups.EndsWith,
        operator: 'not_ends_with'
    };
    /* End Ends With */


    /* Start Operations */
    public static less_than: OperatorType = {
        group: OperatorGroups.Operations,
        operator: '<'
    };

    public static greater_than: OperatorType = {
        group: OperatorGroups.Operations,
        operator: '>'
    };
    /* End Operations */

    public static allOperators: Record<string, OperatorType> = {
        [this.is_empty.operator]: this.is_empty,
        [this.is_not_empty.operator]: this.is_not_empty,

        [this.equal.operator]: this.equal,
        [this.equal_any.operator]: this.equal_any,
        [this.equal_s.operator]: this.equal_s,
        [this.not_equal.operator]: this.not_equal,

        [this.contains.operator]: this.contains,
        [this.contains_any.operator]: this.contains_any,
        [this.contains_all.operator]: this.contains_all,
        [this.contains_s.operator]: this.contains_s,
        [this.contains_word.operator]: this.contains_word,
        [this.contains_words.operator]: this.contains_words,
        [this.not_contains.operator]: this.not_contains,
        [this.not_contains_s.operator]: this.not_contains_s,

        [this.begins_with.operator]: this.begins_with,
        [this.not_begins_with.operator]: this.not_begins_with,

        [this.ends_with.operator]: this.ends_with,
        [this.not_ends_with.operator]: this.not_ends_with,

        [this.less_than.operator]: this.less_than,
        [this.greater_than.operator]: this.greater_than,
    };
}
