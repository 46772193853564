import { Component } from '@angular/core';
import { BootstrapThemeProp } from '@app/core/services/bootstrap-theme.service';
import { AdditionalModalDismissReasons } from '@app/modules/modals/enums/additional-modal-dismiss-reasons';
import { ConfirmationModalResolver } from '@app/modules/modals/interfaces/confirmation-modal-resolver.interface';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Modal } from '../../interfaces/modal.interface';

@Component({
    selector: 'fdx-confirmation-modal',
    templateUrl: './confirmation-modal.component.html'
})
export class ConfirmationModalComponent implements Modal {
    public modalTheme: BootstrapThemeProp = 'primary';
    public title: string = 'Are you sure?';
    public body: string = '';
    public confirmText: string = 'Ok';
    public cancelText: string = 'Cancel';

    constructor(private readonly modal: NgbActiveModal) { }

    public onModalInit(resolve: ConfirmationModalResolver): void {
        if (resolve.modalTheme) {
            this.modalTheme = resolve.modalTheme;
        }

        if (resolve.title) {
            this.title = resolve.title;
        }

        if (resolve.body) {
            this.body = resolve.body;
        }

        if (resolve.confirmText) {
            this.confirmText = resolve.confirmText;
        }

        if (resolve.cancelText) {
            this.cancelText = resolve.cancelText;
        }
    }

    public onModalSubmit(_event: MouseEvent): void {
        this.modal.close();
    }

    public onModalCancelByX(_event: MouseEvent): void {
        this.modal.dismiss(AdditionalModalDismissReasons.X_CLICK);
    }

    public onModalCancelByButton(_event: MouseEvent): void {
        this.modal.dismiss(AdditionalModalDismissReasons.CANCEL_CLICK);
    }

}
