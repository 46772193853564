import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { BaseExportFormComponent } from '@app/exports/components/base-export-form/base-export-form.component';
import { ExistingExport } from '@app/exports/models/interfaces/existing-export.interface';
import { AggregatedDbFields } from '@app/exports/services/code-input.service';
import { CodeMirrorModes } from '@app/modules/inputs/enums/code-mirror-modes.enum';
import { CodeMirrorThemes } from '@app/modules/inputs/enums/code-mirror-themes.enum';

@Component({
    selector: 'fdx-export-if-form',
    styleUrls: ['export-if-form.component.scss'],
    templateUrl: 'export-if-form.component.html'
})
export class ExportIfFormComponent extends BaseExportFormComponent implements OnInit {
    @Input() dbFields: AggregatedDbFields;

    codeMirrorOptions = {
        mode: CodeMirrorModes.Transformer,
        theme: CodeMirrorThemes.Transformer,
        extraKeys: {
            'Tab': false,
            'Shift-Tab': false
        }
    };

    constructor(
        private readonly fb: UntypedFormBuilder
    ) {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const dbFields = changes.dbFields;
        const didDbFieldsChange = dbFields?.currentValue && dbFields.currentValue !== dbFields.previousValue;

        if (didDbFieldsChange) {
            this.dbFields = dbFields.currentValue;
        }
        super.ngOnChanges(changes);
    }

    protected initForm(): void {
        this.form = this.fb.group({
            exportSelector: [this.exportItem?.export_selector ?? 'true'],
            threshold: [this.exportItem?.threshold ?? '0']
        });
    }

    protected patchForm(exportItem: ExistingExport): void {
        this.patchValue({
            exportSelector: exportItem.export_selector,
            threshold: exportItem.threshold
        });
    }
}
