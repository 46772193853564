export default function() {
    return function(arr) {
		if (!Array.isArray(arr)) {
			return arr;
		}

        return arr.filter((value, index, self) => {
			return self.indexOf(value) === index;
		});
    };
}