<ng-template #searchInput>
    <div
        class="position-relative"
        [ngClass]="searchBarClasses">
        <fa-icon
            class="text-secondary position-absolute"
            [icon]="searchIcon"
            [disablePopover]="isPopoverDisabled"
            [ngbPopover]="popoverBody"
            [popoverTitle]="popoverTitle"
            triggers="mouseenter:mouseleave"
            placement="bottom">
        </fa-icon>
        <input
            #input
            type="search"
            name="filterInput"
            class="form-control"
            [formControl]="search"
            [placeholder]="placeholder"
            [ngStyle]="searchBarInputStyles"
            (keydown.enter)="onSearchEnterKeyDown($event)">
        <div
            class="col-md-12 pt-4"
            *ngIf="showResults">
            <div
                *ngIf="resultsFound === 1; else resultIsNotOne"
                class="position-absolute bottom-0 end-0 text-muted">
                {{resultsFound}} result found
            </div>
            <ng-template #resultIsNotOne>
                <div
                    *ngIf="resultsFound === 0; else resultIsNotZero"
                    class="position-absolute bottom-0 end-0 text-muted">
                    No results found
                </div>
            </ng-template>
            <ng-template #resultIsNotZero>
                <div
                    *ngIf="resultsFound !== null"
                    class="position-absolute bottom-0 end-0 text-muted">
                    {{resultsFound}} results found
                </div>
            </ng-template>
        </div>
    </div>
</ng-template>

<ng-container *ngIf="isSearchInputGroup; else notGroup">
    <div
        fdxInputGroupAddons
        [leftAddons]="leftAddons"
        [input]="searchInput"
        [rightAddons]="useSearchButton ? [searchButton] : []">
    </div>
</ng-container>

<ng-template #searchButton>
    <button
        class="btn btn-outline-secondary"
        type="button"
        (click)="onSearchButtonClick()">
        {{ searchButtonString ?? 'Search'}}
    </button>
</ng-template>

<ng-template #notGroup>
    <ng-container *ngTemplateOutlet="searchInput"></ng-container>
</ng-template>
