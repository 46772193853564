import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { API_TIMEZONE } from '@app/core/models/constants/date-time';
import { ScheduleHoursToDisplay } from '@app/core/models/interfaces/schedule-hours-to-display.interface';
import { CronService } from '@app/modules/cron/services/cron.service';
import { ClassInput } from '@feedonomics/frontend-components';

interface HoursInterface {
    server: string;
    local: string;
}

@Component({
    selector: 'fdx-cron-viewer',
    templateUrl: './cron-viewer.component.html',
    styleUrls: ['./cron-viewer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CronViewerComponent implements OnChanges {
    @Input() classes: ClassInput;
    @Input() cron: string | null = null;
    @Input() cronTimezone: string | null = null;
    @Input() displaySettingsTimeZone: boolean = false;
    @Input() schedulePaused: boolean = false;

    scheduleHoursToDisplay: ScheduleHoursToDisplay[] = [];

    hours: HoursInterface[] = [];

    constructor(
        private readonly cronService: CronService
    ) {

    }

    ngOnChanges(): void {

        this.hours = [];

        this.processCron();

    }

    private processCron(): void {

        if (this.cron === null) {
            return;
        }

        if (this.cronTimezone === null) {
            this.scheduleHoursToDisplay = this.cronService.getScheduleHoursToDisplay(this.cron, API_TIMEZONE);
        } else {
            this.scheduleHoursToDisplay = this.cronService.getScheduleHoursToDisplay(this.cron, this.cronTimezone);
        }

        for (const schedule of this.scheduleHoursToDisplay) {

            const hour: HoursInterface = {
                local: `${schedule.userTime.timeToDisplay} ${schedule.userTime.abbreviatedTimeZoneName}`,
                server: `${schedule.apiTime.timeToDisplay} ${schedule.apiTime.abbreviatedTimeZoneName}`
            }

            if (!this.schedulePaused) {
                if (schedule.userTime.dayBefore) {
                    hour.server += ' (next day)';
                }
    
                if (schedule.apiTime.dayBefore) {
                    hour.server += ' (prev day)';
                }
            }
            

            this.hours.push(hour);

        }

    }

    getScheduledDays(): string {

        if (!this.cron) {
            return '-';
        }

        return this.cronService.getScheduledDays(this.cron, this.cronTimezone);

    }

    trackByValue(index: number, value: HoursInterface): string {
        return value.server;
    }

}
