import { Injectable } from '@angular/core';
import { DatabasesDataService } from '../../databases/services/databases-data.service';

export interface AggregatedDbFields {
    autocompleteFields: string[];
    validFields: string[];
}

@Injectable({
    providedIn: 'root'
})
export class CodeInputService {
    constructor(
        private databasesDataService: DatabasesDataService
    ) { }

    aggregateDbFields(dbFields: any): AggregatedDbFields {
        const aggregatedDbFields: AggregatedDbFields = { autocompleteFields: [], validFields: [] };
        for(const field of dbFields) {
            aggregatedDbFields.autocompleteFields.push(`[${field.field_name}]`);
            aggregatedDbFields.validFields.push(field.field_name);
        }
        return aggregatedDbFields;
    }
}
