import { Component, Input } from '@angular/core';
import { CategoryPath } from '@app/category-taxonomy/services/responses/category-paths.response';

@Component({
    selector: 'fdx-category-paths',
    templateUrl: './category-paths.component.html',
    styleUrls: ['./category-paths.component.scss']
})
export class CategoryPathsComponent {

    @Input() categoryPaths: CategoryPath[];
    @Input() dropdownDisplay: boolean = false;

    trackByIndex(index: number): number {
        return index;
    }
}
