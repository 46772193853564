<modal-hoc
    modalTitle="Simulated date/time"
    submitText="Export"
    (modalSubmit)="onModalSubmit($event)"
    (modalCancel)="onModalCancel($event)">
    <form [formGroup]="simulatedDateTimeForm" #form="ngForm">
        <h4>Timezone: Eastern</h4>
        <p>
            <strong>Note:</strong> This functionality only provides the ability to look at the current data while simulating
            the chosen time in any instance of <strong>current_time()</strong> in your transformers.
            <strong>It does not provide previous feeds or downloads.</strong>
        </p>
        <section>
            <fdx-form-field
                formControlName="simulatedDate"
                [form]="form" >
                <fdx-input inputType="datetime-local" labelText="Simulated date/time"></fdx-input>
            </fdx-form-field>
        </section>
    </form>
</modal-hoc>
