import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { CommonModule, NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Params, RouterModule } from '@angular/router';
import { ClassInput } from '@feedonomics/frontend-components';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconDefinition, faUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';

export type LinkCellRendererParams<T = unknown, V = number> = ICellRendererParams<T, V> & ILinkCellRendererParams;

export interface ILinkCellRendererParams {
    classes?: ClassInput;
    icon?: IconDefinition;
    newTab?: boolean;
    routerLink?: unknown[];
    externalLink?: string;
    queryParams?: Params;
}

/**
 * Use `ChangeDetectionStrategy.OnPush` for faster performance.
 * Call `this.changeDetectorRef.markForCheck();` in `agInit()` and `refresh()` methods.
 */
@Component({
    standalone: true,
    selector: 'fdx-link-cell-renderer',
    templateUrl: './link-cell-renderer.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        FontAwesomeModule,
        NgClass,
        NgIf,
        RouterModule
    ]
})
export class LinkCellRendererComponent implements ICellRendererAngularComp {
    public params: LinkCellRendererParams;
    public cellValue!: string;

    readonly newTabIcon: IconDefinition = faUpRightFromSquare;

    get target(): '_blank' | null {
        return this.params?.newTab ? '_blank' : null;
    }

    constructor(private readonly changeDetectorRef: ChangeDetectorRef) { }

    public agInit(params: LinkCellRendererParams): void {
        this.bindParams(params);
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Returning true tells AG Grid not to destroy and recreate the cell renderer component.
     * When returning true, you must handle changes to `params` yourself.
     * @param params cell params
     * @returns true to handle refresh logic yourself in Angular or false for AG Grid to destroy and recreate the component
     */
    public refresh(params: LinkCellRendererParams): boolean {
        this.bindParams(params);
        this.changeDetectorRef.markForCheck();
        return true;
    }

    private bindParams(params: LinkCellRendererParams): void {
        this.cellValue = this.getValueToDisplay(params);
        this.params = params;

        if (params?.newTab && !params?.icon) {
            this.params.icon = this.newTabIcon;
        }
    }

    private getValueToDisplay(params: LinkCellRendererParams): string {
        return params.valueFormatted ? params.valueFormatted : params.value.toString();
    }
}
