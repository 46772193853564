/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterContentInit, Component, ContentChild, Host, Input, OnChanges, OnDestroy, OnInit, Optional, SimpleChanges, SkipSelf, forwardRef } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormGroupDirective, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { LogService } from '@app/core/services/log.service';
import { BaseInputComponent } from '@app/modules/inputs/components/base-input/base-input.component';
import { LabelComponent } from '@app/modules/inputs/components/label/label.component';
import { Subject, takeUntil, tap } from 'rxjs';

/**
 * Documentation: https://feedonomics.atlassian.net/l/c/iwGK6PC4
 *
 * Currently supports only input, textarea, and ng-select input groups with left and/or right content
 * https://getbootstrap.com/docs/5.0/forms/input-group/
 */

@Component({
    selector: 'fdx-form-field',
    templateUrl: './form-field.component.html',
    styleUrls: ['./form-field.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FormFieldComponent),
            multi: true
        }
    ],
    viewProviders: [
        {
            provide: ControlContainer,
            useExisting: FormGroupDirective,
            useFactory: (container: ControlContainer): ControlContainer => container,
            deps: [[new SkipSelf(), ControlContainer]]
        }
    ]
})
export class FormFieldComponent implements OnInit, AfterContentInit, OnDestroy, OnChanges, ControlValueAccessor {

    /**
     * The name of the control
     */
    @Input({required: true}) formControlName: string;
    /**
     * @deprecated
     * The FormGroupDirective in the form tag (#form="ngForm"). This is no longer needed because
     * we use controlContainer to grab the FormGroupDirective instead.
     * TODO task: https://feedonomics.atlassian.net/browse/FP-10526
     */
    @Input() form: FormGroupDirective;

    /**
     * Holds the initial value of the control while content is initializing
     */
    pendingValue: any;
    /**
     * Holds the initial disabled state of the control while content is initializing
     */
    pendingDisabled: boolean;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private onChange?: (val: any) => any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private onTouched?: () => any;

    control: UntypedFormControl;

    @ContentChild(LabelComponent) private readonly label?: LabelComponent;
    @ContentChild(BaseInputComponent) private readonly input: BaseInputComponent;

    @Input() classes: Record<string, boolean> = {};     // The classes for the inner form-field element
    @Input() styles: Record<string, any> = {};          // The styles for the inner form-field element

    readonly unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        @Optional() @Host() @SkipSelf() public readonly controlContainer: FormGroupDirective,
        private readonly logService: LogService
    ) {}

    ngOnInit(): void {
        this.validateConfiguration();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.controlContainer && (changes.formControlName || changes.controlName)) {
            this.control = this.controlContainer.form.get(this.formControlName) as UntypedFormControl;
            this.input?.attachFormField(this);
        }

        if (changes.input && this.input && this.input.tooltipValidation && this.control) {
            this.control.statusChanges.pipe(
                tap(() => this.toggleErrorMargin()),
                takeUntil(this.unsubscribe$)
            ).subscribe();
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    ngAfterContentInit(): void {
        if (!this.input) {
            this.logService.error('An input of parent type BaseInputComponent is required inside of a form-field component.');
        }

        // Include null values by only checking for undefined
        if (this.pendingValue !== undefined) {
            this.input.setValue(this.pendingValue);
        }

        if (this.pendingDisabled !== undefined) {
            this.input.setDisabled(this.pendingDisabled);
        }

        if (this.input.inputType === 'checkbox' || this.input.inputType === 'radio') {
            // form-check must be placed on wrapping div if using horizontal form;
            if (this.classes['row']) {
                this.classes['form-check'] = false;
            } else if (this.classes['form-check'] === undefined) {
                this.classes['form-check'] = true;
            }
        }

        if (this.label) {
            this.label.input = this.input;
            this.input.ariaLabel = false;
        } else {
            this.input.ariaLabel = true;
        }

        this.input.attachFormField(this);
    }

    writeValue(obj: any): void {
        if (this.input) {
            this.input.setValue(obj);
        } else {
            this.pendingValue = obj;
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public handleChange(val: any): void {
        if (typeof this.onChange === 'function') {
            this.onChange(val);
        }
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public handleTouched(): void {
        if (typeof this.onTouched === 'function') {
            this.onTouched();
        }
    }

    setDisabledState(isDisabled: boolean): void {
        if (this.input) {
            this.input.setDisabled(isDisabled);
        } else {
            this.pendingDisabled = isDisabled;
        }
    }

    private toggleErrorMargin(): void {
        if (this.control.errors) {
            this.classes['tooltip-margin-bottom'] = true;
            this.classes['position-relative'] = true;
        } else {
            this.classes['tooltip-margin-bottom'] = false;
            this.classes['position-relative'] = false;
        }
    }

    private validateConfiguration(): string[] {
        const errors: string[] = [];

        if (this.formControlName === null || this.formControlName === undefined) {
            errors.push('must define formControlName for a form-field');
        }

        errors.forEach((error) => {
            this.logService.error(error);
        });

        return errors;
    }
}
