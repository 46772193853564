<!-- TODO update HTML -->

<modal-hoc
    modalTitle="Transformers FAQ"
    [submitButton]="false"
    [cancelButton]="false"
    (modalCancel)="onModalCancel($event)">
    <h4>
        <a href="/help/transformers.html" target="_blank">
            See Some Transformer Examples!
        </a>
    </h4>
    <p>Transformers allow you to add IF and THEN rules to optimize your raw data into transformed data.</p>
    <h5>Choose Variable</h5>
    <section class="pad-left">
        <p>
            This is the variable you'd like to see transformers for.
        </p>
    </section>
    <h5>Change Field Order</h5>
    <section class="pad-left">
        <p>
            Transformers are executed in the order displayed in the Field Selector.<br><br>

            Sometimes, you need one field's transformers to execute before another field's transformers. This can be
            useful, if for example you generate a [material] field from the [description], and then use that [material]
            field to generate the [title]. In this case, you need to move the [title] field below the [material]
            field.<br><br>
            First choose the [title] field in the Choose Field selector<br>
            If the [title] field is already below [material], you're done.<br>
            If it's not, Change Field Order and click on [material].<br>
            [title] should now be below [material] in the selector.
        </p>
    </section>
    <h5>If Conditions</h5>
    <hr>
    <section class="pad-left">
        <p>
            The If condition governs the circumstances under which a Then statement will execute.<br>
            If the If condition is true, the Then statement will execute for a given product.
        </p>

        <h5>Examples</h5>
        <div class="pad-left">
            <p>All Products</p>
            <div class="form-group">
                <textarea class="form-control" style="height: 54px">true</textarea>
            </div>
            <p>
                [price] is between 200 and 300 <br>
                and the [title] contains flower or begins with tulip
            </p>
            <div class="form-group">
                <textarea class="form-control" style="height: 194px">[price] &gt; 200
                    AND [price] &lt; 300
                    AND
                    (
                    [title] contains 'flower'
                    OR
                    [title] begins_with 'tulip'
                    )
                </textarea>
            </div>
            <p>
                Item is currently on sale<br>
                Which means that the current_time is before the end of the sale, and the current_time is after the
                beginning of the sale
            </p>
            <div class="form-group">
                <textarea class="form-control" style="height: 94px">[sale_end_date] after current_time()
                    AND
                    [sale_start_date] before current_time()
                </textarea>
            </div>
        </div>
    </section>
    <h5>Then Statements</h5>
    <hr>
    <section class="pad-left">
        <p>
            Then statements allow you to change variables in programmatic ways. You can reference any variable in the
            feed in a Then statement. With Then statements, you can do things like replace all instances of 'dress' with
            'evening gown' in the title variable<br><br>

            All elements in a Then statement are concatenated together to produce the new variable value.
        </p>

        <h5>Example</h5>
        <div class="pad-left">
            <!-- Product Example -->
            <p>Imagine a product with the following fields:</p>
            <table class="table fixed-table">
                <tr>
                    <td class="col-md-3">brand</td>
                    <td class="col-md-9">nike</td>
                </tr>
                <tr>
                    <td class="col-md-3">title</td>
                    <td class="col-md-9">running shoe</td>
                </tr>
                <tr>
                    <td class="col-md-3">color</td>
                    <td class="col-md-9">red</td>
                </tr>
                <!--
				<tr>
					<td class="col-md-3">description</td>
					<td class="col-md-9">This red shoe is incredibly comfortable</td>
				</tr>
				-->
            </table>
            <br>

            <!-- Field Selector -->
            <div class="row">
                <div class="col-md-offset-3 col-md-6 form-horizontal">
                    <div class="form-group">
                        <label class="col-md-4 control-label">Choose Field</label>
                        <div class="col-md-8">
                            <select class="form-control">
                                <option>title</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Transformer -->
            <div class="row">
                <div class="col-md-10">
                    <div class="form-group">
                        <textarea class="form-control" style="height: 54px">[brand] ' - ' [color] ' ' [title]</textarea>
                    </div>
                </div>
                <div class="col-md-2">
                    <select>
                        <option>0</option>
                    </select>
                </div>
            </div>
            <p>The title is now: nike - red running shoe</p>

            <!-- Transformer -->
            <div class="row">
                <div class="col-md-10">
                    <div class="form-group">
                        <textarea class="form-control" style="height: 54px">tcase([title])</textarea>
                    </div>
                </div>
                <div class="col-md-2">
                    <select>
                        <option>1</option>
                    </select>
                </div>
            </div>
            <p>The title is now: Nike - Red Running Shoe</p>
        </div>
    </section>
</modal-hoc>
