import { Directive, ElementRef, EventEmitter, NgZone, OnDestroy, OnInit, Output } from '@angular/core';

/**
 * This directive is currently being used in combination with ngx-codemirror.
 * There was an issue where if the codemirror was not visible, it was having trouble rendering content.
 * https://javascript.plainenglish.io/track-the-elements-visibility-in-angular-55641597bfcd
 */
@Directive({
    selector: '[fdxTrackVisibility]',
})
export class TrackVisibilityDirective implements OnInit, OnDestroy {
    observer!: IntersectionObserver;

    @Output() readonly visible: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(private el: ElementRef<HTMLElement>, private ngZone: NgZone) {}

    ngOnInit(): void {
        this.ngZone.runOutsideAngular(() => {
            this.observer = new IntersectionObserver((entries) => {
                entries.forEach((e) => {
                    this.visible.emit(e.isIntersecting);
                });
            });
            this.observer.observe(this.el.nativeElement);
        });
    }

    ngOnDestroy(): void {
        this.observer.disconnect();
    }
}
