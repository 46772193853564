<div [formGroup]="rowGroup" class="d-flex align-items-start">
    <div class="fdx-group-connector"></div>
    <div class="row flex-grow-1" [ngStyle]="{'width': '0'}">
        <fdx-form-field
            [formControlName]="fieldName"
            class="col-3" >
            <fdx-input-wrapper
                labelText="Field" >
                <ng-select
                    class="fdx-select-field"
                    [formControlName]="fieldName"
                    [id]="fieldName"
                    [items]="dbFields.autocompleteFields"
                    [groupBy]="fieldsGroupByFn"
                    placeholder="Select / search for field"
                    [clearable]="false" >
                </ng-select>
            </fdx-input-wrapper>
        </fdx-form-field>
        <fdx-form-field
            [formControlName]="operatorName"
            class="col-3" >
            <fdx-input-wrapper
                labelText="Operator" >
                <ng-select
                    class="fdx-select-operator"
                    [formControlName]="operatorName"
                    [id]="fieldName"
                    [items]="allOperators"
                    bindLabel="operator"
                    bindValue="operator"
                    groupBy="group"
                    placeholder="Select / search for operator"
                    [clearable]="false" >
                    <ng-template ng-option-tmp let-item="item">
                        <div class="d-flex gap-1">
                            <div class="flex-grow-1 ng-value-label">{{ item.operator}}</div>
                            <div *ngIf="item.caseSensitive" ngbTooltip="Case sensitive" class="ng-value-label-hint">
                                <fa-icon [icon]="infoIcon"></fa-icon>
                            </div>
                        </div>
                    </ng-template>
                </ng-select>
            </fdx-input-wrapper>
        </fdx-form-field>
        <fdx-form-field
            [formControlName]="valueName"
            class="col-6"
            [ngClass]="{'invisible': hideValue}" >
            <fdx-input-field-or-value
                labelText="Field or value"
                [fGroup]="rowGroup"
                [fields]="dbFields.autocompleteFields"
                [multiple]="multiple" >
            </fdx-input-field-or-value>
        </fdx-form-field>
    </div>
    <button
        type="button"
        class="btn btn-link text-secondary btn-remove-row"
        *ngIf="showRemoveRow"
        [disabled]="disabled"
        (click)="removeRow()">
        <fa-icon
            [icon]="xIcon"
            size="lg"
            class="no-margin" >
        </fa-icon>
    </button>
</div>
