import * as angular from 'angular';
import * as moment from 'moment';
import { MODULE_NAME } from '../../config/constants';

angular.module(MODULE_NAME).directive('feedwizardEbaymotors', ['$http', '$location', '$q', '$timeout', '$rootScope', 'AppStateService', 'FeedWizardService', 'ngToast', function ($http, $location, $q, $timeout, $rootScope, AppStateService, FeedWizardService, ngToast) {
  return {
    restrict: 'E',
    scope: true,
    template: require('./ebaymotors.html'),
    link: function ($scope) {
      const account_id = AppStateService.getAccountId();
      $scope.step = 1;

      $scope.previous_step = function () {
        $scope.step--;
      };

      $scope.next_step = function () {
        $scope.step++;
      };

      function xmlToJson(xml) {
        // Create the return object
        var obj = {};

        if (xml.nodeType == 1) { // element
          // do attributes
          if (xml.attributes.length > 0) {
            obj['@attributes'] = {};

            for (var j = 0; j < xml.attributes.length; j++) {
              var attribute = xml.attributes.item(j);
              obj['@attributes'][attribute.nodeName] = attribute.nodeValue;
            }
          }
        } else if (xml.nodeType == 4) { // CDATA
          obj = xml.data;
        } else if (xml.nodeType == 3) { // text
          obj = xml.nodeValue;
        }

        // do children
        if (xml.hasChildNodes()) {
          for (var i = 0; i < xml.childNodes.length; i++) {
            var item = xml.childNodes.item(i);
            var nodeName = item.nodeName;

            if (typeof(obj[nodeName]) == 'undefined') {
              obj[nodeName] = xmlToJson(item);
            } else {
              if (typeof(obj[nodeName].push) == 'undefined') {
                var old = obj[nodeName];
                obj[nodeName] = [];
                obj[nodeName].push(old);
              }

              obj[nodeName].push(xmlToJson(item));
            }
          }
        }

        return obj;
      };

      $scope.parse_config = function() {
        var file = document.getElementById('ebayMotorsConfigFile').files[0];
        if (file) {
          var reader = new FileReader();
          reader.readAsText(file, 'UTF-8');

          reader.onload = function (evt) {
            var data = xmlToJson((new DOMParser).parseFromString(evt.target.result, 'application/xml'));

            var config = {
              'folder_name': data.importConfig.fileTransfer.source.folderName['#text'],
              'inclusion': data.importConfig.fileTransfer.source.inclusion['#text'],
              'feed_sources': [],
              'file_map': {},
              'filters': []
            };

            data.importConfig.columns.column.forEach(function(col) {
              config.file_map[col['@attributes'].name] = col['@attributes'].targetColumn;
            });

            if (data.importConfig.feedSources && data.importConfig.feedSources.item) {
              if (!Array.isArray(data.importConfig.feedSources.item)) {
                data.importConfig.feedSources.item = [data.importConfig.feedSources.item];
              }

              data.importConfig.feedSources.item.forEach(function(item) {
                config.feed_sources.push({
                  'source': item['@attributes']['address'],
                  'file_name': item['@attributes']['saveTo'],
                  'should_import': true
                });
              });
            }

            if (data.importConfig.filters && data.importConfig.filters.filter) {
              if (!Array.isArray(data.importConfig.filters.filter)) {
                data.importConfig.filters.filter = [data.importConfig.filters.filter];
              }

              data.importConfig.filters.filter.forEach(function(item) {
                config.filters.push({
                  'dealer_number': '',
                  'criteria': (item.criteria && item.criteria['#cdata-section'] ? item.criteria['#cdata-section'] : '').trim(),
                  'updater': (item.updater && item.updater['#cdata-section'] ? item.updater['#cdata-section'] : '').trim(),
                  'transformers': []
                });
              });
            }

            if (data.importConfig.dealers && data.importConfig.dealers.dealer) {
              if (!Array.isArray(data.importConfig.dealers.dealer)) {
                data.importConfig.dealers.dealer = [data.importConfig.dealers.dealer];
              }

              data.importConfig.dealers.dealer.forEach(function(dealer) {
                var dealer_number = dealer['@attributes'].dealerNumber;

                config.filters.push({
                  'dealer_number': dealer_number,
                  'criteria': (dealer.filters.filter.criteria && dealer.filters.filter.criteria['#cdata-section'] ? dealer.filters.filter.criteria['#cdata-section'] : '').trim(),
                  'updater': (dealer.filters.filter.updater && dealer.filters.filter.updater['#cdata-section'] ? dealer.filters.filter.updater['#cdata-section'] : '').trim(),
                  'transformers': []
                });
              });
            }

            $scope.$applyAsync(function() {
              $scope.data.config = config;
              $scope.next_step();
            });
          };

          reader.onerror = function (evt) {
            ngToast.danger({
              'content': 'Couldn\'t read config file!'
            });
          };
        }
      };

      // CodeMirror Options
      $scope.code_mirror = {
        mode: '',
        extraKeys: {
          'Tab': false,
          'Shift-Tab': false,
        },
      };

      $scope.cmInit = cm_variables.cmInit;

      $scope.add_transformer = function(arr) {
        if (!Array.isArray(arr)) {
          arr = [];
        }

        arr.push({
          'field_name': $scope.data.db_fields[0] ? $scope.data.db_fields[0].field_name : 'vin',
          'selector': 'true',
          'transformer': '',
          'export_id': '0'
        });
      };

      $scope.remove_transformer = function(arr, index) {
        arr.splice(index, 1);
      };

      $scope.add_transformers = function() {
        var transformers = [];

        $scope.data.config.filters.forEach(function(filter) {
          if (!Array.isArray(filter.transformers)) {
            return;
          }

          filter.transformers.forEach(function(transformer) {
            var is_valid = transformer.field_name && transformer.selector && transformer.transformer;
            if (is_valid) {
              transformers.push(transformer);
            }
          });
        });

        if (transformers.length === 0) {
          var conf = confirm('Are you sure you want to continue without adding any transformers?');
          if (conf === true) {
            $scope.next_step();
          }

          return;
        }

        $http.post('/api.php/dbs/' + $scope.data.db_id + '/bulk_transformers', {
          'data_type': 'json',
          'bulk_transformers': transformers
        }).then(function() {
          $scope.next_step();
        }, function(response) {
          ngToast.danger({
            'content': 'Error: ' + response.data
          });
        });
      };

      $scope.data = {
        'db_id': 0,
        'import_id': 0,
        'db_fields': [],
        'ftp': {
          'enabled': true,
          'ftp_host': '',
          'ftp_port': 21,
          'ftp_username': '',
          'ftp_password': ''
        },
        'delimiters': {},
        'config': {}
      };

      $http.get('/api.php/accounts/' + account_id + '/feedwizard/ebaymotors/ftp_credentials').then(function(response) {
        $.extend($scope.data.ftp, response.data);
      }, function(response) {
        ngToast.danger({
          'content': response.data.message
        });

        $location.path('/');
      });

      var on_step_error = function (response) {
        $scope.previous_step();

        if (response && response.message) {
          ngToast.danger({
            'content': response.message
          });
        } else {
          ngToast.danger({
            'content': 'Error downloading feed! Please check your credentials and try again!'
          });
        }

        if ($scope.data.db_id) {
          $http.delete('/api.php/dbs/' + $scope.data.db_id);
        }
      };

      var steps = [
        // Create DB
        (new FeedWizardStep())
          .setEndpoint(function () {
            return '/api.php/accounts/' + account_id + '/dbs';
          })
          .setMethod('post')
          .setParams(function () {
            return {
              'name': 'eBay Motors Wizard - ' + $scope.data.config.folder_name +' '+  moment().format('MM/DD LT')
            };
          })
          .setOnSuccess(function (data) {
            $scope.data.db_id = data.id;
          })
          .setOnError(on_step_error)
      ];

      var ftp_import_steps = [
        // Create Import #1
        (new FeedWizardStep())
          .setEndpoint(function () {
            return '/api.php/dbs/' + $scope.data.db_id + '/imports';
          })
          .setMethod('post')
          .setParams(function () {
            var params = new URLSearchParams();

            switch($scope.data.config.inclusion) {
              case '*.*': $scope.data.config.inclusion = '.*'; break;
              case '*.csv': $scope.data.config.inclusion = '.*\.csv'; break;
              case '*.txt': $scope.data.config.inclusion = '.*\.txt'; break;
              case '*.zip': $scope.data.config.inclusion = '.*\.zip'; break;
            }

            params.append('connection_info[protocol]', 'ftp');
            params.append('connection_info[host]', $scope.data.ftp.ftp_host);
            params.append('connection_info[port]', $scope.data.ftp.ftp_port);
            params.append('connection_info[username]', $scope.data.ftp.ftp_username);
            params.append('connection_info[password]', $scope.data.ftp.ftp_password);
            params.append('connection_info[ftp_session_type]', 'passive');
            params.append('file_info[request_type]', 'get');
            params.append('file_info[directory]', '/' + $scope.data.config.folder_name + '/');
            params.append('file_info[file_match]', $scope.data.config.inclusion);
            params.append('file_info[rank]', '1');

            var config = {
              'name': 'File Import',
              'join_type': 'product_feed',
              'file_type': 'delimited',
              'file_location': 'preprocess_script',
              'tags': {'platform': 'Other', 'platform_other': 'Ebay'},
              'preprocess_info': {
                'connection_info': {
                  'protocol': 'ftp',
                  'host': $scope.data.ftp.ftp_host,
                  'port': $scope.data.ftp.ftp_port,
                  'username': $scope.data.ftp.ftp_username,
                  'password': $scope.data.ftp.ftp_password,
                  'ftp_session_type': 'passive'
                },
                'file_info': {
                  'request_type': 'get',
                  'directory': '/' + $scope.data.config.folder_name + '/',
                  'file_match': $scope.data.config.inclusion,
                  'rank': '1'
                },
                'actions': []
              }
            };

            // If file is a zip then unzip
            if ($scope.data.config.inclusion.indexOf('.zip') >= 0) {
              config['preprocess_info']['actions'].push({
                'id': 'unzip',
                'options': {
                  'type': 'zip'
                }
              });

              params.append('actions[unzip][type]', 'zip');
            }

            config['url'] = 'https://haproxy-preprocess.feedonomics.com/preprocess/run_preprocess.php?' + params.toString();

            return config;
          })
          .setOnSuccess(function (response) {
            $scope.data.import_id = response.id;
          })
          .setOnError(on_step_error),

        // Detect Import Delimiters
        (new FeedWizardStep())
          .setEndpoint(function () {
            return '/api.php/dbs/' + $scope.data.db_id + '/imports/' + $scope.data.import_id + '/auto_detect';
          })
          .setMethod('get')
          .setOnSuccess(function (data) {
            $scope.data.delimiters = data && data[0] ? data[0] : {};
          })
          .setOnError(on_step_error),

        // Update Import Delimiters
        (new FeedWizardStep())
          .setEndpoint(function () {
            return '/api.php/dbs/' + $scope.data.db_id + '/imports/' + $scope.data.import_id + '/delimiters';
          })
          .setParams(function () {
            return {
              'enclosure': $scope.data.delimiters['enclosure_character'] || '"',
              'encoding': $scope.data.delimiters['recommended_encoding'] || '',
              'escaper': $scope.data.delimiters['escape_character'] || '"',
              'line_terminator': $scope.data.delimiters['line_terminator'] || "\n",
              'separator': $scope.data.delimiters['field_separator'] || ','
            };
          })
          .setMethod('put')
          .setOnError(on_step_error),

        // Grab headers to create file map
        (new FeedWizardStep())
          .setEndpoint(function () {
            return '/api.php/dbs/' + $scope.data.db_id + '/imports/' + $scope.data.import_id  + '/file?format=parsed&limit=4';
          })
          .setMethod('get')
          .setOnSuccess(function (response) {
            $scope.data.file_map = {};
            $scope.data.file_map_data = {};

            response[0].forEach(function (field, field_index) {
              var header = FeedWizardService.hex2bin(response[0][field_index]);
              var file_map_index = response[0][field_index];

              var name = header.replace(/[^\x00-\x7F]/g, "")
                .trim();

              $scope.data.file_map[file_map_index] = $scope.data.config.file_map[name] || name;
              $scope.data.file_map_data[file_map_index] = [];

              [1, 2, 3].forEach(function(col_index) {
                var value = '';
                if (response[col_index] && response[col_index][field_index]) {
                  value = FeedWizardService.hex2bin(response[col_index][field_index]);
                }

                $scope.data.file_map_data[file_map_index].push(value);
              });
            });
          })
          .setOnError(on_step_error),

        // Update Import #1 file map
        (new FeedWizardStep())
          .setEndpoint(function () {
            return '/api.php/dbs/' + $scope.data.db_id + '/imports/' + $scope.data.import_id + '/file_map';
          })
          .setMethod('put')
          .setParams(function () {
            return {
              'maps': $scope.data.file_map,
              'name_based_maps': 1,
              'encode_source_file_keys': 1,
              'clean_file_headers': 0
            };
          })
          .setOnError(on_step_error),

        // Grab fields for transformers
        (new FeedWizardStep())
          .setEndpoint(function () {
            return '/api.php/dbs/' + $scope.data.db_id + '/db_fields?transformer_count=1';
          })
          .setMethod('get')
          .setOnSuccess(function (response) {
            $scope.data.db_fields = response;

            // Based on the db_fields, set the mode
            var valid_db_fields = $scope.data.db_fields.map(function(value) {
              return value.field_name;
            });

            // add [ and ] for all field names for autocomplete
            var autocomplete_db_fields = $scope.data.db_fields.map(function(value) {
              return '[' + value.field_name + ']';
            });

            // Initialize auto complete
            CodeMirror.hint.transformer_parser = cm_variables.hint_transformer_parser(autocomplete_db_fields);

            // Initialize mode
            CodeMirror.defineSimpleMode(
              "transformer_parser",
              cm_variables.mode_transformer_parser(valid_db_fields)
            );

            $scope.code_mirror.mode = 'transformer_parser';
          })
          .setOnError(on_step_error),
      ];

      var wizard;
      $scope.run = function () {
        var wizard_steps = angular.copy(steps),
          imports = 0;

        // If FTP is enabled merge the steps
        if ($scope.data.ftp.enabled === true) {
          ftp_import_steps.forEach(function(step) {
            wizard_steps.push(step);
          });

          imports++;
        }

        // Add file source imports
        $scope.data.config.feed_sources.forEach(function(source) {
          if (source.should_import === true) {
            wizard_steps.push(
              (new FeedWizardStep())
                .setEndpoint(function () {
                  return '/api.php/dbs/' + $scope.data.db_id + '/imports';
                })
                .setMethod('post')
                .setParams(function () {
                  var file_type = 'delimited';
                  if(source.file_name.indexOf('.xml') > 0) {
                    file_type = 'xml';
                  }

                  var config = {
                    'name': 'File Import: ' + source.file_name,
                    'join_type': 'product_feed',
                    'file_type': file_type,
                    'file_location': 'url',
                    'tags': {'platform': 'Other', 'platform_other': 'Ebay'},
                    'url': source.source
                  };

                  return config;
                })
                .setOnError(on_step_error)
            );

            imports++;
          }
        });

        if(imports === 0) {
          ngToast.danger({
            'message': 'Please select at least 1 import source'
          });

          return;
        }

        wizard = (new FeedWizard($http, $q))
          .addSteps(wizard_steps);

        $scope.next_step();

        if(imports > 1 || $scope.data.ftp.enabled !== true) {
          wizard.run().then(function() {
            $scope.step = 'last';
          }, angular.noop, function (progress) {
            $scope.progress = progress;
          });

          return;
        }

        wizard.run(5).then(function () {
          $scope.next_step();
        }, angular.noop, function (progress) {
          $scope.progress = progress;
        });
      };

      $scope.submit_file_map = function() {
        $scope.next_step();

        wizard.run().then(function () {
          $scope.next_step();
        }, angular.noop, function (progress) {
          $scope.progress = progress;
        });
      };
    }
  };
}]);