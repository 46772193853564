import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatSeconds'
})
export class FormatSecondsPipe implements PipeTransform {
    transform(seconds: number | string): string {
        seconds = Number(seconds);
        const secondsInHour = 3600;
        const secondsInMinute = 60;

        const h = Math.floor(seconds / secondsInHour);
        const hmod = seconds % secondsInHour;
        const m = Math.floor(hmod / secondsInMinute);
        const s = hmod % secondsInMinute;

        const result = [];

        if (h > 0) {
            result.push(
                `${h} hr`
            );
        }

        if (m > 0) {
            result.push(
                `${m} min`
            );
        }

        if (s > 0) {
            result.push(
                `${s} sec`
            );
        }

        return result.join(' ');
    }
}