import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { CellRendererSelectorResult, ColDef, GridApi, GridOptions, GridReadyEvent, ICellRendererParams, IRowNode, Module } from '@ag-grid-community/core';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import AppStateService from '@ajs/services/AppStateService';
import CSVService from '@ajs/services/CSVService';
import FdxUI from '@ajs/services/fdxUI';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DateTimeService } from '@app/core/services/date-time.service';
import { LogEntryCellRendererComponent } from '@app/logs/components/log-entry-cell-renderer/log-entry-cell-renderer.component';
import { LogType } from '@app/logs/models/enums/log-type.enum';
import { TransformerLog } from '@app/logs/models/types/log-transformer.types';
import { LogsDataFormatterService } from '@app/logs/services/logs-data-formatter.service';
import { LogsDataService } from '@app/logs/services/logs-data.service';
import { LogsStateService } from '@app/logs/services/logs-state.service';
import { LogsRequest } from '@app/logs/services/requests/logs-request.type';
import { TransformerLogsResponse } from '@app/logs/services/responses/logs-responses.types';
import { TransformerCellRendererComponent } from '@app/standalone-components/ag-grid/cell-renderers/transformer-cell-renderer/transformer-cell-renderer.component';
import { TransformerModel } from '@app/transformers/models/transformer.model';
import { DateRange, Item } from '@feedonomics/frontend-components';
import { IconDefinition, faDownload } from '@fortawesome/pro-solid-svg-icons';
import { Subject, distinctUntilChanged, filter, takeUntil, tap } from 'rxjs';

@Component({
    selector: 'fdx-logs-transformer',
    templateUrl: './logs-transformer.component.html',
    styleUrls: ['./logs-transformer.component.scss']
})
export class LogsTransformerComponent implements OnInit, OnDestroy {
    readonly logType: LogType = LogType.Transformer;

    private readonly unsubscribe$: Subject<void> = new Subject<void>();
    readonly downloadIcon: IconDefinition = faDownload;

    logs: TransformerLog[] = [];
    quickFilter: string = null;

    // eslint-disable-next-line @typescript-eslint/typedef
    filters = new FormGroup({
        dateRange: new FormControl<DateRange>(null),
        action: new FormControl<string[]>([]),
        field: new FormControl<string[]>([]),
        user: new FormControl<string[]>([])
    });

    actions: Item[] = [];
    fieldNames: Item[] = [];
    users: Item[] = [];

    get databaseId(): string {
        return this.appStateService.getDatabaseId();
    }

    get timeZoneCode(): string {
        return this.dateTimeService.getTimezoneCode();
    }

    // #region AG_GRID
    private gridApi: GridApi<TransformerLog>;

    public readonly modules: Module[] = [
        ClientSideRowModelModule,
        ClipboardModule,
        RowGroupingModule
    ];

    private readonly defaultColDef: ColDef<TransformerLog> = {
        minWidth: 100,
        wrapText: true,
        autoHeight: true,
        flex: 1
    };

    public readonly columnDefs: ColDef<TransformerLog>[] = [
        {
            field: 'date',
            rowGroup: true,
            hide: true
        },
        {
            field: 'time',
            headerName: `Date > time (${this.timeZoneCode})`
        },
        {
            field: 'user',
            headerName: 'User'
        },
        {
            field: 'action',
            headerName: 'Action'
        },
        {
            field: 'exportName',
            headerName: 'Export name'
        },
        {
            field: 'fieldName',
            headerName: 'Field name'
        },
        {
            field: 'old',
            headerName: 'Old',
            flex: 3,
            cellRendererSelector: (params: ICellRendererParams<TransformerLog>): CellRendererSelectorResult => {
                if (typeof params.data.old === 'string') {
                    return {
                        component: LogEntryCellRendererComponent,
                        params: {
                            data: params.data.old
                        }
                    }
                }
                return {
                    component: TransformerCellRendererComponent,
                    params: {
                        transformer: params.data.old
                    }
                }
            }
        },
        {
            field: 'new',
            headerName: 'New',
            flex: 3,
            cellRendererSelector: (params: ICellRendererParams<TransformerLog>): CellRendererSelectorResult => {
                if (typeof params.data.new === 'string') {
                    return {
                        component: LogEntryCellRendererComponent,
                        params: {
                            data: params.data.new
                        }
                    }
                }
                return {
                    component: TransformerCellRendererComponent,
                    params: {
                        transformer: params.data.new
                    }
                }
            }
        }
    ];

    public readonly gridOptions: GridOptions<TransformerLog> = {
        context: this,
        domLayout: 'autoHeight',
        rowModelType: 'clientSide',
        pagination: true,
        defaultColDef: this.defaultColDef,
        groupDisplayType: 'groupRows',
        groupDefaultExpanded: 1,
        columnDefs: this.columnDefs,
        isExternalFilterPresent: () => true,
        doesExternalFilterPass: (node: IRowNode<TransformerLog>) => {
            let actionPasses: boolean;
            if (!this.filters.value.action || this.filters.value.action.length === 0) {
                actionPasses = true;
            } else {
                actionPasses = this.filters.value.action.some((value: string) => node.data.action === value);
            }

            let fieldNamePasses: boolean;
            if (!this.filters.value.field || this.filters.value.field.length === 0) {
                fieldNamePasses = true;
            } else {
                fieldNamePasses = this.filters.value.field.some((value: string) => node.data.fieldName === value);
            }

            let userPasses: boolean;
            if (!this.filters.value.user || this.filters.value.user.length === 0) {
                userPasses = true;
            } else {
                userPasses = this.filters.value.user.some((value: string) => node.data.user === value);
            }

            return actionPasses && fieldNamePasses && userPasses;
        }
    };
    // #endregion

    constructor(
        private readonly appStateService: AppStateService,
        private readonly csvService: CSVService,
        private readonly dateTimeService: DateTimeService,
        private readonly fdxUI: FdxUI,
        private readonly logsDataFormatterService: LogsDataFormatterService,
        private readonly logsDataService: LogsDataService,
        private readonly logsStateService: LogsStateService
    ) {}

    ngOnInit(): void {
        this.initLogsStateService();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private initLogsStateService(): void {
        this.logsStateService.logsRequest$.pipe(
            filter((request: LogsRequest) => request !== null),
            tap((request: LogsRequest) => this.getLogs(request)),
            takeUntil(this.unsubscribe$)
        ).subscribe();

        this.logsStateService.logsQuickFilter$.pipe(
            tap((filterString: string) => {
                this.quickFilter = filterString;
                this.gridApi?.setGridOption('quickFilterText', filterString);
            }),
            takeUntil(this.unsubscribe$)
        ).subscribe();

        this.logsStateService.logsDateRangeFilter$.pipe(
            filter((range: DateRange) => range !== null),
            distinctUntilChanged(),
            tap(() => this.fireRequest()),
            takeUntil(this.unsubscribe$)
        ).subscribe();
    }

    private getLogs(request: LogsRequest): void {
        request.page = this.gridApi?.paginationGetCurrentPage() + 1 || 1;

        this.logsDataService.getTransformerLogs(this.databaseId, request).pipe(
            tap((response: TransformerLogsResponse) => {
                const formatted = this.logsDataFormatterService.formatTransformerStructure(response.logs);
                this.logs = formatted.logs;

                if (request.download) {
                    this.handleDownload(this.logs);
                    return;
                }

                this.actions = formatted.actions;
                this.fieldNames = formatted.fieldNames;
                this.users = formatted.users;

                this.gridApi.setGridOption('rowData', this.logs);
                this.showOrHideNoRowsOverlay();
            }),
            takeUntil(this.unsubscribe$)
        ).subscribe();
    }

    fireRequest(download: boolean = false): void {
        const range: DateRange = this.logsStateService.logsDateRangeFilter$.value;

        this.logsStateService.logsRequest$.next({
            sort: 'time',
            log_disabled: true,
            start_date: range.isoStartDate,
            end_date: range.isoEndDate,
            download: download,
            user_timezone: this.dateTimeService.getTimezone()
        });
    }

    filterChanged(): void {
        this.gridApi?.onFilterChanged();
    }

    public onGridReady(params: GridReadyEvent<TransformerLog>): void {
        this.gridApi = params.api;

        // Apply any filters that were waiting to be set until the grid was ready
        this.gridApi.setGridOption('quickFilterText', this.quickFilter);
        this.gridApi.onFilterChanged();
    }

    showOrHideNoRowsOverlay(): void {
        if (this.gridApi.getDisplayedRowCount() === 0) {
            this.gridApi.showNoRowsOverlay();
        } else {
            this.gridApi.hideOverlay();
        }
    }

    private handleDownload(logs: TransformerLog[]): void {
        if (logs.length === 0) {
            this.fdxUI.showToastError('There are no logs available to download');
            return;
        }

        const data: string[][] = [];

        data.push(['time','user','action','export','field','old','new']);
        logs.forEach((log: TransformerLog) => {
            const dateTime = `${log.date}, ${log.time}`;
            const oldTransformer = this.getTransformerPrint(log.old);
            const newTransformer = this.getTransformerPrint(log.new);
            data.push([dateTime, log.user, log.action, log.exportName, log.fieldName, oldTransformer, newTransformer]);
        });

        this.csvService.download(`${this.logType}_logs.csv`, data);
        this.fdxUI.showToastSuccess('Successfully downloaded logs');
    }

    private getTransformerPrint(transformer: TransformerModel | string): string {
        if (transformer === null) {
            return null;
        }
        if (typeof transformer === 'string') {
            return transformer;
        }
        return `if: ${transformer.selector} | then: ${transformer.transformer} | order: ${transformer.sort_order} | enabled: ${transformer.enabled}`
    }

    patchFromMultiselect(formControlName: string, event: unknown): void {
        this.filters.patchValue({ [formControlName]: event });
    }
}
