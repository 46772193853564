import { ExportConstants } from '@app/exports/constants/exports.constants';
import { QueryType } from '@app/modules/inputs/enums/query-type.enum';
import { TransformerDisplayModel } from '../models/transformer-display.model';

export class TransformerConstants {
    public static newTransformer: TransformerDisplayModel = {
        id: '-1',
        newTransformer: true,
        enabled: true,
        exportModels: [ExportConstants.allExports],
        isExpanded: false,
        type: QueryType.Advanced,
        error: null,
        selector: 'true',
        transformer: '\'\'',
        order: null
    };
}
