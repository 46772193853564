import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'fdx-style-wrapper',
    templateUrl: './style-wrapper.component.html',
    styleUrls: ['../../../styles.scss', './style-wrapper.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
    standalone: true
})
export class StyleWrapperComponent { }
