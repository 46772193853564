import { Component, Input } from '@angular/core';
import { IconDefinition, faTimes } from '@fortawesome/pro-light-svg-icons';
import { faTimes as faTimesSolid } from '@fortawesome/pro-solid-svg-icons';

@Component({
    selector: 'fdx-select-multiple-label-template',
    templateUrl: './select-multiple-label-template.component.html',
    styleUrls: ['./select-multiple-label-template.component.scss']
})
export class SelectMultipleLabelTemplateComponent {
    
    @Input() item: any;
    @Input() clear: any;
    
    hover: boolean = false;
    
    closeIconLight: IconDefinition = faTimes;
    closeIconSolid: IconDefinition = faTimesSolid;
}
