import AppStateService from '@ajs/services/AppStateService';
import fdxUI from '@ajs/services/fdxUI';
import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AppMenuTab } from '@app/core/models/enums/app-menu-tab.enum';
import { BasePageComponent } from '@app/modules/page/abstract/base-page.component';
import { faPlay } from '@fortawesome/pro-solid-svg-icons';
import { finalize, takeUntil } from 'rxjs';
import AttributionDataService from '../../services/attribution-data.service';

@Component({
    selector: 'fdx-attributor-page',
    templateUrl: './attributor-page.component.html',
    styleUrls: ['./attributor-page.component.scss']
})
export class AttributorPageComponent extends BasePageComponent {
    appMenuTab = AppMenuTab.Transformers;
    attributes = [
        'age_group',
        'color',
        'gender',
        'material',
        'pattern',
        'product_dimension',
        'size'
    ];
    form = new UntypedFormGroup({
        attributes: new UntypedFormControl([]), // string[]
        description: new UntypedFormControl('SIMPLY COMFORTABLE The breathable and plush Nike Roshe One Men\'s Shoe provides all day, everyday comfort. Worn with or without socks, dressed up or down, for walking or just taking it easy, this coveted style is designed to be versatile. BENEFITS Mesh construction for excellent breathability Lightweight sole for soft cushioning Cushioned collar for added comfort around the ankle Waffle-inspired outsole pattern for multi-surface traction SIGNATURE DETAILS Inspired by the practice of meditation and the concept of Zen, the Nike Roshe epitomizes simplicity. It has no embellishments, just basic shoe necessities brought to life with every detail, creating a juxtaposition of seriousness and playfulness. Style: 511881; Color: Black/Sail/Anthracite; Size: 6; Gender: Male; Age Group: Adult | Nike Roshe One Men\'s Shoes in Black/Sail, Size: 6 | 511881-010 L 84" x W 36" x H 29.5" , dimensions: 2" x 3"'),
        googleProductCategory: new UntypedFormControl('Apparel & Accessories > Shoes'),
        gtin: new UntypedFormControl('00675911191835'),
        title: new UntypedFormControl('Nike Roshe One Men\'s Shoes in Black/Sail, Size: 6 | 511881-010 Chevron 100% cotton size 11')
    });
    iconPlay = faPlay;
    results: { key: string; values: string[]; }[] = [];
    title = 'Attribute Data';

    constructor(
        private appStateService: AppStateService,
        private attributionDataService: AttributionDataService,
        fdxUI: fdxUI
    ) {
        super(fdxUI);
    }

    formatFieldName(fieldName: string): string {
        fieldName += '';

        return fieldName.replace(/\_/ig, ' ');
    }

    attribute() {
        this.fdxUI.startBlockUI();

        this.attributionDataService.runAttributor(this.appStateService.getDatabaseId(), {
            attributes: this.form.controls.attributes.value || [],
            description: this.form.controls.description.value || '',
            googleProductCategory: this.form.controls.googleProductCategory.value || '',
            gtin: this.form.controls.gtin.value || '',
            title: this.form.controls.title.value || ''
        })
            .pipe(
                finalize(() => {
                    this.fdxUI.stopBlockUI();
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(
                (data) => {
                    this.results = Object.entries(data).map(([key, values]) => {
                        return { key, values };
                    });
                },
                (error: HttpErrorResponse) => {
                    this.fdxUI.showToastError(error.error.message);
                    this.results = [];
                }
            );
    }
}
