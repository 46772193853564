import AppStateService from '@ajs/services/AppStateService';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class BasicQueryUtilities {
    constructor(
        private appStateService: AppStateService
    ) {}
    
    getFieldPrefix(inner: string, row: string): string {
        return `${inner}-${row}-`;
    }
    
    get userId(): string {
        return this.appStateService.getUserId();
    }
    
    get databaseId(): string {
        return this.appStateService.getDatabaseId();
    }
    
    get queryTypeKey(): string {
        return `${this.userId}_${this.databaseId}_query_type`;
    }
    
    get transformerTypeKey(): string {
        return `${this.userId}_${this.databaseId}_transformer_type`;
    }
}