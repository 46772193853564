import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WellFooterComponent } from '@app/core-legacy/components/well-footer/well-footer.component';
import { WellHeaderComponent } from '@app/core-legacy/components/well-header/well-header.component';
import { WellComponent } from '@app/core-legacy/components/well/well.component';

/**
 * @deprecated
 * TODO: Remove all instances of the components below, then delete this module. We
 * also want to convert all uses of BaseComponent to use their own unsubscribe logic.
 */

@NgModule({
    declarations: [
        WellComponent,
        WellFooterComponent,
        WellHeaderComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        WellComponent,
        WellFooterComponent,
        WellHeaderComponent
    ]
})
export class CoreLegacyModule { }
