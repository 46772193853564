import { UserDataModel } from '@app/user/models/user-data.model';
import { UserSettings } from '@app/user/models/user-settings.model';

export default class UsersDataService {
    static $inject = ['$http', '$q'];

    // Services
    $http = null;
    $q = null;

    constructor($http, $q) {
        this.$http = $http;
        this.$q = $q;
    }

    getPendingInvites(): Promise<{ pending_count: number; }> {
        return this.$http.get('/api.php/user/pending_invites').then((response) => {
            return response.data;
        });
    }

    retrieveCurrentUser(): Promise<UserDataModel> {

        const def = this.$q.defer();

        const promise = this.$http.get('/api.php/user');

        promise.then(
            (response) => {
                def.resolve(response.data);
            },
            (response) => {
                def.reject(response.data);
            }
        );

        return def.promise;
    }

    updatePassword(password) {
        const def = this.$q.defer();

        const promise = this.$http.put('/api.php/update_password', {
            'password': password
        });

        promise.then(
            (response) => {
                def.resolve(response.data);
            },
            (response) => {
                def.reject(response.data);
            }
        );

        return def.promise;
    }

    getCurrentUserAccounts() {

        const def = this.$q.defer();

        const promise = this.$http.get('/api.php/user/accounts');

        promise.then(
            (response) => {
                def.resolve(response.data);
            },
            (response) => {
                def.reject(response.data);
            }
        );

        return def.promise;

    }

    updateUserSettings(settings: UserSettings): Promise<any> {
        return this.$http.put('/api.php/user_settings', settings);
    }

    updateUserSetting(settings: UserSettings, key: string, value: string | boolean): Promise<any> {
        const newSettings: UserSettings = {
            ...settings,
            [key]: value
        };

        return this.updateUserSettings(newSettings);
    }


    getUserAccountPermissions() {

        const def = this.$q.defer();

        const promise = this.$http.get('/api.php/user/account_permissions');

        promise.then(
            (response) => {
                def.resolve(response.data);
            },
            (response) => {
                def.reject(response.data);
            }
        );

        return def.promise;
    }

    getUserDbPermissions() {

        const def = this.$q.defer();

        const promise = this.$http.get('/api.php/user/db_permissions');

        promise.then(
            (response) => {
                def.resolve(response.data);
            },
            (response) => {
                def.reject(response.data);
            }
        );

        return def.promise;
    }

    updateUserAccountPermission(permissionId, params): Promise<any> {

        const def = this.$q.defer();

        const promise = this.$http.get(`/api.php/user/update_account_permission/${permissionId}`, params);

        promise.then(
            (response) => {
                def.resolve(response.data);
            },
            (response) => {
                def.reject(response.data);
            }
        );

        return def.promise;
    }

    updateUserDbPermission(permissionId, params): Promise<any> {

        const def = this.$q.defer();

        const promise = this.$http.get(`/api.php/user/update_db_permission/${permissionId}`, params);

        promise.then(
            (response) => {
                def.resolve(response.data);
            },
            (response) => {
                def.reject(response.data);
            }
        );

        return def.promise;
    }
}
