import { Injectable } from '@angular/core';
import { ContainerService } from '@app/core/services/container.service';
import { ConfirmationModalComponent } from '@app/modules/modals/components/confirmation-modal/confirmation-modal.component';
import { ModalComponent } from '@app/modules/modals/interfaces/modal-component.interface';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, take } from 'rxjs';
import { ModalOptions } from '../interfaces/modal-options.interface';
import { Modal } from '../interfaces/modal.interface';

@Injectable({
    providedIn: 'root'
})
export class ModalService {
    constructor(
        private readonly containerService: ContainerService,
        private readonly ngbModal: NgbModal
    ) { }

    /**
     * Opens a new modal window using ng-bootstrap with the specified component and options.
     *
     * The specified component must implement the `Modal<T>` interface.
     * Where `T` is your resolver interface for data which gets passed into your modal.
     *
     * To pass data into your modal specify it under `options.resolve`.
     *
     * If your modal does not need to pass in data then just implement `Modal` without the generic `T`.
     *
     * Other ng-bootstrap modal options can be passed in under `options` as well.
     */
    public open(
        component: ModalComponent,
        options: ModalOptions = {},
        lightDom: boolean = false
    ): NgbModalRef {
        let instance: Modal = null;

        const modal = this.ngbModal.open(
            component,
            {
                ...options,
                beforeDismiss: () => {
                    if (typeof (instance?.canDismiss) === 'function') {
                        return instance.canDismiss();
                    }

                    return true;
                },
                /**
                 * put modals in #bs5-container container
                 * this div has bootstrap 5 styles applied
                 * to it and body currently does not
                 *
                 * specific instances cannot be done under shadow root
                 * lightDom allows them to use the bs3 container instead
                 *
                 * TO DO: remove after fully on bs5
                 */
                container: lightDom ? this.containerService.getLightDomContainer() : this.containerService.getContainer('ModalService'),
                modalDialogClass: 'modal-dialog-centered'
            }
        );

        instance = modal.componentInstance as Modal;

        const data: unknown = options.resolve;

        if (instance) {
            instance.resolve = data;
        }

        if (typeof (instance?.onModalInit) === 'function') {
            instance.onModalInit(data);
        }

        const subscriptions: Subscription[] = [];

        if (typeof (options.onClose) === 'function') {
            subscriptions.push(
                modal.closed
                    .pipe(
                        take(1)
                    )
                    .subscribe(
                        {
                            next: (value) => options.onClose(value),
                            complete: () => subscriptions.forEach((s) => s.unsubscribe())
                        }
                    )
            );
        }

        if (typeof (options.onDismiss) === 'function') {
            subscriptions.push(
                modal.dismissed
                    .pipe(
                        take(1)
                    )
                    .subscribe(
                        {
                            next: (reason) => options.onDismiss(reason),
                            complete: () => subscriptions.forEach((s) => s.unsubscribe())
                        }
                    )
            );
        }

        return modal;
    }

    public showConfirmationModal(title?: string, body?: string, confirmText?: string, cancelText?: string, icon?: IconDefinition): NgbModalRef {
        return this.open(ConfirmationModalComponent, {
            resolve: {
                body,
                modalTheme: 'primary',
                cancelText,
                confirmText,
                title,
                icon
            }
        });
    }

    public showSuccessConfirmationModal(title?: string, body?: string, confirmText?: string, cancelText?: string, icon?: IconDefinition): NgbModalRef {
        return this.open(ConfirmationModalComponent, {
            resolve: {
                body,
                modalTheme: 'success',
                cancelText,
                confirmText,
                title,
                icon
            }
        });
    }

    public showDangerConfirmationModal(title?: string, body?: string, confirmText?: string, cancelText?: string, icon?: IconDefinition): NgbModalRef {
        return this.open(ConfirmationModalComponent, {
            resolve: {
                body,
                modalTheme: 'danger',
                cancelText,
                confirmText,
                title,
                icon
            }
        });
    }

    public showWarningConfirmationModal(title?: string, body?: string, confirmText?: string, cancelText?: string, icon?: IconDefinition): NgbModalRef {
        return this.open(ConfirmationModalComponent, {
            resolve: {
                body,
                modalTheme: 'warning',
                cancelText,
                confirmText,
                title,
                icon
            }
        });
    }

    public showInfoConfirmationModal(title?: string, body?: string, confirmText?: string, cancelText?: string, icon?: IconDefinition): NgbModalRef {
        return this.open(ConfirmationModalComponent, {
            resolve: {
                body,
                modalTheme: 'info',
                cancelText,
                confirmText,
                title,
                icon
            }
        });
    }
}
