import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { PageChangedEvent } from '@app/modules/pagination/types/page-changed-event.type';
import { PageSizeChangedEvent } from '@app/modules/pagination/types/page-size-changed-event.type';

@Component({
    selector: 'fdx-pagination-bar',
    templateUrl: './pagination-bar.component.html',
    styleUrls: ['./pagination-bar.component.scss']
})
export class PaginationBarComponent implements OnChanges {
    @Input() recordsCount: number;
    @Input() pageSize: number = 20;
    @Input() hidePerPageSelector: boolean = false;
    @Input() currentPage: number = 1;

    @Output() pageChanged: EventEmitter<PageChangedEvent> = new EventEmitter<{ startRecord: number, endRecord: number, pageNumber: number }>();
    @Output() pageSizeChanged: EventEmitter<PageSizeChangedEvent> = new EventEmitter<{ pageSize: number }>();

    itemsPerPageList: { value: number, display_name: string }[] = [
        { value: 10, display_name: '10' },
        { value: 20, display_name: '20' },
        { value: 50, display_name: '50' },
        { value: 100, display_name: '100' },
        { value: 500, display_name: '500' },
    ];

    perPage: number;

    ngOnChanges(changes: SimpleChanges): void {
        // Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
        // Add '${implements OnChanges}' to the class.
        if (changes.pageSize) {
            if (changes.pageSize.currentValue !== this.perPage) {
                this.perPage = changes.pageSize.currentValue
            }
        }
        if (changes.recordsCount) {
            // Respect the page size given in an initial load, but handle a mistake in page count.
            if (changes.recordsCount.currentValue !== changes.recordsCount.previousValue && 
                !changes.recordsCount.firstChange ||
                this.countMismatch()
            ) {
                this.changePage(1);
            }
        }
    }

    get pageCount(): number {
        return Math.ceil(this.recordsCount / this.perPage);
    }

    get pageStartRecord(): number {
        return Math.round((this.currentPage - 1) * this.perPage + 1);
    }

    get pageEndRecord(): number {
        return Math.round(Math.min(this.recordsCount, this.currentPage * this.perPage));
    }

    onPerPageChange({ value }: { value: number }): void {
        this.changePage(1);
    }

    changePage(page: number): void {
        this.currentPage = page;

        this.pageChanged.emit({
            startRecord: this.pageStartRecord,
            endRecord: this.pageEndRecord,
            pageNumber: page
        });
    }

    private countMismatch(): boolean {
        return this.currentPage > 1 && ((this.currentPage - 1) * this.pageSize) > this.recordsCount;
    }

}
