import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AggregatedDbFieldsType } from '@app/modules/inputs/types/aggregated-db-fields.type';
import { IconDefinition, faTimes } from '@fortawesome/pro-light-svg-icons';

@Component({
    selector: 'fdx-basic-transformer-field',
    templateUrl: './basic-transformer-field.component.html',
    styleUrls: ['./basic-transformer-field.component.scss']
})
export class BasicTransformerFieldComponent implements OnInit {

    @Input() dbFields: AggregatedDbFieldsType;

    @Input() fieldGroup: UntypedFormGroup;

    @Output() removeFieldClick: EventEmitter<void> = new EventEmitter<void>();

    @Input() disabled: boolean;
    @Input() dragging?: boolean = false;

    formControlName: string;
    xIcon: IconDefinition = faTimes;

    constructor() { }

    ngOnInit(): void {
        this.formControlName = Object.keys(this.fieldGroup.controls)[0];
    }

    removeField(): void {
        this.removeFieldClick.emit();
    }

}
