import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';
import { environment } from './environments/environment';

import * as angular from 'angular';

import { setAngularJSGlobal } from '@angular/upgrade/static';

import 'codemirror/addon/display/placeholder';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/mode/simple';
import 'codemirror/mode/javascript/javascript';

if (environment.production) {
    enableProdMode();
}

setAngularJSGlobal(angular);

void platformBrowserDynamic().bootstrapModule(AppModule);
