import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BaseComponent } from '@app/core-legacy/abstract/base.component';
import { isEmpty } from '@app/core/functions/isEmpty';
import { InputFieldOrValueComponent } from '@app/modules/inputs/components/input-field-or-value/input-field-or-value.component';
import { OperatorConstants } from '@app/modules/inputs/constants/operator.constants';
import { BasicQueryUtilities } from '@app/modules/inputs/services/basic-query-utilities.service';
import { AggregatedDbFieldsType } from '@app/modules/inputs/types/aggregated-db-fields.type';
import { OperatorType } from '@app/modules/inputs/types/operator.type';
import { IconDefinition, faTimes } from '@fortawesome/pro-light-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { takeUntil } from 'rxjs';

@Component({
    selector: 'fdx-basic-query-row',
    templateUrl: './basic-query-row.component.html',
    styleUrls: ['./basic-query-row.component.scss']
})
export class BasicQueryRowComponent extends BaseComponent implements OnInit {

    @Input() dbFields: AggregatedDbFieldsType;
    allOperators = Object.values(OperatorConstants.allOperators);

    @Input() inner: string;
    @Input() row: string;
    @Input() rowGroup: UntypedFormGroup;

    @Output() removeRowClick: EventEmitter<void> = new EventEmitter<void>();

    @Input() disabled: boolean;

    @ViewChild(InputFieldOrValueComponent) valueComponent: InputFieldOrValueComponent;

    get fieldPrefix(): string {
        return this.basicQueryUtilities.getFieldPrefix(this.inner, this.row);
    }

    get fieldName(): string {
        return `${this.fieldPrefix}field`;
    }

    get operatorName(): string {
        return `${this.fieldPrefix}operator`;
    }

    get valueName(): string {
        return `${this.fieldPrefix}value`;
    }

    hideValue: boolean = false;
    multiple: boolean = false;

    xIcon: IconDefinition = faTimes;
    infoIcon: IconDefinition = faInfoCircle;

    constructor(
        private readonly basicQueryUtilities: BasicQueryUtilities
    ) {
        super();
    }

    ngOnInit(): void {
        this.checkSpecialCases(this.rowGroup.value[this.operatorName]);
        this.rowGroup.controls[this.operatorName].valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe({
            next: (newOperatorName: string) => {
                this.checkSpecialCases(newOperatorName);
            }
        })
    }

    checkSpecialCases(newOperatorName: string): void {
        const operator: OperatorType = OperatorConstants.allOperators[newOperatorName];
        if (operator) {
            const oldValue = this.rowGroup.value[this.valueName];

            this.hideValue = operator.hideValue;
            if (this.hideValue && this.valueComponent && !isEmpty(oldValue)) {
                this.valueComponent.patchSelection(null);
            }

            this.multiple = operator.multiple;  // patchSelection happens in component
        }
    }

    fieldsGroupByFn = (item) => 'Field';

    get showRemoveRow(): boolean {
        return (Object.keys(this.rowGroup.parent.controls)).length > 1;
    }

    removeRow(): void {
        this.removeRowClick.emit();
    }
}
