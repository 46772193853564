export default class AttributionDataService {
    // Services
	$http = null;
	$q = null;

	constructor($http, $q) {
		this.$http = $http;
		this.$q = $q;
	}

	update(dbId, data) {
		const def = this.$q.defer();

		const promise = this.$http.post(`/api.php/dbs/${dbId}/attribution_info`, data);

		promise.then(
			(response) => {
				def.resolve(response.data);
			},
			(response) => {
				def.reject(response?.data);
			}
		);

		return def.promise;
	}

	getByDbId(dbId) {
		const def = this.$q.defer();

		const promise = this.$http.get(`/api.php/dbs/${dbId}/attribution_info`);

		promise.then(
			(response) => {
				def.resolve(response.data);
			},
			(response) => {
				def.reject(response.data);
			}
		);

		return def.promise;
	}

	runAttributor(dbId, inputs) {
		const def = this.$q.defer();

		const promise = this.$http.post(`/api.php/dbs/${dbId}/run_attributor`, inputs);

		promise.then(
			(response) => {
				def.resolve(response.data);
			},
			(response) => {
				def.reject(response.data);
			}
		);

		return def.promise;
	}
	
    clearCache(dbId) {
		const def = this.$q.defer();

		const promise = this.$http.post(`/api.php/dbs/${dbId}/attribution_info/clear_cache`);

		promise.then(
			(response) => {
				def.resolve(response.data);
			},
			(response) => {
				def.reject(response.data);
			}
		);

		return def.promise;
    }
}

AttributionDataService.$inject = ['$http', '$q'];