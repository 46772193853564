<button type="button" class="accordion-button px-2 py-0" [ngClass]="{'title-only': titleOnly}" [ngbPanelToggle]="panel">
    <div class="d-flex gap-1 align-items-center">
        <ng-template #tipContent let-text="text">{{text}}</ng-template>
        <button
            *ngIf="!data.hideCopy"
            type="button"
            class="btn shadow-none btn-link text-secondary"
            fdxStopPropagation
            [ngbTooltip]="tipContent"
            triggers="manual"
            #tip="ngbTooltip"
            (click)="copyToClipboard(tip)">
            <fa-icon class="no-margin" size="lg" [icon]="iconCopy"></fa-icon>
        </button>
        <span [innerHTML]="data.title" class="py-2" [ngClass]="{'ps-2': data.hideCopy}"></span>
    </div>
</button>
