import { PrefixSuffixActionType } from './fdx-file-maps-add-remove-prefix-suffix-modal.enums';

// interface ResolverInterface { }

export default class FdxFileMapsAddRemovePrefixSuffixModalController {

	// from uibmodal
	private close: (result?: { $value }) => void;
	private dismiss: (result?: { $value: string }) => void;
	// private resolve: ResolverInterface;

	public actionTypeEnum: Record<string, string> = PrefixSuffixActionType;

	public prefixAction: PrefixSuffixActionType = null;
	public suffixAction: PrefixSuffixActionType = null;

	public prefix: string = '';
	public suffix: string = '';

	static $inject = [];

	constructor() { }

	public ready(): boolean {
		if (this.prefixAction && this.prefix) {
			return true;
		}

		if (this.suffixAction && this.suffix) {
			return true;
		}

		return false;
	}

	public onSubmit(): void {
		if (!this.ready()) {
			return;
		}

		this.close(
			{
				$value: {
					prefixAction: this.prefixAction,
					suffixAction: this.suffixAction,
					prefix: this.prefix,
					suffix: this.suffix,
				}
			}
		);
	}

	public onCancel(): void {
		this.dismiss();
	}
}
