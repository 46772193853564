import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PageSubtitleComponent } from '@app/modules/page/components/page-subtitle/page-subtitle.component';
import { PageTitleComponent } from '@app/modules/page/components/page-title/page-title.component';

@NgModule({
    declarations: [
        PageSubtitleComponent,
        PageTitleComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        PageSubtitleComponent,
        PageTitleComponent
    ]
})
export class PageModule { }
