import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule } from '@app/modules/inputs';
import { SearchBarComponent } from '@app/modules/search-bar/components/search-bar/search-bar.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [
        SearchBarComponent
    ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        FormsModule,
        InputsModule,
        NgbPopoverModule,
        ReactiveFormsModule
    ],
    exports: [
        SearchBarComponent
    ]
})
export class SearchBarModule { }
