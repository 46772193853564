import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, Self, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { LocalStorageService } from '@app/core/services/local-storage.service';
import { LogService } from '@app/core/services/log.service';
import { CodeMirrorConstants } from '@app/modules/inputs/constants/code-mirror.constants';
import { QueryType } from '@app/modules/inputs/enums/query-type.enum';
import { CodeMirrorOptionsModel } from '@app/modules/inputs/models/code-mirror-options.model';
import { BasicQueryUtilities } from '@app/modules/inputs/services/basic-query-utilities.service';
import { BasicQueryService } from '@app/modules/inputs/services/basic-query.service';
import { AggregatedDbFieldsType } from '@app/modules/inputs/types/aggregated-db-fields.type';
import { BasicQueryData } from '@app/modules/inputs/types/basic-query-data.type';
import { BasicQueryComponent } from '@app/modules/query/components/basic-query/basic-query.component';
import { IconDefinition, faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { Subject, catchError, debounceTime, of, switchMap, takeUntil, tap } from 'rxjs';

@Component({
    selector: 'fdx-query-inputs',
    templateUrl: './query-inputs.component.html',
    styleUrls: ['./query-inputs.component.scss'],
    providers: [
        BasicQueryService
    ]
})
export class QueryInputsComponent implements OnChanges, OnDestroy {

    @Input() dbFields: AggregatedDbFieldsType;

    @Input() queryString: string = '';
    @Output() readonly queryStringChange: EventEmitter<string> = new EventEmitter<string>();

    @Input() transformerString?: string;
    @Output() readonly transformerStringChange?: EventEmitter<string> = new EventEmitter<string>();

    @Output() readonly selectorColumns: EventEmitter<string[]> = new EventEmitter<string[]>();

    @Input() disabled?: boolean = false;

    @Input() codeMirrorOptions: CodeMirrorOptionsModel = CodeMirrorConstants.defaultOptions;

    @Input() advancedQueryControlName?: string = 'advancedQueryControl';
    @Input() advancedQueryGroupName?: string = 'advancedQueryGroup';
    @Input() basicQueryGroupName?: string = 'basicQueryGroup';

    @Input() advancedQueryForm?: UntypedFormGroup;
    @Input() basicQueryForm?: UntypedFormGroup;
    @Input() queryForm?: UntypedFormGroup;

    @ViewChild(BasicQueryComponent) readonly basicQueryComponent: BasicQueryComponent;

    @Input() advancedQueryCheatSheet?: boolean = false;

    readonly unsubscribe$: Subject<void> = new Subject<void>();
    readonly faMagnifyingGlass: IconDefinition = faMagnifyingGlass;

    basicQueryData: BasicQueryData;
    updateQueryData$: Subject<void> = new Subject<void>();

    get usingBasicMode(): boolean {
        return this.basicQueryData.queryType === QueryType.Basic;
    }

    get advancedQuery(): UntypedFormControl {
        return this.advancedQueryForm?.controls[this.advancedQueryControlName] as UntypedFormControl;
    }

    get transformerMode(): boolean {
        return this.transformerString !== undefined;
    }

    get advancedDoubleMinHeight(): boolean {
        return !this.basicQueryData.transformerMode;
    }

    get advancedLeadingIcon(): IconDefinition | undefined {
        if (this.basicQueryData.transformerMode) {
            return undefined;
        }

        return this.faMagnifyingGlass;
    }

    constructor(
        @Self() private readonly basicQueryService: BasicQueryService,
        private readonly basicQueryUtilities: BasicQueryUtilities,
        private readonly localStorageService: LocalStorageService,
        private readonly logService: LogService
    ) {
       this.watchQueryDataUpdates();
    }

    private watchQueryDataUpdates(): void {
        this.updateQueryData$.pipe(
            debounceTime(250),
            switchMap(() => this.basicQueryService.getBasicQueryData(this.queryString, this.transformerString, this.basicQueryData?.queryType)),
            tap((data: BasicQueryData) => {
                let callSetupForm: boolean = false;
                if (!this.basicQueryData && !this.queryForm) {
                    callSetupForm = true;
                }

                this.basicQueryData = data;

                let switchedQueryType: boolean = false;
                // If user's query type is set to Basic, but there is an error or if the query is too complex,
                // then switch the query type back to Advanced
                if (this.basicQueryData?.queryType === QueryType.Basic &&
                    (this.basicQueryData?.tooComplex || this.basicQueryData?.error)) {
                    switchedQueryType = true;
                    this.basicQueryData.queryType = QueryType.Advanced;
                }

                if (callSetupForm) {
                    this.setupForm();
                } else if (switchedQueryType) {
                    this.updateControls();
                }

                this.selectorColumns.next(data.selectorColumns);
            }),
            catchError((err: unknown) => {
                this.logService.error('[QueryInputsComponent] Failed to get query data:', err);
                this.watchQueryDataUpdates();
                return of(null);
            }),
            takeUntil(this.unsubscribe$)
        ).subscribe();
    }

    setupForm(): void {
        if (!this.advancedQueryForm) {
            this.advancedQueryForm = new UntypedFormGroup({
                [this.advancedQueryControlName]: new UntypedFormControl({ value: this.queryString, disabled: this.disabled })
            });
        }

        if (!this.basicQueryForm) {
            this.basicQueryForm = new UntypedFormGroup({});
        }

        this.queryForm = new UntypedFormGroup({});
        this.updateControls();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.disabled) {
            this.codeMirrorOptions.readOnly = this.disabled ? 'nocursor' : false;
            if (this.advancedQuery) {
                if (this.disabled) {
                    this.advancedQuery.disable();
                } else {
                    this.advancedQuery.enable();
                }
            }
        }

        if (changes.queryString || changes.transformerString) {
            this.updateBasicQueryService();
        }
    }

    ngOnDestroy(): void {
        this.updateQueryData$.complete();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    /**
     * Resets the query strings and BasicQueryData object to their empty states
     */
    reset(): void {
        this.updateStrings();
    }

    /**
     * Update the queryString and the transformerString with passed in values.
     * Pass in nothing to reset the form. Will handle the change the same as ngOnChanges.
     * @param queryString the new queryString (default '')
     * @param transformerString the new transformerString (default '\'\'', which is an empty transformer.transformer value)
     */
    updateStrings(queryString: string = '', transformerString: string = '\'\''): void {
        this.queryString = queryString;
        if (this.basicQueryData.transformerMode) {
            this.transformerString = transformerString;
        }

        this.updateBasicQueryService();
    }

    /**
     * Sets the basicQueryData to loading and requests the tokenizer to update
     */
    private updateBasicQueryService(): void {
        if (this.basicQueryData && !this.basicQueryData.loading) {
            this.basicQueryData.loading = true;
        }
        this.updateQueryData$.next();
    }

    /**
     * Toggle the QueryType mode to the opposite mode
     */
    toggleQueryMode(): void {
        if (this.usingBasicMode) {
            this.updateQueryFromBasic();
            this.basicQueryData.queryType = QueryType.Advanced;
        } else {
            this.basicQueryData.queryType = QueryType.Basic;
        }

        this.updateControls();

        this.localStorageService.setItem(
            this.basicQueryData.transformerMode ? this.basicQueryUtilities.transformerTypeKey : this.basicQueryUtilities.queryTypeKey,
            this.basicQueryData.queryType
        );
    }

    /**
     * Updates the query string based on the basic query value
     */
    updateQueryFromBasic(): void {
        this.queryString = this.basicQueryComponent.valuesToQuery();
        this.queryStringChanged();
    }

    /**
     * Updates the internal queryString to the latest value if using basic mode, then returns the queryString
     * @returns the latest queryString, after it may have been updated from basic mode
     */
    getLatestQueryString(): string {
        if (this.usingBasicMode && !this.basicQueryData.loading) {
            this.queryString = this.basicQueryComponent.valuesToQuery();
        }

        return this.queryString;
    }

    /**
     * Marks the form as touched and returns if the form is valid
     * @returns whether or not the form is valid
     */
    validate(): boolean {
        this.queryForm.markAllAsTouched();
        return this.queryForm.valid;
    }

    updateControls(): void {
        if (this.usingBasicMode) {
            this.queryForm.removeControl(this.advancedQueryGroupName);
            this.queryForm.addControl(this.basicQueryGroupName, this.basicQueryForm);
        } else {
            this.queryForm.removeControl(this.basicQueryGroupName);
            this.queryForm.addControl(this.advancedQueryGroupName, this.advancedQueryForm);
        }
    }

    updateComponentQuery(switching: boolean = false): void {
        if (this.basicQueryComponent &&
            (this.usingBasicMode || switching) &&                                           // Only update when using Basic mode or when switching to Advanced mode
            (!this.queryForm.parent || this.queryForm.parent.controls['selectorForm'])) {   // and if our FormGroup has no parent (data pages) or if it has a 'selectorForm' sibling (transformer 'some rows' mode)
            this.updateQueryFromBasic();
        }
    }

    queryStringChanged(): void {
        this.queryStringChange.emit(this.queryString);
    }

    transformerStringChanged(): void {
        this.transformerStringChange.emit(this.transformerString);
    }
}
