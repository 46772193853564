import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

// Modules
import { CoreLegacyModule } from '@app/core-legacy/core-legacy.module';
import { PageModule } from '@app/modules/page/page.module';

// Pages
import { FeedtelligencePageComponent } from './pages/feedtelligence-page/feedtelligence-page.component';

@NgModule({
    declarations: [
        FeedtelligencePageComponent
    ],
    imports: [
        CommonModule,
        CoreLegacyModule,
        PageModule
    ]
})
export class FeedtelligenceModule { }
