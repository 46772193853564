<fdx-form-section header="Export retries">
    <form [formGroup]="form">
        <div class="row mt-3">
            <label class="col-sm-4 offset-sm-2 col-form-label text-end">
                <span>Total attempts </span>
                <fa-icon [icon]="iconHelp"
                         ngbPopover="If Feedonomics is unable to push your feed to the receiving party (i.e. Network failure or transient failure on receiving side), Feedonomics will retry up to Total Attempts number of times."
                         triggers="mouseenter:mouseleave"
                         popoverTitle="Total Attempts">
                </fa-icon>
            </label>

            <div class="col-sm-4">
                <input type="number"
                       class="form-control"
                       placeholder="1"
                       formControlName="maxAttempts">
            </div>
        </div>

        <div class="row mt-3">
            <label class="col-sm-4 offset-sm-2 col-form-label text-end">
                <span>Wait time (s) </span>
                <fa-icon [icon]="iconHelp"
                         ngbPopover="Feedonomics will wait 'Wait Time' number of seconds before retrying your export."
                         triggers="mouseenter:mouseleave"
                         popoverTitle="Wait Time">
                </fa-icon>
            </label>

            <div class="col-sm-4">
                <input type="number"
                       class="form-control"
                       placeholder="30"
                       formControlName="timeBetweenAttempts">
            </div>
        </div>
    </form>
</fdx-form-section>
