import { Component } from '@angular/core';
import { Modal } from '@app/modules/modals/modals.module';
import { TransformerFieldDependencyModel } from '@app/transformers/models/transformer-field-dependency.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

interface ResolverInterface {
    transformerFieldDependency: TransformerFieldDependencyModel,
}

@Component({
    selector: 'fdx-transformers-field-dependency-tree-modal',
    templateUrl: './transformers-field-dependency-tree-modal.component.html',
    styleUrls: ['./transformers-field-dependency-tree-modal.component.scss']
})
export class TransformersFieldDependencyTreeModalComponent implements Modal {

    transformerFieldDependency: TransformerFieldDependencyModel;

    constructor(
        private readonly modal: NgbActiveModal
    ) { }

    onModalInit(resolve: ResolverInterface): void {
        this.transformerFieldDependency = resolve.transformerFieldDependency;
    }

    onModalCancel(_event: Event): void {
        this.modal.dismiss();
    }
}
