import * as angular from 'angular';
import FdxModalHocController from './fdx-modal-hoc.controller';
import { MODULE_NAME } from '../../config/constants';

import './fdx-modal-hoc.styles.scss';

export const componentName = 'fdxModalHoc';

angular.module(MODULE_NAME).component(componentName, {
	template: require('./fdx-modal-hoc.template.html'),
	controller: FdxModalHocController,
	transclude: true,
	bindings: {
		modalTheme: '@?',
		modalTitle: '@?',
		secondaryButton: '<?',
		submitButton: '<?',
		cancelButton: '<?',
		secondaryText: '@?',
		submitText: '@?',
		cancelText: '@?',
		secondaryDisabled: '<?',
		submitDisabled: '<?',
		cancelDisabled: '<?',
		onSecondary: '&?',
		onSubmit: '&?',
		onCancel: '&?'
	}
});
