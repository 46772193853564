import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TestFtpAccountRequest } from '@app/ftp-account/services/requests/test-ftp-account.request';
import { TestFtpAccountResultErrorResponse } from '@app/ftp-account/services/responses/test-ftp-account-result-error.response';
import { ToggleDisableFtpResponse } from '@app/ftp-account/services/responses/toggle-disable-ftp.response';
import { catchError, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PutFtpAccountRequest } from './requests/put-ftp-account.request';
import { GetFtpAccountsResponse } from './responses/get-ftp-accounts.response';
import { GetFtpQuotaSizeResponse } from './responses/get-ftp-quota-size.response';

export type TestFtpParams = {
    host: string;
    username: string;
    password: string;
    protocol: string;
    protocol_info?: {
        login_type: string;
        private_key: string;
        private_key_pass: string;
    };
}

@Injectable({
  providedIn: 'root'
})
export class FtpAccountDataService {
  constructor(
    private http: HttpClient
  ) { }

  public createFtpAccount(databaseId: string): Observable<GetFtpAccountsResponse> {
    return this.http.post<GetFtpAccountsResponse>('/api.php/dbs/' + databaseId + '/ftp_accounts', {});
  }

  public getFtpAccounts(databaseId: string, accountDefault: boolean = false): Observable<GetFtpAccountsResponse> {
    return this.http.get<GetFtpAccountsResponse>('/api.php/dbs/' + databaseId + '/ftp_accounts', {params: {'account_default': accountDefault}});
  }

  public putFtpAccounts(databaseId: string, params: PutFtpAccountRequest): Observable<GetFtpAccountsResponse> {
    return this.http.put<GetFtpAccountsResponse>('/api.php/dbs/' + databaseId + '/ftp_accounts', params);
  }

  public getFtpQuotaSize(databaseId: string): Observable<GetFtpQuotaSizeResponse> {
    return this.http.get<GetFtpQuotaSizeResponse>('/api.php/dbs/' + databaseId + '/ftp_quota_size');
  }

    public testFtpAccount(databaseId: string, params: TestFtpAccountRequest): Observable<boolean | TestFtpAccountResultErrorResponse> {
        return this.http.post<boolean | TestFtpAccountResultErrorResponse>('/api.php/dbs/' + databaseId + '/test_ftp', params).pipe(
            map(response => {
                return response === null;
            }),
            catchError(response => {
                return of(new TestFtpAccountResultErrorResponse(response.error));
            })
        );
    }

  // TODO replace with the above function
  public testFtp(databaseId: string, data: TestFtpParams): Observable<unknown> {
      return this.http.post<unknown>(`/api.php/dbs/${databaseId}/test_ftp`, data);
  }

  public toggleDisableFtp(databaseId: string, params: { disable_ftp: '0' | '1' }): Observable<ToggleDisableFtpResponse> {
      return this.http.post<ToggleDisableFtpResponse>('/api.php/dbs/' + databaseId + '/toggle_disable_ftp', params);
  }
}
