import { FdxError } from '@app/core/models/classes/fdx-error.class';
import { FdxApiResponse } from '@app/core/responses/fdx-api.response';
import { WebhookParamsInterface } from '@app/exports/models/interfaces/webhook-params.interface';
import { WebhookTypeOptionInterface } from '@app/exports/models/interfaces/webhook-type-option.interface';
import { WebhookTypeInterface } from '@app/exports/models/interfaces/webhook-type.interface';
import { IDeferred, IHttpResponse, IHttpService, IPromise, IQService } from 'angular';

export class WebhooksTypesDataService {

    static $inject: string[] = ['$http', '$q'];

    constructor(
        private readonly $http: IHttpService,
        private readonly $q: IQService
    ) {
    }

    getWebhookTypeOptions(): IPromise<WebhookTypeOptionInterface[]> {

        const def: IDeferred<WebhookTypeOptionInterface[]> = this.$q.defer();

        this.$http.get('/api.php/webhook_type').then(
            (response: IHttpResponse<FdxApiResponse<WebhookTypeInterface[]>>) => {

                const options = response.data.data.map((entry) => {
                    return {
                        value: entry.webhook_type,
                        display_name: entry.display_name,
                        group: entry.group
                    }
                })

                def.resolve(options);

            },
            (response: IHttpResponse<FdxApiResponse<undefined>>) => {
                def.reject(new FdxError('ERROR', response.data.message));
            }
        )

        return def.promise;

    }

    getWebhookTypeParams(): IPromise<Record<string, { params: WebhookParamsInterface }>> {

        const def: IDeferred<Record<string, { params: WebhookParamsInterface }>> = this.$q.defer();

        this.$http.get('/api.php/webhook_type').then(
            (response: IHttpResponse<FdxApiResponse<WebhookTypeInterface[]>>) => {

                const dataParams = {};

                response.data.data.forEach((entry) => {
                    dataParams[entry.webhook_type] = {
                        params: JSON.parse(entry.params) as WebhookParamsInterface
                    }
                })

                def.resolve(dataParams);

            },
            (response: IHttpResponse<FdxApiResponse<undefined>>) => {
                def.reject(new FdxError('ERROR', response.data.message));
            }
        )

        return def.promise;

    }

}
