<modal-hoc
    [modalTheme]="modalTheme"
    [modalTitle]="title"
    [submitButton]="false"
    [cancelButton]="false"
    (modalCancel)="onModalCancel($event)">
    <fdx-category-paths
        [categoryPaths]="config?.categoryPaths">
    </fdx-category-paths>
</modal-hoc>
