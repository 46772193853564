var FeedWizardStep = function () {
	this.endpoint = function () {};
	this.method = null;
	this.onError = function () {};
	this.onSuccess = function () {};
	this.params = function () {};
};

FeedWizardStep.prototype.setEndpoint = function (func) {
	if (typeof func !== 'function') {
		throw new Error('Endpoint must be a function that returns the endpoint');
	}

	this.endpoint = func;
	return this;
};

FeedWizardStep.prototype.setMethod = function (str) {
	var allowed = ['get', 'post', 'put', 'delete', 'patch'];

	if (allowed.indexOf(str) === -1) {
		throw new Error('Method not recognized (Allowed values: ' + allowed.join(', ') + ')');
	}

	this.method = str;
	return this;
};

FeedWizardStep.prototype.setOnError = function (func) {
	if (typeof func !== 'function') {
		throw new Error('onError must be a function');
	}

	this.onError = func;
	return this;
};

FeedWizardStep.prototype.setOnSuccess = function (func) {
	if (typeof func !== 'function') {
		throw new Error('onSuccess must be a function');
	}

	this.onSuccess = func;
	return this;
};

FeedWizardStep.prototype.setParams = function (func) {
	if (typeof func !== 'function') {
		throw new Error('Params must be a function that returns an object of parameters');
	}

	this.params = func;
	return this;
};

FeedWizardStep.prototype.toObject = function () {
	return {
		'endpoint': this.endpoint,
		'params': this.params,
		'method': this.method,
		'onError': this.onError,
		'onSuccess': this.onSuccess
	};
};

var FeedWizard = function ($http, $q) {
	this._$http = $http;
	this._$q = $q;
	this._steps = [];
	this._promise = null;
	this._current_step_index = 0;
};

FeedWizard.prototype.isRunning = function () {
	return this._promise !== null;
};

FeedWizard.prototype.addStep = function (step) {
	if (!(step instanceof FeedWizardStep)) {
		throw new Error('Step must be an instance of FeedWizardStep');
	}

	this._steps.push(step.toObject());

	return this;
};

FeedWizard.prototype.addSteps = function (steps) {
	if (!Array.isArray(steps)) {
		throw new Error('addSteps must take an array of steps!');
	}

	steps.map(this.addStep, this);

	return this;
};

FeedWizard.prototype.removeStep = function (index) {
	this._steps.splice(index, 1);
};

FeedWizard.prototype.setCurrentStepIndex = function(step) {
	this._current_step_index = step;
	return this;
};

FeedWizard.prototype.runStep = function (step) {
	return this._$http[step.method](step.endpoint(), step.params());
};

FeedWizard.prototype.run = function (to_index) {
	var myThis = this;

	this._promise = this._$q.defer();

	var runStep = function () {
		var step = myThis._steps[myThis._current_step_index];
		if (!step || (myThis._current_step_index === to_index)) {
			myThis._promise.resolve();
			myThis._promise = null;
			return;
		}

		myThis._promise.notify({
			'current_step': myThis._current_step_index,
			'total_steps': myThis._steps.length
		});

		myThis.runStep(step).then(function (response) {
			step.onSuccess(response.data);

			myThis._current_step_index++;

			runStep();
		}, function (response) {
			step.onError(response.data);

			myThis._promise.reject(response.data);
			myThis._promise = null;
		});
	};

	runStep();

	return this._promise.promise;
};

window.FeedWizard = FeedWizard;
window.FeedWizardStep = FeedWizardStep;