import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TransformerFieldDependencyModel } from '@app/transformers/models/transformer-field-dependency.model';
import { TransformerModel } from '@app/transformers/models/transformer.model';
import { DeleteTransformersRequest } from '@app/transformers/services/requests/delete-transformers.request';
import { SortTransformersRequest } from '@app/transformers/services/requests/sort-transformers.request';
import { TokenizeTransformerRequest } from '@app/transformers/services/requests/tokenize-transformer.request';
import { TransformerRequest } from '@app/transformers/services/requests/transformer.request';
import { TransformersRequest } from '@app/transformers/services/requests/transformers.request';
import { DeleteAllTransformersResponse } from '@app/transformers/services/responses/delete-all-transformers.response';
import { TokenizeTransformerResponse } from '@app/transformers/services/responses/tokenize-transformer.response';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TransformersDataService {
    constructor(private readonly http: HttpClient) {}

    getTransformers(databaseId: string, params: TransformersRequest | Record<string, never> = {}): Observable<TransformerModel[]> {
        return this.http.get<TransformerModel[]>(`/api.php/dbs/${databaseId}/transformers`, {params: params});
    }

    getTransformerFieldDependencies(databaseId: string, params: TransformersRequest): Observable<TransformerFieldDependencyModel> {
        return this.http.get<TransformerFieldDependencyModel>(`/api.php/dbs/${databaseId}/transformer_field_dependencies`, {params: params});
    }

    addTransformer(databaseId: string, params: TransformerRequest): Observable<TransformerModel> {
        return this.http.post<TransformerModel>(`/api.php/dbs/${databaseId}/transformers`, params);
    }

    editTransformer(databaseId: string, transformerId: string, params: TransformerRequest): Observable<TransformerModel> {
        return this.http.put<TransformerModel>(`/api.php/dbs/${databaseId}/transformers/${transformerId}`, params);
    }

    deleteTransformers(databaseId: string, params: DeleteTransformersRequest): Observable<void> {
        return this.http.delete<void>(`/api.php/dbs/${databaseId}/transformers`, {
            body: params
        });
    }

    deleteAllTransformers(databaseId: string, fieldName: string ): Observable<DeleteAllTransformersResponse> {
        return this.http.delete<DeleteAllTransformersResponse>(`/api.php/dbs/${databaseId}/transformers/${fieldName}`);
    }

    changeTransformerSortOrder(databaseId: string, transformerId: string, params: SortTransformersRequest): Observable<TransformerModel> {
        return this.http.put<TransformerModel>(`/api.php/dbs/${databaseId}/transformers/${transformerId}/sort_order`, params);
    }

    tokenize(databaseId: string, params: TokenizeTransformerRequest): Observable<TokenizeTransformerResponse> {
        return this.http.get<TokenizeTransformerResponse>(`/api.php/dbs/${databaseId}/transformers/tokenize`, { params });
    }
}
