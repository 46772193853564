import { Component, Input } from '@angular/core';
import { CheatSheetContentType } from '@app/modules/cheat-sheet/models/cheat-sheet-content.type';

@Component({
    selector: 'fdx-cheat-sheet-panel-content',
    templateUrl: './cheat-sheet-panel-content.component.html',
    styleUrls: ['./cheat-sheet-panel-content.component.scss']
})
export class CheatSheetPanelContentComponent {
    @Input() data: CheatSheetContentType;
}
