import { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';

export const faCalendarBolt: IconDefinition = {
    prefix: 'fafeed' as IconPrefix,
    iconName: 'calendar-bolt' as IconName,
    icon: [
        512,
        402,
        [],
        '',
        'M27.4968 153.5C64.0525 90.0781 131.645 51 204.836 51C278.027 51 345.62 90.0781 382.175 153.5C391.614 169.836 398.573 187.133 403.133 204.91C401.053 204.83 398.893 204.75 396.814 204.75C362.817 204.75 331.541 216.842 307.224 236.941V230.375H102.448V332.875C102.448 347.049 113.887 358.5 128.045 358.5H256.59C259.47 390.211 272.828 418.799 293.066 440.98C265.949 453.953 235.872 461 204.756 461C131.565 461 63.9725 421.922 27.4168 358.5C-9.13893 295.078 -9.13893 216.922 27.4168 153.5H27.4968ZM102.448 179.125V204.75H307.224V179.125C307.224 164.951 295.785 153.5 281.627 153.5H256.03V140.688C256.03 133.641 250.271 127.875 243.232 127.875C236.192 127.875 230.433 133.641 230.433 140.688V153.5H179.239V140.688C179.239 133.641 173.48 127.875 166.44 127.875C159.401 127.875 153.642 133.641 153.642 140.688V153.5H128.045C113.887 153.5 102.448 164.951 102.448 179.125ZM281.627 345.688C281.627 282.025 333.221 230.375 396.814 230.375C460.406 230.375 512 282.025 512 345.688C512 409.35 460.406 461 396.814 461C333.221 461 281.627 409.35 281.627 345.688ZM342.9 348.73C344.18 352.654 347.859 355.297 352.019 355.297H380.495L356.418 398.699C354.179 402.703 355.058 407.588 358.498 410.631C361.938 413.674 366.977 413.834 370.577 411.111L447.368 353.455C450.647 350.973 452.007 346.648 450.727 342.725C449.447 338.801 445.768 336.158 441.608 336.158H413.132L437.209 292.756C439.449 288.752 438.569 283.867 435.129 280.824C431.689 277.781 426.65 277.621 423.05 280.344L346.259 338C342.98 340.482 341.62 344.807 342.9 348.73Z'
    ]
}
