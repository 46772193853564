
export default class FdxFtpTriggersTriggerableHostsModalController {

	// from uibmodal
	private close: (result?: { $value }) => void;
	private dismiss: (result?: { $value: string }) => void;

	public acceptedHosts: string[] = [
		'app.feedonomics.com',
		'ftp3.feedonomics.com',
	];

	static $inject = [];

	constructor() { }

	public onCancel(): void {
		this.dismiss();
	}
}
