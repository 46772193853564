import { InjectionToken } from '@angular/core';

/**
 * Injection token for global document object.
 * 
 * Can be injected into components using:
 * ```typescript
 * constructor(@Inject(DOCUMENT) private document: Document)
 * ```
 * 
 * Can be mocked in unit tests:
 * ```typescript
 * TestBed.configureTestingModule({
 *   providers: [
 *     {
 *       provide: DOCUMENT,
 *       useValue: mockDocument
 *     }
 *   ]
 * });
 * ```
 */
export const DOCUMENT = new InjectionToken<Document>(
    'Document',
    {
        providedIn: 'root',
        factory(): Document {
            return document;
        }
    }
);
