import { Component } from '@angular/core';
import { Modal } from '@app/modules/modals/modals.module';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'fdx-transformers-faq-modal',
    templateUrl: './transformers-faq-modal.component.html',
    styleUrls: ['./transformers-faq-modal.component.scss']
})
export class TransformersFaqModalComponent implements Modal {

    constructor(
        private readonly modal: NgbActiveModal
    ) { }

    onModalCancel(_event: Event): void {
        this.modal.dismiss();
    }
}
