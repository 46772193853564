import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChannelsResponse, ErrorCodesResponse, ErrorDataResponse, RemoteUrlsResponse } from '@app/error-resolution/services/responses/error-resolution.response';
import { DataParams } from '@app/error-resolution/types/error-resolution-page.types';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ErrorResolutionDataService {
    constructor(private readonly http: HttpClient) { }

    public getData(databaseId: string, params:  DataParams): Observable<ErrorDataResponse> {
        return this.http.get<ErrorDataResponse>(`/api.php/dbs/${databaseId}/error_resolution_syndications`, {
            params: params
        });
    }

    public getErrorCodes(databaseId: string, channelName: string): Observable<ErrorCodesResponse> {
        const params = { params: { channel_name: channelName } };
        return this.http.get<ErrorCodesResponse>(`/api.php/dbs/${databaseId}/error_resolution_syndication_error_codes`, params);
    }

    public getChannels(databaseId: string): Observable<ChannelsResponse> {
        return this.http.get<ChannelsResponse>(`/api.php/dbs/${databaseId}/error_resolution_syndication_channels`);
    }

    public getRemoteUrls(databaseId: string): Observable<RemoteUrlsResponse> {
        return this.http.get<RemoteUrlsResponse>(`/api.php/dbs/${databaseId}/error_resolution_remote_urls`);
    }

    public deleteAllErrors(databaseId: string, channel: string): Observable<unknown> {
        return this.http.delete(`/api.php/dbs/${databaseId}/channel/${channel}/error_resolution_delete_all_errors`);
    }
}
