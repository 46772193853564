import * as angular from 'angular';
import { MODULE_NAME } from '../config/constants';

import { isEmpty } from '@app/core/functions/isEmpty';
import { componentName as addRemovePrefixSuffixModalComponentName } from '../components/fdx-file-maps-page/components/fdx-file-maps-add-remove-prefix-suffix-modal/fdx-file-maps-add-remove-prefix-suffix-modal.component';
import { componentName as confirmNotificationEmailModalComponentName } from '../components/fdx-file-maps-page/components/fdx-file-maps-confirm-notification-email-modal/fdx-file-maps-confirm-notification-email-modal.component';
import { componentName as faqModalComponentName } from '../components/fdx-file-maps-page/components/fdx-file-maps-faq-modal/fdx-file-maps-faq-modal.component';

angular.module(MODULE_NAME).controller('FileMapsController',
['$scope', '$http', '$state', '$stateParams', '$timeout', '$location', '$window', '$document', '$q', 'AppStateService', 'fdxUI', 'fdxUtils', 'FtpTriggersDataService', 'ImportFtpTriggerService', 'ModalService', 'FileMapService',
function($scope, $http, $state, $stateParams, $timeout, $location, $window , $document, $q, AppStateService, fdxUI, fdxUtils, FtpTriggersDataService, ImportFtpTriggerService, ModalService, FileMapService) {
	// Timeout is a temporary solution for helping select the tab when navigating to this page
    $timeout(() => {
        fdxUI.setTitle('File Maps');
        fdxUI.setActiveTab('data_sources');
    }, 1000);

    const databaseId = AppStateService.getDatabaseId();
    const fieldName = $stateParams.field_name;

	$scope.current_db = AppStateService.getDatabase();
	$scope.accountHasFeature = (feature, value) => AppStateService.getAccount().hasFeature(feature, value);
	$scope.userHasFeature = (feature, value) => AppStateService.getUser().hasFeature(feature, value);
	$scope.isPrivacyLevelAtLeastAnalyst = () => {
		return AppStateService.isPrivacyLevelAtLeastAnalyst();
	};

	$scope.show_db_template = () => {
        return AppStateService.isDbTemplateAccount();
	}

	function save_file_type_config(imprt, file_type) {
		imprt[file_type] = {
			auto_detect_finished: imprt.auto_detect_finished,
			auto_detect_in_progress: imprt.auto_detect_in_progress,
			clean_file_headers: imprt.clean_file_headers,
			delimiters_initially_set: imprt.delimiters_initially_set,
			delimiters_modified: imprt.delimiters_modified,
			error_xml_auto_populate: imprt.error_xml_auto_populate,
			file: imprt.file,
			raw_file: imprt.raw_file,
			file_map: imprt.file_map,
			file_transpose: imprt.file_transpose,
			line_terminator: imprt.line_terminator,
			name_based_maps: imprt.name_based_maps,
			saved_message_xml_force_parse: imprt.saved_message_xml_force_parse,
			saving_message_xml_force_parse: imprt.saving_message_xml_force_parse,
			xml_force_parse: imprt.xml_force_parse,
			xml_include_attrs: imprt.xml_include_attrs,
			xml_parse_recursively: imprt.xml_parse_recursively,
            xml_skip_sed: imprt.xml_skip_sed,
			convert_nested: imprt.convert_nested,
			preserve_boolean_literals: imprt.preserve_boolean_literals,
		};
	}

	function restore_file_type_config(imprt, file_type) {
		let config = imprt[file_type];
		if(config == null) {
			const name_based_maps = (file_type == 'delimited' && imprt.name_based_maps) ? '1' : '0';
			const maps = file_type == 'delimited' ? (name_based_maps ? {} : []) : [{'name':'', 'path':''}];
			config = {
				auto_detect_finished: false,
				auto_detect_in_progress: false,
				clean_file_headers: file_type == 'delimited' && imprt.clean_file_headers,
				delimiters_initially_set: file_type == 'delimited' && imprt.delimiters_initially_set,
				delimiters_modified: file_type == 'delimited' && imprt.delimiters_modified,
				error_xml_auto_populate: null,
				file: null,
				raw_file: null,
				file_map: { maps },
				file_transpose: [],
				line_terminator: file_type == 'delimited' && imprt.line_terminator,
				name_based_maps: name_based_maps,
				saved_message_xml_force_parse: file_type == 'xml' && imprt.saved_message_xml_force_parse,
				saving_message_xml_force_parse: file_type == 'xml' && imprt.saving_message_xml_force_parse,
				xml_force_parse: file_type == 'xml' && imprt.xml_force_parse,
				xml_include_attrs: file_type == 'xml' && imprt.xml_include_attrs,
				xml_parse_recursively: file_type == 'xml' && imprt.xml_parse_recursively,
                xml_skip_sed: file_type === 'xml' && imprt.xml_skip_sed,
				convert_nested: imprt.convert_nested,
				preserve_boolean_literals: imprt.preserve_boolean_literals,
			};
		}
		imprt.auto_detect_finished = config.auto_detect_finished;
		imprt.auto_detect_in_progress = config.auto_detect_in_progress;
		imprt.clean_file_headers = config.clean_file_headers;
		imprt.delimiters_initially_set = config.delimiters_initially_set;
		imprt.delimiters_modified = config.delimiters_modified;
		imprt.error_xml_auto_populate = config.error_xml_auto_populate;
		imprt.file = config.file;
		imprt.raw_file = config.raw_file;
		imprt.file_map = config.file_map;
		imprt.file_transpose = config.file_transpose;
		imprt.line_terminator = config.line_terminator;
		imprt.name_based_maps = config.name_based_maps;
		imprt.saved_message_xml_force_parse = config.saved_message_xml_force_parse;
		imprt.saving_message_xml_force_parse = config.saving_message_xml_force_parse;
		imprt.xml_force_parse = config.xml_force_parse;
		imprt.xml_include_attrs = config.xml_include_attrs;
		imprt.xml_parse_recursively = config.xml_parse_recursively;
        imprt.xml_skip_sed = config.xml_skip_sed;
		imprt.convert_nested = config.convert_nested;
		imprt.preserve_boolean_literals = config.preserve_boolean_literals;
	}

	$scope.ignore_join_involution = function(imprt, field) {
		if (!imprt.file_map.hasOwnProperty('ignore_join')) {
			imprt.file_map.ignore_join = [];
		}
		const field_index = $.inArray(field, imprt.file_map.ignore_join);
		if (field_index == -1) {
			imprt.file_map.ignore_join.push(field);
		} else {
			imprt.file_map.ignore_join.splice(field_index,1);
		}
		imprt.modified = true;
	};

	$scope.imports = [];
	$scope.import_running = false;
    $scope.many_fields = false;

    $scope.status = null;
	$scope.notification = { address : ''};

	// Initialize the import
	$scope.selected_import = {
		id: $location.search().import_id
	};

	//The suffix field
	$scope.auto_populate = {
		toggle: false,
		suffix: '',
	};

	$scope.file_types = [
		{
			value: 'delimited',
			display_name: 'Delimited (csv, tsv...)'
		},
		{
			value: 'xml',
			display_name: 'XML'
		},
		{
			value: 'json',
			display_name: 'JSON'
		},
		{
			value: 'ndjson',
			display_name: 'NDJSON'
		},
	];

	$scope.user = AppStateService.getUser();

	$scope.hex2sjis = function(hex) {
		hex = hex.trim().toUpperCase();
		hex = hex.replace(/[^0-9A-F]+/g,"");

		let teil;
		let str = '';

		for (let i = 0; i < hex.length; i += 2) {
			teil = hex.substring(i, i + 2);

			if (isNaN(FileMapService.sjisToUnicode(teil)))  {
				teil = hex.substring(i, i + 4);
				if (isNaN(FileMapService.sjisToUnicode(teil))) {
					teil = "3F"; // QUESTION MARK
				} else {
					i += 2;
				}
			}

			str += String.fromCharCode(FileMapService.sjisToUnicode(teil));
		}

		return str;
	}

	/*
		Since api.php passes around hex-encoded strings this
		function decodes to "UTF8" for two-byte chars.
	*/
	$scope.hex2bin = function(hex) {
		if (hex && hex.length > 150000) {
			return 'INPUT_LENGTH_ERROR';
		}

		if ($scope.selected_import && $scope.selected_import.file_map.encoding === 'sjis') {
			return $scope.hex2sjis(hex);
		}
		return fdxUtils.hex2bin(hex);
	}

	$scope.groupImportByType = function(item) {
		if (item.join_type=='product_feed') {
			return 'PRODUCT FEEDS';
		}
		else if (item.join_type=='aggregate') {
			return 'AGGREGATES'
		}
		else if (item.id=='extra') {
			return 'EXTRA FIELDS'
		}

			return 'JOINS';

	}

	$scope.change_import = function(imprt, is_file_type_change) {
		if (typeof imprt === 'undefined') {
			return;
		}

        if (imprt.id === 'extra') {
            $state.go(
                'app.extra-fields',
                {
                    id: databaseId
                }
            );
            return;
        }

        // Update the route/search param
		$location.search('import_id', imprt.id);

		if(is_file_type_change) {
			save_file_type_config(imprt, imprt.prev_file_type);
			restore_file_type_config(imprt, imprt.file_type);
		}
		imprt.prev_file_type = imprt.file_type;

		if (imprt.join_type !== 'aggregate') {
			// Fetch the file for the selected_import

			let params;

			// Request parsed or raw file depending on delimiters_initially_set
			if (imprt.delimiters_initially_set) {
				params = {
					'format':'parsed',
					'limit':4
				}
			}
			else {
				params = {
					'format': 'raw',
					'limit': 20000
				}
			}

			if (imprt.file_type=='json') {
				params.file_type = 'json';
			}

			if(imprt.file_type == 'ndjson') {
				params.file_type = 'ndjson';
			}

			imprt.file = null;

			$http({
				method: 'GET',
				url: '/api.php/dbs/' + databaseId + '/imports/' + imprt.id + '/file',
				params: params,
				// responseType: 'text',
				transformResponse: []
			})
			// .get('/api.php/dbs/' + databaseId + '/imports/' + imprt.id + '/file', {'params': params, 'responseType': 'text'})
				.then((response) => {
					imprt.file = response.data;

                    if (!imprt.delimiters_initially_set) {
                        const textArea = document.createElement('textarea');
                        textArea.innerHTML = imprt.file;
                        imprt.file = textArea.value;
                    }

					if (!imprt.file_type) {
						// first non-whitespace char
						const c = imprt.file.match(/^\s*(\S)?/)[1];
						if(c == '{' || c == '[') {
							imprt.file_type = 'json';

							// 1) try JSON.parse. if it fails, then:
							// 2) split into lines. if you find at least 2 full lines, then:
							// 3) JSON parse on the second line. if it parses, it's NDJSON
							try {
								// 1)
								JSON.parse(imprt.file);
							} catch(e) {
								if(!(e instanceof SyntaxError)) {
									throw e;
								}
								// 2)
								const lines = imprt.file.split('\n');
								let at_least_2_complete_lines = lines.length > 1;
								if(imprt.file.length >= 20000) {
									// files get cut off at 20000 chars, so if the file is that big
									// we need to ensure we're looking at a complete second line
									at_least_2_complete_lines = lines.length > 2;
								}
								if(at_least_2_complete_lines) {
									try {
										// 3)
										JSON.parse(lines[1]);
										imprt.file_type = 'ndjson'
									} catch(e) {}
								}
							}
						} else if(c == '<') {
							imprt.file_type = 'xml';
						} else {
							imprt.file_type = 'delimited';
							imprt.name_based_maps = '1';
						}
						restore_file_type_config(imprt, imprt.file_type);
						$scope.change_import(imprt);
						return;
					}

					if (imprt.file_type=='xml') {
						imprt.raw_file = response.data;
						imprt.file = imprt.file.replace( /<!\[CDATA\[([\s\S]*?)\]\]>/g, '$1' );
						imprt.file = vkbeautify.xml( vkbeautify.xmlmin(imprt.file) );

						imprt.xml_parse_recursively = true;
						imprt.xml_include_attrs = false;
					}

					if (imprt.delimiters_initially_set) {
						imprt.file = JSON.parse(imprt.file);
						// Generate Transpose Table, this should really be a directive
						imprt.file_transpose = imprt.file[0].map(function(col, i) {
							return imprt.file.map(function(row) {
								//call hex2bin once per field
								return {
									hex : row[i] ? row[i] : '',
									bin : row[i] ? $scope.hex2bin(row[i]) : ''
								};
							});
						});
					}

					save_file_type_config(imprt, imprt.file_type);
				});
		}
		save_file_type_config(imprt, imprt.file_type);
        $scope.selected_import = imprt;

	}

	/**
	 * Watch for changes of import_id param to handle user clicking 'Extra fields' or 'File Maps'
	 * from the nav-menu while already on the file_maps page. Make sure import_id param in URL
	 * and import_id in select-dropdown are consistent. Remove listener on destroy.
	 */
	$scope.importIdParamListener = () => {
		const newImportId = $location.search().import_id ?? '';
		if (newImportId !== $scope.selected_import.import_id && newImportId !== '') {
			const newIndex = $scope.imports.findIndex((imprt) => imprt.id===newImportId);
			$scope.change_import($scope.imports[newIndex]);
		} else if ($scope.imports.length > 0) {
			$scope.change_import($scope.imports[0]);
		}
	}
	$window.addEventListener('hashchange', $scope.importIdParamListener);
	$scope.$on('$destroy', () => {
		$window.removeEventListener('hashchange', $scope.importIdParamListener);
	});

	$scope.initialize_seen_db_fields = function() {
		// Calculate seen_before for import_fields
		$scope.db_fields_seen = {};
		for (let imprt_offset=0; imprt_offset<$scope.imports.length; imprt_offset++) {
			const imprt = $scope.imports[imprt_offset];
			if (imprt.id=='extra') {
				continue;
			}

			imprt.db_fields_seen = JSON.parse(JSON.stringify($scope.db_fields_seen))


			// Delimited
			if (imprt.file_type=='delimited') {
				// Name based delimited map

				if (imprt.name_based_maps==1) {
					const maps = imprt.file_map.maps
					for (const source_field_name in maps) {
						if (maps.hasOwnProperty(source_field_name)) {
							if (maps[source_field_name]=='') {
								continue;
							}
							// Notate that we've seen this source_field_name
							$scope.db_fields_seen[maps[source_field_name]] =
								($scope.db_fields_seen[maps[source_field_name]] || 0)
								+ 1;
						}
					}
				}

				// Offset based delimited map
				else {
					const maps = imprt.file_map.maps
					for (let map_offset=0; map_offset<maps.length; map_offset++) {
						if (maps[map_offset]=='') {
							continue;
						}
						// Notate that we've seen this field_name
						$scope.db_fields_seen[maps[map_offset]] =
							($scope.db_fields_seen[maps[map_offset]] || 0)
							+ 1;
					}
				}
			}

			// XML, JSON or NDJSON
			else if (imprt.file_type=='xml' || imprt.file_type=='json' || imprt.file_type=='ndjson') {
				const maps = imprt.file_map.maps

				for (let map_offset=0; map_offset<maps.length; map_offset++) {
					if (maps[map_offset].name=='') {
						continue;
					}
					// Notate that we've seen this field_name
					$scope.db_fields_seen[maps[map_offset].name] =
						($scope.db_fields_seen[maps[map_offset].name] || 0)
						+ 1;
				}
			}

		}
	}

	$scope.file_type_changed = function() {
		$scope.change_import($scope.selected_import, true);

	};

	// Grab the imports list
	const getImportsPromise = $http.get('/api.php/dbs/' + databaseId + '/imports', {'params': {'encode_source_file_keys':1}})
		.then(function(response) {
			const data = response.data;

			// Sort imports by order of execution: product_feeds before joins, by id
			data.sort(function(a, b) {
				// a is a product feed, b is not a product feed
				if (a.join_type=='product_feed' && b.join_type!='product_feed') {
					return -1;
				}
				// a is not a product feed, b is a product feed
				else if (a.join_type!='product_feed' && b.join_type=='product_feed') {
					return 1;
				}
				// a and b are of the same join_type priority

					return a.id-b.id;

			});
			$scope.imports = data.filter(function(value) {
				return value.join_type !== 'creation_date_tracker';
			});
			// Initialize file_map info
			for (let imprt_offset=0; imprt_offset<$scope.imports.length; imprt_offset++) {
				const imprt = $scope.imports[imprt_offset];
				if (imprt.id=='extra') {
					continue;
				}

				// Initialize file_map for imports that don't have one
				if (!imprt.file_map) {
					imprt.file_map = {};
				}


				// Initialize delimited imports
				if (imprt.file_type=='delimited') {
					// initialize the line_terminator field
					if (imprt.line_terminator===null || imprt.line_terminator === '') {
						imprt.line_terminator='\\n';
					}


					// Initialize delimiters_initially_set
					imprt.delimiters_initially_set = imprt.file_map.hasOwnProperty('separator')
						&& imprt.file_map.hasOwnProperty('enclosure')
						&& imprt.file_map.hasOwnProperty('escaper');

					// Initialize separator, enclosure, escaper
					if (!imprt.file_map.hasOwnProperty('separator')) {
						imprt.file_map.separator = '';
					}
					if (!imprt.file_map.hasOwnProperty('enclosure')) {
						imprt.file_map.enclosure = '';
					}
					if (!imprt.file_map.hasOwnProperty('escaper')) {
						imprt.file_map.escaper = '';
					}

					// Initialize delimited file_map.maps
					if ( !imprt.file_map.maps ) {
						if (imprt.name_based_maps!='1') {
							imprt.file_map.maps = [];
							if (imprt.file) {
								imprt.file_map.maps.length = imprt.file[0].length;
								imprt.file_map.maps = Array.prototype.slice.call(imprt.file_map.maps);
							}
						}
						else {
							imprt.file_map.maps = {};
						}
					}

				}

				// Initialize xml imports
				else if (imprt.file_type=='xml') {
					// Initialize xml file_map.maps
					if (!imprt.file_map.hasOwnProperty('maps') || !Array.isArray(imprt.file_map.maps)) {
						imprt.file_map.maps = [];
					}
					imprt.file_map.maps.push({'name':'', 'path':''});
				}

				// Initialize json/ndjson imports
				else if (imprt.file_type === 'json' || imprt.file_type === 'ndjson') {
					// Initialize json file_map.maps
					if (!imprt.file_map.hasOwnProperty('maps') || !Array.isArray(imprt.file_map.maps)) {
						imprt.file_map.maps = [];
					}
					imprt.file_map.maps.push({'name':'', 'path':''});
				}

				// Initialize join fields
				if (!imprt.file_map.hasOwnProperty('ignore_join')) {
					imprt.file_map.ignore_join = [];
				}

				if (angular.isDefined(imprt.file_map.convert_nested)) {
					imprt.convert_nested = imprt.file_map.convert_nested;
				} else {
					imprt.convert_nested = 0;
				}

				if (angular.isDefined(imprt.file_map.preserve_boolean_literals)) {
					imprt.preserve_boolean_literals = imprt.file_map.preserve_boolean_literals;
				} else {
					imprt.preserve_boolean_literals = 0;
				}
			}

			$scope.initialize_seen_db_fields();

			// Add extra fields as an import so you can choose from the dropdown for mapping
			$scope.imports.push({'id':'extra', 'name': 'Extra Fields'});

			return $scope.imports;
		});

	// tag each import with has_ftp_trigger flag
	const getFtpTriggersPromise = FtpTriggersDataService.getTriggers(databaseId);
	$q.all([getFtpTriggersPromise, getImportsPromise]).then(([triggers, imports]) => {
		$scope.imports = imports.map((importItem) => {

            const has_ftp_trigger = ImportFtpTriggerService.hasFtpTriggers(importItem.id, triggers)
                || ImportFtpTriggerService.isFtpImportTrigger(importItem.id, triggers);

			return {
				...importItem,
				has_ftp_trigger
			};
		});

        // if no import_id is present, initialize the selected_import to the first one in the list
        const import_id = $location.search().import_id;
        let imprt = null;
        if ($scope.imports?.length > 0) {
            if (import_id) {
                imprt = $scope.imports.find((e) => e.id === import_id);
            } else {
                imprt = $scope.imports[0];
            }
        }

        if (imprt) {
            $scope.change_import(imprt);
        }
	});

    $scope.importIsActive = function(imprt) {
        return imprt?.cron || imprt?.has_ftp_trigger;
    }

	$scope.convert_map = function(selected_import, old_name_based_maps) {
		// Convert from map to array
		let new_map;

		if (old_name_based_maps=='1') {
			new_map = [];
			const first_row = selected_import.file[0];
			for (let i=0; i<first_row.length; i++) {
				if (first_row[i] in selected_import.file_map.maps) {
					new_map.push(selected_import.file_map.maps[first_row[i]]);
				}
				else {
					new_map.push('');
				}
			}
			selected_import.file_map.maps = new_map;
		}
		// Convert from array to map
		else {
			new_map = {};
			for (let i=0; i<selected_import.file_map.maps.length; i++) {
				if (selected_import.file_map.maps[i]!='') {
					const header_value = selected_import.file[0][i];
					new_map[header_value] = selected_import.file_map.maps[i];
				}
			}
			selected_import.file_map.maps = new_map;
		}

		selected_import.modified = true;
	};

	$scope.unset_name_based_map = function(col_name) {
		delete $scope.selected_import.file_map.maps[col_name];
	}

	// Deal with import view based on select
	$scope.update_selected_import = function(import_id) {
		if (import_id=='extra') {
			$scope.selected_import={extra:true};
		}
		else {
			for (let n=0; n<$scope.imports.length; n++) {
				if ($scope.imports[n].id==import_id) {
					$scope.selected_import=$scope.imports[n];
					break;
				}
			}
		}
	};

	// Auto Detect Delimiters
	$scope.auto_detect = function(imprt) {
		imprt.delimiters_modified = true;
		imprt.auto_detect = '';

		imprt.auto_detect_in_progress = true;
		imprt.auto_detect_finished = false;

		$http.get('/api.php/dbs/' + databaseId + '/imports/' + imprt.id + '/auto_detect')
			.then(function(response) {
				const data = response.data;
				imprt.auto_detect_in_progress = false;
				imprt.auto_detect_finished = true;

				// // timeout auto_detect_finished
				// $timeout(function(){
				// 	imprt.auto_detect_finished = false;
				// }, 1500);

				imprt.auto_detect = data;
				if (data.length>0) {
					imprt.file_map.separator = data[0].field_separator;
					imprt.file_map.enclosure = data[0].enclosure_character;
					imprt.file_map.escaper = data[0].escape_character;
					imprt.line_terminator = data[0].line_terminator;

					imprt.file_map.encoding = data[0].recommended_encoding;
					imprt.file_map.actual_encoding = data[0].actual_encoding;
					imprt.std = data[0].std;
				}
			});
	}

	// Auto Populate Variables
	$scope.confirm_auto_populate = function() {
		if($scope.auto_populate.toggle) {
			for (let i=0; i < $scope.selected_import.file_transpose.length; i++) {
				let header_element = $scope.selected_import.file_transpose[i][0].bin,
					raw_header_element = $scope.selected_import.file_transpose[i][0].hex;

				// Hex decode the feedonomics variable name
				header_element = header_element.replace(/[^\x00-\x7F]/g, "");
				// Remove whitespace, lowercase, and replace bad characters
				header_element =
					header_element.trim()
						.toLowerCase()
						.replace(/ /g, "_")
						.replace(/-/g, "_");

				header_element += $scope.auto_populate.suffix;
				$scope.auto_populate.toggle = false;

				if ($scope.selected_import.name_based_maps!='1') {
					$scope.selected_import.file_map.maps[i] = header_element;
				}
				else if ($scope.selected_import.name_based_maps=='1') {
					$scope.selected_import.file_map.maps[raw_header_element] = header_element;
				}
			}
		}
	};

	// Auto Populate XML Variables
	$scope.xml_auto_populate = function() {
		// Return if there is no context_node //
		if(!$scope.selected_import.file_map.context_node){
			$scope.selected_import.error_xml_auto_populate = 'Missing Context Node!';
			return;
		}

			$scope.selected_import.error_xml_auto_populate = null;
			const context_node = $scope.selected_import.file_map.context_node;


		$http({
			method: 'GET',
			url: `/api.php/dbs/${databaseId}/imports/${$scope.selected_import.id}/xml/${context_node}`,
		}).then((res) => {
			const rawXml = res.data;

			if(rawXml.length === 0){
				$scope.selected_import.error_xml_auto_populate = 'Context node not found';
				return;
			}

			// file_map object for auto-populate //
			$scope.selected_import.xml_auto_populate_file_map = {};

			// Parse XML //
			let all_context_nodes = [];
			try{
				// If XML is valid and complete
				const xmlDoc = $.parseXML(rawXml);
				$(xmlDoc).find(context_node).each(function () {
					all_context_nodes.push(this);
				});
			} catch(err){
				// If XML is incomplete (We limit the bytes fetched)
				const parser = new DOMParser();
				const xmlDom = parser.parseFromString(rawXml, "application/xml");
				all_context_nodes = xmlDom.getElementsByTagName(context_node);
			}

			// Loop through each context node //
			if(all_context_nodes && typeof all_context_nodes === 'object'){
				Object.keys(all_context_nodes).forEach(function (key) {
					$(all_context_nodes[key]).children().each(function () {
						$scope.auto_populate_xml_node(this);
					});
				});
			}

			// Display XML auto populate on the UI //
			$scope.display_xml_auto_populate();
		}).catch((err) => {
			$scope.selected_import.error_xml_auto_populate = err.message;
		});
	};

	// Show controls to add/remove prefix/suffix
	$scope.xml_add_remove_prefix_suffix_modal = function () {
		ModalService.open(
			{
				component: addRemovePrefixSuffixModalComponentName,
				windowClass: 'fdx-modal modal-dialog-centered',
				backdropClass: 'fdx-modal'
			}
		).then(
			(resolve) => {
				if (resolve.prefixAction && resolve.prefix) {
					$scope.xml_apply_prefix(resolve.prefixAction, resolve.prefix);
				}

				if (resolve.suffixAction && resolve.suffix) {
					$scope.xml_apply_suffix(resolve.suffixAction, resolve.suffix);
				}
			},
			angular.noop
		);
	};

	// apply the action selected to the prefix or suffix
	$scope.xml_apply_prefix = function(action, value) {
		$scope.selected_import.file_map.maps.forEach(function (map) {
			if (action === 'Add' && map.name.length > 0) {
				map.name = value + map.name;
				$scope.selected_import.modified = true;
			}
			if (action === 'Remove') {
				if (map.name.startsWith(value)) {
					map.name = map.name.slice(value.length);
					$scope.selected_import.modified = true;
				}
			}
		});
	}
	$scope.xml_apply_suffix = function(action, value) {
		$scope.selected_import.file_map.maps.forEach(function (map) {
			if (action === 'Add' && map.name.length > 0) {
				map.name = map.name + value;
				$scope.selected_import.modified = true;
			}
			if (action === 'Remove') {
				if (map.name.endsWith(value)) {
					map.name = map.name.substring(0, map.name.length - value.length);
					$scope.selected_import.modified = true;
				}
			}
		});
	}

	// Adds XML node and its attributes to XML file_map //
	$scope.auto_populate_xml_node = function(node, path = '') {
		const nodeChildren = $(node).children();
		const nodeTagName = $(node).prop('tagName');
		const nodeTagPath = path + nodeTagName + "/";

		// Ignore parsererror tag - It can be added to top-level node if XML is invalid by dom parser //
		if(nodeTagName == 'parsererror'){
			return;
		}

		// Get all attributes of this node //
		const node_attrs = $scope.get_xml_node_attrs(node);

		// If this node has nested nodes //
		if(nodeChildren.length > 0){

			// Since it doesn't have a text node, attributes can be added to file_map as it can potentially be a field //
			for(let i = 0; i < node_attrs.length; i++){
				$scope.add_attr_to_file_map(node_attrs[i], nodeTagPath);
			}

			// Call nested nodes recursively if enabeld //
			if($scope.selected_import.xml_parse_recursively){
				nodeChildren.each(function() {
					$scope.auto_populate_xml_node(this, nodeTagPath);
				});
			}
		} else {
			// This is possibly a text node //
			const nodeText = $(node).text();
			let nodePath = path + nodeTagName;

			if(!nodeText){
				// Since it doesn't have text node, attributes can be added to file_map //
				for(let i = 0; i < node_attrs.length; i++){
					$scope.add_attr_to_file_map(node_attrs[i], nodeTagPath);
				}
			} else {
				// If the text node has attributes, they can be possibly used to identify path - Add first attribute to the path //
				if(node_attrs.length > 0){
					const nodePathFromAttr = $scope.create_file_map_from_attr(node_attrs[0], path, nodeTagName);
					if(nodePathFromAttr){
						nodePath = nodePathFromAttr;
					}
				}
			}

			// Add path to file_map //
			$scope.add_path_to_xml_file_map(nodePath);
		}
	};

	// Return all attributes of a node //
	$scope.get_xml_node_attrs = function(node) {
		const attrs = [];
		if($scope.selected_import.xml_include_attrs){
			$.each(node.attributes, function(i, attr){
				attrs.push({'name': attr.name, 'value': attr.value});
			});
		}
		return attrs;
	};

	// Add attribute to file_map //
	$scope.add_attr_to_file_map = function(attr_obj, nodeTagPath) {
		if(attr_obj.name){
			const attrPath = nodeTagPath + "@" + attr_obj.name;
			$scope.add_path_to_xml_file_map(attrPath);
		}
	};

	// Add attribute to file_map path and return updated path //
	$scope.create_file_map_from_attr = function(attr_obj, path, nodeTagName) {
		if(attr_obj.name && attr_obj.value){
			return path + nodeTagName + "[@" + attr_obj.name + "='" + attr_obj.value + "']";
		}
		return false;
	};

	// Add provided path to xml file_map object //
	$scope.add_path_to_xml_file_map = function(path) {
		$scope.selected_import.xml_auto_populate_file_map[path] = 1;
	};

	// Auto-populate XML using xml auto-populate file_map object //
	$scope.display_xml_auto_populate = function() {
		// Clean up existing map //
		$scope.selected_import.file_map.maps = [];

		// If there is mapping in xml_auto_populate_file_map obj //
		if(!$.isEmptyObject($scope.selected_import.xml_auto_populate_file_map)){
			// Object storing already added variable names so we don't repeat names //
			$scope.selected_import.suggested_var_names = {};

			$.each($scope.selected_import.xml_auto_populate_file_map, function(path, val) {
				// Get suggested variable name for this map path //
				let var_name = $scope.suggest_var_name_from_path(path);

				// Change camelCase to underscore and lowercase
				var_name = var_name.replace(/([A-Z]+)/g,
					function (x,y) {
						return "_" + y.toLowerCase()
					})
					.replace(/^_/, "")
					.replace(/_+/, "_");

				$scope.selected_import.file_map.maps.push({'name':var_name, 'path':path});
			});
		}

		// Always add empty file map entry for new mapping
		$scope.selected_import.file_map.maps.push({'name':'', 'path':''});
	};

	// Returns suggested variable name from path //
	$scope.suggest_var_name_from_path = function(path) {
		// Start with var_name as path //
		let var_name = $scope.clean_var_name(path);

		// Split path by "/"
		let path_vars = [];
		if(path.indexOf("/") !== -1){
			path_vars = path.split("/");
		} else {
			path_vars.push(path);
		}

		const path_vars_length = path_vars.length

		if(path_vars_length > 0){
			let path_vars_minus_offset = 1;
			// Set var_name as last element of path_vars //
			var_name = $scope.clean_var_name(path_vars[path_vars_length - path_vars_minus_offset]);

			// Keep reconstructing var_name if it's already taken AND there are more elements to cover in path_vars //
			while(typeof $scope.selected_import.suggested_var_names[var_name] !== 'undefined'
				&& path_vars_minus_offset < path_vars_length
			) {
				path_vars_minus_offset++;
				// Set var_name by joining path_vars elements with "_" //
				var_name = $scope.clean_var_name(path_vars.slice(path_vars_length - path_vars_minus_offset + 1).join("_"));
			}
		}

		// If final var_name is also taken, append the sequential number to it to create a unique one //
		if(typeof $scope.selected_import.suggested_var_names[var_name] !== 'undefined') {
			$scope.selected_import.suggested_var_names[var_name]++;
			var_name += "_" + ($scope.selected_import.suggested_var_names[var_name]);
		}

		// Mark var_name as taken //
		$scope.selected_import.suggested_var_names[var_name] = 1;

		return var_name;
	};

	// Returns string after replacing all non \w characters with underscore //
	$scope.clean_var_name = function(var_name) {
		return var_name.replace(/[^\w]+/g, '_')
			.replace(/^\_|\_$/g, "")
			.replace(/_+/, "_");
	};

	$scope.toggle_auto_populate = function (value) {
		$scope.auto_populate.toggle = value;
	};

	// Delimited Stuff
	$scope.submit_delimiters = function(imprt) {
		imprt.delimiters_modified=true;
		const params = {
			'separator': imprt.file_map.separator,
			'enclosure': imprt.file_map.enclosure,
			'escaper': imprt.file_map.escaper,
			'encoding': imprt.file_map.encoding,
			'line_terminator': imprt.line_terminator
		};
		$http.put('/api.php/dbs/' + databaseId + '/imports/' + imprt.id + '/delimiters', params)
			.then(function(response) {
				const data = response.data;
				imprt.delimiters_modified=false;
				imprt.delimiters_initially_set = true;
				// Grab the parsed file
				const params = {
					'format':'parsed',
					'limit':4
				}
				$http.get('/api.php/dbs/' + databaseId + '/imports/' + imprt.id + '/file', {'params': params})
					.then(function(response) {
						const data = response.data;
						imprt.file = data;
						if ( !imprt.file_map.maps ) {
							imprt.file_map.maps = [];
						}

						//if import is name based then Array.slice doesnt work on it
						if(imprt.name_based_maps == '1') {
							const keys = Object.keys(imprt.file_map.maps),
								sliced = keys.slice(0 , imprt.file[0].length),
								old_maps = imprt.file_map.maps;

							imprt.file_map.maps = {};
							sliced.forEach(key => {
								imprt.file_map.maps[key] = old_maps[key];
							})

						}else{
							imprt.file_map.maps.length = imprt.file[0].length;
							imprt.file_map.maps = Array.prototype.slice.call(imprt.file_map.maps);
						}

						// Get Transpose Table, this should really be a directive
						imprt.file_transpose = imprt.file[0].map(function(col, i) {
							return imprt.file.map(function(row) {
								return {
									hex : row[i] ? row[i] : '',
									bin : row[i] ? $scope.hex2bin(row[i]) : ''
								};
							})});
					});
			});
	}

	/*
	$scope.prefill_map = function(imprt) {
		for (var n=0; n<imprt.file[0].length; n++) {
			var column_name = imprt.file[0][n];
			imprt.file_map.maps[column_name] = column_name;
		}
	}
	*/


	// XML Stuff
	$scope.add_xml_row = function() {
		const last_index = $scope.selected_import.file_map.maps.length - 1;
		if ($scope.selected_import.file_map.maps[last_index].name != ''
				|| $scope.selected_import.file_map.maps[last_index].path != '') {
			$scope.selected_import.file_map.maps.push({'name':'', 'path':''});
		}
	}

	// JSON Stuff
	$scope.add_json_row = function() {
		const last_index = $scope.selected_import.file_map.maps.length - 1;
		if ($scope.selected_import.file_map.maps[last_index].name != ''
				|| $scope.selected_import.file_map.maps[last_index].path != '') {
			$scope.selected_import.file_map.maps.push({'name':'', 'path':''});
		}
	}

	// get all db_fields
	$scope.db_fields = []; // array of strings containing the display names
	$scope.update_db_fields = function() {
		$http.get('/api.php/dbs/' + databaseId + '/db_fields')
			.then((response) => {
				const data = response.data;
				const updatedDbFields = [];
				for (let i = 0; i < data.length; i++) {
					updatedDbFields.push(data[i].field_name);
				}

                $scope.db_fields = updatedDbFields;
			});
	};
	$scope.update_db_fields();

	$scope.update_force_parse = function(imprt) {
		imprt.saving_xml_force_parse = true;
		const xml_force_parse_param = {'xml_force_parse':imprt.xml_force_parse};
		$http.put('/api.php/dbs/'+databaseId+'/imports/'+imprt.id+'/xml_force_parse',xml_force_parse_param)
			.then(function(response) {
				const data = response.data;
				imprt.xml_force_parse = data.xml_force_parse;
				imprt.saving_message_xml_force_parse = false;
				imprt.saved_message_xml_force_parse = true;

				// timeout added message
				$timeout(function(){
					imprt.saved_message_xml_force_parse = false;
				}, 1500);
			}, function(response) {
				imprt.saving_message_xml_force_parse = false;
				if (imprt.xml_force_parse=='1') {
					imprt.xml_force_parse = '0';
				}
				else {
					imprt.xml_force_parse = '1';
				}
			})
	};

	$scope.count_mapped_fields_delimited = function(selectedImport) {
        const maps = selectedImport.file_map.maps;
		const mappedValues = Object.values(maps).filter((value) => value !== '');
		return {
			'mapped_count': mappedValues.length,
			'total_fields': selectedImport.file_transpose.length    // get the total number of rows in the table - there was a bug where the API was not returning all import.file_map.maps values in old file maps
		};
	};

	$scope.count_mapped_fields_xml_json = function(maps) {
		let mapped_count = 0;
		let total_fields = 0; // always has empty row at end
		if (maps.length > 0) {
			for(const map of maps) {
				if (map['name'] !== '') {
					if (map['path'] !== '') {
						mapped_count++;
					}
					total_fields++;
				}
			}
		}
		return {
			'mapped_count': mapped_count,
			'total_fields': total_fields,
		};
	};


	// Submit File Map for Delimited, XML and JSON
	$scope.submit_file_map = function(imprt , do_notify) {
        imprt.run_in_progress = true;
		imprt.modified = true;
		$scope.import_running = true;
		imprt.error = "";
		window.scrollTo(0, 0);

		if(typeof do_notify === "undefined") {
			do_notify = false;
		}
		if(do_notify && $scope.notification.address) {
			imprt.file_map.do_notify = do_notify;
			imprt.file_map.notification_email = $scope.notification.address + '@feedonomics.com';
		}


		// Default to encode name based map keys;
		// API.php ignores unless name_based_maps = 1
		imprt.file_map.name_based_maps = imprt.name_based_maps;
		if (imprt.file_map.name_based_maps) {
			imprt.file_map.encode_source_file_keys = 1;
		}

		// validation
		if (imprt.file_type === 'delimited') {
			// duplicate validation does not work on structured maps like xml, json, ndjson
			const findDuplicates = (arr) => arr.filter((item, index) => arr.indexOf(item) !== index && !isEmpty(item))
			const duplicates = [...new Set(findDuplicates(Object.values(imprt.file_map.maps)))];
			if (duplicates.length > 0) {
				imprt.error = "You must use unique field names.  Please specify a new name for the following fields: " + duplicates.join(', ');
				imprt.run_in_progress = false;
				$scope.import_running = false;
			}
		}

		if (imprt.file_type === 'xml') {
			const failedPaths = [];
			imprt.file_map.maps.forEach((element, index) => {
				const testPath = element.path;
				try {
					$window.document.evaluate(testPath, $window.document, null, XPathResult.ANY_TYPE, null);
				} catch (e) {
					if (testPath.trim() !== '' && e.message.includes('is not a valid XPath expression')) {
						failedPaths.push(testPath);
					}
				}
			});
			if (failedPaths.length > 0) {
				imprt.error = "Invalid XML Path submitted. Please correct the following: " + failedPaths.join(', ');
				imprt.run_in_progress = false;
				$scope.import_running = false;
			}
		}

		if (imprt.error === '') {
			imprt.file_map.clean_file_headers = imprt.clean_file_headers;

			imprt.file_map.file_type = imprt.file_type;
			if (imprt.file_type == 'json' || imprt.file_type == 'ndjson') {
				imprt.file_map.convert_nested = imprt.convert_nested;
				imprt.file_map.preserve_boolean_literals = imprt.preserve_boolean_literals;
			}

 			$http.put('/api.php/dbs/' + databaseId + '/imports/' + imprt.id + '/file_map', imprt.file_map)
				.then(() => {
					imprt.modified = false;
					imprt.run_in_progress = false;
					$scope.import_running = false;

					if (!$scope.accountHasFeature('import_static_threshold', 'enabled') && $scope.db_fields.length === 0) {
                        fdxUI.showToastSuccess(
                            `File map submitted. Please review <a href="/app/#/${databaseId}/imports">thresholds</a>`
                        );
                    }
					$scope.initialize_seen_db_fields();
					$scope.update_db_fields();
				}, (response) => {
                    // Do not show this specific error message for EDRTS dbs https://feedonomics.atlassian.net/browse/FP-9266
                    if ($scope.isEDRTSDatabase() && response.data === 'Please contact support@feedonomics.com') {
                        imprt.error = null;
                        $scope.status = 'EDRTS_SUCCESS';
                    } else {
                        imprt.error = response.data;
                    }

					imprt.run_in_progress = false;
					$scope.import_running = false;
				});
		}
	}

	// Apply RAD Template
	$scope.apply_rad_template = function() {
		const bodyTemplate = 'Proceeding with the RAD Template will reinstate the database\'s default configurations. Any existing customizations will be lost:'
		+ '<ul><li>Extra Variables</li><li>Transformers in all fields</li><li>Exports</li></ul>';

		ModalService.showConfirmationModal(
			'RAD Template confirmation',
			bodyTemplate,
			'Continue',
			'Cancel',
			true
		).then(
			() => {
				$scope.import_running = true;

				$http.put('/api.php/dbs/' + databaseId + '/db_template', [])
				.then(() => {
					$scope.import_running = false;
					fdxUI.showToastSuccess('RAD Template has been successfully applied');
				}, (response) => {
					$scope.import_running = false;
					fdxUI.showToastError(response.data);

				});
			}
		).catch(() => {
			$scope.import_running = false;
		});
	}


	$scope.show_help = function() {
		ModalService.open(
			{
				component: faqModalComponentName,
				windowClass: 'fdx-modal modal-dialog-centered',
				backdropClass: 'fdx-modal',
				size: 'lg',
			}
		).then(angular.noop, angular.noop);
	};


	$scope.sort_imports_by_id = function(imprt) {
		if (imprt.id=='extra') {
			return 99999999;
		}

			return parseInt(imprt.id);

	};

	$scope.get_header_source = function(imprt) {
		let header_template = '';

		if(imprt.file_location === 'aggregate') {
			return header_template;
		}

		switch(imprt.file_type) {
			case 'delimited' : {
				header_template =  '/app/file_maps/delimited_map_header.html';
				break;
			}
		}

		return header_template;
	};

	$scope.get_card_source = function(imprt) {
		let card_template = '';

		switch(imprt.join_type) {
			case 'aggregate' : {
				card_template = '/app/file_maps/aggregate_card.html';
				break;
			}
			default : {
				switch(imprt.file_type) {
					case 'delimited' : {
						card_template =  '/app/file_maps/delimited_map_card.html';
						break;
					}
					case 'xml' : {
						card_template = '/app/file_maps/xml_map_card.html';
						break;
					}
					case 'json' :{
						card_template = '/app/file_maps/json_map_card.html';
						break;
					}
					case 'ndjson': {
						card_template = '/app/file_maps/ndjson_map_card.html';
						break;
					}
				}
			}
		}

		return card_template;
	};

	$scope.confirm_notification_modal = function () {
		ModalService.open(
			{
				component: confirmNotificationEmailModalComponentName,
				windowClass: 'fdx-modal modal-dialog-centered',
				backdropClass: 'fdx-modal'
			}
		).then(
			(notificationAddress) => {
				$scope.notification.address = notificationAddress;

				$scope.submit_file_map($scope.selected_import, true);
			},
			angular.noop
		);
	};

    $scope.isEDRTSDatabase = () => {
        return  $scope.current_db.event_sync === '1';
    }

    $scope.showVersionSwitchBanner = () => {
        return AppStateService.getUser().hasFeature('new_file_maps_ui','enabled');
    }

    $scope.goToNewFileMapsPage =  () => {
        $state.go('app.file-maps', { id: databaseId, import_id: $scope.selected_import.id });
    }
}]);
