import { AfterViewInit, Component, ElementRef, Input, OnInit } from '@angular/core';
import { LogService } from '@app/core/services/log.service';
import { BaseInputComponent } from '@app/modules/inputs/components/base-input/base-input.component';
import { InputsUtilitiesService } from '@app/modules/inputs/services/inputs-utilities.service';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'fdx-input-radio',
    providers: [{
        provide: BaseInputComponent,
        useExisting: InputRadioComponent
    }],
    templateUrl: './input-radio.component.html',
    styleUrls: ['./input-radio.component.scss']
})
export class InputRadioComponent extends BaseInputComponent implements OnInit, AfterViewInit {

    @Input() controlId: string;            // Required for component
    @Input() controlValue: string;         // Required for component

    constructor(
        protected readonly element: ElementRef<HTMLElement>,
        protected readonly inputsUtilitiesService: InputsUtilitiesService,
        protected readonly logService: LogService,
        protected readonly ngbDateParserFormatter: NgbDateParserFormatter
    ) {
        super(element, inputsUtilitiesService, logService, ngbDateParserFormatter);
        this.inputType = 'radio';
    }

    ngOnInit(): void {
        if (this.classes['btn-check']) {
            this.initClasses['form-check-input'] = false;
        } else if (this.classes['form-check-input'] === undefined) {
            this.initClasses['form-check-input'] = true;
        }
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this.validateConfiguration();
    }

    get id(): string {
        return this.controlId ? this.controlId : this.controlName;
    }

    setValue(value: string | boolean | null): void {
        const checking = value === true || value === this.controlValue;
        this.updateHasValueClass(this.controlValue);
        this.value = checking ? this.controlValue : null;
    }

    /**
     * Only called when selecting this radio button. Not called when another radio button is selected in the
     * same group, so we can safely set the value to this controlValue and change the formField value.
     * @param _$event
     */
    onChange(_$event: string): void {
        this.updateHasValueClass(this.controlValue);
        this.value = this.controlValue;
        this.formField.handleChange(this.value);
        this.onTouched();   // Automatically trigger touch when changed
    }

    validateConfiguration(): string[] {
        const errors: string[] = [];

        if (this.controlId === undefined) {
            errors.push('binding a controlId of type string is required for InputRadioComponent');
        }
        if (this.controlValue === undefined) {
            errors.push('binding a controlValue of type string is required for InputRadioComponent');
        }

        errors.forEach((error) => {
            this.logService.error(error);
        });

        return errors;
    }
}
