import { Component, Input } from '@angular/core';

/**
 * @deprecated remove instances of the well component and just use <div class="card">
 * https://getbootstrap.com/docs/5.2/components/card/
 * https://feedonomics.atlassian.net/browse/FP-9544
 */
@Component({
    selector: 'fdx-well',
    templateUrl: './well.component.html',
    styleUrls: ['./well.component.scss']
})
export class WellComponent {

    @Input() cardClasses: Record<string, boolean> = null;
    @Input() cardStyles: Record<string, any> = null;

    @Input() cardBodyClasses: Record<string, boolean> = null;
    @Input() cardBodyStyles: Record<string, any> = null;

}
