import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { TransformerModel } from '@app/transformers/models/transformer.model';

export type TransformerCellRendererParams<T = unknown, V = string> = ICellRendererParams<T, V> & ITransformerCellRendererParams;

export interface ITransformerCellRendererParams {
    transformer: TransformerModel
}

@Component({
    standalone: true,
    selector: 'fdx-transformer-cell-renderer',
    templateUrl: './transformer-cell-renderer.component.html',
    styleUrls: ['./transformer-cell-renderer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NgIf,
        NgClass
    ]
})
export class TransformerCellRendererComponent implements ICellRendererAngularComp {

    private params!: TransformerCellRendererParams;

    selector: string = null;
    transformer: string = null;
    sortOrder: number = null;
    enabled: '0' | '1' = null;

    constructor(private readonly changeDetectorRef: ChangeDetectorRef) { }

    public agInit(params: TransformerCellRendererParams): void {
        this.bindParams(params);
        this.changeDetectorRef.markForCheck();
    }

    public refresh(params: TransformerCellRendererParams): boolean {
        this.bindParams(params);
        this.changeDetectorRef.markForCheck();
        return true;
    }

    private bindParams(params: TransformerCellRendererParams): void {
        this.params = params;

        if (params.transformer) {
            if ('selector' in params.transformer) {
                this.selector = params.transformer.selector;
            }

            if ('transformer' in params.transformer) {
                this.transformer = params.transformer.transformer;
            }

            if ('sort_order' in params.transformer) {
                this.sortOrder = params.transformer.sort_order;
            }

            if ('enabled' in params.transformer) {
                this.enabled = params.transformer.enabled;
            }
        }
    }
}
