import { Injectable } from '@angular/core';
import { ContainerService } from '@app/core/services/container.service';
import { AdditionalOffcanvasDismissReasons } from '@app/modules/offcanvas/enums/additional-offcanvas-dismiss-reasons';
import { OffcanvasComponent } from '@app/modules/offcanvas/interfaces/offcanvas-component.interface';
import { NgbOffcanvas, NgbOffcanvasRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, take } from 'rxjs';
import { OffcanvasOptions } from '../interfaces/offcanvas-options.interface';
import { Offcanvas } from '../interfaces/offcanvas.interface';

@Injectable({
    providedIn: 'root'
})
export class OffcanvasService {
    constructor(
        private readonly containerService: ContainerService,
        private readonly ngbOffcanvas: NgbOffcanvas
    ) { }

    /**
     * Opens a new offcanvas window using ng-bootstrap with the specified component and options.
     *
     * The specified component must implement the `Offcanvas<T>` interface.
     * Where `T` is your resolver interface for data which gets passed into your offcanvas.
     *
     * To pass data into your offcanvas specify it under `options.resolve`.
     *
     * If your offcanvas does not need to pass in data then just implement `Offcanvas` without the generic `T`.
     *
     * Other ng-bootstrap offcanvas options can be passed in under `options` as well.
     */
    public open(
        component: OffcanvasComponent,
        options: OffcanvasOptions = {}
    ): NgbOffcanvasRef {
        let instance: Offcanvas = null;

        /**
         * put offcanvas in #bs5-offcanvas container
         * this div has bootstrap 5 styles applied
         * to it and body currently does not
         *
         * specific instances cannot be done under shadow root
         * lightDom allows them to use the bs3 container instead
         *
         * TO DO: remove after fully on bs5
         */
        const container: HTMLElement = this.containerService.getContainer();

        const offcanvas: NgbOffcanvasRef = this.ngbOffcanvas.open(
            component,
            {
                ...options,
                beforeDismiss: function () {
                    if (typeof (instance?.canDismiss) === 'function') {
                        return instance.canDismiss();
                    }

                    return true;
                },
                container
            }
        );

        instance = offcanvas.componentInstance as Offcanvas;

        const data: unknown = options.resolve;

        if (instance) {
            instance.resolve = data;
        }

        if (typeof (instance?.onOffcanvasInit) === 'function') {
            instance.onOffcanvasInit(data);
        }

        const subscriptions: Subscription[] = [];

        if (typeof (instance?.onOffcanvasDismissed) === 'function') {
            subscriptions.push(
                offcanvas.dismissed
                    .pipe(
                        take(1)
                    )
                    .subscribe(
                        {
                            next: (reason: unknown) => {
                                // Don't call onOffcanvasDismissed when clicked from dismiss X (it should already get called from component output)
                                if (reason !== AdditionalOffcanvasDismissReasons.X_CLICK) {
                                    instance.onOffcanvasDismissed(reason);
                                }
                            },
                            complete: () => subscriptions.forEach((s) => s.unsubscribe())
                        }
                    )
            );
        }

        if (typeof (instance?.onOffcanvasClosed) === 'function') {
            subscriptions.push(
                offcanvas.closed
                    .pipe(
                        take(1)
                    )
                    .subscribe(
                        {
                            next: (value) => instance.onOffcanvasClosed(value),
                            complete: () => subscriptions.forEach((s) => s.unsubscribe())
                        }
                    )
            );
        }

        return offcanvas;
    }
}
