import { Component } from '@angular/core';
import { Offcanvas } from '@app/modules/offcanvas/offcanvas.module';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'fdx-cheat-sheet-offcanvas',
    templateUrl: './cheat-sheet-offcanvas.component.html',
    styleUrls: ['./cheat-sheet-offcanvas.component.scss']
})
export class CheatSheetOffcanvasComponent implements Offcanvas {

    public title: string = 'FQL cheat sheet';

    constructor(
        private offcanvas: NgbActiveOffcanvas
    ) { }

    public onOffcanvasInit(): void {
        // do nothing
    }

    public onOffcanvasDismissed(reason: any): void {
        this.offcanvas.dismiss(reason);
    }
}
