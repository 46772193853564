import { Component } from '@angular/core';
import { BootstrapThemeProp } from '@app/core/services/bootstrap-theme.service';
import { DateTimeService } from '@app/core/services/date-time.service';
import { ExistingExport } from '@app/exports/models/interfaces/existing-export.interface';
import { CronService } from '@app/modules/cron/services/cron.service';
import { Modal } from '@app/modules/modals/modals.module';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface PushExportModalResolverInterface {
    export: ExistingExport,
    derivedChannelDestination: string;
}

@Component({
  selector: 'fdx-push-export-dialog',
  templateUrl: './push-export-dialog.component.html',
  styleUrls: ['./push-export-dialog.component.scss']
})
export class PushExportDialogComponent implements Modal {
    public modalTheme: BootstrapThemeProp = 'primary';
    public resolve: PushExportModalResolverInterface = {
        export: null,
        derivedChannelDestination: null
    };
    public export: ExistingExport;
    public derivedChannelDestination: string;

    constructor(
        private readonly cronService: CronService,
        private readonly dateTimeService: DateTimeService,
        private readonly modal: NgbActiveModal
    ) { }

    onModalInit(data: PushExportModalResolverInterface): void {
        this.export = data.export;
        this.derivedChannelDestination = data.derivedChannelDestination;
    }

    public onModalSubmit(_event: MouseEvent): void {
        this.modal.close();
    }

    public onModalCancel(_event: MouseEvent): void {
        this.modal.dismiss();
    }

    get destinationUrl(): string {
        return this.derivedChannelDestination;
    }

    get nextScheduledExport(): string {
        if (!this.export?.cron) {
            return '';
        }
        return this.cronService.getNextTimeFromCron(this.export.cron, this.export.cron_timezone);
    }

    get timeToNextExport(): string {
        if (!this.export?.cron) {
            return '';
        }
        return this.dateTimeService.getFormattedTimeDifference(this.nextScheduledExport);
    }
}
