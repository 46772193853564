import { Component, Input } from '@angular/core';

/**
 * @deprecated remove instances of the well header component and just use <div class="card-header">
 * https://getbootstrap.com/docs/5.2/components/card/
 */
@Component({
    selector: 'fdx-well-header',
    templateUrl: './well-header.component.html',
    styleUrls: ['./well-header.component.scss']
})
export class WellHeaderComponent {

    @Input() classes: Record<string, boolean>;
    @Input() styles: Record<string, any>;

}
