import { Injectable } from '@angular/core';
import { deepCopy } from '@app/core/functions';
import { OrderByPipe } from '@app/modules/pipes/pipes/order-by/order-by.pipe';
import { TimeZoneDropdownOptionInterface } from '@app/modules/timezone/models/interfaces/time-zone-dropdown-option.interface';
import { TimeZoneInterface } from '@app/modules/timezone/models/interfaces/time-zone.interface';
import { getTimeZones, TimeZone } from '@vvo/tzdb';
import { Dayjs } from 'dayjs';

@Injectable({
    providedIn: 'root'
})
export class TimeZoneService {

    private timeZones: TimeZoneInterface[];
    /**
     * Map of timezone name to abbreviation.
     */
    private timeZonesAbbreviations: Map<string, string> = new Map<string, string>();
    private timeZonesList: TimeZoneDropdownOptionInterface[] = [];

    constructor(
        private readonly orderByPipe: OrderByPipe
    ) {

        this.initTimeZones();
        this.initTimeZonesDropdownOptions();
        this.initTimeZonesAbbreviations();

    }

    initTimeZones(): void {

        const timeZones = getTimeZones();

        this.timeZones = timeZones.map((timeZone) => {
            return {mainCity: timeZone.mainCities[0], ...timeZone};
        })


    }

    initTimeZonesDropdownOptions(): void {

        // Group timezone by continent
        const groups: Record<string, TimeZoneInterface[]> = {};

        for (const timeZone of this.timeZones) {

            if (timeZone.continentName === 'Oceania') {
                timeZone.continentName = 'Australia / Oceania'
            }

            if (typeof groups[timeZone.continentName] === 'undefined') {
                groups[timeZone.continentName] = [];
            }

            groups[timeZone.continentName].push(deepCopy(timeZone) as TimeZoneInterface); // break reference to original object

        }

        // Sort cities withing each group (continent)
        for (const group in groups) {
            this.orderByPipe.transform(groups[group], ['mainCity']);
        }

        // Sort groups (continents)
        const groupNames = Object.keys(groups);
        this.orderByPipe.transform(groupNames);

        // set the default list options
        this.addTimeZone(this.timeZones.find((timeZone) => timeZone.name === 'America/New_York'), 'FREQUENTLY CHOSEN');
        this.addTimeZone(this.timeZones.find((timeZone) => timeZone.name === 'Europe/London'), 'FREQUENTLY CHOSEN');
        this.addTimeZone(this.timeZones.find((timeZone) => timeZone.name === 'Africa/Johannesburg'), 'FREQUENTLY CHOSEN');

        // Create final list
        for (const group of groupNames) {
            for (const timeZone of groups[group]) {
                this.addTimeZone(timeZone, timeZone.continentName);
            }
        }

    }

    initTimeZonesAbbreviations(): void {

        const timeZones: TimeZone[] = getTimeZones();
        
        this.timeZonesAbbreviations = new Map(timeZones.map((tz) => [tz.name, tz. abbreviation]));

    }

    getTimeZones(): TimeZoneInterface[] {
        return this.timeZones;
    }

    getTimezonesDropdownValues(): TimeZoneDropdownOptionInterface[] {
        return this.timeZonesList;
    }

    getAbbreviatedTimeZoneName(time: Dayjs): string {

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const abbreviation: string | undefined = this.timeZonesAbbreviations.get(time['$x']['$timezone']);

        return abbreviation ? abbreviation : time.offsetName();

    }

    private addTimeZone(timeZone: TimeZoneInterface, group: string): void {

        const timeZoneOffsetString = timeZone.rawFormat.substring(0, 6);

        this.timeZonesList.push({
            city: timeZone.mainCity,
            country: timeZone.countryName,
            group: group,
            abbreviation: timeZone.abbreviation,
            utcOffset: timeZoneOffsetString,
            value: timeZone.name,
            label: `${timeZone.name} ${timeZone.countryName} ${timeZone.continentName} ${timeZone.abbreviation} ${timeZoneOffsetString}`, // value used to search / filter
            timezone: timeZone
        });

    }

}
