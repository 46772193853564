import { AfterViewInit, Component, HostBinding, HostListener } from '@angular/core';
import { ContainerService } from '@app/core/services/container.service';

@Component({
    standalone: true,
    selector: 'fdx-sticky-header',
    templateUrl: './sticky-header.component.html',
    styleUrls: ['./sticky-header.component.scss']
})
export class StickyHeaderComponent implements AfterViewInit {
    @HostBinding('style.top.px')
    top: number = 41;

    @HostListener('window:resize', ['$event'])
    onResize(_e: Event): void {
        this.checkTopOffset();
    }

    constructor(private readonly containerService: ContainerService) { }

    ngAfterViewInit(): void {
        this.checkTopOffset();
    }

    private checkTopOffset(): void {
        const menu = this.containerService.getAppMenuComponent();

        if (menu) {
            this.top = menu.offsetHeight;
        }
    }
}
