import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Return a validator for if the required minimum/maximum number of required checkbox or radio buttons have been met
 * @param minRequired the minimum number of items that has to be selected, default 1
 * @param maxRequired the maximum number of items that has to be selected, default null
 * @param groupOfGroups If the group this validator is a part of contains another layer of groups of controls, set this to true. Only supports one
 * extra level of nesting.
 * @returns a validator function with an error message if there's an error, or null
 */
export function requiredCheckboxOrRadioValidator(minRequired: number = 1, maxRequired: number = null, groupOfGroups: boolean = false): ValidatorFn | null {
    return function validate(formGroup: AbstractControl): ValidationErrors | null {
        if (!(formGroup instanceof FormGroup)) {
            return null;
        }

        let controls: AbstractControl[] = Object.values(formGroup.controls);
        if (groupOfGroups) {
            const flattenedControls: AbstractControl[] = [];

            controls.forEach((group: FormGroup) => {
                Object.values(group.controls).forEach((control: AbstractControl) => {
                    flattenedControls.push(control);
                });
            });

            controls = flattenedControls;
        }

        let checked: number = 0;

        controls.forEach((control: AbstractControl) => {
            if (control.value === true) {
                checked++;
            }
        });

        const minNotMet: boolean = checked < minRequired;
        const maxExceeded: boolean = (maxRequired !== null && checked > maxRequired)

        if (!minNotMet && !maxExceeded) {
            return null;
        }

        let message: string;
        if (minNotMet && controls.length === minRequired && minRequired === 1) {
            message = 'Selection is required';
        } else if (minNotMet && controls.length === minRequired && minRequired > 1) {
            message = 'Selecting all is required';
        } else if ((minNotMet || maxExceeded) && minRequired === maxRequired) {
            message = `Selecting ${minRequired} option${minRequired === 1 ? '' : 's'} is required`;
        } else if (minNotMet && maxRequired === null) {
            message = `Selecting at least ${minRequired} option${minRequired === 1 ? '' : 's'} is required`;
        } else { // minNotMet or maxExceeded
            message = `Selecting between ${minRequired} and ${maxRequired} options is required`;
        }

        return {
            requiredCheckboxOrRadioToBeChecked: {
                message: message
            }
        };
    };
}
