import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy } from '@angular/core';
import { isEmpty } from '@app/core/functions/isEmpty';
import { BootstrapThemeProp } from '@app/core/services/bootstrap-theme.service';
import { ExportsDataService } from '@app/exports/services/exports-data.service';
import { Modal } from '@app/modules/modals/modals.module';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize, Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'fdx-designated-hosts-modal',
    templateUrl: './designated-hosts-modal.component.html'
})
export class DesignatedHostsModalComponent implements Modal, OnDestroy {
    private readonly unsubscribe$: Subject<void> = new Subject<void>();

    hasError = false;
    hosts: string[] = [];
    errorMessage = '';
    loading = false;
    modalTheme: BootstrapThemeProp = 'primary';

    constructor(
        private readonly exportsDataService: ExportsDataService,
        private readonly modal: NgbActiveModal
    ) { }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    onModalInit({ databaseId, url }: { databaseId: string; url: string; }): void {
        if (isEmpty(url)) {
            this.hasError = true;
            this.errorMessage = 'The field `url` cannot be empty';

            return;
        }

        this.loading = true;

        this.exportsDataService.getDesignatedHosts(databaseId, url)
        .pipe(
            finalize(() => {
                this.loading = false;
            }),
            takeUntil(this.unsubscribe$)
        )
        .subscribe(
            ({ data }) => {
                this.hosts = data;
            },
            (error: HttpErrorResponse) => {
                this.hasError = true;
                this.errorMessage = error.message;
            }
        )
    }

    onModalSubmit(event: MouseEvent): void {
        this.modal.close();
    }

    onModalCancel(event: MouseEvent): void {
        this.modal.dismiss();
    }
}
