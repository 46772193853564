<div class="container">
    <fdx-page-title>Run FeedAI™ on a Single Title</fdx-page-title>
    <fdx-well>
        <form [formGroup]="form" (ngSubmit)="classify()">
            <div class="form-horizontal">
                <div class="row form-group align-items-center">
                    <label class="col-md-4 form-label text-end">Product Title</label>
                    <div class="col-md-5">
                        <input class="form-control col-md-5"
                            formControlName="phrases"
                            placeholder="dress shirt">
                    </div>
                </div>

                <div class="row form-group align-items-center">
                    <label class="col-md-4 form-label text-end">Smoothing</label>
                    <div class="col-md-5">
                        <input class="form-control col-md-5"
                            formControlName="smoothing"
                            placeholder="0.00001">
                    </div>
                </div>

                <div class="text-center">
                    <button class="btn btn-primary" type="submit">Submit</button>
                </div>
            </div>
        </form>

        <fdx-well *ngIf="classification" class="mt-4">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th>Category ID</th>
                        <th>Google Product Category</th>
                        <th>Probability</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let distribution of classification.distribution">
                        <td>{{ distribution.cid }}</td>
                        <td>{{ distribution.breadcrumb }}</td>
                        <td>{{ distribution.probability | percent:'0.2-2' }}</td>
                    </tr>
                </tbody>
            </table>
        </fdx-well>

        <fdx-well class="examples mt-4">
            <fdx-well-header>Examples</fdx-well-header>
            <ul class="list-group list-group-flush">
                <li class="list-group-item">Sporti Girls UPF 50+ Paradise Stripe Colorblock Long Sleeve Shirt Sport Fit Rash Guard</li>
                <li class="list-group-item">Levity Scrunch High Heel Dress Boot in Black Suede, Size 11</li>
                <li class="list-group-item">iRobot Roomba 980 Robot Vacuum- Wi-Fi Connected Mapping, Works with Alexa, Ideal for Pet Hair, Carpets, Hard Floors</li>
                <li class="list-group-item">Womens Robin T-Shirt Costume</li>
                <li class="list-group-item">Zebra Striped Pants</li>
                <li class="list-group-item">Metz Single-Hole Bathroom Faucet - Oil Rubbed Bronze / Pop-Up Drain Without Overflow</li>
            </ul>
        </fdx-well>
    </fdx-well>
</div>
