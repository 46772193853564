import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Requests
import { PostFtpTriggersRequest } from './requests/post-ftp-triggers.request';

// Responses
import { GetFtpTriggersResponse } from './responses/get-ftp-triggers.response';
import { PostFtpTriggersResponse } from './responses/post-ftp-triggers.response';

@Injectable({
    providedIn: 'root'
})
export class FtpTriggerDataService {
    constructor(
        private http: HttpClient
    ) { }

    public getFtpTriggers(databaseId: string): Observable<GetFtpTriggersResponse> {
        return this.http.get<GetFtpTriggersResponse>(`/api.php/dbs/${databaseId}/ftp_triggers`);
    }

    public deleteFtpTriggers(databaseId: string, triggerId: string): Observable<void> {
        return this.http.delete<void>(`/api.php/dbs/${databaseId}/ftp_triggers/${triggerId}`);
    }

    public postFtpTriggers(databaseId: string, params: PostFtpTriggersRequest): Observable<PostFtpTriggersResponse> {
        return this.http.post<PostFtpTriggersResponse>(`/api.php/dbs/${databaseId}/ftp_triggers`, params);
    }
}
