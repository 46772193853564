import * as angular from 'angular';
import FdxMapFieldFaqModalController from './fdx-map-field-faq-modal.controller';
import { MODULE_NAME } from '../../../../config/constants';

import './fdx-map-field-faq-modal.styles.scss';

export const componentName = 'fdxMapFieldFaqModal';

angular.module(MODULE_NAME).component(componentName, {
	template: require('./fdx-map-field-faq-modal.template.html'),
	controller: FdxMapFieldFaqModalController,
	bindings: {
		// from uibmodal
		close: '&',
		dismiss: '&',
		resolve: '<?'
	}
});
