// Codemirror init
const infix_functions = [
	'contains_s',
	'contains_words',
	'contains_word',
	'contains_all',
	'contains_any',
	'contains',
	'not_contains_s',
	'not_contains_word',
	'not_contains',
	'equal_s',
	'equal_any',
	'equal',
	'not_equal_s',
	'not_equal',
	'begins_with',
	'not_begins_with',
	'ends_with',
	'not_ends_with',
	'before',
	'after',
	'true',
	'not',
	'is_empty',
	'is_not_empty',
];

const postfix_functions = [
	'str_len',
	'word_count',
	'parse_date',
	'current_time',
	'parse_num',
	'percent',
	'leftnotrunc',
	'left_s',
	'left',
	'right_s',
	'right',
	'mid_s',
	'mid',
	'sort_values',
	'flip_values',
	'str_hash',
	'trim_spaces',
	'replace_i',
	'replace_pattern',
	'replace_exact',
	'replace_words',
	'separate_words',
	'lcase_first',
	'lcase',
	'ucase_first',
	'ucase',
	'tcase',
	'index_of',
	'substring',
	'format_date',
	'round_number',
	'round_up',
	'round_down',
	'margin',
	'convert_category',
	'nohtml',
	'repair_html',
	'decode_html_entities',
	'extract_html_list_items',
	'parse_color',
	'rakuten_property',
	'image_info',
	'transform_image',
	'add_padding',
	'add_overlay',
	'add_text',
	'convert_to_rgb',
	'renew',
	'blur_background',
	'extend_background',
	'resize',
	'crop_image',
	'url_encode',
	'url_decode',
	'minimum',
	'maximum',
	'parse_words',
	'replace',
	'unique_id',
	'filter_items_from_list',
	'match',
	'repeat_str',
	'parse_measures',
    'csv_get',
	'json_get',
	'is_alphanumber',
	'is_alpha',
	'is_number',
	'strip_backslash',
	'transliterate_to_ascii',
	'deduplicate_words',
	'collapse_spaces',
	'grab_from_delimited_map',
	'write_xml',
	'valid_gtin',
	'infer_product_id_type',
	'combine_list_of_lists',
	'google_taxonomy_path_to_id_list',
	'google_taxonomy_id_to_path'
];

const MAX_SUGGESTIONS = 200;

/**
 *
 * @param {string[][]} suggestionGroups A list of lists to match to.  Earlier lists will be given a higher ranking than later lists.
 * @param {*} curWord The word to search against.
 * @returns An array with all appropiate items sorted by their ranking.  Prioritizes earlier lists over later ones and start of string matches over
 * mid-string matches.
 */
function suggestionRanking(suggestionGroups, curWord) {
    // Filter your possible suggestions against the current word (and escape all possible regex chars)
    const regex = new RegExp(curWord.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&'), 'i');

    return suggestionGroups.map((suggestions) => {
        return suggestions.map((item) => {
            if (item.match(regex)) {
                // If it's at the start of the word, ignoring brackets (as curWord already has the leading bracket stripped),
                // rank it higher than the match being mid word.
                if (item.replace('[', '').toLowerCase().indexOf(curWord.toLowerCase()) === 0) {
                    return {
                        item,
                        ranking: 2
                    }
                }
                return {
                    item,
                    ranking: 1
                }
            }
            return null;
            // Clean the invalid items out of the lists, and then sort them.  Afterwards, combine
            // the lists.  When combined, the lists are sored in itself, then end up orgainzed with
            // earlier entered lists before later entered lists.
        }).filter((item) => Boolean(item)).sort((a, b) => b.ranking - a.ranking);
    }).flat().map((item) => item.item);

}

/**
 * Handle the pasting action of items to automatically split them when we're using
 * "multiple" type operators. This is a solution for:
 * https://feedonomics.atlassian.net/browse/FP-9459
 *
 * @param {*} editor the codemirror editor
 * @param {*} change the change from the paste action
 */
export function handleNewlinePasting(editor, change) {
    // First, remove all blanks from change text and make a copy of it
    let changeArray = [...change.text].filter((text) => text !== '');

    // Don't continue with this formatting unless we still have more than one value
    if (changeArray.length <= 1) {
        return;
    }

    // Dedupe items after we've determined we're pasting multiple changes
    changeArray = [...new Set(changeArray)];

    const cursor = editor.getCursor();
    const currentLine = editor.getLine(cursor.line);
    const start = cursor.ch;

    /**
     * Get the end-trimmed current line up to the cursor, then trim it, so we can
     * compare the two lengths later.
     */
    const lineUpToCursor = currentLine.substring(0, start);
    let trimmedBefore = lineUpToCursor.trimEnd();
    let trimLengthBefore = (lineUpToCursor.length - trimmedBefore.length);
    let changeOverriden = false;

    /**
     * If the user has already typed an opening parenthesis, let's handle that case first
     * by removing it, then trimming again, keeping track of how many characters we've now
     * stripped off in total.
     */
    if (trimmedBefore.endsWith('(')) {
        let trimmedText = trimmedBefore.slice(0, -1).trimEnd();
        trimLengthBefore += trimmedBefore.length - trimmedText.length;
        trimmedBefore = trimmedText;
    }

    /**
     * See if the trimmedBefore ends with the final characters of any of the "multiple" operators
     * "contains_any", "contains_all", and "equal_any", which all end with "_any" and "_all".
     */
    if (trimmedBefore.endsWith('_any') || trimmedBefore.endsWith('_all')) {
        let joinedText = changeArray
            .filter((item) => item !== '')      // Filter out empty values
            .map((item) => `'${item}'`)         // Surround each item with single quotes
            .join(', ');                        // Join together with a comma and a space.

        // Prepend the joinedText with one space, then surround the original joinedText with parenthesis.
        joinedText = ` (${joinedText})`;

        // Update the from value to remove any spaces that were trimmed
        const newFrom = {...change.from};
        newFrom.ch -= trimLengthBefore;

        /**
         * Update the change with the newFrom and an array only containing joinedText. Also
         * mark that the change is being overridden so we don't accidentally override
         * something that doesn't need to be overwritten in the next step.
         */
        changeOverriden = true;
        change.update(newFrom, null, [joinedText]);
    }

    /**
     * Finally, check to see if the user has already typed an ending parenthesis directly after
     * where this update has happened (if it has happened). If so, update the change's "to" value
     * to remove the extra spaces that were removed.
     */
    const lineAfterCursor = currentLine.substring(start);
    const trimmedAfter = lineAfterCursor.trimStart();
    let trimLengthAfter = (lineAfterCursor.length - trimmedAfter.length);
    if (changeOverriden && trimmedAfter.startsWith(')')) {
        const newTo = {...change.to};
        newTo.ch += trimLengthAfter + 1;    // +1 to account for the ending parenthesis
        change.update(null, newTo, null);
    }
}

export const cm_variables = {

	mode_transformer_parser: function(valid_db_fields) {

		valid_db_fields = valid_db_fields.join('|');
		const valid_db_fields_regex = new RegExp('\\[(' + valid_db_fields + ')\\]');
		const valid_infix_regex = new RegExp(infix_functions.join('|'), 'i');
		const valid_postfix_regex = new RegExp(postfix_functions.join('|'), 'i');

		return {
				// The start state contains the rules that are intially used
				start: [
					// Comments
					{regex: /#.*/, token: 'comment'},

					// Strings
					{regex: /'(?:[^\\]|\\.)*?'/, token: 'string'},
					{regex: /'(.*)?/, token: 'string', next: 'string'},

					// Valid Variables
					{regex: valid_db_fields_regex, token: 'variable'},
					// Invalid Variables
					{regex: /\[[^\]]*\]/, token: 'variable2'},

					// Infix Functions
					{regex: valid_infix_regex, token: 'atom'},

					// Postfix Functions
					{regex: valid_postfix_regex, token: 'atom'},
				],
				string: [
					{regex: /([^\\]|\\.)*?'/, token: 'string', next: 'start'},
					{regex: /.*/, token: 'string'}
				],
			}
	},
	mode_genai: function(valid_db_fields) {
		valid_db_fields = valid_db_fields.join('|');
		const valid_db_fields_regex = new RegExp('\\[(' + valid_db_fields + ')\\]');

		return {
				// The start state contains the rules that are intially used
				start: [

					// Valid Variables
					{regex: valid_db_fields_regex, token: 'variable'},
					// Invalid Variables
					{regex: /\[[^\]]*\]/, token: 'variable2'}

				],
				string: [
					{regex: /([^\\]|\\.)*?'/, token: 'string', next: 'start'},
					{regex: /.*/, token: 'string'}
				],
			}
	},
	hint_transformer_parser: function(columnNames, transformers_override, filter_transformers = []) {
        let list = [];
		let transformers = [
			// Infix Functions
			'equal',
			'equal_s',
			'equal_any',
			'not_equal',
			'not_equal_s',
			'contains',
			'contains_s',
			'contains_all',
			'contains_any',
			'contains_word',
			'contains_words',
			'not_contains',
			'not_contains_word',
			'not_contains_s',
			'begins_with',
			'not_begins_with',
			'ends_with',
			'not_ends_with',
			'after',
			'before',
			'NOT',
			'AND',
			'OR',
			'is_empty',
			'is_not_empty',

			// Postfix Functions
			'str_len( [field] )',
			'word_count( [field], \'separator\')',
			'nohtml( [field] )',
			'repair_html( [field] )',
			'decode_html_entities( [field] )',
			'extract_html_list_items( [field], \'separator\', start_offset, length )',
			'lcase( [field] )',
			'lcase_first( [field] )',
			'ucase( [field] )',
			'ucase_first( [field] )',
			'tcase( [field] )',
			'replace_i( \'search_for\', \'replace_with\', [field] )',
			'replace( \'search_for\', \'replace_with\', [field] )',
			'left( [field_name], \'text_to_stop_before\' )',
			'left_s( [field_name], \'text_to_stop_before\' )',
			'leftNoTrunc( [field_name], 150 )',
			'right( [field_name], \'text_to_start_after\' )',
			'right_s( [field_name], \'text_to_start_after\' )',
			'mid( [fld_name], \'txt_start\', \'txt_stop\' )',
			'mid_s( [fld_name], \'txt_start\', \'txt_stop\' )',
			'substring( [field_name], start_offset, length )',
			'sort_values( [field_name], \'delimiter\' )',
			'flip_values( [field_name], \'delimiter\' )',
			'strip_backslash( [field_name] )',
			'deduplicate_words( [field_name] )',
			'index_of( [field_name], \'search_for\', offset )',
			'match( \'regex\', [field], offset, \'pattern_modifiers\')',
			'repeat_str( [field], \'times\')',
			'filter_items_from_list( [field], \'delimiter\', \'query_to_keep\')',
			'replace_pattern( \'regex\', \'replace_with\', [field] )',
			'separate_words( [field], \'separator\' )',
            'csv_get( [field], 0 )',
			'replace_exact( \'search_for\', \'replace_with\', [field] )',
			'json_get( [json_field], \'json_key\' )',
			'is_alpha( [field] )',
			'is_number( [field] )',
			'is_alphanumber( [field] )',
			'trim_spaces( [field_name] )',
			'transliterate_to_ascii( [field] )',
			'collapse_spaces( [field_name] )',
			'format_date( [field_name], \'Y-m-d H:i\' )',
			'parse_date( [field_name] )',
			'current_time()',
			'minimum( [field_1], [field_2], \'value1\' )',
			'maximum( [field_1], [field_2], \'value1\' )',
			'parse_num( [field] )',
			'percent( [field] )',
			'round_number( [field], 2, \'up\' )',
			'margin( [field_1], [field_2] )',
			'parse_color( [field] )',
			'unique_id( [field], \'hash_method\' )',
			'url_encode( [field] )',
			'url_decode( [field] )',
			'convert_category( [google_shopping_category], \'shopzilla\', [gender] )',
			'valid_gtin( [field] )',
			'infer_product_id_type( [field] )',
			'combine_list_of_lists(\'input_delimiter\', \'columns_delimiter\', \'lists_delimiter\', [field], [field])',
			'write_xml(\'tag_name\', \'content\')',
			'grab_from_delimited_map(\'key\', \'map_string\', \'map_delimiter\', \'values_string\', \'values_delimiter\')',
			'parse_measures([field])',
			'google_taxonomy_path_to_id_list([field], \'EN\')',
			'google_taxonomy_id_to_path([field], \'EN\')'
		];
		let image_transformations = [];

		if(filter_transformers.includes('image_processing')) {
			transformers.push('transform_image( [field_name] , delimiter , transformation_1 , transformation_2)');
			transformers.push('image_info( [field_name] , delimiter , flags_optional )');

			image_transformations = [
				'add_padding( max_width , max_height , background_color_optional )',
				'add_overlay( position_x , position_y , image_url )',
                'add_text( position_x , position_y , text , options )',
				'convert_to_rgb( )',
				'renew( )',
				'resize( max_width , max_height , maintain_aspect_ratio_optional )',
				'blur_background( max_width , max_height )',
				'extend_background( max_width , max_height )',
				'crop_image( max_width , max_height , anchor_x , anchor_y )',
			];
		}

		if(filter_transformers.includes('rakuten_properties_transformer')) {
			transformers.push('rakuten_property(\'attribute\')');
		}

		if(transformers_override) {
			transformers = transformers_override;
		}

		list = list.concat(transformers);


		return function (editor) {
			const cursor = editor.getCursor();
			const currentLine = editor.getLine(cursor.line);
            let start = cursor.ch;
            let end = start;

			if(
				currentLine.indexOf('transform_image') > -1
				&&
				!list.includes('add_padding( max_width , max_height , background_color_optional )') // checking for one of them is enough
			){
				list = list.concat(image_transformations);
			}
			else {
				list = list.filter((value) => !image_transformations.includes(value));
			}

			let text_left_of_cursor = '';
			// Grab all the text above the current cursor line
			for (let line_num=0; line_num < cursor.line; line_num++) {
				text_left_of_cursor += editor.getLine(line_num) + '\n';
			}
			// Grab the text to the left of the cursor on the current cursor line
			text_left_of_cursor += currentLine.substring(0, cursor.ch);

			// Iterate through captured text to find current cursor position's context
			let in_string = false;
			let in_comment = false;
			for (let char_num = 0; char_num < text_left_of_cursor.length; char_num++) {

				// If you encounter an escape character, skip the next character
				if (text_left_of_cursor[char_num] === '\\') {
					char_num++;
				}

				// Else if you encounter a ', you are now in a string
				else if (text_left_of_cursor[char_num] === "'") {
					in_string = !in_string;
				}

				// Else if you are not in a string and you encounter a #, you are in a comment
				else if (!in_string && text_left_of_cursor[char_num] === '#') {
					in_comment = true;
					// Skip until the end of the line
					for ( ; char_num < text_left_of_cursor.length; char_num++) {
						if (text_left_of_cursor[char_num] === '\n') {
							in_comment = false;
							break;
						}
					}
				}

			}

			// Find the end of the current (relative to cusor) word (i.e. next word boundary)
			while (end < currentLine.length && /[[\]\w$]+/.test(currentLine.charAt(end))) {
				++end;
			}

			// Find the start of the current (relative to cusor) word (i.e. previous word boundary)
			while (start && /[[\]\w$]+/.test(currentLine.charAt(start - 1))) {
                --start;
			}

			// suggestion_list starts empty
			let suggestion_list = [];

			// If there is a word in current cursor context
			if (start !== end && !in_string && !in_comment) {
				// Grab the current word of the cursor context
				let curWord = currentLine.slice(start, end);

                // If explictly putting in a field, remove the bracket for easier fuzzy matching.
                if (curWord.length > 0 && curWord[0] === '[') {
                    suggestion_list = suggestionRanking([columnNames], curWord.substring(1));
                } else {
                    suggestion_list = suggestionRanking([list, columnNames], curWord);
                }

                if (curWord && !isNaN(Number(curWord))) {
                    suggestion_list = [curWord, ...suggestion_list];
                }
			}

            // CodeMirror does not virtualize options.  Arbitrary safeguard from displaying enough items to
            // slow down the UI.
            suggestion_list = suggestion_list.slice(0, MAX_SUGGESTIONS);

			const result = {
				list: suggestion_list,
				from: CodeMirror.Pos(cursor.line, start),
				to: CodeMirror.Pos(cursor.line, end)
			};

			return result;
		};
	},
	hint_genai: function(list) {
		return function (editor) {
			const cursor = editor.getCursor();
			const currentLine = editor.getLine(cursor.line);
			let start = cursor.ch;
			let end = start;

			// Find the end of the current (relative to cusor) word (i.e. next word boundary)
			while (end < currentLine.length && /[[\]\w$]+/.test(currentLine.charAt(end))) {
				++end;
			}

			// Find the start of the current (relative to cusor) word (i.e. previous word boundary)
			while (start && /[[\]\w$]+/.test(currentLine.charAt(start - 1))) {
                --start;
			}

			// suggestion_list starts empty
			let suggestion_list = [];

			// If there is a word in current cursor context
			if (start !== end ) {
				// Grab the current word of the cursor context.  Remove starting brackets for better fuzzy matching
				const curWord = currentLine.slice(start, end).replace('[', '');
				suggestion_list = suggestionRanking([list], curWord);
			}

            // CodeMirror does not virtualize options.  Arbitrary safeguard from displaying enough items to
            // slow down the UI.
            suggestion_list = suggestion_list.slice(0, MAX_SUGGESTIONS);

			const result = {
				list: suggestion_list,
				from: CodeMirror.Pos(cursor.line, start),
				to: CodeMirror.Pos(cursor.line, end)
			};

			return result;
		};
	},

	cmInit: function cmInit(value=false, attribute=false) {
		return function(editor) {
            editor.on('beforeChange', (_cm, change) => {
                if (change.origin === 'paste') {
                    handleNewlinePasting(editor, change);
                }
            });

			editor.on('keyup', (cm, event) => {
				const cursor = editor.getCursor();
				const currentLine = editor.getLine(cursor.line);
				let start = cursor.ch;
				let end = start;

				// Find the end of the current (relative to cusor) word (i.e. next word boundary)
				while (end < currentLine.length && /[[\]\w$]+/.test(currentLine.charAt(end))) {
					++end;
				}

				// Find the start of the current (relative to cusor) word (i.e. previous word boundary)
				while (start && /[[\]\w$]+/.test(currentLine.charAt(start - 1))) {
                    --start;
				}

				// Dont auto suggest at all for:
				const skip_auto_suggest =
					cursor.ch === start
					|| (cursor.ch === end && currentLine.charAt(end) === ']')
					|| event.keyCode === 35 // End key
					|| event.keyCode === 36 // Home key
					;
				if (skip_auto_suggest) {
					return;
				}

				// Prevent autocomplete from following keypresses:
				// Enter, ctrl, tab, shift, space, ', [, ], (, ), comma
				const show_auto_complete =
					!cm.state.completionActive // This is whether or not the dialogue is open
					&& event.keyCode !== 13 // enter
					&& event.keyCode !== 17 // ctrl
					&& event.keyCode !== 9 // tab
					&& event.keyCode !== 16 // shift
					&& event.keyCode !== 32 // space
					&& event.keyCode !== 222 // '
					// && event.keyCode !== 219 // [
					// && event.keyCode !== 221 // ]
					&& event.keyCode !== 57 // (
					&& event.keyCode !== 48 // )
					&& event.keyCode !== 188 // ,

				if (show_auto_complete) {
					CodeMirror.commands.autocomplete(cm, null, {completeSingle: false});
				}

			});

			// Set the values of the codemirror before we bind the on change event
			if (attribute !== false) {
				// eslint-disable-next-line angular/timeout-service
				setTimeout(() => {
					// eslint-disable-next-line no-unused-vars
					editor.on('change', (_editor, _change) => {
						value[attribute] = true;
					});
					value[attribute] = false;

				}, 1000);
			}
		};
	}

};

// eslint-disable-next-line angular/window-service
window.cm_variables = cm_variables;
window.handleNewlinePasting = handleNewlinePasting;
