import { AccountModel } from '@ajs/models/account';
import { UserModel } from '@ajs/models/user';
import AppStateService from '@ajs/services/AppStateService';
import CSVService from '@ajs/services/CSVService';
import { fdxUI as FdxUIService } from '@ajs/services/fdxUI';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryTaxonomyStateService } from '@app/category-taxonomy/services/category-taxonomy-state.service';
import { isEmpty } from '@app/core/functions/isEmpty';
import { ICanDeactivate } from '@app/core/guards/unsaved-changes.guard';
import { AccountFeatureFlag } from '@app/core/models/enums/account-feature-flag.enum';
import { AppMenuTab } from '@app/core/models/enums/app-menu-tab.enum';
import { UserFeatureFlag } from '@app/core/models/enums/user-feature-flag.enum';
import { WINDOW } from '@app/core/providers/window.provider';
import { FdxUtilsService } from '@app/core/services/fdx-utils.service';
import { LinkService } from '@app/core/services/link.service';
import { DbFieldModel } from '@app/databases/models/db-field.model';
import { DbModel } from '@app/databases/models/db.model';
import { DatabasesDataService } from '@app/databases/services/databases-data.service';
import { ExportTriggerModel } from '@app/export-triggers/models/export-trigger.model';
import { ExportsFaqModalComponent } from '@app/exports/components/exports-faq-modal/exports-faq-modal.component';
import { ExportsNotificationModalComponent } from '@app/exports/components/exports-notification-modal/exports-notification-modal.component';
import { ExportsSimulatedDateTimeModalComponent } from '@app/exports/components/exports-simulated-date-time-modal/exports-simulated-date-time-modal.component';
import { PushExportDialogComponent } from '@app/exports/components/push-export-dialog/push-export-dialog.component';
import { ExportProtocol } from '@app/exports/enums/export.enums';
import { ExportModel } from '@app/exports/models/export.model';
import { ExistingExport } from '@app/exports/models/interfaces/existing-export.interface';
import { ExportSchedule } from '@app/exports/models/interfaces/export-schedule.interface';
import { WebhookParamsInterface } from '@app/exports/models/interfaces/webhook-params.interface';
import { WebhookTypeInterface } from '@app/exports/models/interfaces/webhook-type.interface';
import { DbFieldSortable } from '@app/exports/models/types/db-field-sortable.type';
import { ExportsFormatService } from '@app/exports/services/export-format.service';
import { ExportTriggersDataService } from '@app/exports/services/export-triggers-data.service';
import { ExportsDataService } from '@app/exports/services/exports-data.service';
import { ExportField } from '@app/exports/services/responses/get-exports.response';
import { RunParallelExportResponse } from '@app/exports/services/responses/run-parallel-export.response';
import { WebhookTypesService } from '@app/exports/services/webhook-types.service';
import { FtpTriggerDataService } from '@app/ftp-trigger/services/ftp-trigger-data.service';
import { CronService } from '@app/modules/cron/services/cron.service';
import { ModalService } from '@app/modules/modals/services/modal.service';
import { IconDefinition, faBell, faClock, faCopy, faDownload, faQuestionCircle, faRedo, faRocket, faTrash } from '@fortawesome/pro-solid-svg-icons';
import * as moment from 'moment';
import { Observable, Subject, catchError, finalize, firstValueFrom, forkJoin, map, merge, of, take, takeUntil, tap, throwError } from 'rxjs';

@Component({
    selector: 'fdx-exports-page',
    styleUrls: ['./exports-page.component.scss'],
    templateUrl: './exports-page.component.html'
})
export class ExportsPageComponent implements OnInit, OnDestroy, ICanDeactivate {

    private readonly appMenuTab: AppMenuTab = AppMenuTab.Exports;
    private readonly title: string = 'Exports';

    clockIcon: IconDefinition = faClock;
    iconHelp: IconDefinition = faQuestionCircle;
    iconRedo: IconDefinition = faRedo;
    iconDownload: IconDefinition = faDownload;
    iconPostman: IconDefinition = faRocket;
    iconCopy: IconDefinition = faCopy;
    iconTrash: IconDefinition = faTrash;
    iconBell: IconDefinition = faBell;

    @ViewChild('existingExportTitle') readonly existingExportTitle: ElementRef<HTMLElement>;

    dbFields: DbFieldModel[] = [];
    dbFieldsSortable: DbFieldSortable[] = [];
    exports = [];
    triggers = [];
    exportTriggers: ExportTriggerModel[] = [];
    currentExport: ExistingExport;
    initialized: boolean = true;
    notification = { address: '' };
    derivedChannelDestination: string = '';

    // eslint-disable-next-line @typescript-eslint/typedef
    exportSelectForm = new FormGroup({
        existingExport: new FormControl<ExistingExport>(null)
    });

    /**
     * TODO: Delete once the AngularJS ui-router is removed.
     */
    enableRouteChange: boolean = false;

    /**
     * Webhook Type Params - selection from Webhook Type drop-down auto-populates export settings with webhookTypeParams values
     */
    webhookTypeParams: Record<string, { params: WebhookParamsInterface }> = {};

    /**
     * Default timeout is 20 minutes (in seconds)
     */
    private readonly timeoutDefault: number = 20 * 60;

    // Init new export
    newExport: ExistingExport = {
        id: '',
        name: '',
        file_name: '',
        protocol: ExportProtocol.SFTP,
        // Needs to be alphabetically arranged to match the incoming object from the new
        // export form.
        protocol_info: {
            http_method: null,
            http_url: null,
            http_headers: null,
            http_body: null,
            login_type: 'normal',
            private_key: null,
            private_key_pass: null
        },
        host: '',
        username: '',
        password: '',
        export_fields: [{ field_name: '', export_field_name: '', sort_order: 0 }],
        export_selector: 'true',
        file_header: '',
        file_footer: '',
        threshold: 0,
        delimiter: 'tab',
        compression: 'gzip',
        quoted_fields: 0,
        delta_export: '0',
        deduplicate_field_name: [],
        export_format: 'delimited',
        include_column_names: 1,
        item_group_id: '',
        json_minify_type: 'full',
        export_encoding: '',
        enclosure: '',
        escape: '',
        destination: null,
        strip_characters: [
            '\r',
            '\n',
            '\t'
        ],
        show_empty_tags: 0,
        show_empty_parent_tags: 1,
        use_cdata: 0,
        xml_write_document_tag: 1,
        zip_inner_file_name: '',
        timeout: this.timeoutDefault,
        time_between_attempts: 30,
        max_attempts: 3,
        show_advanced_options: false,
        row_sort: '',
        row_order: 'ASC',
        row_limit: 0,
        sortable_fields: [],
        tags: [
            { 'tag': '', 'value': '' }
        ],
        export_index_field: '',

        error: '',
        saving_message: false,
        copied_from: null,
        message_timeout: null,
        message: false,
        validate_custom_input: false,

        // added to support current export model
        time_running: '',
        db_id: '',
        ftp_url: '',
        cron: '',
        cron_timezone: '',
        next_run_time: '',
        running: '0',
        cxn_id: 0,
        timestamp: '',
        legacy_workers: 0,
        worker_pid: 0,
        worker_hostname: '',
        blocked: 0,
        created_at: '',
        _schedules: { ...this.cronService.schedules },
        hasFtpTrigger: false,
        last_run_duration: '0',
        deduplicate_preserve_nulls: 0,
        paused: '0',
        ftp_trigger: false,
        export_triggered: false
    };

    private exportId: string | null = null;
    private readonly unsubscribe$: Subject<void> = new Subject<void>();

    get account(): AccountModel {
        return this.appStateService.getAccount();
    }

    get database(): DbModel {
        return this.appStateService.getDatabase();
    }

    get databaseId(): string {
        return this.appStateService.getDatabaseId();
    }

    get user(): UserModel {
        return this.appStateService.getUser();
    }

    get hasOnlySftp(): boolean {
        return this.account.hasFeatureEnabled(AccountFeatureFlag.ONLY_ALLOW_SFTP);
    }

    get isExistingFtpExport(): boolean {
        return this.currentExport.protocol === ExportProtocol.FTP;
    }

    /**
     * If feature flag is on, hide FTP. But allow existing FTPs.
     */
    get disableFtpCopy(): boolean {
        return this.hasOnlySftp && this.isExistingFtpExport;
    }

    get isDbPaused(): boolean {
        return Boolean(parseInt(this.database.paused));
    }

    get isEBSDatabase(): boolean {
        return this.database.event_sync === '1';
    }

    get showResetExportButton(): boolean {
        return this.currentExport.export_running && parseInt(this.currentExport.time_running, 10) > 5400;
    }

    get showDownloadWebhookForPostmanButton(): boolean {
        return (this.isAnalyst || this.user.hasFeatureEnabled(UserFeatureFlag.EXPORT_WEBHOOK_FOR_POSTMAN))
            && this.currentExport.protocol === 'webhook';
    }

    get isAnalyst(): boolean {
        return this.appStateService.isPrivacyLevelAtLeastAnalyst();
    }

    get hasNegativeExportThresholdDefault(): boolean {
        return this.account.hasFeature('export_threshold_default', '-1');
    }

    constructor(
        @Inject(WINDOW) private readonly window: Window,
        private readonly activatedRoute: ActivatedRoute,
        private readonly appStateService: AppStateService,
        private readonly categoryTaxonomyStateService: CategoryTaxonomyStateService,
        private readonly cronService: CronService,
        private readonly csvService: CSVService,
        private readonly databasesDataService: DatabasesDataService,
        private readonly exportsDataService: ExportsDataService,
        private readonly exportsFormatService: ExportsFormatService,
        private readonly exportTriggersDataService: ExportTriggersDataService,
        private readonly ftpTriggerDataService: FtpTriggerDataService,
        private readonly fdxUI: FdxUIService,
        private readonly fdxUtilsService: FdxUtilsService,
        private readonly linkService: LinkService,
        private readonly modalService: ModalService,
        private readonly router: Router,
        private readonly webHookTypesService: WebhookTypesService
    ) {}

    ngOnInit(): void {
        this.fdxUI.setTitle(this.title);
        this.fdxUI.setActiveTab(this.appMenuTab);

        if (this.hasNegativeExportThresholdDefault) {
            this.newExport.threshold = -1;
        }

        this.setUpExportIdQueryParamsSubscription();

        this.loadExports();

        this.initWebhookTypeParams()
            .pipe(
                finalize(() => {
                    if (this.categoryTaxonomyStateService.hasExportData) {
                        const { data, useNameForExportHeader, exportId } = this.categoryTaxonomyStateService.getExportData();
                        // Only handle/clear export data from taxonomy for new exports. Added to existing exports later once it's loaded.
                        if (exportId === '0') {
                            if (data.webhook_type) {
                                this.changeWebhookType(this.newExport, { webhookType: data.webhook_type, useNameForExportHeader });
                            }
                            this.mergeWithExport(this.newExport, false);
                            this.mergeExportFields(data.export_fields, false);
                            this.categoryTaxonomyStateService.clearExportData();
                        }

                    }
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();

        this.initExportSelect();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    canDeactivate(): Promise<boolean> | boolean {
        if (this.newExport.modified || this.currentExport?.modified) {
            return firstValueFrom(this.showUnsavedChangesConfirmationModal());
        }
        return true;
    }

    showHelp(): void {
        this.modalService.open(ExportsFaqModalComponent, { size: 'lg' });
    }

    setUpExportIdQueryParamsSubscription(): void {
        this.activatedRoute.queryParams
            .pipe(
                takeUntil(this.unsubscribe$)
            )
            .subscribe((params) => {

                if (typeof params.export_id === 'string') {
                    this.exportId = params.export_id;
                } else {
                    this.exportId = null;
                }

            });
    }

    loadExports(exportId: string = null): void {
        forkJoin({
            exports: this.exportsDataService.getExports(this.databaseId, { destination_metadata: true }),
            triggers: this.ftpTriggerDataService.getFtpTriggers(this.databaseId)
        })
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (data) => {
                    this.exports = data.exports;
                    this.triggers = data.triggers;
                    this.onExportsLoaded(this.triggers, exportId);
                }
            });
    }

    onExportsLoaded(triggers, exportId: string): void {
        // If no search export_id, initialize it
        if (!this.exportId && this.exports.length > 0) {
            void this.router.navigate(this.linkService.exportsLink, {
                queryParams: {
                    export_id: this.exports[0].id
                },
                replaceUrl: true
            });
        } else if (this.exportId && !this.exports.some((baseExport) => baseExport.id === this.exportId)) {
            // If we have an export id that isn't actually an export, change to the first export if possible.
            void this.router.navigate(this.linkService.exportsLink, {
                queryParams: {
                    export_id: this.exports.length > 0 ? this.exports[0].id : null
                },
                replaceUrl: true
            });
        }

        this.dbFields = [];
        this.dbFieldsSortable = [];
        this.databasesDataService.getFields(this.databaseId).pipe(
            tap((data: DbFieldModel[]) => {
                this.dbFields = data;
                for (let n = 0; n < data.length; n++) {
                    this.dbFieldsSortable.push({
                        'name': data[n].field_name,
                        'value': data[n].field_name,
                        'source': 'Fields from File Map'
                    });
                }
                for (let n = 0; n < this.exports.length; n++) {
                    // //////////////////////////
                    // Initialize Integers
                    // //////////////////////////
                    this.exports[n].export_running = parseInt(this.exports[n].running);

                    this.exports[n].timeout = parseInt(this.exports[n].timeout);
                    this.exports[n].max_attempts = parseInt(this.exports[n].max_attempts);
                    this.exports[n].time_between_attempts = parseInt(this.exports[n].time_between_attempts);
                    this.exports[n].time_running = this.exports[n].time_running ? parseInt(this.exports[n].time_running) : 0;

                    // //////////////////////////
                    // Parse cron components
                    // //////////////////////////
                    this.exports[n]._schedules = this.fdxUtilsService.clone(this.cronService.schedules);
                    const cron_filter = function (el) {
                        return this.includes(el.value);
                    }
                    if (this.exports[n].cron) {
                        const cron_components = this.exports[n].cron.split(' ');
                        this.exports[n].cron_components = {};
                        if (cron_components.length > 4) {
                            this.exports[n].cron_components.day = this.exports[n]._schedules.days.filter(
                                cron_filter,
                                cron_components[4].split(',')
                            );
                            this.exports[n].cron_components.hour = this.exports[n]._schedules.hours.filter(
                                cron_filter,
                                cron_components[1].split(',')
                            );
                            this.exports[n].cron_components.minute = cron_components[0].split(',');
                            this.exports[n].cron_components.minute.forEach((cron_minute) => {
                                const has_minute = this.exports[n]._schedules.minutes.some((schedule_minute) => {
                                    return schedule_minute.value === cron_minute;
                                });

                                if (!has_minute) {
                                    this.exports[n]._schedules.minutes.push({
                                        display_name: cron_minute,
                                        value: cron_minute
                                    });
                                }
                            });
                            this.exports[n].cron_components.minute = this.exports[n]._schedules.minutes.filter(
                                cron_filter,
                                this.exports[n].cron_components.minute
                            );
                        }
                    }
                    else {
                        this.exports[n].cron_components = {};
                        this.exports[n].cron_components.day = [];
                        this.exports[n].cron_components.hour = [];
                        this.exports[n].cron_components.minute = [];
                    }

                    // //////////////////////////
                    // Initialize the strip_characters
                    // //////////////////////////
                    this.exports[n].strip_characters = JSON.parse(this.exports[n].strip_characters);

                    // Initialize the current_export
                    if (this.exportId === this.exports[n].id || exportId === this.exports[n].id) {
                        this.currentExport = structuredClone(this.exports[n]);

                        this.exportSelectForm.patchValue({
                            existingExport: this.currentExport
                        });

                        this.addExportTagRow(this.currentExport);
                    }
                }
            }),
            catchError((response: unknown) => {
                return throwError(() => response);
            }),
            takeUntil(this.unsubscribe$)
        ).subscribe();

        if (triggers) {
            this.exports.forEach((exportItem) => {
                exportItem.hasFtpTrigger = this.exportsFormatService.exportHasFtpTrigger(exportItem, this.triggers);
            });
        }

        if (this.activatedRoute.snapshot.queryParams.scroll) {
            setTimeout(() => {
                this.existingExportTitle?.nativeElement.scrollIntoView({ behavior: 'smooth' });
                if (this.categoryTaxonomyStateService.hasExportData) {
                    this.addExportFieldsExisting();
                }
            }, 1000);
        }

    }

    // create export
    addExport(validateCustomInput?: boolean): void {
        if (this.currentExport?.modified) {
            this.showUnsavedChangesConfirmationModal('If you proceed, you will lose any unsaved changes on your existing export.').pipe(
                tap((accepted) => {
                    if (!accepted) return;
                    this.addExportHelper(validateCustomInput);
                }),
                takeUntil(this.unsubscribe$)
            ).subscribe();
            return;
        }
        this.addExportHelper(validateCustomInput);
    }

    private addExportHelper(validateCustomInput?: boolean): void {
        this.newExport.row_limit = this.newExport.row_limit || 0;
        this.newExport.validate_custom_input = typeof validateCustomInput !== 'undefined' ? validateCustomInput : true;

        this.newExport.saving_message = true;
        this.newExport.error = '';
        let emptyRow: ExportField;

        if (this.appStateService.isPrivacyLevelAtLeastAnalyst() && !this.newExport.destination) {
            this.newExport.saving_message = false;
            this.newExport.error = 'The field `destination` cannot be empty';
            return;
        }

        // Hold on to the empty row created by the export fields component.
        // Needed as this line would remove the empty field, but if it didn't save,
        // this would break the component.
        for (let n = 0; n < this.newExport.export_fields.length; n++) {
            if (isEmpty(this.newExport.export_fields[n].field_name) && isEmpty(this.newExport.export_fields[n].export_field_name)) {
                emptyRow = this.newExport.export_fields.splice(n, 1)[0];
            }
        }

        if (this.newExport.copied_from) {
            this.newExport.copied_from = this.newExport.copied_from.id;
        }
        this.newExport.row_limit = this.newExport.row_limit || 0;
        this.newExport.validate_custom_input = typeof validateCustomInput !== 'undefined' ? validateCustomInput : true;

        this.newExport.deduplicate_field_name = this.sanitizeDedupe(this.newExport.deduplicate_field_name);

        // Filter invalid values for item_group_id for exports where it's not relevant.
        // There are examples where existing exports retain this value while no longer relevant, so when copied it persists.
        if (!this.newExport.export_format.includes('item_group')) {
            this.newExport.item_group_id = '';
        }

        this.exportsDataService.addExport(this.databaseId, this.newExport).pipe(
            tap((data: ExportModel) => {
                this.newExport.error = '';
                this.currentExport = null;

                // Clear out indications that they are modified before we update the view.
                this.newExport.modified = false;
                if (this.currentExport) {
                    this.currentExport.modified = false;
                }

                // workaround to update existing export view
                // response.data does not appear to return the complete export object
                this.loadExports(data.id);

                // Cancel any existing timeout
                this.window.clearTimeout(this.newExport.message_timeout);

                // Make it reflash if it wasn't empty before
                this.newExport.saving_message = false;
                this.newExport.copied_from = null;
                this.newExport.message = false;
                this.window.setTimeout(() => {
                    this.newExport.message = true;
                }, 0);

                // Add a new timeout
                this.newExport.message_timeout = this.window.setTimeout(() => {
                    this.newExport.message = false;
                }, 1500);

                this.fdxUI.showToastSuccess('Export added!');
            }),
            catchError((response: unknown) => {
                if (response instanceof HttpErrorResponse) {
                    this.newExport.saving_message = false;
                    this.newExport.error = response.error;

                    // If we have some sort of error, readd the empty row to
                    // keep export_fields functioning.
                    if (emptyRow) {
                        this.newExport.export_fields.push(emptyRow);
                    }

                    if (response.error.indexOf('Error parsing json') > -1 || response.error.indexOf('Error parsing xml') > -1) {
                        const modalRef = this.modalService
                            .showConfirmationModal(
                                'Confirmation',
                                'Error parsing custom input, save anyway?',
                                'Ok'
                            );
                        modalRef.closed
                            .pipe(takeUntil(this.unsubscribe$))
                            .subscribe(() => {
                                this.newExport.error = '';
                                this.addExport(false);
                            });
                    }
                    return of(null);
                }
                return throwError(() => response);
            }),
            takeUntil(this.unsubscribe$)
        ).subscribe();
    }

    changeExport(exportItem?: ExistingExport): void {
        // Don't try to change while the component is refreshing.
        if (!this.initialized) return;

        if (this.currentExport?.modified) {
            this.showUnsavedChangesConfirmationModal().pipe(
                tap((accepted) => {
                    if (!accepted) return;
                    this.changeExportHelper(exportItem);
                }),
                takeUntil(this.unsubscribe$)
            ).subscribe();
            return;
        }
        this.changeExportHelper(exportItem);
    }

    private changeExportHelper(exportItem?: ExistingExport): void {
        this.exportTriggers = [];
        // The initialized flag triggers frontend UI to be re-initialized by reloading the DOM elemenent
        this.initialized = false;
        this.currentExport.modified = false;
        this.window.setTimeout(() => {
            this.initialized = true;
        });

        if (exportItem) {
            this.currentExport.added_message = false;
            this.currentExport = structuredClone(this.exports.find(({ id }) => {
                return id === exportItem.id;
            }));
        }

        this.loadExportTriggers();

        void this.router.navigate(this.linkService.exportsLink, {
            queryParams: {
                export_id: this.currentExport?.id
            }
        });

    }

    private loadExportTriggers(): void {

        this.exportTriggersDataService.getExportTriggers(this.databaseId, this.currentExport.id)
            .pipe(
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: (data: ExportTriggerModel[]) => {
                    this.exportTriggers = data;
                },
                error: () => {
                    this.fdxUI.showToastError('Failure to Get Export Triggers');
                }
            });
    }

    showExportTriggersMessage(): boolean {
        return this.isEBSDatabase && Array.isArray(this.exportTriggers) && this.exportTriggers.length > 0;
    }

    // automatically add export tag rows when last row is not empty
    addExportTagRow(exportItem): void {
        if (!Array.isArray(exportItem.tags)) {
            exportItem.tags = [];
        }

        const last_index = exportItem.tags.length - 1;
        if (last_index === -1 || exportItem.tags[last_index].tag != '' || exportItem.tags[last_index].value != '') {
            exportItem.tags.push({ tag: '', value: '' });
        }
    }

    mergeWithExport(partialExportItem, isExisting = false): void {
        const snakeCasedPartialExportItem = this.fdxUtilsService.camelToSnakeCaseObject(partialExportItem);

        const ignoreProps = {
            copied_from: null,
            import_dgq_templates: false,
            preset: null,
            webhook_type: null
        };

        if (isExisting) {
            const updatedExport = {
                ...this.currentExport,
                ...snakeCasedPartialExportItem
            };

            // these props are injected, but not returned by api response
            const a = {...this.currentExport, ...ignoreProps };
            const b = {...updatedExport, ...ignoreProps };

            // return if updatedExport is identical to current export
            if (JSON.stringify(a) === JSON.stringify(b)) {
                return;
            }

            this.currentExport = updatedExport;

            this.currentExport.modified = true;
        } else {
            // This check is extremely dependent on the order of any nested properties
            // from the partial export item.
            if (JSON.stringify({
                ...this.newExport,
                ...snakeCasedPartialExportItem,
                ...ignoreProps
            }) === JSON.stringify({
                ...this.newExport,
                ...ignoreProps
            })) {
                return;
            }
            this.newExport = {
                ...this.newExport,
                ...snakeCasedPartialExportItem
            }

            this.newExport.modified = true;

        }
    }

    addExportFieldsExisting(): void {
        const {data, exportId} = this.categoryTaxonomyStateService.getExportData();
        // Guard against race condition && not update if all fields were duplicate
        if(exportId === '0' || data.export_fields.length === 0) {
            return;
        }

        this.currentExport.export_fields = [...this.currentExport.export_fields, ...data.export_fields];
        this.currentExport = {...this.currentExport};
        this.categoryTaxonomyStateService.clearExportData();
        this.currentExport.modified = true;
    }

    mergeExportFields(exportFields, isExisting: boolean): void {
        if (isExisting) {
            this.currentExport.export_fields = exportFields;
        } else {
            if (JSON.stringify(this.newExport.export_fields) !== JSON.stringify(exportFields)) {
                this.newExport.modified = true;
            }
            this.newExport.export_fields = exportFields;
        }
    }

    processWebhookHttpBody(exportItem: ExistingExport, useNameForExportHeader: boolean): void {
        if (useNameForExportHeader && !isEmpty(exportItem.protocol_info?.http_body)) {
            const httpBodyParsed = JSON.parse(exportItem.protocol_info.http_body);
            httpBodyParsed.name_attribute_flag = true;

            exportItem.protocol_info.http_body = JSON.stringify(httpBodyParsed, null, 8);
        }
    }

    changeWebhookType(exportItem: ExistingExport, { webhookType, useNameForExportHeader = false }): void {
        const webhook_type = webhookType ?? exportItem.webhook_type;
        exportItem.webhook_type = webhook_type;
        exportItem = Object.assign(exportItem, this.fdxUtilsService.clone(this.webhookTypeParams[webhook_type].params));

        this.processWebhookHttpBody(exportItem, useNameForExportHeader);

        // If they have an account wide default export  threshold - honor it here
        if (this.hasNegativeExportThresholdDefault) {
            exportItem.threshold = -1;
        }
    }

    downloadExportsSummary(): void {
        const rows = [];
        rows.push([
            'export_name',
            'export_id',
            'scheduled',
            'triggered'
        ]);

        this.exports.forEach((exportItem: ExistingExport) => {
            rows.push([
                exportItem.name,
                exportItem.id,
                (exportItem.cron !== null ? 'yes' : 'no'),
                exportItem.hasFtpTrigger ? 'yes' : 'no'
            ]);
        });

        const filename = `${this.database.name}_exports_summary-${moment().format('MM-DD-YYYY')}.csv`;
        this.csvService.download(filename, rows);
    }

    resetExport(exportItem: ExistingExport): void {
        this.exportsDataService.resetExport(this.databaseId, exportItem.id)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                'next': (data) => {
                    if (data.status === 'success') {
                        // Disable export running flag and reset button
                        exportItem.export_running = false;
                        exportItem.disable_reset_button = true;
                        this.fdxUI.showToastSuccess('Export has been reset!');
                    } else if (data.status === 'fail') {
                        this.fdxUI.showToastError('Failed resetting export! Export might have already finished running.');
                    }
                },
                'error': () => {
                    this.fdxUI.showToastError('Failed resetting export!');
                }
            });
    }

    async confirmPaused(): Promise<boolean> {
        const defaultValue = 'notConfirmed';
        const result = await firstValueFrom(
            this.modalService.showDangerConfirmationModal(
                'Confirmation',
                'This database is paused. Are you sure you want to run this export?',
                'Confirm'
            ).closed,
            { defaultValue }
        );
        return (result !== defaultValue);
    }

    // Download Webhook for Postman
    downloadWebhookForPostman(exportItem: ExistingExport): void {
        const do_notify = false;
        const push = false;

        this.runParallelExport(exportItem, push, do_notify).then(() => {
                const params = `format=postman&url=${this.currentExport.last_download_link}&total_count=${exportItem.total_count}`;
                const url = `/api.php/dbs/${this.databaseId}/exports/${this.currentExport.id}/download_webhook?${params}`;
                this.fdxUtilsService.downloadURL(url);
            },
            () => {}
        );
    }

    runParallelExportSimulated(exportItem: ExistingExport, push, doNotify: boolean): void {
        this.modalService.open(ExportsSimulatedDateTimeModalComponent)
            .closed
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((simulatedDateTime) => {
                void this.runParallelExport(exportItem, push, doNotify, simulatedDateTime);
            });
    }

    async runParallelExport(exportItem: ExistingExport, push: (boolean|string), doNotify = false, simulatedDate = ''): Promise<any> {
        // prompt confirmation if feedbase is paused
        let confirmation = false;
        if (this.isDbPaused) {
            confirmation = await this.confirmPaused();
        }

        // execute only if conditions are met
        if (!this.isDbPaused || confirmation) {
            exportItem.export_running = true;
            exportItem.has_ever_run = true;
            delete exportItem.run_error;
            const params = {
                push: push,
                simulated_date: simulatedDate,
                do_notify: false,
                notification_email: ''
            };

            if (doNotify && this.notification.address) {
                params.do_notify = doNotify;
                params.notification_email = this.notification.address + '@feedonomics.com';
            }

            // Run Export
            const observable = this.exportsDataService.runParallelExport(this.databaseId, exportItem.id, params);
            const promise  = firstValueFrom(observable);
            promise.then((data: RunParallelExportResponse) => {
                exportItem.export_running = false;

                const url = this.fdxUtilsService.normalizeURL(data.data.url);
                exportItem.last_download_link = url;

                exportItem.total_count = data.data.total_count;

                // we pass false as a boolean to not push but also not download
                if (push == 'false') {
                    this.fdxUtilsService.downloadURL(url);
                }

                if (data.data.extra && data.data.extra.http_status === 202) {
                    this.fdxUI.showToastSuccess('Your export is being processed. Please check the export logs for further information');
                }
            }, (response: HttpErrorResponse) => {
                exportItem.run_error = response.error;
                exportItem.export_running = false;
            });
            return promise;
        }
    }

    copyExportFields(exportItem: ExistingExport): void {
        if (!this.newExport.modified) {
            this.copyExportFieldsHelper(exportItem);
        } else {
            this.showUnsavedChangesConfirmationModal().pipe(
                tap((accepted) => {
                    if (!accepted) return;
                    this.copyExportFieldsHelper(exportItem);
                }),
                takeUntil(this.unsubscribe$)
            ).subscribe();
        }
    }

    private copyExportFieldsHelper(exportItem: ExistingExport): void {
        // Copy export.export_fields
        this.newExport.export_fields = [];
        for (let n = 0; n < exportItem.export_fields.length; n++) {
            this.newExport.export_fields.push({
                field_name: exportItem.export_fields[n].field_name,
                export_field_name: exportItem.export_fields[n].export_field_name,
                sort_order: n
            });
        }

        // Copy all other export keys
        for (const key of Object.keys(this.newExport)) {
            if (key !== 'export_fields') {
                this.newExport[key] = exportItem[key];
            }
        }
        this.newExport.copied_from = exportItem;

        // temp fix, force view update
        this.newExport = {
            ...this.newExport,
            modified: false
        };
    }

    deleteModal(exportItem: ExistingExport): void {
        this.modalService.showDangerConfirmationModal(
            'Confirm Export Delete',
            `Are you sure you want to delete ${exportItem.name}?`,
            'Delete'
        )
            .closed
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.deleteExport(exportItem);
            });
    }

    deleteExport(exportItem: ExistingExport): void {
        this.exportsDataService.deleteExport(this.databaseId, exportItem.id)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() =>{
                // Delete from this.exports
                const exportsList = [...this.exports];
                for (let n = 0; n < exportsList.length; n++) {
                    if (exportsList[n].id === exportItem.id) {
                        exportsList.splice(n, 1);
                        if (exportsList.length === 0) {
                            this.exports = exportsList;
                            delete this.currentExport;
                            void this.router.navigate(this.linkService.exportsLink, {
                                queryParams: {
                                    export_id: null
                                },
                                replaceUrl: true
                            });
                            return;
                        }
                        else if (n < exportsList.length) {
                            this.currentExport = structuredClone(exportsList[n]);
                        }
                        else {
                            this.currentExport = structuredClone(exportsList[n - 1]);
                        }
                        this.changeExport();
                        this.exports = exportsList;
                        break;
                    }
                }
            });
    }

    private calcDerivedChannelDestination(): string {
        switch (this.currentExport.protocol) {
            case 'webhook':
                this.derivedChannelDestination = this.currentExport.protocol_info.http_url;
                break;

            case 'ftp':
            case 'sftp':
            case 'ftps':
                this.derivedChannelDestination = this.currentExport.host;
                break;

            default:
                this.derivedChannelDestination = 'Unknown Destination';
                break;
        }
        return this.derivedChannelDestination;
    }

    confirmPushExportModal(): void {
        this.modalService.open(PushExportDialogComponent, {
            size: 'lg',
            resolve: {
                export: this.currentExport,
                derivedChannelDestination: this.calcDerivedChannelDestination()
            }
        })
        .closed
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                void this.runParallelExport(this.currentExport, 'true', false);
            });
    }

    confirmNotificationExportModal(): void {
        this.modalService.open(ExportsNotificationModalComponent, {
            size: 'lg',
            resolve: {
                derivedChannelDestination: this.calcDerivedChannelDestination()
            }
        })
            .closed
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((notificationAddress: string) => {
                if (notificationAddress) {
                    this.notification.address = notificationAddress;
                    void this.runParallelExport(this.currentExport, 'true', true);
                }
            });
    }

    // Edit Export
    editExport(exportItem: ExistingExport, validate_custom_input = true): void {
        if (this.appStateService.isPrivacyLevelAtLeastAnalyst() && !exportItem.destination) {
            exportItem.error = 'The field `destination` cannot be empty';
            return;
        }

        exportItem.modified = true;
        exportItem.has_ever_run = false;
        exportItem.submit_running = true;
        exportItem.saving_message = true;

        const params = {
            name: exportItem.name,
            file_name: exportItem.file_name,
            destination: exportItem.destination,
            protocol: exportItem.protocol,
            protocol_info: exportItem.protocol_info,
            host: exportItem.host,
            username: exportItem.username,
            password: exportItem.password,
            export_fields: [...exportItem.export_fields],
            export_selector: exportItem.export_selector,
            file_header: exportItem.file_header,
            file_footer: exportItem.file_footer,
            threshold: exportItem.threshold,
            include_column_names: exportItem.include_column_names,
            item_group_id: exportItem.item_group_id,
            json_minify_type: exportItem.json_minify_type,

            export_encoding: exportItem.export_encoding,

            delimiter: exportItem.delimiter,
            enclosure: exportItem.enclosure,
            escape: exportItem.escape,
            strip_characters: exportItem.strip_characters ?? [],
            show_empty_tags: exportItem.show_empty_tags,
            show_empty_parent_tags: exportItem.show_empty_parent_tags,
            use_cdata: exportItem.use_cdata,
            xml_write_document_tag: exportItem.xml_write_document_tag,

            compression: exportItem.compression,
            zip_inner_file_name: exportItem.zip_inner_file_name,

            quoted_fields: exportItem.quoted_fields,
            delta_export: exportItem.delta_export,
            sortable_fields: exportItem.sortable_fields,
            deduplicate_field_name: this.sanitizeDedupe(exportItem.deduplicate_field_name ?? []),
            deduplicate_preserve_nulls: exportItem.deduplicate_preserve_nulls,
            export_format: exportItem.export_format,

            timeout: exportItem.timeout,
            max_attempts: exportItem.max_attempts,
            time_between_attempts: exportItem.time_between_attempts,

            row_sort: exportItem.row_sort,
            row_order: exportItem.row_order,
            row_limit: exportItem.row_limit || 0,
            tags: exportItem.tags || [],
            validate_custom_input: validate_custom_input ?? true,
            export_index_field: exportItem.export_index_field
        }

        const iterations = params.export_fields.length;
        let currentIndex = 0;
        for (let n = 0; n < iterations; n++) {
            // Note we don't increment the current index when splicing because that would cause us to skip an element in the array
            if (isEmpty(params.export_fields[currentIndex].field_name) && isEmpty(params.export_fields[currentIndex].export_field_name)) {
                params.export_fields.splice(currentIndex, 1);
            } else {
                currentIndex++;
            }
        }

        this.exportsDataService.updateExport(this.databaseId, exportItem.id, params)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: () => {
                    exportItem.modified = false;
                    exportItem.submit_running = false;
                    exportItem.error = '';
                    exportItem.saving_message = false;
                    exportItem.added_message = true;

                    const exportIndex: number = this.exports.findIndex(({id}) => id === exportItem.id);
                    this.exports[exportIndex] = {...exportItem};

                    // timeout added message
                    this.window.setTimeout(() => {
                        exportItem.added_message = false;
                    }, 1500);
                },
                error: (response: HttpErrorResponse) => {
                    exportItem.submit_running = false;
                    exportItem.saving_message = false;
                    exportItem.error = response.error;

                    if (exportItem.error.indexOf('Error parsing json') > -1 || exportItem.error.indexOf('Error parsing xml') > -1) {
                        this.modalService.showDangerConfirmationModal(
                            'Confirmation',
                            'Error parsing custom input, save anyway?',
                            'Save Anyway'
                        )
                            .closed
                            .pipe(takeUntil(this.unsubscribe$))
                            .subscribe(() => {
                                exportItem.error = '';
                                this.editExport(exportItem, false);
                            });
                    }
                }
            });
    }

    /* This is not the best way to prepare the data to be sent to the API, but
     * we're dealing with a frontend that is currently optimized for both AngularJS
     * and Angular 13. So at this point, we're going to sanitize the code and update
     * this later.
     */
    sanitizeDedupe(dedupeArray: string[]): string[] {
        return dedupeArray.filter((n) => n);
    }

    private initWebhookTypeParams(): Observable<WebhookTypeInterface[]> {
        return this.webHookTypesService.getWebhookTypes()
            .pipe(
                tap((data) => {
                    const dataParams = {};

                    data.forEach((entry) => {
                        dataParams[entry.webhook_type] = {
                            params: JSON.parse(entry.params) as WebhookParamsInterface
                        }
                    });

                    this.webhookTypeParams = dataParams;
                })
            );
    }

    private initExportSelect(): void {
        this.exportSelectForm.controls.existingExport.valueChanges.pipe(
            tap((exprt: ExistingExport) => this.changeExport(exprt)),
            takeUntil(this.unsubscribe$)
        ).subscribe();
    }

    private showUnsavedChangesConfirmationModal(message?: string): Observable<boolean> {
        const modalRef = this.modalService.showWarningConfirmationModal(
            'Unsaved changes',
            message || 'If you proceed, you will lose the changes you have made.',
            'Lose changes & proceed',
            'Keep editing'
        );
        return merge(
            modalRef.closed.pipe(map(() => true)),
            modalRef.dismissed.pipe(map(() => false))
        ).pipe(
            take(1)
        )
    }

    scheduleUpdateOnExportList(cronData: ExportSchedule): void {
        // Update just the cron in the list of schedules
        const inListExport = this.exports.find(({ id }) => {
            return id === this.currentExport.id;
        });
        inListExport.cron = cronData.cron;
        inListExport.cron_components = cronData.cron_components;
        inListExport.cron_timezone = cronData.cron_timezone;
    }
}
