import { Component } from '@angular/core';
import { LogType } from '@app/logs/models/enums/log-type.enum';

@Component({
    selector: 'fdx-logs-import-export',
    templateUrl: './logs-import-export.component.html',
    styleUrls: ['./logs-import-export.component.scss']
})
export class LogsImportExportComponent {
    readonly logType: LogType = LogType.ImportExport;
}
