import { Injectable } from '@angular/core';
import { PendingInvitesResponse } from '@app/user/services/responses/pending-invites.response';
import { UserDataService } from '@app/user/services/user-data.service';
import { Subject, finalize, repeat, skipWhile, take, takeUntil } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class InvitationsService {

    private static readonly PERMISSION_CHECK_DELAY: number = 60000;
    private readonly unsubscribe$: Subject<void> = new Subject<void>();
    private loadingPermissions?: boolean;
    private pendingPermissions: number = 0;

    get hasPendingInvitations(): boolean {
        return this.pendingPermissions > 0;
    }

    constructor(
        private readonly userDataService: UserDataService
    ) {}

    loadInvitations(): void {
        if (this.loadingPermissions === undefined) {
            this.loadPermissions();
        }
    }

    private loadPermissions(): void {
        this.loadingPermissions = true;

        this.userDataService.getPendingInvites().pipe(
            repeat({delay: InvitationsService.PERMISSION_CHECK_DELAY }),
            skipWhile((response: PendingInvitesResponse) => {
                this.pendingPermissions = response.pending_count;
                return this.hasPendingInvitations;
            }),
            take(1),
            finalize(() => {
                this.loadingPermissions = false;
            }),
            takeUntil(this.unsubscribe$)
        ).subscribe();
    }

}
