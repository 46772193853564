export default class FdxTimeZoneSelectController {
    static $inject = [
        '$timeout'
    ];

    // binding default
    timeZone = null;

    available_timezones = [
        {name:'(UTC-12:00) International Date Line West - Dateline Standard Time',offset:'-12:00'},
        {name:'(UTC-11:00) Coordinated Universal Time-11 - UTC-11',offset:'-11:00'},
        {name:'(UTC-10:00) Aleutian Islands - Aleutian Standard Time',offset:'-10:00'},
        {name:'(UTC-10:00) Hawaii - Hawaiian Standard Time',offset:'-10:00'},
        {name:'(UTC-09:00) Marquesas Islands - Marquesas Standard Time',offset:'-09:00'},
        {name:'(UTC-09:00) Alaska - Alaskan Standard Time',offset:'-09:00'},
        {name:'(UTC-09:00) Coordinated Universal Time-09 - UTC-09',offset:'-09:00'},
        {name:'(UTC-08:00) Baja California - Pacific Standard Time (Mexico)',offset:'-08:00'},
        {name:'(UTC-08:00) Coordinated Universal Time-08 - UTC-08',offset:'-08:00'},
        {name:'(UTC-08:00) Pacific Time (US & Canada) - Pacific Standard Time',offset:'-08:00'},
        {name:'(UTC-07:00) Arizona - US Mountain Standard Time',offset:'-07:00'},
        {name:'(UTC-07:00) Chihuahua, La Paz, Mazatlan - Mountain Standard Time (Mexico)',offset:'-07:00'},
        {name:'(UTC-07:00) Mountain Time (US & Canada) - Mountain Standard Time',offset:'-07:00'},
        {name:'(UTC-06:00) Central America - Central America Standard Time',offset:'-06:00'},
        {name:'(UTC-06:00) Central Time (US & Canada) - Central Standard Time',offset:'-06:00'},
        {name:'(UTC-06:00) Easter Island - Easter Island Standard Time',offset:'-06:00'},
        {name:'(UTC-06:00) Guadalajara, Mexico City, Monterrey - Central Standard Time (Mexico)',offset:'-06:00'},
        {name:'(UTC-06:00) Saskatchewan - Canada Central Standard Time',offset:'-06:00'},
        {name:'(UTC-05:00) Bogota, Lima, Quito, Rio Branco - SA Pacific Standard Time',offset:'-05:00'},
        {name:'(UTC-05:00) Chetumal - Eastern Standard Time (Mexico)',offset:'-05:00'},
        {name:'(UTC-05:00) Eastern Time (US & Canada) - Eastern Standard Time',offset:'-05:00'},
        {name:'(UTC-05:00) Haiti - Haiti Standard Time',offset:'-05:00'},
        {name:'(UTC-05:00) Havana - Cuba Standard Time',offset:'-05:00'},
        {name:'(UTC-05:00) Indiana (East) - US Eastern Standard Time',offset:'-05:00'},
        {name:'(UTC-05:00) Turks and Caicos - Turks And Caicos Standard Time',offset:'-05:00'},
        {name:'(UTC-04:00) Asuncion - Paraguay Standard Time',offset:'-04:00'},
        {name:'(UTC-04:00) Atlantic Time (Canada) - Atlantic Standard Time',offset:'-04:00'},
        {name:'(UTC-04:00) Caracas - Venezuela Standard Time',offset:'-04:00'},
        {name:'(UTC-04:00) Cuiaba - Central Brazilian Standard Time',offset:'-04:00'},
        {name:'(UTC-04:00) Georgetown, La Paz, Manaus, San Juan - SA Western Standard Time',offset:'-04:00'},
        {name:'(UTC-04:00) Santiago - Pacific SA Standard Time',offset:'-04:00'},
        {name:'(UTC-03:30) Newfoundland - Newfoundland Standard Time',offset:'-03:30'},
        {name:'(UTC-03:00) Araguaina - Tocantins Standard Time',offset:'-03:00'},
        {name:'(UTC-03:00) Brasilia - E. South America Standard Time',offset:'-03:00'},
        {name:'(UTC-03:00) Cayenne, Fortaleza - SA Eastern Standard Time',offset:'-03:00'},
        {name:'(UTC-03:00) City of Buenos Aires - Argentina Standard Time',offset:'-03:00'},
        {name:'(UTC-03:00) Greenland - Greenland Standard Time',offset:'-03:00'},
        {name:'(UTC-03:00) Montevideo - Montevideo Standard Time',offset:'-03:00'},
        {name:'(UTC-03:00) Punta Arenas - Magallanes Standard Time',offset:'-03:00'},
        {name:'(UTC-03:00) Saint Pierre and Miquelon - Saint Pierre Standard Time',offset:'-03:00'},
        {name:'(UTC-03:00) Salvador - Bahia Standard Time',offset:'-03:00'},
        {name:'(UTC-02:00) Coordinated Universal Time-02 - UTC-02',offset:'-02:00'},
        {name:'(UTC-01:00) Azores - Azores Standard Time',offset:'-01:00'},
        {name:'(UTC-01:00) Standard Time',offset:'-01:00'},
        {name:'(UTC-00:00) Coordinated Universal Time - UTC',offset:'00:00'},
        {name:'(UTC-00:00) Dublin, Edinburgh, Lisbon, London - GMT Standard Time',offset:'0'},
        {name:'(UTC-00:00) Monrovia, Reykjavik - Greenwich Standard Time',offset:'0'},
        {name:'(UTC-00:00) Sao Tome - Sao Tome Standard Time',offset:'0'},
        {name:'(UTC+01:00) Casablanca - Morocco Standard Time',offset:'+01:00'},
        {name:'(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna - W. Europe Standard Time',offset:'+01:00'},
        {name:'(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague - Central Europe Standard Time',offset:'+01:00'},
        {name:'(UTC+01:00) Brussels, Copenhagen, Madrid, Paris - Romance Standard Time',offset:'+01:00'},
        {name:'(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb - Central European Standard Time',offset:'+01:00'},
        {name:'(UTC+01:00) West Central Africa - W. Central Africa Standard Time',offset:'+01:00'},
        {name:'(UTC+02:00) Amman - Jordan Standard Time',offset:'+02:00'},
        {name:'(UTC+02:00) Athens, Bucharest - GTB Standard Time',offset:'+02:00'},
        {name:'(UTC+02:00) Beirut - Middle East Standard Time',offset:'+02:00'},
        {name:'(UTC+02:00) Cairo - Egypt Standard Time',offset:'+02:00'},
        {name:'(UTC+02:00) Chisinau - E. Europe Standard Time',offset:'+02:00'},
        {name:'(UTC+02:00) Damascus - Syria Standard Time',offset:'+02:00'},
        {name:'(UTC+02:00) Gaza, Hebron - West Bank Standard Time',offset:'+02:00'},
        {name:'(UTC+02:00) Harare, Pretoria - South Africa Standard Time',offset:'+02:00'},
        {name:'(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius - FLE Standard Time',offset:'+02:00'},
        {name:'(UTC+02:00) Jerusalem - Israel Standard Time',offset:'+02:00'},
        {name:'(UTC+02:00) Kaliningrad - Kaliningrad Standard Time',offset:'+02:00'},
        {name:'(UTC+02:00) Khartoum - Sudan Standard Time',offset:'+02:00'},
        {name:'(UTC+02:00) Tripoli - Libya Standard Time',offset:'+02:00'},
        {name:'(UTC+02:00) Windhoek - Namibia Standard Time',offset:'+02:00'},
        {name:'(UTC+03:00) Baghdad - Arabic Standard Time',offset:'+03:00'},
        {name:'(UTC+03:00) Istanbul - Turkey Standard Time',offset:'+03:00'},
        {name:'(UTC+03:00) Kuwait, Riyadh - Arab Standard Time',offset:'+03:00'},
        {name:'(UTC+03:00) Minsk - Belarus Standard Time',offset:'+03:00'},
        {name:'(UTC+03:00) Moscow, St. Petersburg - Russian Standard Time',offset:'+03:00'},
        {name:'(UTC+03:00) Nairobi - E. Africa Standard Time',offset:'+03:00'},
        {name:'(UTC+03:00) Tehran - Iran Standard Time',offset:'+03:00'},
        {name:'(UTC+04:00) Abu Dhabi, Muscat - Arabian Standard Time',offset:'+04:00'},
        {name:'(UTC+04:00) Astrakhan, Ulyanovsk - Astrakhan Standard Time',offset:'+04:00'},
        {name:'(UTC+04:00) Baku - Azerbaijan Standard Time',offset:'+04:00'},
        {name:'(UTC+04:00) Izhevsk, Samara - Russia Time Zone 3',offset:'+04:00'},
        {name:'(UTC+04:00) Port Louis - Mauritius Standard Time',offset:'+04:00'},
        {name:'(UTC+04:00) Saratov - Saratov Standard Time',offset:'+04:00'},
        {name:'(UTC+04:00) Tbilisi - Georgian Standard Time',offset:'+04:00'},
        {name:'(UTC+04:00) Volgograd - Volgograd Standard Time',offset:'+04:00'},
        {name:'(UTC+04:00) Yerevan - Caucasus Standard Time',offset:'+04:00'},
        {name:'(UTC+04:30) Kabul - Afghanistan Standard Time',offset:'+04:30'},
        {name:'(UTC+05:00) Ashgabat, Tashkent - West Asia Standard Time',offset:'+05:00'},
        {name:'(UTC+05:00) Ekaterinburg - Ekaterinburg Standard Time',offset:'+05:00'},
        {name:'(UTC+05:00) Islamabad, Karachi - Pakistan Standard Time',offset:'+05:00'},
        {name:'(UTC+05:00) Qyzylorda - Qyzylorda Standard Time',offset:'+05:00'},
        {name:'(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi - India Standard Time',offset:'+05:30'},
        {name:'(UTC+05:30) Sri Jayawardenepura - Sri Lanka Standard Time',offset:'+05:30'},
        {name:'(UTC+05:45) Kathmandu - Nepal Standard Time',offset:'+05:45'},
        {name:'(UTC+06:00) Astana - Central Asia Standard Time',offset:'+06:00'},
        {name:'(UTC+06:00) Dhaka - Bangladesh Standard Time',offset:'+06:00'},
        {name:'(UTC+06:00) Omsk - Omsk Standard Time',offset:'+06:00'},
        {name:'(UTC+06:30) Yangon (Rangoon) - Myanmar Standard Time',offset:'+06:30'},
        {name:'(UTC+07:00) Bangkok, Hanoi, Jakarta - SE Asia Standard Time',offset:'+07:00'},
        {name:'(UTC+07:00) Barnaul, Gorno-Altaysk - Altai Standard Time',offset:'+07:00'},
        {name:'(UTC+07:00) Hovd - W. Mongolia Standard Time',offset:'+07:00'},
        {name:'(UTC+07:00) Krasnoyarsk - North Asia Standard Time',offset:'+07:00'},
        {name:'(UTC+07:00) Novosibirsk - N. Central Asia Standard Time',offset:'+07:00'},
        {name:'(UTC+07:00) Tomsk - Tomsk Standard Time',offset:'+07:00'},
        {name:'(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi - China Standard Time',offset:'+08:00'},
        {name:'(UTC+08:00) Irkutsk - North Asia East Standard Time',offset:'+08:00'},
        {name:'(UTC+08:00) Kuala Lumpur, Singapore - Singapore Standard Time',offset:'+08:00'},
        {name:'(UTC+08:00) Perth - W. Australia Standard Time',offset:'+08:00'},
        {name:'(UTC+08:00) Taipei - Taipei Standard Time',offset:'+08:00'},
        {name:'(UTC+08:00) Ulaanbaatar - Ulaanbaatar Standard Time',offset:'+08:00'},
        {name:'(UTC+08:45) Eucla - Aus Central W. Standard Time',offset:'+08:45'},
        {name:'(UTC+09:00) Chita - Transbaikal Standard Time',offset:'+09:00'},
        {name:'(UTC+09:00) Osaka, Sapporo, Tokyo - Tokyo Standard Time',offset:'+09:00'},
        {name:'(UTC+09:00) Pyongyang - North Korea Standard Time',offset:'+09:00'},
        {name:'(UTC+09:00) Seoul - Korea Standard Time',offset:'+09:00'},
        {name:'(UTC+09:00) Yakutsk - Yakutsk Standard Time',offset:'+09:00'},
        {name:'(UTC+09:30) Adelaide - Cen. Australia Standard Time',offset:'+09:30'},
        {name:'(UTC+09:30) Darwin - AUS Central Standard Time',offset:'+09:30'},
        {name:'(UTC+10:00) Brisbane - E. Australia Standard Time',offset:'+10:00'},
        {name:'(UTC+10:00) Canberra, Melbourne, Sydney - AUS Eastern Standard Time',offset:'+10:00'},
        {name:'(UTC+10:00) Guam, Port Moresby - West Pacific Standard Time',offset:'+10:00'},
        {name:'(UTC+10:00) Hobart - Tasmania Standard Time',offset:'+10:00'},
        {name:'(UTC+10:00) Vladivostok - Vladivostok Standard Time',offset:'+10:00'},
        {name:'(UTC+10:30) Lord Howe Island - Lord Howe Standard Time',offset:'+10:30'},
        {name:'(UTC+11:00) Bougainville Island - Bougainville Standard Time',offset:'+11:00'},
        {name:'(UTC+11:00) Chokurdakh - Russia Time Zone 10',offset:'+11:00'},
        {name:'(UTC+11:00) Magadan - Magadan Standard Time',offset:'+11:00'},
        {name:'(UTC+11:00) Norfolk Island - Norfolk Standard Time',offset:'+11:00'},
        {name:'(UTC+11:00) Sakhalin - Sakhalin Standard Time',offset:'+11:00'},
        {name:'(UTC+11:00) Solomon Is., New Caledonia - Central Pacific Standard Time',offset:'+11:00'},
        {name:'(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky - Russia Time Zone 11',offset:'+12:00'},
        {name:'(UTC+12:00) Auckland, Wellington - New Zealand Standard Time',offset:'+12:00'},
        {name:'(UTC+12:00) Coordinated Universal Time+12 - UTC+12',offset:'+12:00'},
        {name:'(UTC+12:00) Fiji - Fiji Standard Time',offset:'+12:00'},
        {name:'(UTC+12:00) Chatham Islands - Chatham Islands Standard Time',offset:'+12:00'},
        {name:'(UTC+12:00) Coordinated Universal Time+13 - UTC+13',offset:'+12:00'},
        {name:'(UTC+13:00) Nuku’alofa - Tonga Standard Time',offset:'+13:00'},
        {name:'(UTC+13:00) Samoa - Samoa Standard Time',offset:'+13:00'},
        {name:'(UTC+14:00) Kiritimati Island - Line Islands Standard Time',offset:'+14:00'}
    ];
}