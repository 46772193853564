<div [formGroup]="transformerForm" *ngIf="queryInputs?.basicQueryData; else loading">
    <fdx-advanced-query
        [queryForm]="advancedTransformerForm"
        [controlName]="advancedTransformerControlName"
        [(queryString)]="transformerString"
        (queryStringChange)="transformerStringChanged()"
        [disabled]="disabled"
        [dbFields]="dbFields"
        [codeMirrorOptions]="codeMirrorOptions"
        *ngIf="queryInputs.basicQueryData.queryType === queryType.Advanced" > <!-- Doesn't play nicely while hidden -->
    </fdx-advanced-query>

    <fdx-basic-transformer
        [transformerForm]="basicTransformerForm"
        [transformerString]="transformerString"
        [disabled]="disabled"
        [dbFields]="dbFields"
        [basicQueryData]="queryInputs.basicQueryData"
        [hidden]="queryInputs.basicQueryData.queryType === queryType.Advanced" > <!-- Need reference to component even when not shown -->
    </fdx-basic-transformer>
</div>
<ng-template #loading>
    <fdx-spinner size="md"></fdx-spinner>
</ng-template>
