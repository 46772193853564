<div class="card" [ngClass]="{'mb-3': collapsed}">
    <div class="d-flex align-items-center justify-content-between gap-2 py-3" [ngClass]="{'card-header': !collapsed, 'card-body': collapsed}">
        <fdx-search-bar
            class="w-100"
            placeholder="Keyword search"
            [delay]="400"
            [value]="query"
            (valueChange)="onSearchChange($event)">
        </fdx-search-bar>

        <button
            type="button"
            class="btn btn-outline-secondary"
            [ngClass]="{'btn-toggled': !collapsed, 'btn-flagged': filtersActive}"
            (click)="filtersCollapse.toggle()"
            [attr.aria-expanded]="!collapsed"
            aria-controls="filtersCollapse">
            <fa-icon [icon]="filterIcon" class="no-margin"></fa-icon>
        </button>
    </div>

    <form
        [formGroup]="formGroup"
        (ngSubmit)="formGroup?.valid && applyFilters()"
        #filtersCollapse="ngbCollapse"
        [(ngbCollapse)]="collapsed"
        id="filtersCollapse">
        <div class="card-body">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-4">
                    <label class="col-form-label pt-0" for="date-range">Date range ({{timeZoneCode}})</label>
                    <fsc-daterange-picker
                        formControlName="dateRange"
                        [useShadowDom]="true"
                        shadowRootHost="fdx-style-wrapper"
                        inputId="date-range"
                        inputName="dateRange"
                        inputPlaceholder="mm/dd/yyyy - mm/dd/yyyy"
                        [minDate]="minDate"
                        [maxDate]="maxDate"
                        [clearable]="false">
                    </fsc-daterange-picker>
                </div>
                <ng-content></ng-content>
            </div>
        </div>
        <div class="card-footer d-flex gap-2 justify-content-end">
            <div *ngIf="showWarning" class="align-self-center pe-3">
                <div class="text-warning"><fa-icon [icon]="warningIcon" class="me-2"></fa-icon>The filters shown above have not yet been applied. Click the Apply filters button to apply them to the data table below.</div>
            </div>
            <button type="submit" class="btn btn-primary" [disabled]="!formGroup?.valid">Apply filters</button>
            <button type="button" class="btn btn-link text-secondary" (click)="clearAllFilters()">Clear filters</button>
        </div>
    </form>
</div>

<fdx-filter-chips
    [filterChips]="filterChips"
    (clearFilterClick)="clearFilter($event)"
    (clearAllFiltersClick)="clearAllFilters()"
    [filtersCollapsed]="collapsed"
    [truncateLength]="35">
</fdx-filter-chips>
