import { UserFeatureFlag } from '@app/core/models/enums/user-feature-flag.enum';
import { PrivacyLevel } from '@app/user/enums/privacy-level.enum';
import { Grant, UserDataModel } from '@app/user/models/user-data.model';
import { UserSettings } from '@app/user/models/user-settings.model';
import FdxUtils from '../../services/fdxUtils';

export class UserModel implements UserDataModel {
    account_grants: Record<string, Record<Grant, boolean>> = null;
    account_privacy_levels: Record<string, PrivacyLevel> = null;
    country = '';
    features = null;
    first_name = '';
    last_name: '';
    logo: '';
    last_password_reset = '';
    phone = '';
    roles = [];
    settings: UserSettings = null;
    user_fetched = false;
    user_id = '';
    user_name = '';
    zoho_contact_id = '';
    impersonated_user_name = '';
    impersonated_user_id = '';

    constructor(data: UserDataModel) {
        for (const [key, val] of Object.entries(data)) {
            this[key] = val;
        }
    }

    // TODO more specific type definition for setting param
    getSetting(setting: string): any {
        if (!this.settings) {
            return '';
        }

        return this.settings[setting];
    }

    getSettings(): UserSettings {
        if(!this.settings) {
            return {};
        }

        return this.settings;
    }

    updateSettings(settings: UserSettings): void {
        this.settings = settings;
    }

    /**
     * Checks the features array for a particular feature value
     * @param feature the name of the feature we're looking for
     * @param value The value to check against for the feature; usually "enabled", but could be something else.
     * Call the function without this value to check if the feature value is set at all.
     * @returns whether the feature exists, and if the "value" variable is set, checks if the value matches the one set for the feature
     */
    hasFeature(feature: string, value: string = undefined): boolean {
        if (!this.features) {
            return false;
        }

        if (value === undefined) {
            return Boolean(this.features[feature]);
        }

        return this.features[feature] === value;
    }

    hasFeatureEnabled(feature: UserFeatureFlag): boolean {
        return this.hasFeature(feature, 'enabled');
    }

    /**
     * Determines if a specific grant is present for a given account or globally.
     *
     * This function checks grants with a specific naming convention.
     * Good examples of grant names:
     * - PlatformAdmin:UserOps.ReadOnly.UserRole
     * - PlatformAdmin:UserOps.UserRole
     *
     * A bad example of a grant name:
     * - PlatformAdmin:UserOps.UserRole.ReadOnly
     * This is because you cannot block just the "readonly" part.
     *
     * @param {Grant} grant      - The specific grant to check.
     * @param {string} accountId - The account ID to check the grant for. Defaults to 'ALL'.
     *
     * @returns {boolean} True if the grant is found, otherwise false.
     */
    hasGrant(grant: Grant, accountId: string  = 'ALL'): boolean {
        if (!this.account_grants || Object.keys(this.account_grants).length === 0) {
            return false;
        }
        if (this.account_grants['ALL'][grant]) {
            return true;
        }
        const allRes = Object.keys(this.account_grants['ALL']).filter(v => v.startsWith(grant));
        if (allRes.length) {
            return true;
        }
        if (accountId !== 'All' && this.account_grants[accountId]) {
            if (this.account_grants[accountId][grant]) {
                return true;
            }
            const accountRes = Object.keys(this.account_grants[accountId]).filter(v => v.startsWith(grant));
            if (accountRes.length) {
                return true;
            }
        }
        return false;
    }

    hasMinimumPrivacyLevel(privacyLevel: PrivacyLevel, accountId: string = 'ALL'): boolean {
        return this.isMinPrivacyLevel(this.getPrivacyLevel(accountId), privacyLevel);
    }

    private isMinPrivacyLevel(privacyLevel: PrivacyLevel, minPrivacyLevel: PrivacyLevel): boolean {
        const privacyLevelHierarchy = [PrivacyLevel.Standard, PrivacyLevel.Analyst, PrivacyLevel.Admin];
        const privacyLevelIndex = privacyLevelHierarchy.indexOf(privacyLevel);
        const minPrivacyLevelIndex = privacyLevelHierarchy.indexOf(minPrivacyLevel);

        return privacyLevelIndex >= minPrivacyLevelIndex;
    }

    getPrivacyLevel(accountId: string): PrivacyLevel {
        if (this.getSetting('user.demo_client_view')) {
            return PrivacyLevel.Standard;
        }
        if (this.account_privacy_levels[accountId]) {
            return this.account_privacy_levels[accountId];
        }
        return this.account_privacy_levels['ALL'];
    }

    isInternal(): boolean {
        const fdxUtils = new FdxUtils();
        return fdxUtils.isInternalUser(this);
    }
}
