<ng-template #schedulePopoverTitle>
    <fdx-popover-title theme="primary" titleText="Compare time zones">
    </fdx-popover-title>
</ng-template>

<ng-template #schedulePopoverContent>
    <fdx-cron-viewer
        [cron]="cron"
        [cronTimezone]="cronTimezone"
        [displaySettingsTimeZone]="true">
    </fdx-cron-viewer>
</ng-template>

<div class="small text-muted">
    <fa-icon [icon]="faClockIcon"></fa-icon>
    <span role="button"
          [ngbPopover]="schedulePopoverContent"
          placement="bottom auto"
          [popoverTitle]="schedulePopoverTitle"
    > View local time</span>
</div>
