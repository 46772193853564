export default class FileMapService {
    private unicode = {
        "00": 0x0000, // <control>
        "01": 0x0001, // <control>
        "02": 0x0002, // <control>
        "03": 0x0003, // <control>
        "04": 0x0004, // <control>
        "05": 0x0005, // <control>
        "06": 0x0006, // <control>
        "07": 0x0007, // <control>
        "08": 0x0008, // <control>
        "09": 0x0009, // <control>
        "0A": 0x000A, // <control>
        "0B": 0x000B, // <control>
        "0C": 0x000C, // <control>
        "0D": 0x000D, // <control>
        "0E": 0x000E, // <control>
        "0F": 0x000F, // <control>
        "10": 0x0010, // <control>
        "11": 0x0011, // <control>
        "12": 0x0012, // <control>
        "13": 0x0013, // <control>
        "14": 0x0014, // <control>
        "15": 0x0015, // <control>
        "16": 0x0016, // <control>
        "17": 0x0017, // <control>
        "18": 0x0018, // <control>
        "19": 0x0019, // <control>
        "1A": 0x001A, // <control>
        "1B": 0x001B, // <control>
        "1C": 0x001C, // <control>
        "1D": 0x001D, // <control>
        "1E": 0x001E, // <control>
        "1F": 0x001F, // <control>
        "20": 0x0020, // SPACE
        "21": 0x0021, // EXCLAMATION MARK
        "22": 0x0022, // QUOTATION MARK
        "23": 0x0023, // NUMBER SIGN
        "24": 0x0024, // DOLLAR SIGN
        "25": 0x0025, // PERCENT SIGN
        "26": 0x0026, // AMPERSAND
        "27": 0x0027, // APOSTROPHE
        "28": 0x0028, // LEFT PARENTHESIS
        "29": 0x0029, // RIGHT PARENTHESIS
        "2A": 0x002A, // ASTERISK
        "2B": 0x002B, // PLUS SIGN
        "2C": 0x002C, // COMMA
        "2D": 0x002D, // HYPHEN-MINUS
        "2E": 0x002E, // FULL STOP
        "2F": 0x002F, // SOLIDUS
        "30": 0x0030, // DIGIT ZERO
        "31": 0x0031, // DIGIT ONE
        "32": 0x0032, // DIGIT TWO
        "33": 0x0033, // DIGIT THREE
        "34": 0x0034, // DIGIT FOUR
        "35": 0x0035, // DIGIT FIVE
        "36": 0x0036, // DIGIT SIX
        "37": 0x0037, // DIGIT SEVEN
        "38": 0x0038, // DIGIT EIGHT
        "39": 0x0039, // DIGIT NINE
        "3A": 0x003A, // COLON
        "3B": 0x003B, // SEMICOLON
        "3C": 0x003C, // LESS-THAN SIGN
        "3D": 0x003D, // EQUALS SIGN
        "3E": 0x003E, // GREATER-THAN SIGN
        "3F": 0x003F, // QUESTION MARK
        "40": 0x0040, // COMMERCIAL AT
        "41": 0x0041, // LATIN CAPITAL LETTER A
        "42": 0x0042, // LATIN CAPITAL LETTER B
        "43": 0x0043, // LATIN CAPITAL LETTER C
        "44": 0x0044, // LATIN CAPITAL LETTER D
        "45": 0x0045, // LATIN CAPITAL LETTER E
        "46": 0x0046, // LATIN CAPITAL LETTER F
        "47": 0x0047, // LATIN CAPITAL LETTER G
        "48": 0x0048, // LATIN CAPITAL LETTER H
        "49": 0x0049, // LATIN CAPITAL LETTER I
        "4A": 0x004A, // LATIN CAPITAL LETTER J
        "4B": 0x004B, // LATIN CAPITAL LETTER K
        "4C": 0x004C, // LATIN CAPITAL LETTER L
        "4D": 0x004D, // LATIN CAPITAL LETTER M
        "4E": 0x004E, // LATIN CAPITAL LETTER N
        "4F": 0x004F, // LATIN CAPITAL LETTER O
        "50": 0x0050, // LATIN CAPITAL LETTER P
        "51": 0x0051, // LATIN CAPITAL LETTER Q
        "52": 0x0052, // LATIN CAPITAL LETTER R
        "53": 0x0053, // LATIN CAPITAL LETTER S
        "54": 0x0054, // LATIN CAPITAL LETTER T
        "55": 0x0055, // LATIN CAPITAL LETTER U
        "56": 0x0056, // LATIN CAPITAL LETTER V
        "57": 0x0057, // LATIN CAPITAL LETTER W
        "58": 0x0058, // LATIN CAPITAL LETTER X
        "59": 0x0059, // LATIN CAPITAL LETTER Y
        "5A": 0x005A, // LATIN CAPITAL LETTER Z
        "5B": 0x005B, // LEFT SQUARE BRACKET
        "5C": 0x00A5, // YEN SIGN
        "5D": 0x005D, // RIGHT SQUARE BRACKET
        "5E": 0x005E, // CIRCUMFLEX ACCENT
        "5F": 0x005F, // LOW LINE
        "60": 0x0060, // GRAVE ACCENT
        "61": 0x0061, // LATIN SMALL LETTER A
        "62": 0x0062, // LATIN SMALL LETTER B
        "63": 0x0063, // LATIN SMALL LETTER C
        "64": 0x0064, // LATIN SMALL LETTER D
        "65": 0x0065, // LATIN SMALL LETTER E
        "66": 0x0066, // LATIN SMALL LETTER F
        "67": 0x0067, // LATIN SMALL LETTER G
        "68": 0x0068, // LATIN SMALL LETTER H
        "69": 0x0069, // LATIN SMALL LETTER I
        "6A": 0x006A, // LATIN SMALL LETTER J
        "6B": 0x006B, // LATIN SMALL LETTER K
        "6C": 0x006C, // LATIN SMALL LETTER L
        "6D": 0x006D, // LATIN SMALL LETTER M
        "6E": 0x006E, // LATIN SMALL LETTER N
        "6F": 0x006F, // LATIN SMALL LETTER O
        "70": 0x0070, // LATIN SMALL LETTER P
        "71": 0x0071, // LATIN SMALL LETTER Q
        "72": 0x0072, // LATIN SMALL LETTER R
        "73": 0x0073, // LATIN SMALL LETTER S
        "74": 0x0074, // LATIN SMALL LETTER T
        "75": 0x0075, // LATIN SMALL LETTER U
        "76": 0x0076, // LATIN SMALL LETTER V
        "77": 0x0077, // LATIN SMALL LETTER W
        "78": 0x0078, // LATIN SMALL LETTER X
        "79": 0x0079, // LATIN SMALL LETTER Y
        "7A": 0x007A, // LATIN SMALL LETTER Z
        "7B": 0x007B, // LEFT CURLY BRACKET
        "7C": 0x007C, // VERTICAL LINE
        "7D": 0x007D, // RIGHT CURLY BRACKET
        "7E": 0x203E, // OVERLINE
        "7F": 0x007F, // <control>
        "A1": 0xFF61, // HALFWIDTH IDEOGRAPHIC FULL STOP
        "A2": 0xFF62, // HALFWIDTH LEFT CORNER BRACKET
        "A3": 0xFF63, // HALFWIDTH RIGHT CORNER BRACKET
        "A4": 0xFF64, // HALFWIDTH IDEOGRAPHIC COMMA
        "A5": 0xFF65, // HALFWIDTH KATAKANA MIDDLE DOT
        "A6": 0xFF66, // HALFWIDTH KATAKANA LETTER WO
        "A7": 0xFF67, // HALFWIDTH KATAKANA LETTER SMALL A
        "A8": 0xFF68, // HALFWIDTH KATAKANA LETTER SMALL I
        "A9": 0xFF69, // HALFWIDTH KATAKANA LETTER SMALL U
        "AA": 0xFF6A, // HALFWIDTH KATAKANA LETTER SMALL E
        "AB": 0xFF6B, // HALFWIDTH KATAKANA LETTER SMALL O
        "AC": 0xFF6C, // HALFWIDTH KATAKANA LETTER SMALL YA
        "AD": 0xFF6D, // HALFWIDTH KATAKANA LETTER SMALL YU
        "AE": 0xFF6E, // HALFWIDTH KATAKANA LETTER SMALL YO
        "AF": 0xFF6F, // HALFWIDTH KATAKANA LETTER SMALL TU
        "B0": 0xFF70, // HALFWIDTH KATAKANA-HIRAGANA PROLONGED SOUND MARK
        "B1": 0xFF71, // HALFWIDTH KATAKANA LETTER A
        "B2": 0xFF72, // HALFWIDTH KATAKANA LETTER I
        "B3": 0xFF73, // HALFWIDTH KATAKANA LETTER U
        "B4": 0xFF74, // HALFWIDTH KATAKANA LETTER E
        "B5": 0xFF75, // HALFWIDTH KATAKANA LETTER O
        "B6": 0xFF76, // HALFWIDTH KATAKANA LETTER KA
        "B7": 0xFF77, // HALFWIDTH KATAKANA LETTER KI
        "B8": 0xFF78, // HALFWIDTH KATAKANA LETTER KU
        "B9": 0xFF79, // HALFWIDTH KATAKANA LETTER KE
        "BA": 0xFF7A, // HALFWIDTH KATAKANA LETTER KO
        "BB": 0xFF7B, // HALFWIDTH KATAKANA LETTER SA
        "BC": 0xFF7C, // HALFWIDTH KATAKANA LETTER SI
        "BD": 0xFF7D, // HALFWIDTH KATAKANA LETTER SU
        "BE": 0xFF7E, // HALFWIDTH KATAKANA LETTER SE
        "BF": 0xFF7F, // HALFWIDTH KATAKANA LETTER SO
        "C0": 0xFF80, // HALFWIDTH KATAKANA LETTER TA
        "C1": 0xFF81, // HALFWIDTH KATAKANA LETTER TI
        "C2": 0xFF82, // HALFWIDTH KATAKANA LETTER TU
        "C3": 0xFF83, // HALFWIDTH KATAKANA LETTER TE
        "C4": 0xFF84, // HALFWIDTH KATAKANA LETTER TO
        "C5": 0xFF85, // HALFWIDTH KATAKANA LETTER NA
        "C6": 0xFF86, // HALFWIDTH KATAKANA LETTER NI
        "C7": 0xFF87, // HALFWIDTH KATAKANA LETTER NU
        "C8": 0xFF88, // HALFWIDTH KATAKANA LETTER NE
        "C9": 0xFF89, // HALFWIDTH KATAKANA LETTER NO
        "CA": 0xFF8A, // HALFWIDTH KATAKANA LETTER HA
        "CB": 0xFF8B, // HALFWIDTH KATAKANA LETTER HI
        "CC": 0xFF8C, // HALFWIDTH KATAKANA LETTER HU
        "CD": 0xFF8D, // HALFWIDTH KATAKANA LETTER HE
        "CE": 0xFF8E, // HALFWIDTH KATAKANA LETTER HO
        "CF": 0xFF8F, // HALFWIDTH KATAKANA LETTER MA
        "D0": 0xFF90, // HALFWIDTH KATAKANA LETTER MI
        "D1": 0xFF91, // HALFWIDTH KATAKANA LETTER MU
        "D2": 0xFF92, // HALFWIDTH KATAKANA LETTER ME
        "D3": 0xFF93, // HALFWIDTH KATAKANA LETTER MO
        "D4": 0xFF94, // HALFWIDTH KATAKANA LETTER YA
        "D5": 0xFF95, // HALFWIDTH KATAKANA LETTER YU
        "D6": 0xFF96, // HALFWIDTH KATAKANA LETTER YO
        "D7": 0xFF97, // HALFWIDTH KATAKANA LETTER RA
        "D8": 0xFF98, // HALFWIDTH KATAKANA LETTER RI
        "D9": 0xFF99, // HALFWIDTH KATAKANA LETTER RU
        "DA": 0xFF9A, // HALFWIDTH KATAKANA LETTER RE
        "DB": 0xFF9B, // HALFWIDTH KATAKANA LETTER RO
        "DC": 0xFF9C, // HALFWIDTH KATAKANA LETTER WA
        "DD": 0xFF9D, // HALFWIDTH KATAKANA LETTER N
        "DE": 0xFF9E, // HALFWIDTH KATAKANA VOICED SOUND MARK 
        "DF": 0xFF9F, // HALFWIDTH KATAKANA SEMI-VOICED SOUND MARK 
        "8140": 0x3000, // IDEOGRAPHIC SPACE
        "8141": 0x3001, // IDEOGRAPHIC COMMA
        "8142": 0x3002, // IDEOGRAPHIC FULL STOP
        "8143": 0xFF0C, // FULLWIDTH COMMA
        "8144": 0xFF0E, // FULLWIDTH FULL STOP
        "8145": 0x30FB, // KATAKANA MIDDLE DOT
        "8146": 0xFF1A, // FULLWIDTH COLON
        "8147": 0xFF1B, // FULLWIDTH SEMICOLON
        "8148": 0xFF1F, // FULLWIDTH QUESTION MARK
        "8149": 0xFF01, // FULLWIDTH EXCLAMATION MARK
        "814A": 0x309B, // KATAKANA-HIRAGANA VOICED SOUND MARK
        "814B": 0x309C, // KATAKANA-HIRAGANA SEMI-VOICED SOUND MARK
        "814C": 0x00B4, // ACUTE ACCENT
        "814D": 0xFF40, // FULLWIDTH GRAVE ACCENT
        "814E": 0x00A8, // DIAERESIS
        "814F": 0xFF3E, // FULLWIDTH CIRCUMFLEX ACCENT
        "8150": 0xFFE3, // FULLWIDTH MACRON 
        "8151": 0xFF3F, // FULLWIDTH LOW LINE
        "8152": 0x30FD, // KATAKANA ITERATION MARK
        "8153": 0x30FE, // KATAKANA VOICED ITERATION MARK
        "8154": 0x309D, // HIRAGANA ITERATION MARK
        "8155": 0x309E, // HIRAGANA VOICED ITERATION MARK
        "8156": 0x3003, // DITTO MARK
        "8157": 0x4EDD, // <cjk>
        "8158": 0x3005, // IDEOGRAPHIC ITERATION MARK
        "8159": 0x3006, // IDEOGRAPHIC CLOSING MARK
        "815A": 0x3007, // IDEOGRAPHIC NUMBER ZERO
        "815C": 0x2014, // EM DASH	Windows: U+2015
        "815D": 0x2010, // HYPHEN
        "815E": 0xFF0F, // FULLWIDTH SOLIDUS
        "815F": 0x005C, // REVERSE SOLIDUS	Fullwidth: U+FF3C
        "8160": 0x301C, // WAVE DASH	Windows: U+FF5E
        "8161": 0x2016, // DOUBLE VERTICAL LINE	Windows: U+2225
        "8162": 0xFF5C, // FULLWIDTH VERTICAL LINE
        "8163": 0x2026, // HORIZONTAL ELLIPSIS
        "8164": 0x2025, // TWO DOT LEADER
        "8165": 0x2018, // LEFT SINGLE QUOTATION MARK
        "8166": 0x2019, // RIGHT SINGLE QUOTATION MARK
        "8167": 0x201C, // LEFT DOUBLE QUOTATION MARK
        "8168": 0x201D, // RIGHT DOUBLE QUOTATION MARK
        "8169": 0xFF08, // FULLWIDTH LEFT PARENTHESIS
        "816A": 0xFF09, // FULLWIDTH RIGHT PARENTHESIS
        "816B": 0x3014, // LEFT TORTOISE SHELL BRACKET
        "816C": 0x3015, // RIGHT TORTOISE SHELL BRACKET
        "816D": 0xFF3B, // FULLWIDTH LEFT SQUARE BRACKET
        "816E": 0xFF3D, // FULLWIDTH RIGHT SQUARE BRACKET
        "816F": 0xFF5B, // FULLWIDTH LEFT CURLY BRACKET
        "8170": 0xFF5D, // FULLWIDTH RIGHT CURLY BRACKET
        "8171": 0x3008, // LEFT ANGLE BRACKET
        "8172": 0x3009, // RIGHT ANGLE BRACKET
        "8173": 0x300A, // LEFT DOUBLE ANGLE BRACKET
        "8174": 0x300B, // RIGHT DOUBLE ANGLE BRACKET
        "8175": 0x300C, // LEFT CORNER BRACKET
        "8176": 0x300D, // RIGHT CORNER BRACKET
        "8177": 0x300E, // LEFT WHITE CORNER BRACKET
        "8178": 0x300F, // RIGHT WHITE CORNER BRACKET
        "8179": 0x3010, // LEFT BLACK LENTICULAR BRACKET
        "817A": 0x3011, // RIGHT BLACK LENTICULAR BRACKET
        "817B": 0xFF0B, // FULLWIDTH PLUS SIGN
        "817C": 0x2212, // MINUS SIGN	Windows: U+FF0D
        "817D": 0x00B1, // PLUS-MINUS SIGN
        "817E": 0x00D7, // MULTIPLICATION SIGN
        "8180": 0x00F7, // DIVISION SIGN
        "8181": 0xFF1D, // FULLWIDTH EQUALS SIGN
        "8182": 0x2260, // NOT EQUAL TO
        "8183": 0xFF1C, // FULLWIDTH LESS-THAN SIGN
        "8184": 0xFF1E, // FULLWIDTH GREATER-THAN SIGN
        "8185": 0x2266, // LESS-THAN OVER EQUAL TO
        "8186": 0x2267, // GREATER-THAN OVER EQUAL TO
        "8187": 0x221E, // INFINITY
        "8188": 0x2234, // THEREFORE
        "8189": 0x2642, // MALE SIGN
        "818A": 0x2640, // FEMALE SIGN
        "818B": 0x00B0, // DEGREE SIGN
        "818C": 0x2032, // PRIME
        "818D": 0x2033, // DOUBLE PRIME
        "818E": 0x2103, // DEGREE CELSIUS
        "818F": 0xFFE5, // FULLWIDTH YEN SIGN
        "8190": 0xFF04, // FULLWIDTH DOLLAR SIGN
        "8191": 0x00A2, // CENT SIGN	Windows: U+FFE0
        "8192": 0x00A3, // POUND SIGN	Windows: U+FFE1
        "8193": 0xFF05, // FULLWIDTH PERCENT SIGN
        "8194": 0xFF03, // FULLWIDTH NUMBER SIGN
        "8195": 0xFF06, // FULLWIDTH AMPERSAND
        "8196": 0xFF0A, // FULLWIDTH ASTERISK
        "8197": 0xFF20, // FULLWIDTH COMMERCIAL AT
        "8198": 0x00A7, // SECTION SIGN
        "8199": 0x2606, // WHITE STAR
        "819A": 0x2605, // BLACK STAR
        "819B": 0x25CB, // WHITE CIRCLE
        "819C": 0x25CF, // BLACK CIRCLE
        "819D": 0x25CE, // BULLSEYE
        "819E": 0x25C7, // WHITE DIAMOND
        "819F": 0x25C6, // BLACK DIAMOND
        "81A0": 0x25A1, // WHITE SQUARE
        "81A1": 0x25A0, // BLACK SQUARE
        "81A2": 0x25B3, // WHITE UP-POINTING TRIANGLE
        "81A3": 0x25B2, // BLACK UP-POINTING TRIANGLE
        "81A4": 0x25BD, // WHITE DOWN-POINTING TRIANGLE
        "81A5": 0x25BC, // BLACK DOWN-POINTING TRIANGLE
        "81A6": 0x203B, // REFERENCE MARK
        "81A7": 0x3012, // POSTAL MARK
        "81A8": 0x2192, // RIGHTWARDS ARROW
        "81A9": 0x2190, // LEFTWARDS ARROW
        "81AA": 0x2191, // UPWARDS ARROW
        "81AB": 0x2193, // DOWNWARDS ARROW
        "81AC": 0x3013, // GETA MARK
        "81AD": 0xFF07, // FULLWIDTH APOSTROPHE
        "81B8": 0x2208, // ELEMENT OF	[1983]
        "81B9": 0x220B, // CONTAINS AS MEMBER	[1983]
        "81BA": 0x2286, // SUBSET OF OR EQUAL TO	[1983]
        "81BB": 0x2287, // SUPERSET OF OR EQUAL TO	[1983]
        "81BC": 0x2282, // SUBSET OF	[1983]
        "81BD": 0x2283, // SUPERSET OF	[1983]
        "81BE": 0x222A, // UNION	[1983]
        "81BF": 0x2229, // INTERSECTION	[1983]
        "81C8": 0x2227, // LOGICAL AND	[1983]
        "81C9": 0x2228, // LOGICAL OR	[1983]
        "81CA": 0x00AC, // NOT SIGN	[1983]	Windows: U+FFE2
        "81CB": 0x21D2, // RIGHTWARDS DOUBLE ARROW	[1983]
        "81CC": 0x21D4, // LEFT RIGHT DOUBLE ARROW	[1983]
        "81CD": 0x2200, // FOR ALL	[1983]
        "81CE": 0x2203, // THERE EXISTS	[1983]
        "81DA": 0x2220, // ANGLE	[1983]
        "81DB": 0x22A5, // UP TACK	[1983]
        "81DC": 0x2312, // ARC	[1983]
        "81DD": 0x2202, // PARTIAL DIFFERENTIAL	[1983]
        "81DE": 0x2207, // NABLA	[1983]
        "81DF": 0x2261, // IDENTICAL TO	[1983]
        "81E0": 0x2252, // APPROXIMATELY EQUAL TO OR THE IMAGE OF	[1983]
        "81E1": 0x226A, // MUCH LESS-THAN	[1983]
        "81E2": 0x226B, // MUCH GREATER-THAN	[1983]
        "81E3": 0x221A, // SQUARE ROOT	[1983]
        "81E4": 0x223D, // REVERSED TILDE 	[1983]
        "81E5": 0x221D, // PROPORTIONAL TO	[1983]
        "81E6": 0x2235, // BECAUSE	[1983]
        "81E7": 0x222B, // INTEGRAL	[1983]
        "81E8": 0x222C, // DOUBLE INTEGRAL	[1983]
        "81F0": 0x212B, // ANGSTROM SIGN	[1983]
        "81F1": 0x2030, // PER MILLE SIGN	[1983]
        "81F2": 0x266F, // MUSIC SHARP SIGN	[1983]
        "81F3": 0x266D, // MUSIC FLAT SIGN	[1983]
        "81F4": 0x266A, // EIGHTH NOTE	[1983]
        "81F5": 0x2020, // DAGGER	[1983]
        "81F6": 0x2021, // DOUBLE DAGGER	[1983]
        "81F7": 0x00B6, // PILCROW SIGN	[1983]
        "81FC": 0x25EF, // LARGE CIRCLE	[1983]
        "824F": 0xFF10, // FULLWIDTH DIGIT ZERO
        "8250": 0xFF11, // FULLWIDTH DIGIT ONE
        "8251": 0xFF12, // FULLWIDTH DIGIT TWO
        "8252": 0xFF13, // FULLWIDTH DIGIT THREE
        "8253": 0xFF14, // FULLWIDTH DIGIT FOUR
        "8254": 0xFF15, // FULLWIDTH DIGIT FIVE
        "8255": 0xFF16, // FULLWIDTH DIGIT SIX
        "8256": 0xFF17, // FULLWIDTH DIGIT SEVEN
        "8257": 0xFF18, // FULLWIDTH DIGIT EIGHT
        "8258": 0xFF19, // FULLWIDTH DIGIT NINE
        "8260": 0xFF21, // FULLWIDTH LATIN CAPITAL LETTER A
        "8261": 0xFF22, // FULLWIDTH LATIN CAPITAL LETTER B
        "8262": 0xFF23, // FULLWIDTH LATIN CAPITAL LETTER C
        "8263": 0xFF24, // FULLWIDTH LATIN CAPITAL LETTER D
        "8264": 0xFF25, // FULLWIDTH LATIN CAPITAL LETTER E
        "8265": 0xFF26, // FULLWIDTH LATIN CAPITAL LETTER F
        "8266": 0xFF27, // FULLWIDTH LATIN CAPITAL LETTER G
        "8267": 0xFF28, // FULLWIDTH LATIN CAPITAL LETTER H
        "8268": 0xFF29, // FULLWIDTH LATIN CAPITAL LETTER I
        "8269": 0xFF2A, // FULLWIDTH LATIN CAPITAL LETTER J
        "826A": 0xFF2B, // FULLWIDTH LATIN CAPITAL LETTER K
        "826B": 0xFF2C, // FULLWIDTH LATIN CAPITAL LETTER L
        "826C": 0xFF2D, // FULLWIDTH LATIN CAPITAL LETTER M
        "826D": 0xFF2E, // FULLWIDTH LATIN CAPITAL LETTER N
        "826E": 0xFF2F, // FULLWIDTH LATIN CAPITAL LETTER O
        "826F": 0xFF30, // FULLWIDTH LATIN CAPITAL LETTER P
        "8270": 0xFF31, // FULLWIDTH LATIN CAPITAL LETTER Q
        "8271": 0xFF32, // FULLWIDTH LATIN CAPITAL LETTER R
        "8272": 0xFF33, // FULLWIDTH LATIN CAPITAL LETTER S
        "8273": 0xFF34, // FULLWIDTH LATIN CAPITAL LETTER T
        "8274": 0xFF35, // FULLWIDTH LATIN CAPITAL LETTER U
        "8275": 0xFF36, // FULLWIDTH LATIN CAPITAL LETTER V
        "8276": 0xFF37, // FULLWIDTH LATIN CAPITAL LETTER W
        "8277": 0xFF38, // FULLWIDTH LATIN CAPITAL LETTER X
        "8278": 0xFF39, // FULLWIDTH LATIN CAPITAL LETTER Y
        "8279": 0xFF3A, // FULLWIDTH LATIN CAPITAL LETTER Z
        "8281": 0xFF41, // FULLWIDTH LATIN SMALL LETTER A
        "8282": 0xFF42, // FULLWIDTH LATIN SMALL LETTER B
        "8283": 0xFF43, // FULLWIDTH LATIN SMALL LETTER C
        "8284": 0xFF44, // FULLWIDTH LATIN SMALL LETTER D
        "8285": 0xFF45, // FULLWIDTH LATIN SMALL LETTER E
        "8286": 0xFF46, // FULLWIDTH LATIN SMALL LETTER F
        "8287": 0xFF47, // FULLWIDTH LATIN SMALL LETTER G
        "8288": 0xFF48, // FULLWIDTH LATIN SMALL LETTER H
        "8289": 0xFF49, // FULLWIDTH LATIN SMALL LETTER I
        "828A": 0xFF4A, // FULLWIDTH LATIN SMALL LETTER J
        "828B": 0xFF4B, // FULLWIDTH LATIN SMALL LETTER K
        "828C": 0xFF4C, // FULLWIDTH LATIN SMALL LETTER L
        "828D": 0xFF4D, // FULLWIDTH LATIN SMALL LETTER M
        "828E": 0xFF4E, // FULLWIDTH LATIN SMALL LETTER N
        "828F": 0xFF4F, // FULLWIDTH LATIN SMALL LETTER O
        "8290": 0xFF50, // FULLWIDTH LATIN SMALL LETTER P
        "8291": 0xFF51, // FULLWIDTH LATIN SMALL LETTER Q
        "8292": 0xFF52, // FULLWIDTH LATIN SMALL LETTER R
        "8293": 0xFF53, // FULLWIDTH LATIN SMALL LETTER S
        "8294": 0xFF54, // FULLWIDTH LATIN SMALL LETTER T
        "8295": 0xFF55, // FULLWIDTH LATIN SMALL LETTER U
        "8296": 0xFF56, // FULLWIDTH LATIN SMALL LETTER V
        "8297": 0xFF57, // FULLWIDTH LATIN SMALL LETTER W
        "8298": 0xFF58, // FULLWIDTH LATIN SMALL LETTER X
        "8299": 0xFF59, // FULLWIDTH LATIN SMALL LETTER Y
        "829A": 0xFF5A, // FULLWIDTH LATIN SMALL LETTER Z
        "829F": 0x3041, // HIRAGANA LETTER SMALL A
        "82A0": 0x3042, // HIRAGANA LETTER A
        "82A1": 0x3043, // HIRAGANA LETTER SMALL I
        "82A2": 0x3044, // HIRAGANA LETTER I
        "82A3": 0x3045, // HIRAGANA LETTER SMALL U
        "82A4": 0x3046, // HIRAGANA LETTER U
        "82A5": 0x3047, // HIRAGANA LETTER SMALL E
        "82A6": 0x3048, // HIRAGANA LETTER E
        "82A7": 0x3049, // HIRAGANA LETTER SMALL O
        "82A8": 0x304A, // HIRAGANA LETTER O
        "82A9": 0x304B, // HIRAGANA LETTER KA
        "82AA": 0x304C, // HIRAGANA LETTER GA
        "82AB": 0x304D, // HIRAGANA LETTER KI
        "82AC": 0x304E, // HIRAGANA LETTER GI
        "82AD": 0x304F, // HIRAGANA LETTER KU
        "82AE": 0x3050, // HIRAGANA LETTER GU
        "82AF": 0x3051, // HIRAGANA LETTER KE
        "82B0": 0x3052, // HIRAGANA LETTER GE
        "82B1": 0x3053, // HIRAGANA LETTER KO
        "82B2": 0x3054, // HIRAGANA LETTER GO
        "82B3": 0x3055, // HIRAGANA LETTER SA
        "82B4": 0x3056, // HIRAGANA LETTER ZA
        "82B5": 0x3057, // HIRAGANA LETTER SI
        "82B6": 0x3058, // HIRAGANA LETTER ZI
        "82B7": 0x3059, // HIRAGANA LETTER SU
        "82B8": 0x305A, // HIRAGANA LETTER ZU
        "82B9": 0x305B, // HIRAGANA LETTER SE
        "82BA": 0x305C, // HIRAGANA LETTER ZE
        "82BB": 0x305D, // HIRAGANA LETTER SO
        "82BC": 0x305E, // HIRAGANA LETTER ZO
        "82BD": 0x305F, // HIRAGANA LETTER TA
        "82BE": 0x3060, // HIRAGANA LETTER DA
        "82BF": 0x3061, // HIRAGANA LETTER TI
        "82C0": 0x3062, // HIRAGANA LETTER DI
        "82C1": 0x3063, // HIRAGANA LETTER SMALL TU
        "82C2": 0x3064, // HIRAGANA LETTER TU
        "82C3": 0x3065, // HIRAGANA LETTER DU
        "82C4": 0x3066, // HIRAGANA LETTER TE
        "82C5": 0x3067, // HIRAGANA LETTER DE
        "82C6": 0x3068, // HIRAGANA LETTER TO
        "82C7": 0x3069, // HIRAGANA LETTER DO
        "82C8": 0x306A, // HIRAGANA LETTER NA
        "82C9": 0x306B, // HIRAGANA LETTER NI
        "82CA": 0x306C, // HIRAGANA LETTER NU
        "82CB": 0x306D, // HIRAGANA LETTER NE
        "82CC": 0x306E, // HIRAGANA LETTER NO
        "82CD": 0x306F, // HIRAGANA LETTER HA
        "82CE": 0x3070, // HIRAGANA LETTER BA
        "82CF": 0x3071, // HIRAGANA LETTER PA
        "82D0": 0x3072, // HIRAGANA LETTER HI
        "82D1": 0x3073, // HIRAGANA LETTER BI
        "82D2": 0x3074, // HIRAGANA LETTER PI
        "82D3": 0x3075, // HIRAGANA LETTER HU
        "82D4": 0x3076, // HIRAGANA LETTER BU
        "82D5": 0x3077, // HIRAGANA LETTER PU
        "82D6": 0x3078, // HIRAGANA LETTER HE
        "82D7": 0x3079, // HIRAGANA LETTER BE
        "82D8": 0x307A, // HIRAGANA LETTER PE
        "82D9": 0x307B, // HIRAGANA LETTER HO
        "82DA": 0x307C, // HIRAGANA LETTER BO
        "82DB": 0x307D, // HIRAGANA LETTER PO
        "82DC": 0x307E, // HIRAGANA LETTER MA
        "82DD": 0x307F, // HIRAGANA LETTER MI
        "82DE": 0x3080, // HIRAGANA LETTER MU
        "82DF": 0x3081, // HIRAGANA LETTER ME
        "82E0": 0x3082, // HIRAGANA LETTER MO
        "82E1": 0x3083, // HIRAGANA LETTER SMALL YA
        "82E2": 0x3084, // HIRAGANA LETTER YA
        "82E3": 0x3085, // HIRAGANA LETTER SMALL YU
        "82E4": 0x3086, // HIRAGANA LETTER YU
        "82E5": 0x3087, // HIRAGANA LETTER SMALL YO
        "82E6": 0x3088, // HIRAGANA LETTER YO
        "82E7": 0x3089, // HIRAGANA LETTER RA
        "82E8": 0x308A, // HIRAGANA LETTER RI
        "82E9": 0x308B, // HIRAGANA LETTER RU
        "82EA": 0x308C, // HIRAGANA LETTER RE
        "82EB": 0x308D, // HIRAGANA LETTER RO
        "82EC": 0x308E, // HIRAGANA LETTER SMALL WA
        "82ED": 0x308F, // HIRAGANA LETTER WA
        "82EE": 0x3090, // HIRAGANA LETTER WI
        "82EF": 0x3091, // HIRAGANA LETTER WE
        "82F0": 0x3092, // HIRAGANA LETTER WO
        "82F1": 0x3093, // HIRAGANA LETTER N
        "8340": 0x30A1, // KATAKANA LETTER SMALL A
        "8341": 0x30A2, // KATAKANA LETTER A
        "8342": 0x30A3, // KATAKANA LETTER SMALL I
        "8343": 0x30A4, // KATAKANA LETTER I
        "8344": 0x30A5, // KATAKANA LETTER SMALL U
        "8345": 0x30A6, // KATAKANA LETTER U
        "8346": 0x30A7, // KATAKANA LETTER SMALL E
        "8347": 0x30A8, // KATAKANA LETTER E
        "8348": 0x30A9, // KATAKANA LETTER SMALL O
        "8349": 0x30AA, // KATAKANA LETTER O
        "834A": 0x30AB, // KATAKANA LETTER KA
        "834B": 0x30AC, // KATAKANA LETTER GA
        "834C": 0x30AD, // KATAKANA LETTER KI
        "834D": 0x30AE, // KATAKANA LETTER GI
        "834E": 0x30AF, // KATAKANA LETTER KU
        "834F": 0x30B0, // KATAKANA LETTER GU
        "8350": 0x30B1, // KATAKANA LETTER KE
        "8351": 0x30B2, // KATAKANA LETTER GE
        "8352": 0x30B3, // KATAKANA LETTER KO
        "8353": 0x30B4, // KATAKANA LETTER GO
        "8354": 0x30B5, // KATAKANA LETTER SA
        "8355": 0x30B6, // KATAKANA LETTER ZA
        "8356": 0x30B7, // KATAKANA LETTER SI
        "8357": 0x30B8, // KATAKANA LETTER ZI
        "8358": 0x30B9, // KATAKANA LETTER SU
        "8359": 0x30BA, // KATAKANA LETTER ZU
        "835A": 0x30BB, // KATAKANA LETTER SE
        "835C": 0x30BD, // KATAKANA LETTER SO
        "835D": 0x30BE, // KATAKANA LETTER ZO
        "835E": 0x30BF, // KATAKANA LETTER TA
        "835F": 0x30C0, // KATAKANA LETTER DA
        "8360": 0x30C1, // KATAKANA LETTER TI
        "8361": 0x30C2, // KATAKANA LETTER DI
        "8362": 0x30C3, // KATAKANA LETTER SMALL TU
        "8363": 0x30C4, // KATAKANA LETTER TU
        "8364": 0x30C5, // KATAKANA LETTER DU
        "8365": 0x30C6, // KATAKANA LETTER TE
        "8366": 0x30C7, // KATAKANA LETTER DE
        "8367": 0x30C8, // KATAKANA LETTER TO
        "8368": 0x30C9, // KATAKANA LETTER DO
        "8369": 0x30CA, // KATAKANA LETTER NA
        "836A": 0x30CB, // KATAKANA LETTER NI
        "836B": 0x30CC, // KATAKANA LETTER NU
        "836C": 0x30CD, // KATAKANA LETTER NE
        "836D": 0x30CE, // KATAKANA LETTER NO
        "836E": 0x30CF, // KATAKANA LETTER HA
        "836F": 0x30D0, // KATAKANA LETTER BA
        "8370": 0x30D1, // KATAKANA LETTER PA
        "8371": 0x30D2, // KATAKANA LETTER HI
        "8372": 0x30D3, // KATAKANA LETTER BI
        "8373": 0x30D4, // KATAKANA LETTER PI
        "8374": 0x30D5, // KATAKANA LETTER HU
        "8375": 0x30D6, // KATAKANA LETTER BU
        "8376": 0x30D7, // KATAKANA LETTER PU
        "8377": 0x30D8, // KATAKANA LETTER HE
        "8378": 0x30D9, // KATAKANA LETTER BE
        "8379": 0x30DA, // KATAKANA LETTER PE
        "837A": 0x30DB, // KATAKANA LETTER HO
        "837B": 0x30DC, // KATAKANA LETTER BO
        "837C": 0x30DD, // KATAKANA LETTER PO
        "837D": 0x30DE, // KATAKANA LETTER MA
        "837E": 0x30DF, // KATAKANA LETTER MI
        "8380": 0x30E0, // KATAKANA LETTER MU
        "8381": 0x30E1, // KATAKANA LETTER ME
        "8382": 0x30E2, // KATAKANA LETTER MO
        "8383": 0x30E3, // KATAKANA LETTER SMALL YA
        "8384": 0x30E4, // KATAKANA LETTER YA
        "8385": 0x30E5, // KATAKANA LETTER SMALL YU
        "8386": 0x30E6, // KATAKANA LETTER YU
        "8387": 0x30E7, // KATAKANA LETTER SMALL YO
        "8388": 0x30E8, // KATAKANA LETTER YO
        "8389": 0x30E9, // KATAKANA LETTER RA
        "838A": 0x30EA, // KATAKANA LETTER RI
        "838B": 0x30EB, // KATAKANA LETTER RU
        "838C": 0x30EC, // KATAKANA LETTER RE
        "838D": 0x30ED, // KATAKANA LETTER RO
        "838E": 0x30EE, // KATAKANA LETTER SMALL WA
        "838F": 0x30EF, // KATAKANA LETTER WA
        "8390": 0x30F0, // KATAKANA LETTER WI
        "8391": 0x30F1, // KATAKANA LETTER WE
        "8392": 0x30F2, // KATAKANA LETTER WO
        "8393": 0x30F3, // KATAKANA LETTER N
        "8394": 0x30F4, // KATAKANA LETTER VU
        "8395": 0x30F5, // KATAKANA LETTER SMALL KA
        "8396": 0x30F6, // KATAKANA LETTER SMALL KE
        "839F": 0x0391, // GREEK CAPITAL LETTER ALPHA
        "83A0": 0x0392, // GREEK CAPITAL LETTER BETA
        "83A1": 0x0393, // GREEK CAPITAL LETTER GAMMA
        "83A2": 0x0394, // GREEK CAPITAL LETTER DELTA
        "83A3": 0x0395, // GREEK CAPITAL LETTER EPSILON
        "83A4": 0x0396, // GREEK CAPITAL LETTER ZETA
        "83A5": 0x0397, // GREEK CAPITAL LETTER ETA
        "83A6": 0x0398, // GREEK CAPITAL LETTER THETA
        "83A7": 0x0399, // GREEK CAPITAL LETTER IOTA
        "83A8": 0x039A, // GREEK CAPITAL LETTER KAPPA
        "83A9": 0x039B, // GREEK CAPITAL LETTER LAMDA
        "83AA": 0x039C, // GREEK CAPITAL LETTER MU
        "83AB": 0x039D, // GREEK CAPITAL LETTER NU
        "83AC": 0x039E, // GREEK CAPITAL LETTER XI
        "83AD": 0x039F, // GREEK CAPITAL LETTER OMICRON
        "83AE": 0x03A0, // GREEK CAPITAL LETTER PI
        "83AF": 0x03A1, // GREEK CAPITAL LETTER RHO
        "83B0": 0x03A3, // GREEK CAPITAL LETTER SIGMA
        "83B1": 0x03A4, // GREEK CAPITAL LETTER TAU
        "83B2": 0x03A5, // GREEK CAPITAL LETTER UPSILON
        "83B3": 0x03A6, // GREEK CAPITAL LETTER PHI
        "83B4": 0x03A7, // GREEK CAPITAL LETTER CHI
        "83B5": 0x03A8, // GREEK CAPITAL LETTER PSI
        "83B6": 0x03A9, // GREEK CAPITAL LETTER OMEGA
        "83BF": 0x03B1, // GREEK SMALL LETTER ALPHA
        "83C0": 0x03B2, // GREEK SMALL LETTER BETA
        "83C1": 0x03B3, // GREEK SMALL LETTER GAMMA
        "83C2": 0x03B4, // GREEK SMALL LETTER DELTA
        "83C3": 0x03B5, // GREEK SMALL LETTER EPSILON
        "83C4": 0x03B6, // GREEK SMALL LETTER ZETA
        "83C5": 0x03B7, // GREEK SMALL LETTER ETA
        "83C6": 0x03B8, // GREEK SMALL LETTER THETA
        "83C7": 0x03B9, // GREEK SMALL LETTER IOTA
        "83C8": 0x03BA, // GREEK SMALL LETTER KAPPA
        "83C9": 0x03BB, // GREEK SMALL LETTER LAMDA
        "83CA": 0x03BC, // GREEK SMALL LETTER MU
        "83CB": 0x03BD, // GREEK SMALL LETTER NU
        "83CC": 0x03BE, // GREEK SMALL LETTER XI
        "83CD": 0x03BF, // GREEK SMALL LETTER OMICRON
        "83CE": 0x03C0, // GREEK SMALL LETTER PI
        "83CF": 0x03C1, // GREEK SMALL LETTER RHO
        "83D0": 0x03C3, // GREEK SMALL LETTER SIGMA
        "83D1": 0x03C4, // GREEK SMALL LETTER TAU
        "83D2": 0x03C5, // GREEK SMALL LETTER UPSILON
        "83D3": 0x03C6, // GREEK SMALL LETTER PHI
        "83D4": 0x03C7, // GREEK SMALL LETTER CHI
        "83D5": 0x03C8, // GREEK SMALL LETTER PSI
        "83D6": 0x03C9, // GREEK SMALL LETTER OMEGA
        "8440": 0x0410, // CYRILLIC CAPITAL LETTER A
        "8441": 0x0411, // CYRILLIC CAPITAL LETTER BE
        "8442": 0x0412, // CYRILLIC CAPITAL LETTER VE
        "8443": 0x0413, // CYRILLIC CAPITAL LETTER GHE
        "8444": 0x0414, // CYRILLIC CAPITAL LETTER DE
        "8445": 0x0415, // CYRILLIC CAPITAL LETTER IE
        "8446": 0x0401, // CYRILLIC CAPITAL LETTER IO
        "8447": 0x0416, // CYRILLIC CAPITAL LETTER ZHE
        "8448": 0x0417, // CYRILLIC CAPITAL LETTER ZE
        "8449": 0x0418, // CYRILLIC CAPITAL LETTER I
        "844A": 0x0419, // CYRILLIC CAPITAL LETTER SHORT I
        "844B": 0x041A, // CYRILLIC CAPITAL LETTER KA
        "844C": 0x041B, // CYRILLIC CAPITAL LETTER EL
        "844D": 0x041C, // CYRILLIC CAPITAL LETTER EM
        "844E": 0x041D, // CYRILLIC CAPITAL LETTER EN
        "844F": 0x041E, // CYRILLIC CAPITAL LETTER O
        "8450": 0x041F, // CYRILLIC CAPITAL LETTER PE
        "8451": 0x0420, // CYRILLIC CAPITAL LETTER ER
        "8452": 0x0421, // CYRILLIC CAPITAL LETTER ES
        "8453": 0x0422, // CYRILLIC CAPITAL LETTER TE
        "8454": 0x0423, // CYRILLIC CAPITAL LETTER U
        "8455": 0x0424, // CYRILLIC CAPITAL LETTER EF
        "8456": 0x0425, // CYRILLIC CAPITAL LETTER HA
        "8457": 0x0426, // CYRILLIC CAPITAL LETTER TSE
        "8458": 0x0427, // CYRILLIC CAPITAL LETTER CHE
        "8459": 0x0428, // CYRILLIC CAPITAL LETTER SHA
        "845A": 0x0429, // CYRILLIC CAPITAL LETTER SHCHA
        "845C": 0x042B, // CYRILLIC CAPITAL LETTER YERU
        "845D": 0x042C, // CYRILLIC CAPITAL LETTER SOFT SIGN
        "845E": 0x042D, // CYRILLIC CAPITAL LETTER E
        "845F": 0x042E, // CYRILLIC CAPITAL LETTER YU
        "8460": 0x042F, // CYRILLIC CAPITAL LETTER YA
        "8470": 0x0430, // CYRILLIC SMALL LETTER A
        "8471": 0x0431, // CYRILLIC SMALL LETTER BE
        "8472": 0x0432, // CYRILLIC SMALL LETTER VE
        "8473": 0x0433, // CYRILLIC SMALL LETTER GHE
        "8474": 0x0434, // CYRILLIC SMALL LETTER DE
        "8475": 0x0435, // CYRILLIC SMALL LETTER IE
        "8476": 0x0451, // CYRILLIC SMALL LETTER IO
        "8477": 0x0436, // CYRILLIC SMALL LETTER ZHE
        "8478": 0x0437, // CYRILLIC SMALL LETTER ZE
        "8479": 0x0438, // CYRILLIC SMALL LETTER I
        "847A": 0x0439, // CYRILLIC SMALL LETTER SHORT I
        "847B": 0x043A, // CYRILLIC SMALL LETTER KA
        "847C": 0x043B, // CYRILLIC SMALL LETTER EL
        "847D": 0x043C, // CYRILLIC SMALL LETTER EM
        "847E": 0x043D, // CYRILLIC SMALL LETTER EN
        "8480": 0x043E, // CYRILLIC SMALL LETTER O
        "8481": 0x043F, // CYRILLIC SMALL LETTER PE
        "8482": 0x0440, // CYRILLIC SMALL LETTER ER
        "8483": 0x0441, // CYRILLIC SMALL LETTER ES
        "8484": 0x0442, // CYRILLIC SMALL LETTER TE
        "8485": 0x0443, // CYRILLIC SMALL LETTER U
        "8486": 0x0444, // CYRILLIC SMALL LETTER EF
        "8487": 0x0445, // CYRILLIC SMALL LETTER HA
        "8488": 0x0446, // CYRILLIC SMALL LETTER TSE
        "8489": 0x0447, // CYRILLIC SMALL LETTER CHE
        "848A": 0x0448, // CYRILLIC SMALL LETTER SHA
        "848B": 0x0449, // CYRILLIC SMALL LETTER SHCHA
        "848C": 0x044A, // CYRILLIC SMALL LETTER HARD SIGN
        "848D": 0x044B, // CYRILLIC SMALL LETTER YERU
        "848E": 0x044C, // CYRILLIC SMALL LETTER SOFT SIGN
        "848F": 0x044D, // CYRILLIC SMALL LETTER E
        "8490": 0x044E, // CYRILLIC SMALL LETTER YU
        "8491": 0x044F, // CYRILLIC SMALL LETTER YA
        "849F": 0x2500, // BOX DRAWINGS LIGHT HORIZONTAL	[1983]
        "84A0": 0x2502, // BOX DRAWINGS LIGHT VERTICAL	[1983]
        "84A1": 0x250C, // BOX DRAWINGS LIGHT DOWN AND RIGHT	[1983]
        "84A2": 0x2510, // BOX DRAWINGS LIGHT DOWN AND LEFT	[1983]
        "84A3": 0x2518, // BOX DRAWINGS LIGHT UP AND LEFT	[1983]
        "84A4": 0x2514, // BOX DRAWINGS LIGHT UP AND RIGHT	[1983]
        "84A5": 0x251C, // BOX DRAWINGS LIGHT VERTICAL AND RIGHT	[1983]
        "84A6": 0x252C, // BOX DRAWINGS LIGHT DOWN AND HORIZONTAL	[1983]
        "84A7": 0x2524, // BOX DRAWINGS LIGHT VERTICAL AND LEFT	[1983]
        "84A8": 0x2534, // BOX DRAWINGS LIGHT UP AND HORIZONTAL	[1983]
        "84A9": 0x253C, // BOX DRAWINGS LIGHT VERTICAL AND HORIZONTAL	[1983]
        "84AA": 0x2501, // BOX DRAWINGS HEAVY HORIZONTAL	[1983]
        "84AB": 0x2503, // BOX DRAWINGS HEAVY VERTICAL	[1983]
        "84AC": 0x250F, // BOX DRAWINGS HEAVY DOWN AND RIGHT	[1983]
        "84AD": 0x2513, // BOX DRAWINGS HEAVY DOWN AND LEFT	[1983]
        "84AE": 0x251B, // BOX DRAWINGS HEAVY UP AND LEFT	[1983]
        "84AF": 0x2517, // BOX DRAWINGS HEAVY UP AND RIGHT	[1983]
        "84B0": 0x2523, // BOX DRAWINGS HEAVY VERTICAL AND RIGHT	[1983]
        "84B1": 0x2533, // BOX DRAWINGS HEAVY DOWN AND HORIZONTAL	[1983]
        "84B2": 0x252B, // BOX DRAWINGS HEAVY VERTICAL AND LEFT	[1983]
        "84B3": 0x253B, // BOX DRAWINGS HEAVY UP AND HORIZONTAL	[1983]
        "84B4": 0x254B, // BOX DRAWINGS HEAVY VERTICAL AND HORIZONTAL	[1983]
        "84B5": 0x2520, // BOX DRAWINGS VERTICAL HEAVY AND RIGHT LIGHT	[1983]
        "84B6": 0x252F, // BOX DRAWINGS DOWN LIGHT AND HORIZONTAL HEAVY	[1983]
        "84B7": 0x2528, // BOX DRAWINGS VERTICAL HEAVY AND LEFT LIGHT	[1983]
        "84B8": 0x2537, // BOX DRAWINGS UP LIGHT AND HORIZONTAL HEAVY	[1983]
        "84B9": 0x253F, // BOX DRAWINGS VERTICAL LIGHT AND HORIZONTAL HEAVY	[1983]
        "84BA": 0x251D, // BOX DRAWINGS VERTICAL LIGHT AND RIGHT HEAVY	[1983]
        "84BB": 0x2530, // BOX DRAWINGS DOWN HEAVY AND HORIZONTAL LIGHT	[1983]
        "84BC": 0x2525, // BOX DRAWINGS VERTICAL LIGHT AND LEFT HEAVY	[1983]
        "84BD": 0x2538, // BOX DRAWINGS UP HEAVY AND HORIZONTAL LIGHT	[1983]
        "84BE": 0x2542, // BOX DRAWINGS VERTICAL HEAVY AND HORIZONTAL LIGHT	[1983]
        "889F": 0x4E9C, // <cjk>
        "88A0": 0x5516, // <cjk>
        "88A1": 0x5A03, // <cjk>
        "88A2": 0x963F, // <cjk>
        "88A3": 0x54C0, // <cjk>
        "88A4": 0x611B, // <cjk>
        "88A5": 0x6328, // <cjk>
        "88A6": 0x59F6, // <cjk>
        "88A7": 0x9022, // <cjk>
        "88A8": 0x8475, // <cjk>
        "88A9": 0x831C, // <cjk>
        "88AA": 0x7A50, // <cjk>
        "88AB": 0x60AA, // <cjk>
        "88AC": 0x63E1, // <cjk>
        "88AD": 0x6E25, // <cjk>
        "88AE": 0x65ED, // <cjk>
        "88AF": 0x8466, // <cjk>
        "88B0": 0x82A6, // <cjk>
        "88B1": 0x9BF5, // <cjk>
        "88B2": 0x6893, // <cjk>
        "88B3": 0x5727, // <cjk>
        "88B4": 0x65A1, // <cjk>
        "88B5": 0x6271, // <cjk>
        "88B6": 0x5B9B, // <cjk>
        "88B7": 0x59D0, // <cjk>
        "88B8": 0x867B, // <cjk>
        "88B9": 0x98F4, // <cjk>
        "88BA": 0x7D62, // <cjk>
        "88BB": 0x7DBE, // <cjk>
        "88BC": 0x9B8E, // <cjk>
        "88BD": 0x6216, // <cjk>
        "88BE": 0x7C9F, // <cjk>
        "88BF": 0x88B7, // <cjk>
        "88C0": 0x5B89, // <cjk>
        "88C1": 0x5EB5, // <cjk>
        "88C2": 0x6309, // <cjk>
        "88C3": 0x6697, // <cjk>
        "88C4": 0x6848, // <cjk>
        "88C5": 0x95C7, // <cjk>
        "88C6": 0x978D, // <cjk>
        "88C7": 0x674F, // <cjk>
        "88C8": 0x4EE5, // <cjk>
        "88C9": 0x4F0A, // <cjk>
        "88CA": 0x4F4D, // <cjk>
        "88CB": 0x4F9D, // <cjk>
        "88CC": 0x5049, // <cjk>
        "88CD": 0x56F2, // <cjk>
        "88CE": 0x5937, // <cjk>
        "88CF": 0x59D4, // <cjk>
        "88D0": 0x5A01, // <cjk>
        "88D1": 0x5C09, // <cjk>
        "88D2": 0x60DF, // <cjk>
        "88D3": 0x610F, // <cjk>
        "88D4": 0x6170, // <cjk>
        "88D5": 0x6613, // <cjk>
        "88D6": 0x6905, // <cjk>
        "88D7": 0x70BA, // <cjk>
        "88D8": 0x754F, // <cjk>
        "88D9": 0x7570, // <cjk>
        "88DA": 0x79FB, // <cjk>
        "88DB": 0x7DAD, // <cjk>
        "88DC": 0x7DEF, // <cjk>
        "88DD": 0x80C3, // <cjk>
        "88DE": 0x840E, // <cjk>
        "88DF": 0x8863, // <cjk>
        "88E0": 0x8B02, // <cjk>
        "88E1": 0x9055, // <cjk>
        "88E2": 0x907A, // <cjk>
        "88E3": 0x533B, // <cjk>
        "88E4": 0x4E95, // <cjk>
        "88E5": 0x4EA5, // <cjk>
        "88E6": 0x57DF, // <cjk>
        "88E7": 0x80B2, // <cjk>
        "88E8": 0x90C1, // <cjk>
        "88E9": 0x78EF, // <cjk>
        "88EA": 0x4E00, // <cjk>
        "88EB": 0x58F1, // <cjk>
        "88EC": 0x6EA2, // <cjk>
        "88ED": 0x9038, // <cjk>
        "88EE": 0x7A32, // <cjk>
        "88EF": 0x8328, // <cjk>
        "88F0": 0x828B, // <cjk>
        "88F1": 0x9C2F, // <cjk>
        "88F2": 0x5141, // <cjk>
        "88F3": 0x5370, // <cjk>
        "88F4": 0x54BD, // <cjk>
        "88F5": 0x54E1, // <cjk>
        "88F6": 0x56E0, // <cjk>
        "88F7": 0x59FB, // <cjk>
        "88F8": 0x5F15, // <cjk>
        "88F9": 0x98F2, // <cjk>
        "88FA": 0x6DEB, // <cjk>
        "88FB": 0x80E4, // <cjk>
        "88FC": 0x852D, // <cjk>
        "8940": 0x9662, // <cjk>
        "8941": 0x9670, // <cjk>
        "8942": 0x96A0, // <cjk>
        "8943": 0x97FB, // <cjk>
        "8944": 0x540B, // <cjk>
        "8945": 0x53F3, // <cjk>
        "8946": 0x5B87, // <cjk>
        "8947": 0x70CF, // <cjk>
        "8948": 0x7FBD, // <cjk>
        "8949": 0x8FC2, // <cjk>
        "894A": 0x96E8, // <cjk>
        "894B": 0x536F, // <cjk>
        "894C": 0x9D5C, // <cjk>
        "894D": 0x7ABA, // <cjk>
        "894E": 0x4E11, // <cjk>
        "894F": 0x7893, // <cjk>
        "8950": 0x81FC, // <cjk>
        "8951": 0x6E26, // <cjk>
        "8952": 0x5618, // <cjk>
        "8953": 0x5504, // <cjk>
        "8954": 0x6B1D, // <cjk>
        "8955": 0x851A, // <cjk>
        "8956": 0x9C3B, // <cjk>
        "8957": 0x59E5, // <cjk>
        "8958": 0x53A9, // <cjk>
        "8959": 0x6D66, // <cjk>
        "895A": 0x74DC, // <cjk>
        "895C": 0x5642, // <cjk>
        "895D": 0x4E91, // <cjk>
        "895E": 0x904B, // <cjk>
        "895F": 0x96F2, // <cjk>
        "8960": 0x834F, // <cjk>
        "8961": 0x990C, // <cjk>
        "8962": 0x53E1, // <cjk>
        "8963": 0x55B6, // <cjk>
        "8964": 0x5B30, // <cjk>
        "8965": 0x5F71, // <cjk>
        "8966": 0x6620, // <cjk>
        "8967": 0x66F3, // <cjk>
        "8968": 0x6804, // <cjk>
        "8969": 0x6C38, // <cjk>
        "896A": 0x6CF3, // <cjk>
        "896B": 0x6D29, // <cjk>
        "896C": 0x745B, // <cjk>
        "896D": 0x76C8, // <cjk>
        "896E": 0x7A4E, // <cjk>
        "896F": 0x9834, // <cjk>
        "8970": 0x82F1, // <cjk>
        "8971": 0x885B, // <cjk>
        "8972": 0x8A60, // <cjk>
        "8973": 0x92ED, // <cjk>
        "8974": 0x6DB2, // <cjk>
        "8975": 0x75AB, // <cjk>
        "8976": 0x76CA, // <cjk>
        "8977": 0x99C5, // <cjk>
        "8978": 0x60A6, // <cjk>
        "8979": 0x8B01, // <cjk>
        "897A": 0x8D8A, // <cjk>
        "897B": 0x95B2, // <cjk>
        "897C": 0x698E, // <cjk>
        "897D": 0x53AD, // <cjk>
        "897E": 0x5186, // <cjk>
        "8980": 0x5712, // <cjk>
        "8981": 0x5830, // <cjk>
        "8982": 0x5944, // <cjk>
        "8983": 0x5BB4, // <cjk>
        "8984": 0x5EF6, // <cjk>
        "8985": 0x6028, // <cjk>
        "8986": 0x63A9, // <cjk>
        "8987": 0x63F4, // <cjk>
        "8988": 0x6CBF, // <cjk>
        "8989": 0x6F14, // <cjk>
        "898A": 0x708E, // <cjk>
        "898B": 0x7114, // <cjk>
        "898C": 0x7159, // <cjk>
        "898D": 0x71D5, // <cjk>
        "898E": 0x733F, // <cjk>
        "898F": 0x7E01, // <cjk>
        "8990": 0x8276, // <cjk>
        "8991": 0x82D1, // <cjk>
        "8992": 0x8597, // <cjk>
        "8993": 0x9060, // <cjk>
        "8994": 0x925B, // <cjk>
        "8995": 0x9D1B, // <cjk>
        "8996": 0x5869, // <cjk>
        "8997": 0x65BC, // <cjk>
        "8998": 0x6C5A, // <cjk>
        "8999": 0x7525, // <cjk>
        "899A": 0x51F9, // <cjk>
        "899B": 0x592E, // <cjk>
        "899C": 0x5965, // <cjk>
        "899D": 0x5F80, // <cjk>
        "899E": 0x5FDC, // <cjk>
        "899F": 0x62BC, // <cjk>
        "89A0": 0x65FA, // <cjk>
        "89A1": 0x6A2A, // <cjk>
        "89A2": 0x6B27, // <cjk>
        "89A3": 0x6BB4, // <cjk>
        "89A4": 0x738B, // <cjk>
        "89A5": 0x7FC1, // <cjk>
        "89A6": 0x8956, // <cjk>
        "89A7": 0x9D2C, // <cjk>
        "89A8": 0x9D0E, // <cjk>
        "89A9": 0x9EC4, // <cjk>
        "89AA": 0x5CA1, // <cjk>
        "89AB": 0x6C96, // <cjk>
        "89AC": 0x837B, // <cjk>
        "89AD": 0x5104, // <cjk>
        "89AE": 0x5C4B, // <cjk>
        "89AF": 0x61B6, // <cjk>
        "89B0": 0x81C6, // <cjk>
        "89B1": 0x6876, // <cjk>
        "89B2": 0x7261, // <cjk>
        "89B3": 0x4E59, // <cjk>
        "89B4": 0x4FFA, // <cjk>
        "89B5": 0x5378, // <cjk>
        "89B6": 0x6069, // <cjk>
        "89B7": 0x6E29, // <cjk>
        "89B8": 0x7A4F, // <cjk>
        "89B9": 0x97F3, // <cjk>
        "89BA": 0x4E0B, // <cjk>
        "89BB": 0x5316, // <cjk>
        "89BC": 0x4EEE, // <cjk>
        "89BD": 0x4F55, // <cjk>
        "89BE": 0x4F3D, // <cjk>
        "89BF": 0x4FA1, // <cjk>
        "89C0": 0x4F73, // <cjk>
        "89C1": 0x52A0, // <cjk>
        "89C2": 0x53EF, // <cjk>
        "89C3": 0x5609, // <cjk>
        "89C4": 0x590F, // <cjk>
        "89C5": 0x5AC1, // <cjk>
        "89C6": 0x5BB6, // <cjk>
        "89C7": 0x5BE1, // <cjk>
        "89C8": 0x79D1, // <cjk>
        "89C9": 0x6687, // <cjk>
        "89CA": 0x679C, // <cjk>
        "89CB": 0x67B6, // <cjk>
        "89CC": 0x6B4C, // <cjk>
        "89CD": 0x6CB3, // <cjk>
        "89CE": 0x706B, // <cjk>
        "89CF": 0x73C2, // <cjk>
        "89D0": 0x798D, // <cjk>
        "89D1": 0x79BE, // <cjk>
        "89D2": 0x7A3C, // <cjk>
        "89D3": 0x7B87, // <cjk>
        "89D4": 0x82B1, // <cjk>
        "89D5": 0x82DB, // <cjk>
        "89D6": 0x8304, // <cjk>
        "89D7": 0x8377, // <cjk>
        "89D8": 0x83EF, // <cjk>
        "89D9": 0x83D3, // <cjk>
        "89DA": 0x8766, // <cjk>
        "89DB": 0x8AB2, // <cjk>
        "89DC": 0x5629, // <cjk>
        "89DD": 0x8CA8, // <cjk>
        "89DE": 0x8FE6, // <cjk>
        "89DF": 0x904E, // <cjk>
        "89E0": 0x971E, // <cjk>
        "89E1": 0x868A, // <cjk>
        "89E2": 0x4FC4, // <cjk>
        "89E3": 0x5CE8, // <cjk>
        "89E4": 0x6211, // <cjk>
        "89E5": 0x7259, // <cjk>
        "89E6": 0x753B, // <cjk>
        "89E7": 0x81E5, // <cjk>
        "89E8": 0x82BD, // <cjk>
        "89E9": 0x86FE, // <cjk>
        "89EA": 0x8CC0, // <cjk>
        "89EB": 0x96C5, // <cjk>
        "89EC": 0x9913, // <cjk>
        "89ED": 0x99D5, // <cjk>
        "89EE": 0x4ECB, // <cjk>
        "89EF": 0x4F1A, // <cjk>
        "89F0": 0x89E3, // <cjk>
        "89F1": 0x56DE, // <cjk>
        "89F2": 0x584A, // <cjk>
        "89F3": 0x58CA, // <cjk>
        "89F4": 0x5EFB, // <cjk>
        "89F5": 0x5FEB, // <cjk>
        "89F6": 0x602A, // <cjk>
        "89F7": 0x6094, // <cjk>
        "89F8": 0x6062, // <cjk>
        "89F9": 0x61D0, // <cjk>
        "89FA": 0x6212, // <cjk>
        "89FB": 0x62D0, // <cjk>
        "89FC": 0x6539, // <cjk>
        "8A40": 0x9B41, // <cjk>
        "8A41": 0x6666, // <cjk>
        "8A42": 0x68B0, // <cjk>
        "8A43": 0x6D77, // <cjk>
        "8A44": 0x7070, // <cjk>
        "8A45": 0x754C, // <cjk>
        "8A46": 0x7686, // <cjk>
        "8A47": 0x7D75, // <cjk>
        "8A48": 0x82A5, // <cjk>
        "8A49": 0x87F9, // <cjk>
        "8A4A": 0x958B, // <cjk>
        "8A4B": 0x968E, // <cjk>
        "8A4C": 0x8C9D, // <cjk>
        "8A4D": 0x51F1, // <cjk>
        "8A4E": 0x52BE, // <cjk>
        "8A4F": 0x5916, // <cjk>
        "8A50": 0x54B3, // <cjk>
        "8A51": 0x5BB3, // <cjk>
        "8A52": 0x5D16, // <cjk>
        "8A53": 0x6168, // <cjk>
        "8A54": 0x6982, // <cjk>
        "8A55": 0x6DAF, // <cjk>
        "8A56": 0x788D, // <cjk>
        "8A57": 0x84CB, // <cjk>
        "8A58": 0x8857, // <cjk>
        "8A59": 0x8A72, // <cjk>
        "8A5A": 0x93A7, // <cjk>
        "8A5C": 0x6D6C, // <cjk>
        "8A5D": 0x99A8, // <cjk>
        "8A5E": 0x86D9, // <cjk>
        "8A5F": 0x57A3, // <cjk>
        "8A60": 0x67FF, // <cjk>
        "8A61": 0x86CE, // <cjk>
        "8A62": 0x920E, // <cjk>
        "8A63": 0x5283, // <cjk>
        "8A64": 0x5687, // <cjk>
        "8A65": 0x5404, // <cjk>
        "8A66": 0x5ED3, // <cjk>
        "8A67": 0x62E1, // <cjk>
        "8A68": 0x64B9, // <cjk>
        "8A69": 0x683C, // <cjk>
        "8A6A": 0x6838, // <cjk>
        "8A6B": 0x6BBB, // <cjk>
        "8A6C": 0x7372, // <cjk>
        "8A6D": 0x78BA, // <cjk>
        "8A6E": 0x7A6B, // <cjk>
        "8A6F": 0x899A, // <cjk>
        "8A70": 0x89D2, // <cjk>
        "8A71": 0x8D6B, // <cjk>
        "8A72": 0x8F03, // <cjk>
        "8A73": 0x90ED, // <cjk>
        "8A74": 0x95A3, // <cjk>
        "8A75": 0x9694, // <cjk>
        "8A76": 0x9769, // <cjk>
        "8A77": 0x5B66, // <cjk>
        "8A78": 0x5CB3, // <cjk>
        "8A79": 0x697D, // <cjk>
        "8A7A": 0x984D, // <cjk>
        "8A7B": 0x984E, // <cjk>
        "8A7C": 0x639B, // <cjk>
        "8A7D": 0x7B20, // <cjk>
        "8A7E": 0x6A2B, // <cjk>
        "8A80": 0x6A7F, // <cjk>
        "8A81": 0x68B6, // <cjk>
        "8A82": 0x9C0D, // <cjk>
        "8A83": 0x6F5F, // <cjk>
        "8A84": 0x5272, // <cjk>
        "8A85": 0x559D, // <cjk>
        "8A86": 0x6070, // <cjk>
        "8A87": 0x62EC, // <cjk>
        "8A88": 0x6D3B, // <cjk>
        "8A89": 0x6E07, // <cjk>
        "8A8A": 0x6ED1, // <cjk>
        "8A8B": 0x845B, // <cjk>
        "8A8C": 0x8910, // <cjk>
        "8A8D": 0x8F44, // <cjk>
        "8A8E": 0x4E14, // <cjk>
        "8A8F": 0x9C39, // <cjk>
        "8A90": 0x53F6, // <cjk>
        "8A91": 0x691B, // <cjk>
        "8A92": 0x6A3A, // <cjk>
        "8A93": 0x9784, // <cjk>
        "8A94": 0x682A, // <cjk>
        "8A95": 0x515C, // <cjk>
        "8A96": 0x7AC3, // <cjk>
        "8A97": 0x84B2, // <cjk>
        "8A98": 0x91DC, // <cjk>
        "8A99": 0x938C, // <cjk>
        "8A9A": 0x565B, // <cjk>
        "8A9B": 0x9D28, // <cjk>
        "8A9C": 0x6822, // <cjk>
        "8A9D": 0x8305, // <cjk>
        "8A9E": 0x8431, // <cjk>
        "8A9F": 0x7CA5, // <cjk>
        "8AA0": 0x5208, // <cjk>
        "8AA1": 0x82C5, // <cjk>
        "8AA2": 0x74E6, // <cjk>
        "8AA3": 0x4E7E, // <cjk>
        "8AA4": 0x4F83, // <cjk>
        "8AA5": 0x51A0, // <cjk>
        "8AA6": 0x5BD2, // <cjk>
        "8AA7": 0x520A, // <cjk>
        "8AA8": 0x52D8, // <cjk>
        "8AA9": 0x52E7, // <cjk>
        "8AAA": 0x5DFB, // <cjk>
        "8AAB": 0x559A, // <cjk>
        "8AAC": 0x582A, // <cjk>
        "8AAD": 0x59E6, // <cjk>
        "8AAE": 0x5B8C, // <cjk>
        "8AAF": 0x5B98, // <cjk>
        "8AB0": 0x5BDB, // <cjk>
        "8AB1": 0x5E72, // <cjk>
        "8AB2": 0x5E79, // <cjk>
        "8AB3": 0x60A3, // <cjk>
        "8AB4": 0x611F, // <cjk>
        "8AB5": 0x6163, // <cjk>
        "8AB6": 0x61BE, // <cjk>
        "8AB7": 0x63DB, // <cjk>
        "8AB8": 0x6562, // <cjk>
        "8AB9": 0x67D1, // <cjk>
        "8ABA": 0x6853, // <cjk>
        "8ABB": 0x68FA, // <cjk>
        "8ABC": 0x6B3E, // <cjk>
        "8ABD": 0x6B53, // <cjk>
        "8ABE": 0x6C57, // <cjk>
        "8ABF": 0x6F22, // <cjk>
        "8AC0": 0x6F97, // <cjk>
        "8AC1": 0x6F45, // <cjk>
        "8AC2": 0x74B0, // <cjk>
        "8AC3": 0x7518, // <cjk>
        "8AC4": 0x76E3, // <cjk>
        "8AC5": 0x770B, // <cjk>
        "8AC6": 0x7AFF, // <cjk>
        "8AC7": 0x7BA1, // <cjk>
        "8AC8": 0x7C21, // <cjk>
        "8AC9": 0x7DE9, // <cjk>
        "8ACA": 0x7F36, // <cjk>
        "8ACB": 0x7FF0, // <cjk>
        "8ACC": 0x809D, // <cjk>
        "8ACD": 0x8266, // <cjk>
        "8ACE": 0x839E, // <cjk>
        "8ACF": 0x89B3, // <cjk>
        "8AD0": 0x8ACC, // <cjk>
        "8AD1": 0x8CAB, // <cjk>
        "8AD2": 0x9084, // <cjk>
        "8AD3": 0x9451, // <cjk>
        "8AD4": 0x9593, // <cjk>
        "8AD5": 0x9591, // <cjk>
        "8AD6": 0x95A2, // <cjk>
        "8AD7": 0x9665, // <cjk>
        "8AD8": 0x97D3, // <cjk>
        "8AD9": 0x9928, // <cjk>
        "8ADA": 0x8218, // <cjk>
        "8ADB": 0x4E38, // <cjk>
        "8ADC": 0x542B, // <cjk>
        "8ADD": 0x5CB8, // <cjk>
        "8ADE": 0x5DCC, // <cjk>
        "8ADF": 0x73A9, // <cjk>
        "8AE0": 0x764C, // <cjk>
        "8AE1": 0x773C, // <cjk>
        "8AE2": 0x5CA9, // <cjk>
        "8AE3": 0x7FEB, // <cjk>
        "8AE4": 0x8D0B, // <cjk>
        "8AE5": 0x96C1, // <cjk>
        "8AE6": 0x9811, // <cjk>
        "8AE7": 0x9854, // <cjk>
        "8AE8": 0x9858, // <cjk>
        "8AE9": 0x4F01, // <cjk>
        "8AEA": 0x4F0E, // <cjk>
        "8AEB": 0x5371, // <cjk>
        "8AEC": 0x559C, // <cjk>
        "8AED": 0x5668, // <cjk>
        "8AEE": 0x57FA, // <cjk>
        "8AEF": 0x5947, // <cjk>
        "8AF0": 0x5B09, // <cjk>
        "8AF1": 0x5BC4, // <cjk>
        "8AF2": 0x5C90, // <cjk>
        "8AF3": 0x5E0C, // <cjk>
        "8AF4": 0x5E7E, // <cjk>
        "8AF5": 0x5FCC, // <cjk>
        "8AF6": 0x63EE, // <cjk>
        "8AF7": 0x673A, // <cjk>
        "8AF8": 0x65D7, // <cjk>
        "8AF9": 0x65E2, // <cjk>
        "8AFA": 0x671F, // <cjk>
        "8AFB": 0x68CB, // <cjk>
        "8AFC": 0x68C4, // <cjk>
        "8B40": 0x6A5F, // <cjk>
        "8B41": 0x5E30, // <cjk>
        "8B42": 0x6BC5, // <cjk>
        "8B43": 0x6C17, // <cjk>
        "8B44": 0x6C7D, // <cjk>
        "8B45": 0x757F, // <cjk>
        "8B46": 0x7948, // <cjk>
        "8B47": 0x5B63, // <cjk>
        "8B48": 0x7A00, // <cjk>
        "8B49": 0x7D00, // <cjk>
        "8B4A": 0x5FBD, // <cjk>
        "8B4B": 0x898F, // <cjk>
        "8B4C": 0x8A18, // <cjk>
        "8B4D": 0x8CB4, // <cjk>
        "8B4E": 0x8D77, // <cjk>
        "8B4F": 0x8ECC, // <cjk>
        "8B50": 0x8F1D, // <cjk>
        "8B51": 0x98E2, // <cjk>
        "8B52": 0x9A0E, // <cjk>
        "8B53": 0x9B3C, // <cjk>
        "8B54": 0x4E80, // <cjk>
        "8B55": 0x507D, // <cjk>
        "8B56": 0x5100, // <cjk>
        "8B57": 0x5993, // <cjk>
        "8B58": 0x5B9C, // <cjk>
        "8B59": 0x622F, // <cjk>
        "8B5A": 0x6280, // <cjk>
        "8B5C": 0x6B3A, // <cjk>
        "8B5D": 0x72A0, // <cjk>
        "8B5E": 0x7591, // <cjk>
        "8B5F": 0x7947, // <cjk>
        "8B60": 0x7FA9, // <cjk>
        "8B61": 0x87FB, // <cjk>
        "8B62": 0x8ABC, // <cjk>
        "8B63": 0x8B70, // <cjk>
        "8B64": 0x63AC, // <cjk>
        "8B65": 0x83CA, // <cjk>
        "8B66": 0x97A0, // <cjk>
        "8B67": 0x5409, // <cjk>
        "8B68": 0x5403, // <cjk>
        "8B69": 0x55AB, // <cjk>
        "8B6A": 0x6854, // <cjk>
        "8B6B": 0x6A58, // <cjk>
        "8B6C": 0x8A70, // <cjk>
        "8B6D": 0x7827, // <cjk>
        "8B6E": 0x6775, // <cjk>
        "8B6F": 0x9ECD, // <cjk>
        "8B70": 0x5374, // <cjk>
        "8B71": 0x5BA2, // <cjk>
        "8B72": 0x811A, // <cjk>
        "8B73": 0x8650, // <cjk>
        "8B74": 0x9006, // <cjk>
        "8B75": 0x4E18, // <cjk>
        "8B76": 0x4E45, // <cjk>
        "8B77": 0x4EC7, // <cjk>
        "8B78": 0x4F11, // <cjk>
        "8B79": 0x53CA, // <cjk>
        "8B7A": 0x5438, // <cjk>
        "8B7B": 0x5BAE, // <cjk>
        "8B7C": 0x5F13, // <cjk>
        "8B7D": 0x6025, // <cjk>
        "8B7E": 0x6551, // <cjk>
        "8B80": 0x673D, // <cjk>
        "8B81": 0x6C42, // <cjk>
        "8B82": 0x6C72, // <cjk>
        "8B83": 0x6CE3, // <cjk>
        "8B84": 0x7078, // <cjk>
        "8B85": 0x7403, // <cjk>
        "8B86": 0x7A76, // <cjk>
        "8B87": 0x7AAE, // <cjk>
        "8B88": 0x7B08, // <cjk>
        "8B89": 0x7D1A, // <cjk>
        "8B8A": 0x7CFE, // <cjk>
        "8B8B": 0x7D66, // <cjk>
        "8B8C": 0x65E7, // <cjk>
        "8B8D": 0x725B, // <cjk>
        "8B8E": 0x53BB, // <cjk>
        "8B8F": 0x5C45, // <cjk>
        "8B90": 0x5DE8, // <cjk>
        "8B91": 0x62D2, // <cjk>
        "8B92": 0x62E0, // <cjk>
        "8B93": 0x6319, // <cjk>
        "8B94": 0x6E20, // <cjk>
        "8B95": 0x865A, // <cjk>
        "8B96": 0x8A31, // <cjk>
        "8B97": 0x8DDD, // <cjk>
        "8B98": 0x92F8, // <cjk>
        "8B99": 0x6F01, // <cjk>
        "8B9A": 0x79A6, // <cjk>
        "8B9B": 0x9B5A, // <cjk>
        "8B9C": 0x4EA8, // <cjk>
        "8B9D": 0x4EAB, // <cjk>
        "8B9E": 0x4EAC, // <cjk>
        "8B9F": 0x4F9B, // <cjk>
        "8BA0": 0x4FA0, // <cjk>
        "8BA1": 0x50D1, // <cjk>
        "8BA2": 0x5147, // <cjk>
        "8BA3": 0x7AF6, // <cjk>
        "8BA4": 0x5171, // <cjk>
        "8BA5": 0x51F6, // <cjk>
        "8BA6": 0x5354, // <cjk>
        "8BA7": 0x5321, // <cjk>
        "8BA8": 0x537F, // <cjk>
        "8BA9": 0x53EB, // <cjk>
        "8BAA": 0x55AC, // <cjk>
        "8BAB": 0x5883, // <cjk>
        "8BAC": 0x5CE1, // <cjk>
        "8BAD": 0x5F37, // <cjk>
        "8BAE": 0x5F4A, // <cjk>
        "8BAF": 0x602F, // <cjk>
        "8BB0": 0x6050, // <cjk>
        "8BB1": 0x606D, // <cjk>
        "8BB2": 0x631F, // <cjk>
        "8BB3": 0x6559, // <cjk>
        "8BB4": 0x6A4B, // <cjk>
        "8BB5": 0x6CC1, // <cjk>
        "8BB6": 0x72C2, // <cjk>
        "8BB7": 0x72ED, // <cjk>
        "8BB8": 0x77EF, // <cjk>
        "8BB9": 0x80F8, // <cjk>
        "8BBA": 0x8105, // <cjk>
        "8BBB": 0x8208, // <cjk>
        "8BBC": 0x854E, // <cjk>
        "8BBD": 0x90F7, // <cjk>
        "8BBE": 0x93E1, // <cjk>
        "8BBF": 0x97FF, // <cjk>
        "8BC0": 0x9957, // <cjk>
        "8BC1": 0x9A5A, // <cjk>
        "8BC2": 0x4EF0, // <cjk>
        "8BC3": 0x51DD, // <cjk>
        "8BC4": 0x5C2D, // <cjk>
        "8BC5": 0x6681, // <cjk>
        "8BC6": 0x696D, // <cjk>
        "8BC7": 0x5C40, // <cjk>
        "8BC8": 0x66F2, // <cjk>
        "8BC9": 0x6975, // <cjk>
        "8BCA": 0x7389, // <cjk>
        "8BCB": 0x6850, // <cjk>
        "8BCC": 0x7C81, // <cjk>
        "8BCD": 0x50C5, // <cjk>
        "8BCE": 0x52E4, // <cjk>
        "8BCF": 0x5747, // <cjk>
        "8BD0": 0x5DFE, // <cjk>
        "8BD1": 0x9326, // <cjk>
        "8BD2": 0x65A4, // <cjk>
        "8BD3": 0x6B23, // <cjk>
        "8BD4": 0x6B3D, // <cjk>
        "8BD5": 0x7434, // <cjk>
        "8BD6": 0x7981, // <cjk>
        "8BD7": 0x79BD, // <cjk>
        "8BD8": 0x7B4B, // <cjk>
        "8BD9": 0x7DCA, // <cjk>
        "8BDA": 0x82B9, // <cjk>
        "8BDB": 0x83CC, // <cjk>
        "8BDC": 0x887F, // <cjk>
        "8BDD": 0x895F, // <cjk>
        "8BDE": 0x8B39, // <cjk>
        "8BDF": 0x8FD1, // <cjk>
        "8BE0": 0x91D1, // <cjk>
        "8BE1": 0x541F, // <cjk>
        "8BE2": 0x9280, // <cjk>
        "8BE3": 0x4E5D, // <cjk>
        "8BE4": 0x5036, // <cjk>
        "8BE5": 0x53E5, // <cjk>
        "8BE6": 0x533A, // <cjk>
        "8BE7": 0x72D7, // <cjk>
        "8BE8": 0x7396, // <cjk>
        "8BE9": 0x77E9, // <cjk>
        "8BEA": 0x82E6, // <cjk>
        "8BEB": 0x8EAF, // <cjk>
        "8BEC": 0x99C6, // <cjk>
        "8BED": 0x99C8, // <cjk>
        "8BEE": 0x99D2, // <cjk>
        "8BEF": 0x5177, // <cjk>
        "8BF0": 0x611A, // <cjk>
        "8BF1": 0x865E, // <cjk>
        "8BF2": 0x55B0, // <cjk>
        "8BF3": 0x7A7A, // <cjk>
        "8BF4": 0x5076, // <cjk>
        "8BF5": 0x5BD3, // <cjk>
        "8BF6": 0x9047, // <cjk>
        "8BF7": 0x9685, // <cjk>
        "8BF8": 0x4E32, // <cjk>
        "8BF9": 0x6ADB, // <cjk>
        "8BFA": 0x91E7, // <cjk>
        "8BFB": 0x5C51, // <cjk>
        "8BFC": 0x5C48, // <cjk>
        "8C40": 0x6398, // <cjk>
        "8C41": 0x7A9F, // <cjk>
        "8C42": 0x6C93, // <cjk>
        "8C43": 0x9774, // <cjk>
        "8C44": 0x8F61, // <cjk>
        "8C45": 0x7AAA, // <cjk>
        "8C46": 0x718A, // <cjk>
        "8C47": 0x9688, // <cjk>
        "8C48": 0x7C82, // <cjk>
        "8C49": 0x6817, // <cjk>
        "8C4A": 0x7E70, // <cjk>
        "8C4B": 0x6851, // <cjk>
        "8C4C": 0x936C, // <cjk>
        "8C4D": 0x52F2, // <cjk>
        "8C4E": 0x541B, // <cjk>
        "8C4F": 0x85AB, // <cjk>
        "8C50": 0x8A13, // <cjk>
        "8C51": 0x7FA4, // <cjk>
        "8C52": 0x8ECD, // <cjk>
        "8C53": 0x90E1, // <cjk>
        "8C54": 0x5366, // <cjk>
        "8C55": 0x8888, // <cjk>
        "8C56": 0x7941, // <cjk>
        "8C57": 0x4FC2, // <cjk>
        "8C58": 0x50BE, // <cjk>
        "8C59": 0x5211, // <cjk>
        "8C5A": 0x5144, // <cjk>
        "8C5C": 0x572D, // <cjk>
        "8C5D": 0x73EA, // <cjk>
        "8C5E": 0x578B, // <cjk>
        "8C5F": 0x5951, // <cjk>
        "8C60": 0x5F62, // <cjk>
        "8C61": 0x5F84, // <cjk>
        "8C62": 0x6075, // <cjk>
        "8C63": 0x6176, // <cjk>
        "8C64": 0x6167, // <cjk>
        "8C65": 0x61A9, // <cjk>
        "8C66": 0x63B2, // <cjk>
        "8C67": 0x643A, // <cjk>
        "8C68": 0x656C, // <cjk>
        "8C69": 0x666F, // <cjk>
        "8C6A": 0x6842, // <cjk>
        "8C6B": 0x6E13, // <cjk>
        "8C6C": 0x7566, // <cjk>
        "8C6D": 0x7A3D, // <cjk>
        "8C6E": 0x7CFB, // <cjk>
        "8C6F": 0x7D4C, // <cjk>
        "8C70": 0x7D99, // <cjk>
        "8C71": 0x7E4B, // <cjk>
        "8C72": 0x7F6B, // <cjk>
        "8C73": 0x830E, // <cjk>
        "8C74": 0x834A, // <cjk>
        "8C75": 0x86CD, // <cjk>
        "8C76": 0x8A08, // <cjk>
        "8C77": 0x8A63, // <cjk>
        "8C78": 0x8B66, // <cjk>
        "8C79": 0x8EFD, // <cjk>
        "8C7A": 0x981A, // <cjk>
        "8C7B": 0x9D8F, // <cjk>
        "8C7C": 0x82B8, // <cjk>
        "8C7D": 0x8FCE, // <cjk>
        "8C7E": 0x9BE8, // <cjk>
        "8C80": 0x5287, // <cjk>
        "8C81": 0x621F, // <cjk>
        "8C82": 0x6483, // <cjk>
        "8C83": 0x6FC0, // <cjk>
        "8C84": 0x9699, // <cjk>
        "8C85": 0x6841, // <cjk>
        "8C86": 0x5091, // <cjk>
        "8C87": 0x6B20, // <cjk>
        "8C88": 0x6C7A, // <cjk>
        "8C89": 0x6F54, // <cjk>
        "8C8A": 0x7A74, // <cjk>
        "8C8B": 0x7D50, // <cjk>
        "8C8C": 0x8840, // <cjk>
        "8C8D": 0x8A23, // <cjk>
        "8C8E": 0x6708, // <cjk>
        "8C8F": 0x4EF6, // <cjk>
        "8C90": 0x5039, // <cjk>
        "8C91": 0x5026, // <cjk>
        "8C92": 0x5065, // <cjk>
        "8C93": 0x517C, // <cjk>
        "8C94": 0x5238, // <cjk>
        "8C95": 0x5263, // <cjk>
        "8C96": 0x55A7, // <cjk>
        "8C97": 0x570F, // <cjk>
        "8C98": 0x5805, // <cjk>
        "8C99": 0x5ACC, // <cjk>
        "8C9A": 0x5EFA, // <cjk>
        "8C9B": 0x61B2, // <cjk>
        "8C9C": 0x61F8, // <cjk>
        "8C9D": 0x62F3, // <cjk>
        "8C9E": 0x6372, // <cjk>
        "8C9F": 0x691C, // <cjk>
        "8CA0": 0x6A29, // <cjk>
        "8CA1": 0x727D, // <cjk>
        "8CA2": 0x72AC, // <cjk>
        "8CA3": 0x732E, // <cjk>
        "8CA4": 0x7814, // <cjk>
        "8CA5": 0x786F, // <cjk>
        "8CA6": 0x7D79, // <cjk>
        "8CA7": 0x770C, // <cjk>
        "8CA8": 0x80A9, // <cjk>
        "8CA9": 0x898B, // <cjk>
        "8CAA": 0x8B19, // <cjk>
        "8CAB": 0x8CE2, // <cjk>
        "8CAC": 0x8ED2, // <cjk>
        "8CAD": 0x9063, // <cjk>
        "8CAE": 0x9375, // <cjk>
        "8CAF": 0x967A, // <cjk>
        "8CB0": 0x9855, // <cjk>
        "8CB1": 0x9A13, // <cjk>
        "8CB2": 0x9E78, // <cjk>
        "8CB3": 0x5143, // <cjk>
        "8CB4": 0x539F, // <cjk>
        "8CB5": 0x53B3, // <cjk>
        "8CB6": 0x5E7B, // <cjk>
        "8CB7": 0x5F26, // <cjk>
        "8CB8": 0x6E1B, // <cjk>
        "8CB9": 0x6E90, // <cjk>
        "8CBA": 0x7384, // <cjk>
        "8CBB": 0x73FE, // <cjk>
        "8CBC": 0x7D43, // <cjk>
        "8CBD": 0x8237, // <cjk>
        "8CBE": 0x8A00, // <cjk>
        "8CBF": 0x8AFA, // <cjk>
        "8CC0": 0x9650, // <cjk>
        "8CC1": 0x4E4E, // <cjk>
        "8CC2": 0x500B, // <cjk>
        "8CC3": 0x53E4, // <cjk>
        "8CC4": 0x547C, // <cjk>
        "8CC5": 0x56FA, // <cjk>
        "8CC6": 0x59D1, // <cjk>
        "8CC7": 0x5B64, // <cjk>
        "8CC8": 0x5DF1, // <cjk>
        "8CC9": 0x5EAB, // <cjk>
        "8CCA": 0x5F27, // <cjk>
        "8CCB": 0x6238, // <cjk>
        "8CCC": 0x6545, // <cjk>
        "8CCD": 0x67AF, // <cjk>
        "8CCE": 0x6E56, // <cjk>
        "8CCF": 0x72D0, // <cjk>
        "8CD0": 0x7CCA, // <cjk>
        "8CD1": 0x88B4, // <cjk>
        "8CD2": 0x80A1, // <cjk>
        "8CD3": 0x80E1, // <cjk>
        "8CD4": 0x83F0, // <cjk>
        "8CD5": 0x864E, // <cjk>
        "8CD6": 0x8A87, // <cjk>
        "8CD7": 0x8DE8, // <cjk>
        "8CD8": 0x9237, // <cjk>
        "8CD9": 0x96C7, // <cjk>
        "8CDA": 0x9867, // <cjk>
        "8CDB": 0x9F13, // <cjk>
        "8CDC": 0x4E94, // <cjk>
        "8CDD": 0x4E92, // <cjk>
        "8CDE": 0x4F0D, // <cjk>
        "8CDF": 0x5348, // <cjk>
        "8CE0": 0x5449, // <cjk>
        "8CE1": 0x543E, // <cjk>
        "8CE2": 0x5A2F, // <cjk>
        "8CE3": 0x5F8C, // <cjk>
        "8CE4": 0x5FA1, // <cjk>
        "8CE5": 0x609F, // <cjk>
        "8CE6": 0x68A7, // <cjk>
        "8CE7": 0x6A8E, // <cjk>
        "8CE8": 0x745A, // <cjk>
        "8CE9": 0x7881, // <cjk>
        "8CEA": 0x8A9E, // <cjk>
        "8CEB": 0x8AA4, // <cjk>
        "8CEC": 0x8B77, // <cjk>
        "8CED": 0x9190, // <cjk>
        "8CEE": 0x4E5E, // <cjk>
        "8CEF": 0x9BC9, // <cjk>
        "8CF0": 0x4EA4, // <cjk>
        "8CF1": 0x4F7C, // <cjk>
        "8CF2": 0x4FAF, // <cjk>
        "8CF3": 0x5019, // <cjk>
        "8CF4": 0x5016, // <cjk>
        "8CF5": 0x5149, // <cjk>
        "8CF6": 0x516C, // <cjk>
        "8CF7": 0x529F, // <cjk>
        "8CF8": 0x52B9, // <cjk>
        "8CF9": 0x52FE, // <cjk>
        "8CFA": 0x539A, // <cjk>
        "8CFB": 0x53E3, // <cjk>
        "8CFC": 0x5411, // <cjk>
        "8D40": 0x540E, // <cjk>
        "8D41": 0x5589, // <cjk>
        "8D42": 0x5751, // <cjk>
        "8D43": 0x57A2, // <cjk>
        "8D44": 0x597D, // <cjk>
        "8D45": 0x5B54, // <cjk>
        "8D46": 0x5B5D, // <cjk>
        "8D47": 0x5B8F, // <cjk>
        "8D48": 0x5DE5, // <cjk>
        "8D49": 0x5DE7, // <cjk>
        "8D4A": 0x5DF7, // <cjk>
        "8D4B": 0x5E78, // <cjk>
        "8D4C": 0x5E83, // <cjk>
        "8D4D": 0x5E9A, // <cjk>
        "8D4E": 0x5EB7, // <cjk>
        "8D4F": 0x5F18, // <cjk>
        "8D50": 0x6052, // <cjk>
        "8D51": 0x614C, // <cjk>
        "8D52": 0x6297, // <cjk>
        "8D53": 0x62D8, // <cjk>
        "8D54": 0x63A7, // <cjk>
        "8D55": 0x653B, // <cjk>
        "8D56": 0x6602, // <cjk>
        "8D57": 0x6643, // <cjk>
        "8D58": 0x66F4, // <cjk>
        "8D59": 0x676D, // <cjk>
        "8D5A": 0x6821, // <cjk>
        "8D5C": 0x69CB, // <cjk>
        "8D5D": 0x6C5F, // <cjk>
        "8D5E": 0x6D2A, // <cjk>
        "8D5F": 0x6D69, // <cjk>
        "8D60": 0x6E2F, // <cjk>
        "8D61": 0x6E9D, // <cjk>
        "8D62": 0x7532, // <cjk>
        "8D63": 0x7687, // <cjk>
        "8D64": 0x786C, // <cjk>
        "8D65": 0x7A3F, // <cjk>
        "8D66": 0x7CE0, // <cjk>
        "8D67": 0x7D05, // <cjk>
        "8D68": 0x7D18, // <cjk>
        "8D69": 0x7D5E, // <cjk>
        "8D6A": 0x7DB1, // <cjk>
        "8D6B": 0x8015, // <cjk>
        "8D6C": 0x8003, // <cjk>
        "8D6D": 0x80AF, // <cjk>
        "8D6E": 0x80B1, // <cjk>
        "8D6F": 0x8154, // <cjk>
        "8D70": 0x818F, // <cjk>
        "8D71": 0x822A, // <cjk>
        "8D72": 0x8352, // <cjk>
        "8D73": 0x884C, // <cjk>
        "8D74": 0x8861, // <cjk>
        "8D75": 0x8B1B, // <cjk>
        "8D76": 0x8CA2, // <cjk>
        "8D77": 0x8CFC, // <cjk>
        "8D78": 0x90CA, // <cjk>
        "8D79": 0x9175, // <cjk>
        "8D7A": 0x9271, // <cjk>
        "8D7B": 0x783F, // <cjk>
        "8D7C": 0x92FC, // <cjk>
        "8D7D": 0x95A4, // <cjk>
        "8D7E": 0x964D, // <cjk>
        "8D80": 0x9805, // <cjk>
        "8D81": 0x9999, // <cjk>
        "8D82": 0x9AD8, // <cjk>
        "8D83": 0x9D3B, // <cjk>
        "8D84": 0x525B, // <cjk>
        "8D85": 0x52AB, // <cjk>
        "8D86": 0x53F7, // <cjk>
        "8D87": 0x5408, // <cjk>
        "8D88": 0x58D5, // <cjk>
        "8D89": 0x62F7, // <cjk>
        "8D8A": 0x6FE0, // <cjk>
        "8D8B": 0x8C6A, // <cjk>
        "8D8C": 0x8F5F, // <cjk>
        "8D8D": 0x9EB9, // <cjk>
        "8D8E": 0x514B, // <cjk>
        "8D8F": 0x523B, // <cjk>
        "8D90": 0x544A, // <cjk>
        "8D91": 0x56FD, // <cjk>
        "8D92": 0x7A40, // <cjk>
        "8D93": 0x9177, // <cjk>
        "8D94": 0x9D60, // <cjk>
        "8D95": 0x9ED2, // <cjk>
        "8D96": 0x7344, // <cjk>
        "8D97": 0x6F09, // <cjk>
        "8D98": 0x8170, // <cjk>
        "8D99": 0x7511, // <cjk>
        "8D9A": 0x5FFD, // <cjk>
        "8D9B": 0x60DA, // <cjk>
        "8D9C": 0x9AA8, // <cjk>
        "8D9D": 0x72DB, // <cjk>
        "8D9E": 0x8FBC, // <cjk>
        "8D9F": 0x6B64, // <cjk>
        "8DA0": 0x9803, // <cjk>
        "8DA1": 0x4ECA, // <cjk>
        "8DA2": 0x56F0, // <cjk>
        "8DA3": 0x5764, // <cjk>
        "8DA4": 0x58BE, // <cjk>
        "8DA5": 0x5A5A, // <cjk>
        "8DA6": 0x6068, // <cjk>
        "8DA7": 0x61C7, // <cjk>
        "8DA8": 0x660F, // <cjk>
        "8DA9": 0x6606, // <cjk>
        "8DAA": 0x6839, // <cjk>
        "8DAB": 0x68B1, // <cjk>
        "8DAC": 0x6DF7, // <cjk>
        "8DAD": 0x75D5, // <cjk>
        "8DAE": 0x7D3A, // <cjk>
        "8DAF": 0x826E, // <cjk>
        "8DB0": 0x9B42, // <cjk>
        "8DB1": 0x4E9B, // <cjk>
        "8DB2": 0x4F50, // <cjk>
        "8DB3": 0x53C9, // <cjk>
        "8DB4": 0x5506, // <cjk>
        "8DB5": 0x5D6F, // <cjk>
        "8DB6": 0x5DE6, // <cjk>
        "8DB7": 0x5DEE, // <cjk>
        "8DB8": 0x67FB, // <cjk>
        "8DB9": 0x6C99, // <cjk>
        "8DBA": 0x7473, // <cjk>
        "8DBB": 0x7802, // <cjk>
        "8DBC": 0x8A50, // <cjk>
        "8DBD": 0x9396, // <cjk>
        "8DBE": 0x88DF, // <cjk>
        "8DBF": 0x5750, // <cjk>
        "8DC0": 0x5EA7, // <cjk>
        "8DC1": 0x632B, // <cjk>
        "8DC2": 0x50B5, // <cjk>
        "8DC3": 0x50AC, // <cjk>
        "8DC4": 0x518D, // <cjk>
        "8DC5": 0x6700, // <cjk>
        "8DC6": 0x54C9, // <cjk>
        "8DC7": 0x585E, // <cjk>
        "8DC8": 0x59BB, // <cjk>
        "8DC9": 0x5BB0, // <cjk>
        "8DCA": 0x5F69, // <cjk>
        "8DCB": 0x624D, // <cjk>
        "8DCC": 0x63A1, // <cjk>
        "8DCD": 0x683D, // <cjk>
        "8DCE": 0x6B73, // <cjk>
        "8DCF": 0x6E08, // <cjk>
        "8DD0": 0x707D, // <cjk>
        "8DD1": 0x91C7, // <cjk>
        "8DD2": 0x7280, // <cjk>
        "8DD3": 0x7815, // <cjk>
        "8DD4": 0x7826, // <cjk>
        "8DD5": 0x796D, // <cjk>
        "8DD6": 0x658E, // <cjk>
        "8DD7": 0x7D30, // <cjk>
        "8DD8": 0x83DC, // <cjk>
        "8DD9": 0x88C1, // <cjk>
        "8DDA": 0x8F09, // <cjk>
        "8DDB": 0x969B, // <cjk>
        "8DDC": 0x5264, // <cjk>
        "8DDD": 0x5728, // <cjk>
        "8DDE": 0x6750, // <cjk>
        "8DDF": 0x7F6A, // <cjk>
        "8DE0": 0x8CA1, // <cjk>
        "8DE1": 0x51B4, // <cjk>
        "8DE2": 0x5742, // <cjk>
        "8DE3": 0x962A, // <cjk>
        "8DE4": 0x583A, // <cjk>
        "8DE5": 0x698A, // <cjk>
        "8DE6": 0x80B4, // <cjk>
        "8DE7": 0x54B2, // <cjk>
        "8DE8": 0x5D0E, // <cjk>
        "8DE9": 0x57FC, // <cjk>
        "8DEA": 0x7895, // <cjk>
        "8DEB": 0x9DFA, // <cjk>
        "8DEC": 0x4F5C, // <cjk>
        "8DED": 0x524A, // <cjk>
        "8DEE": 0x548B, // <cjk>
        "8DEF": 0x643E, // <cjk>
        "8DF0": 0x6628, // <cjk>
        "8DF1": 0x6714, // <cjk>
        "8DF2": 0x67F5, // <cjk>
        "8DF3": 0x7A84, // <cjk>
        "8DF4": 0x7B56, // <cjk>
        "8DF5": 0x7D22, // <cjk>
        "8DF6": 0x932F, // <cjk>
        "8DF7": 0x685C, // <cjk>
        "8DF8": 0x9BAD, // <cjk>
        "8DF9": 0x7B39, // <cjk>
        "8DFA": 0x5319, // <cjk>
        "8DFB": 0x518A, // <cjk>
        "8DFC": 0x5237, // <cjk>
        "8E40": 0x5BDF, // <cjk>
        "8E41": 0x62F6, // <cjk>
        "8E42": 0x64AE, // <cjk>
        "8E43": 0x64E6, // <cjk>
        "8E44": 0x672D, // <cjk>
        "8E45": 0x6BBA, // <cjk>
        "8E46": 0x85A9, // <cjk>
        "8E47": 0x96D1, // <cjk>
        "8E48": 0x7690, // <cjk>
        "8E49": 0x9BD6, // <cjk>
        "8E4A": 0x634C, // <cjk>
        "8E4B": 0x9306, // <cjk>
        "8E4C": 0x9BAB, // <cjk>
        "8E4D": 0x76BF, // <cjk>
        "8E4E": 0x6652, // <cjk>
        "8E4F": 0x4E09, // <cjk>
        "8E50": 0x5098, // <cjk>
        "8E51": 0x53C2, // <cjk>
        "8E52": 0x5C71, // <cjk>
        "8E53": 0x60E8, // <cjk>
        "8E54": 0x6492, // <cjk>
        "8E55": 0x6563, // <cjk>
        "8E56": 0x685F, // <cjk>
        "8E57": 0x71E6, // <cjk>
        "8E58": 0x73CA, // <cjk>
        "8E59": 0x7523, // <cjk>
        "8E5A": 0x7B97, // <cjk>
        "8E5C": 0x8695, // <cjk>
        "8E5D": 0x8B83, // <cjk>
        "8E5E": 0x8CDB, // <cjk>
        "8E5F": 0x9178, // <cjk>
        "8E60": 0x9910, // <cjk>
        "8E61": 0x65AC, // <cjk>
        "8E62": 0x66AB, // <cjk>
        "8E63": 0x6B8B, // <cjk>
        "8E64": 0x4ED5, // <cjk>
        "8E65": 0x4ED4, // <cjk>
        "8E66": 0x4F3A, // <cjk>
        "8E67": 0x4F7F, // <cjk>
        "8E68": 0x523A, // <cjk>
        "8E69": 0x53F8, // <cjk>
        "8E6A": 0x53F2, // <cjk>
        "8E6B": 0x55E3, // <cjk>
        "8E6C": 0x56DB, // <cjk>
        "8E6D": 0x58EB, // <cjk>
        "8E6E": 0x59CB, // <cjk>
        "8E6F": 0x59C9, // <cjk>
        "8E70": 0x59FF, // <cjk>
        "8E71": 0x5B50, // <cjk>
        "8E72": 0x5C4D, // <cjk>
        "8E73": 0x5E02, // <cjk>
        "8E74": 0x5E2B, // <cjk>
        "8E75": 0x5FD7, // <cjk>
        "8E76": 0x601D, // <cjk>
        "8E77": 0x6307, // <cjk>
        "8E78": 0x652F, // <cjk>
        "8E79": 0x5B5C, // <cjk>
        "8E7A": 0x65AF, // <cjk>
        "8E7B": 0x65BD, // <cjk>
        "8E7C": 0x65E8, // <cjk>
        "8E7D": 0x679D, // <cjk>
        "8E7E": 0x6B62, // <cjk>
        "8E80": 0x6B7B, // <cjk>
        "8E81": 0x6C0F, // <cjk>
        "8E82": 0x7345, // <cjk>
        "8E83": 0x7949, // <cjk>
        "8E84": 0x79C1, // <cjk>
        "8E85": 0x7CF8, // <cjk>
        "8E86": 0x7D19, // <cjk>
        "8E87": 0x7D2B, // <cjk>
        "8E88": 0x80A2, // <cjk>
        "8E89": 0x8102, // <cjk>
        "8E8A": 0x81F3, // <cjk>
        "8E8B": 0x8996, // <cjk>
        "8E8C": 0x8A5E, // <cjk>
        "8E8D": 0x8A69, // <cjk>
        "8E8E": 0x8A66, // <cjk>
        "8E8F": 0x8A8C, // <cjk>
        "8E90": 0x8AEE, // <cjk>
        "8E91": 0x8CC7, // <cjk>
        "8E92": 0x8CDC, // <cjk>
        "8E93": 0x96CC, // <cjk>
        "8E94": 0x98FC, // <cjk>
        "8E95": 0x6B6F, // <cjk>
        "8E96": 0x4E8B, // <cjk>
        "8E97": 0x4F3C, // <cjk>
        "8E98": 0x4F8D, // <cjk>
        "8E99": 0x5150, // <cjk>
        "8E9A": 0x5B57, // <cjk>
        "8E9B": 0x5BFA, // <cjk>
        "8E9C": 0x6148, // <cjk>
        "8E9D": 0x6301, // <cjk>
        "8E9E": 0x6642, // <cjk>
        "8E9F": 0x6B21, // <cjk>
        "8EA0": 0x6ECB, // <cjk>
        "8EA1": 0x6CBB, // <cjk>
        "8EA2": 0x723E, // <cjk>
        "8EA3": 0x74BD, // <cjk>
        "8EA4": 0x75D4, // <cjk>
        "8EA5": 0x78C1, // <cjk>
        "8EA6": 0x793A, // <cjk>
        "8EA7": 0x800C, // <cjk>
        "8EA8": 0x8033, // <cjk>
        "8EA9": 0x81EA, // <cjk>
        "8EAA": 0x8494, // <cjk>
        "8EAB": 0x8F9E, // <cjk>
        "8EAC": 0x6C50, // <cjk>
        "8EAD": 0x9E7F, // <cjk>
        "8EAE": 0x5F0F, // <cjk>
        "8EAF": 0x8B58, // <cjk>
        "8EB0": 0x9D2B, // <cjk>
        "8EB1": 0x7AFA, // <cjk>
        "8EB2": 0x8EF8, // <cjk>
        "8EB3": 0x5B8D, // <cjk>
        "8EB4": 0x96EB, // <cjk>
        "8EB5": 0x4E03, // <cjk>
        "8EB6": 0x53F1, // <cjk>
        "8EB7": 0x57F7, // <cjk>
        "8EB8": 0x5931, // <cjk>
        "8EB9": 0x5AC9, // <cjk>
        "8EBA": 0x5BA4, // <cjk>
        "8EBB": 0x6089, // <cjk>
        "8EBC": 0x6E7F, // <cjk>
        "8EBD": 0x6F06, // <cjk>
        "8EBE": 0x75BE, // <cjk>
        "8EBF": 0x8CEA, // <cjk>
        "8EC0": 0x5B9F, // <cjk>
        "8EC1": 0x8500, // <cjk>
        "8EC2": 0x7BE0, // <cjk>
        "8EC3": 0x5072, // <cjk>
        "8EC4": 0x67F4, // <cjk>
        "8EC5": 0x829D, // <cjk>
        "8EC6": 0x5C61, // <cjk>
        "8EC7": 0x854A, // <cjk>
        "8EC8": 0x7E1E, // <cjk>
        "8EC9": 0x820E, // <cjk>
        "8ECA": 0x5199, // <cjk>
        "8ECB": 0x5C04, // <cjk>
        "8ECC": 0x6368, // <cjk>
        "8ECD": 0x8D66, // <cjk>
        "8ECE": 0x659C, // <cjk>
        "8ECF": 0x716E, // <cjk>
        "8ED0": 0x793E, // <cjk>
        "8ED1": 0x7D17, // <cjk>
        "8ED2": 0x8005, // <cjk>
        "8ED3": 0x8B1D, // <cjk>
        "8ED4": 0x8ECA, // <cjk>
        "8ED5": 0x906E, // <cjk>
        "8ED6": 0x86C7, // <cjk>
        "8ED7": 0x90AA, // <cjk>
        "8ED8": 0x501F, // <cjk>
        "8ED9": 0x52FA, // <cjk>
        "8EDA": 0x5C3A, // <cjk>
        "8EDB": 0x6753, // <cjk>
        "8EDC": 0x707C, // <cjk>
        "8EDD": 0x7235, // <cjk>
        "8EDE": 0x914C, // <cjk>
        "8EDF": 0x91C8, // <cjk>
        "8EE0": 0x932B, // <cjk>
        "8EE1": 0x82E5, // <cjk>
        "8EE2": 0x5BC2, // <cjk>
        "8EE3": 0x5F31, // <cjk>
        "8EE4": 0x60F9, // <cjk>
        "8EE5": 0x4E3B, // <cjk>
        "8EE6": 0x53D6, // <cjk>
        "8EE7": 0x5B88, // <cjk>
        "8EE8": 0x624B, // <cjk>
        "8EE9": 0x6731, // <cjk>
        "8EEA": 0x6B8A, // <cjk>
        "8EEB": 0x72E9, // <cjk>
        "8EEC": 0x73E0, // <cjk>
        "8EED": 0x7A2E, // <cjk>
        "8EEE": 0x816B, // <cjk>
        "8EEF": 0x8DA3, // <cjk>
        "8EF0": 0x9152, // <cjk>
        "8EF1": 0x9996, // <cjk>
        "8EF2": 0x5112, // <cjk>
        "8EF3": 0x53D7, // <cjk>
        "8EF4": 0x546A, // <cjk>
        "8EF5": 0x5BFF, // <cjk>
        "8EF6": 0x6388, // <cjk>
        "8EF7": 0x6A39, // <cjk>
        "8EF8": 0x7DAC, // <cjk>
        "8EF9": 0x9700, // <cjk>
        "8EFA": 0x56DA, // <cjk>
        "8EFB": 0x53CE, // <cjk>
        "8EFC": 0x5468, // <cjk>
        "8F40": 0x5B97, // <cjk>
        "8F41": 0x5C31, // <cjk>
        "8F42": 0x5DDE, // <cjk>
        "8F43": 0x4FEE, // <cjk>
        "8F44": 0x6101, // <cjk>
        "8F45": 0x62FE, // <cjk>
        "8F46": 0x6D32, // <cjk>
        "8F47": 0x79C0, // <cjk>
        "8F48": 0x79CB, // <cjk>
        "8F49": 0x7D42, // <cjk>
        "8F4A": 0x7E4D, // <cjk>
        "8F4B": 0x7FD2, // <cjk>
        "8F4C": 0x81ED, // <cjk>
        "8F4D": 0x821F, // <cjk>
        "8F4E": 0x8490, // <cjk>
        "8F4F": 0x8846, // <cjk>
        "8F50": 0x8972, // <cjk>
        "8F51": 0x8B90, // <cjk>
        "8F52": 0x8E74, // <cjk>
        "8F53": 0x8F2F, // <cjk>
        "8F54": 0x9031, // <cjk>
        "8F55": 0x914B, // <cjk>
        "8F56": 0x916C, // <cjk>
        "8F57": 0x96C6, // <cjk>
        "8F58": 0x919C, // <cjk>
        "8F59": 0x4EC0, // <cjk>
        "8F5A": 0x4F4F, // <cjk>
        "8F5C": 0x5341, // <cjk>
        "8F5D": 0x5F93, // <cjk>
        "8F5E": 0x620E, // <cjk>
        "8F5F": 0x67D4, // <cjk>
        "8F60": 0x6C41, // <cjk>
        "8F61": 0x6E0B, // <cjk>
        "8F62": 0x7363, // <cjk>
        "8F63": 0x7E26, // <cjk>
        "8F64": 0x91CD, // <cjk>
        "8F65": 0x9283, // <cjk>
        "8F66": 0x53D4, // <cjk>
        "8F67": 0x5919, // <cjk>
        "8F68": 0x5BBF, // <cjk>
        "8F69": 0x6DD1, // <cjk>
        "8F6A": 0x795D, // <cjk>
        "8F6B": 0x7E2E, // <cjk>
        "8F6C": 0x7C9B, // <cjk>
        "8F6D": 0x587E, // <cjk>
        "8F6E": 0x719F, // <cjk>
        "8F6F": 0x51FA, // <cjk>
        "8F70": 0x8853, // <cjk>
        "8F71": 0x8FF0, // <cjk>
        "8F72": 0x4FCA, // <cjk>
        "8F73": 0x5CFB, // <cjk>
        "8F74": 0x6625, // <cjk>
        "8F75": 0x77AC, // <cjk>
        "8F76": 0x7AE3, // <cjk>
        "8F77": 0x821C, // <cjk>
        "8F78": 0x99FF, // <cjk>
        "8F79": 0x51C6, // <cjk>
        "8F7A": 0x5FAA, // <cjk>
        "8F7B": 0x65EC, // <cjk>
        "8F7C": 0x696F, // <cjk>
        "8F7D": 0x6B89, // <cjk>
        "8F7E": 0x6DF3, // <cjk>
        "8F80": 0x6E96, // <cjk>
        "8F81": 0x6F64, // <cjk>
        "8F82": 0x76FE, // <cjk>
        "8F83": 0x7D14, // <cjk>
        "8F84": 0x5DE1, // <cjk>
        "8F85": 0x9075, // <cjk>
        "8F86": 0x9187, // <cjk>
        "8F87": 0x9806, // <cjk>
        "8F88": 0x51E6, // <cjk>
        "8F89": 0x521D, // <cjk>
        "8F8A": 0x6240, // <cjk>
        "8F8B": 0x6691, // <cjk>
        "8F8C": 0x66D9, // <cjk>
        "8F8D": 0x6E1A, // <cjk>
        "8F8E": 0x5EB6, // <cjk>
        "8F8F": 0x7DD2, // <cjk>
        "8F90": 0x7F72, // <cjk>
        "8F91": 0x66F8, // <cjk>
        "8F92": 0x85AF, // <cjk>
        "8F93": 0x85F7, // <cjk>
        "8F94": 0x8AF8, // <cjk>
        "8F95": 0x52A9, // <cjk>
        "8F96": 0x53D9, // <cjk>
        "8F97": 0x5973, // <cjk>
        "8F98": 0x5E8F, // <cjk>
        "8F99": 0x5F90, // <cjk>
        "8F9A": 0x6055, // <cjk>
        "8F9B": 0x92E4, // <cjk>
        "8F9C": 0x9664, // <cjk>
        "8F9D": 0x50B7, // <cjk>
        "8F9E": 0x511F, // <cjk>
        "8F9F": 0x52DD, // <cjk>
        "8FA0": 0x5320, // <cjk>
        "8FA1": 0x5347, // <cjk>
        "8FA2": 0x53EC, // <cjk>
        "8FA3": 0x54E8, // <cjk>
        "8FA4": 0x5546, // <cjk>
        "8FA5": 0x5531, // <cjk>
        "8FA6": 0x5617, // <cjk>
        "8FA7": 0x5968, // <cjk>
        "8FA8": 0x59BE, // <cjk>
        "8FA9": 0x5A3C, // <cjk>
        "8FAA": 0x5BB5, // <cjk>
        "8FAB": 0x5C06, // <cjk>
        "8FAC": 0x5C0F, // <cjk>
        "8FAD": 0x5C11, // <cjk>
        "8FAE": 0x5C1A, // <cjk>
        "8FAF": 0x5E84, // <cjk>
        "8FB0": 0x5E8A, // <cjk>
        "8FB1": 0x5EE0, // <cjk>
        "8FB2": 0x5F70, // <cjk>
        "8FB3": 0x627F, // <cjk>
        "8FB4": 0x6284, // <cjk>
        "8FB5": 0x62DB, // <cjk>
        "8FB6": 0x638C, // <cjk>
        "8FB7": 0x6377, // <cjk>
        "8FB8": 0x6607, // <cjk>
        "8FB9": 0x660C, // <cjk>
        "8FBA": 0x662D, // <cjk>
        "8FBB": 0x6676, // <cjk>
        "8FBC": 0x677E, // <cjk>
        "8FBD": 0x68A2, // <cjk>
        "8FBE": 0x6A1F, // <cjk>
        "8FBF": 0x6A35, // <cjk>
        "8FC0": 0x6CBC, // <cjk>
        "8FC1": 0x6D88, // <cjk>
        "8FC2": 0x6E09, // <cjk>
        "8FC3": 0x6E58, // <cjk>
        "8FC4": 0x713C, // <cjk>
        "8FC5": 0x7126, // <cjk>
        "8FC6": 0x7167, // <cjk>
        "8FC7": 0x75C7, // <cjk>
        "8FC8": 0x7701, // <cjk>
        "8FC9": 0x785D, // <cjk>
        "8FCA": 0x7901, // <cjk>
        "8FCB": 0x7965, // <cjk>
        "8FCC": 0x79F0, // <cjk>
        "8FCD": 0x7AE0, // <cjk>
        "8FCE": 0x7B11, // <cjk>
        "8FCF": 0x7CA7, // <cjk>
        "8FD0": 0x7D39, // <cjk>
        "8FD1": 0x8096, // <cjk>
        "8FD2": 0x83D6, // <cjk>
        "8FD3": 0x848B, // <cjk>
        "8FD4": 0x8549, // <cjk>
        "8FD5": 0x885D, // <cjk>
        "8FD6": 0x88F3, // <cjk>
        "8FD7": 0x8A1F, // <cjk>
        "8FD8": 0x8A3C, // <cjk>
        "8FD9": 0x8A54, // <cjk>
        "8FDA": 0x8A73, // <cjk>
        "8FDB": 0x8C61, // <cjk>
        "8FDC": 0x8CDE, // <cjk>
        "8FDD": 0x91A4, // <cjk>
        "8FDE": 0x9266, // <cjk>
        "8FDF": 0x937E, // <cjk>
        "8FE0": 0x9418, // <cjk>
        "8FE1": 0x969C, // <cjk>
        "8FE2": 0x9798, // <cjk>
        "8FE3": 0x4E0A, // <cjk>
        "8FE4": 0x4E08, // <cjk>
        "8FE5": 0x4E1E, // <cjk>
        "8FE6": 0x4E57, // <cjk>
        "8FE7": 0x5197, // <cjk>
        "8FE8": 0x5270, // <cjk>
        "8FE9": 0x57CE, // <cjk>
        "8FEA": 0x5834, // <cjk>
        "8FEB": 0x58CC, // <cjk>
        "8FEC": 0x5B22, // <cjk>
        "8FED": 0x5E38, // <cjk>
        "8FEE": 0x60C5, // <cjk>
        "8FEF": 0x64FE, // <cjk>
        "8FF0": 0x6761, // <cjk>
        "8FF1": 0x6756, // <cjk>
        "8FF2": 0x6D44, // <cjk>
        "8FF3": 0x72B6, // <cjk>
        "8FF4": 0x7573, // <cjk>
        "8FF5": 0x7A63, // <cjk>
        "8FF6": 0x84B8, // <cjk>
        "8FF7": 0x8B72, // <cjk>
        "8FF8": 0x91B8, // <cjk>
        "8FF9": 0x9320, // <cjk>
        "8FFA": 0x5631, // <cjk>
        "8FFB": 0x57F4, // <cjk>
        "8FFC": 0x98FE, // <cjk>
        "9040": 0x62ED, // <cjk>
        "9041": 0x690D, // <cjk>
        "9042": 0x6B96, // <cjk>
        "9043": 0x71ED, // <cjk>
        "9044": 0x7E54, // <cjk>
        "9045": 0x8077, // <cjk>
        "9046": 0x8272, // <cjk>
        "9047": 0x89E6, // <cjk>
        "9048": 0x98DF, // <cjk>
        "9049": 0x8755, // <cjk>
        "904A": 0x8FB1, // <cjk>
        "904B": 0x5C3B, // <cjk>
        "904C": 0x4F38, // <cjk>
        "904D": 0x4FE1, // <cjk>
        "904E": 0x4FB5, // <cjk>
        "904F": 0x5507, // <cjk>
        "9050": 0x5A20, // <cjk>
        "9051": 0x5BDD, // <cjk>
        "9052": 0x5BE9, // <cjk>
        "9053": 0x5FC3, // <cjk>
        "9054": 0x614E, // <cjk>
        "9055": 0x632F, // <cjk>
        "9056": 0x65B0, // <cjk>
        "9057": 0x664B, // <cjk>
        "9058": 0x68EE, // <cjk>
        "9059": 0x699B, // <cjk>
        "905A": 0x6D78, // <cjk>
        "905C": 0x7533, // <cjk>
        "905D": 0x75B9, // <cjk>
        "905E": 0x771F, // <cjk>
        "905F": 0x795E, // <cjk>
        "9060": 0x79E6, // <cjk>
        "9061": 0x7D33, // <cjk>
        "9062": 0x81E3, // <cjk>
        "9063": 0x82AF, // <cjk>
        "9064": 0x85AA, // <cjk>
        "9065": 0x89AA, // <cjk>
        "9066": 0x8A3A, // <cjk>
        "9067": 0x8EAB, // <cjk>
        "9068": 0x8F9B, // <cjk>
        "9069": 0x9032, // <cjk>
        "906A": 0x91DD, // <cjk>
        "906B": 0x9707, // <cjk>
        "906C": 0x4EBA, // <cjk>
        "906D": 0x4EC1, // <cjk>
        "906E": 0x5203, // <cjk>
        "906F": 0x5875, // <cjk>
        "9070": 0x58EC, // <cjk>
        "9071": 0x5C0B, // <cjk>
        "9072": 0x751A, // <cjk>
        "9073": 0x5C3D, // <cjk>
        "9074": 0x814E, // <cjk>
        "9075": 0x8A0A, // <cjk>
        "9076": 0x8FC5, // <cjk>
        "9077": 0x9663, // <cjk>
        "9078": 0x976D, // <cjk>
        "9079": 0x7B25, // <cjk>
        "907A": 0x8ACF, // <cjk>
        "907B": 0x9808, // <cjk>
        "907C": 0x9162, // <cjk>
        "907D": 0x56F3, // <cjk>
        "907E": 0x53A8, // <cjk>
        "9080": 0x9017, // <cjk>
        "9081": 0x5439, // <cjk>
        "9082": 0x5782, // <cjk>
        "9083": 0x5E25, // <cjk>
        "9084": 0x63A8, // <cjk>
        "9085": 0x6C34, // <cjk>
        "9086": 0x708A, // <cjk>
        "9087": 0x7761, // <cjk>
        "9088": 0x7C8B, // <cjk>
        "9089": 0x7FE0, // <cjk>
        "908A": 0x8870, // <cjk>
        "908B": 0x9042, // <cjk>
        "908C": 0x9154, // <cjk>
        "908D": 0x9310, // <cjk>
        "908E": 0x9318, // <cjk>
        "908F": 0x968F, // <cjk>
        "9090": 0x745E, // <cjk>
        "9091": 0x9AC4, // <cjk>
        "9092": 0x5D07, // <cjk>
        "9093": 0x5D69, // <cjk>
        "9094": 0x6570, // <cjk>
        "9095": 0x67A2, // <cjk>
        "9096": 0x8DA8, // <cjk>
        "9097": 0x96DB, // <cjk>
        "9098": 0x636E, // <cjk>
        "9099": 0x6749, // <cjk>
        "909A": 0x6919, // <cjk>
        "909B": 0x83C5, // <cjk>
        "909C": 0x9817, // <cjk>
        "909D": 0x96C0, // <cjk>
        "909E": 0x88FE, // <cjk>
        "909F": 0x6F84, // <cjk>
        "90A0": 0x647A, // <cjk>
        "90A1": 0x5BF8, // <cjk>
        "90A2": 0x4E16, // <cjk>
        "90A3": 0x702C, // <cjk>
        "90A4": 0x755D, // <cjk>
        "90A5": 0x662F, // <cjk>
        "90A6": 0x51C4, // <cjk>
        "90A7": 0x5236, // <cjk>
        "90A8": 0x52E2, // <cjk>
        "90A9": 0x59D3, // <cjk>
        "90AA": 0x5F81, // <cjk>
        "90AB": 0x6027, // <cjk>
        "90AC": 0x6210, // <cjk>
        "90AD": 0x653F, // <cjk>
        "90AE": 0x6574, // <cjk>
        "90AF": 0x661F, // <cjk>
        "90B0": 0x6674, // <cjk>
        "90B1": 0x68F2, // <cjk>
        "90B2": 0x6816, // <cjk>
        "90B3": 0x6B63, // <cjk>
        "90B4": 0x6E05, // <cjk>
        "90B5": 0x7272, // <cjk>
        "90B6": 0x751F, // <cjk>
        "90B7": 0x76DB, // <cjk>
        "90B8": 0x7CBE, // <cjk>
        "90B9": 0x8056, // <cjk>
        "90BA": 0x58F0, // <cjk>
        "90BB": 0x88FD, // <cjk>
        "90BC": 0x897F, // <cjk>
        "90BD": 0x8AA0, // <cjk>
        "90BE": 0x8A93, // <cjk>
        "90BF": 0x8ACB, // <cjk>
        "90C0": 0x901D, // <cjk>
        "90C1": 0x9192, // <cjk>
        "90C2": 0x9752, // <cjk>
        "90C3": 0x9759, // <cjk>
        "90C4": 0x6589, // <cjk>
        "90C5": 0x7A0E, // <cjk>
        "90C6": 0x8106, // <cjk>
        "90C7": 0x96BB, // <cjk>
        "90C8": 0x5E2D, // <cjk>
        "90C9": 0x60DC, // <cjk>
        "90CA": 0x621A, // <cjk>
        "90CB": 0x65A5, // <cjk>
        "90CC": 0x6614, // <cjk>
        "90CD": 0x6790, // <cjk>
        "90CE": 0x77F3, // <cjk>
        "90CF": 0x7A4D, // <cjk>
        "90D0": 0x7C4D, // <cjk>
        "90D1": 0x7E3E, // <cjk>
        "90D2": 0x810A, // <cjk>
        "90D3": 0x8CAC, // <cjk>
        "90D4": 0x8D64, // <cjk>
        "90D5": 0x8DE1, // <cjk>
        "90D6": 0x8E5F, // <cjk>
        "90D7": 0x78A9, // <cjk>
        "90D8": 0x5207, // <cjk>
        "90D9": 0x62D9, // <cjk>
        "90DA": 0x63A5, // <cjk>
        "90DB": 0x6442, // <cjk>
        "90DC": 0x6298, // <cjk>
        "90DD": 0x8A2D, // <cjk>
        "90DE": 0x7A83, // <cjk>
        "90DF": 0x7BC0, // <cjk>
        "90E0": 0x8AAC, // <cjk>
        "90E1": 0x96EA, // <cjk>
        "90E2": 0x7D76, // <cjk>
        "90E3": 0x820C, // <cjk>
        "90E4": 0x8749, // <cjk>
        "90E5": 0x4ED9, // <cjk>
        "90E6": 0x5148, // <cjk>
        "90E7": 0x5343, // <cjk>
        "90E8": 0x5360, // <cjk>
        "90E9": 0x5BA3, // <cjk>
        "90EA": 0x5C02, // <cjk>
        "90EB": 0x5C16, // <cjk>
        "90EC": 0x5DDD, // <cjk>
        "90ED": 0x6226, // <cjk>
        "90EE": 0x6247, // <cjk>
        "90EF": 0x64B0, // <cjk>
        "90F0": 0x6813, // <cjk>
        "90F1": 0x6834, // <cjk>
        "90F2": 0x6CC9, // <cjk>
        "90F3": 0x6D45, // <cjk>
        "90F4": 0x6D17, // <cjk>
        "90F5": 0x67D3, // <cjk>
        "90F6": 0x6F5C, // <cjk>
        "90F7": 0x714E, // <cjk>
        "90F8": 0x717D, // <cjk>
        "90F9": 0x65CB, // <cjk>
        "90FA": 0x7A7F, // <cjk>
        "90FB": 0x7BAD, // <cjk>
        "90FC": 0x7DDA, // <cjk>
        "9140": 0x7E4A, // <cjk>
        "9141": 0x7FA8, // <cjk>
        "9142": 0x817A, // <cjk>
        "9143": 0x821B, // <cjk>
        "9144": 0x8239, // <cjk>
        "9145": 0x85A6, // <cjk>
        "9146": 0x8A6E, // <cjk>
        "9147": 0x8CCE, // <cjk>
        "9148": 0x8DF5, // <cjk>
        "9149": 0x9078, // <cjk>
        "914A": 0x9077, // <cjk>
        "914B": 0x92AD, // <cjk>
        "914C": 0x9291, // <cjk>
        "914D": 0x9583, // <cjk>
        "914E": 0x9BAE, // <cjk>
        "914F": 0x524D, // <cjk>
        "9150": 0x5584, // <cjk>
        "9151": 0x6F38, // <cjk>
        "9152": 0x7136, // <cjk>
        "9153": 0x5168, // <cjk>
        "9154": 0x7985, // <cjk>
        "9155": 0x7E55, // <cjk>
        "9156": 0x81B3, // <cjk>
        "9157": 0x7CCE, // <cjk>
        "9158": 0x564C, // <cjk>
        "9159": 0x5851, // <cjk>
        "915A": 0x5CA8, // <cjk>
        "915C": 0x66FE, // <cjk>
        "915D": 0x66FD, // <cjk>
        "915E": 0x695A, // <cjk>
        "915F": 0x72D9, // <cjk>
        "9160": 0x758F, // <cjk>
        "9161": 0x758E, // <cjk>
        "9162": 0x790E, // <cjk>
        "9163": 0x7956, // <cjk>
        "9164": 0x79DF, // <cjk>
        "9165": 0x7C97, // <cjk>
        "9166": 0x7D20, // <cjk>
        "9167": 0x7D44, // <cjk>
        "9168": 0x8607, // <cjk>
        "9169": 0x8A34, // <cjk>
        "916A": 0x963B, // <cjk>
        "916B": 0x9061, // <cjk>
        "916C": 0x9F20, // <cjk>
        "916D": 0x50E7, // <cjk>
        "916E": 0x5275, // <cjk>
        "916F": 0x53CC, // <cjk>
        "9170": 0x53E2, // <cjk>
        "9171": 0x5009, // <cjk>
        "9172": 0x55AA, // <cjk>
        "9173": 0x58EE, // <cjk>
        "9174": 0x594F, // <cjk>
        "9175": 0x723D, // <cjk>
        "9176": 0x5B8B, // <cjk>
        "9177": 0x5C64, // <cjk>
        "9178": 0x531D, // <cjk>
        "9179": 0x60E3, // <cjk>
        "917A": 0x60F3, // <cjk>
        "917B": 0x635C, // <cjk>
        "917C": 0x6383, // <cjk>
        "917D": 0x633F, // <cjk>
        "917E": 0x63BB, // <cjk>
        "9180": 0x64CD, // <cjk>
        "9181": 0x65E9, // <cjk>
        "9182": 0x66F9, // <cjk>
        "9183": 0x5DE3, // <cjk>
        "9184": 0x69CD, // <cjk>
        "9185": 0x69FD, // <cjk>
        "9186": 0x6F15, // <cjk>
        "9187": 0x71E5, // <cjk>
        "9188": 0x4E89, // <cjk>
        "9189": 0x75E9, // <cjk>
        "918A": 0x76F8, // <cjk>
        "918B": 0x7A93, // <cjk>
        "918C": 0x7CDF, // <cjk>
        "918D": 0x7DCF, // <cjk>
        "918E": 0x7D9C, // <cjk>
        "918F": 0x8061, // <cjk>
        "9190": 0x8349, // <cjk>
        "9191": 0x8358, // <cjk>
        "9192": 0x846C, // <cjk>
        "9193": 0x84BC, // <cjk>
        "9194": 0x85FB, // <cjk>
        "9195": 0x88C5, // <cjk>
        "9196": 0x8D70, // <cjk>
        "9197": 0x9001, // <cjk>
        "9198": 0x906D, // <cjk>
        "9199": 0x9397, // <cjk>
        "919A": 0x971C, // <cjk>
        "919B": 0x9A12, // <cjk>
        "919C": 0x50CF, // <cjk>
        "919D": 0x5897, // <cjk>
        "919E": 0x618E, // <cjk>
        "919F": 0x81D3, // <cjk>
        "91A0": 0x8535, // <cjk>
        "91A1": 0x8D08, // <cjk>
        "91A2": 0x9020, // <cjk>
        "91A3": 0x4FC3, // <cjk>
        "91A4": 0x5074, // <cjk>
        "91A5": 0x5247, // <cjk>
        "91A6": 0x5373, // <cjk>
        "91A7": 0x606F, // <cjk>
        "91A8": 0x6349, // <cjk>
        "91A9": 0x675F, // <cjk>
        "91AA": 0x6E2C, // <cjk>
        "91AB": 0x8DB3, // <cjk>
        "91AC": 0x901F, // <cjk>
        "91AD": 0x4FD7, // <cjk>
        "91AE": 0x5C5E, // <cjk>
        "91AF": 0x8CCA, // <cjk>
        "91B0": 0x65CF, // <cjk>
        "91B1": 0x7D9A, // <cjk>
        "91B2": 0x5352, // <cjk>
        "91B3": 0x8896, // <cjk>
        "91B4": 0x5176, // <cjk>
        "91B5": 0x63C3, // <cjk>
        "91B6": 0x5B58, // <cjk>
        "91B7": 0x5B6B, // <cjk>
        "91B8": 0x5C0A, // <cjk>
        "91B9": 0x640D, // <cjk>
        "91BA": 0x6751, // <cjk>
        "91BB": 0x905C, // <cjk>
        "91BC": 0x4ED6, // <cjk>
        "91BD": 0x591A, // <cjk>
        "91BE": 0x592A, // <cjk>
        "91BF": 0x6C70, // <cjk>
        "91C0": 0x8A51, // <cjk>
        "91C1": 0x553E, // <cjk>
        "91C2": 0x5815, // <cjk>
        "91C3": 0x59A5, // <cjk>
        "91C4": 0x60F0, // <cjk>
        "91C5": 0x6253, // <cjk>
        "91C6": 0x67C1, // <cjk>
        "91C7": 0x8235, // <cjk>
        "91C8": 0x6955, // <cjk>
        "91C9": 0x9640, // <cjk>
        "91CA": 0x99C4, // <cjk>
        "91CB": 0x9A28, // <cjk>
        "91CC": 0x4F53, // <cjk>
        "91CD": 0x5806, // <cjk>
        "91CE": 0x5BFE, // <cjk>
        "91CF": 0x8010, // <cjk>
        "91D0": 0x5CB1, // <cjk>
        "91D1": 0x5E2F, // <cjk>
        "91D2": 0x5F85, // <cjk>
        "91D3": 0x6020, // <cjk>
        "91D4": 0x614B, // <cjk>
        "91D5": 0x6234, // <cjk>
        "91D6": 0x66FF, // <cjk>
        "91D7": 0x6CF0, // <cjk>
        "91D8": 0x6EDE, // <cjk>
        "91D9": 0x80CE, // <cjk>
        "91DA": 0x817F, // <cjk>
        "91DB": 0x82D4, // <cjk>
        "91DC": 0x888B, // <cjk>
        "91DD": 0x8CB8, // <cjk>
        "91DE": 0x9000, // <cjk>
        "91DF": 0x902E, // <cjk>
        "91E0": 0x968A, // <cjk>
        "91E1": 0x9EDB, // <cjk>
        "91E2": 0x9BDB, // <cjk>
        "91E3": 0x4EE3, // <cjk>
        "91E4": 0x53F0, // <cjk>
        "91E5": 0x5927, // <cjk>
        "91E6": 0x7B2C, // <cjk>
        "91E7": 0x918D, // <cjk>
        "91E8": 0x984C, // <cjk>
        "91E9": 0x9DF9, // <cjk>
        "91EA": 0x6EDD, // <cjk>
        "91EB": 0x7027, // <cjk>
        "91EC": 0x5353, // <cjk>
        "91ED": 0x5544, // <cjk>
        "91EE": 0x5B85, // <cjk>
        "91EF": 0x6258, // <cjk>
        "91F0": 0x629E, // <cjk>
        "91F1": 0x62D3, // <cjk>
        "91F2": 0x6CA2, // <cjk>
        "91F3": 0x6FEF, // <cjk>
        "91F4": 0x7422, // <cjk>
        "91F5": 0x8A17, // <cjk>
        "91F6": 0x9438, // <cjk>
        "91F7": 0x6FC1, // <cjk>
        "91F8": 0x8AFE, // <cjk>
        "91F9": 0x8338, // <cjk>
        "91FA": 0x51E7, // <cjk>
        "91FB": 0x86F8, // <cjk>
        "91FC": 0x53EA, // <cjk>
        "9240": 0x53E9, // <cjk>
        "9241": 0x4F46, // <cjk>
        "9242": 0x9054, // <cjk>
        "9243": 0x8FB0, // <cjk>
        "9244": 0x596A, // <cjk>
        "9245": 0x8131, // <cjk>
        "9246": 0x5DFD, // <cjk>
        "9247": 0x7AEA, // <cjk>
        "9248": 0x8FBF, // <cjk>
        "9249": 0x68DA, // <cjk>
        "924A": 0x8C37, // <cjk>
        "924B": 0x72F8, // <cjk>
        "924C": 0x9C48, // <cjk>
        "924D": 0x6A3D, // <cjk>
        "924E": 0x8AB0, // <cjk>
        "924F": 0x4E39, // <cjk>
        "9250": 0x5358, // <cjk>
        "9251": 0x5606, // <cjk>
        "9252": 0x5766, // <cjk>
        "9253": 0x62C5, // <cjk>
        "9254": 0x63A2, // <cjk>
        "9255": 0x65E6, // <cjk>
        "9256": 0x6B4E, // <cjk>
        "9257": 0x6DE1, // <cjk>
        "9258": 0x6E5B, // <cjk>
        "9259": 0x70AD, // <cjk>
        "925A": 0x77ED, // <cjk>
        "925C": 0x7BAA, // <cjk>
        "925D": 0x7DBB, // <cjk>
        "925E": 0x803D, // <cjk>
        "925F": 0x80C6, // <cjk>
        "9260": 0x86CB, // <cjk>
        "9261": 0x8A95, // <cjk>
        "9262": 0x935B, // <cjk>
        "9263": 0x56E3, // <cjk>
        "9264": 0x58C7, // <cjk>
        "9265": 0x5F3E, // <cjk>
        "9266": 0x65AD, // <cjk>
        "9267": 0x6696, // <cjk>
        "9268": 0x6A80, // <cjk>
        "9269": 0x6BB5, // <cjk>
        "926A": 0x7537, // <cjk>
        "926B": 0x8AC7, // <cjk>
        "926C": 0x5024, // <cjk>
        "926D": 0x77E5, // <cjk>
        "926E": 0x5730, // <cjk>
        "926F": 0x5F1B, // <cjk>
        "9270": 0x6065, // <cjk>
        "9271": 0x667A, // <cjk>
        "9272": 0x6C60, // <cjk>
        "9273": 0x75F4, // <cjk>
        "9274": 0x7A1A, // <cjk>
        "9275": 0x7F6E, // <cjk>
        "9276": 0x81F4, // <cjk>
        "9277": 0x8718, // <cjk>
        "9278": 0x9045, // <cjk>
        "9279": 0x99B3, // <cjk>
        "927A": 0x7BC9, // <cjk>
        "927B": 0x755C, // <cjk>
        "927C": 0x7AF9, // <cjk>
        "927D": 0x7B51, // <cjk>
        "927E": 0x84C4, // <cjk>
        "9280": 0x9010, // <cjk>
        "9281": 0x79E9, // <cjk>
        "9282": 0x7A92, // <cjk>
        "9283": 0x8336, // <cjk>
        "9284": 0x5AE1, // <cjk>
        "9285": 0x7740, // <cjk>
        "9286": 0x4E2D, // <cjk>
        "9287": 0x4EF2, // <cjk>
        "9288": 0x5B99, // <cjk>
        "9289": 0x5FE0, // <cjk>
        "928A": 0x62BD, // <cjk>
        "928B": 0x663C, // <cjk>
        "928C": 0x67F1, // <cjk>
        "928D": 0x6CE8, // <cjk>
        "928E": 0x866B, // <cjk>
        "928F": 0x8877, // <cjk>
        "9290": 0x8A3B, // <cjk>
        "9291": 0x914E, // <cjk>
        "9292": 0x92F3, // <cjk>
        "9293": 0x99D0, // <cjk>
        "9294": 0x6A17, // <cjk>
        "9295": 0x7026, // <cjk>
        "9296": 0x732A, // <cjk>
        "9297": 0x82E7, // <cjk>
        "9298": 0x8457, // <cjk>
        "9299": 0x8CAF, // <cjk>
        "929A": 0x4E01, // <cjk>
        "929B": 0x5146, // <cjk>
        "929C": 0x51CB, // <cjk>
        "929D": 0x558B, // <cjk>
        "929E": 0x5BF5, // <cjk>
        "929F": 0x5E16, // <cjk>
        "92A0": 0x5E33, // <cjk>
        "92A1": 0x5E81, // <cjk>
        "92A2": 0x5F14, // <cjk>
        "92A3": 0x5F35, // <cjk>
        "92A4": 0x5F6B, // <cjk>
        "92A5": 0x5FB4, // <cjk>
        "92A6": 0x61F2, // <cjk>
        "92A7": 0x6311, // <cjk>
        "92A8": 0x66A2, // <cjk>
        "92A9": 0x671D, // <cjk>
        "92AA": 0x6F6E, // <cjk>
        "92AB": 0x7252, // <cjk>
        "92AC": 0x753A, // <cjk>
        "92AD": 0x773A, // <cjk>
        "92AE": 0x8074, // <cjk>
        "92AF": 0x8139, // <cjk>
        "92B0": 0x8178, // <cjk>
        "92B1": 0x8776, // <cjk>
        "92B2": 0x8ABF, // <cjk>
        "92B3": 0x8ADC, // <cjk>
        "92B4": 0x8D85, // <cjk>
        "92B5": 0x8DF3, // <cjk>
        "92B6": 0x929A, // <cjk>
        "92B7": 0x9577, // <cjk>
        "92B8": 0x9802, // <cjk>
        "92B9": 0x9CE5, // <cjk>
        "92BA": 0x52C5, // <cjk>
        "92BB": 0x6357, // <cjk>
        "92BC": 0x76F4, // <cjk>
        "92BD": 0x6715, // <cjk>
        "92BE": 0x6C88, // <cjk>
        "92BF": 0x73CD, // <cjk>
        "92C0": 0x8CC3, // <cjk>
        "92C1": 0x93AE, // <cjk>
        "92C2": 0x9673, // <cjk>
        "92C3": 0x6D25, // <cjk>
        "92C4": 0x589C, // <cjk>
        "92C5": 0x690E, // <cjk>
        "92C6": 0x69CC, // <cjk>
        "92C7": 0x8FFD, // <cjk>
        "92C8": 0x939A, // <cjk>
        "92C9": 0x75DB, // <cjk>
        "92CA": 0x901A, // <cjk>
        "92CB": 0x585A, // <cjk>
        "92CC": 0x6802, // <cjk>
        "92CD": 0x63B4, // <cjk>
        "92CE": 0x69FB, // <cjk>
        "92CF": 0x4F43, // <cjk>
        "92D0": 0x6F2C, // <cjk>
        "92D1": 0x67D8, // <cjk>
        "92D2": 0x8FBB, // <cjk>
        "92D3": 0x8526, // <cjk>
        "92D4": 0x7DB4, // <cjk>
        "92D5": 0x9354, // <cjk>
        "92D6": 0x693F, // <cjk>
        "92D7": 0x6F70, // <cjk>
        "92D8": 0x576A, // <cjk>
        "92D9": 0x58F7, // <cjk>
        "92DA": 0x5B2C, // <cjk>
        "92DB": 0x7D2C, // <cjk>
        "92DC": 0x722A, // <cjk>
        "92DD": 0x540A, // <cjk>
        "92DE": 0x91E3, // <cjk>
        "92DF": 0x9DB4, // <cjk>
        "92E0": 0x4EAD, // <cjk>
        "92E1": 0x4F4E, // <cjk>
        "92E2": 0x505C, // <cjk>
        "92E3": 0x5075, // <cjk>
        "92E4": 0x5243, // <cjk>
        "92E5": 0x8C9E, // <cjk>
        "92E6": 0x5448, // <cjk>
        "92E7": 0x5824, // <cjk>
        "92E8": 0x5B9A, // <cjk>
        "92E9": 0x5E1D, // <cjk>
        "92EA": 0x5E95, // <cjk>
        "92EB": 0x5EAD, // <cjk>
        "92EC": 0x5EF7, // <cjk>
        "92ED": 0x5F1F, // <cjk>
        "92EE": 0x608C, // <cjk>
        "92EF": 0x62B5, // <cjk>
        "92F0": 0x633A, // <cjk>
        "92F1": 0x63D0, // <cjk>
        "92F2": 0x68AF, // <cjk>
        "92F3": 0x6C40, // <cjk>
        "92F4": 0x7887, // <cjk>
        "92F5": 0x798E, // <cjk>
        "92F6": 0x7A0B, // <cjk>
        "92F7": 0x7DE0, // <cjk>
        "92F8": 0x8247, // <cjk>
        "92F9": 0x8A02, // <cjk>
        "92FA": 0x8AE6, // <cjk>
        "92FB": 0x8E44, // <cjk>
        "92FC": 0x9013, // <cjk>
        "9340": 0x90B8, // <cjk>
        "9341": 0x912D, // <cjk>
        "9342": 0x91D8, // <cjk>
        "9343": 0x9F0E, // <cjk>
        "9344": 0x6CE5, // <cjk>
        "9345": 0x6458, // <cjk>
        "9346": 0x64E2, // <cjk>
        "9347": 0x6575, // <cjk>
        "9348": 0x6EF4, // <cjk>
        "9349": 0x7684, // <cjk>
        "934A": 0x7B1B, // <cjk>
        "934B": 0x9069, // <cjk>
        "934C": 0x93D1, // <cjk>
        "934D": 0x6EBA, // <cjk>
        "934E": 0x54F2, // <cjk>
        "934F": 0x5FB9, // <cjk>
        "9350": 0x64A4, // <cjk>
        "9351": 0x8F4D, // <cjk>
        "9352": 0x8FED, // <cjk>
        "9353": 0x9244, // <cjk>
        "9354": 0x5178, // <cjk>
        "9355": 0x586B, // <cjk>
        "9356": 0x5929, // <cjk>
        "9357": 0x5C55, // <cjk>
        "9358": 0x5E97, // <cjk>
        "9359": 0x6DFB, // <cjk>
        "935A": 0x7E8F, // <cjk>
        "935C": 0x8CBC, // <cjk>
        "935D": 0x8EE2, // <cjk>
        "935E": 0x985B, // <cjk>
        "935F": 0x70B9, // <cjk>
        "9360": 0x4F1D, // <cjk>
        "9361": 0x6BBF, // <cjk>
        "9362": 0x6FB1, // <cjk>
        "9363": 0x7530, // <cjk>
        "9364": 0x96FB, // <cjk>
        "9365": 0x514E, // <cjk>
        "9366": 0x5410, // <cjk>
        "9367": 0x5835, // <cjk>
        "9368": 0x5857, // <cjk>
        "9369": 0x59AC, // <cjk>
        "936A": 0x5C60, // <cjk>
        "936B": 0x5F92, // <cjk>
        "936C": 0x6597, // <cjk>
        "936D": 0x675C, // <cjk>
        "936E": 0x6E21, // <cjk>
        "936F": 0x767B, // <cjk>
        "9370": 0x83DF, // <cjk>
        "9371": 0x8CED, // <cjk>
        "9372": 0x9014, // <cjk>
        "9373": 0x90FD, // <cjk>
        "9374": 0x934D, // <cjk>
        "9375": 0x7825, // <cjk>
        "9376": 0x783A, // <cjk>
        "9377": 0x52AA, // <cjk>
        "9378": 0x5EA6, // <cjk>
        "9379": 0x571F, // <cjk>
        "937A": 0x5974, // <cjk>
        "937B": 0x6012, // <cjk>
        "937C": 0x5012, // <cjk>
        "937D": 0x515A, // <cjk>
        "937E": 0x51AC, // <cjk>
        "9380": 0x51CD, // <cjk>
        "9381": 0x5200, // <cjk>
        "9382": 0x5510, // <cjk>
        "9383": 0x5854, // <cjk>
        "9384": 0x5858, // <cjk>
        "9385": 0x5957, // <cjk>
        "9386": 0x5B95, // <cjk>
        "9387": 0x5CF6, // <cjk>
        "9388": 0x5D8B, // <cjk>
        "9389": 0x60BC, // <cjk>
        "938A": 0x6295, // <cjk>
        "938B": 0x642D, // <cjk>
        "938C": 0x6771, // <cjk>
        "938D": 0x6843, // <cjk>
        "938E": 0x68BC, // <cjk>
        "938F": 0x68DF, // <cjk>
        "9390": 0x76D7, // <cjk>
        "9391": 0x6DD8, // <cjk>
        "9392": 0x6E6F, // <cjk>
        "9393": 0x6D9B, // <cjk>
        "9394": 0x706F, // <cjk>
        "9395": 0x71C8, // <cjk>
        "9396": 0x5F53, // <cjk>
        "9397": 0x75D8, // <cjk>
        "9398": 0x7977, // <cjk>
        "9399": 0x7B49, // <cjk>
        "939A": 0x7B54, // <cjk>
        "939B": 0x7B52, // <cjk>
        "939C": 0x7CD6, // <cjk>
        "939D": 0x7D71, // <cjk>
        "939E": 0x5230, // <cjk>
        "939F": 0x8463, // <cjk>
        "93A0": 0x8569, // <cjk>
        "93A1": 0x85E4, // <cjk>
        "93A2": 0x8A0E, // <cjk>
        "93A3": 0x8B04, // <cjk>
        "93A4": 0x8C46, // <cjk>
        "93A5": 0x8E0F, // <cjk>
        "93A6": 0x9003, // <cjk>
        "93A7": 0x900F, // <cjk>
        "93A8": 0x9419, // <cjk>
        "93A9": 0x9676, // <cjk>
        "93AA": 0x982D, // <cjk>
        "93AB": 0x9A30, // <cjk>
        "93AC": 0x95D8, // <cjk>
        "93AD": 0x50CD, // <cjk>
        "93AE": 0x52D5, // <cjk>
        "93AF": 0x540C, // <cjk>
        "93B0": 0x5802, // <cjk>
        "93B1": 0x5C0E, // <cjk>
        "93B2": 0x61A7, // <cjk>
        "93B3": 0x649E, // <cjk>
        "93B4": 0x6D1E, // <cjk>
        "93B5": 0x77B3, // <cjk>
        "93B6": 0x7AE5, // <cjk>
        "93B7": 0x80F4, // <cjk>
        "93B8": 0x8404, // <cjk>
        "93B9": 0x9053, // <cjk>
        "93BA": 0x9285, // <cjk>
        "93BB": 0x5CE0, // <cjk>
        "93BC": 0x9D07, // <cjk>
        "93BD": 0x533F, // <cjk>
        "93BE": 0x5F97, // <cjk>
        "93BF": 0x5FB3, // <cjk>
        "93C0": 0x6D9C, // <cjk>
        "93C1": 0x7279, // <cjk>
        "93C2": 0x7763, // <cjk>
        "93C3": 0x79BF, // <cjk>
        "93C4": 0x7BE4, // <cjk>
        "93C5": 0x6BD2, // <cjk>
        "93C6": 0x72EC, // <cjk>
        "93C7": 0x8AAD, // <cjk>
        "93C8": 0x6803, // <cjk>
        "93C9": 0x6A61, // <cjk>
        "93CA": 0x51F8, // <cjk>
        "93CB": 0x7A81, // <cjk>
        "93CC": 0x6934, // <cjk>
        "93CD": 0x5C4A, // <cjk>
        "93CE": 0x9CF6, // <cjk>
        "93CF": 0x82EB, // <cjk>
        "93D0": 0x5BC5, // <cjk>
        "93D1": 0x9149, // <cjk>
        "93D2": 0x701E, // <cjk>
        "93D3": 0x5678, // <cjk>
        "93D4": 0x5C6F, // <cjk>
        "93D5": 0x60C7, // <cjk>
        "93D6": 0x6566, // <cjk>
        "93D7": 0x6C8C, // <cjk>
        "93D8": 0x8C5A, // <cjk>
        "93D9": 0x9041, // <cjk>
        "93DA": 0x9813, // <cjk>
        "93DB": 0x5451, // <cjk>
        "93DC": 0x66C7, // <cjk>
        "93DD": 0x920D, // <cjk>
        "93DE": 0x5948, // <cjk>
        "93DF": 0x90A3, // <cjk>
        "93E0": 0x5185, // <cjk>
        "93E1": 0x4E4D, // <cjk>
        "93E2": 0x51EA, // <cjk>
        "93E3": 0x8599, // <cjk>
        "93E4": 0x8B0E, // <cjk>
        "93E5": 0x7058, // <cjk>
        "93E6": 0x637A, // <cjk>
        "93E7": 0x934B, // <cjk>
        "93E8": 0x6962, // <cjk>
        "93E9": 0x99B4, // <cjk>
        "93EA": 0x7E04, // <cjk>
        "93EB": 0x7577, // <cjk>
        "93EC": 0x5357, // <cjk>
        "93ED": 0x6960, // <cjk>
        "93EE": 0x8EDF, // <cjk>
        "93EF": 0x96E3, // <cjk>
        "93F0": 0x6C5D, // <cjk>
        "93F1": 0x4E8C, // <cjk>
        "93F2": 0x5C3C, // <cjk>
        "93F3": 0x5F10, // <cjk>
        "93F4": 0x8FE9, // <cjk>
        "93F5": 0x5302, // <cjk>
        "93F6": 0x8CD1, // <cjk>
        "93F7": 0x8089, // <cjk>
        "93F8": 0x8679, // <cjk>
        "93F9": 0x5EFF, // <cjk>
        "93FA": 0x65E5, // <cjk>
        "93FB": 0x4E73, // <cjk>
        "93FC": 0x5165, // <cjk>
        "9440": 0x5982, // <cjk>
        "9441": 0x5C3F, // <cjk>
        "9442": 0x97EE, // <cjk>
        "9443": 0x4EFB, // <cjk>
        "9444": 0x598A, // <cjk>
        "9445": 0x5FCD, // <cjk>
        "9446": 0x8A8D, // <cjk>
        "9447": 0x6FE1, // <cjk>
        "9448": 0x79B0, // <cjk>
        "9449": 0x7962, // <cjk>
        "944A": 0x5BE7, // <cjk>
        "944B": 0x8471, // <cjk>
        "944C": 0x732B, // <cjk>
        "944D": 0x71B1, // <cjk>
        "944E": 0x5E74, // <cjk>
        "944F": 0x5FF5, // <cjk>
        "9450": 0x637B, // <cjk>
        "9451": 0x649A, // <cjk>
        "9452": 0x71C3, // <cjk>
        "9453": 0x7C98, // <cjk>
        "9454": 0x4E43, // <cjk>
        "9455": 0x5EFC, // <cjk>
        "9456": 0x4E4B, // <cjk>
        "9457": 0x57DC, // <cjk>
        "9458": 0x56A2, // <cjk>
        "9459": 0x60A9, // <cjk>
        "945A": 0x6FC3, // <cjk>
        "945C": 0x80FD, // <cjk>
        "945D": 0x8133, // <cjk>
        "945E": 0x81BF, // <cjk>
        "945F": 0x8FB2, // <cjk>
        "9460": 0x8997, // <cjk>
        "9461": 0x86A4, // <cjk>
        "9462": 0x5DF4, // <cjk>
        "9463": 0x628A, // <cjk>
        "9464": 0x64AD, // <cjk>
        "9465": 0x8987, // <cjk>
        "9466": 0x6777, // <cjk>
        "9467": 0x6CE2, // <cjk>
        "9468": 0x6D3E, // <cjk>
        "9469": 0x7436, // <cjk>
        "946A": 0x7834, // <cjk>
        "946B": 0x5A46, // <cjk>
        "946C": 0x7F75, // <cjk>
        "946D": 0x82AD, // <cjk>
        "946E": 0x99AC, // <cjk>
        "946F": 0x4FF3, // <cjk>
        "9470": 0x5EC3, // <cjk>
        "9471": 0x62DD, // <cjk>
        "9472": 0x6392, // <cjk>
        "9473": 0x6557, // <cjk>
        "9474": 0x676F, // <cjk>
        "9475": 0x76C3, // <cjk>
        "9476": 0x724C, // <cjk>
        "9477": 0x80CC, // <cjk>
        "9478": 0x80BA, // <cjk>
        "9479": 0x8F29, // <cjk>
        "947A": 0x914D, // <cjk>
        "947B": 0x500D, // <cjk>
        "947C": 0x57F9, // <cjk>
        "947D": 0x5A92, // <cjk>
        "947E": 0x6885, // <cjk>
        "9480": 0x6973, // <cjk>
        "9481": 0x7164, // <cjk>
        "9482": 0x72FD, // <cjk>
        "9483": 0x8CB7, // <cjk>
        "9484": 0x58F2, // <cjk>
        "9485": 0x8CE0, // <cjk>
        "9486": 0x966A, // <cjk>
        "9487": 0x9019, // <cjk>
        "9488": 0x877F, // <cjk>
        "9489": 0x79E4, // <cjk>
        "948A": 0x77E7, // <cjk>
        "948B": 0x8429, // <cjk>
        "948C": 0x4F2F, // <cjk>
        "948D": 0x5265, // <cjk>
        "948E": 0x535A, // <cjk>
        "948F": 0x62CD, // <cjk>
        "9490": 0x67CF, // <cjk>
        "9491": 0x6CCA, // <cjk>
        "9492": 0x767D, // <cjk>
        "9493": 0x7B94, // <cjk>
        "9494": 0x7C95, // <cjk>
        "9495": 0x8236, // <cjk>
        "9496": 0x8584, // <cjk>
        "9497": 0x8FEB, // <cjk>
        "9498": 0x66DD, // <cjk>
        "9499": 0x6F20, // <cjk>
        "949A": 0x7206, // <cjk>
        "949B": 0x7E1B, // <cjk>
        "949C": 0x83AB, // <cjk>
        "949D": 0x99C1, // <cjk>
        "949E": 0x9EA6, // <cjk>
        "949F": 0x51FD, // <cjk>
        "94A0": 0x7BB1, // <cjk>
        "94A1": 0x7872, // <cjk>
        "94A2": 0x7BB8, // <cjk>
        "94A3": 0x8087, // <cjk>
        "94A4": 0x7B48, // <cjk>
        "94A5": 0x6AE8, // <cjk>
        "94A6": 0x5E61, // <cjk>
        "94A7": 0x808C, // <cjk>
        "94A8": 0x7551, // <cjk>
        "94A9": 0x7560, // <cjk>
        "94AA": 0x516B, // <cjk>
        "94AB": 0x9262, // <cjk>
        "94AC": 0x6E8C, // <cjk>
        "94AD": 0x767A, // <cjk>
        "94AE": 0x9197, // <cjk>
        "94AF": 0x9AEA, // <cjk>
        "94B0": 0x4F10, // <cjk>
        "94B1": 0x7F70, // <cjk>
        "94B2": 0x629C, // <cjk>
        "94B3": 0x7B4F, // <cjk>
        "94B4": 0x95A5, // <cjk>
        "94B5": 0x9CE9, // <cjk>
        "94B6": 0x567A, // <cjk>
        "94B7": 0x5859, // <cjk>
        "94B8": 0x86E4, // <cjk>
        "94B9": 0x96BC, // <cjk>
        "94BA": 0x4F34, // <cjk>
        "94BB": 0x5224, // <cjk>
        "94BC": 0x534A, // <cjk>
        "94BD": 0x53CD, // <cjk>
        "94BE": 0x53DB, // <cjk>
        "94BF": 0x5E06, // <cjk>
        "94C0": 0x642C, // <cjk>
        "94C1": 0x6591, // <cjk>
        "94C2": 0x677F, // <cjk>
        "94C3": 0x6C3E, // <cjk>
        "94C4": 0x6C4E, // <cjk>
        "94C5": 0x7248, // <cjk>
        "94C6": 0x72AF, // <cjk>
        "94C7": 0x73ED, // <cjk>
        "94C8": 0x7554, // <cjk>
        "94C9": 0x7E41, // <cjk>
        "94CA": 0x822C, // <cjk>
        "94CB": 0x85E9, // <cjk>
        "94CC": 0x8CA9, // <cjk>
        "94CD": 0x7BC4, // <cjk>
        "94CE": 0x91C6, // <cjk>
        "94CF": 0x7169, // <cjk>
        "94D0": 0x9812, // <cjk>
        "94D1": 0x98EF, // <cjk>
        "94D2": 0x633D, // <cjk>
        "94D3": 0x6669, // <cjk>
        "94D4": 0x756A, // <cjk>
        "94D5": 0x76E4, // <cjk>
        "94D6": 0x78D0, // <cjk>
        "94D7": 0x8543, // <cjk>
        "94D8": 0x86EE, // <cjk>
        "94D9": 0x532A, // <cjk>
        "94DA": 0x5351, // <cjk>
        "94DB": 0x5426, // <cjk>
        "94DC": 0x5983, // <cjk>
        "94DD": 0x5E87, // <cjk>
        "94DE": 0x5F7C, // <cjk>
        "94DF": 0x60B2, // <cjk>
        "94E0": 0x6249, // <cjk>
        "94E1": 0x6279, // <cjk>
        "94E2": 0x62AB, // <cjk>
        "94E3": 0x6590, // <cjk>
        "94E4": 0x6BD4, // <cjk>
        "94E5": 0x6CCC, // <cjk>
        "94E6": 0x75B2, // <cjk>
        "94E7": 0x76AE, // <cjk>
        "94E8": 0x7891, // <cjk>
        "94E9": 0x79D8, // <cjk>
        "94EA": 0x7DCB, // <cjk>
        "94EB": 0x7F77, // <cjk>
        "94EC": 0x80A5, // <cjk>
        "94ED": 0x88AB, // <cjk>
        "94EE": 0x8AB9, // <cjk>
        "94EF": 0x8CBB, // <cjk>
        "94F0": 0x907F, // <cjk>
        "94F1": 0x975E, // <cjk>
        "94F2": 0x98DB, // <cjk>
        "94F3": 0x6A0B, // <cjk>
        "94F4": 0x7C38, // <cjk>
        "94F5": 0x5099, // <cjk>
        "94F6": 0x5C3E, // <cjk>
        "94F7": 0x5FAE, // <cjk>
        "94F8": 0x6787, // <cjk>
        "94F9": 0x6BD8, // <cjk>
        "94FA": 0x7435, // <cjk>
        "94FB": 0x7709, // <cjk>
        "94FC": 0x7F8E, // <cjk>
        "9540": 0x9F3B, // <cjk>
        "9541": 0x67CA, // <cjk>
        "9542": 0x7A17, // <cjk>
        "9543": 0x5339, // <cjk>
        "9544": 0x758B, // <cjk>
        "9545": 0x9AED, // <cjk>
        "9546": 0x5F66, // <cjk>
        "9547": 0x819D, // <cjk>
        "9548": 0x83F1, // <cjk>
        "9549": 0x8098, // <cjk>
        "954A": 0x5F3C, // <cjk>
        "954B": 0x5FC5, // <cjk>
        "954C": 0x7562, // <cjk>
        "954D": 0x7B46, // <cjk>
        "954E": 0x903C, // <cjk>
        "954F": 0x6867, // <cjk>
        "9550": 0x59EB, // <cjk>
        "9551": 0x5A9B, // <cjk>
        "9552": 0x7D10, // <cjk>
        "9553": 0x767E, // <cjk>
        "9554": 0x8B2C, // <cjk>
        "9555": 0x4FF5, // <cjk>
        "9556": 0x5F6A, // <cjk>
        "9557": 0x6A19, // <cjk>
        "9558": 0x6C37, // <cjk>
        "9559": 0x6F02, // <cjk>
        "955A": 0x74E2, // <cjk>
        "955C": 0x8868, // <cjk>
        "955D": 0x8A55, // <cjk>
        "955E": 0x8C79, // <cjk>
        "955F": 0x5EDF, // <cjk>
        "9560": 0x63CF, // <cjk>
        "9561": 0x75C5, // <cjk>
        "9562": 0x79D2, // <cjk>
        "9563": 0x82D7, // <cjk>
        "9564": 0x9328, // <cjk>
        "9565": 0x92F2, // <cjk>
        "9566": 0x849C, // <cjk>
        "9567": 0x86ED, // <cjk>
        "9568": 0x9C2D, // <cjk>
        "9569": 0x54C1, // <cjk>
        "956A": 0x5F6C, // <cjk>
        "956B": 0x658C, // <cjk>
        "956C": 0x6D5C, // <cjk>
        "956D": 0x7015, // <cjk>
        "956E": 0x8CA7, // <cjk>
        "956F": 0x8CD3, // <cjk>
        "9570": 0x983B, // <cjk>
        "9571": 0x654F, // <cjk>
        "9572": 0x74F6, // <cjk>
        "9573": 0x4E0D, // <cjk>
        "9574": 0x4ED8, // <cjk>
        "9575": 0x57E0, // <cjk>
        "9576": 0x592B, // <cjk>
        "9577": 0x5A66, // <cjk>
        "9578": 0x5BCC, // <cjk>
        "9579": 0x51A8, // <cjk>
        "957A": 0x5E03, // <cjk>
        "957B": 0x5E9C, // <cjk>
        "957C": 0x6016, // <cjk>
        "957D": 0x6276, // <cjk>
        "957E": 0x6577, // <cjk>
        "9580": 0x65A7, // <cjk>
        "9581": 0x666E, // <cjk>
        "9582": 0x6D6E, // <cjk>
        "9583": 0x7236, // <cjk>
        "9584": 0x7B26, // <cjk>
        "9585": 0x8150, // <cjk>
        "9586": 0x819A, // <cjk>
        "9587": 0x8299, // <cjk>
        "9588": 0x8B5C, // <cjk>
        "9589": 0x8CA0, // <cjk>
        "958A": 0x8CE6, // <cjk>
        "958B": 0x8D74, // <cjk>
        "958C": 0x961C, // <cjk>
        "958D": 0x9644, // <cjk>
        "958E": 0x4FAE, // <cjk>
        "958F": 0x64AB, // <cjk>
        "9590": 0x6B66, // <cjk>
        "9591": 0x821E, // <cjk>
        "9592": 0x8461, // <cjk>
        "9593": 0x856A, // <cjk>
        "9594": 0x90E8, // <cjk>
        "9595": 0x5C01, // <cjk>
        "9596": 0x6953, // <cjk>
        "9597": 0x98A8, // <cjk>
        "9598": 0x847A, // <cjk>
        "9599": 0x8557, // <cjk>
        "959A": 0x4F0F, // <cjk>
        "959B": 0x526F, // <cjk>
        "959C": 0x5FA9, // <cjk>
        "959D": 0x5E45, // <cjk>
        "959E": 0x670D, // <cjk>
        "959F": 0x798F, // <cjk>
        "95A0": 0x8179, // <cjk>
        "95A1": 0x8907, // <cjk>
        "95A2": 0x8986, // <cjk>
        "95A3": 0x6DF5, // <cjk>
        "95A4": 0x5F17, // <cjk>
        "95A5": 0x6255, // <cjk>
        "95A6": 0x6CB8, // <cjk>
        "95A7": 0x4ECF, // <cjk>
        "95A8": 0x7269, // <cjk>
        "95A9": 0x9B92, // <cjk>
        "95AA": 0x5206, // <cjk>
        "95AB": 0x543B, // <cjk>
        "95AC": 0x5674, // <cjk>
        "95AD": 0x58B3, // <cjk>
        "95AE": 0x61A4, // <cjk>
        "95AF": 0x626E, // <cjk>
        "95B0": 0x711A, // <cjk>
        "95B1": 0x596E, // <cjk>
        "95B2": 0x7C89, // <cjk>
        "95B3": 0x7CDE, // <cjk>
        "95B4": 0x7D1B, // <cjk>
        "95B5": 0x96F0, // <cjk>
        "95B6": 0x6587, // <cjk>
        "95B7": 0x805E, // <cjk>
        "95B8": 0x4E19, // <cjk>
        "95B9": 0x4F75, // <cjk>
        "95BA": 0x5175, // <cjk>
        "95BB": 0x5840, // <cjk>
        "95BC": 0x5E63, // <cjk>
        "95BD": 0x5E73, // <cjk>
        "95BE": 0x5F0A, // <cjk>
        "95BF": 0x67C4, // <cjk>
        "95C0": 0x4E26, // <cjk>
        "95C1": 0x853D, // <cjk>
        "95C2": 0x9589, // <cjk>
        "95C3": 0x965B, // <cjk>
        "95C4": 0x7C73, // <cjk>
        "95C5": 0x9801, // <cjk>
        "95C6": 0x50FB, // <cjk>
        "95C7": 0x58C1, // <cjk>
        "95C8": 0x7656, // <cjk>
        "95C9": 0x78A7, // <cjk>
        "95CA": 0x5225, // <cjk>
        "95CB": 0x77A5, // <cjk>
        "95CC": 0x8511, // <cjk>
        "95CD": 0x7B86, // <cjk>
        "95CE": 0x504F, // <cjk>
        "95CF": 0x5909, // <cjk>
        "95D0": 0x7247, // <cjk>
        "95D1": 0x7BC7, // <cjk>
        "95D2": 0x7DE8, // <cjk>
        "95D3": 0x8FBA, // <cjk>
        "95D4": 0x8FD4, // <cjk>
        "95D5": 0x904D, // <cjk>
        "95D6": 0x4FBF, // <cjk>
        "95D7": 0x52C9, // <cjk>
        "95D8": 0x5A29, // <cjk>
        "95D9": 0x5F01, // <cjk>
        "95DA": 0x97AD, // <cjk>
        "95DB": 0x4FDD, // <cjk>
        "95DC": 0x8217, // <cjk>
        "95DD": 0x92EA, // <cjk>
        "95DE": 0x5703, // <cjk>
        "95DF": 0x6355, // <cjk>
        "95E0": 0x6B69, // <cjk>
        "95E1": 0x752B, // <cjk>
        "95E2": 0x88DC, // <cjk>
        "95E3": 0x8F14, // <cjk>
        "95E4": 0x7A42, // <cjk>
        "95E5": 0x52DF, // <cjk>
        "95E6": 0x5893, // <cjk>
        "95E7": 0x6155, // <cjk>
        "95E8": 0x620A, // <cjk>
        "95E9": 0x66AE, // <cjk>
        "95EA": 0x6BCD, // <cjk>
        "95EB": 0x7C3F, // <cjk>
        "95EC": 0x83E9, // <cjk>
        "95ED": 0x5023, // <cjk>
        "95EE": 0x4FF8, // <cjk>
        "95EF": 0x5305, // <cjk>
        "95F0": 0x5446, // <cjk>
        "95F1": 0x5831, // <cjk>
        "95F2": 0x5949, // <cjk>
        "95F3": 0x5B9D, // <cjk>
        "95F4": 0x5CF0, // <cjk>
        "95F5": 0x5CEF, // <cjk>
        "95F6": 0x5D29, // <cjk>
        "95F7": 0x5E96, // <cjk>
        "95F8": 0x62B1, // <cjk>
        "95F9": 0x6367, // <cjk>
        "95FA": 0x653E, // <cjk>
        "95FB": 0x65B9, // <cjk>
        "95FC": 0x670B, // <cjk>
        "9640": 0x6CD5, // <cjk>
        "9641": 0x6CE1, // <cjk>
        "9642": 0x70F9, // <cjk>
        "9643": 0x7832, // <cjk>
        "9644": 0x7E2B, // <cjk>
        "9645": 0x80DE, // <cjk>
        "9646": 0x82B3, // <cjk>
        "9647": 0x840C, // <cjk>
        "9648": 0x84EC, // <cjk>
        "9649": 0x8702, // <cjk>
        "964A": 0x8912, // <cjk>
        "964B": 0x8A2A, // <cjk>
        "964C": 0x8C4A, // <cjk>
        "964D": 0x90A6, // <cjk>
        "964E": 0x92D2, // <cjk>
        "964F": 0x98FD, // <cjk>
        "9650": 0x9CF3, // <cjk>
        "9651": 0x9D6C, // <cjk>
        "9652": 0x4E4F, // <cjk>
        "9653": 0x4EA1, // <cjk>
        "9654": 0x508D, // <cjk>
        "9655": 0x5256, // <cjk>
        "9656": 0x574A, // <cjk>
        "9657": 0x59A8, // <cjk>
        "9658": 0x5E3D, // <cjk>
        "9659": 0x5FD8, // <cjk>
        "965A": 0x5FD9, // <cjk>
        "965C": 0x66B4, // <cjk>
        "965D": 0x671B, // <cjk>
        "965E": 0x67D0, // <cjk>
        "965F": 0x68D2, // <cjk>
        "9660": 0x5192, // <cjk>
        "9661": 0x7D21, // <cjk>
        "9662": 0x80AA, // <cjk>
        "9663": 0x81A8, // <cjk>
        "9664": 0x8B00, // <cjk>
        "9665": 0x8C8C, // <cjk>
        "9666": 0x8CBF, // <cjk>
        "9667": 0x927E, // <cjk>
        "9668": 0x9632, // <cjk>
        "9669": 0x5420, // <cjk>
        "966A": 0x982C, // <cjk>
        "966B": 0x5317, // <cjk>
        "966C": 0x50D5, // <cjk>
        "966D": 0x535C, // <cjk>
        "966E": 0x58A8, // <cjk>
        "966F": 0x64B2, // <cjk>
        "9670": 0x6734, // <cjk>
        "9671": 0x7267, // <cjk>
        "9672": 0x7766, // <cjk>
        "9673": 0x7A46, // <cjk>
        "9674": 0x91E6, // <cjk>
        "9675": 0x52C3, // <cjk>
        "9676": 0x6CA1, // <cjk>
        "9677": 0x6B86, // <cjk>
        "9678": 0x5800, // <cjk>
        "9679": 0x5E4C, // <cjk>
        "967A": 0x5954, // <cjk>
        "967B": 0x672C, // <cjk>
        "967C": 0x7FFB, // <cjk>
        "967D": 0x51E1, // <cjk>
        "967E": 0x76C6, // <cjk>
        "9680": 0x6469, // <cjk>
        "9681": 0x78E8, // <cjk>
        "9682": 0x9B54, // <cjk>
        "9683": 0x9EBB, // <cjk>
        "9684": 0x57CB, // <cjk>
        "9685": 0x59B9, // <cjk>
        "9686": 0x6627, // <cjk>
        "9687": 0x679A, // <cjk>
        "9688": 0x6BCE, // <cjk>
        "9689": 0x54E9, // <cjk>
        "968A": 0x69D9, // <cjk>
        "968B": 0x5E55, // <cjk>
        "968C": 0x819C, // <cjk>
        "968D": 0x6795, // <cjk>
        "968E": 0x9BAA, // <cjk>
        "968F": 0x67FE, // <cjk>
        "9690": 0x9C52, // <cjk>
        "9691": 0x685D, // <cjk>
        "9692": 0x4EA6, // <cjk>
        "9693": 0x4FE3, // <cjk>
        "9694": 0x53C8, // <cjk>
        "9695": 0x62B9, // <cjk>
        "9696": 0x672B, // <cjk>
        "9697": 0x6CAB, // <cjk>
        "9698": 0x8FC4, // <cjk>
        "9699": 0x4FAD, // <cjk>
        "969A": 0x7E6D, // <cjk>
        "969B": 0x9EBF, // <cjk>
        "969C": 0x4E07, // <cjk>
        "969D": 0x6162, // <cjk>
        "969E": 0x6E80, // <cjk>
        "969F": 0x6F2B, // <cjk>
        "96A0": 0x8513, // <cjk>
        "96A1": 0x5473, // <cjk>
        "96A2": 0x672A, // <cjk>
        "96A3": 0x9B45, // <cjk>
        "96A4": 0x5DF3, // <cjk>
        "96A5": 0x7B95, // <cjk>
        "96A6": 0x5CAC, // <cjk>
        "96A7": 0x5BC6, // <cjk>
        "96A8": 0x871C, // <cjk>
        "96A9": 0x6E4A, // <cjk>
        "96AA": 0x84D1, // <cjk>
        "96AB": 0x7A14, // <cjk>
        "96AC": 0x8108, // <cjk>
        "96AD": 0x5999, // <cjk>
        "96AE": 0x7C8D, // <cjk>
        "96AF": 0x6C11, // <cjk>
        "96B0": 0x7720, // <cjk>
        "96B1": 0x52D9, // <cjk>
        "96B2": 0x5922, // <cjk>
        "96B3": 0x7121, // <cjk>
        "96B4": 0x725F, // <cjk>
        "96B5": 0x77DB, // <cjk>
        "96B6": 0x9727, // <cjk>
        "96B7": 0x9D61, // <cjk>
        "96B8": 0x690B, // <cjk>
        "96B9": 0x5A7F, // <cjk>
        "96BA": 0x5A18, // <cjk>
        "96BB": 0x51A5, // <cjk>
        "96BC": 0x540D, // <cjk>
        "96BD": 0x547D, // <cjk>
        "96BE": 0x660E, // <cjk>
        "96BF": 0x76DF, // <cjk>
        "96C0": 0x8FF7, // <cjk>
        "96C1": 0x9298, // <cjk>
        "96C2": 0x9CF4, // <cjk>
        "96C3": 0x59EA, // <cjk>
        "96C4": 0x725D, // <cjk>
        "96C5": 0x6EC5, // <cjk>
        "96C6": 0x514D, // <cjk>
        "96C7": 0x68C9, // <cjk>
        "96C8": 0x7DBF, // <cjk>
        "96C9": 0x7DEC, // <cjk>
        "96CA": 0x9762, // <cjk>
        "96CB": 0x9EBA, // <cjk>
        "96CC": 0x6478, // <cjk>
        "96CD": 0x6A21, // <cjk>
        "96CE": 0x8302, // <cjk>
        "96CF": 0x5984, // <cjk>
        "96D0": 0x5B5F, // <cjk>
        "96D1": 0x6BDB, // <cjk>
        "96D2": 0x731B, // <cjk>
        "96D3": 0x76F2, // <cjk>
        "96D4": 0x7DB2, // <cjk>
        "96D5": 0x8017, // <cjk>
        "96D6": 0x8499, // <cjk>
        "96D7": 0x5132, // <cjk>
        "96D8": 0x6728, // <cjk>
        "96D9": 0x9ED9, // <cjk>
        "96DA": 0x76EE, // <cjk>
        "96DB": 0x6762, // <cjk>
        "96DC": 0x52FF, // <cjk>
        "96DD": 0x9905, // <cjk>
        "96DE": 0x5C24, // <cjk>
        "96DF": 0x623B, // <cjk>
        "96E0": 0x7C7E, // <cjk>
        "96E1": 0x8CB0, // <cjk>
        "96E2": 0x554F, // <cjk>
        "96E3": 0x60B6, // <cjk>
        "96E4": 0x7D0B, // <cjk>
        "96E5": 0x9580, // <cjk>
        "96E6": 0x5301, // <cjk>
        "96E7": 0x4E5F, // <cjk>
        "96E8": 0x51B6, // <cjk>
        "96E9": 0x591C, // <cjk>
        "96EA": 0x723A, // <cjk>
        "96EB": 0x8036, // <cjk>
        "96EC": 0x91CE, // <cjk>
        "96ED": 0x5F25, // <cjk>
        "96EE": 0x77E2, // <cjk>
        "96EF": 0x5384, // <cjk>
        "96F0": 0x5F79, // <cjk>
        "96F1": 0x7D04, // <cjk>
        "96F2": 0x85AC, // <cjk>
        "96F3": 0x8A33, // <cjk>
        "96F4": 0x8E8D, // <cjk>
        "96F5": 0x9756, // <cjk>
        "96F6": 0x67F3, // <cjk>
        "96F7": 0x85AE, // <cjk>
        "96F8": 0x9453, // <cjk>
        "96F9": 0x6109, // <cjk>
        "96FA": 0x6108, // <cjk>
        "96FB": 0x6CB9, // <cjk>
        "96FC": 0x7652, // <cjk>
        "9740": 0x8AED, // <cjk>
        "9741": 0x8F38, // <cjk>
        "9742": 0x552F, // <cjk>
        "9743": 0x4F51, // <cjk>
        "9744": 0x512A, // <cjk>
        "9745": 0x52C7, // <cjk>
        "9746": 0x53CB, // <cjk>
        "9747": 0x5BA5, // <cjk>
        "9748": 0x5E7D, // <cjk>
        "9749": 0x60A0, // <cjk>
        "974A": 0x6182, // <cjk>
        "974B": 0x63D6, // <cjk>
        "974C": 0x6709, // <cjk>
        "974D": 0x67DA, // <cjk>
        "974E": 0x6E67, // <cjk>
        "974F": 0x6D8C, // <cjk>
        "9750": 0x7336, // <cjk>
        "9751": 0x7337, // <cjk>
        "9752": 0x7531, // <cjk>
        "9753": 0x7950, // <cjk>
        "9754": 0x88D5, // <cjk>
        "9755": 0x8A98, // <cjk>
        "9756": 0x904A, // <cjk>
        "9757": 0x9091, // <cjk>
        "9758": 0x90F5, // <cjk>
        "9759": 0x96C4, // <cjk>
        "975A": 0x878D, // <cjk>
        "975C": 0x4E88, // <cjk>
        "975D": 0x4F59, // <cjk>
        "975E": 0x4E0E, // <cjk>
        "975F": 0x8A89, // <cjk>
        "9760": 0x8F3F, // <cjk>
        "9761": 0x9810, // <cjk>
        "9762": 0x50AD, // <cjk>
        "9763": 0x5E7C, // <cjk>
        "9764": 0x5996, // <cjk>
        "9765": 0x5BB9, // <cjk>
        "9766": 0x5EB8, // <cjk>
        "9767": 0x63DA, // <cjk>
        "9768": 0x63FA, // <cjk>
        "9769": 0x64C1, // <cjk>
        "976A": 0x66DC, // <cjk>
        "976B": 0x694A, // <cjk>
        "976C": 0x69D8, // <cjk>
        "976D": 0x6D0B, // <cjk>
        "976E": 0x6EB6, // <cjk>
        "976F": 0x7194, // <cjk>
        "9770": 0x7528, // <cjk>
        "9771": 0x7AAF, // <cjk>
        "9772": 0x7F8A, // <cjk>
        "9773": 0x8000, // <cjk>
        "9774": 0x8449, // <cjk>
        "9775": 0x84C9, // <cjk>
        "9776": 0x8981, // <cjk>
        "9777": 0x8B21, // <cjk>
        "9778": 0x8E0A, // <cjk>
        "9779": 0x9065, // <cjk>
        "977A": 0x967D, // <cjk>
        "977B": 0x990A, // <cjk>
        "977C": 0x617E, // <cjk>
        "977D": 0x6291, // <cjk>
        "977E": 0x6B32, // <cjk>
        "9780": 0x6C83, // <cjk>
        "9781": 0x6D74, // <cjk>
        "9782": 0x7FCC, // <cjk>
        "9783": 0x7FFC, // <cjk>
        "9784": 0x6DC0, // <cjk>
        "9785": 0x7F85, // <cjk>
        "9786": 0x87BA, // <cjk>
        "9787": 0x88F8, // <cjk>
        "9788": 0x6765, // <cjk>
        "9789": 0x83B1, // <cjk>
        "978A": 0x983C, // <cjk>
        "978B": 0x96F7, // <cjk>
        "978C": 0x6D1B, // <cjk>
        "978D": 0x7D61, // <cjk>
        "978E": 0x843D, // <cjk>
        "978F": 0x916A, // <cjk>
        "9790": 0x4E71, // <cjk>
        "9791": 0x5375, // <cjk>
        "9792": 0x5D50, // <cjk>
        "9793": 0x6B04, // <cjk>
        "9794": 0x6FEB, // <cjk>
        "9795": 0x85CD, // <cjk>
        "9796": 0x862D, // <cjk>
        "9797": 0x89A7, // <cjk>
        "9798": 0x5229, // <cjk>
        "9799": 0x540F, // <cjk>
        "979A": 0x5C65, // <cjk>
        "979B": 0x674E, // <cjk>
        "979C": 0x68A8, // <cjk>
        "979D": 0x7406, // <cjk>
        "979E": 0x7483, // <cjk>
        "979F": 0x75E2, // <cjk>
        "97A0": 0x88CF, // <cjk>
        "97A1": 0x88E1, // <cjk>
        "97A2": 0x91CC, // <cjk>
        "97A3": 0x96E2, // <cjk>
        "97A4": 0x9678, // <cjk>
        "97A5": 0x5F8B, // <cjk>
        "97A6": 0x7387, // <cjk>
        "97A7": 0x7ACB, // <cjk>
        "97A8": 0x844E, // <cjk>
        "97A9": 0x63A0, // <cjk>
        "97AA": 0x7565, // <cjk>
        "97AB": 0x5289, // <cjk>
        "97AC": 0x6D41, // <cjk>
        "97AD": 0x6E9C, // <cjk>
        "97AE": 0x7409, // <cjk>
        "97AF": 0x7559, // <cjk>
        "97B0": 0x786B, // <cjk>
        "97B1": 0x7C92, // <cjk>
        "97B2": 0x9686, // <cjk>
        "97B3": 0x7ADC, // <cjk>
        "97B4": 0x9F8D, // <cjk>
        "97B5": 0x4FB6, // <cjk>
        "97B6": 0x616E, // <cjk>
        "97B7": 0x65C5, // <cjk>
        "97B8": 0x865C, // <cjk>
        "97B9": 0x4E86, // <cjk>
        "97BA": 0x4EAE, // <cjk>
        "97BB": 0x50DA, // <cjk>
        "97BC": 0x4E21, // <cjk>
        "97BD": 0x51CC, // <cjk>
        "97BE": 0x5BEE, // <cjk>
        "97BF": 0x6599, // <cjk>
        "97C0": 0x6881, // <cjk>
        "97C1": 0x6DBC, // <cjk>
        "97C2": 0x731F, // <cjk>
        "97C3": 0x7642, // <cjk>
        "97C4": 0x77AD, // <cjk>
        "97C5": 0x7A1C, // <cjk>
        "97C6": 0x7CE7, // <cjk>
        "97C7": 0x826F, // <cjk>
        "97C8": 0x8AD2, // <cjk>
        "97C9": 0x907C, // <cjk>
        "97CA": 0x91CF, // <cjk>
        "97CB": 0x9675, // <cjk>
        "97CC": 0x9818, // <cjk>
        "97CD": 0x529B, // <cjk>
        "97CE": 0x7DD1, // <cjk>
        "97CF": 0x502B, // <cjk>
        "97D0": 0x5398, // <cjk>
        "97D1": 0x6797, // <cjk>
        "97D2": 0x6DCB, // <cjk>
        "97D3": 0x71D0, // <cjk>
        "97D4": 0x7433, // <cjk>
        "97D5": 0x81E8, // <cjk>
        "97D6": 0x8F2A, // <cjk>
        "97D7": 0x96A3, // <cjk>
        "97D8": 0x9C57, // <cjk>
        "97D9": 0x9E9F, // <cjk>
        "97DA": 0x7460, // <cjk>
        "97DB": 0x5841, // <cjk>
        "97DC": 0x6D99, // <cjk>
        "97DD": 0x7D2F, // <cjk>
        "97DE": 0x985E, // <cjk>
        "97DF": 0x4EE4, // <cjk>
        "97E0": 0x4F36, // <cjk>
        "97E1": 0x4F8B, // <cjk>
        "97E2": 0x51B7, // <cjk>
        "97E3": 0x52B1, // <cjk>
        "97E4": 0x5DBA, // <cjk>
        "97E5": 0x601C, // <cjk>
        "97E6": 0x73B2, // <cjk>
        "97E7": 0x793C, // <cjk>
        "97E8": 0x82D3, // <cjk>
        "97E9": 0x9234, // <cjk>
        "97EA": 0x96B7, // <cjk>
        "97EB": 0x96F6, // <cjk>
        "97EC": 0x970A, // <cjk>
        "97ED": 0x9E97, // <cjk>
        "97EE": 0x9F62, // <cjk>
        "97EF": 0x66A6, // <cjk>
        "97F0": 0x6B74, // <cjk>
        "97F1": 0x5217, // <cjk>
        "97F2": 0x52A3, // <cjk>
        "97F3": 0x70C8, // <cjk>
        "97F4": 0x88C2, // <cjk>
        "97F5": 0x5EC9, // <cjk>
        "97F6": 0x604B, // <cjk>
        "97F7": 0x6190, // <cjk>
        "97F8": 0x6F23, // <cjk>
        "97F9": 0x7149, // <cjk>
        "97FA": 0x7C3E, // <cjk>
        "97FB": 0x7DF4, // <cjk>
        "97FC": 0x806F, // <cjk>
        "9840": 0x84EE, // <cjk>
        "9841": 0x9023, // <cjk>
        "9842": 0x932C, // <cjk>
        "9843": 0x5442, // <cjk>
        "9844": 0x9B6F, // <cjk>
        "9845": 0x6AD3, // <cjk>
        "9846": 0x7089, // <cjk>
        "9847": 0x8CC2, // <cjk>
        "9848": 0x8DEF, // <cjk>
        "9849": 0x9732, // <cjk>
        "984A": 0x52B4, // <cjk>
        "984B": 0x5A41, // <cjk>
        "984C": 0x5ECA, // <cjk>
        "984D": 0x5F04, // <cjk>
        "984E": 0x6717, // <cjk>
        "984F": 0x697C, // <cjk>
        "9850": 0x6994, // <cjk>
        "9851": 0x6D6A, // <cjk>
        "9852": 0x6F0F, // <cjk>
        "9853": 0x7262, // <cjk>
        "9854": 0x72FC, // <cjk>
        "9855": 0x7BED, // <cjk>
        "9856": 0x8001, // <cjk>
        "9857": 0x807E, // <cjk>
        "9858": 0x874B, // <cjk>
        "9859": 0x90CE, // <cjk>
        "985A": 0x516D, // <cjk>
        "985C": 0x7984, // <cjk>
        "985D": 0x808B, // <cjk>
        "985E": 0x9332, // <cjk>
        "985F": 0x8AD6, // <cjk>
        "9860": 0x502D, // <cjk>
        "9861": 0x548C, // <cjk>
        "9862": 0x8A71, // <cjk>
        "9863": 0x6B6A, // <cjk>
        "9864": 0x8CC4, // <cjk>
        "9865": 0x8107, // <cjk>
        "9866": 0x60D1, // <cjk>
        "9867": 0x67A0, // <cjk>
        "9868": 0x9DF2, // <cjk>
        "9869": 0x4E99, // <cjk>
        "986A": 0x4E98, // <cjk>
        "986B": 0x9C10, // <cjk>
        "986C": 0x8A6B, // <cjk>
        "986D": 0x85C1, // <cjk>
        "986E": 0x8568, // <cjk>
        "986F": 0x6900, // <cjk>
        "9870": 0x6E7E, // <cjk>
        "9871": 0x7897, // <cjk>
        "9872": 0x8155, // <cjk>
        "989F": 0x5F0C, // <cjk>
        "98A0": 0x4E10, // <cjk>
        "98A1": 0x4E15, // <cjk>
        "98A2": 0x4E2A, // <cjk>
        "98A3": 0x4E31, // <cjk>
        "98A4": 0x4E36, // <cjk>
        "98A5": 0x4E3C, // <cjk>
        "98A6": 0x4E3F, // <cjk>
        "98A7": 0x4E42, // <cjk>
        "98A8": 0x4E56, // <cjk>
        "98A9": 0x4E58, // <cjk>
        "98AA": 0x4E82, // <cjk>
        "98AB": 0x4E85, // <cjk>
        "98AC": 0x8C6B, // <cjk>
        "98AD": 0x4E8A, // <cjk>
        "98AE": 0x8212, // <cjk>
        "98AF": 0x5F0D, // <cjk>
        "98B0": 0x4E8E, // <cjk>
        "98B1": 0x4E9E, // <cjk>
        "98B2": 0x4E9F, // <cjk>
        "98B3": 0x4EA0, // <cjk>
        "98B4": 0x4EA2, // <cjk>
        "98B5": 0x4EB0, // <cjk>
        "98B6": 0x4EB3, // <cjk>
        "98B7": 0x4EB6, // <cjk>
        "98B8": 0x4ECE, // <cjk>
        "98B9": 0x4ECD, // <cjk>
        "98BA": 0x4EC4, // <cjk>
        "98BB": 0x4EC6, // <cjk>
        "98BC": 0x4EC2, // <cjk>
        "98BD": 0x4ED7, // <cjk>
        "98BE": 0x4EDE, // <cjk>
        "98BF": 0x4EED, // <cjk>
        "98C0": 0x4EDF, // <cjk>
        "98C1": 0x4EF7, // <cjk>
        "98C2": 0x4F09, // <cjk>
        "98C3": 0x4F5A, // <cjk>
        "98C4": 0x4F30, // <cjk>
        "98C5": 0x4F5B, // <cjk>
        "98C6": 0x4F5D, // <cjk>
        "98C7": 0x4F57, // <cjk>
        "98C8": 0x4F47, // <cjk>
        "98C9": 0x4F76, // <cjk>
        "98CA": 0x4F88, // <cjk>
        "98CB": 0x4F8F, // <cjk>
        "98CC": 0x4F98, // <cjk>
        "98CD": 0x4F7B, // <cjk>
        "98CE": 0x4F69, // <cjk>
        "98CF": 0x4F70, // <cjk>
        "98D0": 0x4F91, // <cjk>
        "98D1": 0x4F6F, // <cjk>
        "98D2": 0x4F86, // <cjk>
        "98D3": 0x4F96, // <cjk>
        "98D4": 0x5118, // <cjk>
        "98D5": 0x4FD4, // <cjk>
        "98D6": 0x4FDF, // <cjk>
        "98D7": 0x4FCE, // <cjk>
        "98D8": 0x4FD8, // <cjk>
        "98D9": 0x4FDB, // <cjk>
        "98DA": 0x4FD1, // <cjk>
        "98DB": 0x4FDA, // <cjk>
        "98DC": 0x4FD0, // <cjk>
        "98DD": 0x4FE4, // <cjk>
        "98DE": 0x4FE5, // <cjk>
        "98DF": 0x501A, // <cjk>
        "98E0": 0x5028, // <cjk>
        "98E1": 0x5014, // <cjk>
        "98E2": 0x502A, // <cjk>
        "98E3": 0x5025, // <cjk>
        "98E4": 0x5005, // <cjk>
        "98E5": 0x4F1C, // <cjk>
        "98E6": 0x4FF6, // <cjk>
        "98E7": 0x5021, // <cjk>
        "98E8": 0x5029, // <cjk>
        "98E9": 0x502C, // <cjk>
        "98EA": 0x4FFE, // <cjk>
        "98EB": 0x4FEF, // <cjk>
        "98EC": 0x5011, // <cjk>
        "98ED": 0x5006, // <cjk>
        "98EE": 0x5043, // <cjk>
        "98EF": 0x5047, // <cjk>
        "98F0": 0x6703, // <cjk>
        "98F1": 0x5055, // <cjk>
        "98F2": 0x5050, // <cjk>
        "98F3": 0x5048, // <cjk>
        "98F4": 0x505A, // <cjk>
        "98F5": 0x5056, // <cjk>
        "98F6": 0x506C, // <cjk>
        "98F7": 0x5078, // <cjk>
        "98F8": 0x5080, // <cjk>
        "98F9": 0x509A, // <cjk>
        "98FA": 0x5085, // <cjk>
        "98FB": 0x50B4, // <cjk>
        "98FC": 0x50B2, // <cjk>
        "9940": 0x50C9, // <cjk>
        "9941": 0x50CA, // <cjk>
        "9942": 0x50B3, // <cjk>
        "9943": 0x50C2, // <cjk>
        "9944": 0x50D6, // <cjk>
        "9945": 0x50DE, // <cjk>
        "9946": 0x50E5, // <cjk>
        "9947": 0x50ED, // <cjk>
        "9948": 0x50E3, // <cjk>
        "9949": 0x50EE, // <cjk>
        "994A": 0x50F9, // <cjk>
        "994B": 0x50F5, // <cjk>
        "994C": 0x5109, // <cjk>
        "994D": 0x5101, // <cjk>
        "994E": 0x5102, // <cjk>
        "994F": 0x5116, // <cjk>
        "9950": 0x5115, // <cjk>
        "9951": 0x5114, // <cjk>
        "9952": 0x511A, // <cjk>
        "9953": 0x5121, // <cjk>
        "9954": 0x513A, // <cjk>
        "9955": 0x5137, // <cjk>
        "9956": 0x513C, // <cjk>
        "9957": 0x513B, // <cjk>
        "9958": 0x513F, // <cjk>
        "9959": 0x5140, // <cjk>
        "995A": 0x5152, // <cjk>
        "995C": 0x5154, // <cjk>
        "995D": 0x5162, // <cjk>
        "995E": 0x7AF8, // <cjk>
        "995F": 0x5169, // <cjk>
        "9960": 0x516A, // <cjk>
        "9961": 0x516E, // <cjk>
        "9962": 0x5180, // <cjk>
        "9963": 0x5182, // <cjk>
        "9964": 0x56D8, // <cjk>
        "9965": 0x518C, // <cjk>
        "9966": 0x5189, // <cjk>
        "9967": 0x518F, // <cjk>
        "9968": 0x5191, // <cjk>
        "9969": 0x5193, // <cjk>
        "996A": 0x5195, // <cjk>
        "996B": 0x5196, // <cjk>
        "996C": 0x51A4, // <cjk>
        "996D": 0x51A6, // <cjk>
        "996E": 0x51A2, // <cjk>
        "996F": 0x51A9, // <cjk>
        "9970": 0x51AA, // <cjk>
        "9971": 0x51AB, // <cjk>
        "9972": 0x51B3, // <cjk>
        "9973": 0x51B1, // <cjk>
        "9974": 0x51B2, // <cjk>
        "9975": 0x51B0, // <cjk>
        "9976": 0x51B5, // <cjk>
        "9977": 0x51BD, // <cjk>
        "9978": 0x51C5, // <cjk>
        "9979": 0x51C9, // <cjk>
        "997A": 0x51DB, // <cjk>
        "997B": 0x51E0, // <cjk>
        "997C": 0x8655, // <cjk>
        "997D": 0x51E9, // <cjk>
        "997E": 0x51ED, // <cjk>
        "9980": 0x51F0, // <cjk>
        "9981": 0x51F5, // <cjk>
        "9982": 0x51FE, // <cjk>
        "9983": 0x5204, // <cjk>
        "9984": 0x520B, // <cjk>
        "9985": 0x5214, // <cjk>
        "9986": 0x520E, // <cjk>
        "9987": 0x5227, // <cjk>
        "9988": 0x522A, // <cjk>
        "9989": 0x522E, // <cjk>
        "998A": 0x5233, // <cjk>
        "998B": 0x5239, // <cjk>
        "998C": 0x524F, // <cjk>
        "998D": 0x5244, // <cjk>
        "998E": 0x524B, // <cjk>
        "998F": 0x524C, // <cjk>
        "9990": 0x525E, // <cjk>
        "9991": 0x5254, // <cjk>
        "9992": 0x526A, // <cjk>
        "9993": 0x5274, // <cjk>
        "9994": 0x5269, // <cjk>
        "9995": 0x5273, // <cjk>
        "9996": 0x527F, // <cjk>
        "9997": 0x527D, // <cjk>
        "9998": 0x528D, // <cjk>
        "9999": 0x5294, // <cjk>
        "999A": 0x5292, // <cjk>
        "999B": 0x5271, // <cjk>
        "999C": 0x5288, // <cjk>
        "999D": 0x5291, // <cjk>
        "999E": 0x8FA8, // <cjk>
        "999F": 0x8FA7, // <cjk>
        "99A0": 0x52AC, // <cjk>
        "99A1": 0x52AD, // <cjk>
        "99A2": 0x52BC, // <cjk>
        "99A3": 0x52B5, // <cjk>
        "99A4": 0x52C1, // <cjk>
        "99A5": 0x52CD, // <cjk>
        "99A6": 0x52D7, // <cjk>
        "99A7": 0x52DE, // <cjk>
        "99A8": 0x52E3, // <cjk>
        "99A9": 0x52E6, // <cjk>
        "99AA": 0x98ED, // <cjk>
        "99AB": 0x52E0, // <cjk>
        "99AC": 0x52F3, // <cjk>
        "99AD": 0x52F5, // <cjk>
        "99AE": 0x52F8, // <cjk>
        "99AF": 0x52F9, // <cjk>
        "99B0": 0x5306, // <cjk>
        "99B1": 0x5308, // <cjk>
        "99B2": 0x7538, // <cjk>
        "99B3": 0x530D, // <cjk>
        "99B4": 0x5310, // <cjk>
        "99B5": 0x530F, // <cjk>
        "99B6": 0x5315, // <cjk>
        "99B7": 0x531A, // <cjk>
        "99B8": 0x5323, // <cjk>
        "99B9": 0x532F, // <cjk>
        "99BA": 0x5331, // <cjk>
        "99BB": 0x5333, // <cjk>
        "99BC": 0x5338, // <cjk>
        "99BD": 0x5340, // <cjk>
        "99BE": 0x5346, // <cjk>
        "99BF": 0x5345, // <cjk>
        "99C0": 0x4E17, // <cjk>
        "99C1": 0x5349, // <cjk>
        "99C2": 0x534D, // <cjk>
        "99C3": 0x51D6, // <cjk>
        "99C4": 0x535E, // <cjk>
        "99C5": 0x5369, // <cjk>
        "99C6": 0x536E, // <cjk>
        "99C7": 0x5918, // <cjk>
        "99C8": 0x537B, // <cjk>
        "99C9": 0x5377, // <cjk>
        "99CA": 0x5382, // <cjk>
        "99CB": 0x5396, // <cjk>
        "99CC": 0x53A0, // <cjk>
        "99CD": 0x53A6, // <cjk>
        "99CE": 0x53A5, // <cjk>
        "99CF": 0x53AE, // <cjk>
        "99D0": 0x53B0, // <cjk>
        "99D1": 0x53B6, // <cjk>
        "99D2": 0x53C3, // <cjk>
        "99D3": 0x7C12, // <cjk>
        "99D4": 0x96D9, // <cjk>
        "99D5": 0x53DF, // <cjk>
        "99D6": 0x66FC, // <cjk>
        "99D7": 0x71EE, // <cjk>
        "99D8": 0x53EE, // <cjk>
        "99D9": 0x53E8, // <cjk>
        "99DA": 0x53ED, // <cjk>
        "99DB": 0x53FA, // <cjk>
        "99DC": 0x5401, // <cjk>
        "99DD": 0x543D, // <cjk>
        "99DE": 0x5440, // <cjk>
        "99DF": 0x542C, // <cjk>
        "99E0": 0x542D, // <cjk>
        "99E1": 0x543C, // <cjk>
        "99E2": 0x542E, // <cjk>
        "99E3": 0x5436, // <cjk>
        "99E4": 0x5429, // <cjk>
        "99E5": 0x541D, // <cjk>
        "99E6": 0x544E, // <cjk>
        "99E7": 0x548F, // <cjk>
        "99E8": 0x5475, // <cjk>
        "99E9": 0x548E, // <cjk>
        "99EA": 0x545F, // <cjk>
        "99EB": 0x5471, // <cjk>
        "99EC": 0x5477, // <cjk>
        "99ED": 0x5470, // <cjk>
        "99EE": 0x5492, // <cjk>
        "99EF": 0x547B, // <cjk>
        "99F0": 0x5480, // <cjk>
        "99F1": 0x5476, // <cjk>
        "99F2": 0x5484, // <cjk>
        "99F3": 0x5490, // <cjk>
        "99F4": 0x5486, // <cjk>
        "99F5": 0x54C7, // <cjk>
        "99F6": 0x54A2, // <cjk>
        "99F7": 0x54B8, // <cjk>
        "99F8": 0x54A5, // <cjk>
        "99F9": 0x54AC, // <cjk>
        "99FA": 0x54C4, // <cjk>
        "99FB": 0x54C8, // <cjk>
        "99FC": 0x54A8, // <cjk>
        "9A40": 0x54AB, // <cjk>
        "9A41": 0x54C2, // <cjk>
        "9A42": 0x54A4, // <cjk>
        "9A43": 0x54BE, // <cjk>
        "9A44": 0x54BC, // <cjk>
        "9A45": 0x54D8, // <cjk>
        "9A46": 0x54E5, // <cjk>
        "9A47": 0x54E6, // <cjk>
        "9A48": 0x550F, // <cjk>
        "9A49": 0x5514, // <cjk>
        "9A4A": 0x54FD, // <cjk>
        "9A4B": 0x54EE, // <cjk>
        "9A4C": 0x54ED, // <cjk>
        "9A4D": 0x54FA, // <cjk>
        "9A4E": 0x54E2, // <cjk>
        "9A4F": 0x5539, // <cjk>
        "9A50": 0x5540, // <cjk>
        "9A51": 0x5563, // <cjk>
        "9A52": 0x554C, // <cjk>
        "9A53": 0x552E, // <cjk>
        "9A54": 0x555C, // <cjk>
        "9A55": 0x5545, // <cjk>
        "9A56": 0x5556, // <cjk>
        "9A57": 0x5557, // <cjk>
        "9A58": 0x5538, // <cjk>
        "9A59": 0x5533, // <cjk>
        "9A5A": 0x555D, // <cjk>
        "9A5C": 0x5580, // <cjk>
        "9A5D": 0x54AF, // <cjk>
        "9A5E": 0x558A, // <cjk>
        "9A5F": 0x559F, // <cjk>
        "9A60": 0x557B, // <cjk>
        "9A61": 0x557E, // <cjk>
        "9A62": 0x5598, // <cjk>
        "9A63": 0x559E, // <cjk>
        "9A64": 0x55AE, // <cjk>
        "9A65": 0x557C, // <cjk>
        "9A66": 0x5583, // <cjk>
        "9A67": 0x55A9, // <cjk>
        "9A68": 0x5587, // <cjk>
        "9A69": 0x55A8, // <cjk>
        "9A6A": 0x55DA, // <cjk>
        "9A6B": 0x55C5, // <cjk>
        "9A6C": 0x55DF, // <cjk>
        "9A6D": 0x55C4, // <cjk>
        "9A6E": 0x55DC, // <cjk>
        "9A6F": 0x55E4, // <cjk>
        "9A70": 0x55D4, // <cjk>
        "9A71": 0x5614, // <cjk>
        "9A72": 0x55F7, // <cjk>
        "9A73": 0x5616, // <cjk>
        "9A74": 0x55FE, // <cjk>
        "9A75": 0x55FD, // <cjk>
        "9A76": 0x561B, // <cjk>
        "9A77": 0x55F9, // <cjk>
        "9A78": 0x564E, // <cjk>
        "9A79": 0x5650, // <cjk>
        "9A7A": 0x71DF, // <cjk>
        "9A7B": 0x5634, // <cjk>
        "9A7C": 0x5636, // <cjk>
        "9A7D": 0x5632, // <cjk>
        "9A7E": 0x5638, // <cjk>
        "9A80": 0x566B, // <cjk>
        "9A81": 0x5664, // <cjk>
        "9A82": 0x562F, // <cjk>
        "9A83": 0x566C, // <cjk>
        "9A84": 0x566A, // <cjk>
        "9A85": 0x5686, // <cjk>
        "9A86": 0x5680, // <cjk>
        "9A87": 0x568A, // <cjk>
        "9A88": 0x56A0, // <cjk>
        "9A89": 0x5694, // <cjk>
        "9A8A": 0x568F, // <cjk>
        "9A8B": 0x56A5, // <cjk>
        "9A8C": 0x56AE, // <cjk>
        "9A8D": 0x56B6, // <cjk>
        "9A8E": 0x56B4, // <cjk>
        "9A8F": 0x56C2, // <cjk>
        "9A90": 0x56BC, // <cjk>
        "9A91": 0x56C1, // <cjk>
        "9A92": 0x56C3, // <cjk>
        "9A93": 0x56C0, // <cjk>
        "9A94": 0x56C8, // <cjk>
        "9A95": 0x56CE, // <cjk>
        "9A96": 0x56D1, // <cjk>
        "9A97": 0x56D3, // <cjk>
        "9A98": 0x56D7, // <cjk>
        "9A99": 0x56EE, // <cjk>
        "9A9A": 0x56F9, // <cjk>
        "9A9B": 0x5700, // <cjk>
        "9A9C": 0x56FF, // <cjk>
        "9A9D": 0x5704, // <cjk>
        "9A9E": 0x5709, // <cjk>
        "9A9F": 0x5708, // <cjk>
        "9AA0": 0x570B, // <cjk>
        "9AA1": 0x570D, // <cjk>
        "9AA2": 0x5713, // <cjk>
        "9AA3": 0x5718, // <cjk>
        "9AA4": 0x5716, // <cjk>
        "9AA5": 0x55C7, // <cjk>
        "9AA6": 0x571C, // <cjk>
        "9AA7": 0x5726, // <cjk>
        "9AA8": 0x5737, // <cjk>
        "9AA9": 0x5738, // <cjk>
        "9AAA": 0x574E, // <cjk>
        "9AAB": 0x573B, // <cjk>
        "9AAC": 0x5740, // <cjk>
        "9AAD": 0x574F, // <cjk>
        "9AAE": 0x5769, // <cjk>
        "9AAF": 0x57C0, // <cjk>
        "9AB0": 0x5788, // <cjk>
        "9AB1": 0x5761, // <cjk>
        "9AB2": 0x577F, // <cjk>
        "9AB3": 0x5789, // <cjk>
        "9AB4": 0x5793, // <cjk>
        "9AB5": 0x57A0, // <cjk>
        "9AB6": 0x57B3, // <cjk>
        "9AB7": 0x57A4, // <cjk>
        "9AB8": 0x57AA, // <cjk>
        "9AB9": 0x57B0, // <cjk>
        "9ABA": 0x57C3, // <cjk>
        "9ABB": 0x57C6, // <cjk>
        "9ABC": 0x57D4, // <cjk>
        "9ABD": 0x57D2, // <cjk>
        "9ABE": 0x57D3, // <cjk>
        "9ABF": 0x580A, // <cjk>
        "9AC0": 0x57D6, // <cjk>
        "9AC1": 0x57E3, // <cjk>
        "9AC2": 0x580B, // <cjk>
        "9AC3": 0x5819, // <cjk>
        "9AC4": 0x581D, // <cjk>
        "9AC5": 0x5872, // <cjk>
        "9AC6": 0x5821, // <cjk>
        "9AC7": 0x5862, // <cjk>
        "9AC8": 0x584B, // <cjk>
        "9AC9": 0x5870, // <cjk>
        "9ACA": 0x6BC0, // <cjk>
        "9ACB": 0x5852, // <cjk>
        "9ACC": 0x583D, // <cjk>
        "9ACD": 0x5879, // <cjk>
        "9ACE": 0x5885, // <cjk>
        "9ACF": 0x58B9, // <cjk>
        "9AD0": 0x589F, // <cjk>
        "9AD1": 0x58AB, // <cjk>
        "9AD2": 0x58BA, // <cjk>
        "9AD3": 0x58DE, // <cjk>
        "9AD4": 0x58BB, // <cjk>
        "9AD5": 0x58B8, // <cjk>
        "9AD6": 0x58AE, // <cjk>
        "9AD7": 0x58C5, // <cjk>
        "9AD8": 0x58D3, // <cjk>
        "9AD9": 0x58D1, // <cjk>
        "9ADA": 0x58D7, // <cjk>
        "9ADB": 0x58D9, // <cjk>
        "9ADC": 0x58D8, // <cjk>
        "9ADD": 0x58E5, // <cjk>
        "9ADE": 0x58DC, // <cjk>
        "9ADF": 0x58E4, // <cjk>
        "9AE0": 0x58DF, // <cjk>
        "9AE1": 0x58EF, // <cjk>
        "9AE2": 0x58FA, // <cjk>
        "9AE3": 0x58F9, // <cjk>
        "9AE4": 0x58FB, // <cjk>
        "9AE5": 0x58FC, // <cjk>
        "9AE6": 0x58FD, // <cjk>
        "9AE7": 0x5902, // <cjk>
        "9AE8": 0x590A, // <cjk>
        "9AE9": 0x5910, // <cjk>
        "9AEA": 0x591B, // <cjk>
        "9AEB": 0x68A6, // <cjk>
        "9AEC": 0x5925, // <cjk>
        "9AED": 0x592C, // <cjk>
        "9AEE": 0x592D, // <cjk>
        "9AEF": 0x5932, // <cjk>
        "9AF0": 0x5938, // <cjk>
        "9AF1": 0x593E, // <cjk>
        "9AF2": 0x7AD2, // <cjk>
        "9AF3": 0x5955, // <cjk>
        "9AF4": 0x5950, // <cjk>
        "9AF5": 0x594E, // <cjk>
        "9AF6": 0x595A, // <cjk>
        "9AF7": 0x5958, // <cjk>
        "9AF8": 0x5962, // <cjk>
        "9AF9": 0x5960, // <cjk>
        "9AFA": 0x5967, // <cjk>
        "9AFB": 0x596C, // <cjk>
        "9AFC": 0x5969, // <cjk>
        "9B40": 0x5978, // <cjk>
        "9B41": 0x5981, // <cjk>
        "9B42": 0x599D, // <cjk>
        "9B43": 0x4F5E, // <cjk>
        "9B44": 0x4FAB, // <cjk>
        "9B45": 0x59A3, // <cjk>
        "9B46": 0x59B2, // <cjk>
        "9B47": 0x59C6, // <cjk>
        "9B48": 0x59E8, // <cjk>
        "9B49": 0x59DC, // <cjk>
        "9B4A": 0x598D, // <cjk>
        "9B4B": 0x59D9, // <cjk>
        "9B4C": 0x59DA, // <cjk>
        "9B4D": 0x5A25, // <cjk>
        "9B4E": 0x5A1F, // <cjk>
        "9B4F": 0x5A11, // <cjk>
        "9B50": 0x5A1C, // <cjk>
        "9B51": 0x5A09, // <cjk>
        "9B52": 0x5A1A, // <cjk>
        "9B53": 0x5A40, // <cjk>
        "9B54": 0x5A6C, // <cjk>
        "9B55": 0x5A49, // <cjk>
        "9B56": 0x5A35, // <cjk>
        "9B57": 0x5A36, // <cjk>
        "9B58": 0x5A62, // <cjk>
        "9B59": 0x5A6A, // <cjk>
        "9B5A": 0x5A9A, // <cjk>
        "9B5C": 0x5ABE, // <cjk>
        "9B5D": 0x5ACB, // <cjk>
        "9B5E": 0x5AC2, // <cjk>
        "9B5F": 0x5ABD, // <cjk>
        "9B60": 0x5AE3, // <cjk>
        "9B61": 0x5AD7, // <cjk>
        "9B62": 0x5AE6, // <cjk>
        "9B63": 0x5AE9, // <cjk>
        "9B64": 0x5AD6, // <cjk>
        "9B65": 0x5AFA, // <cjk>
        "9B66": 0x5AFB, // <cjk>
        "9B67": 0x5B0C, // <cjk>
        "9B68": 0x5B0B, // <cjk>
        "9B69": 0x5B16, // <cjk>
        "9B6A": 0x5B32, // <cjk>
        "9B6B": 0x5AD0, // <cjk>
        "9B6C": 0x5B2A, // <cjk>
        "9B6D": 0x5B36, // <cjk>
        "9B6E": 0x5B3E, // <cjk>
        "9B6F": 0x5B43, // <cjk>
        "9B70": 0x5B45, // <cjk>
        "9B71": 0x5B40, // <cjk>
        "9B72": 0x5B51, // <cjk>
        "9B73": 0x5B55, // <cjk>
        "9B74": 0x5B5A, // <cjk>
        "9B75": 0x5B5B, // <cjk>
        "9B76": 0x5B65, // <cjk>
        "9B77": 0x5B69, // <cjk>
        "9B78": 0x5B70, // <cjk>
        "9B79": 0x5B73, // <cjk>
        "9B7A": 0x5B75, // <cjk>
        "9B7B": 0x5B78, // <cjk>
        "9B7C": 0x6588, // <cjk>
        "9B7D": 0x5B7A, // <cjk>
        "9B7E": 0x5B80, // <cjk>
        "9B80": 0x5B83, // <cjk>
        "9B81": 0x5BA6, // <cjk>
        "9B82": 0x5BB8, // <cjk>
        "9B83": 0x5BC3, // <cjk>
        "9B84": 0x5BC7, // <cjk>
        "9B85": 0x5BC9, // <cjk>
        "9B86": 0x5BD4, // <cjk>
        "9B87": 0x5BD0, // <cjk>
        "9B88": 0x5BE4, // <cjk>
        "9B89": 0x5BE6, // <cjk>
        "9B8A": 0x5BE2, // <cjk>
        "9B8B": 0x5BDE, // <cjk>
        "9B8C": 0x5BE5, // <cjk>
        "9B8D": 0x5BEB, // <cjk>
        "9B8E": 0x5BF0, // <cjk>
        "9B8F": 0x5BF6, // <cjk>
        "9B90": 0x5BF3, // <cjk>
        "9B91": 0x5C05, // <cjk>
        "9B92": 0x5C07, // <cjk>
        "9B93": 0x5C08, // <cjk>
        "9B94": 0x5C0D, // <cjk>
        "9B95": 0x5C13, // <cjk>
        "9B96": 0x5C20, // <cjk>
        "9B97": 0x5C22, // <cjk>
        "9B98": 0x5C28, // <cjk>
        "9B99": 0x5C38, // <cjk>
        "9B9A": 0x5C39, // <cjk>
        "9B9B": 0x5C41, // <cjk>
        "9B9C": 0x5C46, // <cjk>
        "9B9D": 0x5C4E, // <cjk>
        "9B9E": 0x5C53, // <cjk>
        "9B9F": 0x5C50, // <cjk>
        "9BA0": 0x5C4F, // <cjk>
        "9BA1": 0x5B71, // <cjk>
        "9BA2": 0x5C6C, // <cjk>
        "9BA3": 0x5C6E, // <cjk>
        "9BA4": 0x4E62, // <cjk>
        "9BA5": 0x5C76, // <cjk>
        "9BA6": 0x5C79, // <cjk>
        "9BA7": 0x5C8C, // <cjk>
        "9BA8": 0x5C91, // <cjk>
        "9BA9": 0x5C94, // <cjk>
        "9BAA": 0x599B, // <cjk>
        "9BAB": 0x5CAB, // <cjk>
        "9BAC": 0x5CBB, // <cjk>
        "9BAD": 0x5CB6, // <cjk>
        "9BAE": 0x5CBC, // <cjk>
        "9BAF": 0x5CB7, // <cjk>
        "9BB0": 0x5CC5, // <cjk>
        "9BB1": 0x5CBE, // <cjk>
        "9BB2": 0x5CC7, // <cjk>
        "9BB3": 0x5CD9, // <cjk>
        "9BB4": 0x5CE9, // <cjk>
        "9BB5": 0x5CFD, // <cjk>
        "9BB6": 0x5CFA, // <cjk>
        "9BB7": 0x5CED, // <cjk>
        "9BB8": 0x5D8C, // <cjk>
        "9BB9": 0x5CEA, // <cjk>
        "9BBA": 0x5D0B, // <cjk>
        "9BBB": 0x5D15, // <cjk>
        "9BBC": 0x5D17, // <cjk>
        "9BBD": 0x5D5C, // <cjk>
        "9BBE": 0x5D1F, // <cjk>
        "9BBF": 0x5D1B, // <cjk>
        "9BC0": 0x5D11, // <cjk>
        "9BC1": 0x5D14, // <cjk>
        "9BC2": 0x5D22, // <cjk>
        "9BC3": 0x5D1A, // <cjk>
        "9BC4": 0x5D19, // <cjk>
        "9BC5": 0x5D18, // <cjk>
        "9BC6": 0x5D4C, // <cjk>
        "9BC7": 0x5D52, // <cjk>
        "9BC8": 0x5D4E, // <cjk>
        "9BC9": 0x5D4B, // <cjk>
        "9BCA": 0x5D6C, // <cjk>
        "9BCB": 0x5D73, // <cjk>
        "9BCC": 0x5D76, // <cjk>
        "9BCD": 0x5D87, // <cjk>
        "9BCE": 0x5D84, // <cjk>
        "9BCF": 0x5D82, // <cjk>
        "9BD0": 0x5DA2, // <cjk>
        "9BD1": 0x5D9D, // <cjk>
        "9BD2": 0x5DAC, // <cjk>
        "9BD3": 0x5DAE, // <cjk>
        "9BD4": 0x5DBD, // <cjk>
        "9BD5": 0x5D90, // <cjk>
        "9BD6": 0x5DB7, // <cjk>
        "9BD7": 0x5DBC, // <cjk>
        "9BD8": 0x5DC9, // <cjk>
        "9BD9": 0x5DCD, // <cjk>
        "9BDA": 0x5DD3, // <cjk>
        "9BDB": 0x5DD2, // <cjk>
        "9BDC": 0x5DD6, // <cjk>
        "9BDD": 0x5DDB, // <cjk>
        "9BDE": 0x5DEB, // <cjk>
        "9BDF": 0x5DF2, // <cjk>
        "9BE0": 0x5DF5, // <cjk>
        "9BE1": 0x5E0B, // <cjk>
        "9BE2": 0x5E1A, // <cjk>
        "9BE3": 0x5E19, // <cjk>
        "9BE4": 0x5E11, // <cjk>
        "9BE5": 0x5E1B, // <cjk>
        "9BE6": 0x5E36, // <cjk>
        "9BE7": 0x5E37, // <cjk>
        "9BE8": 0x5E44, // <cjk>
        "9BE9": 0x5E43, // <cjk>
        "9BEA": 0x5E40, // <cjk>
        "9BEB": 0x5E4E, // <cjk>
        "9BEC": 0x5E57, // <cjk>
        "9BED": 0x5E54, // <cjk>
        "9BEE": 0x5E5F, // <cjk>
        "9BEF": 0x5E62, // <cjk>
        "9BF0": 0x5E64, // <cjk>
        "9BF1": 0x5E47, // <cjk>
        "9BF2": 0x5E75, // <cjk>
        "9BF3": 0x5E76, // <cjk>
        "9BF4": 0x5E7A, // <cjk>
        "9BF5": 0x9EBC, // <cjk>
        "9BF6": 0x5E7F, // <cjk>
        "9BF7": 0x5EA0, // <cjk>
        "9BF8": 0x5EC1, // <cjk>
        "9BF9": 0x5EC2, // <cjk>
        "9BFA": 0x5EC8, // <cjk>
        "9BFB": 0x5ED0, // <cjk>
        "9BFC": 0x5ECF, // <cjk>
        "9C40": 0x5ED6, // <cjk>
        "9C41": 0x5EE3, // <cjk>
        "9C42": 0x5EDD, // <cjk>
        "9C43": 0x5EDA, // <cjk>
        "9C44": 0x5EDB, // <cjk>
        "9C45": 0x5EE2, // <cjk>
        "9C46": 0x5EE1, // <cjk>
        "9C47": 0x5EE8, // <cjk>
        "9C48": 0x5EE9, // <cjk>
        "9C49": 0x5EEC, // <cjk>
        "9C4A": 0x5EF1, // <cjk>
        "9C4B": 0x5EF3, // <cjk>
        "9C4C": 0x5EF0, // <cjk>
        "9C4D": 0x5EF4, // <cjk>
        "9C4E": 0x5EF8, // <cjk>
        "9C4F": 0x5EFE, // <cjk>
        "9C50": 0x5F03, // <cjk>
        "9C51": 0x5F09, // <cjk>
        "9C52": 0x5F5D, // <cjk>
        "9C53": 0x5F5C, // <cjk>
        "9C54": 0x5F0B, // <cjk>
        "9C55": 0x5F11, // <cjk>
        "9C56": 0x5F16, // <cjk>
        "9C57": 0x5F29, // <cjk>
        "9C58": 0x5F2D, // <cjk>
        "9C59": 0x5F38, // <cjk>
        "9C5A": 0x5F41, // <cjk>
        "9C5C": 0x5F4C, // <cjk>
        "9C5D": 0x5F4E, // <cjk>
        "9C5E": 0x5F2F, // <cjk>
        "9C5F": 0x5F51, // <cjk>
        "9C60": 0x5F56, // <cjk>
        "9C61": 0x5F57, // <cjk>
        "9C62": 0x5F59, // <cjk>
        "9C63": 0x5F61, // <cjk>
        "9C64": 0x5F6D, // <cjk>
        "9C65": 0x5F73, // <cjk>
        "9C66": 0x5F77, // <cjk>
        "9C67": 0x5F83, // <cjk>
        "9C68": 0x5F82, // <cjk>
        "9C69": 0x5F7F, // <cjk>
        "9C6A": 0x5F8A, // <cjk>
        "9C6B": 0x5F88, // <cjk>
        "9C6C": 0x5F91, // <cjk>
        "9C6D": 0x5F87, // <cjk>
        "9C6E": 0x5F9E, // <cjk>
        "9C6F": 0x5F99, // <cjk>
        "9C70": 0x5F98, // <cjk>
        "9C71": 0x5FA0, // <cjk>
        "9C72": 0x5FA8, // <cjk>
        "9C73": 0x5FAD, // <cjk>
        "9C74": 0x5FBC, // <cjk>
        "9C75": 0x5FD6, // <cjk>
        "9C76": 0x5FFB, // <cjk>
        "9C77": 0x5FE4, // <cjk>
        "9C78": 0x5FF8, // <cjk>
        "9C79": 0x5FF1, // <cjk>
        "9C7A": 0x5FDD, // <cjk>
        "9C7B": 0x60B3, // <cjk>
        "9C7C": 0x5FFF, // <cjk>
        "9C7D": 0x6021, // <cjk>
        "9C7E": 0x6060, // <cjk>
        "9C80": 0x6019, // <cjk>
        "9C81": 0x6010, // <cjk>
        "9C82": 0x6029, // <cjk>
        "9C83": 0x600E, // <cjk>
        "9C84": 0x6031, // <cjk>
        "9C85": 0x601B, // <cjk>
        "9C86": 0x6015, // <cjk>
        "9C87": 0x602B, // <cjk>
        "9C88": 0x6026, // <cjk>
        "9C89": 0x600F, // <cjk>
        "9C8A": 0x603A, // <cjk>
        "9C8B": 0x605A, // <cjk>
        "9C8C": 0x6041, // <cjk>
        "9C8D": 0x606A, // <cjk>
        "9C8E": 0x6077, // <cjk>
        "9C8F": 0x605F, // <cjk>
        "9C90": 0x604A, // <cjk>
        "9C91": 0x6046, // <cjk>
        "9C92": 0x604D, // <cjk>
        "9C93": 0x6063, // <cjk>
        "9C94": 0x6043, // <cjk>
        "9C95": 0x6064, // <cjk>
        "9C96": 0x6042, // <cjk>
        "9C97": 0x606C, // <cjk>
        "9C98": 0x606B, // <cjk>
        "9C99": 0x6059, // <cjk>
        "9C9A": 0x6081, // <cjk>
        "9C9B": 0x608D, // <cjk>
        "9C9C": 0x60E7, // <cjk>
        "9C9D": 0x6083, // <cjk>
        "9C9E": 0x609A, // <cjk>
        "9C9F": 0x6084, // <cjk>
        "9CA0": 0x609B, // <cjk>
        "9CA1": 0x6096, // <cjk>
        "9CA2": 0x6097, // <cjk>
        "9CA3": 0x6092, // <cjk>
        "9CA4": 0x60A7, // <cjk>
        "9CA5": 0x608B, // <cjk>
        "9CA6": 0x60E1, // <cjk>
        "9CA7": 0x60B8, // <cjk>
        "9CA8": 0x60E0, // <cjk>
        "9CA9": 0x60D3, // <cjk>
        "9CAA": 0x60B4, // <cjk>
        "9CAB": 0x5FF0, // <cjk>
        "9CAC": 0x60BD, // <cjk>
        "9CAD": 0x60C6, // <cjk>
        "9CAE": 0x60B5, // <cjk>
        "9CAF": 0x60D8, // <cjk>
        "9CB0": 0x614D, // <cjk>
        "9CB1": 0x6115, // <cjk>
        "9CB2": 0x6106, // <cjk>
        "9CB3": 0x60F6, // <cjk>
        "9CB4": 0x60F7, // <cjk>
        "9CB5": 0x6100, // <cjk>
        "9CB6": 0x60F4, // <cjk>
        "9CB7": 0x60FA, // <cjk>
        "9CB8": 0x6103, // <cjk>
        "9CB9": 0x6121, // <cjk>
        "9CBA": 0x60FB, // <cjk>
        "9CBB": 0x60F1, // <cjk>
        "9CBC": 0x610D, // <cjk>
        "9CBD": 0x610E, // <cjk>
        "9CBE": 0x6147, // <cjk>
        "9CBF": 0x613E, // <cjk>
        "9CC0": 0x6128, // <cjk>
        "9CC1": 0x6127, // <cjk>
        "9CC2": 0x614A, // <cjk>
        "9CC3": 0x613F, // <cjk>
        "9CC4": 0x613C, // <cjk>
        "9CC5": 0x612C, // <cjk>
        "9CC6": 0x6134, // <cjk>
        "9CC7": 0x613D, // <cjk>
        "9CC8": 0x6142, // <cjk>
        "9CC9": 0x6144, // <cjk>
        "9CCA": 0x6173, // <cjk>
        "9CCB": 0x6177, // <cjk>
        "9CCC": 0x6158, // <cjk>
        "9CCD": 0x6159, // <cjk>
        "9CCE": 0x615A, // <cjk>
        "9CCF": 0x616B, // <cjk>
        "9CD0": 0x6174, // <cjk>
        "9CD1": 0x616F, // <cjk>
        "9CD2": 0x6165, // <cjk>
        "9CD3": 0x6171, // <cjk>
        "9CD4": 0x615F, // <cjk>
        "9CD5": 0x615D, // <cjk>
        "9CD6": 0x6153, // <cjk>
        "9CD7": 0x6175, // <cjk>
        "9CD8": 0x6199, // <cjk>
        "9CD9": 0x6196, // <cjk>
        "9CDA": 0x6187, // <cjk>
        "9CDB": 0x61AC, // <cjk>
        "9CDC": 0x6194, // <cjk>
        "9CDD": 0x619A, // <cjk>
        "9CDE": 0x618A, // <cjk>
        "9CDF": 0x6191, // <cjk>
        "9CE0": 0x61AB, // <cjk>
        "9CE1": 0x61AE, // <cjk>
        "9CE2": 0x61CC, // <cjk>
        "9CE3": 0x61CA, // <cjk>
        "9CE4": 0x61C9, // <cjk>
        "9CE5": 0x61F7, // <cjk>
        "9CE6": 0x61C8, // <cjk>
        "9CE7": 0x61C3, // <cjk>
        "9CE8": 0x61C6, // <cjk>
        "9CE9": 0x61BA, // <cjk>
        "9CEA": 0x61CB, // <cjk>
        "9CEB": 0x7F79, // <cjk>
        "9CEC": 0x61CD, // <cjk>
        "9CED": 0x61E6, // <cjk>
        "9CEE": 0x61E3, // <cjk>
        "9CEF": 0x61F6, // <cjk>
        "9CF0": 0x61FA, // <cjk>
        "9CF1": 0x61F4, // <cjk>
        "9CF2": 0x61FF, // <cjk>
        "9CF3": 0x61FD, // <cjk>
        "9CF4": 0x61FC, // <cjk>
        "9CF5": 0x61FE, // <cjk>
        "9CF6": 0x6200, // <cjk>
        "9CF7": 0x6208, // <cjk>
        "9CF8": 0x6209, // <cjk>
        "9CF9": 0x620D, // <cjk>
        "9CFA": 0x620C, // <cjk>
        "9CFB": 0x6214, // <cjk>
        "9CFC": 0x621B, // <cjk>
        "9D40": 0x621E, // <cjk>
        "9D41": 0x6221, // <cjk>
        "9D42": 0x622A, // <cjk>
        "9D43": 0x622E, // <cjk>
        "9D44": 0x6230, // <cjk>
        "9D45": 0x6232, // <cjk>
        "9D46": 0x6233, // <cjk>
        "9D47": 0x6241, // <cjk>
        "9D48": 0x624E, // <cjk>
        "9D49": 0x625E, // <cjk>
        "9D4A": 0x6263, // <cjk>
        "9D4B": 0x625B, // <cjk>
        "9D4C": 0x6260, // <cjk>
        "9D4D": 0x6268, // <cjk>
        "9D4E": 0x627C, // <cjk>
        "9D4F": 0x6282, // <cjk>
        "9D50": 0x6289, // <cjk>
        "9D51": 0x627E, // <cjk>
        "9D52": 0x6292, // <cjk>
        "9D53": 0x6293, // <cjk>
        "9D54": 0x6296, // <cjk>
        "9D55": 0x62D4, // <cjk>
        "9D56": 0x6283, // <cjk>
        "9D57": 0x6294, // <cjk>
        "9D58": 0x62D7, // <cjk>
        "9D59": 0x62D1, // <cjk>
        "9D5A": 0x62BB, // <cjk>
        "9D5C": 0x62FF, // <cjk>
        "9D5D": 0x62C6, // <cjk>
        "9D5E": 0x64D4, // <cjk>
        "9D5F": 0x62C8, // <cjk>
        "9D60": 0x62DC, // <cjk>
        "9D61": 0x62CC, // <cjk>
        "9D62": 0x62CA, // <cjk>
        "9D63": 0x62C2, // <cjk>
        "9D64": 0x62C7, // <cjk>
        "9D65": 0x629B, // <cjk>
        "9D66": 0x62C9, // <cjk>
        "9D67": 0x630C, // <cjk>
        "9D68": 0x62EE, // <cjk>
        "9D69": 0x62F1, // <cjk>
        "9D6A": 0x6327, // <cjk>
        "9D6B": 0x6302, // <cjk>
        "9D6C": 0x6308, // <cjk>
        "9D6D": 0x62EF, // <cjk>
        "9D6E": 0x62F5, // <cjk>
        "9D6F": 0x6350, // <cjk>
        "9D70": 0x633E, // <cjk>
        "9D71": 0x634D, // <cjk>
        "9D72": 0x641C, // <cjk>
        "9D73": 0x634F, // <cjk>
        "9D74": 0x6396, // <cjk>
        "9D75": 0x638E, // <cjk>
        "9D76": 0x6380, // <cjk>
        "9D77": 0x63AB, // <cjk>
        "9D78": 0x6376, // <cjk>
        "9D79": 0x63A3, // <cjk>
        "9D7A": 0x638F, // <cjk>
        "9D7B": 0x6389, // <cjk>
        "9D7C": 0x639F, // <cjk>
        "9D7D": 0x63B5, // <cjk>
        "9D7E": 0x636B, // <cjk>
        "9D80": 0x6369, // <cjk>
        "9D81": 0x63BE, // <cjk>
        "9D82": 0x63E9, // <cjk>
        "9D83": 0x63C0, // <cjk>
        "9D84": 0x63C6, // <cjk>
        "9D85": 0x63E3, // <cjk>
        "9D86": 0x63C9, // <cjk>
        "9D87": 0x63D2, // <cjk>
        "9D88": 0x63F6, // <cjk>
        "9D89": 0x63C4, // <cjk>
        "9D8A": 0x6416, // <cjk>
        "9D8B": 0x6434, // <cjk>
        "9D8C": 0x6406, // <cjk>
        "9D8D": 0x6413, // <cjk>
        "9D8E": 0x6426, // <cjk>
        "9D8F": 0x6436, // <cjk>
        "9D90": 0x651D, // <cjk>
        "9D91": 0x6417, // <cjk>
        "9D92": 0x6428, // <cjk>
        "9D93": 0x640F, // <cjk>
        "9D94": 0x6467, // <cjk>
        "9D95": 0x646F, // <cjk>
        "9D96": 0x6476, // <cjk>
        "9D97": 0x644E, // <cjk>
        "9D98": 0x652A, // <cjk>
        "9D99": 0x6495, // <cjk>
        "9D9A": 0x6493, // <cjk>
        "9D9B": 0x64A5, // <cjk>
        "9D9C": 0x64A9, // <cjk>
        "9D9D": 0x6488, // <cjk>
        "9D9E": 0x64BC, // <cjk>
        "9D9F": 0x64DA, // <cjk>
        "9DA0": 0x64D2, // <cjk>
        "9DA1": 0x64C5, // <cjk>
        "9DA2": 0x64C7, // <cjk>
        "9DA3": 0x64BB, // <cjk>
        "9DA4": 0x64D8, // <cjk>
        "9DA5": 0x64C2, // <cjk>
        "9DA6": 0x64F1, // <cjk>
        "9DA7": 0x64E7, // <cjk>
        "9DA8": 0x8209, // <cjk>
        "9DA9": 0x64E0, // <cjk>
        "9DAA": 0x64E1, // <cjk>
        "9DAB": 0x62AC, // <cjk>
        "9DAC": 0x64E3, // <cjk>
        "9DAD": 0x64EF, // <cjk>
        "9DAE": 0x652C, // <cjk>
        "9DAF": 0x64F6, // <cjk>
        "9DB0": 0x64F4, // <cjk>
        "9DB1": 0x64F2, // <cjk>
        "9DB2": 0x64FA, // <cjk>
        "9DB3": 0x6500, // <cjk>
        "9DB4": 0x64FD, // <cjk>
        "9DB5": 0x6518, // <cjk>
        "9DB6": 0x651C, // <cjk>
        "9DB7": 0x6505, // <cjk>
        "9DB8": 0x6524, // <cjk>
        "9DB9": 0x6523, // <cjk>
        "9DBA": 0x652B, // <cjk>
        "9DBB": 0x6534, // <cjk>
        "9DBC": 0x6535, // <cjk>
        "9DBD": 0x6537, // <cjk>
        "9DBE": 0x6536, // <cjk>
        "9DBF": 0x6538, // <cjk>
        "9DC0": 0x754B, // <cjk>
        "9DC1": 0x6548, // <cjk>
        "9DC2": 0x6556, // <cjk>
        "9DC3": 0x6555, // <cjk>
        "9DC4": 0x654D, // <cjk>
        "9DC5": 0x6558, // <cjk>
        "9DC6": 0x655E, // <cjk>
        "9DC7": 0x655D, // <cjk>
        "9DC8": 0x6572, // <cjk>
        "9DC9": 0x6578, // <cjk>
        "9DCA": 0x6582, // <cjk>
        "9DCB": 0x6583, // <cjk>
        "9DCC": 0x8B8A, // <cjk>
        "9DCD": 0x659B, // <cjk>
        "9DCE": 0x659F, // <cjk>
        "9DCF": 0x65AB, // <cjk>
        "9DD0": 0x65B7, // <cjk>
        "9DD1": 0x65C3, // <cjk>
        "9DD2": 0x65C6, // <cjk>
        "9DD3": 0x65C1, // <cjk>
        "9DD4": 0x65C4, // <cjk>
        "9DD5": 0x65CC, // <cjk>
        "9DD6": 0x65D2, // <cjk>
        "9DD7": 0x65DB, // <cjk>
        "9DD8": 0x65D9, // <cjk>
        "9DD9": 0x65E0, // <cjk>
        "9DDA": 0x65E1, // <cjk>
        "9DDB": 0x65F1, // <cjk>
        "9DDC": 0x6772, // <cjk>
        "9DDD": 0x660A, // <cjk>
        "9DDE": 0x6603, // <cjk>
        "9DDF": 0x65FB, // <cjk>
        "9DE0": 0x6773, // <cjk>
        "9DE1": 0x6635, // <cjk>
        "9DE2": 0x6636, // <cjk>
        "9DE3": 0x6634, // <cjk>
        "9DE4": 0x661C, // <cjk>
        "9DE5": 0x664F, // <cjk>
        "9DE6": 0x6644, // <cjk>
        "9DE7": 0x6649, // <cjk>
        "9DE8": 0x6641, // <cjk>
        "9DE9": 0x665E, // <cjk>
        "9DEA": 0x665D, // <cjk>
        "9DEB": 0x6664, // <cjk>
        "9DEC": 0x6667, // <cjk>
        "9DED": 0x6668, // <cjk>
        "9DEE": 0x665F, // <cjk>
        "9DEF": 0x6662, // <cjk>
        "9DF0": 0x6670, // <cjk>
        "9DF1": 0x6683, // <cjk>
        "9DF2": 0x6688, // <cjk>
        "9DF3": 0x668E, // <cjk>
        "9DF4": 0x6689, // <cjk>
        "9DF5": 0x6684, // <cjk>
        "9DF6": 0x6698, // <cjk>
        "9DF7": 0x669D, // <cjk>
        "9DF8": 0x66C1, // <cjk>
        "9DF9": 0x66B9, // <cjk>
        "9DFA": 0x66C9, // <cjk>
        "9DFB": 0x66BE, // <cjk>
        "9DFC": 0x66BC, // <cjk>
        "9E40": 0x66C4, // <cjk>
        "9E41": 0x66B8, // <cjk>
        "9E42": 0x66D6, // <cjk>
        "9E43": 0x66DA, // <cjk>
        "9E44": 0x66E0, // <cjk>
        "9E45": 0x663F, // <cjk>
        "9E46": 0x66E6, // <cjk>
        "9E47": 0x66E9, // <cjk>
        "9E48": 0x66F0, // <cjk>
        "9E49": 0x66F5, // <cjk>
        "9E4A": 0x66F7, // <cjk>
        "9E4B": 0x670F, // <cjk>
        "9E4C": 0x6716, // <cjk>
        "9E4D": 0x671E, // <cjk>
        "9E4E": 0x6726, // <cjk>
        "9E4F": 0x6727, // <cjk>
        "9E50": 0x9738, // <cjk>
        "9E51": 0x672E, // <cjk>
        "9E52": 0x673F, // <cjk>
        "9E53": 0x6736, // <cjk>
        "9E54": 0x6741, // <cjk>
        "9E55": 0x6738, // <cjk>
        "9E56": 0x6737, // <cjk>
        "9E57": 0x6746, // <cjk>
        "9E58": 0x675E, // <cjk>
        "9E59": 0x6760, // <cjk>
        "9E5A": 0x6759, // <cjk>
        "9E5C": 0x6764, // <cjk>
        "9E5D": 0x6789, // <cjk>
        "9E5E": 0x6770, // <cjk>
        "9E5F": 0x67A9, // <cjk>
        "9E60": 0x677C, // <cjk>
        "9E61": 0x676A, // <cjk>
        "9E62": 0x678C, // <cjk>
        "9E63": 0x678B, // <cjk>
        "9E64": 0x67A6, // <cjk>
        "9E65": 0x67A1, // <cjk>
        "9E66": 0x6785, // <cjk>
        "9E67": 0x67B7, // <cjk>
        "9E68": 0x67EF, // <cjk>
        "9E69": 0x67B4, // <cjk>
        "9E6A": 0x67EC, // <cjk>
        "9E6B": 0x67B3, // <cjk>
        "9E6C": 0x67E9, // <cjk>
        "9E6D": 0x67B8, // <cjk>
        "9E6E": 0x67E4, // <cjk>
        "9E6F": 0x67DE, // <cjk>
        "9E70": 0x67DD, // <cjk>
        "9E71": 0x67E2, // <cjk>
        "9E72": 0x67EE, // <cjk>
        "9E73": 0x67B9, // <cjk>
        "9E74": 0x67CE, // <cjk>
        "9E75": 0x67C6, // <cjk>
        "9E76": 0x67E7, // <cjk>
        "9E77": 0x6A9C, // <cjk>
        "9E78": 0x681E, // <cjk>
        "9E79": 0x6846, // <cjk>
        "9E7A": 0x6829, // <cjk>
        "9E7B": 0x6840, // <cjk>
        "9E7C": 0x684D, // <cjk>
        "9E7D": 0x6832, // <cjk>
        "9E7E": 0x684E, // <cjk>
        "9E80": 0x68B3, // <cjk>
        "9E81": 0x682B, // <cjk>
        "9E82": 0x6859, // <cjk>
        "9E83": 0x6863, // <cjk>
        "9E84": 0x6877, // <cjk>
        "9E85": 0x687F, // <cjk>
        "9E86": 0x689F, // <cjk>
        "9E87": 0x688F, // <cjk>
        "9E88": 0x68AD, // <cjk>
        "9E89": 0x6894, // <cjk>
        "9E8A": 0x689D, // <cjk>
        "9E8B": 0x689B, // <cjk>
        "9E8C": 0x6883, // <cjk>
        "9E8D": 0x6AAE, // <cjk>
        "9E8E": 0x68B9, // <cjk>
        "9E8F": 0x6874, // <cjk>
        "9E90": 0x68B5, // <cjk>
        "9E91": 0x68A0, // <cjk>
        "9E92": 0x68BA, // <cjk>
        "9E93": 0x690F, // <cjk>
        "9E94": 0x688D, // <cjk>
        "9E95": 0x687E, // <cjk>
        "9E96": 0x6901, // <cjk>
        "9E97": 0x68CA, // <cjk>
        "9E98": 0x6908, // <cjk>
        "9E99": 0x68D8, // <cjk>
        "9E9A": 0x6922, // <cjk>
        "9E9B": 0x6926, // <cjk>
        "9E9C": 0x68E1, // <cjk>
        "9E9D": 0x690C, // <cjk>
        "9E9E": 0x68CD, // <cjk>
        "9E9F": 0x68D4, // <cjk>
        "9EA0": 0x68E7, // <cjk>
        "9EA1": 0x68D5, // <cjk>
        "9EA2": 0x6936, // <cjk>
        "9EA3": 0x6912, // <cjk>
        "9EA4": 0x6904, // <cjk>
        "9EA5": 0x68D7, // <cjk>
        "9EA6": 0x68E3, // <cjk>
        "9EA7": 0x6925, // <cjk>
        "9EA8": 0x68F9, // <cjk>
        "9EA9": 0x68E0, // <cjk>
        "9EAA": 0x68EF, // <cjk>
        "9EAB": 0x6928, // <cjk>
        "9EAC": 0x692A, // <cjk>
        "9EAD": 0x691A, // <cjk>
        "9EAE": 0x6923, // <cjk>
        "9EAF": 0x6921, // <cjk>
        "9EB0": 0x68C6, // <cjk>
        "9EB1": 0x6979, // <cjk>
        "9EB2": 0x6977, // <cjk>
        "9EB3": 0x695C, // <cjk>
        "9EB4": 0x6978, // <cjk>
        "9EB5": 0x696B, // <cjk>
        "9EB6": 0x6954, // <cjk>
        "9EB7": 0x697E, // <cjk>
        "9EB8": 0x696E, // <cjk>
        "9EB9": 0x6939, // <cjk>
        "9EBA": 0x6974, // <cjk>
        "9EBB": 0x693D, // <cjk>
        "9EBC": 0x6959, // <cjk>
        "9EBD": 0x6930, // <cjk>
        "9EBE": 0x6961, // <cjk>
        "9EBF": 0x695E, // <cjk>
        "9EC0": 0x695D, // <cjk>
        "9EC1": 0x6981, // <cjk>
        "9EC2": 0x696A, // <cjk>
        "9EC3": 0x69B2, // <cjk>
        "9EC4": 0x69AE, // <cjk>
        "9EC5": 0x69D0, // <cjk>
        "9EC6": 0x69BF, // <cjk>
        "9EC7": 0x69C1, // <cjk>
        "9EC8": 0x69D3, // <cjk>
        "9EC9": 0x69BE, // <cjk>
        "9ECA": 0x69CE, // <cjk>
        "9ECB": 0x5BE8, // <cjk>
        "9ECC": 0x69CA, // <cjk>
        "9ECD": 0x69DD, // <cjk>
        "9ECE": 0x69BB, // <cjk>
        "9ECF": 0x69C3, // <cjk>
        "9ED0": 0x69A7, // <cjk>
        "9ED1": 0x6A2E, // <cjk>
        "9ED2": 0x6991, // <cjk>
        "9ED3": 0x69A0, // <cjk>
        "9ED4": 0x699C, // <cjk>
        "9ED5": 0x6995, // <cjk>
        "9ED6": 0x69B4, // <cjk>
        "9ED7": 0x69DE, // <cjk>
        "9ED8": 0x69E8, // <cjk>
        "9ED9": 0x6A02, // <cjk>
        "9EDA": 0x6A1B, // <cjk>
        "9EDB": 0x69FF, // <cjk>
        "9EDC": 0x6B0A, // <cjk>
        "9EDD": 0x69F9, // <cjk>
        "9EDE": 0x69F2, // <cjk>
        "9EDF": 0x69E7, // <cjk>
        "9EE0": 0x6A05, // <cjk>
        "9EE1": 0x69B1, // <cjk>
        "9EE2": 0x6A1E, // <cjk>
        "9EE3": 0x69ED, // <cjk>
        "9EE4": 0x6A14, // <cjk>
        "9EE5": 0x69EB, // <cjk>
        "9EE6": 0x6A0A, // <cjk>
        "9EE7": 0x6A12, // <cjk>
        "9EE8": 0x6AC1, // <cjk>
        "9EE9": 0x6A23, // <cjk>
        "9EEA": 0x6A13, // <cjk>
        "9EEB": 0x6A44, // <cjk>
        "9EEC": 0x6A0C, // <cjk>
        "9EED": 0x6A72, // <cjk>
        "9EEE": 0x6A36, // <cjk>
        "9EEF": 0x6A78, // <cjk>
        "9EF0": 0x6A47, // <cjk>
        "9EF1": 0x6A62, // <cjk>
        "9EF2": 0x6A59, // <cjk>
        "9EF3": 0x6A66, // <cjk>
        "9EF4": 0x6A48, // <cjk>
        "9EF5": 0x6A38, // <cjk>
        "9EF6": 0x6A22, // <cjk>
        "9EF7": 0x6A90, // <cjk>
        "9EF8": 0x6A8D, // <cjk>
        "9EF9": 0x6AA0, // <cjk>
        "9EFA": 0x6A84, // <cjk>
        "9EFB": 0x6AA2, // <cjk>
        "9EFC": 0x6AA3, // <cjk>
        "9F40": 0x6A97, // <cjk>
        "9F41": 0x8617, // <cjk>
        "9F42": 0x6ABB, // <cjk>
        "9F43": 0x6AC3, // <cjk>
        "9F44": 0x6AC2, // <cjk>
        "9F45": 0x6AB8, // <cjk>
        "9F46": 0x6AB3, // <cjk>
        "9F47": 0x6AAC, // <cjk>
        "9F48": 0x6ADE, // <cjk>
        "9F49": 0x6AD1, // <cjk>
        "9F4A": 0x6ADF, // <cjk>
        "9F4B": 0x6AAA, // <cjk>
        "9F4C": 0x6ADA, // <cjk>
        "9F4D": 0x6AEA, // <cjk>
        "9F4E": 0x6AFB, // <cjk>
        "9F4F": 0x6B05, // <cjk>
        "9F50": 0x8616, // <cjk>
        "9F51": 0x6AFA, // <cjk>
        "9F52": 0x6B12, // <cjk>
        "9F53": 0x6B16, // <cjk>
        "9F54": 0x9B31, // <cjk>
        "9F55": 0x6B1F, // <cjk>
        "9F56": 0x6B38, // <cjk>
        "9F57": 0x6B37, // <cjk>
        "9F58": 0x76DC, // <cjk>
        "9F59": 0x6B39, // <cjk>
        "9F5A": 0x98EE, // <cjk>
        "9F5C": 0x6B43, // <cjk>
        "9F5D": 0x6B49, // <cjk>
        "9F5E": 0x6B50, // <cjk>
        "9F5F": 0x6B59, // <cjk>
        "9F60": 0x6B54, // <cjk>
        "9F61": 0x6B5B, // <cjk>
        "9F62": 0x6B5F, // <cjk>
        "9F63": 0x6B61, // <cjk>
        "9F64": 0x6B78, // <cjk>
        "9F65": 0x6B79, // <cjk>
        "9F66": 0x6B7F, // <cjk>
        "9F67": 0x6B80, // <cjk>
        "9F68": 0x6B84, // <cjk>
        "9F69": 0x6B83, // <cjk>
        "9F6A": 0x6B8D, // <cjk>
        "9F6B": 0x6B98, // <cjk>
        "9F6C": 0x6B95, // <cjk>
        "9F6D": 0x6B9E, // <cjk>
        "9F6E": 0x6BA4, // <cjk>
        "9F6F": 0x6BAA, // <cjk>
        "9F70": 0x6BAB, // <cjk>
        "9F71": 0x6BAF, // <cjk>
        "9F72": 0x6BB2, // <cjk>
        "9F73": 0x6BB1, // <cjk>
        "9F74": 0x6BB3, // <cjk>
        "9F75": 0x6BB7, // <cjk>
        "9F76": 0x6BBC, // <cjk>
        "9F77": 0x6BC6, // <cjk>
        "9F78": 0x6BCB, // <cjk>
        "9F79": 0x6BD3, // <cjk>
        "9F7A": 0x6BDF, // <cjk>
        "9F7B": 0x6BEC, // <cjk>
        "9F7C": 0x6BEB, // <cjk>
        "9F7D": 0x6BF3, // <cjk>
        "9F7E": 0x6BEF, // <cjk>
        "9F80": 0x9EBE, // <cjk>
        "9F81": 0x6C08, // <cjk>
        "9F82": 0x6C13, // <cjk>
        "9F83": 0x6C14, // <cjk>
        "9F84": 0x6C1B, // <cjk>
        "9F85": 0x6C24, // <cjk>
        "9F86": 0x6C23, // <cjk>
        "9F87": 0x6C5E, // <cjk>
        "9F88": 0x6C55, // <cjk>
        "9F89": 0x6C62, // <cjk>
        "9F8A": 0x6C6A, // <cjk>
        "9F8B": 0x6C82, // <cjk>
        "9F8C": 0x6C8D, // <cjk>
        "9F8D": 0x6C9A, // <cjk>
        "9F8E": 0x6C81, // <cjk>
        "9F8F": 0x6C9B, // <cjk>
        "9F90": 0x6C7E, // <cjk>
        "9F91": 0x6C68, // <cjk>
        "9F92": 0x6C73, // <cjk>
        "9F93": 0x6C92, // <cjk>
        "9F94": 0x6C90, // <cjk>
        "9F95": 0x6CC4, // <cjk>
        "9F96": 0x6CF1, // <cjk>
        "9F97": 0x6CD3, // <cjk>
        "9F98": 0x6CBD, // <cjk>
        "9F99": 0x6CD7, // <cjk>
        "9F9A": 0x6CC5, // <cjk>
        "9F9B": 0x6CDD, // <cjk>
        "9F9C": 0x6CAE, // <cjk>
        "9F9D": 0x6CB1, // <cjk>
        "9F9E": 0x6CBE, // <cjk>
        "9F9F": 0x6CBA, // <cjk>
        "9FA0": 0x6CDB, // <cjk>
        "9FA1": 0x6CEF, // <cjk>
        "9FA2": 0x6CD9, // <cjk>
        "9FA3": 0x6CEA, // <cjk>
        "9FA4": 0x6D1F, // <cjk>
        "9FA5": 0x884D, // <cjk>
        "9FA6": 0x6D36, // <cjk>
        "9FA7": 0x6D2B, // <cjk>
        "9FA8": 0x6D3D, // <cjk>
        "9FA9": 0x6D38, // <cjk>
        "9FAA": 0x6D19, // <cjk>
        "9FAB": 0x6D35, // <cjk>
        "9FAC": 0x6D33, // <cjk>
        "9FAD": 0x6D12, // <cjk>
        "9FAE": 0x6D0C, // <cjk>
        "9FAF": 0x6D63, // <cjk>
        "9FB0": 0x6D93, // <cjk>
        "9FB1": 0x6D64, // <cjk>
        "9FB2": 0x6D5A, // <cjk>
        "9FB3": 0x6D79, // <cjk>
        "9FB4": 0x6D59, // <cjk>
        "9FB5": 0x6D8E, // <cjk>
        "9FB6": 0x6D95, // <cjk>
        "9FB7": 0x6FE4, // <cjk>
        "9FB8": 0x6D85, // <cjk>
        "9FB9": 0x6DF9, // <cjk>
        "9FBA": 0x6E15, // <cjk>
        "9FBB": 0x6E0A, // <cjk>
        "9FBC": 0x6DB5, // <cjk>
        "9FBD": 0x6DC7, // <cjk>
        "9FBE": 0x6DE6, // <cjk>
        "9FBF": 0x6DB8, // <cjk>
        "9FC0": 0x6DC6, // <cjk>
        "9FC1": 0x6DEC, // <cjk>
        "9FC2": 0x6DDE, // <cjk>
        "9FC3": 0x6DCC, // <cjk>
        "9FC4": 0x6DE8, // <cjk>
        "9FC5": 0x6DD2, // <cjk>
        "9FC6": 0x6DC5, // <cjk>
        "9FC7": 0x6DFA, // <cjk>
        "9FC8": 0x6DD9, // <cjk>
        "9FC9": 0x6DE4, // <cjk>
        "9FCA": 0x6DD5, // <cjk>
        "9FCB": 0x6DEA, // <cjk>
        "9FCC": 0x6DEE, // <cjk>
        "9FCD": 0x6E2D, // <cjk>
        "9FCE": 0x6E6E, // <cjk>
        "9FCF": 0x6E2E, // <cjk>
        "9FD0": 0x6E19, // <cjk>
        "9FD1": 0x6E72, // <cjk>
        "9FD2": 0x6E5F, // <cjk>
        "9FD3": 0x6E3E, // <cjk>
        "9FD4": 0x6E23, // <cjk>
        "9FD5": 0x6E6B, // <cjk>
        "9FD6": 0x6E2B, // <cjk>
        "9FD7": 0x6E76, // <cjk>
        "9FD8": 0x6E4D, // <cjk>
        "9FD9": 0x6E1F, // <cjk>
        "9FDA": 0x6E43, // <cjk>
        "9FDB": 0x6E3A, // <cjk>
        "9FDC": 0x6E4E, // <cjk>
        "9FDD": 0x6E24, // <cjk>
        "9FDE": 0x6EFF, // <cjk>
        "9FDF": 0x6E1D, // <cjk>
        "9FE0": 0x6E38, // <cjk>
        "9FE1": 0x6E82, // <cjk>
        "9FE2": 0x6EAA, // <cjk>
        "9FE3": 0x6E98, // <cjk>
        "9FE4": 0x6EC9, // <cjk>
        "9FE5": 0x6EB7, // <cjk>
        "9FE6": 0x6ED3, // <cjk>
        "9FE7": 0x6EBD, // <cjk>
        "9FE8": 0x6EAF, // <cjk>
        "9FE9": 0x6EC4, // <cjk>
        "9FEA": 0x6EB2, // <cjk>
        "9FEB": 0x6ED4, // <cjk>
        "9FEC": 0x6ED5, // <cjk>
        "9FED": 0x6E8F, // <cjk>
        "9FEE": 0x6EA5, // <cjk>
        "9FEF": 0x6EC2, // <cjk>
        "9FF0": 0x6E9F, // <cjk>
        "9FF1": 0x6F41, // <cjk>
        "9FF2": 0x6F11, // <cjk>
        "9FF3": 0x704C, // <cjk>
        "9FF4": 0x6EEC, // <cjk>
        "9FF5": 0x6EF8, // <cjk>
        "9FF6": 0x6EFE, // <cjk>
        "9FF7": 0x6F3F, // <cjk>
        "9FF8": 0x6EF2, // <cjk>
        "9FF9": 0x6F31, // <cjk>
        "9FFA": 0x6EEF, // <cjk>
        "9FFB": 0x6F32, // <cjk>
        "9FFC": 0x6ECC, // <cjk>
        "E040": 0x6F3E, // <cjk>
        "E041": 0x6F13, // <cjk>
        "E042": 0x6EF7, // <cjk>
        "E043": 0x6F86, // <cjk>
        "E044": 0x6F7A, // <cjk>
        "E045": 0x6F78, // <cjk>
        "E046": 0x6F81, // <cjk>
        "E047": 0x6F80, // <cjk>
        "E048": 0x6F6F, // <cjk>
        "E049": 0x6F5B, // <cjk>
        "E04A": 0x6FF3, // <cjk>
        "E04B": 0x6F6D, // <cjk>
        "E04C": 0x6F82, // <cjk>
        "E04D": 0x6F7C, // <cjk>
        "E04E": 0x6F58, // <cjk>
        "E04F": 0x6F8E, // <cjk>
        "E050": 0x6F91, // <cjk>
        "E051": 0x6FC2, // <cjk>
        "E052": 0x6F66, // <cjk>
        "E053": 0x6FB3, // <cjk>
        "E054": 0x6FA3, // <cjk>
        "E055": 0x6FA1, // <cjk>
        "E056": 0x6FA4, // <cjk>
        "E057": 0x6FB9, // <cjk>
        "E058": 0x6FC6, // <cjk>
        "E059": 0x6FAA, // <cjk>
        "E05A": 0x6FDF, // <cjk>
        "E05C": 0x6FEC, // <cjk>
        "E05D": 0x6FD4, // <cjk>
        "E05E": 0x6FD8, // <cjk>
        "E05F": 0x6FF1, // <cjk>
        "E060": 0x6FEE, // <cjk>
        "E061": 0x6FDB, // <cjk>
        "E062": 0x7009, // <cjk>
        "E063": 0x700B, // <cjk>
        "E064": 0x6FFA, // <cjk>
        "E065": 0x7011, // <cjk>
        "E066": 0x7001, // <cjk>
        "E067": 0x700F, // <cjk>
        "E068": 0x6FFE, // <cjk>
        "E069": 0x701B, // <cjk>
        "E06A": 0x701A, // <cjk>
        "E06B": 0x6F74, // <cjk>
        "E06C": 0x701D, // <cjk>
        "E06D": 0x7018, // <cjk>
        "E06E": 0x701F, // <cjk>
        "E06F": 0x7030, // <cjk>
        "E070": 0x703E, // <cjk>
        "E071": 0x7032, // <cjk>
        "E072": 0x7051, // <cjk>
        "E073": 0x7063, // <cjk>
        "E074": 0x7099, // <cjk>
        "E075": 0x7092, // <cjk>
        "E076": 0x70AF, // <cjk>
        "E077": 0x70F1, // <cjk>
        "E078": 0x70AC, // <cjk>
        "E079": 0x70B8, // <cjk>
        "E07A": 0x70B3, // <cjk>
        "E07B": 0x70AE, // <cjk>
        "E07C": 0x70DF, // <cjk>
        "E07D": 0x70CB, // <cjk>
        "E07E": 0x70DD, // <cjk>
        "E080": 0x70D9, // <cjk>
        "E081": 0x7109, // <cjk>
        "E082": 0x70FD, // <cjk>
        "E083": 0x711C, // <cjk>
        "E084": 0x7119, // <cjk>
        "E085": 0x7165, // <cjk>
        "E086": 0x7155, // <cjk>
        "E087": 0x7188, // <cjk>
        "E088": 0x7166, // <cjk>
        "E089": 0x7162, // <cjk>
        "E08A": 0x714C, // <cjk>
        "E08B": 0x7156, // <cjk>
        "E08C": 0x716C, // <cjk>
        "E08D": 0x718F, // <cjk>
        "E08E": 0x71FB, // <cjk>
        "E08F": 0x7184, // <cjk>
        "E090": 0x7195, // <cjk>
        "E091": 0x71A8, // <cjk>
        "E092": 0x71AC, // <cjk>
        "E093": 0x71D7, // <cjk>
        "E094": 0x71B9, // <cjk>
        "E095": 0x71BE, // <cjk>
        "E096": 0x71D2, // <cjk>
        "E097": 0x71C9, // <cjk>
        "E098": 0x71D4, // <cjk>
        "E099": 0x71CE, // <cjk>
        "E09A": 0x71E0, // <cjk>
        "E09B": 0x71EC, // <cjk>
        "E09C": 0x71E7, // <cjk>
        "E09D": 0x71F5, // <cjk>
        "E09E": 0x71FC, // <cjk>
        "E09F": 0x71F9, // <cjk>
        "E0A0": 0x71FF, // <cjk>
        "E0A1": 0x720D, // <cjk>
        "E0A2": 0x7210, // <cjk>
        "E0A3": 0x721B, // <cjk>
        "E0A4": 0x7228, // <cjk>
        "E0A5": 0x722D, // <cjk>
        "E0A6": 0x722C, // <cjk>
        "E0A7": 0x7230, // <cjk>
        "E0A8": 0x7232, // <cjk>
        "E0A9": 0x723B, // <cjk>
        "E0AA": 0x723C, // <cjk>
        "E0AB": 0x723F, // <cjk>
        "E0AC": 0x7240, // <cjk>
        "E0AD": 0x7246, // <cjk>
        "E0AE": 0x724B, // <cjk>
        "E0AF": 0x7258, // <cjk>
        "E0B0": 0x7274, // <cjk>
        "E0B1": 0x727E, // <cjk>
        "E0B2": 0x7282, // <cjk>
        "E0B3": 0x7281, // <cjk>
        "E0B4": 0x7287, // <cjk>
        "E0B5": 0x7292, // <cjk>
        "E0B6": 0x7296, // <cjk>
        "E0B7": 0x72A2, // <cjk>
        "E0B8": 0x72A7, // <cjk>
        "E0B9": 0x72B9, // <cjk>
        "E0BA": 0x72B2, // <cjk>
        "E0BB": 0x72C3, // <cjk>
        "E0BC": 0x72C6, // <cjk>
        "E0BD": 0x72C4, // <cjk>
        "E0BE": 0x72CE, // <cjk>
        "E0BF": 0x72D2, // <cjk>
        "E0C0": 0x72E2, // <cjk>
        "E0C1": 0x72E0, // <cjk>
        "E0C2": 0x72E1, // <cjk>
        "E0C3": 0x72F9, // <cjk>
        "E0C4": 0x72F7, // <cjk>
        "E0C5": 0x500F, // <cjk>
        "E0C6": 0x7317, // <cjk>
        "E0C7": 0x730A, // <cjk>
        "E0C8": 0x731C, // <cjk>
        "E0C9": 0x7316, // <cjk>
        "E0CA": 0x731D, // <cjk>
        "E0CB": 0x7334, // <cjk>
        "E0CC": 0x732F, // <cjk>
        "E0CD": 0x7329, // <cjk>
        "E0CE": 0x7325, // <cjk>
        "E0CF": 0x733E, // <cjk>
        "E0D0": 0x734E, // <cjk>
        "E0D1": 0x734F, // <cjk>
        "E0D2": 0x9ED8, // <cjk>
        "E0D3": 0x7357, // <cjk>
        "E0D4": 0x736A, // <cjk>
        "E0D5": 0x7368, // <cjk>
        "E0D6": 0x7370, // <cjk>
        "E0D7": 0x7378, // <cjk>
        "E0D8": 0x7375, // <cjk>
        "E0D9": 0x737B, // <cjk>
        "E0DA": 0x737A, // <cjk>
        "E0DB": 0x73C8, // <cjk>
        "E0DC": 0x73B3, // <cjk>
        "E0DD": 0x73CE, // <cjk>
        "E0DE": 0x73BB, // <cjk>
        "E0DF": 0x73C0, // <cjk>
        "E0E0": 0x73E5, // <cjk>
        "E0E1": 0x73EE, // <cjk>
        "E0E2": 0x73DE, // <cjk>
        "E0E3": 0x74A2, // <cjk>
        "E0E4": 0x7405, // <cjk>
        "E0E5": 0x746F, // <cjk>
        "E0E6": 0x7425, // <cjk>
        "E0E7": 0x73F8, // <cjk>
        "E0E8": 0x7432, // <cjk>
        "E0E9": 0x743A, // <cjk>
        "E0EA": 0x7455, // <cjk>
        "E0EB": 0x743F, // <cjk>
        "E0EC": 0x745F, // <cjk>
        "E0ED": 0x7459, // <cjk>
        "E0EE": 0x7441, // <cjk>
        "E0EF": 0x745C, // <cjk>
        "E0F0": 0x7469, // <cjk>
        "E0F1": 0x7470, // <cjk>
        "E0F2": 0x7463, // <cjk>
        "E0F3": 0x746A, // <cjk>
        "E0F4": 0x7476, // <cjk>
        "E0F5": 0x747E, // <cjk>
        "E0F6": 0x748B, // <cjk>
        "E0F7": 0x749E, // <cjk>
        "E0F8": 0x74A7, // <cjk>
        "E0F9": 0x74CA, // <cjk>
        "E0FA": 0x74CF, // <cjk>
        "E0FB": 0x74D4, // <cjk>
        "E0FC": 0x73F1, // <cjk>
        "E140": 0x74E0, // <cjk>
        "E141": 0x74E3, // <cjk>
        "E142": 0x74E7, // <cjk>
        "E143": 0x74E9, // <cjk>
        "E144": 0x74EE, // <cjk>
        "E145": 0x74F2, // <cjk>
        "E146": 0x74F0, // <cjk>
        "E147": 0x74F1, // <cjk>
        "E148": 0x74F8, // <cjk>
        "E149": 0x74F7, // <cjk>
        "E14A": 0x7504, // <cjk>
        "E14B": 0x7503, // <cjk>
        "E14C": 0x7505, // <cjk>
        "E14D": 0x750C, // <cjk>
        "E14E": 0x750E, // <cjk>
        "E14F": 0x750D, // <cjk>
        "E150": 0x7515, // <cjk>
        "E151": 0x7513, // <cjk>
        "E152": 0x751E, // <cjk>
        "E153": 0x7526, // <cjk>
        "E154": 0x752C, // <cjk>
        "E155": 0x753C, // <cjk>
        "E156": 0x7544, // <cjk>
        "E157": 0x754D, // <cjk>
        "E158": 0x754A, // <cjk>
        "E159": 0x7549, // <cjk>
        "E15A": 0x755B, // <cjk>
        "E15C": 0x755A, // <cjk>
        "E15D": 0x7569, // <cjk>
        "E15E": 0x7564, // <cjk>
        "E15F": 0x7567, // <cjk>
        "E160": 0x756B, // <cjk>
        "E161": 0x756D, // <cjk>
        "E162": 0x7578, // <cjk>
        "E163": 0x7576, // <cjk>
        "E164": 0x7586, // <cjk>
        "E165": 0x7587, // <cjk>
        "E166": 0x7574, // <cjk>
        "E167": 0x758A, // <cjk>
        "E168": 0x7589, // <cjk>
        "E169": 0x7582, // <cjk>
        "E16A": 0x7594, // <cjk>
        "E16B": 0x759A, // <cjk>
        "E16C": 0x759D, // <cjk>
        "E16D": 0x75A5, // <cjk>
        "E16E": 0x75A3, // <cjk>
        "E16F": 0x75C2, // <cjk>
        "E170": 0x75B3, // <cjk>
        "E171": 0x75C3, // <cjk>
        "E172": 0x75B5, // <cjk>
        "E173": 0x75BD, // <cjk>
        "E174": 0x75B8, // <cjk>
        "E175": 0x75BC, // <cjk>
        "E176": 0x75B1, // <cjk>
        "E177": 0x75CD, // <cjk>
        "E178": 0x75CA, // <cjk>
        "E179": 0x75D2, // <cjk>
        "E17A": 0x75D9, // <cjk>
        "E17B": 0x75E3, // <cjk>
        "E17C": 0x75DE, // <cjk>
        "E17D": 0x75FE, // <cjk>
        "E17E": 0x75FF, // <cjk>
        "E180": 0x75FC, // <cjk>
        "E181": 0x7601, // <cjk>
        "E182": 0x75F0, // <cjk>
        "E183": 0x75FA, // <cjk>
        "E184": 0x75F2, // <cjk>
        "E185": 0x75F3, // <cjk>
        "E186": 0x760B, // <cjk>
        "E187": 0x760D, // <cjk>
        "E188": 0x7609, // <cjk>
        "E189": 0x761F, // <cjk>
        "E18A": 0x7627, // <cjk>
        "E18B": 0x7620, // <cjk>
        "E18C": 0x7621, // <cjk>
        "E18D": 0x7622, // <cjk>
        "E18E": 0x7624, // <cjk>
        "E18F": 0x7634, // <cjk>
        "E190": 0x7630, // <cjk>
        "E191": 0x763B, // <cjk>
        "E192": 0x7647, // <cjk>
        "E193": 0x7648, // <cjk>
        "E194": 0x7646, // <cjk>
        "E195": 0x765C, // <cjk>
        "E196": 0x7658, // <cjk>
        "E197": 0x7661, // <cjk>
        "E198": 0x7662, // <cjk>
        "E199": 0x7668, // <cjk>
        "E19A": 0x7669, // <cjk>
        "E19B": 0x766A, // <cjk>
        "E19C": 0x7667, // <cjk>
        "E19D": 0x766C, // <cjk>
        "E19E": 0x7670, // <cjk>
        "E19F": 0x7672, // <cjk>
        "E1A0": 0x7676, // <cjk>
        "E1A1": 0x7678, // <cjk>
        "E1A2": 0x767C, // <cjk>
        "E1A3": 0x7680, // <cjk>
        "E1A4": 0x7683, // <cjk>
        "E1A5": 0x7688, // <cjk>
        "E1A6": 0x768B, // <cjk>
        "E1A7": 0x768E, // <cjk>
        "E1A8": 0x7696, // <cjk>
        "E1A9": 0x7693, // <cjk>
        "E1AA": 0x7699, // <cjk>
        "E1AB": 0x769A, // <cjk>
        "E1AC": 0x76B0, // <cjk>
        "E1AD": 0x76B4, // <cjk>
        "E1AE": 0x76B8, // <cjk>
        "E1AF": 0x76B9, // <cjk>
        "E1B0": 0x76BA, // <cjk>
        "E1B1": 0x76C2, // <cjk>
        "E1B2": 0x76CD, // <cjk>
        "E1B3": 0x76D6, // <cjk>
        "E1B4": 0x76D2, // <cjk>
        "E1B5": 0x76DE, // <cjk>
        "E1B6": 0x76E1, // <cjk>
        "E1B7": 0x76E5, // <cjk>
        "E1B8": 0x76E7, // <cjk>
        "E1B9": 0x76EA, // <cjk>
        "E1BA": 0x862F, // <cjk>
        "E1BB": 0x76FB, // <cjk>
        "E1BC": 0x7708, // <cjk>
        "E1BD": 0x7707, // <cjk>
        "E1BE": 0x7704, // <cjk>
        "E1BF": 0x7729, // <cjk>
        "E1C0": 0x7724, // <cjk>
        "E1C1": 0x771E, // <cjk>
        "E1C2": 0x7725, // <cjk>
        "E1C3": 0x7726, // <cjk>
        "E1C4": 0x771B, // <cjk>
        "E1C5": 0x7737, // <cjk>
        "E1C6": 0x7738, // <cjk>
        "E1C7": 0x7747, // <cjk>
        "E1C8": 0x775A, // <cjk>
        "E1C9": 0x7768, // <cjk>
        "E1CA": 0x776B, // <cjk>
        "E1CB": 0x775B, // <cjk>
        "E1CC": 0x7765, // <cjk>
        "E1CD": 0x777F, // <cjk>
        "E1CE": 0x777E, // <cjk>
        "E1CF": 0x7779, // <cjk>
        "E1D0": 0x778E, // <cjk>
        "E1D1": 0x778B, // <cjk>
        "E1D2": 0x7791, // <cjk>
        "E1D3": 0x77A0, // <cjk>
        "E1D4": 0x779E, // <cjk>
        "E1D5": 0x77B0, // <cjk>
        "E1D6": 0x77B6, // <cjk>
        "E1D7": 0x77B9, // <cjk>
        "E1D8": 0x77BF, // <cjk>
        "E1D9": 0x77BC, // <cjk>
        "E1DA": 0x77BD, // <cjk>
        "E1DB": 0x77BB, // <cjk>
        "E1DC": 0x77C7, // <cjk>
        "E1DD": 0x77CD, // <cjk>
        "E1DE": 0x77D7, // <cjk>
        "E1DF": 0x77DA, // <cjk>
        "E1E0": 0x77DC, // <cjk>
        "E1E1": 0x77E3, // <cjk>
        "E1E2": 0x77EE, // <cjk>
        "E1E3": 0x77FC, // <cjk>
        "E1E4": 0x780C, // <cjk>
        "E1E5": 0x7812, // <cjk>
        "E1E6": 0x7926, // <cjk>
        "E1E7": 0x7820, // <cjk>
        "E1E8": 0x792A, // <cjk>
        "E1E9": 0x7845, // <cjk>
        "E1EA": 0x788E, // <cjk>
        "E1EB": 0x7874, // <cjk>
        "E1EC": 0x7886, // <cjk>
        "E1ED": 0x787C, // <cjk>
        "E1EE": 0x789A, // <cjk>
        "E1EF": 0x788C, // <cjk>
        "E1F0": 0x78A3, // <cjk>
        "E1F1": 0x78B5, // <cjk>
        "E1F2": 0x78AA, // <cjk>
        "E1F3": 0x78AF, // <cjk>
        "E1F4": 0x78D1, // <cjk>
        "E1F5": 0x78C6, // <cjk>
        "E1F6": 0x78CB, // <cjk>
        "E1F7": 0x78D4, // <cjk>
        "E1F8": 0x78BE, // <cjk>
        "E1F9": 0x78BC, // <cjk>
        "E1FA": 0x78C5, // <cjk>
        "E1FB": 0x78CA, // <cjk>
        "E1FC": 0x78EC, // <cjk>
        "E240": 0x78E7, // <cjk>
        "E241": 0x78DA, // <cjk>
        "E242": 0x78FD, // <cjk>
        "E243": 0x78F4, // <cjk>
        "E244": 0x7907, // <cjk>
        "E245": 0x7912, // <cjk>
        "E246": 0x7911, // <cjk>
        "E247": 0x7919, // <cjk>
        "E248": 0x792C, // <cjk>
        "E249": 0x792B, // <cjk>
        "E24A": 0x7940, // <cjk>
        "E24B": 0x7960, // <cjk>
        "E24C": 0x7957, // <cjk>
        "E24D": 0x795F, // <cjk>
        "E24E": 0x795A, // <cjk>
        "E24F": 0x7955, // <cjk>
        "E250": 0x7953, // <cjk>
        "E251": 0x797A, // <cjk>
        "E252": 0x797F, // <cjk>
        "E253": 0x798A, // <cjk>
        "E254": 0x799D, // <cjk>
        "E255": 0x79A7, // <cjk>
        "E256": 0x9F4B, // <cjk>
        "E257": 0x79AA, // <cjk>
        "E258": 0x79AE, // <cjk>
        "E259": 0x79B3, // <cjk>
        "E25A": 0x79B9, // <cjk>
        "E25C": 0x79C9, // <cjk>
        "E25D": 0x79D5, // <cjk>
        "E25E": 0x79E7, // <cjk>
        "E25F": 0x79EC, // <cjk>
        "E260": 0x79E1, // <cjk>
        "E261": 0x79E3, // <cjk>
        "E262": 0x7A08, // <cjk>
        "E263": 0x7A0D, // <cjk>
        "E264": 0x7A18, // <cjk>
        "E265": 0x7A19, // <cjk>
        "E266": 0x7A20, // <cjk>
        "E267": 0x7A1F, // <cjk>
        "E268": 0x7980, // <cjk>
        "E269": 0x7A31, // <cjk>
        "E26A": 0x7A3B, // <cjk>
        "E26B": 0x7A3E, // <cjk>
        "E26C": 0x7A37, // <cjk>
        "E26D": 0x7A43, // <cjk>
        "E26E": 0x7A57, // <cjk>
        "E26F": 0x7A49, // <cjk>
        "E270": 0x7A61, // <cjk>
        "E271": 0x7A62, // <cjk>
        "E272": 0x7A69, // <cjk>
        "E273": 0x9F9D, // <cjk>
        "E274": 0x7A70, // <cjk>
        "E275": 0x7A79, // <cjk>
        "E276": 0x7A7D, // <cjk>
        "E277": 0x7A88, // <cjk>
        "E278": 0x7A97, // <cjk>
        "E279": 0x7A95, // <cjk>
        "E27A": 0x7A98, // <cjk>
        "E27B": 0x7A96, // <cjk>
        "E27C": 0x7AA9, // <cjk>
        "E27D": 0x7AC8, // <cjk>
        "E27E": 0x7AB0, // <cjk>
        "E280": 0x7AB6, // <cjk>
        "E281": 0x7AC5, // <cjk>
        "E282": 0x7AC4, // <cjk>
        "E283": 0x7ABF, // <cjk>
        "E284": 0x9083, // <cjk>
        "E285": 0x7AC7, // <cjk>
        "E286": 0x7ACA, // <cjk>
        "E287": 0x7ACD, // <cjk>
        "E288": 0x7ACF, // <cjk>
        "E289": 0x7AD5, // <cjk>
        "E28A": 0x7AD3, // <cjk>
        "E28B": 0x7AD9, // <cjk>
        "E28C": 0x7ADA, // <cjk>
        "E28D": 0x7ADD, // <cjk>
        "E28E": 0x7AE1, // <cjk>
        "E28F": 0x7AE2, // <cjk>
        "E290": 0x7AE6, // <cjk>
        "E291": 0x7AED, // <cjk>
        "E292": 0x7AF0, // <cjk>
        "E293": 0x7B02, // <cjk>
        "E294": 0x7B0F, // <cjk>
        "E295": 0x7B0A, // <cjk>
        "E296": 0x7B06, // <cjk>
        "E297": 0x7B33, // <cjk>
        "E298": 0x7B18, // <cjk>
        "E299": 0x7B19, // <cjk>
        "E29A": 0x7B1E, // <cjk>
        "E29B": 0x7B35, // <cjk>
        "E29C": 0x7B28, // <cjk>
        "E29D": 0x7B36, // <cjk>
        "E29E": 0x7B50, // <cjk>
        "E29F": 0x7B7A, // <cjk>
        "E2A0": 0x7B04, // <cjk>
        "E2A1": 0x7B4D, // <cjk>
        "E2A2": 0x7B0B, // <cjk>
        "E2A3": 0x7B4C, // <cjk>
        "E2A4": 0x7B45, // <cjk>
        "E2A5": 0x7B75, // <cjk>
        "E2A6": 0x7B65, // <cjk>
        "E2A7": 0x7B74, // <cjk>
        "E2A8": 0x7B67, // <cjk>
        "E2A9": 0x7B70, // <cjk>
        "E2AA": 0x7B71, // <cjk>
        "E2AB": 0x7B6C, // <cjk>
        "E2AC": 0x7B6E, // <cjk>
        "E2AD": 0x7B9D, // <cjk>
        "E2AE": 0x7B98, // <cjk>
        "E2AF": 0x7B9F, // <cjk>
        "E2B0": 0x7B8D, // <cjk>
        "E2B1": 0x7B9C, // <cjk>
        "E2B2": 0x7B9A, // <cjk>
        "E2B3": 0x7B8B, // <cjk>
        "E2B4": 0x7B92, // <cjk>
        "E2B5": 0x7B8F, // <cjk>
        "E2B6": 0x7B5D, // <cjk>
        "E2B7": 0x7B99, // <cjk>
        "E2B8": 0x7BCB, // <cjk>
        "E2B9": 0x7BC1, // <cjk>
        "E2BA": 0x7BCC, // <cjk>
        "E2BB": 0x7BCF, // <cjk>
        "E2BC": 0x7BB4, // <cjk>
        "E2BD": 0x7BC6, // <cjk>
        "E2BE": 0x7BDD, // <cjk>
        "E2BF": 0x7BE9, // <cjk>
        "E2C0": 0x7C11, // <cjk>
        "E2C1": 0x7C14, // <cjk>
        "E2C2": 0x7BE6, // <cjk>
        "E2C3": 0x7BE5, // <cjk>
        "E2C4": 0x7C60, // <cjk>
        "E2C5": 0x7C00, // <cjk>
        "E2C6": 0x7C07, // <cjk>
        "E2C7": 0x7C13, // <cjk>
        "E2C8": 0x7BF3, // <cjk>
        "E2C9": 0x7BF7, // <cjk>
        "E2CA": 0x7C17, // <cjk>
        "E2CB": 0x7C0D, // <cjk>
        "E2CC": 0x7BF6, // <cjk>
        "E2CD": 0x7C23, // <cjk>
        "E2CE": 0x7C27, // <cjk>
        "E2CF": 0x7C2A, // <cjk>
        "E2D0": 0x7C1F, // <cjk>
        "E2D1": 0x7C37, // <cjk>
        "E2D2": 0x7C2B, // <cjk>
        "E2D3": 0x7C3D, // <cjk>
        "E2D4": 0x7C4C, // <cjk>
        "E2D5": 0x7C43, // <cjk>
        "E2D6": 0x7C54, // <cjk>
        "E2D7": 0x7C4F, // <cjk>
        "E2D8": 0x7C40, // <cjk>
        "E2D9": 0x7C50, // <cjk>
        "E2DA": 0x7C58, // <cjk>
        "E2DB": 0x7C5F, // <cjk>
        "E2DC": 0x7C64, // <cjk>
        "E2DD": 0x7C56, // <cjk>
        "E2DE": 0x7C65, // <cjk>
        "E2DF": 0x7C6C, // <cjk>
        "E2E0": 0x7C75, // <cjk>
        "E2E1": 0x7C83, // <cjk>
        "E2E2": 0x7C90, // <cjk>
        "E2E3": 0x7CA4, // <cjk>
        "E2E4": 0x7CAD, // <cjk>
        "E2E5": 0x7CA2, // <cjk>
        "E2E6": 0x7CAB, // <cjk>
        "E2E7": 0x7CA1, // <cjk>
        "E2E8": 0x7CA8, // <cjk>
        "E2E9": 0x7CB3, // <cjk>
        "E2EA": 0x7CB2, // <cjk>
        "E2EB": 0x7CB1, // <cjk>
        "E2EC": 0x7CAE, // <cjk>
        "E2ED": 0x7CB9, // <cjk>
        "E2EE": 0x7CBD, // <cjk>
        "E2EF": 0x7CC0, // <cjk>
        "E2F0": 0x7CC5, // <cjk>
        "E2F1": 0x7CC2, // <cjk>
        "E2F2": 0x7CD8, // <cjk>
        "E2F3": 0x7CD2, // <cjk>
        "E2F4": 0x7CDC, // <cjk>
        "E2F5": 0x7CE2, // <cjk>
        "E2F6": 0x9B3B, // <cjk>
        "E2F7": 0x7CEF, // <cjk>
        "E2F8": 0x7CF2, // <cjk>
        "E2F9": 0x7CF4, // <cjk>
        "E2FA": 0x7CF6, // <cjk>
        "E2FB": 0x7CFA, // <cjk>
        "E2FC": 0x7D06, // <cjk>
        "E340": 0x7D02, // <cjk>
        "E341": 0x7D1C, // <cjk>
        "E342": 0x7D15, // <cjk>
        "E343": 0x7D0A, // <cjk>
        "E344": 0x7D45, // <cjk>
        "E345": 0x7D4B, // <cjk>
        "E346": 0x7D2E, // <cjk>
        "E347": 0x7D32, // <cjk>
        "E348": 0x7D3F, // <cjk>
        "E349": 0x7D35, // <cjk>
        "E34A": 0x7D46, // <cjk>
        "E34B": 0x7D73, // <cjk>
        "E34C": 0x7D56, // <cjk>
        "E34D": 0x7D4E, // <cjk>
        "E34E": 0x7D72, // <cjk>
        "E34F": 0x7D68, // <cjk>
        "E350": 0x7D6E, // <cjk>
        "E351": 0x7D4F, // <cjk>
        "E352": 0x7D63, // <cjk>
        "E353": 0x7D93, // <cjk>
        "E354": 0x7D89, // <cjk>
        "E355": 0x7D5B, // <cjk>
        "E356": 0x7D8F, // <cjk>
        "E357": 0x7D7D, // <cjk>
        "E358": 0x7D9B, // <cjk>
        "E359": 0x7DBA, // <cjk>
        "E35A": 0x7DAE, // <cjk>
        "E35C": 0x7DB5, // <cjk>
        "E35D": 0x7DC7, // <cjk>
        "E35E": 0x7DBD, // <cjk>
        "E35F": 0x7DAB, // <cjk>
        "E360": 0x7E3D, // <cjk>
        "E361": 0x7DA2, // <cjk>
        "E362": 0x7DAF, // <cjk>
        "E363": 0x7DDC, // <cjk>
        "E364": 0x7DB8, // <cjk>
        "E365": 0x7D9F, // <cjk>
        "E366": 0x7DB0, // <cjk>
        "E367": 0x7DD8, // <cjk>
        "E368": 0x7DDD, // <cjk>
        "E369": 0x7DE4, // <cjk>
        "E36A": 0x7DDE, // <cjk>
        "E36B": 0x7DFB, // <cjk>
        "E36C": 0x7DF2, // <cjk>
        "E36D": 0x7DE1, // <cjk>
        "E36E": 0x7E05, // <cjk>
        "E36F": 0x7E0A, // <cjk>
        "E370": 0x7E23, // <cjk>
        "E371": 0x7E21, // <cjk>
        "E372": 0x7E12, // <cjk>
        "E373": 0x7E31, // <cjk>
        "E374": 0x7E1F, // <cjk>
        "E375": 0x7E09, // <cjk>
        "E376": 0x7E0B, // <cjk>
        "E377": 0x7E22, // <cjk>
        "E378": 0x7E46, // <cjk>
        "E379": 0x7E66, // <cjk>
        "E37A": 0x7E3B, // <cjk>
        "E37B": 0x7E35, // <cjk>
        "E37C": 0x7E39, // <cjk>
        "E37D": 0x7E43, // <cjk>
        "E37E": 0x7E37, // <cjk>
        "E380": 0x7E32, // <cjk>
        "E381": 0x7E3A, // <cjk>
        "E382": 0x7E67, // <cjk>
        "E383": 0x7E5D, // <cjk>
        "E384": 0x7E56, // <cjk>
        "E385": 0x7E5E, // <cjk>
        "E386": 0x7E59, // <cjk>
        "E387": 0x7E5A, // <cjk>
        "E388": 0x7E79, // <cjk>
        "E389": 0x7E6A, // <cjk>
        "E38A": 0x7E69, // <cjk>
        "E38B": 0x7E7C, // <cjk>
        "E38C": 0x7E7B, // <cjk>
        "E38D": 0x7E83, // <cjk>
        "E38E": 0x7DD5, // <cjk>
        "E38F": 0x7E7D, // <cjk>
        "E390": 0x8FAE, // <cjk>
        "E391": 0x7E7F, // <cjk>
        "E392": 0x7E88, // <cjk>
        "E393": 0x7E89, // <cjk>
        "E394": 0x7E8C, // <cjk>
        "E395": 0x7E92, // <cjk>
        "E396": 0x7E90, // <cjk>
        "E397": 0x7E93, // <cjk>
        "E398": 0x7E94, // <cjk>
        "E399": 0x7E96, // <cjk>
        "E39A": 0x7E8E, // <cjk>
        "E39B": 0x7E9B, // <cjk>
        "E39C": 0x7E9C, // <cjk>
        "E39D": 0x7F38, // <cjk>
        "E39E": 0x7F3A, // <cjk>
        "E39F": 0x7F45, // <cjk>
        "E3A0": 0x7F4C, // <cjk>
        "E3A1": 0x7F4D, // <cjk>
        "E3A2": 0x7F4E, // <cjk>
        "E3A3": 0x7F50, // <cjk>
        "E3A4": 0x7F51, // <cjk>
        "E3A5": 0x7F55, // <cjk>
        "E3A6": 0x7F54, // <cjk>
        "E3A7": 0x7F58, // <cjk>
        "E3A8": 0x7F5F, // <cjk>
        "E3A9": 0x7F60, // <cjk>
        "E3AA": 0x7F68, // <cjk>
        "E3AB": 0x7F69, // <cjk>
        "E3AC": 0x7F67, // <cjk>
        "E3AD": 0x7F78, // <cjk>
        "E3AE": 0x7F82, // <cjk>
        "E3AF": 0x7F86, // <cjk>
        "E3B0": 0x7F83, // <cjk>
        "E3B1": 0x7F88, // <cjk>
        "E3B2": 0x7F87, // <cjk>
        "E3B3": 0x7F8C, // <cjk>
        "E3B4": 0x7F94, // <cjk>
        "E3B5": 0x7F9E, // <cjk>
        "E3B6": 0x7F9D, // <cjk>
        "E3B7": 0x7F9A, // <cjk>
        "E3B8": 0x7FA3, // <cjk>
        "E3B9": 0x7FAF, // <cjk>
        "E3BA": 0x7FB2, // <cjk>
        "E3BB": 0x7FB9, // <cjk>
        "E3BC": 0x7FAE, // <cjk>
        "E3BD": 0x7FB6, // <cjk>
        "E3BE": 0x7FB8, // <cjk>
        "E3BF": 0x8B71, // <cjk>
        "E3C0": 0x7FC5, // <cjk>
        "E3C1": 0x7FC6, // <cjk>
        "E3C2": 0x7FCA, // <cjk>
        "E3C3": 0x7FD5, // <cjk>
        "E3C4": 0x7FD4, // <cjk>
        "E3C5": 0x7FE1, // <cjk>
        "E3C6": 0x7FE6, // <cjk>
        "E3C7": 0x7FE9, // <cjk>
        "E3C8": 0x7FF3, // <cjk>
        "E3C9": 0x7FF9, // <cjk>
        "E3CA": 0x98DC, // <cjk>
        "E3CB": 0x8006, // <cjk>
        "E3CC": 0x8004, // <cjk>
        "E3CD": 0x800B, // <cjk>
        "E3CE": 0x8012, // <cjk>
        "E3CF": 0x8018, // <cjk>
        "E3D0": 0x8019, // <cjk>
        "E3D1": 0x801C, // <cjk>
        "E3D2": 0x8021, // <cjk>
        "E3D3": 0x8028, // <cjk>
        "E3D4": 0x803F, // <cjk>
        "E3D5": 0x803B, // <cjk>
        "E3D6": 0x804A, // <cjk>
        "E3D7": 0x8046, // <cjk>
        "E3D8": 0x8052, // <cjk>
        "E3D9": 0x8058, // <cjk>
        "E3DA": 0x805A, // <cjk>
        "E3DB": 0x805F, // <cjk>
        "E3DC": 0x8062, // <cjk>
        "E3DD": 0x8068, // <cjk>
        "E3DE": 0x8073, // <cjk>
        "E3DF": 0x8072, // <cjk>
        "E3E0": 0x8070, // <cjk>
        "E3E1": 0x8076, // <cjk>
        "E3E2": 0x8079, // <cjk>
        "E3E3": 0x807D, // <cjk>
        "E3E4": 0x807F, // <cjk>
        "E3E5": 0x8084, // <cjk>
        "E3E6": 0x8086, // <cjk>
        "E3E7": 0x8085, // <cjk>
        "E3E8": 0x809B, // <cjk>
        "E3E9": 0x8093, // <cjk>
        "E3EA": 0x809A, // <cjk>
        "E3EB": 0x80AD, // <cjk>
        "E3EC": 0x5190, // <cjk>
        "E3ED": 0x80AC, // <cjk>
        "E3EE": 0x80DB, // <cjk>
        "E3EF": 0x80E5, // <cjk>
        "E3F0": 0x80D9, // <cjk>
        "E3F1": 0x80DD, // <cjk>
        "E3F2": 0x80C4, // <cjk>
        "E3F3": 0x80DA, // <cjk>
        "E3F4": 0x80D6, // <cjk>
        "E3F5": 0x8109, // <cjk>
        "E3F6": 0x80EF, // <cjk>
        "E3F7": 0x80F1, // <cjk>
        "E3F8": 0x811B, // <cjk>
        "E3F9": 0x8129, // <cjk>
        "E3FA": 0x8123, // <cjk>
        "E3FB": 0x812F, // <cjk>
        "E3FC": 0x814B, // <cjk>
        "E440": 0x968B, // <cjk>
        "E441": 0x8146, // <cjk>
        "E442": 0x813E, // <cjk>
        "E443": 0x8153, // <cjk>
        "E444": 0x8151, // <cjk>
        "E445": 0x80FC, // <cjk>
        "E446": 0x8171, // <cjk>
        "E447": 0x816E, // <cjk>
        "E448": 0x8165, // <cjk>
        "E449": 0x8166, // <cjk>
        "E44A": 0x8174, // <cjk>
        "E44B": 0x8183, // <cjk>
        "E44C": 0x8188, // <cjk>
        "E44D": 0x818A, // <cjk>
        "E44E": 0x8180, // <cjk>
        "E44F": 0x8182, // <cjk>
        "E450": 0x81A0, // <cjk>
        "E451": 0x8195, // <cjk>
        "E452": 0x81A4, // <cjk>
        "E453": 0x81A3, // <cjk>
        "E454": 0x815F, // <cjk>
        "E455": 0x8193, // <cjk>
        "E456": 0x81A9, // <cjk>
        "E457": 0x81B0, // <cjk>
        "E458": 0x81B5, // <cjk>
        "E459": 0x81BE, // <cjk>
        "E45A": 0x81B8, // <cjk>
        "E45C": 0x81C0, // <cjk>
        "E45D": 0x81C2, // <cjk>
        "E45E": 0x81BA, // <cjk>
        "E45F": 0x81C9, // <cjk>
        "E460": 0x81CD, // <cjk>
        "E461": 0x81D1, // <cjk>
        "E462": 0x81D9, // <cjk>
        "E463": 0x81D8, // <cjk>
        "E464": 0x81C8, // <cjk>
        "E465": 0x81DA, // <cjk>
        "E466": 0x81DF, // <cjk>
        "E467": 0x81E0, // <cjk>
        "E468": 0x81E7, // <cjk>
        "E469": 0x81FA, // <cjk>
        "E46A": 0x81FB, // <cjk>
        "E46B": 0x81FE, // <cjk>
        "E46C": 0x8201, // <cjk>
        "E46D": 0x8202, // <cjk>
        "E46E": 0x8205, // <cjk>
        "E46F": 0x8207, // <cjk>
        "E470": 0x820A, // <cjk>
        "E471": 0x820D, // <cjk>
        "E472": 0x8210, // <cjk>
        "E473": 0x8216, // <cjk>
        "E474": 0x8229, // <cjk>
        "E475": 0x822B, // <cjk>
        "E476": 0x8238, // <cjk>
        "E477": 0x8233, // <cjk>
        "E478": 0x8240, // <cjk>
        "E479": 0x8259, // <cjk>
        "E47A": 0x8258, // <cjk>
        "E47B": 0x825D, // <cjk>
        "E47C": 0x825A, // <cjk>
        "E47D": 0x825F, // <cjk>
        "E47E": 0x8264, // <cjk>
        "E480": 0x8262, // <cjk>
        "E481": 0x8268, // <cjk>
        "E482": 0x826A, // <cjk>
        "E483": 0x826B, // <cjk>
        "E484": 0x822E, // <cjk>
        "E485": 0x8271, // <cjk>
        "E486": 0x8277, // <cjk>
        "E487": 0x8278, // <cjk>
        "E488": 0x827E, // <cjk>
        "E489": 0x828D, // <cjk>
        "E48A": 0x8292, // <cjk>
        "E48B": 0x82AB, // <cjk>
        "E48C": 0x829F, // <cjk>
        "E48D": 0x82BB, // <cjk>
        "E48E": 0x82AC, // <cjk>
        "E48F": 0x82E1, // <cjk>
        "E490": 0x82E3, // <cjk>
        "E491": 0x82DF, // <cjk>
        "E492": 0x82D2, // <cjk>
        "E493": 0x82F4, // <cjk>
        "E494": 0x82F3, // <cjk>
        "E495": 0x82FA, // <cjk>
        "E496": 0x8393, // <cjk>
        "E497": 0x8303, // <cjk>
        "E498": 0x82FB, // <cjk>
        "E499": 0x82F9, // <cjk>
        "E49A": 0x82DE, // <cjk>
        "E49B": 0x8306, // <cjk>
        "E49C": 0x82DC, // <cjk>
        "E49D": 0x8309, // <cjk>
        "E49E": 0x82D9, // <cjk>
        "E49F": 0x8335, // <cjk>
        "E4A0": 0x8334, // <cjk>
        "E4A1": 0x8316, // <cjk>
        "E4A2": 0x8332, // <cjk>
        "E4A3": 0x8331, // <cjk>
        "E4A4": 0x8340, // <cjk>
        "E4A5": 0x8339, // <cjk>
        "E4A6": 0x8350, // <cjk>
        "E4A7": 0x8345, // <cjk>
        "E4A8": 0x832F, // <cjk>
        "E4A9": 0x832B, // <cjk>
        "E4AA": 0x8317, // <cjk>
        "E4AB": 0x8318, // <cjk>
        "E4AC": 0x8385, // <cjk>
        "E4AD": 0x839A, // <cjk>
        "E4AE": 0x83AA, // <cjk>
        "E4AF": 0x839F, // <cjk>
        "E4B0": 0x83A2, // <cjk>
        "E4B1": 0x8396, // <cjk>
        "E4B2": 0x8323, // <cjk>
        "E4B3": 0x838E, // <cjk>
        "E4B4": 0x8387, // <cjk>
        "E4B5": 0x838A, // <cjk>
        "E4B6": 0x837C, // <cjk>
        "E4B7": 0x83B5, // <cjk>
        "E4B8": 0x8373, // <cjk>
        "E4B9": 0x8375, // <cjk>
        "E4BA": 0x83A0, // <cjk>
        "E4BB": 0x8389, // <cjk>
        "E4BC": 0x83A8, // <cjk>
        "E4BD": 0x83F4, // <cjk>
        "E4BE": 0x8413, // <cjk>
        "E4BF": 0x83EB, // <cjk>
        "E4C0": 0x83CE, // <cjk>
        "E4C1": 0x83FD, // <cjk>
        "E4C2": 0x8403, // <cjk>
        "E4C3": 0x83D8, // <cjk>
        "E4C4": 0x840B, // <cjk>
        "E4C5": 0x83C1, // <cjk>
        "E4C6": 0x83F7, // <cjk>
        "E4C7": 0x8407, // <cjk>
        "E4C8": 0x83E0, // <cjk>
        "E4C9": 0x83F2, // <cjk>
        "E4CA": 0x840D, // <cjk>
        "E4CB": 0x8422, // <cjk>
        "E4CC": 0x8420, // <cjk>
        "E4CD": 0x83BD, // <cjk>
        "E4CE": 0x8438, // <cjk>
        "E4CF": 0x8506, // <cjk>
        "E4D0": 0x83FB, // <cjk>
        "E4D1": 0x846D, // <cjk>
        "E4D2": 0x842A, // <cjk>
        "E4D3": 0x843C, // <cjk>
        "E4D4": 0x855A, // <cjk>
        "E4D5": 0x8484, // <cjk>
        "E4D6": 0x8477, // <cjk>
        "E4D7": 0x846B, // <cjk>
        "E4D8": 0x84AD, // <cjk>
        "E4D9": 0x846E, // <cjk>
        "E4DA": 0x8482, // <cjk>
        "E4DB": 0x8469, // <cjk>
        "E4DC": 0x8446, // <cjk>
        "E4DD": 0x842C, // <cjk>
        "E4DE": 0x846F, // <cjk>
        "E4DF": 0x8479, // <cjk>
        "E4E0": 0x8435, // <cjk>
        "E4E1": 0x84CA, // <cjk>
        "E4E2": 0x8462, // <cjk>
        "E4E3": 0x84B9, // <cjk>
        "E4E4": 0x84BF, // <cjk>
        "E4E5": 0x849F, // <cjk>
        "E4E6": 0x84D9, // <cjk>
        "E4E7": 0x84CD, // <cjk>
        "E4E8": 0x84BB, // <cjk>
        "E4E9": 0x84DA, // <cjk>
        "E4EA": 0x84D0, // <cjk>
        "E4EB": 0x84C1, // <cjk>
        "E4EC": 0x84C6, // <cjk>
        "E4ED": 0x84D6, // <cjk>
        "E4EE": 0x84A1, // <cjk>
        "E4EF": 0x8521, // <cjk>
        "E4F0": 0x84FF, // <cjk>
        "E4F1": 0x84F4, // <cjk>
        "E4F2": 0x8517, // <cjk>
        "E4F3": 0x8518, // <cjk>
        "E4F4": 0x852C, // <cjk>
        "E4F5": 0x851F, // <cjk>
        "E4F6": 0x8515, // <cjk>
        "E4F7": 0x8514, // <cjk>
        "E4F8": 0x84FC, // <cjk>
        "E4F9": 0x8540, // <cjk>
        "E4FA": 0x8563, // <cjk>
        "E4FB": 0x8558, // <cjk>
        "E4FC": 0x8548, // <cjk>
        "E540": 0x8541, // <cjk>
        "E541": 0x8602, // <cjk>
        "E542": 0x854B, // <cjk>
        "E543": 0x8555, // <cjk>
        "E544": 0x8580, // <cjk>
        "E545": 0x85A4, // <cjk>
        "E546": 0x8588, // <cjk>
        "E547": 0x8591, // <cjk>
        "E548": 0x858A, // <cjk>
        "E549": 0x85A8, // <cjk>
        "E54A": 0x856D, // <cjk>
        "E54B": 0x8594, // <cjk>
        "E54C": 0x859B, // <cjk>
        "E54D": 0x85EA, // <cjk>
        "E54E": 0x8587, // <cjk>
        "E54F": 0x859C, // <cjk>
        "E550": 0x8577, // <cjk>
        "E551": 0x857E, // <cjk>
        "E552": 0x8590, // <cjk>
        "E553": 0x85C9, // <cjk>
        "E554": 0x85BA, // <cjk>
        "E555": 0x85CF, // <cjk>
        "E556": 0x85B9, // <cjk>
        "E557": 0x85D0, // <cjk>
        "E558": 0x85D5, // <cjk>
        "E559": 0x85DD, // <cjk>
        "E55A": 0x85E5, // <cjk>
        "E55C": 0x85F9, // <cjk>
        "E55D": 0x860A, // <cjk>
        "E55E": 0x8613, // <cjk>
        "E55F": 0x860B, // <cjk>
        "E560": 0x85FE, // <cjk>
        "E561": 0x85FA, // <cjk>
        "E562": 0x8606, // <cjk>
        "E563": 0x8622, // <cjk>
        "E564": 0x861A, // <cjk>
        "E565": 0x8630, // <cjk>
        "E566": 0x863F, // <cjk>
        "E567": 0x864D, // <cjk>
        "E568": 0x4E55, // <cjk>
        "E569": 0x8654, // <cjk>
        "E56A": 0x865F, // <cjk>
        "E56B": 0x8667, // <cjk>
        "E56C": 0x8671, // <cjk>
        "E56D": 0x8693, // <cjk>
        "E56E": 0x86A3, // <cjk>
        "E56F": 0x86A9, // <cjk>
        "E570": 0x86AA, // <cjk>
        "E571": 0x868B, // <cjk>
        "E572": 0x868C, // <cjk>
        "E573": 0x86B6, // <cjk>
        "E574": 0x86AF, // <cjk>
        "E575": 0x86C4, // <cjk>
        "E576": 0x86C6, // <cjk>
        "E577": 0x86B0, // <cjk>
        "E578": 0x86C9, // <cjk>
        "E579": 0x8823, // <cjk>
        "E57A": 0x86AB, // <cjk>
        "E57B": 0x86D4, // <cjk>
        "E57C": 0x86DE, // <cjk>
        "E57D": 0x86E9, // <cjk>
        "E57E": 0x86EC, // <cjk>
        "E580": 0x86DF, // <cjk>
        "E581": 0x86DB, // <cjk>
        "E582": 0x86EF, // <cjk>
        "E583": 0x8712, // <cjk>
        "E584": 0x8706, // <cjk>
        "E585": 0x8708, // <cjk>
        "E586": 0x8700, // <cjk>
        "E587": 0x8703, // <cjk>
        "E588": 0x86FB, // <cjk>
        "E589": 0x8711, // <cjk>
        "E58A": 0x8709, // <cjk>
        "E58B": 0x870D, // <cjk>
        "E58C": 0x86F9, // <cjk>
        "E58D": 0x870A, // <cjk>
        "E58E": 0x8734, // <cjk>
        "E58F": 0x873F, // <cjk>
        "E590": 0x8737, // <cjk>
        "E591": 0x873B, // <cjk>
        "E592": 0x8725, // <cjk>
        "E593": 0x8729, // <cjk>
        "E594": 0x871A, // <cjk>
        "E595": 0x8760, // <cjk>
        "E596": 0x875F, // <cjk>
        "E597": 0x8778, // <cjk>
        "E598": 0x874C, // <cjk>
        "E599": 0x874E, // <cjk>
        "E59A": 0x8774, // <cjk>
        "E59B": 0x8757, // <cjk>
        "E59C": 0x8768, // <cjk>
        "E59D": 0x876E, // <cjk>
        "E59E": 0x8759, // <cjk>
        "E59F": 0x8753, // <cjk>
        "E5A0": 0x8763, // <cjk>
        "E5A1": 0x876A, // <cjk>
        "E5A2": 0x8805, // <cjk>
        "E5A3": 0x87A2, // <cjk>
        "E5A4": 0x879F, // <cjk>
        "E5A5": 0x8782, // <cjk>
        "E5A6": 0x87AF, // <cjk>
        "E5A7": 0x87CB, // <cjk>
        "E5A8": 0x87BD, // <cjk>
        "E5A9": 0x87C0, // <cjk>
        "E5AA": 0x87D0, // <cjk>
        "E5AB": 0x96D6, // <cjk>
        "E5AC": 0x87AB, // <cjk>
        "E5AD": 0x87C4, // <cjk>
        "E5AE": 0x87B3, // <cjk>
        "E5AF": 0x87C7, // <cjk>
        "E5B0": 0x87C6, // <cjk>
        "E5B1": 0x87BB, // <cjk>
        "E5B2": 0x87EF, // <cjk>
        "E5B3": 0x87F2, // <cjk>
        "E5B4": 0x87E0, // <cjk>
        "E5B5": 0x880F, // <cjk>
        "E5B6": 0x880D, // <cjk>
        "E5B7": 0x87FE, // <cjk>
        "E5B8": 0x87F6, // <cjk>
        "E5B9": 0x87F7, // <cjk>
        "E5BA": 0x880E, // <cjk>
        "E5BB": 0x87D2, // <cjk>
        "E5BC": 0x8811, // <cjk>
        "E5BD": 0x8816, // <cjk>
        "E5BE": 0x8815, // <cjk>
        "E5BF": 0x8822, // <cjk>
        "E5C0": 0x8821, // <cjk>
        "E5C1": 0x8831, // <cjk>
        "E5C2": 0x8836, // <cjk>
        "E5C3": 0x8839, // <cjk>
        "E5C4": 0x8827, // <cjk>
        "E5C5": 0x883B, // <cjk>
        "E5C6": 0x8844, // <cjk>
        "E5C7": 0x8842, // <cjk>
        "E5C8": 0x8852, // <cjk>
        "E5C9": 0x8859, // <cjk>
        "E5CA": 0x885E, // <cjk>
        "E5CB": 0x8862, // <cjk>
        "E5CC": 0x886B, // <cjk>
        "E5CD": 0x8881, // <cjk>
        "E5CE": 0x887E, // <cjk>
        "E5CF": 0x889E, // <cjk>
        "E5D0": 0x8875, // <cjk>
        "E5D1": 0x887D, // <cjk>
        "E5D2": 0x88B5, // <cjk>
        "E5D3": 0x8872, // <cjk>
        "E5D4": 0x8882, // <cjk>
        "E5D5": 0x8897, // <cjk>
        "E5D6": 0x8892, // <cjk>
        "E5D7": 0x88AE, // <cjk>
        "E5D8": 0x8899, // <cjk>
        "E5D9": 0x88A2, // <cjk>
        "E5DA": 0x888D, // <cjk>
        "E5DB": 0x88A4, // <cjk>
        "E5DC": 0x88B0, // <cjk>
        "E5DD": 0x88BF, // <cjk>
        "E5DE": 0x88B1, // <cjk>
        "E5DF": 0x88C3, // <cjk>
        "E5E0": 0x88C4, // <cjk>
        "E5E1": 0x88D4, // <cjk>
        "E5E2": 0x88D8, // <cjk>
        "E5E3": 0x88D9, // <cjk>
        "E5E4": 0x88DD, // <cjk>
        "E5E5": 0x88F9, // <cjk>
        "E5E6": 0x8902, // <cjk>
        "E5E7": 0x88FC, // <cjk>
        "E5E8": 0x88F4, // <cjk>
        "E5E9": 0x88E8, // <cjk>
        "E5EA": 0x88F2, // <cjk>
        "E5EB": 0x8904, // <cjk>
        "E5EC": 0x890C, // <cjk>
        "E5ED": 0x890A, // <cjk>
        "E5EE": 0x8913, // <cjk>
        "E5EF": 0x8943, // <cjk>
        "E5F0": 0x891E, // <cjk>
        "E5F1": 0x8925, // <cjk>
        "E5F2": 0x892A, // <cjk>
        "E5F3": 0x892B, // <cjk>
        "E5F4": 0x8941, // <cjk>
        "E5F5": 0x8944, // <cjk>
        "E5F6": 0x893B, // <cjk>
        "E5F7": 0x8936, // <cjk>
        "E5F8": 0x8938, // <cjk>
        "E5F9": 0x894C, // <cjk>
        "E5FA": 0x891D, // <cjk>
        "E5FB": 0x8960, // <cjk>
        "E5FC": 0x895E, // <cjk>
        "E640": 0x8966, // <cjk>
        "E641": 0x8964, // <cjk>
        "E642": 0x896D, // <cjk>
        "E643": 0x896A, // <cjk>
        "E644": 0x896F, // <cjk>
        "E645": 0x8974, // <cjk>
        "E646": 0x8977, // <cjk>
        "E647": 0x897E, // <cjk>
        "E648": 0x8983, // <cjk>
        "E649": 0x8988, // <cjk>
        "E64A": 0x898A, // <cjk>
        "E64B": 0x8993, // <cjk>
        "E64C": 0x8998, // <cjk>
        "E64D": 0x89A1, // <cjk>
        "E64E": 0x89A9, // <cjk>
        "E64F": 0x89A6, // <cjk>
        "E650": 0x89AC, // <cjk>
        "E651": 0x89AF, // <cjk>
        "E652": 0x89B2, // <cjk>
        "E653": 0x89BA, // <cjk>
        "E654": 0x89BD, // <cjk>
        "E655": 0x89BF, // <cjk>
        "E656": 0x89C0, // <cjk>
        "E657": 0x89DA, // <cjk>
        "E658": 0x89DC, // <cjk>
        "E659": 0x89DD, // <cjk>
        "E65A": 0x89E7, // <cjk>
        "E65C": 0x89F8, // <cjk>
        "E65D": 0x8A03, // <cjk>
        "E65E": 0x8A16, // <cjk>
        "E65F": 0x8A10, // <cjk>
        "E660": 0x8A0C, // <cjk>
        "E661": 0x8A1B, // <cjk>
        "E662": 0x8A1D, // <cjk>
        "E663": 0x8A25, // <cjk>
        "E664": 0x8A36, // <cjk>
        "E665": 0x8A41, // <cjk>
        "E666": 0x8A5B, // <cjk>
        "E667": 0x8A52, // <cjk>
        "E668": 0x8A46, // <cjk>
        "E669": 0x8A48, // <cjk>
        "E66A": 0x8A7C, // <cjk>
        "E66B": 0x8A6D, // <cjk>
        "E66C": 0x8A6C, // <cjk>
        "E66D": 0x8A62, // <cjk>
        "E66E": 0x8A85, // <cjk>
        "E66F": 0x8A82, // <cjk>
        "E670": 0x8A84, // <cjk>
        "E671": 0x8AA8, // <cjk>
        "E672": 0x8AA1, // <cjk>
        "E673": 0x8A91, // <cjk>
        "E674": 0x8AA5, // <cjk>
        "E675": 0x8AA6, // <cjk>
        "E676": 0x8A9A, // <cjk>
        "E677": 0x8AA3, // <cjk>
        "E678": 0x8AC4, // <cjk>
        "E679": 0x8ACD, // <cjk>
        "E67A": 0x8AC2, // <cjk>
        "E67B": 0x8ADA, // <cjk>
        "E67C": 0x8AEB, // <cjk>
        "E67D": 0x8AF3, // <cjk>
        "E67E": 0x8AE7, // <cjk>
        "E680": 0x8AE4, // <cjk>
        "E681": 0x8AF1, // <cjk>
        "E682": 0x8B14, // <cjk>
        "E683": 0x8AE0, // <cjk>
        "E684": 0x8AE2, // <cjk>
        "E685": 0x8AF7, // <cjk>
        "E686": 0x8ADE, // <cjk>
        "E687": 0x8ADB, // <cjk>
        "E688": 0x8B0C, // <cjk>
        "E689": 0x8B07, // <cjk>
        "E68A": 0x8B1A, // <cjk>
        "E68B": 0x8AE1, // <cjk>
        "E68C": 0x8B16, // <cjk>
        "E68D": 0x8B10, // <cjk>
        "E68E": 0x8B17, // <cjk>
        "E68F": 0x8B20, // <cjk>
        "E690": 0x8B33, // <cjk>
        "E691": 0x97AB, // <cjk>
        "E692": 0x8B26, // <cjk>
        "E693": 0x8B2B, // <cjk>
        "E694": 0x8B3E, // <cjk>
        "E695": 0x8B28, // <cjk>
        "E696": 0x8B41, // <cjk>
        "E697": 0x8B4C, // <cjk>
        "E698": 0x8B4F, // <cjk>
        "E699": 0x8B4E, // <cjk>
        "E69A": 0x8B49, // <cjk>
        "E69B": 0x8B56, // <cjk>
        "E69C": 0x8B5B, // <cjk>
        "E69D": 0x8B5A, // <cjk>
        "E69E": 0x8B6B, // <cjk>
        "E69F": 0x8B5F, // <cjk>
        "E6A0": 0x8B6C, // <cjk>
        "E6A1": 0x8B6F, // <cjk>
        "E6A2": 0x8B74, // <cjk>
        "E6A3": 0x8B7D, // <cjk>
        "E6A4": 0x8B80, // <cjk>
        "E6A5": 0x8B8C, // <cjk>
        "E6A6": 0x8B8E, // <cjk>
        "E6A7": 0x8B92, // <cjk>
        "E6A8": 0x8B93, // <cjk>
        "E6A9": 0x8B96, // <cjk>
        "E6AA": 0x8B99, // <cjk>
        "E6AB": 0x8B9A, // <cjk>
        "E6AC": 0x8C3A, // <cjk>
        "E6AD": 0x8C41, // <cjk>
        "E6AE": 0x8C3F, // <cjk>
        "E6AF": 0x8C48, // <cjk>
        "E6B0": 0x8C4C, // <cjk>
        "E6B1": 0x8C4E, // <cjk>
        "E6B2": 0x8C50, // <cjk>
        "E6B3": 0x8C55, // <cjk>
        "E6B4": 0x8C62, // <cjk>
        "E6B5": 0x8C6C, // <cjk>
        "E6B6": 0x8C78, // <cjk>
        "E6B7": 0x8C7A, // <cjk>
        "E6B8": 0x8C82, // <cjk>
        "E6B9": 0x8C89, // <cjk>
        "E6BA": 0x8C85, // <cjk>
        "E6BB": 0x8C8A, // <cjk>
        "E6BC": 0x8C8D, // <cjk>
        "E6BD": 0x8C8E, // <cjk>
        "E6BE": 0x8C94, // <cjk>
        "E6BF": 0x8C7C, // <cjk>
        "E6C0": 0x8C98, // <cjk>
        "E6C1": 0x621D, // <cjk>
        "E6C2": 0x8CAD, // <cjk>
        "E6C3": 0x8CAA, // <cjk>
        "E6C4": 0x8CBD, // <cjk>
        "E6C5": 0x8CB2, // <cjk>
        "E6C6": 0x8CB3, // <cjk>
        "E6C7": 0x8CAE, // <cjk>
        "E6C8": 0x8CB6, // <cjk>
        "E6C9": 0x8CC8, // <cjk>
        "E6CA": 0x8CC1, // <cjk>
        "E6CB": 0x8CE4, // <cjk>
        "E6CC": 0x8CE3, // <cjk>
        "E6CD": 0x8CDA, // <cjk>
        "E6CE": 0x8CFD, // <cjk>
        "E6CF": 0x8CFA, // <cjk>
        "E6D0": 0x8CFB, // <cjk>
        "E6D1": 0x8D04, // <cjk>
        "E6D2": 0x8D05, // <cjk>
        "E6D3": 0x8D0A, // <cjk>
        "E6D4": 0x8D07, // <cjk>
        "E6D5": 0x8D0F, // <cjk>
        "E6D6": 0x8D0D, // <cjk>
        "E6D7": 0x8D10, // <cjk>
        "E6D8": 0x9F4E, // <cjk>
        "E6D9": 0x8D13, // <cjk>
        "E6DA": 0x8CCD, // <cjk>
        "E6DB": 0x8D14, // <cjk>
        "E6DC": 0x8D16, // <cjk>
        "E6DD": 0x8D67, // <cjk>
        "E6DE": 0x8D6D, // <cjk>
        "E6DF": 0x8D71, // <cjk>
        "E6E0": 0x8D73, // <cjk>
        "E6E1": 0x8D81, // <cjk>
        "E6E2": 0x8D99, // <cjk>
        "E6E3": 0x8DC2, // <cjk>
        "E6E4": 0x8DBE, // <cjk>
        "E6E5": 0x8DBA, // <cjk>
        "E6E6": 0x8DCF, // <cjk>
        "E6E7": 0x8DDA, // <cjk>
        "E6E8": 0x8DD6, // <cjk>
        "E6E9": 0x8DCC, // <cjk>
        "E6EA": 0x8DDB, // <cjk>
        "E6EB": 0x8DCB, // <cjk>
        "E6EC": 0x8DEA, // <cjk>
        "E6ED": 0x8DEB, // <cjk>
        "E6EE": 0x8DDF, // <cjk>
        "E6EF": 0x8DE3, // <cjk>
        "E6F0": 0x8DFC, // <cjk>
        "E6F1": 0x8E08, // <cjk>
        "E6F2": 0x8E09, // <cjk>
        "E6F3": 0x8DFF, // <cjk>
        "E6F4": 0x8E1D, // <cjk>
        "E6F5": 0x8E1E, // <cjk>
        "E6F6": 0x8E10, // <cjk>
        "E6F7": 0x8E1F, // <cjk>
        "E6F8": 0x8E42, // <cjk>
        "E6F9": 0x8E35, // <cjk>
        "E6FA": 0x8E30, // <cjk>
        "E6FB": 0x8E34, // <cjk>
        "E6FC": 0x8E4A, // <cjk>
        "E740": 0x8E47, // <cjk>
        "E741": 0x8E49, // <cjk>
        "E742": 0x8E4C, // <cjk>
        "E743": 0x8E50, // <cjk>
        "E744": 0x8E48, // <cjk>
        "E745": 0x8E59, // <cjk>
        "E746": 0x8E64, // <cjk>
        "E747": 0x8E60, // <cjk>
        "E748": 0x8E2A, // <cjk>
        "E749": 0x8E63, // <cjk>
        "E74A": 0x8E55, // <cjk>
        "E74B": 0x8E76, // <cjk>
        "E74C": 0x8E72, // <cjk>
        "E74D": 0x8E7C, // <cjk>
        "E74E": 0x8E81, // <cjk>
        "E74F": 0x8E87, // <cjk>
        "E750": 0x8E85, // <cjk>
        "E751": 0x8E84, // <cjk>
        "E752": 0x8E8B, // <cjk>
        "E753": 0x8E8A, // <cjk>
        "E754": 0x8E93, // <cjk>
        "E755": 0x8E91, // <cjk>
        "E756": 0x8E94, // <cjk>
        "E757": 0x8E99, // <cjk>
        "E758": 0x8EAA, // <cjk>
        "E759": 0x8EA1, // <cjk>
        "E75A": 0x8EAC, // <cjk>
        "E75C": 0x8EC6, // <cjk>
        "E75D": 0x8EB1, // <cjk>
        "E75E": 0x8EBE, // <cjk>
        "E75F": 0x8EC5, // <cjk>
        "E760": 0x8EC8, // <cjk>
        "E761": 0x8ECB, // <cjk>
        "E762": 0x8EDB, // <cjk>
        "E763": 0x8EE3, // <cjk>
        "E764": 0x8EFC, // <cjk>
        "E765": 0x8EFB, // <cjk>
        "E766": 0x8EEB, // <cjk>
        "E767": 0x8EFE, // <cjk>
        "E768": 0x8F0A, // <cjk>
        "E769": 0x8F05, // <cjk>
        "E76A": 0x8F15, // <cjk>
        "E76B": 0x8F12, // <cjk>
        "E76C": 0x8F19, // <cjk>
        "E76D": 0x8F13, // <cjk>
        "E76E": 0x8F1C, // <cjk>
        "E76F": 0x8F1F, // <cjk>
        "E770": 0x8F1B, // <cjk>
        "E771": 0x8F0C, // <cjk>
        "E772": 0x8F26, // <cjk>
        "E773": 0x8F33, // <cjk>
        "E774": 0x8F3B, // <cjk>
        "E775": 0x8F39, // <cjk>
        "E776": 0x8F45, // <cjk>
        "E777": 0x8F42, // <cjk>
        "E778": 0x8F3E, // <cjk>
        "E779": 0x8F4C, // <cjk>
        "E77A": 0x8F49, // <cjk>
        "E77B": 0x8F46, // <cjk>
        "E77C": 0x8F4E, // <cjk>
        "E77D": 0x8F57, // <cjk>
        "E77E": 0x8F5C, // <cjk>
        "E780": 0x8F62, // <cjk>
        "E781": 0x8F63, // <cjk>
        "E782": 0x8F64, // <cjk>
        "E783": 0x8F9C, // <cjk>
        "E784": 0x8F9F, // <cjk>
        "E785": 0x8FA3, // <cjk>
        "E786": 0x8FAD, // <cjk>
        "E787": 0x8FAF, // <cjk>
        "E788": 0x8FB7, // <cjk>
        "E789": 0x8FDA, // <cjk>
        "E78A": 0x8FE5, // <cjk>
        "E78B": 0x8FE2, // <cjk>
        "E78C": 0x8FEA, // <cjk>
        "E78D": 0x8FEF, // <cjk>
        "E78E": 0x9087, // <cjk>
        "E78F": 0x8FF4, // <cjk>
        "E790": 0x9005, // <cjk>
        "E791": 0x8FF9, // <cjk>
        "E792": 0x8FFA, // <cjk>
        "E793": 0x9011, // <cjk>
        "E794": 0x9015, // <cjk>
        "E795": 0x9021, // <cjk>
        "E796": 0x900D, // <cjk>
        "E797": 0x901E, // <cjk>
        "E798": 0x9016, // <cjk>
        "E799": 0x900B, // <cjk>
        "E79A": 0x9027, // <cjk>
        "E79B": 0x9036, // <cjk>
        "E79C": 0x9035, // <cjk>
        "E79D": 0x9039, // <cjk>
        "E79E": 0x8FF8, // <cjk>
        "E79F": 0x904F, // <cjk>
        "E7A0": 0x9050, // <cjk>
        "E7A1": 0x9051, // <cjk>
        "E7A2": 0x9052, // <cjk>
        "E7A3": 0x900E, // <cjk>
        "E7A4": 0x9049, // <cjk>
        "E7A5": 0x903E, // <cjk>
        "E7A6": 0x9056, // <cjk>
        "E7A7": 0x9058, // <cjk>
        "E7A8": 0x905E, // <cjk>
        "E7A9": 0x9068, // <cjk>
        "E7AA": 0x906F, // <cjk>
        "E7AB": 0x9076, // <cjk>
        "E7AC": 0x96A8, // <cjk>
        "E7AD": 0x9072, // <cjk>
        "E7AE": 0x9082, // <cjk>
        "E7AF": 0x907D, // <cjk>
        "E7B0": 0x9081, // <cjk>
        "E7B1": 0x9080, // <cjk>
        "E7B2": 0x908A, // <cjk>
        "E7B3": 0x9089, // <cjk>
        "E7B4": 0x908F, // <cjk>
        "E7B5": 0x90A8, // <cjk>
        "E7B6": 0x90AF, // <cjk>
        "E7B7": 0x90B1, // <cjk>
        "E7B8": 0x90B5, // <cjk>
        "E7B9": 0x90E2, // <cjk>
        "E7BA": 0x90E4, // <cjk>
        "E7BB": 0x6248, // <cjk>
        "E7BC": 0x90DB, // <cjk>
        "E7BD": 0x9102, // <cjk>
        "E7BE": 0x9112, // <cjk>
        "E7BF": 0x9119, // <cjk>
        "E7C0": 0x9132, // <cjk>
        "E7C1": 0x9130, // <cjk>
        "E7C2": 0x914A, // <cjk>
        "E7C3": 0x9156, // <cjk>
        "E7C4": 0x9158, // <cjk>
        "E7C5": 0x9163, // <cjk>
        "E7C6": 0x9165, // <cjk>
        "E7C7": 0x9169, // <cjk>
        "E7C8": 0x9173, // <cjk>
        "E7C9": 0x9172, // <cjk>
        "E7CA": 0x918B, // <cjk>
        "E7CB": 0x9189, // <cjk>
        "E7CC": 0x9182, // <cjk>
        "E7CD": 0x91A2, // <cjk>
        "E7CE": 0x91AB, // <cjk>
        "E7CF": 0x91AF, // <cjk>
        "E7D0": 0x91AA, // <cjk>
        "E7D1": 0x91B5, // <cjk>
        "E7D2": 0x91B4, // <cjk>
        "E7D3": 0x91BA, // <cjk>
        "E7D4": 0x91C0, // <cjk>
        "E7D5": 0x91C1, // <cjk>
        "E7D6": 0x91C9, // <cjk>
        "E7D7": 0x91CB, // <cjk>
        "E7D8": 0x91D0, // <cjk>
        "E7D9": 0x91D6, // <cjk>
        "E7DA": 0x91DF, // <cjk>
        "E7DB": 0x91E1, // <cjk>
        "E7DC": 0x91DB, // <cjk>
        "E7DD": 0x91FC, // <cjk>
        "E7DE": 0x91F5, // <cjk>
        "E7DF": 0x91F6, // <cjk>
        "E7E0": 0x921E, // <cjk>
        "E7E1": 0x91FF, // <cjk>
        "E7E2": 0x9214, // <cjk>
        "E7E3": 0x922C, // <cjk>
        "E7E4": 0x9215, // <cjk>
        "E7E5": 0x9211, // <cjk>
        "E7E6": 0x925E, // <cjk>
        "E7E7": 0x9257, // <cjk>
        "E7E8": 0x9245, // <cjk>
        "E7E9": 0x9249, // <cjk>
        "E7EA": 0x9264, // <cjk>
        "E7EB": 0x9248, // <cjk>
        "E7EC": 0x9295, // <cjk>
        "E7ED": 0x923F, // <cjk>
        "E7EE": 0x924B, // <cjk>
        "E7EF": 0x9250, // <cjk>
        "E7F0": 0x929C, // <cjk>
        "E7F1": 0x9296, // <cjk>
        "E7F2": 0x9293, // <cjk>
        "E7F3": 0x929B, // <cjk>
        "E7F4": 0x925A, // <cjk>
        "E7F5": 0x92CF, // <cjk>
        "E7F6": 0x92B9, // <cjk>
        "E7F7": 0x92B7, // <cjk>
        "E7F8": 0x92E9, // <cjk>
        "E7F9": 0x930F, // <cjk>
        "E7FA": 0x92FA, // <cjk>
        "E7FB": 0x9344, // <cjk>
        "E7FC": 0x932E, // <cjk>
        "E840": 0x9319, // <cjk>
        "E841": 0x9322, // <cjk>
        "E842": 0x931A, // <cjk>
        "E843": 0x9323, // <cjk>
        "E844": 0x933A, // <cjk>
        "E845": 0x9335, // <cjk>
        "E846": 0x933B, // <cjk>
        "E847": 0x935C, // <cjk>
        "E848": 0x9360, // <cjk>
        "E849": 0x937C, // <cjk>
        "E84A": 0x936E, // <cjk>
        "E84B": 0x9356, // <cjk>
        "E84C": 0x93B0, // <cjk>
        "E84D": 0x93AC, // <cjk>
        "E84E": 0x93AD, // <cjk>
        "E84F": 0x9394, // <cjk>
        "E850": 0x93B9, // <cjk>
        "E851": 0x93D6, // <cjk>
        "E852": 0x93D7, // <cjk>
        "E853": 0x93E8, // <cjk>
        "E854": 0x93E5, // <cjk>
        "E855": 0x93D8, // <cjk>
        "E856": 0x93C3, // <cjk>
        "E857": 0x93DD, // <cjk>
        "E858": 0x93D0, // <cjk>
        "E859": 0x93C8, // <cjk>
        "E85A": 0x93E4, // <cjk>
        "E85C": 0x9414, // <cjk>
        "E85D": 0x9413, // <cjk>
        "E85E": 0x9403, // <cjk>
        "E85F": 0x9407, // <cjk>
        "E860": 0x9410, // <cjk>
        "E861": 0x9436, // <cjk>
        "E862": 0x942B, // <cjk>
        "E863": 0x9435, // <cjk>
        "E864": 0x9421, // <cjk>
        "E865": 0x943A, // <cjk>
        "E866": 0x9441, // <cjk>
        "E867": 0x9452, // <cjk>
        "E868": 0x9444, // <cjk>
        "E869": 0x945B, // <cjk>
        "E86A": 0x9460, // <cjk>
        "E86B": 0x9462, // <cjk>
        "E86C": 0x945E, // <cjk>
        "E86D": 0x946A, // <cjk>
        "E86E": 0x9229, // <cjk>
        "E86F": 0x9470, // <cjk>
        "E870": 0x9475, // <cjk>
        "E871": 0x9477, // <cjk>
        "E872": 0x947D, // <cjk>
        "E873": 0x945A, // <cjk>
        "E874": 0x947C, // <cjk>
        "E875": 0x947E, // <cjk>
        "E876": 0x9481, // <cjk>
        "E877": 0x947F, // <cjk>
        "E878": 0x9582, // <cjk>
        "E879": 0x9587, // <cjk>
        "E87A": 0x958A, // <cjk>
        "E87B": 0x9594, // <cjk>
        "E87C": 0x9596, // <cjk>
        "E87D": 0x9598, // <cjk>
        "E87E": 0x9599, // <cjk>
        "E880": 0x95A0, // <cjk>
        "E881": 0x95A8, // <cjk>
        "E882": 0x95A7, // <cjk>
        "E883": 0x95AD, // <cjk>
        "E884": 0x95BC, // <cjk>
        "E885": 0x95BB, // <cjk>
        "E886": 0x95B9, // <cjk>
        "E887": 0x95BE, // <cjk>
        "E888": 0x95CA, // <cjk>
        "E889": 0x6FF6, // <cjk>
        "E88A": 0x95C3, // <cjk>
        "E88B": 0x95CD, // <cjk>
        "E88C": 0x95CC, // <cjk>
        "E88D": 0x95D5, // <cjk>
        "E88E": 0x95D4, // <cjk>
        "E88F": 0x95D6, // <cjk>
        "E890": 0x95DC, // <cjk>
        "E891": 0x95E1, // <cjk>
        "E892": 0x95E5, // <cjk>
        "E893": 0x95E2, // <cjk>
        "E894": 0x9621, // <cjk>
        "E895": 0x9628, // <cjk>
        "E896": 0x962E, // <cjk>
        "E897": 0x962F, // <cjk>
        "E898": 0x9642, // <cjk>
        "E899": 0x964C, // <cjk>
        "E89A": 0x964F, // <cjk>
        "E89B": 0x964B, // <cjk>
        "E89C": 0x9677, // <cjk>
        "E89D": 0x965C, // <cjk>
        "E89E": 0x965E, // <cjk>
        "E89F": 0x965D, // <cjk>
        "E8A0": 0x965F, // <cjk>
        "E8A1": 0x9666, // <cjk>
        "E8A2": 0x9672, // <cjk>
        "E8A3": 0x966C, // <cjk>
        "E8A4": 0x968D, // <cjk>
        "E8A5": 0x9698, // <cjk>
        "E8A6": 0x9695, // <cjk>
        "E8A7": 0x9697, // <cjk>
        "E8A8": 0x96AA, // <cjk>
        "E8A9": 0x96A7, // <cjk>
        "E8AA": 0x96B1, // <cjk>
        "E8AB": 0x96B2, // <cjk>
        "E8AC": 0x96B0, // <cjk>
        "E8AD": 0x96B4, // <cjk>
        "E8AE": 0x96B6, // <cjk>
        "E8AF": 0x96B8, // <cjk>
        "E8B0": 0x96B9, // <cjk>
        "E8B1": 0x96CE, // <cjk>
        "E8B2": 0x96CB, // <cjk>
        "E8B3": 0x96C9, // <cjk>
        "E8B4": 0x96CD, // <cjk>
        "E8B5": 0x894D, // <cjk>
        "E8B6": 0x96DC, // <cjk>
        "E8B7": 0x970D, // <cjk>
        "E8B8": 0x96D5, // <cjk>
        "E8B9": 0x96F9, // <cjk>
        "E8BA": 0x9704, // <cjk>
        "E8BB": 0x9706, // <cjk>
        "E8BC": 0x9708, // <cjk>
        "E8BD": 0x9713, // <cjk>
        "E8BE": 0x970E, // <cjk>
        "E8BF": 0x9711, // <cjk>
        "E8C0": 0x970F, // <cjk>
        "E8C1": 0x9716, // <cjk>
        "E8C2": 0x9719, // <cjk>
        "E8C3": 0x9724, // <cjk>
        "E8C4": 0x972A, // <cjk>
        "E8C5": 0x9730, // <cjk>
        "E8C6": 0x9739, // <cjk>
        "E8C7": 0x973D, // <cjk>
        "E8C8": 0x973E, // <cjk>
        "E8C9": 0x9744, // <cjk>
        "E8CA": 0x9746, // <cjk>
        "E8CB": 0x9748, // <cjk>
        "E8CC": 0x9742, // <cjk>
        "E8CD": 0x9749, // <cjk>
        "E8CE": 0x975C, // <cjk>
        "E8CF": 0x9760, // <cjk>
        "E8D0": 0x9764, // <cjk>
        "E8D1": 0x9766, // <cjk>
        "E8D2": 0x9768, // <cjk>
        "E8D3": 0x52D2, // <cjk>
        "E8D4": 0x976B, // <cjk>
        "E8D5": 0x9771, // <cjk>
        "E8D6": 0x9779, // <cjk>
        "E8D7": 0x9785, // <cjk>
        "E8D8": 0x977C, // <cjk>
        "E8D9": 0x9781, // <cjk>
        "E8DA": 0x977A, // <cjk>
        "E8DB": 0x9786, // <cjk>
        "E8DC": 0x978B, // <cjk>
        "E8DD": 0x978F, // <cjk>
        "E8DE": 0x9790, // <cjk>
        "E8DF": 0x979C, // <cjk>
        "E8E0": 0x97A8, // <cjk>
        "E8E1": 0x97A6, // <cjk>
        "E8E2": 0x97A3, // <cjk>
        "E8E3": 0x97B3, // <cjk>
        "E8E4": 0x97B4, // <cjk>
        "E8E5": 0x97C3, // <cjk>
        "E8E6": 0x97C6, // <cjk>
        "E8E7": 0x97C8, // <cjk>
        "E8E8": 0x97CB, // <cjk>
        "E8E9": 0x97DC, // <cjk>
        "E8EA": 0x97ED, // <cjk>
        "E8EB": 0x9F4F, // <cjk>
        "E8EC": 0x97F2, // <cjk>
        "E8ED": 0x7ADF, // <cjk>
        "E8EE": 0x97F6, // <cjk>
        "E8EF": 0x97F5, // <cjk>
        "E8F0": 0x980F, // <cjk>
        "E8F1": 0x980C, // <cjk>
        "E8F2": 0x9838, // <cjk>
        "E8F3": 0x9824, // <cjk>
        "E8F4": 0x9821, // <cjk>
        "E8F5": 0x9837, // <cjk>
        "E8F6": 0x983D, // <cjk>
        "E8F7": 0x9846, // <cjk>
        "E8F8": 0x984F, // <cjk>
        "E8F9": 0x984B, // <cjk>
        "E8FA": 0x986B, // <cjk>
        "E8FB": 0x986F, // <cjk>
        "E8FC": 0x9870, // <cjk>
        "E940": 0x9871, // <cjk>
        "E941": 0x9874, // <cjk>
        "E942": 0x9873, // <cjk>
        "E943": 0x98AA, // <cjk>
        "E944": 0x98AF, // <cjk>
        "E945": 0x98B1, // <cjk>
        "E946": 0x98B6, // <cjk>
        "E947": 0x98C4, // <cjk>
        "E948": 0x98C3, // <cjk>
        "E949": 0x98C6, // <cjk>
        "E94A": 0x98E9, // <cjk>
        "E94B": 0x98EB, // <cjk>
        "E94C": 0x9903, // <cjk>
        "E94D": 0x9909, // <cjk>
        "E94E": 0x9912, // <cjk>
        "E94F": 0x9914, // <cjk>
        "E950": 0x9918, // <cjk>
        "E951": 0x9921, // <cjk>
        "E952": 0x991D, // <cjk>
        "E953": 0x991E, // <cjk>
        "E954": 0x9924, // <cjk>
        "E955": 0x9920, // <cjk>
        "E956": 0x992C, // <cjk>
        "E957": 0x992E, // <cjk>
        "E958": 0x993D, // <cjk>
        "E959": 0x993E, // <cjk>
        "E95A": 0x9942, // <cjk>
        "E95C": 0x9945, // <cjk>
        "E95D": 0x9950, // <cjk>
        "E95E": 0x994B, // <cjk>
        "E95F": 0x9951, // <cjk>
        "E960": 0x9952, // <cjk>
        "E961": 0x994C, // <cjk>
        "E962": 0x9955, // <cjk>
        "E963": 0x9997, // <cjk>
        "E964": 0x9998, // <cjk>
        "E965": 0x99A5, // <cjk>
        "E966": 0x99AD, // <cjk>
        "E967": 0x99AE, // <cjk>
        "E968": 0x99BC, // <cjk>
        "E969": 0x99DF, // <cjk>
        "E96A": 0x99DB, // <cjk>
        "E96B": 0x99DD, // <cjk>
        "E96C": 0x99D8, // <cjk>
        "E96D": 0x99D1, // <cjk>
        "E96E": 0x99ED, // <cjk>
        "E96F": 0x99EE, // <cjk>
        "E970": 0x99F1, // <cjk>
        "E971": 0x99F2, // <cjk>
        "E972": 0x99FB, // <cjk>
        "E973": 0x99F8, // <cjk>
        "E974": 0x9A01, // <cjk>
        "E975": 0x9A0F, // <cjk>
        "E976": 0x9A05, // <cjk>
        "E977": 0x99E2, // <cjk>
        "E978": 0x9A19, // <cjk>
        "E979": 0x9A2B, // <cjk>
        "E97A": 0x9A37, // <cjk>
        "E97B": 0x9A45, // <cjk>
        "E97C": 0x9A42, // <cjk>
        "E97D": 0x9A40, // <cjk>
        "E97E": 0x9A43, // <cjk>
        "E980": 0x9A3E, // <cjk>
        "E981": 0x9A55, // <cjk>
        "E982": 0x9A4D, // <cjk>
        "E983": 0x9A5B, // <cjk>
        "E984": 0x9A57, // <cjk>
        "E985": 0x9A5F, // <cjk>
        "E986": 0x9A62, // <cjk>
        "E987": 0x9A65, // <cjk>
        "E988": 0x9A64, // <cjk>
        "E989": 0x9A69, // <cjk>
        "E98A": 0x9A6B, // <cjk>
        "E98B": 0x9A6A, // <cjk>
        "E98C": 0x9AAD, // <cjk>
        "E98D": 0x9AB0, // <cjk>
        "E98E": 0x9ABC, // <cjk>
        "E98F": 0x9AC0, // <cjk>
        "E990": 0x9ACF, // <cjk>
        "E991": 0x9AD1, // <cjk>
        "E992": 0x9AD3, // <cjk>
        "E993": 0x9AD4, // <cjk>
        "E994": 0x9ADE, // <cjk>
        "E995": 0x9ADF, // <cjk>
        "E996": 0x9AE2, // <cjk>
        "E997": 0x9AE3, // <cjk>
        "E998": 0x9AE6, // <cjk>
        "E999": 0x9AEF, // <cjk>
        "E99A": 0x9AEB, // <cjk>
        "E99B": 0x9AEE, // <cjk>
        "E99C": 0x9AF4, // <cjk>
        "E99D": 0x9AF1, // <cjk>
        "E99E": 0x9AF7, // <cjk>
        "E99F": 0x9AFB, // <cjk>
        "E9A0": 0x9B06, // <cjk>
        "E9A1": 0x9B18, // <cjk>
        "E9A2": 0x9B1A, // <cjk>
        "E9A3": 0x9B1F, // <cjk>
        "E9A4": 0x9B22, // <cjk>
        "E9A5": 0x9B23, // <cjk>
        "E9A6": 0x9B25, // <cjk>
        "E9A7": 0x9B27, // <cjk>
        "E9A8": 0x9B28, // <cjk>
        "E9A9": 0x9B29, // <cjk>
        "E9AA": 0x9B2A, // <cjk>
        "E9AB": 0x9B2E, // <cjk>
        "E9AC": 0x9B2F, // <cjk>
        "E9AD": 0x9B32, // <cjk>
        "E9AE": 0x9B44, // <cjk>
        "E9AF": 0x9B43, // <cjk>
        "E9B0": 0x9B4F, // <cjk>
        "E9B1": 0x9B4D, // <cjk>
        "E9B2": 0x9B4E, // <cjk>
        "E9B3": 0x9B51, // <cjk>
        "E9B4": 0x9B58, // <cjk>
        "E9B5": 0x9B74, // <cjk>
        "E9B6": 0x9B93, // <cjk>
        "E9B7": 0x9B83, // <cjk>
        "E9B8": 0x9B91, // <cjk>
        "E9B9": 0x9B96, // <cjk>
        "E9BA": 0x9B97, // <cjk>
        "E9BB": 0x9B9F, // <cjk>
        "E9BC": 0x9BA0, // <cjk>
        "E9BD": 0x9BA8, // <cjk>
        "E9BE": 0x9BB4, // <cjk>
        "E9BF": 0x9BC0, // <cjk>
        "E9C0": 0x9BCA, // <cjk>
        "E9C1": 0x9BB9, // <cjk>
        "E9C2": 0x9BC6, // <cjk>
        "E9C3": 0x9BCF, // <cjk>
        "E9C4": 0x9BD1, // <cjk>
        "E9C5": 0x9BD2, // <cjk>
        "E9C6": 0x9BE3, // <cjk>
        "E9C7": 0x9BE2, // <cjk>
        "E9C8": 0x9BE4, // <cjk>
        "E9C9": 0x9BD4, // <cjk>
        "E9CA": 0x9BE1, // <cjk>
        "E9CB": 0x9C3A, // <cjk>
        "E9CC": 0x9BF2, // <cjk>
        "E9CD": 0x9BF1, // <cjk>
        "E9CE": 0x9BF0, // <cjk>
        "E9CF": 0x9C15, // <cjk>
        "E9D0": 0x9C14, // <cjk>
        "E9D1": 0x9C09, // <cjk>
        "E9D2": 0x9C13, // <cjk>
        "E9D3": 0x9C0C, // <cjk>
        "E9D4": 0x9C06, // <cjk>
        "E9D5": 0x9C08, // <cjk>
        "E9D6": 0x9C12, // <cjk>
        "E9D7": 0x9C0A, // <cjk>
        "E9D8": 0x9C04, // <cjk>
        "E9D9": 0x9C2E, // <cjk>
        "E9DA": 0x9C1B, // <cjk>
        "E9DB": 0x9C25, // <cjk>
        "E9DC": 0x9C24, // <cjk>
        "E9DD": 0x9C21, // <cjk>
        "E9DE": 0x9C30, // <cjk>
        "E9DF": 0x9C47, // <cjk>
        "E9E0": 0x9C32, // <cjk>
        "E9E1": 0x9C46, // <cjk>
        "E9E2": 0x9C3E, // <cjk>
        "E9E3": 0x9C5A, // <cjk>
        "E9E4": 0x9C60, // <cjk>
        "E9E5": 0x9C67, // <cjk>
        "E9E6": 0x9C76, // <cjk>
        "E9E7": 0x9C78, // <cjk>
        "E9E8": 0x9CE7, // <cjk>
        "E9E9": 0x9CEC, // <cjk>
        "E9EA": 0x9CF0, // <cjk>
        "E9EB": 0x9D09, // <cjk>
        "E9EC": 0x9D08, // <cjk>
        "E9ED": 0x9CEB, // <cjk>
        "E9EE": 0x9D03, // <cjk>
        "E9EF": 0x9D06, // <cjk>
        "E9F0": 0x9D2A, // <cjk>
        "E9F1": 0x9D26, // <cjk>
        "E9F2": 0x9DAF, // <cjk>
        "E9F3": 0x9D23, // <cjk>
        "E9F4": 0x9D1F, // <cjk>
        "E9F5": 0x9D44, // <cjk>
        "E9F6": 0x9D15, // <cjk>
        "E9F7": 0x9D12, // <cjk>
        "E9F8": 0x9D41, // <cjk>
        "E9F9": 0x9D3F, // <cjk>
        "E9FA": 0x9D3E, // <cjk>
        "E9FB": 0x9D46, // <cjk>
        "E9FC": 0x9D48, // <cjk>
        "EA40": 0x9D5D, // <cjk>
        "EA41": 0x9D5E, // <cjk>
        "EA42": 0x9D64, // <cjk>
        "EA43": 0x9D51, // <cjk>
        "EA44": 0x9D50, // <cjk>
        "EA45": 0x9D59, // <cjk>
        "EA46": 0x9D72, // <cjk>
        "EA47": 0x9D89, // <cjk>
        "EA48": 0x9D87, // <cjk>
        "EA49": 0x9DAB, // <cjk>
        "EA4A": 0x9D6F, // <cjk>
        "EA4B": 0x9D7A, // <cjk>
        "EA4C": 0x9D9A, // <cjk>
        "EA4D": 0x9DA4, // <cjk>
        "EA4E": 0x9DA9, // <cjk>
        "EA4F": 0x9DB2, // <cjk>
        "EA50": 0x9DC4, // <cjk>
        "EA51": 0x9DC1, // <cjk>
        "EA52": 0x9DBB, // <cjk>
        "EA53": 0x9DB8, // <cjk>
        "EA54": 0x9DBA, // <cjk>
        "EA55": 0x9DC6, // <cjk>
        "EA56": 0x9DCF, // <cjk>
        "EA57": 0x9DC2, // <cjk>
        "EA58": 0x9DD9, // <cjk>
        "EA59": 0x9DD3, // <cjk>
        "EA5A": 0x9DF8, // <cjk>
        "EA5C": 0x9DED, // <cjk>
        "EA5D": 0x9DEF, // <cjk>
        "EA5E": 0x9DFD, // <cjk>
        "EA5F": 0x9E1A, // <cjk>
        "EA60": 0x9E1B, // <cjk>
        "EA61": 0x9E1E, // <cjk>
        "EA62": 0x9E75, // <cjk>
        "EA63": 0x9E79, // <cjk>
        "EA64": 0x9E7D, // <cjk>
        "EA65": 0x9E81, // <cjk>
        "EA66": 0x9E88, // <cjk>
        "EA67": 0x9E8B, // <cjk>
        "EA68": 0x9E8C, // <cjk>
        "EA69": 0x9E92, // <cjk>
        "EA6A": 0x9E95, // <cjk>
        "EA6B": 0x9E91, // <cjk>
        "EA6C": 0x9E9D, // <cjk>
        "EA6D": 0x9EA5, // <cjk>
        "EA6E": 0x9EA9, // <cjk>
        "EA6F": 0x9EB8, // <cjk>
        "EA70": 0x9EAA, // <cjk>
        "EA71": 0x9EAD, // <cjk>
        "EA72": 0x9761, // <cjk>
        "EA73": 0x9ECC, // <cjk>
        "EA74": 0x9ECE, // <cjk>
        "EA75": 0x9ECF, // <cjk>
        "EA76": 0x9ED0, // <cjk>
        "EA77": 0x9ED4, // <cjk>
        "EA78": 0x9EDC, // <cjk>
        "EA79": 0x9EDE, // <cjk>
        "EA7A": 0x9EDD, // <cjk>
        "EA7B": 0x9EE0, // <cjk>
        "EA7C": 0x9EE5, // <cjk>
        "EA7D": 0x9EE8, // <cjk>
        "EA7E": 0x9EEF, // <cjk>
        "EA80": 0x9EF4, // <cjk>
        "EA81": 0x9EF6, // <cjk>
        "EA82": 0x9EF7, // <cjk>
        "EA83": 0x9EF9, // <cjk>
        "EA84": 0x9EFB, // <cjk>
        "EA85": 0x9EFC, // <cjk>
        "EA86": 0x9EFD, // <cjk>
        "EA87": 0x9F07, // <cjk>
        "EA88": 0x9F08, // <cjk>
        "EA89": 0x76B7, // <cjk>
        "EA8A": 0x9F15, // <cjk>
        "EA8B": 0x9F21, // <cjk>
        "EA8C": 0x9F2C, // <cjk>
        "EA8D": 0x9F3E, // <cjk>
        "EA8E": 0x9F4A, // <cjk>
        "EA8F": 0x9F52, // <cjk>
        "EA90": 0x9F54, // <cjk>
        "EA91": 0x9F63, // <cjk>
        "EA92": 0x9F5F, // <cjk>
        "EA93": 0x9F60, // <cjk>
        "EA94": 0x9F61, // <cjk>
        "EA95": 0x9F66, // <cjk>
        "EA96": 0x9F67, // <cjk>
        "EA97": 0x9F6C, // <cjk>
        "EA98": 0x9F6A, // <cjk>
        "EA99": 0x9F77, // <cjk>
        "EA9A": 0x9F72, // <cjk>
        "EA9B": 0x9F76, // <cjk>
        "EA9C": 0x9F95, // <cjk>
        "EA9D": 0x9F9C, // <cjk>
        "EA9E": 0x9FA0, // <cjk>
        "EA9F": 0x582F, // <cjk>	[1983]
        "EAA0": 0x69C7, // <cjk>	[1983]
        "EAA1": 0x9059, // <cjk>	[1983]
        "EAA2": 0x7464, // <cjk>	[1983]
        "EAA3": 0x51DC, // <cjk>	[1990]
        "EAA4": 0x7199  // <cjk>	[1990]
    }

    public sjisToUnicode(hex: string): number {
        return this.unicode[hex];
    }
}