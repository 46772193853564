<div class="input-group has-validation">
    <ng-container *ngFor="let addon of leftAddons">
        <ng-container *ngTemplateOutlet="addon"></ng-container>
    </ng-container>
    <ng-container *ngTemplateOutlet="input"></ng-container>
    <ng-container *ngFor="let addon of rightAddons">
        <ng-container *ngTemplateOutlet="addon"></ng-container>
    </ng-container>
    <ng-container *ngTemplateOutlet="validation"></ng-container>
</div>
