import * as angular from 'angular';
import { MODULE_NAME } from '../../../config/constants';
import FdxTreeController from './fdx-tree.controller';

export const componentName = 'fdxTree';

angular.module(MODULE_NAME).component(componentName, {
    controller: FdxTreeController,
    // eslint-disable-next-line
    template: require('./fdx-tree.template.html'),
    bindings: {
        data: '=',
        field: '=',
        maxNodes: '=',
        chunkSize: '='
    }
});
