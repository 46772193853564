export default class FdxError {
    public key: string = null;
    public description: string = null;

    constructor(key: string, description: string) {
        if (key) {
            this.key = key;
        }

        if (description) {
            this.description = description;
        }
    }
}
