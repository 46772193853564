
export default class FdxFileMapsConfirmNotificationEmailModalController {

	// from uibmodal
	private close: (result?: { $value }) => void;
	private dismiss: (result?: { $value: string }) => void;

	public notificationAddress: string = '';

	static $inject = [];

	constructor() { }

	public onSubmit(): void {
		if (!this.notificationAddress) {
			return;
		}

		this.close(
			{
				$value: this.notificationAddress
			}
		);
	}

	public onCancel(): void {
		this.dismiss();
	}
}
