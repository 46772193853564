<div [ngbTooltip]="tooltip">
    <button
        type="button"
        class="btn btn-link text-nowrap py-1"
        [disabled]="disabledState"
        (click)="changeDisplayedQuery()">
        <fa-icon
            [icon]="faArrowRightArrowleft"
            size="sm">
        </fa-icon>
        <span>{{ buttonText }}</span>
    </button>
</div>
