import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { IconDefinition, faChevronLeft, faChevronRight, faEllipsisH } from '@fortawesome/pro-solid-svg-icons';

@Component({
    selector: 'fdx-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnChanges {
    static readonly FILTER_PAG_REGEX: RegExp = /[^0-9]/g;

    @Input() page: number = 1;
    @Output() readonly pageChange: EventEmitter<number> = new EventEmitter<number>();

    @Input() pageCount: number;
    @Input() pageSize: number = 10;
    @Input() maxSize: number = 5;
    @Input() size: 'sm' | 'lg';
    @Input() withInput: boolean = true;
    @Input() withLabels: boolean = false;
    @Input() position: 'start' | 'center' | 'end' = 'center';

    readonly iconChevronLeft: IconDefinition = faChevronLeft;
    readonly iconChevronRight: IconDefinition = faChevronRight;
    readonly iconEllipsis: IconDefinition = faEllipsisH;

    get positionClass(): string {
        return `justify-content-${this.position}`;
    }

    get collectionSizeUpperBound(): number {
        return this.pageCount * this.pageSize;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.pageCount && this.withInput) {
            this.maxSize = this.pageCount;
        }
    }

    formatInput(e: Event): void {
        const { target } = e;

        if (target instanceof HTMLInputElement) {
            target.value = target.value.replace(PaginationComponent.FILTER_PAG_REGEX, '');
        }
    }

    parseInt(number: string): number {
        return parseInt(number, 10);
    }

    updatePage(page: number): void {
        if (Number.isNaN(page) || page === 0 || page > this.pageCount) {
            return;
        }

        this.page = page;
        this.pageChange.emit(this.page);
    }
}
