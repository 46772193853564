export default function() {
    const entityMap = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': '&quot;',
        "'": '&#39;',
        "/": '&#x2F;'
    };
    return function(str: string): string {
        return String(str).replace(/[&<>"'/]/g, function (s): string {
            return entityMap[s];
        });
    }
}