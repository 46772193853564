/* eslint-disable @typescript-eslint/no-explicit-any */
import { FeedSupportTicket } from '@app/feed-support/models/types/feed-support-ticket.type';
import { faBagsShopping, faBarcodeRead, faBrowser, faCirclePause, faCirclePlay, faCircleXmark, faDatabase, faFileExport, faFileImport, faFileInvoiceDollar, faGridRound2, faGridRound2Plus, faImages, faLayerPlus, faLink, faMagnifyingGlassChart, faMoneyCheckDollar, faScannerKeyboard, faShieldHalved, faSliders, faSparkles, faSunglasses, faTableList, faTriangleExclamation } from '@fortawesome/pro-duotone-svg-icons';
// import { faSolidCartShoppingCirclePause, faSolidCartShoppingCirclePlay } from '@awesome.me/kit-022db84172/icons/modules/kit/custom';

// #region POPULAR
const CUSTOM_LABEL: FeedSupportTicket = {
    title: 'Custom labels',
    label: 'custom labels',
    subtitle: 'Manage product custom labels',
    image: '/dist/app/assets/images/tag_grid.svg',
    link: {
        form: 'feeds-feed-support',
        topic: 'custom_labels'
    }
};

const ADD_EXCLUDE_PRODUCTS: FeedSupportTicket = {
    title: 'Add/exclude products',
    label: 'add/exclude products',
    subtitle: 'Manage which products are included in your export feeds',
    image: '/dist/app/assets/images/three_product_cards.svg',
    link: {
        form: 'feeds-feed-support',
        topic: 'add_exclude_products'
    }
};

const GOOGLE_BING_PROMOTIONS: FeedSupportTicket = {
    title: 'Google/Bing promotions',
    label: 'Google/Bing promotions',
    subtitle: 'Manage Google and Bing promotions',
    image: '/dist/app/assets/images/product_card_with_percentage_badge.svg',
    link: {
        form: 'feeds-feed-support',
        topic: 'promotions'
    }
};

const EXPORT_TROUBLESHOOTING: FeedSupportTicket = {
    title: 'Export troubleshooting',
    label: 'export troubleshooting',
    subtitle: 'Assistance with issues related to data exports',
    image: '/dist/app/assets/images/card_to_export_icon_to_card_grid.svg',
    link: {
        form: 'feeds-feed-support',
        topic: 'export_troubleshooting'
    }
};

const IMPORT_TROUBLESHOOTING: FeedSupportTicket = {
    title: 'Import troubleshooting',
    label: 'import troubleshooting',
    subtitle: 'Assistance with issues related to importing data',
    image: '/dist/app/assets/images/card_grid_to_import_icon_to_card.svg',
    link: {
        form: 'feeds-feed-support',
        topic: 'import_troubleshooting'
    }
};

const OPTIMIZE_TITLE_DESCRIPTION: FeedSupportTicket = {
    title: 'Optimize title/description',
    label: 'optimize title/description',
    subtitle: 'Assistance with improving titles or descriptions in your exports',
    image: '/dist/app/assets/images/card_with_text_and_sparkle.svg',
    link: {
        form: 'feeds-feed-support',
        topic: 'title_description_optimization'
    }
};
// #endregion


// #region TOPICS_DATABASE
const ADD_STORE: FeedSupportTicket = {
    title: 'Add store',
    label: 'add store',
    subtitle: 'Add a new store to Feedonomics',
    icon: faDatabase,
    link: {
        form: 'stores-add-store'
    }
};

const PAUSE_STORE: FeedSupportTicket = {
    title: 'Pause store',
    label: 'pause store',
    subtitle: 'Pause an existing store',
    icon: faCirclePause,
    link: {
        form: 'stores-pause-store'
    }
};

const UNPAUSE_STORE: FeedSupportTicket = {
    title: 'Unpause store',
    label: 'unpause store',
    subtitle: 'Resume a previously paused store',
    icon: faCirclePlay,
    link: {
        form: 'stores-unpause-store'
    }
};
// #endregion

// #region TOPICS_CHANNEL_ISSUES
const ACCOUNT_SUSPENSION: FeedSupportTicket = {
    title: 'Account suspension',
    label: 'account suspension',
    subtitle: 'Assistance with resolving account suspension issues',
    icon: faTriangleExclamation,
    link: {
        form: 'feeds-feed-support',
        topic: 'account_suspension'
    }
};

const PRICE_AVAILABILITY_ERRORS: FeedSupportTicket = {
    title: 'Price/availability errors',
    label: 'price/availability errors',
    subtitle: 'Report and address price or availability errors',
    icon: faMoneyCheckDollar,
    link: {
        form: 'feeds-feed-support',
        topic: 'price_availability_errors'
    }
};

const CHANNEL_ERRORS: FeedSupportTicket = {
    title: 'Channel errors',
    label: 'channel errors',
    subtitle: 'Support for channel-specific errors',
    icon: faGridRound2,
    link: {
        form: 'feeds-feed-support',
        topic: 'channel_specific_errors'
    }
};

const PRODUCT_LISTING_ERRORS: FeedSupportTicket = {
    title: 'Product listing errors',
    label: 'product listing errors',
    subtitle: 'Support for product listing errors',
    icon: faBagsShopping,
    link: {
        form: 'feeds-feed-support',
        topic: 'marketplace_listing_errors'
    }
};

const GTIN_RELATED_ISSUES: FeedSupportTicket = {
    title: 'GTINs related issues',
    label: 'GTINs related issues',
    subtitle: 'Report and resolve GTIN errors',
    icon: faBarcodeRead,
    link: {
        form: 'feeds-feed-support',
        topic: 'gtins_related_issues'
    }
};

const LINK_OR_IMAGE_ISSUES: FeedSupportTicket = {
    title: 'Link/image issues',
    label: 'link/image issues',
    subtitle: 'Report and resolve link or image issues',
    icon: faLink,
    link: {
        form: 'feeds-feed-support',
        topic: 'link_or_image_issues'
    }
};

const ID_TRACKING_ISSUES: FeedSupportTicket = {
    title: 'Product ID tracking issues',
    label: 'product ID tracking issues',
    subtitle: 'Support for UTM or other product ID tracking issues',
    icon: faMagnifyingGlassChart,
    link: {
        form: 'feeds-feed-support',
        topic: 'id_tracking_issues'
    }
};

const TAX_SHIPPING_ERRORS: FeedSupportTicket = {
    title: 'Tax/shipping errors',
    label: 'tax/shipping errors',
    subtitle: 'Assistance with tax and shipping errors',
    icon: faScannerKeyboard,
    link: {
        form: 'feeds-feed-support',
        topic: 'tax_shipping_errors'
    }
};
// #endregion

// #region IMPORTS
const IMPORT_TROUBLESHOOTING_ICON: FeedSupportTicket = {
    title: 'Import troubleshooting',
    label: 'import troubleshooting',
    subtitle: 'Assistance with issues related to importing data',
    icon: faFileImport,
    link: {
        form: 'feeds-feed-support',
        topic: 'import_troubleshooting'
    }
};

const IMPORT_CHANGE: FeedSupportTicket = {
    title: 'Import change',
    label: 'import change',
    subtitle: 'Modify your import settings',
    icon: faSliders,
    link: {
        form: 'feeds-feed-support',
        topic: 'import_change'
    }
};
// #endregion

// #region EXPORTS
const EXPORT_TROUBLESHOOTING_ICON: FeedSupportTicket = {
    title: 'Export troubleshooting',
    label: 'export troubleshooting',
    subtitle: 'Assistance with issues related to data exports',
    icon: faFileExport,
    link: {
        form: 'feeds-feed-support',
        topic: 'export_troubleshooting'
    }
};

const ADD_EXCLUDE_PRODUCTS_ICON: FeedSupportTicket = {
    title: 'Add/exclude products',
    label: 'add/exclude products',
    subtitle: 'Manage which products are included in your export feeds',
    icon: faSunglasses,
    link: {
        form: 'feeds-feed-support',
        topic: 'add_exclude_products'
    }
};

const OPTIMIZE_TITLE_DESCRIPTION_ICON: FeedSupportTicket = {
    title: 'Optimize title/description',
    label: 'optimize title/description',
    subtitle: 'Assistance with improving titles or descriptions in your exports',
    icon: faSparkles,
    link: {
        form: 'feeds-feed-support',
        topic: 'title_description_optimization'
    }
};

const OPTIMIZE_DATA: FeedSupportTicket = {
    title: 'Optimize data',
    label: 'optimize data',
    subtitle: 'Request re-optimization of your exported data',
    image: '/dist/app/assets/icons/file_sparkle.svg',
    link: {
        form: 'feeds-feed-support',
        topic: 're_optimization'
    }
};

const POPULATE_CATEGORIES: FeedSupportTicket =
{
    title: 'Categorization issues',
    label: 'categorization issues',
    subtitle: 'Get help with product categorization in your exports',
    icon: faTableList,
    link: {
        form: 'feeds-feed-support',
        topic: 'populate_missing_or_update_categories'
    }
};

const DATA_GOVERNANCE: FeedSupportTicket = {
    title: 'Data governance',
    label: 'data governance',
    subtitle: 'Understand and manage the rules for data export protection',
    icon: faShieldHalved,
    link: {
        form: 'feeds-feed-support',
        topic: 'data_governance'
    }
};

const REPLATFORM_FEED_BUILD: FeedSupportTicket = {
    title: 'Change website provider/eCommerce platform',
    label: 'change website provider/eCommerce platform',
    subtitle: 'Notify us about migrating or upgrading your eCommerce platform',
    icon: faBrowser,
    link: {
        form: 'feeds-feed-support',
        topic: 'replatform_feed_build'
    }
};

const ADD_CHANNEL: FeedSupportTicket = {
    title: 'Add channel',
    label: 'add channel',
    subtitle: 'Add a new channel to export your data',
    icon: faGridRound2Plus,
    link: {
        form: 'feeds-new-feed'
    }
};

const PAUSE_CHANNEL: FeedSupportTicket = {
    title: 'Pause channel',
    label: 'pause channel',
    subtitle: 'Pause data exports for a channel',
    image: '/dist/app/assets/icons/cart_pause.svg',
    link: {
        form: 'feeds-pause-feed'
    }
};

const UNPAUSE_CHANNEL: FeedSupportTicket = {
    title: 'Unpause channel',
    label: 'unpause channel',
    subtitle: 'Unpause data exports for a channel',
    image: '/dist/app/assets/icons/cart_play.svg',
    link: {
        form: 'feeds-unpause-feed'
    }
};
// #endregion


// #region EXTRA_CHANNEL_PROGRAMS
const MARKETPLACE_PROMOTIONS: FeedSupportTicket = {
    title: 'Marketplace promotions',
    label: 'marketplace promotions',
    subtitle: 'Add or adjust promotions for marketplaces',
    link: {
        form: 'feeds-feed-support',
        topic: 'marketplace_promotions'
    }
};

const LOCAL_INVENTORY_ADS_SUPPORT: FeedSupportTicket = {
    title: 'Local inventory ads',
    label: 'local inventory ads',
    subtitle: 'Support for local inventory ads',
    link: {
        form: 'feeds-feed-support',
        topic: 'local_inventory_ads_support'
    }
};

const PRODUCT_RATINGS: FeedSupportTicket = {
    title: 'Product ratings',
    label: 'product ratings',
    subtitle: 'Support for product ratings feeds',
    link: {
        form: 'feeds-feed-support',
        topic: 'product_ratings'
    }
};

const DYNAMIC_TEXT_ADS_SUPPORT: FeedSupportTicket = {
    title: 'Dynamic text ads',
    label: 'dynamic text ads',
    subtitle: 'Support for dynamic text ads',
    link: {
        form: 'feeds-feed-support',
        topic: 'dynamic_text_ads_support'
    }
};
// #endregion


// #region EXTRA_ORDERS
const ORDER_AUTOMATION_ISSUES: FeedSupportTicket = {
    title: 'Order automation issues',
    label: 'order automation issues',
    subtitle: 'Get help with order automation issues',
    link: {
        form: 'feeds-feed-support',
        topic: 'order_automation_support'
    }
};

const SET_UP_ORDER_AUTOMATION: FeedSupportTicket = {
    title: 'Order automation set up',
    label: 'order automation set up',
    subtitle: 'Set up order automation for sales channels',
    link: {
        form: 'feeds-feed-support',
        topic: 'set_up_order_automation'
    }
};
// #endregion


// #region EXTRA_TOOLS
const IMAGE_PADDING: FeedSupportTicket = {
    title: 'Image padding',
    label: 'image padding',
    subtitle: 'Apply extra space around the edges of your images',
    icon: faImages,
    link: {
        form: 'products-image-padding'
    }
};

const IMAGE_OVERLAY: FeedSupportTicket = {
    title: 'Dynamic image overlay',
    label: 'dynamic image overlay',
    subtitle: 'Add custom text or graphics on top of your images',
    icon: faLayerPlus,
    link: {
        form: 'products-dynamic-image-overlays'
    }
};

const A_B_TESTING: FeedSupportTicket = {
    title: 'A/B testing',
    label: 'A/B testing',
    subtitle: 'Conduct split tests on products within your feed',
    link: {
        form: 'feeds-feed-support',
        topic: 'a_b_testing'
    }
};

const SET_UP_GOOGLE_MERCHANT_CENTER: FeedSupportTicket = {
    title: 'Set up Google Merchant Center alerts',
    label: 'set up Google Merchant Center alerts',
    subtitle: 'Configure alerts for Google Merchant Center',
    link: {
        form: 'feeds-feed-support',
        topic: 'setup_google_merchant_center_alerts'
    }
};
// #endregion


// #region EXTRA_ACCOUNT
const FEED_AUDIT_REQUEST: FeedSupportTicket = {
    title: 'Feed audit request',
    label: 'feed audit request',
    subtitle: 'Request a review of your data feed',
    link: {
        form: 'feeds-feed-support',
        topic: 'feed_audit_request'
    }
};

const BILLING_INQUIRY: FeedSupportTicket = {
    title: 'Billing inquiry',
    label: 'billing inquiry',
    subtitle: 'Questions about your account billing',
    icon: faFileInvoiceDollar,
    link: {
        form: 'billing-billing-question'
    }
};

const SERVICE_CANCELLATION: FeedSupportTicket = {
    title: 'Service cancellation',
    label: 'service cancellation',
    subtitle: 'Request cancellation for a service',
    icon: faCircleXmark,
    link: {
        form: 'stores-initiate-cancellation'
    }
};
// #endregion


// #region OTHERS
export const FEED_SUPPORT_GENERAL_INQUIRY: FeedSupportTicket = {
    title: 'General inquiry',
    label: 'general inquiry',
    subtitle: 'Any question not related to a listed topic',
    link: {
        form: 'feeds-feed-support',
        topic: 'inquiry'
    }
};
// #endregion

// #region ANALYST
export const FLEXITICKET_PORTAL: FeedSupportTicket = {
    title: 'Feedonomics FlexiTicket',
    label: 'Feedonomics FlexiTicket',
    subtitle: 'Analyst-only ticket entry',
    link: {
        form: 'analyst-ticket-portal-provide-details'
    }
}
// #endregion

export const FEED_SUPPORT_TICKETS_POPULAR: FeedSupportTicket[] = [
    CUSTOM_LABEL,
    ADD_EXCLUDE_PRODUCTS,
    GOOGLE_BING_PROMOTIONS,
    EXPORT_TROUBLESHOOTING,
    IMPORT_TROUBLESHOOTING,
    OPTIMIZE_TITLE_DESCRIPTION
];

export const FEED_SUPPORT_TICKETS_STORES: FeedSupportTicket[] = [
    ADD_STORE,
    PAUSE_STORE,
    UNPAUSE_STORE
];

export const FEED_SUPPORT_TICKETS_CHANNEL_ISSUES: FeedSupportTicket[] = [
    ACCOUNT_SUSPENSION,
    PRICE_AVAILABILITY_ERRORS,
    CHANNEL_ERRORS,
    PRODUCT_LISTING_ERRORS,
    GTIN_RELATED_ISSUES,
    LINK_OR_IMAGE_ISSUES,
    ID_TRACKING_ISSUES,
    TAX_SHIPPING_ERRORS
]

export const FEED_SUPPORT_TICKETS_IMPORTS: FeedSupportTicket[] = [
    IMPORT_TROUBLESHOOTING_ICON,
    IMPORT_CHANGE
]

export const FEED_SUPPORT_TICKETS_EXPORTS: FeedSupportTicket[] = [
    EXPORT_TROUBLESHOOTING_ICON,
    ADD_EXCLUDE_PRODUCTS_ICON,
    OPTIMIZE_TITLE_DESCRIPTION_ICON,
    OPTIMIZE_DATA,
    POPULATE_CATEGORIES,
    DATA_GOVERNANCE,
    REPLATFORM_FEED_BUILD,
    ADD_CHANNEL,
    PAUSE_CHANNEL,
    UNPAUSE_CHANNEL
]

export const FEED_SUPPORT_TICKETS_CHANNEL_PROGRAMS: FeedSupportTicket[] = [
    CUSTOM_LABEL,
    GOOGLE_BING_PROMOTIONS,
    MARKETPLACE_PROMOTIONS,
    LOCAL_INVENTORY_ADS_SUPPORT,
    PRODUCT_RATINGS,
    DYNAMIC_TEXT_ADS_SUPPORT
];

export const FEED_SUPPORT_TICKETS_ORDERS: FeedSupportTicket[] = [
    SET_UP_ORDER_AUTOMATION,
    ORDER_AUTOMATION_ISSUES
];

export const FEED_SUPPORT_TICKETS_TOOLS: FeedSupportTicket[] = [
    IMAGE_PADDING,
    IMAGE_OVERLAY,
    A_B_TESTING,
    SET_UP_GOOGLE_MERCHANT_CENTER
];

export const FEED_SUPPORT_TICKETS_ACCOUNT: FeedSupportTicket[] = [
    FEED_AUDIT_REQUEST,
    BILLING_INQUIRY,
    SERVICE_CANCELLATION
];

export const FEED_SUPPORT_TICKETS_ALL: FeedSupportTicket[] = [
    GOOGLE_BING_PROMOTIONS,
    CUSTOM_LABEL,
    ADD_EXCLUDE_PRODUCTS,
    EXPORT_TROUBLESHOOTING,
    IMPORT_TROUBLESHOOTING,
    OPTIMIZE_TITLE_DESCRIPTION,
    ADD_STORE,
    PAUSE_STORE,
    UNPAUSE_STORE,
    SERVICE_CANCELLATION,
    ADD_CHANNEL,
    PAUSE_CHANNEL,
    UNPAUSE_CHANNEL,
    IMAGE_PADDING,
    IMAGE_OVERLAY,
    BILLING_INQUIRY,
    DATA_GOVERNANCE,
    IMPORT_CHANGE,
    POPULATE_CATEGORIES,
    PRODUCT_RATINGS,
    OPTIMIZE_DATA,
    MARKETPLACE_PROMOTIONS,
    REPLATFORM_FEED_BUILD,
    DYNAMIC_TEXT_ADS_SUPPORT,
    ACCOUNT_SUSPENSION,
    CHANNEL_ERRORS,
    GTIN_RELATED_ISSUES,
    ID_TRACKING_ISSUES,
    LINK_OR_IMAGE_ISSUES,
    PRICE_AVAILABILITY_ERRORS,
    TAX_SHIPPING_ERRORS,
    LOCAL_INVENTORY_ADS_SUPPORT,
    PRODUCT_LISTING_ERRORS,
    ORDER_AUTOMATION_ISSUES,
    FEED_AUDIT_REQUEST,
    A_B_TESTING,
    FEED_SUPPORT_GENERAL_INQUIRY,
    SET_UP_GOOGLE_MERCHANT_CENTER,
    SET_UP_ORDER_AUTOMATION
];
