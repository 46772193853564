<div [attr.title]="tooltip ?? null">
    <button
        *ngIf="clickable; else notClickable"
        #button
        type="button"
        class="btn badge rounded-pill icon-badge"
        [ngClass]="buttonClasses"
        [disabled]="disabled || isZero"
        (click)="onClick($event)"
        (mousedown)="onMouseDown($event)">
        <ng-container [ngTemplateOutlet]="badgeContent"></ng-container>
    </button>
</div>

<ng-template #notClickable>
    <div
        class="badge rounded-pill icon-badge"
        [ngClass]="badgeThemeClass">
        <ng-container [ngTemplateOutlet]="badgeContent"></ng-container>
    </div>
</ng-template>

<ng-template #badgeContent>
    <fa-icon
        *ngIf="icon"
        [icon]="icon"
        size="lg"></fa-icon>
    <span *ngIf="textIsNumber; else textIsString">{{ text | number }}</span>
    <ng-template #textIsString>
        <span>{{ text }}</span>
    </ng-template>
</ng-template>
