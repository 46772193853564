import { DecimalPipe, NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { BootstrapThemeProp, BootstrapThemeService, BootstrapThemeType } from '@app/core/services/bootstrap-theme.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

@Component({
    standalone: true,
    selector: 'fdx-icon-badge-button',
    templateUrl: './icon-badge-button.component.html',
    imports: [
        DecimalPipe,
        FontAwesomeModule,
        NgClass,
        NgIf,
        NgTemplateOutlet
    ]
})
export class IconBadgeButtonComponent implements OnChanges {

    @Input() theme: BootstrapThemeProp | null;
    @Input() iconOverride?: IconDefinition;
    @Input() text: string | number;
    @Input() classes: Record<string, boolean>;
    @Input() disabled?: boolean = false;
    @Input() active?: boolean = false;
    @Input() clickable?: boolean = true;
    @Input() desaturateWhenZero?: boolean = false;
    @Input() tooltip?: string;
    @Output() readonly buttonClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
    @Output() readonly buttonMouseDown: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

    @ViewChild('button') button: ElementRef<HTMLButtonElement>;

    themeType: BootstrapThemeType;

    get isZero(): boolean {
        return this.desaturateWhenZero && this.textIsNumber && Number(this.text) === 0;
    }

    get buttonThemeClass(): string {
        if (!this.themeType || this.isZero) {
            return 'btn-outline-secondary';
        }
        return `btn-outline-${this.themeType.name}`;
    }

    get badgeThemeClass(): string {
        if (!this.themeType || this.isZero) {
            return 'text-bg-outline-secondary';
        }
        return `text-bg-outline-${this.themeType.name}`;
    }

    get buttonClasses(): Record<string, boolean> {
        return {
            ...this.classes,
            [this.buttonThemeClass]: true,
            'active': this.active
        };
    }

    get badgeClasses(): Record<string, boolean> {
        return {
            ...this.classes,
            [this.badgeThemeClass]: true
        };
    }

    get icon(): IconDefinition | null {
        if (!this.theme) {
            return null;
        }
        return this.iconOverride !== undefined ? this.iconOverride : this.themeType.icon;
    }

    get textIsNumber(): boolean {
        return typeof this.text === 'number' || !isNaN(Number(this.text));
    }

    constructor(
        private readonly bootstrapThemeService: BootstrapThemeService
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if ('theme' in changes && this.theme) {
            this.themeType = this.bootstrapThemeService.getThemeType(this.theme);
        }
    }

    onClick(e: MouseEvent): void {
        this.buttonClick.emit(e);
        this.button.nativeElement.blur();   // Force a blur since some emitted AgGrid events seem to block
        // the native blur, and the button would remained focused
    }

    onMouseDown(e: MouseEvent): void {
        this.buttonMouseDown.emit(e);
    }
}
