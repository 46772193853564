import { Component, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ClassInput } from '@feedonomics/frontend-components';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';

@Component({
    selector: 'fdx-collapsible-code',
    templateUrl: './collapsible-code.component.html',
    styleUrls: ['./collapsible-code.component.scss']
})
export class CollapsibleCodeComponent implements OnChanges {

    @Input() content: string = '';
    @Input() readonly: boolean = false;
    @Input() overflowScroll: boolean = false;

    isCollapsed: boolean = true;

    readonly downIcon: IconDefinition = faChevronDown;
    readonly upIcon: IconDefinition = faChevronUp;

    constructor(
        public readonly element: ElementRef<HTMLElement>
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if ('content' in changes) {
            this.bindContent();
        }
    }

    toggle(): void {
        const codeMirrorScroll = this.element.nativeElement.querySelector('div.CodeMirror-scroll');

        if (codeMirrorScroll) {
            codeMirrorScroll.scrollTop = 0;
            codeMirrorScroll.scrollLeft = 0;
        }

        this.isCollapsed = !this.isCollapsed;
    }

    get showToggle(): boolean {
        return this.content && (this.content.match(/\n/g) || []).length >= 3;
    }

    get toggleText(): string {
        return this.isCollapsed ? 'Expand' : 'Contract';
    }

    get classes(): ClassInput {
        return {
            'code-collapsed': this.isCollapsed,
            'readonly': this.readonly,
            'codemirror-overflow-v-scroll': this.overflowScroll
        };
    }

    get icon(): IconDefinition {
        return this.isCollapsed ? this.downIcon : this.upIcon;
    }

    private bindContent(): void {
        if (this.isCollapsed && !this.showToggle) {
            this.isCollapsed = false;
        }
    }
}
