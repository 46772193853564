import { AgGridModule } from '@ag-grid-community/angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LogEntryCellRendererComponent } from '@app/logs/components/log-entry-cell-renderer/log-entry-cell-renderer.component';
import { LogsChangeComponent } from '@app/logs/components/logs-change/logs-change.component';
import { LogsEDRTSComponent } from '@app/logs/components/logs-edrts/logs-edrts.component';
import { LogsExportEventQueueComponent } from '@app/logs/components/logs-export-event-queue/logs-export-event-queue.component';
import { LogsFeedAlertsComponent } from '@app/logs/components/logs-feed-alerts/logs-feed-alerts.component';
import { LogsFiltersComponent } from '@app/logs/components/logs-filters/logs-filters.component';
import { LogsImportExportComponent } from '@app/logs/components/logs-import-export/logs-import-export.component';
import { LogsInfoModalComponent } from '@app/logs/components/logs-info-modal/logs-info-modal.component';
import { LogsTransformerComponent } from '@app/logs/components/logs-transformer/logs-transformer.component';
import { LogsRoutingModule } from '@app/logs/logs-routing.module';
import { LogsPageComponent } from '@app/logs/pages/logs-page/logs-page.component';
import { DirectivesModule } from '@app/modules/directives/directives.module';
import { InputsModule } from '@app/modules/inputs';
import { ModalsModule } from '@app/modules/modals/modals.module';
import { PageModule } from '@app/modules/page/page.module';
import { PaginationModule } from '@app/modules/pagination/pagination.module';
import { SearchBarModule } from '@app/modules/search-bar/search-bar.module';
import { FilterChipsComponent } from '@app/standalone-components/buttons/filter-chips/filter-chips.component';
import { DaterangePickerModule, SelectModule } from '@feedonomics/frontend-components';
import { NgSelectModule } from '@feedonomics/ng-select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbCollapseModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [
        LogEntryCellRendererComponent,
        LogsChangeComponent,
        LogsEDRTSComponent,
        LogsExportEventQueueComponent,
        LogsFeedAlertsComponent,
        LogsFiltersComponent,
        LogsImportExportComponent,
        LogsInfoModalComponent,
        LogsPageComponent,
        LogsTransformerComponent
    ],
    imports: [
        AgGridModule,
        CommonModule,
        DaterangePickerModule,
        DirectivesModule,
        FilterChipsComponent,
        FontAwesomeModule,
        FormsModule,
        InputsModule,
        LogsRoutingModule,
        ModalsModule,
        NgbCollapseModule,
        NgbPopoverModule,
        NgSelectModule,
        PageModule,
        PaginationModule,
        ReactiveFormsModule,
        SearchBarModule,
        SelectModule
    ]
})
export class LogsModule { }
