import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BasicTransformerComponent } from '@app/modules/inputs/components/basic-transformer/basic-transformer.component';
import { CodeMirrorConstants } from '@app/modules/inputs/constants/code-mirror.constants';
import { QueryType } from '@app/modules/inputs/enums/query-type.enum';
import { CodeMirrorOptionsModel } from '@app/modules/inputs/models/code-mirror-options.model';
import { AggregatedDbFieldsType } from '@app/modules/inputs/types/aggregated-db-fields.type';
import { QueryInputsComponent } from '@app/modules/query/components/query-inputs/query-inputs.component';

@Component({
    selector: 'fdx-transformer-inputs',
    templateUrl: './transformer-inputs.component.html',
    styleUrls: ['./transformer-inputs.component.scss']
})
export class TransformerInputsComponent implements OnChanges {

    @Input() dbFields: AggregatedDbFieldsType;

    @Input() transformerString: string = '';
    @Output() readonly transformerStringChange: EventEmitter<string> = new EventEmitter<string>();

    @Input() disabled?: boolean = false;

    @Input() queryInputs: QueryInputsComponent;

    @Input() codeMirrorOptions: CodeMirrorOptionsModel = {
        ...CodeMirrorConstants.defaultOptions,
        placeholder: ''
    };

    @Input() advancedTransformerControlName?: string = 'advancedTransformerControl';
    @Input() advancedTransformerGroupName?: string = 'advancedTransformerGroup';
    @Input() basicTransformerGroupName?: string = 'basicTransformerGroup';

    // eslint-disable-next-line @typescript-eslint/typedef
    @Input() advancedTransformerForm;
    // eslint-disable-next-line @typescript-eslint/typedef
    @Input() basicTransformerForm;
    // eslint-disable-next-line @typescript-eslint/typedef
    @Input() transformerForm;

    @ViewChild(BasicTransformerComponent) basicTransformerComponent: BasicTransformerComponent;

    get queryType(): typeof QueryType {
        return QueryType;
    }

    get advancedTransformer(): UntypedFormControl {
        return this.advancedTransformerForm?.controls[this.advancedTransformerControlName];
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.disabled) {
            this.codeMirrorOptions.readOnly = this.disabled ? 'nocursor' : false;
            if (this.advancedTransformer) {
                if (this.disabled) {
                    this.advancedTransformer.disable();
                } else {
                    this.advancedTransformer.enable();
                }
            }
        }
    }

    /**
     * Toggle the QueryType mode to the opposite mode
     */
    toggleQueryMode(): void {
        if (this.queryInputs.usingBasicMode) {
            this.updateTransformerFromBasic();
        }

        this.updateControls();
    }

    /**
     * Updates the transformer string based on the basic transformer value
     */
    updateTransformerFromBasic(): void {
        this.transformerString = this.basicTransformerComponent.valuesToQuery();
        this.transformerStringChanged();
    }

    updateControls():void {
        if (this.queryInputs.usingBasicMode) {
            this.transformerForm.removeControl(this.advancedTransformerGroupName);
            this.transformerForm.addControl(this.basicTransformerGroupName, this.basicTransformerForm);
        } else {
            this.transformerForm.removeControl(this.basicTransformerGroupName);
            this.transformerForm.addControl(this.advancedTransformerGroupName, this.advancedTransformerForm);
        }
    }

    updateComponentQuery(switching: boolean = false): void {    // Only update when using Basic mode or when switching to Advanced mode
        if (this.basicTransformerComponent &&
                (this.queryInputs.usingBasicMode || switching)) {
            this.updateTransformerFromBasic();
        }
    }

    transformerStringChanged(): void {
        this.transformerStringChange.emit(this.transformerString);
    }
}
