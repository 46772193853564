export default class FdxCollapsibleCodeOldController {

    // Services
    $element = null;

    // External bindings defaults
    isCollapsed = null;
    content = '';

    constructor($element) {
      this.$element = $element;
    }

    $onChanges(changes) {
        if (changes.content) {
            if (this.isCollapsed && !this.showToggle()) this.isCollapsed = false;
        }
    }

    toggle() {
        const codeMirrorScroll = this.$element.find('div.CodeMirror-scroll');
        if (codeMirrorScroll) {
            codeMirrorScroll.scrollTop(0).scrollLeft(0);
        }
        this.isCollapsed = !this.isCollapsed;
    }

    showToggle() {
        return (this.content.match(/\n/g) || []).length >= 3;
    }

    showLowToggle() {
        return (this.content.match(/\n/g) || []).length >= 30;
    }

    getToggleText() {
        return this.isCollapsed ? 'Expand' : 'Contract';
    }

    getClass() {
        return this.isCollapsed ? 'code-collapsed' : '';
    }

    getIconClass() {
        return this.isCollapsed ? 'fa-chevron-down' : 'fa-chevron-up';
    }


}

FdxCollapsibleCodeOldController.$inject = ['$element'];