import { AccountModel } from '@ajs/models/account';
import { UserModel } from '@ajs/models/user';
import AppStateService from '@ajs/services/AppStateService';
import FdxUtils from '@ajs/services/fdxUtils';
import { Injectable } from '@angular/core';
import { EMPTY_TIMESTAMP } from '@app/core/models/constants/app-constants';
import { DbModel } from '@app/databases/models/db.model';
import { PrivacyLevel } from '@app/user/enums/privacy-level.enum';

interface AttributeMap {
    [key: string]: string | number | boolean | AttributeMap;
}

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {
    private get loggedInUser(): UserModel {
        return this.appStateService.getLoggedInUser();
    }

    private get accountId(): string {
        return this.appStateService.getAccountId() as string;
    }

    private get account(): AccountModel {
        return this.appStateService.getAccount();
    }

    private get database(): DbModel {
        return this.appStateService.getDatabase();
    }

    private get privacyLevel(): PrivacyLevel {
        return this.loggedInUser.getPrivacyLevel(this.accountId);
    }

    private get roles(): string {
        return this.loggedInUser.roles.sort().join(', ');
    }

    constructor(
        private readonly appStateService: AppStateService,
        private readonly fdxUtils: FdxUtils
    ) { }

    identify(): void {
        // pendo
        if ('pendo' in globalThis && this.loggedInUser) {
            const attributes = this.getPendoAttributes();

            if (pendo.getVisitorId() !== this.loggedInUser.user_id) {
                pendo.initialize(attributes);
            } else {
                pendo.updateOptions(attributes);
            }
        }
    }

    private getAttributes(): AttributeMap {
        const attributes: AttributeMap = {};

        if (this.loggedInUser) {
            attributes['user_user_id'] = this.loggedInUser['user_id'];
            attributes['user_email'] = this.loggedInUser['user_name'];
            attributes['user_role'] = this.privacyLevel;
            attributes['user_roles'] = this.roles;
            attributes['user_zoho_contact_id'] = this.loggedInUser['zoho_contact_id'];

            for (const feature in this.loggedInUser['features']) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                attributes[`user_feature_${feature}`] = this.loggedInUser['features'][feature] as string;
            }
        }

        if (this.account) {
            attributes['account_id'] = this.account['id'];
            attributes['account_account_name'] = this.account['account_name'];
            attributes['account_permissions'] = this.account['permissions'];

            for (const feature in this.account['features']) {
                attributes[`account_feature_${feature}`] = this.account['features'][feature];
            }
        }

        if (this.database) {
            attributes['db_id'] = this.database['id'];
            attributes['db_name'] = this.database['name'];
            attributes['db_paused'] = this.database['paused'];
            attributes['db_date_created'] = this.database['time'];
            attributes['db_date_import_last_started'] = this.database['timestamp'];
            attributes['db_data_count'] = this.database['data_count'];
            attributes['db_last_run_imports_status'] = this.database['last_run_imports_status'];
            attributes['db_created_at'] = this.database['created_at'];
            attributes['db_deleted_at'] = this.database['deleted_at'];
            attributes['db_db_group_id'] = this.database['db_group_id'];

            for (const feature in this.database['features']) {
                attributes[`db_feature_${feature}`] = this.database['features'][feature];
            }
        }

        // clean mysql date time fields to ISO
        const dateTimeAttributes = [
            'db_date_import_last_started',
            'db_date_created',
            'db_created_at',
            'db_deleted_at'
        ];

        for (const attribute in attributes) {
            if (dateTimeAttributes.includes(attribute)) {
                if (attributes[attribute] && attributes[attribute] !== EMPTY_TIMESTAMP) {
                    attributes[attribute] = this.fdxUtils.getDateObj(attributes[attribute]).toISOString();
                } else {
                    delete attributes[attribute];
                }
            }
        }

        return attributes;
    }

    private getPendoAttributes(): AttributeMap {
        const attributes = this.getAttributes();

        const customAttributes = new Set<string>([
            'user_user_id',
            'user_email',
            'user_role',
            'user_roles',
            'db_id',
            'db_name',
            'account_id',
            'account_account_name'
        ]);

        // set the custom attributes
        const pendoAttributes: AttributeMap = {
            visitor: {
                id: attributes['user_user_id'],
                email: attributes['user_email'],
                role: attributes['user_role'],
                roles: attributes['user_roles']
            },
            account: {
                id: attributes['db_id'] ?? null,
                name: attributes['db_name'] ?? null
            },
            parentAccount: {
                id: attributes['account_id'] ?? null,
                name: attributes['account_account_name'] ?? null
            }
        };

        // attach all other attributes
        const userRegex = /^user_/g;
        const dbRegex = /^db_/g;

        for (const [key, value] of Object.entries(attributes)) {
            if (customAttributes.has(key)) {
                continue;
            }

            const isUserAttribute = key.match(userRegex);
            const isDbAttribute = key.match(dbRegex);

            if (isUserAttribute) {
                pendoAttributes['visitor'][key] = value;
            } else if (isDbAttribute) {
                pendoAttributes['account'][key] = value;
            } else {
                pendoAttributes['parentAccount'][key] = value;
            }
        }

        return pendoAttributes;
    }
}
