import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExportTriggerModel } from '@app/export-triggers/models/export-trigger.model';
import { Observable } from 'rxjs';

// Requests
import { PostExportTriggerRequest } from '@app/exports/services/requests/post-export-trigger.request';

@Injectable({
    providedIn: 'root'
})
export class ExportTriggersDataService {
    constructor(
        private http: HttpClient
    ) { }

    public getExportTriggers(databaseId: string, exportId: string): Observable<ExportTriggerModel[]> {
        return this.http.get<ExportTriggerModel[]>(`/api.php/dbs/${databaseId}/exports/${exportId}/export_triggers`);
    }

    public postExportTrigger(databaseId: string, exportId: string, params: PostExportTriggerRequest): Observable<ExportTriggerModel> {
        return this.http.post<ExportTriggerModel>(`/api.php/dbs/${databaseId}/exports/${exportId}/export_triggers`, params);
    }

    public putExportTrigger(databaseId: string, exportId: string, exportTriggerId: string, params: PostExportTriggerRequest): Observable<ExportTriggerModel> {
        return this.http.put<ExportTriggerModel>(`/api.php/dbs/${databaseId}/exports/${exportId}/export_triggers/${exportTriggerId}`, params);
    }

    public getExportTrigger(databaseId: string, exportId: string, exportTriggerId: string): Observable<ExportTriggerModel> {
        return this.http.get<ExportTriggerModel>(`/api.php/dbs/${databaseId}/exports/${exportId}/export_triggers/${exportTriggerId}`);
    }

    public deleteExportTrigger(databaseId: string, exportId: string, exportTriggerId: string): Observable<void> {
        return this.http.delete<void>(`/api.php/dbs/${databaseId}/exports/${exportId}/export_triggers/${exportTriggerId}`);
    }
}
