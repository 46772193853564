import { PrivacyLevel } from '@app/user/enums/privacy-level.enum';
import { UserSettings } from '@app/user/models/user-settings.model';

export enum Grant {
    FeedAlerts = 'PlatformApp:FeedAlerts',
    FeedAMP = 'FeedAMP:Application',
    FeedStatus = 'PlatformApp:FeedStatus',
    FeedSupport = 'PlatformApp:FeedSupport',
    ManageSSO = 'Manage SSO',
    ManageRoles = 'Manage Roles',
}

type AccountGrant = {
    [key in Grant]?: boolean;
}

export interface UserDataModel {
    account_grants: Record<string, AccountGrant>;
    account_privacy_levels: Record<string, PrivacyLevel>;
    country: string;
    features?: Record<string, string>; // TODO define all feature key-value pairs
    first_name: string;
    last_name: string;
    logo: string;
    last_password_reset?: string; // e.g. 2021-08-10 22:41:56
    phone: string;
    roles: Array<string>;
    settings?: UserSettings;
    user_fetched?: boolean; // TODO might not be part of api response but added on client-side
    user_id: string;
    user_name: string;
    zoho_contact_id: string;
    impersonated_user_name: string;
    impersonated_user_id: string;
}
