import { Component } from '@angular/core';
import { prettyJSON } from '@app/core/functions/prettyJSON';
import { BootstrapThemeProp } from '@app/core/services/bootstrap-theme.service';
import { Modal } from '@app/modules/modals/modals.module';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface LogsInfoResolverInterface {
    raw: string
}

@Component({
  selector: 'fdx-logs-info-modal',
  templateUrl: './logs-info-modal.component.html',
  styleUrls: ['./logs-info-modal.component.scss']
})
export class LogsInfoModalComponent implements Modal {
    public modalTheme: BootstrapThemeProp = 'primary';
    public title: string = 'Raw log response';

    info: string;

    constructor(
        private readonly modal: NgbActiveModal
    ) { }

    public onModalInit(resolve: LogsInfoResolverInterface): void {
        if (resolve.raw) {
            this.info = prettyJSON(resolve.raw);
        }
    }

    public onModalSubmit(_event: MouseEvent): void {
        this.modal.close();
    }

    public onModalCancel(_event: MouseEvent): void {
        this.modal.dismiss();
    }
}
