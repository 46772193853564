import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ReleaseNoteDataModel } from '@app/release-notes/models/release-note-data.model';
import { LatestReleaseNoteDataModel } from '@app/release-notes/models/latest-release-note-data.model';

@Injectable({
    providedIn: 'root'
})
export default class ReleaseNotesDataService {
    constructor(private http: HttpClient) { }

    public getReleaseNotes(): Observable<ReleaseNoteDataModel[]> {
        return this.http.get<Array<ReleaseNoteDataModel>>('/api.php/release_notes');
    }

    public getLatestReleaseNoteDate(): Observable<LatestReleaseNoteDataModel> {
        return this.http.get<LatestReleaseNoteDataModel>('/api.php/release_notes/latest');
    }
}
