import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AlertComponent } from '@app/modules/alerts/components/alert/alert.component';
import { DirectivesModule } from '@app/modules/directives/directives.module';
import { PipesModule } from '@app/modules/pipes/pipes.module';
import { SpinnerComponent } from '@app/standalone-components/spinner/spinner.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [
        AlertComponent
    ],
    imports: [
        CommonModule,
        DirectivesModule,
        FontAwesomeModule,
        NgbAlertModule,
        PipesModule,
        SpinnerComponent
    ],
    exports: [
        AlertComponent
    ]
})
export class AlertsModule { }
