<main class="p-4">
    <div class="d-flex align-items-center gap-3 mb-3">
        <fdx-page-title [beta]="true">{{ title }}</fdx-page-title>
        <form [formGroup]="logTypeForm">
            <fdx-form-field
                formControlName="logType"
                [classes]="{ 'log-type-dropdown': true }">
                <fdx-input-wrapper
                    labelText="Select log type">
                    <ng-select
                        formControlName="logType"
                        id="logType"
                        [items]="logTypes"
                        bindLabel="display"
                        [clearable]="false">
                    </ng-select>
                </fdx-input-wrapper>
            </fdx-form-field>
        </form>
    </div>

    <router-outlet />

</main>
