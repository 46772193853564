export default class DatabaseGroupsDataService {

  // Services
  $http = null;
  $q = null;

  constructor($http, $q) {
    this.$http = $http;
    this.$q = $q;
  }

  getAll(idAccount) {

    const def = this.$q.defer();

    const promise = this.$http.get('/api.php/accounts/' + idAccount + '/db_groups');

    promise.then(
      (response) => {
        def.resolve(response.data);
      },
      (response) => {
        def.reject(response.data);
      }
    );

    return def.promise;

  }

  create(idAccount, data) {

    const def = this.$q.defer();

    const promise = this.$http.post('/api.php/accounts/' + idAccount + '/db_groups', data);

    promise.then(
      (response) => {
        def.resolve(response.data);
      },
      (response) => {
        def.reject(response.data);
      }
    );

    return def.promise;

  }

  update(idGroup, data) {

    const def = this.$q.defer();

    const promise = this.$http.put('/api.php/db_groups/' + idGroup, data);

    promise.then(
      (response) => {
        def.resolve(response.data);
      },
      (response) => {
        def.reject(response.data);
      }
    );

    return def.promise;

  }

  delete(idGroup) {

    const def = this.$q.defer();

    const promise = this.$http.delete('/api.php/db_groups/' + idGroup);

    promise.then(
      (response) => {
        def.resolve(response.data);
      },
      (response) => {
        def.reject(response.data);
      }
    );

    return def.promise;

  }

}

DatabaseGroupsDataService.$inject = ['$http', '$q'];