<div class="container">
    <fdx-page-title>Attribute Data</fdx-page-title>
    <fdx-well>
        <form [formGroup]="form">
            <div class="row form-group">
                <label class="col-md-2 col-form-label text-end">Title</label>
                <div class="col-md-10">
                    <input type="text" class="form-control" formControlName="title" />
                </div>
            </div>
            <div class="row form-group">
                <label class="col-md-2 col-form-label text-end">Description</label>
                <div class="col-md-10">
                    <textarea class="form-control" formControlName="description"></textarea>
                </div>
            </div>
            <div class="row form-group">
                <label class="col-md-2 col-form-label text-end">GTIN</label>
                <div class="col-md-10">
                    <input type="text" class="form-control" formControlName="gtin" />
                </div>
            </div>
            <div class="row form-group">
                <label class="col-md-2 col-form-label text-end">Google Product Category</label>
                <div class="col-md-10">
                    <input type="text" class="form-control" formControlName="googleProductCategory" />
                </div>
            </div>
            <div class="row form-group">
                <label class="col-md-2 col-form-label text-end">Attributes
                    <br />
                    <small class="font-weight-normal text-muted">Empty will return all available attributes</small>
                </label>
                <div class="col-md-10">
                    <ng-select [multiple]="true"
                            formControlName="attributes"
                            [items]="attributes"
                            placeholder="Select / search for an attribute">
                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                            <fdx-select-multiple-label-template [item]="item" [clear]="clear">{{ formatFieldName(item) }}</fdx-select-multiple-label-template>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
            <div class="row text-end">
                <div class="col">
                    <button type="button" class="btn btn-outline-primary w-auto" (click)="attribute()">
                        <fa-icon [icon]="iconPlay"></fa-icon>Attribute Data
                    </button>
                </div>
            </div>
        </form>

    </fdx-well>

    <fdx-well *ngIf="results.length" class="mt-5">
        <fdx-well-header>Results</fdx-well-header>

        <table class="table mb-0">
            <thead>
            <tr>
                <th>Attribute</th>
                <th>Raw Results</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let result of results">
                <td>{{ result.key }}</td>
                <td><code>{{ result.values | json }}</code></td>
            </tr>
            </tbody>
        </table>
    </fdx-well>
</div>
