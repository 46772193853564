import { IHttpService } from 'angular';

import { PREPROCESS_URL } from '@app/core/models/constants/app-constants';

interface ResolverInterface {
    current_shopify_import: any
}

export default class FdxImportsShopifyInstructionsModalController {

    // from uibmodal
    private close: (result?: { $value }) => void;
    private dismiss: (result?: { $value: string }) => void;
    private resolve: ResolverInterface;

    // External bindings defaults
    current_shopify_import: any = {};

    // internals
    current_shopify_listing_status: string = '';
    shopify_info_listing: any = [];
    preprocess_info: any = {};

    // to be returned on fetch fail
    error = '';

    static $inject = ['$http'];

    constructor(private $http: IHttpService) { }

    $onInit(): void {
        this.current_shopify_import = this.resolve.current_shopify_import;
        this.preprocess_info = this.current_shopify_import.importData.import_info.preprocess_info;

        const params = {
            'url': PREPROCESS_URL,
            'preprocess_info': this.preprocess_info
        };

        this.$http.post('/api.php/get_api_info', params)
            .then(
                response => {
                    this.current_shopify_listing_status = '';
                    this.shopify_info_listing = response.data;
                },
                response => {
                    this.current_shopify_listing_status = response.data.display_message;
                    this.error = response.data.display_message;
                }
            );
    }

    public onCancel(): void {
        this.dismiss();
    }
}
