import { Injectable } from '@angular/core';
import { arrayEquals } from '@app/core/functions/arrayEquals';
import { API_DATE_TIME_FORMAT } from '@app/core/models/constants/date-time';
import { ScheduleHoursToDisplay } from '@app/core/models/interfaces/schedule-hours-to-display.interface';
import { ScheduleInterface } from '@app/core/models/interfaces/schedule.interface';
import { LogService } from '@app/core/services/log.service';
import { CronDisplayInterface } from '@app/modules/cron/models/interfaces/cron-display.interface';
import { CronInterface } from '@app/modules/cron/models/interfaces/cron.interface';
import { ScheduleType } from '@app/modules/cron/models/types/schedule.type';
import { TimeZoneService } from '@app/modules/timezone/services/time-zone.service';
import { CronDate, CronExpression, parseExpression } from 'cron-parser';

import * as dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

@Injectable({
    providedIn: 'root'
})
export class CronService {

    private readonly everyDay: number[] = [];
    private readonly everyHour: number[] = [];
    private readonly every15Minutes: number[] = [0, 15, 30, 45];

    public schedules: ScheduleType = {
        'days': [
            {display_name: 'Unscheduled', value: '-1'},
            {display_name: 'Every Day', value: '*'},
            {display_name: 'Sunday', value: '0'},
            {display_name: 'Monday', value: '1'},
            {display_name: 'Tuesday', value: '2'},
            {display_name: 'Wednesday', value: '3'},
            {display_name: 'Thursday', value: '4'},
            {display_name: 'Friday', value: '5'},
            {display_name: 'Saturday', value: '6'}
        ],
        'minutes': [
            {display_name: 'Unscheduled', value: '-1'},
            {display_name: '00', value: '0'},
            {display_name: '15', value: '15'},
            {display_name: '30', value: '30'},
            {display_name: '45', value: '45'}
        ],
        'hours': [
            {display_name: 'Unscheduled', value: '-1'},
            {display_name: 'Every Hour', value: '*'},
            {display_name: '12 AM', value: '0'},
            {display_name: '1 AM', value: '1'},
            {display_name: '2 AM', value: '2'},
            {display_name: '3 AM', value: '3'},
            {display_name: '4 AM', value: '4'},
            {display_name: '5 AM', value: '5'},
            {display_name: '6 AM', value: '6'},
            {display_name: '7 AM', value: '7'},
            {display_name: '8 AM', value: '8'},
            {display_name: '9 AM', value: '9'},
            {display_name: '10 AM', value: '10'},
            {display_name: '11 AM', value: '11'},
            {display_name: '12 PM', value: '12'},
            {display_name: '1 PM', value: '13'},
            {display_name: '2 PM', value: '14'},
            {display_name: '3 PM', value: '15'},
            {display_name: '4 PM', value: '16'},
            {display_name: '5 PM', value: '17'},
            {display_name: '6 PM', value: '18'},
            {display_name: '7 PM', value: '19'},
            {display_name: '8 PM', value: '20'},
            {display_name: '9 PM', value: '21'},
            {display_name: '10 PM', value: '22'},
            {display_name: '11 PM', value: '23'}
        ]
    }

    constructor(
        private readonly logService: LogService,
        private readonly timeZoneService: TimeZoneService
    ) {

        for (let i = 0; i < 24; i++) {
            this.everyHour.push(i);
        }

        for (let i = 0; i < 8; i++) {
            this.everyDay.push(i);
        }

    }

    getCronDataToDisplay(cron: string, cronTimezone: string): CronDisplayInterface {

        if (!cron) {
            return null;
        }

        const interval = this.getCronInterval(cron, cronTimezone);

        const result: CronDisplayInterface = {
            interval: interval,
            api: {
                day: this.getDays(this.getCronExpressionWithEndDate(cron, cronTimezone, dayjs().add(7, 'day').toDate())),
                hour: this.getHours(interval),
                minute: this.getMinutes(interval)
            },
            user: {
                minute: []
            }
        };

        if (arrayEquals(Array.from(interval.fields.hour), this.everyHour)) {
            result.user.minute = this.getMinutesUser(interval, cronTimezone);
        }

        return result;

    }

    getCronInterval(cron: string, cronTimezone: string): CronExpression {
        return parseExpression(cron, {tz: cronTimezone});
    }

    private getCronExpressionWithEndDate(cron: string, cronTimezone: string, endDate: Date): CronExpression<true> {
        return parseExpression(cron, {tz: cronTimezone, startDate: new Date(), endDate, iterator: true });
    }

    private getDays(interval: CronExpression<true>): string[] {
        if (arrayEquals(Array.from(interval.fields.dayOfWeek), this.everyDay)) {
            return ['*'];
        }

        if (interval.fields.dayOfWeek) {
            // Get any days of week according to the supplied timezone, and then
            // show those days instead of the days in the cron's original timezone.
            // This specifically requires a cron expression with an endDate.
            let nextDate: IteratorResult<CronDate, CronDate>;
            const daysOfWeek: Set<number> = new Set();
            // eslint-disable-next-line no-constant-condition
            while(true) {
                try {
                    nextDate = interval.next();
                    daysOfWeek.add(dayjs.tz(nextDate.value.toDate()).tz(dayjs.tz.guess()).day());
                } catch (e: unknown) {
                    break;
                }
            }

            const daysOfWeekArr = Array.from(daysOfWeek);

            return this.schedules.days.filter((day) => {
                return daysOfWeekArr.some((weekDay) => {
                    return weekDay.toString() === day.value;
                });
            }).map((day) => day.display_name);

        }

        return [];

    }

    private getHours(interval: CronExpression): string[] {

        if (interval.fields.hour) {

            if (arrayEquals(Array.from(interval.fields.hour), this.everyHour)) {
                return ['*'];
            }

            return interval.fields.hour.map(this.addZeroPadding);

        }

        return [];

    }

    private getMinutes(interval: CronExpression): string[] {

        if (!interval.fields.minute) {
            return [];
        }

        if (arrayEquals(Array.from(interval.fields.minute), this.every15Minutes)) {
            return ['*'];
        }

        return interval.fields.minute.map(this.addZeroPadding);

    }

    private getMinutesUser(interval: CronExpression, cronTimezone: string): string[] {

        return interval.fields.minute.map((minute) => {
            const minuteAsString = this.addZeroPadding(minute);
            const dateAPI = dayjs.tz(minuteAsString, 'mm', cronTimezone);
            const dateUser = dayjs.tz(dateAPI).tz(dayjs.tz.guess()); // to the guessed user timezone
            return dateUser.format('mm');
        });

    }

    getCronDataAsString(cronData: CronInterface): string {
        return `${cronData.minute.join(',')} ${cronData.hour.join(',')} * * ${cronData.day.join(',')}`;
    }

    decodeCronData(cron: string): CronInterface {

        if (cron === null) {
            return {
                day: null,
                hour: null,
                minute: null
            }
        }

        const cronComponents = cron.split(' ');

        if (cronComponents.length < 5) {
            return null;
        }

        return {
            day: cronComponents[4].split(',').filter((day: string) => day !== '-1'),
            hour: cronComponents[1].split(',').filter((hour: string) => hour !== '-1'),
            minute: cronComponents[0].split(',').filter((minute: string) => minute !== '-1')
        }

    }

    private combineHoursMinutes(cronData: CronInterface): string[] {

        const result: string[] = [];

        for (const hour of cronData.hour) {
            for (const minute of cronData.minute) {
                result.push(`${hour}:${minute}`);
            }
        }

        return result;

    }

    getScheduledDays(cron: string, cronTimezone: string): string {

        const cronData = this.getCronDataToDisplay(cron, cronTimezone);

        if (!cronData) {
            return '-';
        }

        const isEveryDay = arrayEquals(cronData.api.day, ['*']);
        const isEveryHour = arrayEquals(cronData.api.hour, ['*']);
        const isEvery15Minutes = arrayEquals(cronData.api.minute, ['*']);
        const atTheHourAPI: boolean = arrayEquals(cronData.api.minute, ['00']);
        const atTheHourUser: boolean = arrayEquals(cronData.user.minute, ['00']);

        if (isEveryDay) {

            if (isEveryHour) {

                if (atTheHourUser || atTheHourAPI) {
                    return ScheduleInterface.EVERY_HOUR_AT_THE_HOUR;
                }

                if (isEvery15Minutes) {
                    return ScheduleInterface.EVERY_15_MINUTES;
                }

                return ScheduleInterface.EVERY_HOUR_AT_THE_MINUTE.replace('{minutes}', cronData.api.minute.join(`${cronData.api.minute.length === 2 ? ' &' : ','} `));

            }

            return ScheduleInterface.EVERY_DAY;

        }

        return cronData.api.day.join(', ');

    }

    private getHoursAndMinutes(cron: string): string[] {
        return this.combineHoursMinutes(this.decodeCronData(cron));
    }

    getScheduleHoursToDisplay(cron: string, cronTimezone: string): ScheduleHoursToDisplay[] {

        const hours = this.getHoursAndMinutes(cron);

        const hoursToDisplay: ScheduleHoursToDisplay[] = [];

        for (const hour of hours) {

            try {

                if (hour.startsWith('*')) { // Every hour at the *:XX minute
                    continue;
                }

                const apiTime = dayjs.tz(hour, 'HH:mm', cronTimezone);
                const userTime = dayjs.tz(apiTime).tz(dayjs.tz.guess()); // to the guessed user timezone

                hoursToDisplay.push({
                    source: hour,
                    userTime: {
                        abbreviatedTimeZoneName: this.timeZoneService.getAbbreviatedTimeZoneName(userTime),
                        dayAfter: userTime.day() > apiTime.day(),
                        dayBefore: userTime.day() < apiTime.day(),
                        timeToDisplay: userTime.format('h:mm A'),
                        time: userTime
                    },
                    apiTime: {
                        abbreviatedTimeZoneName: this.timeZoneService.getAbbreviatedTimeZoneName(apiTime),
                        dayAfter: apiTime.day() > userTime.day(),
                        dayBefore: apiTime.day() < userTime.day(),
                        timeToDisplay: apiTime.format('h:mm A'),
                        time: apiTime
                    }
                });

            } catch (e) {
                this.logService.error('Invalid time: ', hour, e);
            }


        }

        return hoursToDisplay;

    }

    /**
     * Returns the next date an object with this cron would be scheduled.
     * @param {string} cron The cron
     * @param {string} cronTimezone A timezone to adjust the cron {i.e. 'America/New York'}
     * @returns {string} A date time string formatted as per API_DATE_TIME_FORMAT
     */
    getNextTimeFromCron(cron: string, cronTimezone: string): string {
        if (!cron) {
            return '';
        }
        return dayjs(this.getCronInterval(cron, cronTimezone).next().toDate()).format(API_DATE_TIME_FORMAT).toString();
    }

    private addZeroPadding(value: number): string {
        if (value < 10) {
            return '0' + value.toString();
        }
        return value.toString();
    }

}
