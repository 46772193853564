import { isEmpty } from '@app/core/functions/isEmpty';

export default class FdxToggleController {

    // external services
    $timeout = null;

    // External bindings defaults
    ngModel = null;
    ngChange = null;
    primaryColor = '';
    useTransitions = true;

    get themeClass() {
        if (!isEmpty(this.primaryColor)) {
            return `fdx-toggle--${this.primaryColor}`;
        }

        return '';
    }

    constructor($timeout) {
        this.$timeout = $timeout;
    }

    onChangeHandler() {

        if (angular.isFunction(this.ngChange)) {
            this.$timeout(() => {
                this.ngChange({});
            });
        }

    }
}

FdxToggleController.$inject = ['$timeout'];
