import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { isEmpty } from '@app/core/functions/isEmpty';
import { DISPLAY_DATE_FORMAT, DISPLAY_TIME_FORMAT } from '@app/core/models/constants/date-time';
import { DateTimeService } from '@app/core/services/date-time.service';
import { FEED_ALERTS_SERVABILITY_MAP } from '@app/logs/constants/logs.constants';
import { IFeedAlertsLogAccountStatusProduct, IFeedAlertsLogErrorIssues, IFeedAlertsLogRawResponse } from '@app/logs/models/interfaces/log-feed-alerts.interfaces';
import { ChangeLog } from '@app/logs/models/types/log-change.types';
import { EDRTSLog } from '@app/logs/models/types/log-edrts.types';
import { ExportEventQueueLog } from '@app/logs/models/types/log-export-event-queue.types';
import { FeedAlertsLog, FeedAlertsLogPayloadJSON } from '@app/logs/models/types/log-feed-alerts.types';
import { ImportExportLog } from '@app/logs/models/types/log-import-export.types';
import { TransformerLog } from '@app/logs/models/types/log-transformer.types';
import { IChangeLog, IEDRTSLog, IExportEventQueueLog, IFeedAlertsLog, IImportExportLog, ITransformerLog } from '@app/logs/services/responses/logs-responses.types';
import { TransformerModel } from '@app/transformers/models/transformer.model';
import { Item } from '@feedonomics/frontend-components';

@Injectable({
    providedIn: 'root'
})
export class LogsDataFormatterService {

    constructor(
        private readonly dateTimeService: DateTimeService,
        private readonly decimalPipe: DecimalPipe
    ) {}

    formatImportExportStructure(_raw: IImportExportLog[]): ImportExportLog[] {
        return null;
    }

    /**
     * Formats the transformer logs' "logs" API response values into the proper format for the AG Grid.
     * @param raw an array of the raw log type
     * @returns an object containing the formatted logs array, plus arrays of unique actions, field names,
     * and users, all formatted to appear as an Item in the fsc-multiselect component
     */
    formatTransformerStructure(raw: ITransformerLog[]): {
        logs: TransformerLog[],
        actions: Item[],
        fieldNames: Item[],
        users: Item[]
    } {
        const actions = new Set<string>();
        const fieldNames = new Set<string>();
        const users = new Set<string>();

        const logs = raw.map<TransformerLog>((rawLog: ITransformerLog) => {
            let responseBody: TransformerModel;
            let priorState: TransformerModel;

            try {
                responseBody = JSON.parse(rawLog.response_body);
            } catch (e) {
                responseBody = null;
            }

            try {
                priorState = JSON.parse(rawLog.prior_state);
            } catch (e) {
                priorState = null;
            }

            const action = rawLog.action;
            actions.add(action);

            const user = rawLog.api_user_name;
            users.add(user);

            const fieldName = priorState?.field_name ?  priorState.field_name : responseBody.field_name;
            fieldNames.add(fieldName);

            // Check if priorState has resolved to be a TransformerModel. If not, pass the original raw response string.
            const hasOldData = priorState?.selector
                || priorState?.transformer
                || priorState?.sort_order
                || priorState?.enabled;
            const oldData = action === 'create' || hasOldData ? priorState : rawLog.response_body;

            // Check if responseBody has resolved to be a TransformerModel. If not, pass the original raw response string.
            const hasNewData = responseBody?.selector
                || responseBody?.transformer
                || responseBody?.sort_order
                || responseBody?.enabled;
            const newData = action === 'delete' || hasNewData ? responseBody : rawLog.response_body;

            return {
                date: this.dateTimeService.getFormattedTime(rawLog.date, DISPLAY_DATE_FORMAT),
                time: this.dateTimeService.getFormattedTime(rawLog.date, DISPLAY_TIME_FORMAT),
                user: user,
                action: action,
                exportName: rawLog.export_name ? rawLog.export_name : 'All',
                fieldName: fieldName,
                old: oldData,
                new: newData
            }
        });

        return {
            logs,
            actions: this.formatItem(Array.from(actions)),
            fieldNames: this.formatItem(Array.from(fieldNames)),
            users: this.formatItem(Array.from(users)),
        }
    }

    /**
     * Formats the FeedAlerts/Google Merchant Center logs' "logs" API response values into the proper format
     * for the AG Grid.
     * @param raw an array of the raw log type
     * @returns an object containing the formatted logs array
     */
    formatFeedAlertsStructure(raw: IFeedAlertsLog[]): FeedAlertsLog[] {
        return raw.map<FeedAlertsLog>((rawLog: IFeedAlertsLog) => {
            let payloadJSON: FeedAlertsLogPayloadJSON;
            try {
                payloadJSON = JSON.parse(rawLog.payload_json);
            } catch (e) {
                payloadJSON = null;
            }

            let rawResponse: IFeedAlertsLogRawResponse;
            try {
                rawResponse = JSON.parse(rawLog.raw_response);
            } catch (e) {
                rawResponse = null;
            }

            let exceptionJSON: string;
            if ('error' in payloadJSON) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                exceptionJSON = (payloadJSON as any).error?.errors;
                delete payloadJSON.error;
            }

            const product: IFeedAlertsLogAccountStatusProduct =
                rawResponse?.account_status?.products?.length === 1 && typeof payloadJSON === 'object' ?
                rawResponse.account_status.products[0] : null;

            if (!product) {
                return {
                    date: this.dateTimeService.getFormattedTime(rawLog.time, DISPLAY_DATE_FORMAT),
                    time: this.dateTimeService.getFormattedTime(rawLog.time, DISPLAY_TIME_FORMAT),
                    status: null,
                    channel: null,
                    totalProducts: null,
                    totalErrors: null,
                    errorRate: null,
                    exceptionJSON: exceptionJSON,
                    detail: []
                } satisfies FeedAlertsLog;
            }

            const feedErrors = this.getFeedErrors(product);
            const feedSize = this.getFeedSize(product);
            const feedKey = `${product.channel}-${product.country}-${product.destination}`;

            let status: 'Passed' | 'Failed' = 'Passed';
            if (feedKey in payloadJSON) {
                // Loop through statuses to see if there are any failures
                Object.values(payloadJSON[feedKey]).some((errorObject) => {
                    if (errorObject.status === 'fail') {
                        status = 'Failed';
                        return true;
                    }
                    return false;
                });
            }

            let errorRate = 'N/A';
            if (feedSize !== 0) {
                errorRate = this.decimalPipe.transform(feedErrors / feedSize  * 100, '1.1-1');
            }

            return {
                date: this.dateTimeService.getFormattedTime(rawLog.time, DISPLAY_DATE_FORMAT),
                time: this.dateTimeService.getFormattedTime(rawLog.time, DISPLAY_TIME_FORMAT),
                status: status,
                channel: `${product.channel}-${product.destination} (${product.country})`,
                totalProducts: feedSize,
                totalErrors: feedErrors,
                errorRate: errorRate,
                exceptionJSON: exceptionJSON,
                detail: product.itemLevelIssues.sort(this.comparatorFeedAlertsErrors)
            } satisfies FeedAlertsLog;
        });
    }

    formatChangeStructure(_raw: IChangeLog[]): ChangeLog[] {
        return null;
    }

    formatExportEventQueueStructure(_raw: IExportEventQueueLog[]): ExportEventQueueLog[] {
        return null;
    }

    formatEDRTSStructure(_raw: IEDRTSLog[]): EDRTSLog[] {
        return null;
    }

    /**
     * Maps a string array's values into an array of Items, which get displayed in an fsc-multiselect.
     * Also sorts and fitlers the items based on their string value.
     * @param arr the array of string values
     * @returns an array of Items with the string value added as both the "value" and "label" attributes of
     * that Item.
     */
    private formatItem(arr: string[]): Item[] {
        return arr.sort().filter((value) => !isEmpty(value)).map<Item>((value) => {
            return {
                value: value,
                label: value
            }
        })
    }

    /**
     * Gets the number of FeedAlerts errors
     * @param feed the Feed Alerts log account status product object
     * @returns the number of errors
     */
    private getFeedErrors(feed: IFeedAlertsLogAccountStatusProduct): number {
        if (!feed.statistics) {
            return 0;
        }

        return parseInt(feed.statistics.disapproved, 10) || 0;
    }

    /**
     * Gets the total feed size for the FeedAlerts log
     * @param feed the Feed Alerts log account status product object
     * @returns the feed size
     */
    private getFeedSize(feed: IFeedAlertsLogAccountStatusProduct): number {
        if (!feed.statistics) {
            feed.statistics = {};
        }

        return parseInt(feed.statistics.active, 10) || 0
            + parseInt(feed.statistics.disapproved, 10) || 0
            + parseInt(feed.statistics.expiring, 10) || 0
            + parseInt(feed.statistics.pending, 10) || 0;
    }

    /**
     * Comparator for the FeedAlerts errors, prioritizing unaffected over demoted over disapproved
     * @param a the first FeedAlerts log error issues value
     * @param b the second FeedAlerts log error issues value
     * @returns the comparator number
     */
    private comparatorFeedAlertsErrors(a: IFeedAlertsLogErrorIssues, b: IFeedAlertsLogErrorIssues): number {
        const servability = FEED_ALERTS_SERVABILITY_MAP[a.servability] - FEED_ALERTS_SERVABILITY_MAP[b.servability];
        return servability || parseInt(b.numItems, 10) - parseInt(a.numItems, 10);
    }
}
