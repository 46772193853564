import * as angular from 'angular';
import FdxFileMapsAddRemovePrefixSuffixModalController from './fdx-file-maps-add-remove-prefix-suffix-modal.controller';
import { MODULE_NAME } from '../../../../config/constants';

import './fdx-file-maps-add-remove-prefix-suffix-modal.styles.scss';

export const componentName = 'fdxFileMapsAddRemovePrefixSuffixModal';

angular.module(MODULE_NAME).component(componentName, {
	template: require('./fdx-file-maps-add-remove-prefix-suffix-modal.template.html'),
	controller: FdxFileMapsAddRemovePrefixSuffixModalController,
	bindings: {
		// from uibmodal
		close: '&',
		dismiss: '&',
		resolve: '<?'
	}
});
