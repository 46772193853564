<div class="d-flex gap-2">
    <div class="border rounded p-3 flex-grow-1 fdx-basic-query-row-wrapper" [formGroup]="innerGroup" [ngStyle]="{'width': '0'}">
        <div *ngFor="let rowGroup of innerGroup.controls | keyvalue; let last = last">
            <fdx-basic-query-row
                [dbFields]="dbFields"
                [inner]="inner"
                [row]="rowGroup.key"
                [rowGroup]="controlAsFormGroup(rowGroup.value)"
                (removeRowClick)="removeRowClicked(rowGroup.key)"
                [disabled]="disabled" >
            </fdx-basic-query-row>
            <div *ngIf="!last; else isLast" class="my-1 text-primary fdx-grouping-label-or-button">AND</div>
            <ng-template #isLast>
                <div class="position-relative">
                    <button
                        type="button"
                        class="btn btn-outline-primary mt-1 fdx-grouping-label-or-button connect-up"
                        (click)="addNewRowGroup()"
                        [disabled]="disabled" >
                        <fa-icon [icon]="iconPlus"></fa-icon>AND
                    </button>
                </div>
            </ng-template>
        </div>
    </div>
    <button
        type="button"
        class="btn btn-link text-secondary align-self-start"
        *ngIf="showRemoveInner"
        [disabled]="disabled"
        (click)="removeInner()">
        <fa-icon
            [icon]="xIcon"
            size="lg"
            class="no-margin" >
        </fa-icon>
    </button>
</div>
