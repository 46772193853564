/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, TemplateRef } from '@angular/core';
import { FormControl, FormGroupDirective } from '@angular/forms';
import { isEmpty } from '@app/core/functions/isEmpty';
import { LogService } from '@app/core/services/log.service';
import { FormFieldComponent } from '@app/modules/inputs/components/form-field/form-field.component';
import { InputTypes } from '@app/modules/inputs/enums/input-types.enum';
import { InputsUtilitiesService } from '@app/modules/inputs/services/inputs-utilities.service';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

@Component({
    selector: 'fdx-base-input',
    template: ''
})
export abstract class BaseInputComponent implements AfterViewInit, OnDestroy {
    protected formField: FormFieldComponent;
    protected form: FormGroupDirective;     // Reference to the #form="ngForm" FormGroupDirective of this form, used for validation
    protected controlName: string;          // name of the control passed in from form-field.component
    public ariaLabel: boolean = true;       // form-field.component will set to true if no fdx-label was included

    protected initClasses: Record<string, boolean> = {};    // The classes which the form control initializes with

    @Input() inputType: InputTypes | string = 'text';

    @Input() labelText: string;             // the corresponding label's text (required if no fdx-label used as sibling)
    @Input() title?: string;                // https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/title
    @Input() placeholder?: string;
    @Input() readonly?: boolean = false;    // https://getbootstrap.com/docs/5.0/forms/form-control/#readonly
    @Input() autocomplete?: boolean = true; // https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
    @Input() inputmode?: string;            // https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inputmode

    /** Number, date, and similar type inputs */
    @Input() step?: number;                 // Doesn't seem to work on datetime-local or time inputs
    @Input() min?: string;                  // Minimum value, used for number, range, date, month, week, time, and datetime-local inputs
    @Input() max?: string;                  // Maximum value, used for number, range, date, month, week, time, and datetime-local inputs

    /** classes handles the following cases:
     * https://getbootstrap.com/docs/5.0/forms/form-control/#sizing
     * https://getbootstrap.com/docs/5.0/forms/form-control/#readonly-plain-text
     * https://getbootstrap.com/docs/5.0/forms/select/#sizing
     * https://getbootstrap.com/docs/5.0/forms/checks-radios/#toggle-buttons and https://getbootstrap.com/docs/5.0/forms/checks-radios/#radio-toggle-buttons (will remove .form-check-input if 'btn-check' is included)
    */
    @Input() classes?: Record<string, boolean> = {};

    @Input() styles?: Record<string, any> = {};

    /** Set to true to use https://getbootstrap.com/docs/5.0/forms/validation/#tooltips instead of invalid-feedback */
    @Input() tooltipValidation?: boolean = false;

    @Input() customValidationText?: string;  // Custom error string for when validation is not met (will overwrite defaults)

    @Input() emitDelay?: number = 0;

    /** Input Group variables */
    @Input() inputGroupLeftAddons?: TemplateRef<any> | TemplateRef<any>[];
    @Input() inputGroupRightAddons?: TemplateRef<any> | TemplateRef<any>[];
    /** Input Group variables */

    control: FormControl = new FormControl(null);
    value: string;
    disabled: boolean = false;

    readonly unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        protected readonly element: ElementRef<HTMLElement>,
        protected readonly inputsUtilitiesService: InputsUtilitiesService,
        protected readonly logService: LogService,
        protected readonly ngbDateParserFormatter: NgbDateParserFormatter
    ) {}

    attachFormField(formField: FormFieldComponent): void {
        this.formField = formField;
        this.form = this.formField.controlContainer;
        this.controlName = this.formField.formControlName;
        this.control = this.formField.control;
    }

    ngAfterViewInit(): void {
        this.baseValidationConfiguration();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    setValue(value: string | null): void {
        this.updateHasValueClass(value);
        this.value = value;
    }

    onChange($event: string): void {
        this.updateHasValueClass($event);
        this.formField.handleChange($event);
    }

    onTouched(): void {
        this.formField.handleTouched();
    }

    setDisabled(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    get minValue(): string {
        if (this.control.errors?.['min'] !== undefined) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
            return this.control.errors.min.min;
        } else if (this.min !== undefined) {
            return this.min;
        }
        return null;
    }

    get maxValue(): string {
        if (this.control.errors?.['max'] !== undefined) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
            return this.control.errors.max.max;
        } else if (this.max !== undefined) {
            return this.max;
        }
        return null;
    }

    get inputClasses(): Record<string, boolean> {
        return {
            ...this.initClasses,
            ...this.classes,
            'is-invalid': this.control.invalid && (this.control.dirty || this.control.touched || this.form.submitted)
        };
    }

    get id(): string {
        return this.controlName;
    }

    get leftAddons(): TemplateRef<any>[] {
        if (this.inputGroupLeftAddons) {
            if (!Array.isArray(this.inputGroupLeftAddons)) {
                this.inputGroupLeftAddons = [this.inputGroupLeftAddons];
            }
            return this.inputGroupLeftAddons;
        }
        return null;
    }

    get rightAddons(): TemplateRef<any>[] {
        if (this.inputGroupRightAddons) {
            if (!Array.isArray(this.inputGroupRightAddons)) {
                this.inputGroupRightAddons = [this.inputGroupRightAddons];
            }
            return this.inputGroupRightAddons;
        }
        return null;
    }

    get isInputGroup(): boolean {
        return this.leftAddons?.length > 0 || this.rightAddons?.length > 0;
    }

    get ariaDescribedBy(): string {
        if ((this.leftAddons && this.rightAddons) || this.leftAddons?.length > 1 || this.rightAddons?.length > 1) {
            return null;
        } else if (this.leftAddons) {
            return `${this.id}-addon-left`;
        } else if (this.rightAddons) {
            return `${this.id}-addon-right`;
        }
        return null;
    }

    updateHasValueClass(value: string): void {
        if (isEmpty(value) && isEmpty(this.placeholder)) {
            this.element.nativeElement.classList.remove('fdx-has-value');
        } else {
            this.element.nativeElement.classList.add('fdx-has-value');  // For use with floating forms
        }
    }

    baseValidationConfiguration(): string[] {
        const errors: string[] = [];

        if (this.ariaLabel && isEmpty(this.labelText)) {
            errors.push(`No fdx-label has provided for form control: "${this.controlName}". You must add a descriptive labelText input value on this form control's input component.`);
        }

        errors.forEach((error) => {
            this.logService.error(error);
        });

        return errors;
    }
}
