import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { isEmpty } from '@app/core/functions/isEmpty';
import { CodeMirrorConstants } from '@app/modules/inputs/constants/code-mirror.constants';
import { CodeMirrorOptionsModel } from '@app/modules/inputs/models/code-mirror-options.model';
import { AggregatedDbFieldsType } from '@app/modules/inputs/types/aggregated-db-fields.type';
import { TransformerDisplayModel } from '@app/transformers/models/transformer-display.model';
import { TransformerModel } from '@app/transformers/models/transformer.model';
import { environment } from '@environments/environment';

@Component({
    selector: 'fdx-readonly-transformer',
    templateUrl: './readonly-transformer.component.html',
    styleUrls: ['./readonly-transformer.component.scss']
})
export class ReadonlyTransformerComponent implements OnChanges {

    @Input() transformer: TransformerDisplayModel | TransformerModel;
    @Input() dbFields: AggregatedDbFieldsType;
    @Input() fieldName?: string;    // Don't include fieldName to use "inline" style

    public readonlyTransformerForm: UntypedFormGroup = new UntypedFormGroup({
        selector: new UntypedFormControl(''),
        transformer: new UntypedFormControl('')
    });

    get hasFieldName(): boolean {
        return isEmpty(this.fieldName);
    }

    get thenLabel(): string {
        if (!this.hasFieldName) {
            return `Then <span class="text-primary">[${this.fieldName}]</span> =`;
        }
        return 'Then';
    }

    get formFieldClasses(): Record<string, boolean> {
        const hasFieldName = this.hasFieldName;
        return {
            'd-flex': hasFieldName,
            'gap-2': hasFieldName
        };
    }

    get formFieldStyle(): Record<string, string> {
        if (this.hasFieldName) {
            return {
                'width': '0'
            };
        }
        return {};
    }

    get inputWrapperStyle(): Record<string, string> {
        if (this.hasFieldName) {
            return {
                'min-width': '0'
            };
        }
        return {};
    }

    deactivateCodeMirror: boolean = environment.deactivateCodeMirror;

    codeMirrorOptions: CodeMirrorOptionsModel = {
        ...CodeMirrorConstants.defaultOptions,
        placeholder: '',
        readOnly: 'nocursor',
        lineWrapping: true
    };

    ngOnChanges(changes: SimpleChanges): void {
        if ('transformer' in changes) {
            this.readonlyTransformerForm.patchValue({
                selector: this.transformer ? this.transformer.selector : '',
                transformer: this.transformer ? this.transformer.transformer : ''
            });
        }
    }

}
