export default class ImportsDataService {

	// Services
	$http = null;
	$q = null;

	constructor($http, $q) {
		this.$http = $http;
		this.$q = $q;
	}

	getAll(idDatabase) {
		const def = this.$q.defer();

		const promise = this.$http.get('/api.php/dbs/' + idDatabase + '/imports');

		promise.then(
			(response) => {
				def.resolve(response.data);
			},
			(response) => {
				def.reject(response.data);
			}
		);

		return def.promise;
	}

	delete(databaseId, importId) {
		const def = this.$q.defer();

		const promise = this.$http.delete('/api.php/dbs/' + databaseId + '/imports/' + importId);

		promise.then(
			(response) => {
				def.resolve(response.data);
			},
			(response) => {
				def.reject(response.data);
			}
		);

		return def.promise;
	}
}

ImportsDataService.$inject = ['$http', '$q'];