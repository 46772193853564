<!-- eslint-disable @angular-eslint/template/no-inline-styles -->
<div class="fdx-datepicker-container">
    <input
        [(ngModel)]="dateValue"
        (ngModelChange)="onChange($event)"
        (blur)="onTouched()"
        [disabled]="disabled"
        [ngClass]="inputClasses"
        [ngStyle]="styles"
        type="text"
        [id]="id"
        [name]="controlName"
        [attr.title]="title ? title : null"
        [attr.placeholder]="placeholder ? placeholder: null"
        [attr.aria-label]="ariaLabel ? labelText: null"
        [attr.readonly]="readonly ? readonly : null"
        [attr.autocomplete]="!autocomplete ? 'off' : null"
        [attr.inputmode]="inputmode ? inputmode: null"

        [attr.required]="control.errors?.['required'] ? 'required' : null"

        #datePickerControl="ngModel"
        #datePicker="ngbDatepicker"
        ngbDatepicker />
    <div (click)="datePicker.toggle()" class="fdx-datepicker-toggle">
        <fa-icon [icon]="faCalendarIcon" [size]="iconSize"></fa-icon>
    </div>
</div>
<fdx-validation [control]="control"
    [labelText]="labelText"
    [customValidationText]="customValidationText"
    [submitted]="form.submitted"
    [ngClass]="{'invalid-feedback': !tooltipValidation, 'invalid-tooltip': tooltipValidation}"
></fdx-validation>
