import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FdxApiResponse } from '@app/core/responses/fdx-api.response';
import { UserSettings } from '@app/user/models/user-settings.model';

// Requests
import { UpdatePasswordRequest } from '@app/user/services/requests/update-password.request';
import { UpdateProfileRequest } from '@app/user/services/requests/update-profile.request';

// Responses
import { PendingInvitesResponse } from '@app/user/services/responses/pending-invites.response';
import { UpdateProfileResponse } from '@app/user/services/responses/update-profile.response';
import { UserSettingsResponse } from '@app/user/services/responses/user-settings.response';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

    constructor(
        private readonly http: HttpClient
    ) { }

    public updateProfile(request: UpdateProfileRequest): Observable<UpdateProfileResponse> {
        return this.http.put<UpdateProfileResponse>('/api.php/user', request);
    }

    public updatePassword(request: UpdatePasswordRequest): Observable<UserSettingsResponse> {
        return this.http.put<UserSettingsResponse>('/api.php/update_password', request);
    }

    public updateSettings(request: UserSettings): Observable<UserSettingsResponse> {
        return this.http.put<UserSettingsResponse>('/api.php/user_settings', request);
    }

    public updateZohoContactId(): Observable<void> {
        return this.http.post<void>('/api.php/user/zoho_contact_id', {});
    }

    public generatePendingUser(user: { accountName: string; pendingUserOption: string; username: string; }): Observable<FdxApiResponse<void>> {
        return this.http.post<FdxApiResponse<void>>('/api.php/generate_pending_user', {
            account_name: user.accountName,
            pending_user_option: user.pendingUserOption,
            username: user.username
        });
    }

    public informPrimaryUser(user: { accountName: string; username: string; }): Observable<FdxApiResponse<void>> {
        return this.http.post<FdxApiResponse<void>>('/api.php/inform_primary_user', {
            account_name: user.accountName,
            username: user.username
        });
    }

    public addPrimaryUser(user: {accountId: string; username: string; }): Observable<string> {
        return this.http.put(
            '/api.php/user/add_primary_user',
            {
                account_id: user.accountId,
                username: user.username
            },
            {
                responseType: 'text'
            }
        );
    }

    public getPendingInvites(): Observable<PendingInvitesResponse> {
        return this.http.get<PendingInvitesResponse>('/api.php/user/pending_invites');
    }
}
