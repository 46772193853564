import fdxUI from '@ajs/services/fdxUI';
import { Clipboard } from '@angular/cdk/clipboard';
import { AfterViewInit, Component, ElementRef, Inject, Input, ViewChild } from '@angular/core';
import { DOCUMENT } from '@app/core/providers/document.provider';
import { WINDOW } from '@app/core/providers/window.provider';
import { CheatSheetContentType } from '@app/modules/cheat-sheet/models/cheat-sheet-content.type';
import { CheatSheetService, CheatSheetTab, CheatSheetTabTitles } from '@app/modules/cheat-sheet/services/cheat-sheet.service';

import { IconDefinition, faUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';

@Component({
    selector: 'fdx-cheat-sheet',
    templateUrl: './cheat-sheet.component.html',
    styleUrls: ['./cheat-sheet.component.scss']
})
export class CheatSheetComponent implements AfterViewInit {

    tabs: CheatSheetTab[] = this.cheatSheetService.tabs;
    currentSection: string = this.encodeString(CheatSheetTabTitles.Intro);

    @ViewChild('container') container: ElementRef;
    minHeight: number = 680;
    height: string = `${this.minHeight}px`;
    offcanvasOffset: number = 15;           // Offcanvas padding
    pageOffset: number = 16 + 1 + 30 + 20;  // card padding + card border + page padding + page margin


    newTabIcon: IconDefinition = faUpRightFromSquare;

    @Input() onCheatSheetPage: boolean = true;

    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        @Inject(WINDOW) public window: Window,
        private readonly cheatSheetService: CheatSheetService,
        private clipboard: Clipboard,
        private readonly fdxUi: fdxUI,
    ) { }

    ngAfterViewInit(): void {
        this.resizeCheatSheet();
    }

    resizeCheatSheet(): void {
        const el = this.container?.nativeElement;
        if (el) {
            const heightNumber: number = this.window.innerHeight -
                el.getBoundingClientRect().top -
                (this.onCheatSheetPage ? this.pageOffset : this.offcanvasOffset);

            this.height = `${Math.max(heightNumber, this.minHeight)}px`;
        }
    }

    onSectionChange(sectionId: string): void {
        this.currentSection = sectionId;
    }

    tabClass(title: string): string {
        if (this.currentSection === this.encodeString(title) || this.currentSection.includes(this.encodeString(title))) {
            return 'active';
        }
        return '';
    }

    groupClass(title: string): string {
        if (this.currentSection === this.encodeString(title)) {
            return 'active';
        }
        return '';
    }

    scrollTo(destination: string): void {
        const query = `#${this.encodeString(destination)}`;
        const shadowRoot = this.document.getElementsByTagName('fdx-style-wrapper')[0].shadowRoot;

        if (shadowRoot) {
            shadowRoot.querySelector(query)?.scrollIntoView({ behavior:'smooth' });
        }
    }

    encodeString(str: string): string {
        return str.toLowerCase().split(/ |\//).join('_');
    }

    isTitleOnly(data: CheatSheetContentType): boolean {
        return this.cheatSheetService.isTitleOnly(data);
    }

    copyToClipboard(event: Event): void {
        const copied = this.clipboard.copy((event.target as Element).textContent);
        if (copied) {
            this.fdxUi.showToastSuccess('Copied to clipboard');
        } else {
            this.fdxUi.showToastError('Failed to copy to clipboard');
        }
    }
}
