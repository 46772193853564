import AppStateService from '@ajs/services/AppStateService';
import { Injectable } from '@angular/core';
import { LatestReleaseNoteDataModel } from '@app/release-notes/models/latest-release-note-data.model';

import { ReleaseNoteDataModel } from '@app/release-notes/models/release-note-data.model';

import ReleaseNotesDataService from '@app/release-notes/services/release-notes-data.service';
import { UserDataModel } from '@app/user/models/user-data.model';
import { Observable } from 'rxjs';

export const releaseNotesLastReadSettingsKey = 'releaseNotes.lastRead';

@Injectable({
    providedIn: 'root'
})
export default class ReleaseNotesService {
    public unread: boolean = false;
    public latest: string = null;

    constructor(
        private readonly appStateService: AppStateService,
        private readonly releaseNotesDataService: ReleaseNotesDataService
    ) { }

    public markAsRead(): void {
        this.unread = false;
    }

    public markAsUnread(): void {
        this.unread = true;
    }

    public triggerRead(lastRead: string): void {
        this.markAsRead();

        void this.appStateService
            .updateUserSetting(
                releaseNotesLastReadSettingsKey,
                lastRead
            );
    }

    public getReleaseNotes(): Observable<ReleaseNoteDataModel[]> {
        return this.releaseNotesDataService.getReleaseNotes();
    }

    public checkUnread(): void {
        this.releaseNotesDataService
            .getLatestReleaseNoteDate()
            .subscribe((value: LatestReleaseNoteDataModel) => {
                    // if null then there are no release notes to read
                    if (!value) {
                        this.markAsRead();
                        return;
                    }

                    const releaseDate: string = value.release_date;

                    // save latest release date in case we need it for something
                    this.latest = releaseDate;

                    // get the user from the app state service
                    const user: UserDataModel = this.appStateService.getUser();

                    // no user or settings so assume release notes are unread
                    if (!(user && user.settings)) {
                        this.markAsRead();
                        return;
                    }

                    const lastRead = user.settings[releaseNotesLastReadSettingsKey];
                    if (!lastRead) {
                        this.markAsUnread();
                        return;
                    }

                    if (lastRead !== releaseDate) {
                        this.markAsUnread();
                    } else {
                        this.markAsRead();
                    }
                },
                (_error: string) => {
                    // could not query the latest release note date
                    this.markAsRead();
                });
    }
}
