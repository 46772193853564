import NavigationService from '@ajs/services/NavigationService';
import ResolversService from '@ajs/services/ResolversService';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { LogService } from '@app/core/services/log.service';
import { StateParams } from '@uirouter/angularjs';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserGuard {
    constructor(
        private readonly logService: LogService,
        private readonly navigationService: NavigationService,
        private readonly resolversService: ResolversService
    ) { }

    public canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.resolversService
            .resolve(['user'], {} as StateParams)
            .then(
                () => {
                    return true;
                }
            )
            .catch(
                (e) => {
                    this.logService.error('[UserGuard:canActivate] Failed to resolve user:', e);
                    this.navigationService.redirectToLogin();
                    return false;
                }
            );
    }
}
