<fdx-form-section header="Tags">
    <form [formGroup]="form">
        <ng-container formArrayName="tags">
            <div class="row">
                <div class="form-label col-md-6">Tag</div>
                <div class="form-label col-md-6">Value</div>
            </div>

            <ng-container *ngFor="let tagGroup of tagGroups; let index = index; trackBy: trackByIndex">
                <div [formGroup]="tagGroup">
                    <div class="row" [ngClass]="{ 'mt-3': index !== 0 }">
                        <div class="col-md-6">
                            <input type="text" class="form-control" formControlName="tag">
                        </div>

                        <div class="col-md-6">
                            <input type="text" class="form-control" formControlName="value" (keydown)="addNewTag()">
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </form>
</fdx-form-section>
