<ngx-codemirror
    fdxTrackVisibility
    (visible)="visibilityChange($event)"
    (cursorActivity)="cursorActivity.emit($event)"
    (drop)="drop.emit($event)"
    (focusChange)="onFocusChange($event)"
    (scroll)="scroll.emit($event)"
    [(ngModel)]="value"
    [autoFocus]="autoFocus"
    [className]="className"
    [name]="name"
    [options]="options"
    [preserveScrollPosition]="preserveScrollPosition">
</ngx-codemirror>
