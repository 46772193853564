import { AfterViewInit, Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { LogService } from '@app/core/services/log.service';
import { BaseInputComponent } from '@app/modules/inputs/components/base-input/base-input.component';
import { InputsUtilitiesService } from '@app/modules/inputs/services/inputs-utilities.service';
import { IconDefinition, faCopy } from '@fortawesome/pro-solid-svg-icons';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'fdx-input-number',
    providers: [{
        provide: BaseInputComponent,
        useExisting: InputNumberComponent
    }],
    templateUrl: './input-number.component.html',
    styleUrls: ['./input-number.component.scss']
})
export class InputNumberComponent extends BaseInputComponent implements OnInit, AfterViewInit {
    @ViewChild('inputElement') inputElement: ElementRef<HTMLInputElement>;
    @ViewChild('copyButton') copyButtonRef: TemplateRef<any>;

    iconCopy: IconDefinition = faCopy;

    @Input() showCopyButton: boolean = false;

    constructor(
        protected readonly element: ElementRef<HTMLElement>,
        protected readonly inputsUtilitiesService: InputsUtilitiesService,
        protected readonly logService: LogService,
        protected readonly ngbDateParserFormatter: NgbDateParserFormatter
    ) {
        super(element, inputsUtilitiesService, logService, ngbDateParserFormatter);
    }

    ngOnInit(): void {
        if (this.readonly === true && this.classes['form-control-plaintext']) {
            // if we're using the readonly form-control-plaintext class, don't add the standard form-control style
            this.initClasses['form-control'] = false;
        } else if (this.classes['form-control'] === undefined) {
            this.initClasses['form-control'] = true;
        }
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();

        if (this.showCopyButton) {
            this.inputGroupRightAddons = this.concatDynamicRightAddon(this.copyButtonRef, this.inputGroupRightAddons);
        }
    }

    inputFocus(): void {
        this.inputElement.nativeElement.focus();
    }

    private concatDynamicRightAddon(templateRefToAppend: TemplateRef<any>, inputGroup?: TemplateRef<any> | TemplateRef<any>[]): TemplateRef<any>[] {
        let tempInputGroup: TemplateRef<any>[] = [];
        if (inputGroup) {
            if (!Array.isArray(inputGroup)) {
                tempInputGroup.push(inputGroup);
            } else {
                tempInputGroup = [...inputGroup];
            }
        }

        return [...tempInputGroup, templateRefToAppend];
    }

}
