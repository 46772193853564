<div [formGroup]="fieldGroup" #form="ngForm">
    <fdx-form-field
        [formControlName]="formControlName"
        [form]="form" >
        <fdx-input-field-or-value
            [classes]="{'auto-grow': true}"
            labelText="Field or value"
            [fGroup]="fieldGroup"
            [fields]="dbFields.autocompleteFields"
            [dragging]="dragging"
            [removable]="true"
            (clearClicked)="removeField()" >
        </fdx-input-field-or-value>
    </fdx-form-field>
</div>
