<modal-hoc
    [modalTitle]="modalTitle"
    submitText="Download"
    [submitDisabled]="loading"
    (modalSubmit)="onModalSubmit()"
	(modalCancel)="onModalCancel()">
	<div class="text-center" *ngIf="loading; else content">
		<fdx-spinner></fdx-spinner>
	</div>
	<ng-template #content>
		<form [formGroup]="dataDownloadForm" #form="ngForm">
			<fdx-form-field formControlName="delimiter" [classes]="{'row': true}" [form]="form">
				<fdx-label>Separator</fdx-label>
				<fdx-input-wrapper>
					<ng-select id="delimiter" formControlName="delimiter" [clearable]="false">
						<ng-option *ngFor="let delimiter of delimiterType | keyvalue; trackBy: trackByIndex"
							[value]="delimiter.value">{{delimiter.key}}</ng-option>
					</ng-select>
				</fdx-input-wrapper>
			</fdx-form-field>
			<fdx-form-field formControlName="downloadType" [classes]="{'row': true, 'mt-2': true}" [form]="form">
				<fdx-label>Download option</fdx-label>
				<fdx-input-wrapper>
					<ng-select id="downloadType" formControlName="downloadType" [clearable]="false">
						<ng-option value="visible">Visible Rows Only</ng-option>
						<ng-option value="all">All Available Rows</ng-option>
					</ng-select>
				</fdx-input-wrapper>
			</fdx-form-field>
		</form>
	</ng-template>
</modal-hoc>
