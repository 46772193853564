import { CdkDragEnter, PreviewContainer, moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { escapeSingleQuotes } from '@app/core/functions/escapeSingleQuotes';
import { isEmpty } from '@app/core/functions/isEmpty';
import { FdxUtilsService } from '@app/core/services/fdx-utils.service';
import { BasicTransformerField } from '@app/modules/inputs/classes/basic-transformer-field.class';
import { AggregatedDbFieldsType } from '@app/modules/inputs/types/aggregated-db-fields.type';
import { BasicQueryData } from '@app/modules/inputs/types/basic-query-data.type';
import { IconDefinition, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { Subject, takeUntil, tap } from 'rxjs';

@Component({
    selector: 'fdx-basic-transformer',
    templateUrl: './basic-transformer.component.html',
    styleUrls: ['./basic-transformer.component.scss']
})
export class BasicTransformerComponent implements AfterViewInit, OnChanges {

    // eslint-disable-next-line @typescript-eslint/typedef
    @Input() transformerForm;
    fieldArrayName: string = 'fieldArray';

    get fieldArray(): FormArray {
        if (!this.transformerForm.controls[this.fieldArrayName]) {
            this.transformerForm.addControl(this.fieldArrayName, new FormArray([]));
        }

        return this.transformerForm.controls[this.fieldArrayName];
    }

    @Input() transformerString: string;

    @Input() disabled?: boolean = false;

    @Input() basicQueryData: BasicQueryData;

    @Input() dbFields: AggregatedDbFieldsType;

    formReady: boolean = false;

    dragging: boolean = false;

    iconPlus: IconDefinition = faPlus;

    cdkDragPreviewContainer: PreviewContainer = 'parent';

    get isDragging(): boolean {
        return this.dragging;
    }

    readonly unsubscribe$: Subject<void> = new Subject<void>();

    trackByValue(_index: number, value: AbstractControl): AbstractControl {
        return value;
    }

    constructor(
        private fdxUtils: FdxUtilsService
    ) {}

    ngAfterViewInit(): void {
        this.transformerForm.valueChanges.pipe(
            tap(() => this.checkAndSetRequiredValidators()),
            takeUntil(this.unsubscribe$))
        .subscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        // Setup form as long as it wasn't previously setup, or if it has been setup, ensure there are no errors (no reason to setup while typing in advanced mode)
        if (changes.basicQueryData && this.basicQueryData &&
            (changes.basicQueryData.previousValue === undefined || (!this.basicQueryData.error && !this.basicQueryData.tooComplex))) {
            this.setupForm();
        }

        if (changes.disabled && this.formReady && this.fieldArray) {
            Object.values(this.fieldArray.controls).forEach((control: AbstractControl) => {
                if (this.disabled) {
                    control.disable();
                } else {
                    control.enable();
                }
            });
        }
    }

    setupForm(): void {
        this.formReady = false;

        this.fieldArray.clear();

        this.basicQueryData.transformer.forEach((field: BasicTransformerField, index: number) => {
            this.fieldArray.push(
                this.getFieldGroup(field)
            );
        });

        this.formReady = true;
    }

    checkAndSetRequiredValidators(): void {
        // eslint-disable-next-line @typescript-eslint/typedef
        const controls = Object.values(this.fieldArray.controls);
        const validators: ValidatorFn[] = controls.length > 1 ? [Validators.required] : [];
        controls.forEach((group: FormGroup) => {
            Object.keys(group.value).forEach((fieldName: string) => {
                // eslint-disable-next-line @typescript-eslint/typedef
                const control = group.controls[fieldName];
                control.setValidators(validators);
                control.updateValueAndValidity({emitEvent: false});
            });
        });
    }

    valuesToQuery(): string {
        const rowsToRemove: number[] = [];

        // First loop to determine if we can remove any fields
        this.fieldArray.controls.forEach((group: FormGroup, index: number) => {
            if (index > 0 && isEmpty(group.value)) {     // Not first field
                rowsToRemove.push(index);
            }
        });

        rowsToRemove.forEach((row: number) => {
            this.removeFieldClick(row, false);
        });

        this.checkAndSetRequiredValidators();

        // Second loop to actually build the transformer string with the new values
        let newTransformerString: string = '';

        this.fieldArray.controls.forEach((group: FormGroup) => {
            let value: string = Object.values(group.controls)[0].value as string;
            if (value && !this.fdxUtils.isField(value)) {   // If value is not a field
                value = escapeSingleQuotes(value); // re-escape apostraphes
                value = `'${value}'`;                       // add single quotes around string
            } else if (isEmpty(value)) {      // If value is empty
                value = '\'\'';                               // make value empty quotes
            }

            newTransformerString += value;
        });

        this.transformerString = newTransformerString;
        return this.transformerString;
    }

    removeFieldClick(index: number, emit: boolean = true): void {
        this.fieldArray.removeAt(index, {emitEvent: emit});
    }

    addNewField(): void {
        this.fieldArray.push(this.getFieldGroup());
    }

    getFieldGroup(field: BasicTransformerField = new BasicTransformerField(null)): FormGroup {
        const value = !isEmpty(field.value) ? field.value.replace(/\\'/g, '\'') : null;

        return new FormGroup({
            field: new FormControl({value: value, disabled: this.disabled})
        });
    }

    controlAsFormGroup(field: AbstractControl): FormGroup {
        return field as FormGroup;
    }

    entered(event: CdkDragEnter): void {
        moveItemInArray(this.fieldArray.controls, event.item.data, event.container.data);
    }
}
