<div
    class="d-flex flex-column fdx-category-path-wrapper"
    [ngClass]="{ 'gap-2': !dropdownDisplay }">
    <ng-container *ngFor="let path of categoryPaths; trackBy: trackByIndex; let first = first">
        <ng-container *ngIf="!dropdownDisplay || first">
            <div
                [ngClass]="{'mt-1': dropdownDisplay && first, 'ms-3': dropdownDisplay}">
                <div class="fdx-category-path">{{path.path}}</div>
                <div *ngIf="path.query" class="border-start mt-1 ps-3 fdx-category-query">when {{path.query}}</div>
            </div>
        </ng-container>
    </ng-container>
    <div *ngIf="dropdownDisplay && categoryPaths.length > 1" class="mt-1 ms-3 fdx-more-category-paths">
        <ng-container [ngPlural]="categoryPaths.length - 1">
            <ng-template ngPluralCase="=1">+1 more category path</ng-template>
            <ng-template ngPluralCase="other">+{{ categoryPaths.length - 1 }} more category paths</ng-template>
        </ng-container>
    </div>
</div>
