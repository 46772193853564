import * as angular from 'angular';
import { MODULE_NAME } from '../config/constants';

angular
    .module(MODULE_NAME)
    .controller('FeedSupportExistingTicketsController', ['$scope', 'AppStateService', function ($scope, AppStateService) {

        $scope.account_id = AppStateService.getAccount().id;

        $scope.breadcrumbs = [
            {
                title: 'Feedsupport™',
                route: ['/', $scope.account_id, 'feedsupport-new']
            }
        ];

    }]);
