import * as angular from 'angular';
import { MODULE_NAME } from '../../../config/constants';

angular.module(MODULE_NAME)
.directive('feedsupportCreateTicket', function() {
    return {
        restrict: 'E',
        template: require('./feedsupport-create-ticket.template.html'),
        controller : 'FeedSupportCreateTicket',
    };
})
.directive('feedsupportSelectStore', function() {
  return {
    template: require('./feedsupport-select-store.template.html'),
  };
});