import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BootstrapThemeProp } from '@app/core/services/bootstrap-theme.service';
import { Modal } from '@app/modules/modals/modals.module';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'exports-simulated-date-time-modal',
    templateUrl: './exports-simulated-date-time-modal.component.html',
    styleUrls: ['./exports-simulated-date-time-modal.component.scss']
})
export class ExportsSimulatedDateTimeModalComponent implements Modal {
    public modalTheme: BootstrapThemeProp = 'primary';

    public simulatedDateTimeForm: UntypedFormGroup = new UntypedFormGroup({
        simulatedDate: new UntypedFormControl('')
    });

    constructor(
        private modal: NgbActiveModal,
    ) { }

    public onModalSubmit(event: MouseEvent): void {
        this.modal.close(this.simulatedDateTimeForm.get('simulatedDate').value);
    }

    public onModalCancel(event: MouseEvent): void {
        this.modal.dismiss();
    }
}
