/* eslint-disable @typescript-eslint/default-param-last */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CategoryFieldMapDataModel, CategoryFieldValuesMapDataModel, CategorySearchDataModel, CategoryTaxonomySeller, ExportedCategoryListDataModel, FieldGroupedCategoriesMapDataModel, GetCategoryFieldValuesParams } from '@app/category-taxonomy/models';
import { CategoryPathsRequest } from '@app/category-taxonomy/services/requests/category-paths.request';
import { GetCategoriesRequest } from '@app/category-taxonomy/services/requests/get-categories.request';
import { GetGroupedCategoriesRequest } from '@app/category-taxonomy/services/requests/get-grouped-categories.request';
import { UniqueAttributesRequest } from '@app/category-taxonomy/services/requests/unique-attributes.request';
import { CategoryPathsResponse } from '@app/category-taxonomy/services/responses/category-paths.response';
import { ChannelsWithRegionsResponse } from '@app/category-taxonomy/services/responses/channels-with-regions.response';
import { GetCategoryAuthResponse } from '@app/category-taxonomy/services/responses/get-category-auth.response';
import { UniqueAttributeField } from '@app/category-taxonomy/services/responses/unique-attributes.response';
import { Observable, map } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export default class CategoryTaxonomyDataService {

    constructor(private http: HttpClient) {}

    getCategories(databaseId: string, params: GetCategoriesRequest, page: number = 1, pageSize: number = 50): Observable<CategorySearchDataModel> {
        return this.http.get<CategorySearchDataModel>(`/api.php/dbs/${databaseId}/category_taxonomy/${page}/${pageSize}`, {
            params: params
        }).pipe(map((categories) => {
            // ensure data integrity
            return {
                ...categories,
                current_page: parseInt(`${categories.current_page}`, 10),
                page_size: parseInt(`${categories.page_size}`, 10),
                total_pages: Math.ceil(categories.total_pages)
            };
        }));
    }

    getCategoryAuth(databaseId: string, client: string): Observable<GetCategoryAuthResponse> {
        const url = `/api.php/dbs/${databaseId}/category_taxonomy_required_attributes`;
        const params = {
            client
        };

        return this.http.get<GetCategoryAuthResponse>(url, { params });
    }

    getCategoryFields(databaseId: string, categoryId: string, client: string, seller: CategoryTaxonomySeller): Observable<CategoryFieldMapDataModel> {
        const url = `/api.php/dbs/${databaseId}/category_taxonomy/${categoryId}`;
        const params = {
            client,
            seller_id: seller.id,
            seller_token: seller.token
        };

        return this.http.get<CategoryFieldMapDataModel>(url, { params });
    }

    getCategoryFieldValues(requestData: GetCategoryFieldValuesParams): Observable<CategoryFieldValuesMapDataModel> {
        const {
            attributeId,
            categoryId,
            client,
            databaseId,
            seller
        } = requestData;

        const url = `/api.php/dbs/${databaseId}/category_taxonomy_values_for_attribute/${categoryId}`;
        const params = {
            client,
            attribute_id: attributeId,
            seller_id: seller?.id,
            seller_token: seller?.token
        };

        return this.http.get<CategoryFieldValuesMapDataModel>(url, { params });
    }

    getCategoryList(databaseId: string, client: string, seller: CategoryTaxonomySeller): Observable<ExportedCategoryListDataModel> {
        const url = `/api.php/dbs/${databaseId}/category_list`;
        const params = {
            client,
            seller_id: seller.id,
            seller_token: seller.token
        };

        return this.http.get<ExportedCategoryListDataModel>(url, { params });
    }

    getGroupedCategories(databaseId: string, params: GetGroupedCategoriesRequest, page: number, pageSize: number = 50): Observable<FieldGroupedCategoriesMapDataModel> {
        return this.http.get<FieldGroupedCategoriesMapDataModel>(`/api.php/dbs/${databaseId}/category_taxonomy_grouped/${page}/${pageSize}`, {
            params: params
        }).pipe(map((data) => {
            return {
                ...data,
                current_page: parseInt(`${data.current_page}`, 10),
                page_size: parseInt(`${data.page_size}`, 10),
                total_pages: Math.ceil(data.total_pages)
            };
        }));
    }

    getCategoryPaths(databaseId: string, params: CategoryPathsRequest): Observable<CategoryPathsResponse> {
        return this.http.get<CategoryPathsResponse>(`/api.php/dbs/${databaseId}/category_paths/`, { params });
    }

    getChannelsWithRegions(databaseId: string): Observable<ChannelsWithRegionsResponse> {
        return this.http.get<ChannelsWithRegionsResponse>(`/api.php/dbs/${databaseId}/channels_with_regions`, {});
    }

    getUniqueAttributes(databaseId: string, params: UniqueAttributesRequest): Observable<UniqueAttributeField[]> {
        return this.http.post<UniqueAttributeField[]>(`/api.php/dbs/${databaseId}/unique_taxonomy_attributes`, params);
    }
}
