import * as angular from 'angular';
import FdxFtpTriggersTriggerableHostsModalController from './fdx-ftp-triggers-triggerable-hosts-modal.controller';
import { MODULE_NAME } from '../../../../config/constants';

import './fdx-ftp-triggers-triggerable-hosts-modal.styles.scss';

export const componentName = 'fdxFtpTriggersTriggerableHostsModal';

angular.module(MODULE_NAME).component(componentName, {
	template: require('./fdx-ftp-triggers-triggerable-hosts-modal.template.html'),
	controller: FdxFtpTriggersTriggerableHostsModalController,
	bindings: {
		// from uibmodal
		close: '&',
		dismiss: '&',
		resolve: '<?'
	}
});
