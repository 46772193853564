import * as angular from 'angular';
import { MODULE_NAME } from '../config/constants';

angular.module(MODULE_NAME)
  .directive('transformerWizard', ['$http', TransformerWizard])
  .directive('transformerWizardRow', [TransformerWizardRow]);

let has_initted_code_mirror = false;
function init_code_mirror($scope) {
  $scope.cmInit = cm_variables.cmInit;

  const valid_db_fields = $scope.dbfields.map(function(value) {
    return value.displayName;
  });

  const autocomplete_db_fields = $scope.dbfields.map(function(value) {
    return '[' + value.displayName + ']';
  });

  // Initialize auto complete
  const value_functions = [
    'collapse_spaces( [field_name] )',
    'convert_category( [google_shopping_category], \'shopzilla\', [gender] )',
    'csv_get( [field], 0 )',
    'current_time()',
    'decode_html_entities( [field] )',
    'deduplicate_words( [field_name] )',
    'format_date( [field_name], \'Y-m-d H:i\' )',
    'index_of( [field_name], \'search_for\', offset )',
    'json_get( [json_field], \'json_key\' )',
    'lcase_first( [field] )',
    'lcase( [field] )',
    'left( [field_name], \'text_to_stop_before\' )',
    'leftNoTrunc( [field_name], 150 )',
    'match( \'regex\', [field], offset, \'pattern_modifiers\')',
    'repeat_str( [field], \'times\')',
    'filter_items_from_list( [field], \'delimiter\', \'query_to_keep\')',
    'maximum( [field_1], [field_2], \'value1\' )',
    'mid( [fld_name], \'txt_start\', \'txt_stop\' )',
    'minimum( [field_1], [field_2], \'value1\' )',
    'nohtml( [field] )',
    'extract_html_list_items( [field], \'separator\', start_offset, length )',
    'parse_color( [field] )',
    'parse_date( [field_name] )',
    'parse_num( [field] )',
    'percent( [field] )',
    'replace( \'search_for\', \'replace_with\', [field] )',
    'replace_i( \'search_for\', \'replace_with\', [field] )',
    'replace_pattern( \'regex\', \'replace_with\', [field] )',
    'replace_exact( \'search_for\', \'replace_with\', [field] )',
    'separate_words( [field], \'separator\' )',
    'right( [field_name], \'text_to_start_after\' )',
    'round_number( [field], 2, \'up\' )',
    'margin( [field_1, [field_2] )',
    'str_len( [field] )',
    'strip_backslash( [field_name] )',
    'substring( [field_name], start_offset, length )',
    'tcase( [field] )',
    'is_alpha( [field] )',
    'is_number( [field] )',
    'is_alphanumber( [field] )',
    'trim_spaces( [field_name] )',
    'image_info( [field_name], delimiter , flags_optional )',
    'transform_image( [field_name], delimiter , transformation_1 , transformation_2 )',
    'add_padding( max_width , max_height, background_color_optional )',
    'add_overlay( pos_x , pos_y, image_url )',
    'add_text( pos_x , pos_y , text , options )',
    'convert_to_rgb( )',
    'renew( )',
    'resize( max_width , max_height , maintain_aspect_ratio_optional )',
    'crop_image( max_width , max_height , anchor_x_optional, anchor_y_optional )',
    'blur_background( max_width , max_height )',
    'extend_background( max_width , max_height )',
    'ucase_first( [field] )',
    'ucase( [field] )',
    'unique_id( [field], \'hash_method\' )',
    'url_decode( [field] )',
    'url_encode( [field] )',
    'valid_gtin( [field] )',
    'word_count( [field], \'separator\')',
    'google_taxonomy_path_to_id_list( [field], \'EN\' )',
    'google_taxonomy_id_to_path( [field], \'EN\' )'
  ];

  if(!has_initted_code_mirror) {
    has_initted_code_mirror = true;

    CodeMirror.hint.transformer_parser = cm_variables.hint_transformer_parser(autocomplete_db_fields, value_functions);

    // Initialize mode
    CodeMirror.defineSimpleMode(
      'transformer_parser',
      cm_variables.mode_transformer_parser(valid_db_fields)
    );
  }

  $scope.code_mirror = {
    'mode': 'transformer_parser',
    'extraKeys': {
      'Tab': false,
      'Shift-Tab': false
    }
  };

  $scope.operators = [
    'after',
    'before',
    'begins_with',
    'contains',
    'contains_all',
    'contains_any',
    'contains_word',
    'contains_words',
    'ends_with',
    'equal',
    'equal_any',
    'NOT',
    'not_begins_with',
    'not_contains',
    'not_ends_with',
    'not_equal',
    '>',
    '<'
  ];
}

function TransformerWizard($http) {
  return {
    restrict: 'E',
    template: require('./templates/transformerwizard.html'),
    scope: {
      addfunc: '=',
      dbfields: '=',
      dbId: '=',
      delfunc: '=',
      editfunc: '=',
      exports: '=',
      field: '=',
      transformer: '=',
    },
    link: function($scope) {
      $scope.loading = true;
      $scope.groups = [];
      $scope.tokenized_transformer = [];

      init_code_mirror($scope);

      function new_selector(type, push_arr, conditional) {
        let transformer;

        if (type === 'group') {
          transformer = {
            'conditional': conditional || '',
            'transformers': [],
            'type': type,
          };
        } else {
          transformer = {
            'complex_field': '',
            'conditional': conditional || '',
            'field': '',
            'transformer_type': 'simple',
            'transformers': [],
            'type': type,
            'value': '',
            'where': '',
          };
        }

        if (push_arr && push_arr.push) {
          push_arr.push(transformer);
        }

        return transformer;
      }

      $scope.$watch('transformer', function(newVal, oldVal) {
        if (newVal && newVal != oldVal) {
          $scope.transformer.transformer_modified_simple = true;
        }
      }, true);

      $scope.$watch('tokenized_transformer', function(newVal, oldVal) {
        if (newVal.hasOwnProperty(0)) {
          const fields = ['type', 'value'];

          Object.keys(oldVal).forEach( function(index) {
            fields.forEach( function(field) {
              if (newVal[index] && newVal[index][field] != oldVal[index][field]) {
                $scope.transformer.transformer_modified_simple = true;
              }
            })
          });
        }
      }, true);

      function new_transformer(type) {
        return {
          'value': '',
          'type': type
        };
      }

      function peek(items, index) {
        return items[index] || {
          'token': '',
          'value': ''
        };
      }

      function is_transformer_complete(transformer) {
        return (transformer.field && transformer.where && transformer.value);
      }

      function handle_complete_transformer(transformers, tokenized) {
        // Standalone transformer that should be grouped
        if (transformers.length === 1) {
          const transformer = transformers.pop();
          const group = new_selector('group', null, transformer.conditional);

          group.transformers.push(transformer);

          tokenized.push(group);
        } else {
          transformers.pop();
        }
      }

      function handle_value_only_transformer(transformer, peeked) {
        if (peeked.token === 'b_literal' && transformer.field && !transformer.value && !transformer.where) {
          transformer.value = transformer.field;
          transformer.field = '';
        }
      }

      function tokenized_selector_to_json(items) {
        let tokenized = [],
          transformers = [],
          is_complex_field = false;

        items.forEach(function(item, index) {
          switch(item.token) {
            case 'open_paren': {
              if (is_complex_field === true) {
                transformers[transformers.length - 1]['complex_field'] += item.value;
                return;
              }

              const transformer = transformers[transformers.length - 1],
                peeked = peek(items, index - 1);

              if (!transformer || ['and', 'or'].indexOf(peeked.token) === -1) {
                transformers.push(
                  new_selector('group', transformer ? transformer.transformers : null)
                );
              }
            } break;
            case 'function_prefix': {
              is_complex_field = true;

              const transformer = transformers[transformers.length - 1];
              if (!transformer || transformer.type === 'group') {
                transformers.push(
                  new_selector('transformer', transformer ? transformer.transformers : null)
                );
              }

              transformers[transformers.length - 1]['transformer_type'] = 'complex';
              transformers[transformers.length - 1]['complex_field'] = item.value;
            } break;
            case 'b_literal': {
              if (is_complex_field) {
                transformers[transformers.length - 1]['complex_field'] += '[' + item.value + ']';
                return;
              }

              const transformer = transformers[transformers.length - 1];
              if (!transformer || transformer.type === 'group') {
                transformers.push(
                  new_selector('transformer', transformer ? transformer.transformers : null)
                );
              }

              if (!transformers[transformers.length - 1]['field']) {
                transformers[transformers.length - 1]['field'] = item.value;
              } else {
                transformers[transformers.length - 1]['value'] = item.value;
              }

              if (is_transformer_complete(transformers[transformers.length - 1])) {
                handle_complete_transformer(transformers, tokenized);
              }
            } break;
            case 'variable': {
              if (is_complex_field) {
                transformers[transformers.length - 1]['complex_field'] += '['+ item.value +']';
                return;
              }

              let transformer;

              transformer = transformers[transformers.length - 1];
              if (!transformer || transformer.type === 'group') {
                transformers.push(
                  new_selector('transformer', transformer ? transformer.transformers : null)
                );
              }

              transformer = transformers[transformers.length - 1];
              if (transformer['field']) {
                transformer['value'] = '\'' + item.value + '\'';
              } else {
                transformer['field'] = item.value;
              }

              if (is_transformer_complete(transformer)) {
                handle_complete_transformer(transformers, tokenized);
              }
            } break;
            case 'relop':
            case 'function_infix': {
              transformers[transformers.length - 1]['where'] = item.value;
            } break;
            case 'comma': {
              if (is_complex_field) {
                transformers[transformers.length - 1]['complex_field'] += item.value +' ';
              }
            } break;
            case 'string': {
              if (is_complex_field) {
                transformers[transformers.length - 1]['complex_field'] += '\''+ item.value +'\'';
                return;
              }

              transformers[transformers.length - 1]['value'] = '\''+ item.value +'\'';

              if (is_transformer_complete(transformers[transformers.length - 1])) {
                handle_complete_transformer(transformers, tokenized);
              }
            } break;
            case 'num': {
              if (is_complex_field) {
                transformers[transformers.length - 1]['complex_field'] += item.value;
                return;
              }

              transformers[transformers.length - 1]['value'] = item.value;

              if (is_transformer_complete(transformers[transformers.length - 1])) {
                handle_complete_transformer(transformers, tokenized);
              }
            } break;
            case 'and':
            case 'or': {
              const peeked = peek(items, index + 1);
              let type = ['variable', 'function_prefix'].indexOf(peeked.token) >= 0 ? 'transformer' : 'group';

              if (peeked.token === 'function_prefix' && transformers.length === 0) {
                type = 'group';
              }

              transformers.push(
                new_selector(type, transformers.length ? transformers[transformers.length - 1].transformers : null, item.token)
              );
            } break;
            case 'close_paren': {
              if (is_complex_field) {
                transformers[transformers.length - 1]['complex_field'] += item.value;
                is_complex_field = false;
              }

              handle_value_only_transformer(transformers[transformers.length - 1], peek(items, index - 1));

              // If its the last closing paren then push, otherwise go back a level
              if (transformers.length === 1) {
                tokenized.push(transformers.pop());
              } else {
                transformers.pop();
              }
            } break;
            case 'end': {
              if (transformers.length === 1 && transformers[0].type === 'transformer') {
                const transformer = transformers.pop();
                const group = new_selector('group', null, transformer.conditional);

                handle_value_only_transformer(transformer, peek(items, index - 1));

                group.transformers.push(transformer);

                tokenized.push(group);
              }

              while (transformers.length) {
                const transformer = transformers.pop();

                handle_value_only_transformer(transformer, peek(items, index - 1));

                tokenized.push(transformer);
              }
            } break;
          }
        });

        $scope.groups = tokenized;
        $scope.loading = false;
      }

      function tokenized_transformer_to_json(items) {
        let tokenized = [],
          transformer;

        items.forEach(function(item) {
          switch(item.token) {
            case 'function': {
              if (transformer && transformer.value.length) {
                tokenized.push(transformer);
              }

              transformer = new_transformer('custom');

              transformer.value += item.value;
            } break;
            case 'open_paren': {
              transformer.value += item.value;
            } break;
            case 'comma': {
              transformer.value += item.value + ' ';
            } break;
            case 'addop':
            case 'mulop':
            case 'num': {
              if(!transformer) {
                transformer = new_transformer('custom');
              }

              transformer.value += item.value + ' ';
            } break;
            case 'string': {
              if(!transformer) {
                transformer = new_transformer('custom');
              }

              transformer.value += '\'' + item.value + '\'';
            } break;
            case 'close_paren': {
              transformer.value += item.value;
              tokenized.push(transformer);
              transformer = null;
            } break;
            case 'variable': {
              if(!transformer) {
                transformer = new_transformer('variable');
              }

              if(transformer.type === 'variable') {
                transformer.value = item.value;
              } else {
                transformer.value += '[' + item.value + ']';
              }
            } break;
            case 'end': {
              if (transformer && transformer.value.length) {
                tokenized.push(transformer);
              }
            } break;
          }
        });

        if (!tokenized.length) {
          tokenized.push(new_transformer('custom'));
        }

        $scope.tokenized_transformer = tokenized;
      }

      if ($scope.transformer && ($scope.transformer.selector || $scope.transformer.transformer)) {
        $scope.unable_to_parse = false;

        $http.post('/api.php/dbs/' + $scope.dbId + '/transformers/tokenize-old', {
          'selector': $scope.transformer.selector,
          'transformer': $scope.transformer.transformer
        }).then(function (response) {
          if (Array.isArray(response.data.data.selector)) {
            tokenized_selector_to_json(response.data.data.selector);
          }

          if (Array.isArray(response.data.data.transformer)) {
            tokenized_transformer_to_json(response.data.data.transformer)
          }
        }, function (response) {
          $scope.unable_to_parse = true;
          $scope.loading = false;
        });
      } else {
        $scope.transformer = {
          'export_id': 0
        };

        $scope.groups = [
          {
            'type': 'group',
            'conditional': '',
            'transformers': [
              {
                'field': '',
                'transformer_type': 'simple',
                'transformers': [],
                'type': 'transformer',
                'value': '',
                'where': '',
              }
            ]
          }
        ];

        $scope.tokenized_transformer = [
          new_transformer('variable')
        ];

        $scope.loading = false;
      }

      $scope.add_transformer_row = function(type) {
        $scope.tokenized_transformer.push(new_transformer(type));
      };

      $scope.remove_transformer_row = function(rows, index) {
        rows.splice(index, 1);
      };

      $scope.data = {
        build_transformer_string: function(transformer, transformer_index) {
          let str = '';

          if (transformer_index > 0 && transformer.conditional) {
            str += ' ' + transformer.conditional.toUpperCase() + ' ';
          }

          const is_transformer_valid = transformer.complex_field || ((transformer.field && transformer.where) || (!transformer.field && !transformer.where && transformer.value));
          if (!is_transformer_valid) {
            return str;
          }

          if (transformer.complex_field) {
            str += transformer.complex_field;
          } else if(transformer.value && !transformer.field && !transformer.where) {
            str += transformer.value;
          } else {
            str += '[' + transformer.field + '] ' + transformer.where + ' ' + transformer.value;
          }

          return str.trim() ? str : '';
        },
        parse_group: function(groups, transformers) {
          groups.forEach(function(group, group_index) {
            const transformer_str = $scope.data.build_transformer_string(group, group_index);
            if (transformer_str) {
              transformers.push(transformer_str);
            }

            if (group.transformers.length) {
              transformers.push('(');
            }

            group.transformers.forEach(function(transformer, transformer_index) {
              const transformer_str = $scope.data.build_transformer_string(transformer, transformer_index);
              if (transformer_str) {
                transformers.push(transformer_str);
              }

              if (transformer.transformers.length) {
                transformers.push('(');

                $scope.data.parse_group(transformer.transformers, transformers);

                transformers.push(')');
              }
            });

            if (group.transformers.length) {
              transformers.push(')');
            }
          });
        },
        log: function() {
          const transformers = [];

          $scope.data.parse_group($scope.groups, transformers);
        },
        save: function() {
          const selectors = [];
          $scope.data.parse_group($scope.groups, selectors);
          $scope.transformer.selector = selectors.join('');

          const transformers = [];
          $scope.tokenized_transformer.forEach(function(item) {
            if(item.type === 'variable') {
              transformers.push('[' + item.value +']');
            } else {
              transformers.push(item.value);
            }
          });

          $scope.transformer.transformer = transformers.join(' ');

          if($scope.transformer && $scope.transformer.id) {
            ($scope.editfunc || angular.noop)($scope.transformer);
          } else {
            ($scope.addfunc || angular.noop)($scope.transformer);
          }
        },
        delete: function() {
          ($scope.delfunc || angular.noop)($scope.transformer);
        }
      };
    }
  }
}

function TransformerWizardRow() {
  return {
    template: require('./templates/transformerwizard-row.html'),
    scope: {
      dbfields: '=',
      first: '=',
      group: '=',
      groups: '=',
      last: '=',
      parentgroup: '=',
      parentgroupindex: '=',
      subgroup: '=',
      transformer: '=',
    },
    link: function($scope) {
      init_code_mirror($scope);

      $scope.$watch('group.transformers.length', function(length) {
        // If empty then remove itself
        if (length === 0) {
          $scope.parentgroup.splice($scope.parentgroupindex, 1);

          // If its by itself and there are sub transformers then baaack it up!
        } else if (length === 1 && $scope.group.transformers[0].transformers.length >= 1) {
          $scope.group.transformers = $scope.group.transformers[0].transformers;
        }
      });

      $scope.$watch('group', function(newVal, oldVal) {

        if (newVal) {
          const group_fields = ['conditional', 'type'];

          group_fields.forEach( function(g_field) {
            if (newVal[g_field] && newVal[g_field] != oldVal[g_field]) {
              $scope.transformer.transformer_modified_simple = true;
            }
          });

          const transformer_fields = [
            "complex_field",
            "field",
            "where",
            "value",
            "conditional",
            "transformer_type",
            "type",
          ];

          Object.keys(oldVal.transformers).forEach( function(tr_index) {
            transformer_fields.forEach( function (field) {
              if(newVal.transformers[tr_index] && oldVal.transformers[tr_index][field] != newVal.transformers[tr_index][field]) {
                $scope.transformer.transformer_modified_simple = true;
              }
            });

          });
        }
      }, true);

      $scope.add_field = function(arr, conditional, type, transformer_type) {
        arr.push({
          'conditional': conditional,
          'field': '',
          'transformer_type': transformer_type || 'simple',
          'transformers': [],
          'type': type,
          'value': '',
          'where': '',
        });

        if (type === 'group') {
          $scope.add_field(arr[arr.length - 1].transformers, '', 'transformer');
        }
      };

      $scope.remove_field = function(transformer_index) {
        $scope.group.transformers.splice(transformer_index, 1);

        if ($scope.group.transformers.length) {
          $scope.group.transformers[0].conditional = '';
        }
      };
    }
  };
}
