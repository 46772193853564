import fdxUtils from './fdxUtils';
import AppStateService from './AppStateService';

export default class AnalyticsService {

    static $inject = [
        '$window',
        'AppStateService',
        'fdxUtils',
    ];

    constructor(
        private $window,
        private AppStateService: AppStateService,
        private fdxUtils: fdxUtils,
    ) {
    }

    public identify(): void {

        // hotjar
        if (this.$window.hj && this.loggedInUser) {
            this.$window.hj('identify', this.loggedInUser.user_id, this.getHotJarAttributes());
        }

        // pendo
        if (this.$window.pendo && this.$window.pendo.ENV && this.loggedInUser) {
            if (this.$window.pendo.getVisitorId() !== this.loggedInUser.user_id) {
                this.$window.pendo.initialize(this.getPendoAttributes());
            } else {
                this.$window.pendo.updateOptions(this.getPendoAttributes());
            }
        }
    }

    public triggerEvent(eventName: string): void {
        if (this.$window.hj) {
            this.$window.hj('event', eventName);
        }
    }

    get loggedInUser() {
        return this.AppStateService.getLoggedInUser();
    }

    get account() {
        return this.AppStateService.getAccount();
    }

    get database() {
        return this.AppStateService.getDatabase();
    }

    get privacyLevel() {
        return this.loggedInUser.getPrivacyLevel(this.AppStateService.getAccountId());
    }

    get roles() {
        return this.loggedInUser.roles.sort().join(', ');
    }

    getAttributes(): any {
        let attributes = {};

        if (this.loggedInUser) {
            attributes['user_user_id'] = this.loggedInUser['user_id'];
            attributes['user_email'] = this.loggedInUser['user_name'];
            attributes['user_role'] = this.privacyLevel;
            attributes['user_roles'] = this.roles;
            attributes['user_zoho_contact_id'] = this.loggedInUser['zoho_contact_id'];

            for (const feature in this.loggedInUser['features']) {
                attributes[`user_feature_${feature}`] = this.loggedInUser['features'][feature];
            }
        }

        if (this.account) {
            attributes['account_id'] = this.account['id'];
            attributes['account_account_name'] = this.account['account_name'];
            attributes['account_permissions'] = this.account['permissions'];

            for (const feature in this.account['features']) {
                attributes[`account_feature_${feature}`] = this.account['features'][feature];
            }
        }

        if (this.database) {
            attributes['db_id'] = this.database['id'];
            attributes['db_name'] = this.database['name'];
            attributes['db_paused'] = this.database['paused'];
            attributes['db_date_created'] = this.database['time'];
            attributes['db_date_import_last_started'] = this.database['timestamp'];
            attributes['db_data_count'] = this.database['data_count'];
            attributes['db_last_run_imports_status'] = this.database['last_run_imports_status'];
            attributes['db_created_at'] = this.database['created_at'];
            attributes['db_deleted_at'] = this.database['deleted_at'];
            attributes['db_db_group_id'] = this.database['db_group_id'];

            for (const feature in this.database['features']) {
                attributes[`db_feature_${feature}`] = this.database['features'][feature];
            }
        }

        // clean mysql date time fields to ISO
        const dateTimeAttributes = [
            'db_date_import_last_started',
            'db_date_created',
            'db_created_at',
            'db_deleted_at',
        ];
        for (const attribute in attributes) {
            if (dateTimeAttributes.includes(attribute)) {
                if (attributes[attribute] && attributes[attribute] !== '0000-00-00 00:00:00') {
                    attributes[attribute] = this.fdxUtils.getDateObj(attributes[attribute]).toISOString();
                } else {
                    delete attributes[attribute];
                }
            }
        }
        return attributes;
    }

    getHotJarAttributes(): any {
        const attributes = this.getAttributes();

        const customAttributes = [
            'user_email',
        ];

        // set the custom attributes
        let hotJarAttributes = {
            email: attributes['user_email'],
        };

        // attach all other attributes
        for (let [attribute_name, attribute_value] of Object.entries(attributes)) {
            if (!customAttributes.includes(attribute_name)) {
                hotJarAttributes[attribute_name] = attribute_value;
            }
        }
        return hotJarAttributes;
    }

    getPendoAttributes(): any {
        const attributes = this.getAttributes();

        const customAttributes = [
            'user_user_id',
            'user_email',
            'user_role',
            'user_roles',
            'db_id',
            'db_name',
            'account_id',
            'account_account_name',
        ];

        // set the custom attributes
        let pendoAttributes = {
            visitor: {
                id: attributes['user_user_id'],
                email: attributes['user_email'],
                role: attributes['user_role'],
                roles: attributes['user_roles'],
            },
            account: {
                id: attributes['db_id'] ?? null,
                name: attributes['db_name'] ?? null,
            },
            parentAccount: {
                id: attributes['account_id'] ?? null,
                name: attributes['account_account_name'] ?? null,
            }
        };

        // attach all other attributes
        const userRegex = /^user_/g;
        const dbRegex = /^db_/g;
        for (let [attribute_name, attribute_value] of Object.entries(attributes)) {
            if (!customAttributes.includes(attribute_name)) {
                const isUserAttribute = attribute_name.match(userRegex);
                const isDbAttribute = attribute_name.match(dbRegex);
                if (isUserAttribute) {
                    pendoAttributes['visitor'][attribute_name] = attribute_value;
                } else if (isDbAttribute) {
                    pendoAttributes['account'][attribute_name] = attribute_value;
                } else {
                    pendoAttributes['parentAccount'][attribute_name] = attribute_value;
                }
            }
        }
        return pendoAttributes;
    }
}