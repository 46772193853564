import { UIRouterState } from '@ajs/ajs-upgraded-providers';
import AppStateService from '@ajs/services/AppStateService';
import ImpersonateUserService from '@ajs/services/ImpersonateUserService';
import FdxUI from '@ajs/services/fdxUI';
import { Component, HostBinding, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { getEncodedUrlPath } from '@app/core/functions';
import { WINDOW } from '@app/core/providers/window.provider';
import { FdxBlockUIService } from '@app/core/services/fdx-block-ui.service';
import { FdxUtilsService } from '@app/core/services/fdx-utils.service';
import { LinkService } from '@app/core/services/link.service';
import { AbstractAppMenuComponent } from '@app/modules/nav/components/abstract-app-menu/abstract-app-menu.controller';
import { InvitationsService } from '@app/user/services/invitations.service';
import { UserDataService } from '@app/user/services/user-data.service';
import { IconDefinition, faBellRing, faTable, faWrench } from '@fortawesome/pro-solid-svg-icons';
import { StateService } from '@uirouter/angularjs';

@Component({
    selector: 'fdx-app-ebay-menu',
    templateUrl: './app-ebay-menu.component.html',
    styleUrls: ['./app-ebay-menu.component.scss']
})
export class AppEbayMenuComponent extends AbstractAppMenuComponent {
    @HostBinding('class.sticky-top')
    readonly stickyTop: boolean = true;
    
    readonly productsIcon: IconDefinition = faTable;
    readonly feedSupportIcon: IconDefinition = faWrench;
    readonly notificationIcon: IconDefinition = faBellRing;

    get logo(): string {
        return '/assets/img/brands/ebay-white.svg';
    }

    get isFeedSupport(): boolean {
        return this.title.getTitle().startsWith('FeedSupport');
    }

    get currentPath(): string {
        return getEncodedUrlPath() ?? '';
    }

    constructor(
        @Inject(UIRouterState) protected readonly $state: StateService,   // Temporary for use with Ebay Selection and User Access Control pages being on AngularJS router
        protected readonly linkService: LinkService,
        protected readonly appStateService: AppStateService,
        protected readonly fdxBlockUIService: FdxBlockUIService,
        protected readonly fdxUI: FdxUI,
        protected readonly fdxUtils: FdxUtilsService,
        protected readonly impersonateUserService: ImpersonateUserService,   // TODO: Upgrade to Angular
        protected readonly invitationsService: InvitationsService,
        protected readonly userDataService: UserDataService,
        protected readonly router: Router,
        @Inject(WINDOW) protected readonly window: Window,
        private readonly title: Title
    ) {
        super($state, linkService, appStateService, fdxBlockUIService, fdxUI, fdxUtils, impersonateUserService, invitationsService, userDataService, router, window);
    }

    protected reloadAfterClientDemoViewToggle(): void {
        void this.router.navigate(this.linkService.feedonomicsLink);
    }

    shortenEmail(username: string | null): string | null {
        if (!username) {
            return username;
        }

        const emailLength = username.indexOf('@');

        if (emailLength < 15 && emailLength > 0) {
            return username.substring(0, emailLength);
        } else if (username.length > 15) {
            return username.substring(0, 15);
        }
        return username;
    }
}
