import { Injectable } from '@angular/core';
import { ColumnStatsChildRowData, ColumnStatsColumnEnum, ColumnStatsParentRowData } from '@app/analyze-data/types/column-stats.types';
import { CategoryPaths } from '@app/category-taxonomy/services/responses/category-paths.response';
import { AnalyzeFieldsByNameResponse, FieldUniqueValueCounts, GroupedFieldUniqueValue, GroupedFieldUniqueValueCounts } from '@app/databases/services/responses/analyze-fields-by-name.response';

@Injectable({
    providedIn: 'root'
})
export class ColumnStatsUtilitiesService {

    /**
     * Convert the response from the Analyze Data API call into an array we can utilize for column stats purposes.
     * If we're only analyzing one column, the data gets returned in a single-dimensional array. If we're analyzing
     * two columns, the data gets returned in a multi-dimensional array in order to be used with AG Grid master/detail.
     * @param response - the response from the Analyze Data API call
     * @returns The formatted data with optional children for master/detail rows
     */
    convertToRowDataType(response: AnalyzeFieldsByNameResponse): {
        parentRowData: ColumnStatsParentRowData[],
        missingCount: number,
        invalidCount: number
    } {
        const column1UniqueValues: FieldUniqueValueCounts | GroupedFieldUniqueValueCounts = response.unique_values;
        const column1CategoryPaths: CategoryPaths = response.category_paths;
        const invalidCategories: string[] = response.invalid_category_values;

        const column1Keys: string[] = Object.keys(column1UniqueValues);
        const firstUniqueValue: number = Number(column1UniqueValues[column1Keys[0]]);
        const isFirstUniqueValueNumeric: boolean = !isNaN(firstUniqueValue);

        let missingCount: number = 0;
        let invalidCount: number = 0;

        if (isFirstUniqueValueNumeric) { // we're only analyzing column1
            // format the data when there are no nested groups
            const parentRowData = Object.keys(column1UniqueValues)
                .map((columnNameKey: string) => {
                    const count = Number(column1UniqueValues[columnNameKey]);
                    const invalid = invalidCategories?.includes(columnNameKey);

                    if (invalid && columnNameKey === '') {
                        missingCount += count;
                    } else if (invalid) {
                        invalidCount += count;
                    }

                    return <ColumnStatsParentRowData>{
                        [ColumnStatsColumnEnum.UniqueValue]: {
                            value: columnNameKey,
                            categoryPaths: column1CategoryPaths ? column1CategoryPaths[columnNameKey] : null   // Will map "undefined" as the category path array if not found
                        },
                        [ColumnStatsColumnEnum.Count]: count,
                        [ColumnStatsColumnEnum.Invalid]: invalid
                    };
                });

            return {
                parentRowData,
                missingCount,
                invalidCount
            }
        }

        // we're analyzing column1 and column2
        // format the data when there ARE nested groups
        const parentRowData = Object.keys(column1UniqueValues)
            .map((columnNameKey: string) => {
                const columnNameValue: GroupedFieldUniqueValue = column1UniqueValues[columnNameKey] as GroupedFieldUniqueValue;

                const formattedGroupByData: ColumnStatsChildRowData[] = Object.keys(columnNameValue.unique_values).map((groupByKey) => {
                    const groupByCount = Number(columnNameValue.unique_values[groupByKey]);
                    return <ColumnStatsChildRowData>{
                        [ColumnStatsColumnEnum.UniqueValue]: groupByKey,
                        [ColumnStatsColumnEnum.Count]: groupByCount
                    };
                });

                const count = columnNameValue.total_count;
                const invalid = invalidCategories?.includes(columnNameKey);

                if (invalid && columnNameKey === '') {
                    missingCount += count;
                } else if (invalid) {
                    invalidCount += count;
                }

                return <ColumnStatsParentRowData>{
                    [ColumnStatsColumnEnum.UniqueValue]: {
                        value: columnNameKey,
                        categoryPaths: column1CategoryPaths ? column1CategoryPaths[columnNameKey] : null  // Will map "undefined" as the category path array if not found
                    },
                    [ColumnStatsColumnEnum.Count]: count,
                    [ColumnStatsColumnEnum.Invalid]: invalid,
                    children: formattedGroupByData
                };
            });

        return {
            parentRowData,
            missingCount,
            invalidCount
        }
    }
}
