<div
    class="row mb-3 g-0"
    *ngIf="fields">
    <div class="col-11 offset-1">
        <fdx-search-bar
            size="md"
            class="ms-auto"
            placeholder="Search variable or export name"
            (valueChange)="updateFilteredFields($event)"
            [resultsFound]="filteredFieldsCount"
            [showResults]="true">
        </fdx-search-bar>
    </div>
</div>

<div class="row mb-1 g-0">
    <div class="offset-1 col-5">
        <strong>Variable name</strong>
    </div>
    <div [ngClass]="getExportFieldClass()">
        <strong>Export name</strong>
    </div>
</div>

<div class="d-flex flex-column flex-grow-1">
    <cdk-virtual-scroll-viewport
        cdkDropList
        [cdkDropListData]="fields"
        (cdkDropListDropped)="drop($event)"
        [itemSize]="itemSize"
        [minBufferPx]="bufferPx"
        [maxBufferPx]="bufferPx"
        class="virtual-viewport export-fields"
        *ngIf="showFieldsList"
        [ngClass]="{'invisible': scrollingFieldsList}">
        <div
            cdkDrag
            *cdkVirtualFor="let field of filteredFields; let i = index; let last = last"
            class="py-1 row export-field"
            [attr.id]="getDragItemId(i)"
            (cdkDragStarted)="dragStarted($event)"
            [cdkDragDisabled]="searchActive">
            <div class="col-1 g-0">
                <div
                    class="icon-ctnr"
                    *ngIf="!searchActive">
                    <fa-icon
                        [icon]="sortAltIcon"
                        size="lg"></fa-icon>
                </div>
            </div>
            <div class="col-5">
                <ng-select
                    name="db-field"
                    [ngModel]="getFieldValue(field)"
                    (ngModelChange)="setFieldValue($event, field)"
                    (change)="fieldsChangeHandler()"
                    placeholder="Select / search for a field"
                    dropdownPosition="bottom"
                    [virtualScroll]="true"
                    appendTo="body"
                    [items]="dbFields"
                    bindLabel="field_name"
                    bindValue="field_name">
                </ng-select>
            </div>
            <div [ngClass]="getExportFieldClass()" class="d-flex flex-align-center">
                <input
                    type="text"
                    name="export-field"
                    class="form-control"
                    [(ngModel)]="field.export_field_name"
                    (change)="onInputChangeHandler(i)">
                <button
                    type="button"
                    class="btn btn-link text-secondary"
                    [ngClass]="{'invisible': last}"
                    (click)="removeField(field)">
                    <fa-icon
                        [icon]="xIcon"
                        size="lg"
                        class="no-margin" >
                    </fa-icon>
                </button>
            </div>
            <div
                class="col-2"
                *ngIf="showRequired">
                <span [ngClass]="getColor(field.required)">{{ field.required}}</span>
            </div>
        </div>
    </cdk-virtual-scroll-viewport>
    <fdx-spinner
        class="position-absolute align-self-center"
        *ngIf="!showFieldsList || scrollingFieldsList"></fdx-spinner>
</div>
