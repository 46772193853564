/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { isEmpty } from '@app/core/functions/isEmpty';
import { CARRIAGE_RETURN, CARRIAGE_RETURN_AND_NEWLINE, NEWLINE } from '@app/core/models/constants/app-constants';
import { LogService } from '@app/core/services/log.service';
import { BaseInputComponent } from '@app/modules/inputs/components/base-input/base-input.component';
import { InputsUtilitiesService } from '@app/modules/inputs/services/inputs-utilities.service';
import { NgSelectComponent } from '@feedonomics/ng-select';
import { IconDefinition, faTimes } from '@fortawesome/pro-light-svg-icons';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs';

export enum InputFieldOrValueItemGroupEnum {
    Field = 'Field',
    Default = ''
}

export type InputFieldOrValueItemType = {
    group: InputFieldOrValueItemGroupEnum,
    display: string,
};

@Component({
    selector: 'fdx-input-field-or-value',
    providers: [{
        provide: BaseInputComponent,
        useExisting: InputFieldOrValueComponent
    }],
    templateUrl: './input-field-or-value.component.html',
    styleUrls: ['./input-field-or-value.component.scss']
})
export class InputFieldOrValueComponent extends BaseInputComponent implements OnInit, OnChanges, AfterViewInit {

    @ViewChild('select') ngSelect: NgSelectComponent;
    @ViewChild('select', { read: TemplateRef }) ngSelectTemplate: TemplateRef<NgSelectComponent>;
    @ViewChild('select', { read: ElementRef }) ngSelectElRef: ElementRef<HTMLElement>;

    @Input() fields: string[];

    items: InputFieldOrValueItemType[] = [];

    // eslint-disable-next-line @typescript-eslint/typedef
    @Input() fGroup;

    lastSearchedTerm: string;
    searchingField: boolean = false;
    noFieldResults: boolean = false;

    hovering: boolean = false;
    @Input() dragging?: boolean = false;
    @Input() removable?: boolean = false;
    @Input() multiple?: boolean = false;
    @Output() readonly clearClicked?: EventEmitter<void> = new EventEmitter<void>();

    xIcon: IconDefinition = faTimes;

    minWidth: number = 150;
    width: number = this.minWidth;
    widthOffset: number = 25;

    get shouldHideRemoveField(): boolean {
        return !this.removable || !this.hovering || this.dragging || this.ngSelect?.isOpen || (Object.keys(this.fGroup.parent.controls)).length === 1;
    }

    get emptySearchTerm(): boolean {
        return this.ngSelect.searchTerm === null || this.ngSelect.searchTerm.length < 1;
    }

    constructor(
        protected readonly element: ElementRef<HTMLElement>,
        protected readonly inputsUtilitiesService: InputsUtilitiesService,
        protected readonly logService: LogService,
        protected readonly ngbDateParserFormatter: NgbDateParserFormatter
    ) {
        super(element, inputsUtilitiesService, logService, ngbDateParserFormatter);
        this.inputType = 'input-field-or-value';
    }

    ngOnInit(): void {
        if (isEmpty(this.placeholder)) {
            this.placeholder = 'Enter field or value';
        }

        this.initClasses['form-control-ng-select fdx-combobox'] = true;

        this.fGroup.valueChanges.pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe({
            next: (value) => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                const newValue = value[this.controlName];
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                if (!Array.isArray(newValue) && this.isField(newValue)) {
                    this.classes['fdx-select-field'] = true;
                } else {
                    this.classes['fdx-select-field'] = false;
                }
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.fields) {
            this.items = this.fields.map((display: string) => {
                return {
                    group: InputFieldOrValueItemGroupEnum.Field,
                    display: display
                };
            });
        }

        if (changes.dragging && this.dragging) {
            this.ngSelect.isOpen = false;
        }

        if (changes.multiple && changes.multiple.previousValue !== changes.multiple.currentValue) {
            setTimeout(() => { // Patch selection needs to run after ng-select updates to multiple mode
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                this.patchSelection(this.multiple ? (this.control.value ?? []) : null);
            });
        }
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();

        if (this.removable) {
            this.width = Number(this.ngSelectElRef.nativeElement.querySelector<HTMLElement>('.ng-value-label')?.offsetWidth) + this.widthOffset;
            if (isNaN(this.width)) {
                this.width = this.minWidth;
            }
            this.styles['width'] = `${Math.max(this.minWidth, this.width)}px`;
        }

        this.lastSearchedTerm = this.value;

        if (!this.multiple && this.isField(this.value)) {
            this.classes['fdx-select-field'] = true;
        }
    }

    isField(value: string): boolean {
        return value?.startsWith('[');
    }

    patchSelection(selection: InputFieldOrValueItemType | InputFieldOrValueItemType[] | string | string[]): void {
        let newValue = null;
        if (typeof selection === 'string') {
            newValue = this.multiple ? [selection] : selection;
        } else if (Array.isArray(selection)) {
            newValue = selection.length > 0 ? selection.map((item: InputFieldOrValueItemType | string) => typeof item === 'string' ? item : item.display) : [];
        } else if (selection) {
            newValue = selection.display;
        }
        this.control.patchValue(newValue);

        const newControlValue = this.control.value;
        const newSearchTerm = Array.isArray(newControlValue) ? null : newControlValue;
        this.lastSearchedTerm = newSearchTerm;
        this.ngSelect.searchTerm = newSearchTerm;
    }

    searchEvent(search: { term: string; items: InputFieldOrValueItemType[]; }): void {
        if (search.term.length < 1) {
            this.ngSelect.isOpen = false;
        }

        if (this.removable) {   // Clearable version is the basic transformer input, which should be expandable
            this.width = Number(this.ngSelectElRef.nativeElement.querySelector<HTMLElement>('.fdx-search-term')?.offsetWidth) + this.widthOffset + 10;
            if (isNaN(this.width)) {
                this.width = this.minWidth;
            }
            this.styles['width'] = `${Math.max(this.minWidth, this.width)}px`;
        }

        if (this.isField(search.term)) {
            this.classes['fdx-select-field'] = true;
            if (search.items.length === 0) {
                this.noFieldResults = true;
            } else {
                this.noFieldResults = false;
            }
            this.searchingField = true;
        } else {
            this.classes['fdx-select-field'] = false;
            this.noFieldResults = false;
            this.searchingField = false;
        }
        this.classes['fdx-select-field-no-tag-template'] = !this.noFieldResults && this.searchingField;

        this.lastSearchedTerm = search.term;
    }

    blurEvent(): void {
        let newValue = null;
        if (this.multiple) {
            const oldValue: string[] = this.control.value;
            if (!isEmpty(this.lastSearchedTerm)) {    // If we searched something last time,
                if (!oldValue.includes(this.lastSearchedTerm)) {
                    oldValue.push(this.lastSearchedTerm);            // add it to the list of values if it doesn't exist already
                }
                newValue = oldValue;
            } else {
                return;                                             // otherwise, don't change anything
            }
        } else if (!isEmpty(this.lastSearchedTerm)) {
            newValue = this.lastSearchedTerm;
        }

        this.control.patchValue(newValue);
    }

    openEvent(): void {
        if (this.emptySearchTerm) {
            this.ngSelect.isOpen = false;
        }

        if (this.ngSelect.searchTerm?.startsWith(' ')) {
            this.ngSelect.filter(this.ngSelect.searchTerm);
        }
    }

    keydownEvent(event: KeyboardEvent): void {
        const space: string = ' ';
        if (event.key === space && this.emptySearchTerm) {
            this.ngSelect.searchTerm = space;
            this.lastSearchedTerm = space;
            this.ngSelect.filter(space);
        }
    }

    /**
     * Handle the pasting action of items to automatically split pasted data into multiple new items
     * if we're in "multiple" mode. Takes pasted data that includes newline characters and splits them
     * into multiple new items, then handles the addition of these items into the control manually.
     * https://feedonomics.atlassian.net/browse/FP-9459
     * @param event The clipboard event fired from the paste action
     */
    onPaste(event: ClipboardEvent): void {
        const clipboardData = event.clipboardData;
        let pastedData = clipboardData?.getData('Text');

        // Check that we're in multiple mode, that the pastedData exists, and it includes a carriage return or newline character
        if (this.multiple && pastedData && (pastedData.includes(CARRIAGE_RETURN) || pastedData.includes(NEWLINE))) {
            // If so, handle the pasting action ourselves
            event.stopPropagation();
            event.preventDefault();

            // Determine which delimiter we're going to attempt to split on.
            let delimiter: string;
            if (pastedData.includes(CARRIAGE_RETURN_AND_NEWLINE)) {
                delimiter = CARRIAGE_RETURN_AND_NEWLINE;
            } else if (pastedData.includes(CARRIAGE_RETURN)) {
                delimiter = CARRIAGE_RETURN;
            } else {
                delimiter = NEWLINE;
            }

            /**
             * If the pasted data ends with a the delimiter (most likely from Excel),
             * then chop it off before splitting the string.
             */
            if (pastedData.endsWith(delimiter)) {
                pastedData = pastedData.slice(0, -(delimiter.length));
            }
            const newItems: string[] = pastedData.split(delimiter);

            const values: Set<string> = new Set(this.control.value);

            for (const item of newItems) {
                // If the item doesn't exist in the set and the value of the item isn't empty, add it
                if (!values.has(item) && !isEmpty(item)) {
                    values.add(item);
                }
            }

            this.patchSelection(Array.from(values));
        }
    }

    clickedClear(): void {
        this.clearClicked.emit();
    }
}
