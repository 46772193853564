<div class="mb-2">
    Modifying the current transformer field <mark>[{{transformerFieldDependency.dependency_map[0]?.name}}]</mark> will affect
    {{transformerFieldDependency.dependent_fields.length}} dependent
    {{transformerFieldDependency.dependent_fields.length !== 1 ? 'fields' : 'field'}}:
</div>
<div class="mx-3">
    <tree-root
        [nodes]="transformerFieldDependency.dependency_map[0]?.children"
        [options]="options">
    </tree-root>
</div>
