<span class="cron-viewer-days text-dark">
    {{ getScheduledDays() }}
</span>

<div
    class="d-flex cron-viewer-hours"
    [ngClass]="classes"
    *ngFor="let hour of hours; trackBy: trackByValue">
    <ng-container *ngIf="!displaySettingsTimeZone">
        <span class="cron-viewer-sever-time mt-1 text-secondary">{{ hour.local }}</span>
    </ng-container>
    <ng-container *ngIf="displaySettingsTimeZone">
        <span class="cron-viewer-local-time mt-1">{{ hour.local }}</span>
        <span class="cron-viewer-divider mt-1 mx-1 text-secondary">|</span>
        <span class="cron-viewer-server-time mt-1 text-secondary">{{ hour.server }}</span>
    </ng-container>
</div>
