import { BootstrapThemeProp } from '@app/core/services/bootstrap-theme.service';
interface ResolverInterface {
	modalTheme: BootstrapThemeProp,
	title: string,
	body: string,
	confirmText: string,
	cancelText: string,
	enableHtml: boolean
}

export default class FdxConfirmationModalController {

	// from uibmodal
	private close: (result?: { $value }) => void;
	private dismiss: (result?: { $value: string }) => void;
	private resolve: ResolverInterface;

	public modalTheme: BootstrapThemeProp = 'primary';
	public title: string = 'Are you sure?';
	public body: string = '';
	public confirmText: string = 'Ok';
	public cancelText: string = 'Cancel';
	public enableHtml: boolean = false;

	static $inject = [];

	constructor() { }

	$onInit() {
		if (!this.resolve) {
			return;
		}

		if (this.resolve.modalTheme) {
			this.modalTheme = this.resolve.modalTheme;
		}

		if (this.resolve.title) {
			this.title = this.resolve.title;
		}

		if (this.resolve.body) {
			this.body = this.resolve.body;
		}

		if (this.resolve.confirmText) {
			this.confirmText = this.resolve.confirmText;
		}

		if (this.resolve.cancelText) {
			this.cancelText = this.resolve.cancelText;
		}

		if (this.resolve.enableHtml) {
			this.enableHtml = this.resolve.enableHtml;
		}
	}

	public onSubmit(): void {
		this.close();
	}

	public onCancel(): void {
		this.dismiss();
	}
}
