import * as angular from 'angular';
import { MODULE_NAME } from './config/constants';

import { UserModel } from './models/user';

angular.module(MODULE_NAME).controller('MainController',
	['$scope', '$location', '$http', '$state', '$timeout', 'AppStateService', 'fdxUI',
	function($scope, $location, $http, $state, $timeout, AppStateService, fdxUI) {

	fdxUI.setTitle('Client List');
	$scope.user = {
		user_fetched: false,
		user_id: '',
		user_name: '',
		logo: '',
		role: '',
	};
	$scope.missing_feedsupport_id=false;
	$scope.need_migration=false;
	$scope.checking_permissions=false;

	// user isn't guaranteed to exist yet because this controller isn't backed by a resolver, so check existence
	$scope.accountHasFeature = (feature, value) => AppStateService.accountHasFeature(feature, value);
	$scope.userHasFeature = (feature, value) => AppStateService.getUser()?.hasFeature(feature, value);
	$scope.userIsInternal = () => AppStateService.getUser()?.isInternal();

	$scope.nav = {
		'dashboards': false,
		'imports': false,
		'data': false,
		'transformers': false,
		'exports': false,
		'reports': false,
		'settings': false
	};

	$scope.primaryUser = function(){
		return $scope.primary_user_flag===true;
	}

	$scope.missingFeedSupportId = function(){
		return $scope.missing_feedsupport_id;
	}

	$scope.needMigration = function() {
		return $scope.need_migration;
	}

	$scope.updateFeedSupportId = function(status){
		$scope.missing_feedsupport_id = status;
	}

	$scope.pendingInvitations = function(){
		return $scope.pending_permissions > 0;
	}

	$scope.lowerToast = function() {
        if( $state.current?.data?.lowerToast === true ){
        	return true;
        }
        return false;
	}

	// Required for navbar links to maintain the proper db id for their links (value set from view controllers)
	$scope.current_db = {};

    function setDatabase(data) {

        if( ! data ) {
            $scope.current_db = {};
			return;
        }

		$scope.current_db = data;

	}

	// Set the username (legacy)
	// data: UserModel
	function setUser(data) {

        if( ! data ) {
            $scope.user = new UserModel({
                user_fetched: false,
                user_id: '',
                user_name: '',
                logo: '',
                role: '',
            });
            $scope.missing_feedsupport_id = false;
            $scope.need_migration = false;
            return;
        }

        $scope.user = data;
        $scope.user.user_fetched = true;

        if (data.zoho_contact_id == '') {
            $scope.missing_feedsupport_id = true;
        }

        // Check if the user_name is a valid email. If not they have not been migrated.
        var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!re.test(String($scope.user.user_name).toLowerCase())) {
            $scope.need_migration = true;
        }

        $scope.$broadcast('userLoaded');

    }

    // update $scope.user on change
    $scope.$watch(() => {
        return AppStateService.getUser();
    }, () => {
        setUser(AppStateService.getUser());
    });

    // update $scope.current_db on change
    $scope.$watch(() => {
        return AppStateService.getDatabase();
    }, () => {
        setDatabase(AppStateService.getDatabase())
    });

	$scope.$watch(() => {
		return $state.current.data?.fitIframe;
	}, (fitIframe) => {
		$scope.fitIframe = fitIframe;
	});

}]);
