<input
    [(ngModel)]="value"
    (ngModelChange)="onChange($event)"
    (blur)="onTouched()"
    [ngClass]="inputClasses"
    [ngStyle]="styles"
    type="radio"
    [id]="id"
    [name]="controlName"
    [value]="controlValue"
    [attr.title]="title ? title : null"
    [attr.aria-label]="ariaLabel ? labelText: null"
    [attr.required]="control.errors?.['required'] ? 'required' : null" />
<fdx-validation [control]="control"
    [labelText]="labelText"
    [customValidationText]="customValidationText"
    [submitted]="form.submitted"
    [ngClass]="{'invalid-feedback': !tooltipValidation, 'invalid-tooltip': tooltipValidation}"
    [showIcon]="true"
></fdx-validation>
