<span>
    <ng-content select="[prefix]">
    </ng-content>
</span>

<span>{{ timeToDisplay }}</span>

<span>
    <ng-content select="[sufix]">
    </ng-content>
</span>
