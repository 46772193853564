<modal-hoc modalTitle="Confirm Notification Email"
			submitText="Confirm"
			(modalSubmit)="onModalSubmit($event)"
			(modalCancel)="onModalCancel($event)">
	<ng-container>
		<form [formGroup]="notificationAddressForm" #form="ngForm">
			<p>Send email when export has successfully pushed to <strong>{{ derivedChannelDestination }}</strong>.</p>
			<p>Please confirm this is correct before proceeding.</p>
			<div class="input-group">
				<span class="input-group-text">
					<fa-icon [icon]="iconEnvelope" aria-label="Envelope" class="no-margin"></fa-icon>
				</span>
				<input
					type="text"
					class="form-control text-right"
					placeholder="email"
					formControlName="notificationAddress">
				<span class="input-group-text">@feedonomics.com</span>
			</div>
		</form>
	</ng-container>
</modal-hoc>
