import { Injectable } from '@angular/core';
import { faCalendarBolt } from '@app/core/font-awesome-icons/calendar-bolt';
import { ScheduleStatusEnum } from '@app/core/models/enums/schedule-status.enum';
import { BootstrapThemeProp } from '@app/core/services/bootstrap-theme.service';
import { Item } from '@feedonomics/frontend-components';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCircle as faCircleOutline } from '@fortawesome/pro-regular-svg-icons';
import { faCircle, faCircleBolt, faCircleCalendar, faCirclePause } from '@fortawesome/pro-solid-svg-icons';

export type ScheduleConfig = {
    theme: BootstrapThemeProp,
    icon: IconDefinition,
    text: string
};

export const ALL_STATUSES: Map<ScheduleStatusEnum, ScheduleConfig> = new Map<ScheduleStatusEnum, ScheduleConfig>([
    [ScheduleStatusEnum.ACTIVE_SCHEDULED, {
        theme: 'success',
        icon: faCircleCalendar,
        text: 'Active, scheduled'
    }],
    [ScheduleStatusEnum.ACTIVE_SCHEDULED_AND_TRIGGERED, {
        theme: 'success',
        icon: faCalendarBolt,
        text: 'Active, scheduled & triggered'
    }],
    [ScheduleStatusEnum.ACTIVE_TRIGGERED, {
        theme: 'success',
        icon: faCircleBolt,
        text: 'Active, triggered'
    }],
    [ScheduleStatusEnum.ACTIVE_UNSCHEDULED, {
        theme: 'secondary',
        icon: faCircleOutline,
        text: 'Inactive'
    }],
    [ScheduleStatusEnum.PAUSED_SCHEDULED, {
        theme: 'secondary',
        icon: faCirclePause,
        text: 'Paused'
    }],
    [ScheduleStatusEnum.PAUSED_UNSCHEDULED, {
        theme: 'secondary',
        icon: faCirclePause,
        text: 'Paused'
    }]
]);

/**
 * All status as an Item array (for use in fsc-multiselect)
 * Currently unused, since we're using the more streamlined one below.
 */
export const ALL_STATUSES_AS_ITEMS: Item[] = Array.from(ALL_STATUSES.entries()).map<Item>((status: [ScheduleStatusEnum, ScheduleConfig]) => {
    const value = status[1];
    return {
        icon: value.icon,
        iconClass: `text-${value.theme}-500`,
        iconFixedWidth: true,
        value: value.text,
        label: value.text,
        text: value.text
    }
});

/**
 * Probably temporary enum for use with below array
 */
enum MasterStatus {
    ACTIVE = 'Active',
    INACTIVE = 'Inactive',
    PAUSED = 'Paused'
}

/**
 * Generic status filters for imports or exports, probably temporary.
 * Design opted to go with the more generic list of status options at
 * the moment, but we may end up switching to use ALL_STATUSES_AS_ITEMS
 * instead.
 */
export const STATUS_FILTERS: Item[] = [
    {
        value: MasterStatus.ACTIVE,
        label: MasterStatus.ACTIVE,
        icon: faCircle,
        iconClass: 'text-success'
    },
    {
        value: MasterStatus.INACTIVE,
        label: MasterStatus.INACTIVE,
        icon: faCircleOutline,
        iconClass: 'text-secondary'
    },
    {
        value: MasterStatus.PAUSED,
        label: MasterStatus.PAUSED,
        icon: faCirclePause,
        iconClass: 'text-secondary'
    }
];

@Injectable({ providedIn: 'root' })
export class ScheduleStatusService {

    /**
     * Returns the proper BootstrapThemeProp value for the given schedule and paused statuses
     * @param scheduleType the schedule type
     * @returns the BootstrapThemeProp to designate to this status
     */
    getTheme(scheduleType: ScheduleStatusEnum): BootstrapThemeProp {
        return ALL_STATUSES.get(scheduleType).theme;
    }

    /**
     * Returns the status icon for the given configuration
     * @param hasSchedule whether or not this entity has a schedule
     * @param hasFtpTriggers whether or not this entity has FTP triggers
     * @param paused whether or not this entity is paused
     * @returns the correct status icon
     */
    getStatusIcon(hasSchedule: boolean, hasFtpTriggers: boolean, paused: boolean): IconDefinition {
        const status = this.getStatus(hasSchedule, hasFtpTriggers, paused);
        return ALL_STATUSES.get(status).icon;
    }

    /**
     * Returns the status icon class for the given configuration
     * @param hasSchedule whether or not this entity has a schedule
     * @param hasFtpTriggers whether or not this entity has FTP triggers
     * @param paused whether or not this entity is paused
     * @returns the correct status icon class
     */
    getStatusIconClass(hasSchedule: boolean, hasFtpTriggers: boolean, paused: boolean): string {
        const status = this.getStatus(hasSchedule, hasFtpTriggers, paused);
        return `text-${this.getTheme(status)}-500`;
    }

    /**
     * Returns the status text for the given configuration
     * @param hasSchedule whether or not this entity has a schedule
     * @param hasFtpTriggers whether or not this entity has FTP triggers
     * @param paused whether or not this entity is paused
     * @returns the correct status text
     */
    getStatusText(hasSchedule: boolean, hasFtpTriggers: boolean, paused: boolean): string {
        const status = this.getStatus(hasSchedule, hasFtpTriggers, paused);
        return ALL_STATUSES.get(status).text;
    }

    /**
     * Returns the ScheduleStatusEnum for the given configuration.
     * @param hasSchedule whether or not this entity has a schedule
     * @param hasFtpTriggers whether or not this entity has FTP triggers
     * @param paused whether or not this entity is paused
     * @returns the ScheduleStatusEnum value
     */
    getStatus(hasSchedule: boolean, hasFtpTriggers: boolean, paused: boolean): ScheduleStatusEnum {
        if (paused) {
            if (hasSchedule) {
                return ScheduleStatusEnum.PAUSED_SCHEDULED;
            }
            return ScheduleStatusEnum.PAUSED_UNSCHEDULED;
        }

        if (!hasSchedule && !hasFtpTriggers) {
            return ScheduleStatusEnum.ACTIVE_UNSCHEDULED;
        }

        if (hasSchedule && !hasFtpTriggers) {
            return ScheduleStatusEnum.ACTIVE_SCHEDULED;
        }

        if (!hasSchedule && hasFtpTriggers) {
            return ScheduleStatusEnum.ACTIVE_TRIGGERED;
        }

        return ScheduleStatusEnum.ACTIVE_SCHEDULED_AND_TRIGGERED;
    }

    /**
     * Determines whether or not this entity passes any of the status filters, given the configuration
     * @param hasSchedule whether or not this entity has a schedule
     * @param hasFtpTriggers whether or not this entity has FTP triggers
     * @param paused whether or not this entity is paused
     * @param selectedStatuses the selected statuses from the filters array. Currently, should contain values from STATUS_FILTERS
     * @returns if this entity passes the given status filters
     */
    statusPassesFilter(hasSchedule: boolean, hasFtpTriggers: boolean, paused: boolean, selectedStatuses: string[]): boolean {
        if (selectedStatuses.length === STATUS_FILTERS.length || selectedStatuses.length === 0) {
            return true;
        }

        if (selectedStatuses.some((status) => status === MasterStatus.PAUSED) && paused) {
            return true;
        }

        if (selectedStatuses.some((status) => status === MasterStatus.ACTIVE) && (hasSchedule || hasFtpTriggers) && !paused) {
            return true;
        }

        if (selectedStatuses.some((status) => status === MasterStatus.INACTIVE) && !(hasSchedule || hasFtpTriggers) && !paused) {
            return true;
        }

        return false;
    }
}
