import { Injectable } from '@angular/core';
import { WebhookType } from '@app/exports/data/webhook-type';

interface ExportField {
    field_name: string;
    export_field_name: string;
    sort_order: number;
}

interface CategoryFieldsExport {
    delimiter: string;
    enclosure: string;
    escape: string;
    export_encoding: string;
    export_fields: ExportField[];
    export_format: string;
    file_name: string;
    name: string;
    protocol: string;
    protocol_info: {
        http_method: string;
        http_url: string;
        http_headers: string;
        http_body: string;
    },
    strip_characters: string[];
    webhook_type: string;
}

interface CategoryFieldsExportSeed {
    exportFields: string[];
    useNameForExportHeader: boolean;
    webhookType: WebhookType;
    exportId: string;
}

interface CategoryFieldsExportMeta {
    data: Partial<CategoryFieldsExport>;
    useNameForExportHeader: boolean;
    exportId: string;
}

@Injectable({
    providedIn: 'root'
})
export class CategoryTaxonomyStateService {
    private categoryFieldsExport: CategoryFieldsExportMeta  = null;

    get hasExportData(): boolean {
        return this.categoryFieldsExport !== null;
    }

    getExportData(): CategoryFieldsExportMeta {
        return { ...this.categoryFieldsExport };
    }

    setExportData(exportData: CategoryFieldsExportSeed): void {
        this.categoryFieldsExport = this.makeCategoryFieldExport(exportData);
    }

    clearExportData(): void {
        this.categoryFieldsExport = null;
    }

    makeCategoryFieldExport(seed: CategoryFieldsExportSeed): CategoryFieldsExportMeta {
        const fields = seed.exportFields.map((field) => {
            return {
                field_name: '',
                export_field_name: field,
                sort_order: 0
            };
        });

        return {
            data: {
                export_fields: fields,
                webhook_type: seed.webhookType
            },
            useNameForExportHeader: seed.useNameForExportHeader,
            exportId: seed.exportId
        };
    }
}
