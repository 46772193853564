import { PrivacyLevel } from '@app/user/enums/privacy-level.enum';
import AppStateService from './AppStateService';
import TitleService from './TitleService';

export default class fdxUI {

    public startBlockUI: (message?: string) => void;
    public stopBlockUI: () => void;

    static $inject: string[] = [
        'blockUI',
        'AppStateService',
        'ngToast',
        'TitleService'
    ];

    constructor(
        blockUI: any,
        private readonly appStateService: AppStateService,
        private readonly ngToast: any,
        private readonly titleService: TitleService
    ) {
        this.startBlockUI = blockUI.start;
        this.stopBlockUI = blockUI.stop;
    }

    public setTitle(value: string): void {
        this.titleService.setTitle(value);
    }

    public setActiveTab(value: string): void {
        this.appStateService.activeTab = value;
    }

    public showToastInfo(content: string, dismissOtherToasts: boolean = false, timeout: number = 4000, dismissOnTimeout: boolean = true, titleOverride: string = null): void {
        if (dismissOtherToasts === true) {
            this.ngToast.dismiss();
        }

        this.ngToast.info({
            content: `<i class="fas fa-info-circle ng-toast__icon"></i> <b>${titleOverride ? titleOverride: 'Info'}:</b> ${content}`,
            dismissButton: true,
            dismissOnClick: false,
            dismissOnTimeout: dismissOnTimeout,
            timeout: timeout
        });
    }

    public showToastSuccess(content: string, dismissOtherToasts: boolean = false, timeout: number = 4000, dismissOnTimeout: boolean = true, titleOverride: string = null): void {
        if (dismissOtherToasts === true) {
            this.ngToast.dismiss();
        }

        this.ngToast.success({
            content: `<i class="fas fa-check-circle ng-toast__icon"></i> <b>${titleOverride ? titleOverride: 'Success'}:</b> ${content}`,
            dismissButton: true,
            dismissOnClick: false,
            dismissOnTimeout: dismissOnTimeout,
            timeout: timeout
        });
    }

    public showToastWarning(content: string, dismissOtherToasts: boolean = false, timeout: number = 4000, dismissOnTimeout: boolean = true, titleOverride: string = null): void {
        if (dismissOtherToasts === true) {
            this.ngToast.dismiss();
        }

        this.ngToast.warning({
            content: `<i class="fas fa-exclamation-triangle ng-toast__icon"></i><b>${titleOverride ? titleOverride: 'Warning'}:</b> ${content}`,
            dismissButton: true,
            dismissOnClick: false,
            dismissOnTimeout: dismissOnTimeout,
            timeout: timeout
        });
    }

    public showToastError(content: string, dismissOtherToasts: boolean = false, timeout: number = 4000, dismissOnTimeout: boolean = true, titleOverride: string = null): void {
        if (dismissOtherToasts === true) {
            this.ngToast.dismiss();
        }

        this.ngToast.danger({
            content: `<i class="fas fa-info-circle ng-toast__icon"></i><b>${titleOverride ? titleOverride: 'Error'}:</b> ${content}`,
            dismissButton: true,
            dismissOnClick: false,
            dismissOnTimeout: dismissOnTimeout,
            timeout: timeout
        });
    }

    public showMinimalEbayUI(): boolean {
        const account = this.appStateService.getAccount();
        const user = this.appStateService.getUser();

        const isAccountEbayEnabled = account?.hasFeature('ebay_minimal_ui', 'enabled');
        const isUserEbayEnabled = user?.hasFeature('ebay_minimal_ui', 'enabled');

        const enabledInNormalMode = isAccountEbayEnabled
            && user?.getPrivacyLevel(String(account.id)) === PrivacyLevel.Standard
            // eslint-disable-next-line @typescript-eslint/prefer-string-starts-ends-with
            && !/feedonomics\.com$/.test(user?.user_name)
            && !isUserEbayEnabled;

        const enabledInDemoMode = isAccountEbayEnabled && (user.settings && user.settings['user.demo_client_view']);

        return enabledInNormalMode || enabledInDemoMode;
    }
}

export { fdxUI };
