import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

// Modules
import { PageModule } from '@app/modules/page/page.module';

// Pages
import { KeyboardShortcutsRoutingModule } from '@app/keyboard-shortcuts/keyboard-shortcuts-routing.module';
import { KeyboardShortcutsPageComponent } from './pages/keyboard-shortcuts-page/keyboard-shortcuts-page.component';

@NgModule({
    declarations: [
        KeyboardShortcutsPageComponent,
    ],
    imports: [
        CommonModule,
        KeyboardShortcutsRoutingModule,
        PageModule
    ]
})
export class KeyboardShortcutsModule {
}
