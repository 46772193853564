import AppStateService from './AppStateService';
import ReleaseNotesDataService from './ReleaseNotesDataService';

import { UserDataModel } from '@app/user/models/user-data.model';
import { LatestReleaseNoteDataModel } from '../models/release_notes/LatestReleaseNoteDataModel';

export const releaseNotesLastReadSettingsKey = 'releaseNotes.lastRead';

export default class ReleaseNotesService {
	public unread: boolean = false;
	public latest: string = null;

	static $inject = [
		'AppStateService',
		'ReleaseNotesDataService'
	];

	constructor(
		private AppStateService: AppStateService,
		private ReleaseNotesDataService: ReleaseNotesDataService
	) { }

	public markAsRead(): void {
		this.unread = false;
	}

	public markAsUnread(): void {
		this.unread = true;
	}

	public triggerRead(lastRead: string): void {
		this.markAsRead();

		void this.AppStateService
			.updateUserSetting(
				releaseNotesLastReadSettingsKey,
				lastRead
			);
	}

	public checkUnread(): void {
		this.ReleaseNotesDataService
			.getLatestReleaseNoteDate()
			.then((value: LatestReleaseNoteDataModel) => {
				// if null then there are no release notes to read
				if (!value) {
					this.markAsRead();
					return;
				}

				const releaseDate: string = value.release_date;

				// save latest release date in case we need it for something
				this.latest = releaseDate;

				// get the user from the app state service
				const user: UserDataModel = this.AppStateService.getUser();

				// no user or settings so assume release notes are unread
				if (!(user && user.settings)) {
					this.markAsUnread();
					return;
				}

				const lastRead = user.settings[releaseNotesLastReadSettingsKey];

				// no last checked settings so assume release notes are unread
				if (!lastRead) {
					this.markAsUnread();
					return;
				}

				if (lastRead !== releaseDate) {
					this.markAsUnread();
				} else {
					this.markAsRead();
				}
			})
			.catch((reason: any) => {
				// could not query the latest release note date
				this.markAsRead();
			});
	}
}
