/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { cm_variables } from '@ajs/vendor/feedonomics/utilities';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    forwardRef,
    HostBinding,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LogService } from '@app/core/services/log.service';
import { CodeMirrorModes } from '@app/modules/inputs/enums/code-mirror-modes.enum';
import { AggregatedDbFieldsType } from '@app/modules/inputs/types/aggregated-db-fields.type';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';
import { Editor, ScrollInfo } from 'codemirror';
import { BehaviorSubject, filter, Subject, take, takeUntil, tap } from 'rxjs';

// This component seeks to be a seamless wrapper around ngx-codemirror and use all the same inputs and outputs,
// with the added functionality of autocompletion.
@Component({
    selector: 'fdx-code-input',
    styleUrls: ['./code-input.component.scss'],
    templateUrl: './code-input.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            // eslint-disable-next-line @angular-eslint/no-forward-ref
            useExisting: forwardRef(() => CodeInputComponent),
            multi: true
        }
    ],
    preserveWhitespaces: false,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CodeInputComponent implements ControlValueAccessor, OnChanges, AfterViewInit, OnDestroy {
    @Input() fields: AggregatedDbFieldsType;

    /* class applied to the created textarea */
    @Input() className: string = '';
    /* name applied to the created textarea */
    @Input() name: string = 'codemirror';
    /* autofocus setting applied to the created textarea */
    @Input() autoFocus: boolean = false;
    /**
     * set options for codemirror
     * @link http://codemirror.net/doc/manual.html#config
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() options: { [key: string]: any; };
    /* preserve previous scroll position after updating value */
    @Input() preserveScrollPosition: boolean = false;

    @HostBinding('class.double-min-height')
    @Input() doubleMinHeight?: boolean = false;

    /* called when the text cursor is moved */
    @Output() readonly cursorActivity: EventEmitter<Editor> = new EventEmitter<Editor>();
    /* called when the editor is focused or loses focus */
    @Output() readonly focusChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    /* called when the editor is scrolled */
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() readonly scroll: EventEmitter<ScrollInfo> = new EventEmitter<ScrollInfo>();
    /* called when file(s) are dropped */
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() readonly drop: EventEmitter<[Editor, DragEvent]> = new EventEmitter<[Editor, DragEvent]>();

    @ViewChild(CodemirrorComponent, { static: false })
    private readonly cmComponent: CodemirrorComponent;

    afterViewInitCompleted$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly unsubscribe$: Subject<void> = new Subject<void>();

    get value(): string {
        return this.innerValue;
    }

    set value(val: string) {
        if (val !== this.innerValue) {
            this.innerValue = val;
            this.onChange(val);
        }
    }

    private innerValue: string = '';

    constructor(
        private readonly logService: LogService,
        private readonly ngZone: NgZone
    ) { }

    ngAfterViewInit(): void {
        this.ngZone
            .runOutsideAngular(
                async () => {
                    await this.cmComponent.codeMirrorGlobal;
                    this.afterViewInitCompleted$.next(true);
                }
            )
            .catch(
                (e) => {
                    this.logService.error(
                        '[CodeInputComponent] Failed to runOutsideAngular:',
                        e
                    );
                }
            );
    }

    ngOnChanges({ fields }: SimpleChanges): void {
        if (fields?.currentValue && fields.currentValue !== fields.previousValue) {
            this.afterViewInitCompleted$
                .pipe(
                    filter(
                        (completed) => {
                            return completed;
                        }
                    ),
                    take(1),
                    takeUntil(this.unsubscribe$)
                )
                .subscribe(
                    () => {
                        this.initCodeMirrorAutocomplete(fields.currentValue.autocompleteFields);
                        this.initCodeMirrorMode(fields.currentValue.validFields);
                    }
                );
        }
    }

    ngOnDestroy(): void {
        this.afterViewInitCompleted$.complete();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    initCodeMirrorAutocomplete(autocompleteFields: string[]): void {
        if (autocompleteFields.length !== 0) {
            switch(this.options.mode) {
                case(CodeMirrorModes.Transformer):
                    this.cmComponent.codeMirrorGlobal.registerHelper(
                        'hint',
                        CodeMirrorModes.Transformer,
                        cm_variables.hint_transformer_parser(autocompleteFields)
                    );
                    break;
                case(CodeMirrorModes.GenAI):
                    this.cmComponent.codeMirrorGlobal.registerHelper(
                        'hint',
                        CodeMirrorModes.GenAI,
                        cm_variables.hint_genai(autocompleteFields)
                    );
                    break;
                default:
                    break;
            }

        }

        cm_variables.cmInit()(this.cmComponent.codeMirror);
    }

    initCodeMirrorMode(validFields: string[]): void {
        if (validFields.length !== 0) {
            switch(this.options.mode) {
                case(CodeMirrorModes.Transformer):
                    this.cmComponent.codeMirrorGlobal.defineSimpleMode(
                        CodeMirrorModes.Transformer,
                        cm_variables.mode_transformer_parser(validFields)
                    );
                    this.cmComponent.codeMirror.setOption('mode', CodeMirrorModes.Transformer);
                    break;
                case(CodeMirrorModes.GenAI):
                    this.cmComponent.codeMirrorGlobal.defineSimpleMode(
                        CodeMirrorModes.GenAI,
                        cm_variables.mode_genai(validFields)
                    );
                    this.cmComponent.codeMirror.setOption('mode', CodeMirrorModes.GenAI);
                    break;
                default:
                    break;
            }
        }


    }

    onFocusChange(focusing: boolean): void {
        if (!focusing) {
            this.onTouched();
        }
        this.focusChange.emit(focusing);
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange(_: any): void { }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTouched(): void { }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        // If the component started disabled, that state wouldn't reflect since the code editor
        // didn't exist
        this.afterViewInitCompleted$.pipe(
            filter((completed) => completed),
            take(1),
            tap(() => this.cmComponent?.setOptionIfChanged('readOnly', isDisabled ? 'nocursor' : false)),
            takeUntil(this.unsubscribe$)
        ).subscribe();
    }

    writeValue(value: string): void {
        if (value !== this.innerValue) {
            this.innerValue = value;
            this.cmComponent?.codeMirror.setValue(this.value);  // Trigger change detection when value is changed outside of component
        }
    }

    /**
     * We were running into a problem where codemirror wasn't rendering correctly if data was being set when it was not visible
     * So we're triggering a refresh on the codemirror component when it becomes visible.
     * @param isVisible
     */
    visibilityChange(isVisible: boolean): void {
        if (isVisible) {
            this.cmComponent?.codeMirror.refresh();
        }
    }
}
