export enum TokenizerTransformerTokenEnum {
    Function = 'function',
    OpenParen = 'open_paren',
    Comma = 'comma',
    Addop = 'addop',
    Mulop = 'mulop',
    Num = 'num',
    String = 'string',
    CloseParen = 'close_paren',
    Variable = 'variable',
    End = 'end'
}