<modal-hoc
    modalTitle="Designated Hosts"
    [submitButton]="false"
    [cancelButton]="false"
    (modalCancel)="onModalCancel($event)">
    <p class="row">This export will always be sent from the following IP addresses:<p>

    <div class="row" *ngIf="!hasError">
        <div *ngFor="let host of hosts" class="offset-md-1 col-md-10">{{host}}</div>
    </div>

    <fdx-alert [classes]="{'row mt-2': true}" *ngIf="hasError" theme="danger" title="Error" [body]="errorMessage"></fdx-alert>

    <div *ngIf="loading" class="row">
        <fdx-spinner></fdx-spinner>
    </div>
</modal-hoc>
