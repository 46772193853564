import * as angular from 'angular';
import { MODULE_NAME } from '../../../config/constants';
import FdxGoogleSigninButtonController from './fdx-google-signin-button.controller';

import './fdx-google-signin-button.styles.scss';

export const componentName = 'fdxGoogleSigninButton';

angular.module(MODULE_NAME).component(componentName, {
	controller: FdxGoogleSigninButtonController,
	template: require('./fdx-google-signin-button.template.html'),
	transclude: true,
	bindings: {
		onClick: '&?'
	}
});
