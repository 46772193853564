import * as angular from 'angular';
import * as moment from 'moment';
import { MODULE_NAME } from '../../config/constants';

angular.module(MODULE_NAME).directive('feedwizardShopify', ['$http', '$q', '$rootScope', 'AppStateService', 'FeedWizardService', 'fdxUI', 'ShopifyService', function($http, $q, $rootScope, AppStateService, FeedWizardService, fdxUI, ShopifyService) {
	return {
		restrict: 'E',
		scope: true,
		template: require('./shopify.html'),
		link: function ($scope) {
			const account_id = AppStateService.getAccountId();

			// We are sunsetting the "Old UI" - leaving code in place until all new UI gets full approval
			$scope.showNewUI = true; //ShopifyService.showNewUI(AppStateService.getAccountId());

			$scope.step = 1;

			$scope.previous_step = function () {
				$scope.step--;
			};

			$scope.next_step = function () {
				$scope.step++;
			};

			if ($scope.showNewUI) {
				$scope.data = {
					'example_url': '',
					'store_name': '',
					'app_password': '',
					'url_parse_error': false,
					'db_id': '',
					'db_name': ''
				};

				// Example URL UX is no longer viable / deprecated. This code should be removed when "Old UI" gets formally cleared out as well.
				// $scope.parseExampleUrl = (url) => {
				// 	const parsedUrl = ShopifyService.parseExampleUrl(url);
					
				// 	if (parsedUrl.store_url && parsedUrl.app_password) {
				// 		$scope.data.store_url = parsedUrl.store_url;
				// 		$scope.data.app_password = parsedUrl.app_password;
				// 		$scope.data.url_parse_error = false;
				// 	} else {
				// 		$scope.data.store_url = '';
				// 		$scope.data.app_password = '';
				// 		$scope.data.url_parse_error = true;
				// 	}
				// };
	
				$scope.openHelpModal = () => {
					const modalInstance = ShopifyService.openHelpModal();
	
					modalInstance.then(
						() => {},
						() => {}
					);
				};
			} else {
				// This is the popup used for oauth; to bypass popup blockers we need to open it behind the window
				// immediately on click; Popup is then handled after the ajax request or closed on error
				var popup;

				$scope.data = {
					'store_url': '',
					'store_name': '',
					'db_id': '',
					'scopes': {
						'products': true,
						'orders': false,
						'locations': true,
						'inventory': true
					}
				};
			}

			$scope.shopify_messages = {
				'skip_install': false,
				'hide_instructions': false,
				'show_db_list': true,
				//'building_message':'Collecting information for Shopify authorization!',
				'authorization_message':'Please follow the steps in the popup.',
				'authorization_reopen':'Not seeing it? Click here to open it again.',
				'building_message':'Building! This may take a minute...',
				'finished_message':'Your request has been complete! ',
				'finished_db_list':'Click here to go back to your database list.',
			}

			$scope.shopify_error_message_overrides = {
				'Unable to retrieve store name':'We were unable to retrieve the store name from the url provided. Please check the store url and try again or hit \'Skip Install\' and add comments as to how you would like us to proceed. ',
				'The value of `store_url` must pass the filter provided': 'Shopify Wizard Error - Shopify URL entered is incorrect, please update and re-run the wizard',
			};

			// Responses from Preprocess are block text - not data objects (despite including string format versions WITHIN strings). Check for the specific one we're looking for before following default string behavior
			$scope.shopify_wildcard_error_overrides = {
				'[API] Invalid API key or access token (unrecognized login or wrong password)':'Shopify Wizard Error - Admin API Access Token is incorrect, please update and re-run the wizard ',
			};

			// Used to capture when the wizard completes successfully
			$scope.$parent.wizard_completed_successfully = false;
			$scope.$parent.wizard_skipped = false;

			var on_step_error = function (response) {
				$scope.previous_step();
				if (response && typeof response === 'string') {
					var error_display = response;
					// Look for specific pre-process errors first
					if(response.startsWith('Preprocessing Failed')) {
						for (const [key,value] of Object.entries($scope.shopify_wildcard_error_overrides)) {
							if(response.includes(key)){
								error_display = value;
							}
						}
					}
					fdxUI.showToastError(error_display, false, 30000);
				} 
				else if (response && response.message) {
					var error_display = response.message;
					if (response.message in $scope.shopify_error_message_overrides) {
						error_display = $scope.shopify_error_message_overrides[response.message];
					}

					fdxUI.showToastError(error_display, false, 30000);
				}

				if (popup) {
					popup.close();
					popup = null;
				}
			};

			let steps;

			if ($scope.showNewUI) {
				steps = [
					// Grab store name
					(new FeedWizardStep())
						.setEndpoint(function () {
							return '/api.php/accounts/' + AppStateService.getAccountId() + '/feedwizard/shopify/store_name';
						})
						.setMethod('get')
						.setParams(function () {
							return {
								'params': {
									'store_url': $scope.data.store_url
								}
							};
						})
						.setOnSuccess(function (response) {
							$scope.data.store_name = response.data.store_name;
						})
						.setOnError(on_step_error),

					// Create DB
					(new FeedWizardStep())
						.setEndpoint(function () {
							return '/api.php/accounts/' + AppStateService.getAccountId() + '/dbs';
						})
						.setMethod('post')
						.setParams(function () {
							return {
								'name': ($scope.data['db_name'] ?  $scope.data['db_name'] + ' - ' : '') + 'Shopify Wizard - ' +  moment().format('MM/DD LT')
							};
						})
						.setOnSuccess(function (data) {
							$scope.data.db_id = data.id;
						})
						.setOnError(on_step_error),

					// Create Import
					(new FeedWizardStep())
						.setEndpoint(function () {
							return '/api.php/dbs/' + $scope.data.db_id + '/imports';
						})
						.setMethod('post')
						.setParams(function () {
							var params = {
								"connection_info" : {
									"client":"shopify",
									"oauth_token": $scope.data.app_password,
									"protocol": 'api',
									"shop_name":  $scope.data.store_name,
								}
							};
							var url_params = new URLSearchParams();
							for (var key in params.connection_info) {
								url_params.append('connection_info['+key+']', params.connection_info[key]);
							}
							return {
								'file_location': 'preprocess_script',
								'tags': {'platform': 'Shopify'},
								'file_type': 'delimited',
								'join_type': 'product_feed',
								'name': 'Shopify',
								'url': 'https://haproxy-preprocess.feedonomics.com/preprocess/run_preprocess.php?'+ url_params.toString(),
								'preprocess_info': params,
							};
						})
						.setOnSuccess(function (response) {
							$scope.data.import_id = response.id;
						})
						.setOnError(on_step_error),

					// Update Import Delimiters
					(new FeedWizardStep())
						.setEndpoint(function () {
							return '/api.php/dbs/' + $scope.data.db_id + '/imports/' + $scope.data.import_id + '/delimiters';
						})
						.setParams(function () {
							return {
								'enclosure': '"',
								'encoding': '',
								'escaper': '"',
								'line_terminator': "\n",
								'separator': ','
							};
						})
						.setMethod('put')
						.setOnError(on_step_error),

					// Grab headers to create file map
					(new FeedWizardStep())
						.setEndpoint(function () {
							return '/api.php/dbs/' + $scope.data.db_id + '/imports/' + $scope.data.import_id + '/file?format=parsed&limit=4';
						})
						.setMethod('get')
						.setOnSuccess(function (response) {
							$scope.data.file_map = {};

							response[0].forEach(function (field, index) {
								var header = FeedWizardService.hex2bin(response[0][index]);

								var name = header.replace(/[^\x00-\x7F]/g, "")
									.trim()
									.toLowerCase()
									.replace(/ /g, "_")
									.replace(/-/g, "_");

								$scope.data.file_map[response[0][index]] = name;
							});
						})
						.setOnError(on_step_error),

					// Update Import #1 file map
					(new FeedWizardStep())
						.setEndpoint(function () {
							return '/api.php/dbs/' + $scope.data.db_id + '/imports/' + $scope.data.import_id + '/file_map';
						})
						.setMethod('put')
						.setParams(function () {
							return {
								'maps': $scope.data.file_map,
								'name_based_maps': 1,
								'encode_source_file_keys': 1,
								'clean_file_headers': 0
							};
						})
						.setOnError(on_step_error),
				];
			} else {
				steps = [
					// Grab store name
					(new FeedWizardStep())
						.setEndpoint(function () {
							return '/api.php/accounts/' + AppStateService.getAccountId() + '/feedwizard/shopify/store_name';
						})
						.setMethod('get')
						.setParams(function () {
							return {
								'params': {
									'store_url': $scope.data.store_url
								}
							};
						})
						.setOnSuccess(function (response) {
							$scope.data.store_name = response.data.store_name;
						})
						.setOnError(on_step_error),
	
					// Create DB
					(new FeedWizardStep())
						.setEndpoint(function () {
							return '/api.php/accounts/' + AppStateService.getAccountId() + '/dbs';
						})
						.setMethod('post')
						.setParams(function () {
							return {
								'name': ($scope.data['db_name'] ?  $scope.data['db_name'] + ' - ' : '') + 'Shopify Wizard - ' +  moment().format('MM/DD LT')
							};
						})
						.setOnSuccess(function (data) {
							$scope.data.db_id = data.id;
						})
						.setOnError(on_step_error),
	
					// Generate Authentication URL
					(new FeedWizardStep())
						.setEndpoint(function () {
							return '/api.php/dbs/' + $scope.data.db_id + '/generate_authorization_url';
						})
						.setMethod('get')
						.setParams(function () {
							return {
								'params': {
									'channel': 'shopify',
									'response_type': 'javascript',
									'scopes': {
										'products': $scope.data.scopes.products,
										'orders': $scope.data.scopes.orders,
										'locations': $scope.data.scopes.locations,
										'inventory': $scope.data.scopes.inventory
									},
									'shop_name': $scope.data.store_name
								}
							};
						})
						.setOnSuccess(function (response) {
							$scope.data.oauth_url = response;
	
							popup.location.href = response;
							popup.focus();
						})
						.setOnError(on_step_error),
	
					// Grab the shopify refresh token
					(new FeedWizardStep())
						.setEndpoint(function () {
							return '/api.php/dbs/' + $scope.data.db_id + '/shopify_refresh_token';
						})
						.setMethod('get')
						.setOnSuccess(function (response) {
							$scope.data.refresh_token = response.refresh_token;
						})
						.setOnError(on_step_error),
	
					// Create Import
					(new FeedWizardStep())
						.setEndpoint(function () {
							return '/api.php/dbs/' + $scope.data.db_id + '/imports';
						})
						.setMethod('post')
						.setParams(function () {
							var params = {
								"connection_info" : {
									"client":"shopify",
									"oauth_token": $scope.data.refresh_token,
									"protocol": 'api',
									"shop_name":  $scope.data.store_name,
								}
							};
							var url_params = new URLSearchParams();
							for (var key in params.connection_info) {
								url_params.append('connection_info['+key+']', params.connection_info[key]);
							}
							return {
								'file_location': 'preprocess_script',
								'tags': {'platform': 'Shopify'},
								'file_type': 'delimited',
								'join_type': 'product_feed',
								'name': 'Shopify',
								'url': 'https://haproxy-preprocess.feedonomics.com/preprocess/run_preprocess.php?'+ url_params.toString(),
								'preprocess_info': params,
							};
						})
						.setOnSuccess(function (response) {
							$scope.data.import_id = response.id;
						})
						.setOnError(on_step_error),
	
					// Update Import Delimiters
					(new FeedWizardStep())
						.setEndpoint(function () {
							return '/api.php/dbs/' + $scope.data.db_id + '/imports/' + $scope.data.import_id + '/delimiters';
						})
						.setParams(function () {
							return {
								'enclosure': '"',
								'encoding': '',
								'escaper': '"',
								'line_terminator': "\n",
								'separator': ','
							};
						})
						.setMethod('put')
						.setOnError(on_step_error),
	
					// Grab headers to create file map
					(new FeedWizardStep())
						.setEndpoint(function () {
							return '/api.php/dbs/' + $scope.data.db_id + '/imports/' + $scope.data.import_id + '/file?format=parsed&limit=4';
						})
						.setMethod('get')
						.setOnSuccess(function (response) {
							$scope.data.file_map = {};
	
							response[0].forEach(function (field, index) {
								var header = FeedWizardService.hex2bin(response[0][index]);
	
								var name = header.replace(/[^\x00-\x7F]/g, "")
									.trim()
									.toLowerCase()
									.replace(/ /g, "_")
									.replace(/-/g, "_");
	
								$scope.data.file_map[response[0][index]] = name;
							});
						})
						.setOnError(on_step_error),
	
					// Update Import #1 file map
					(new FeedWizardStep())
						.setEndpoint(function () {
							return '/api.php/dbs/' + $scope.data.db_id + '/imports/' + $scope.data.import_id + '/file_map';
						})
						.setMethod('put')
						.setParams(function () {
							return {
								'maps': $scope.data.file_map,
								'name_based_maps': 1,
								'encode_source_file_keys': 1,
								'clean_file_headers': 0
							};
						})
						.setOnError(on_step_error),
				];
			}

			var wizard = (new FeedWizard($http, $q))
				.addSteps(steps);

			// This is used to grab data from the oauth popup
			var oauthPopupEvent = function (e) {
				$scope.next_step();

				wizard.run().then(function () {
					$scope.next_step();

					fdxUI.showToastSuccess('Your build is complete!');

					$rootScope.$broadcast("wizard_completed_successfully", {
						store_url: $scope.data['store_url'],
						db_id: $scope.data.db_id
					});
				}, angular.noop, function (progress) {
					$scope.progress = progress;
				});
			};

			window.document.addEventListener('oauthPopupEvent', oauthPopupEvent);
			$scope.$on('$destroy', function () {
				window.document.removeEventListener('oauthPopupEvent', oauthPopupEvent);
			});

			$scope.open_window = function (url) {
				if (popup) {
					popup.close();
				}

				popup = window.open(url || '', 'social', 'width=640, height=480, left=0, top=0, toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no');
				if (!url) {
					popup.document.body.innerHTML = '<h1>Processing...</h1><h3>Please do not close this window!</h3>';
				}
			};

			if ($scope.showNewUI) {
				$scope.build = function () {
					$scope.next_step();

					wizard.run().then(function () {
						$scope.next_step();

						$rootScope.$broadcast('wizard_completed_successfully', {
							'store_url': $scope.data.store_url,
							'oauth_token': $scope.data.app_password,
							'db_id': $scope.data.db_id
						});
					}, angular.noop, function (progress) {
						$scope.progress = progress;
					});
				};
			} else {
				$scope.build = function () {
					$scope.open_window();
					$scope.next_step();
	
					wizard.run(3).then(function () {
						$scope.next_step();
					}, angular.noop, function (progress) {
						$scope.progress = progress;
					});
				};
			}

			$scope.skip = function() {
				$rootScope.$broadcast('wizard_skipped');
			};

			// If presented by FeedSupport
			if (typeof $scope.$parent.wizard_initialize_db_name != 'undefined' && $scope.$parent.wizard_initialize_db_name != '') {
				$scope.data['db_name'] = $scope.$parent.wizard_initialize_db_name;
				$scope.shopify_messages.skip_install = true;
				$scope.shopify_messages.hide_instructions = true;
				$scope.shopify_messages.show_db_list = false;
				$scope.shopify_messages.authorization_message = 'Please log into the Shopify Store via the popup. If you are unable to login to Shopify please click \'Skip install\' and continue with the ticket.';
				$scope.shopify_messages.authorization_reopen = 'If you do not see the popup, please click here to open it again.';
			}
		}
	};
}]);