/* eslint-disable @typescript-eslint/naming-convention */
import ImpersonateUserService from '@ajs/services/ImpersonateUserService';
import NavigationService from '@ajs/services/NavigationService';
import ResolversService from '@ajs/services/ResolversService';
import TitleService from '@ajs/services/TitleService';
import { InjectionToken, Provider } from '@angular/core';
import { StateParams, StateService, TransitionService } from '@uirouter/angularjs';
import { IRootScopeService, auto } from 'angular';
import AppStateService from './services/AppStateService';
import CSVService from './services/CSVService';
import DataDownloadService from './services/DataDownloadService';
import DatabasesDataService from './services/DatabasesDataService';
import ExportsDataService from './services/ExportsDataService';
import LogService from './services/LogService';
import FdxUI from './services/fdxUI';
import FdxUtils from './services/fdxUtils';

export function AppStateServiceFactory($injector: auto.IInjectorService): AppStateService {
    return $injector.get('AppStateService');
}

export function CSVServiceServiceFactory($injector: auto.IInjectorService): CSVService {
    return $injector.get('CSVService');
}

export function DatabasesDataServiceFactory($injector: auto.IInjectorService): DatabasesDataService {
    return $injector.get('DatabasesDataService');
}

export function DataDownloadServiceFactory($injector: auto.IInjectorService): DataDownloadService {
    return $injector.get('DataDownloadService');
}

export function fdxUIServiceFactory($injector: auto.IInjectorService): FdxUI {
    return $injector.get('fdxUI');
}

export function ExportsDataServiceFactory($injector: auto.IInjectorService): ExportsDataService {
    return $injector.get('ExportsDataService');
}

export function NavigationServiceFactory($injector: auto.IInjectorService): NavigationService {
    return $injector.get('NavigationService');
}

export function ResolversServiceFactory($injector: auto.IInjectorService): ResolversService {
    return $injector.get('ResolversService');
}

export function fdxUtilsServiceFactory($injector: auto.IInjectorService): FdxUtils {
    return $injector.get('fdxUtils');
}

export function ImpersonateUserServiceFactory($injector: auto.IInjectorService): ImpersonateUserService {
    return $injector.get('ImpersonateUserService');
}

export function logServiceFactory($injector: auto.IInjectorService): LogService {
    return $injector.get('LogService');
}

export function titleServiceFactory($injector: auto.IInjectorService): TitleService {
    return $injector.get('TitleService');
}

export function uiRouterStateServiceFactory($injector: auto.IInjectorService): StateService {
    return $injector.get('$state');
}

export function uiRouterStateParamsServiceFactory($injector: auto.IInjectorService): StateParams {
    return $injector.get('$stateParams');
}

export const AppStateServiceProvider = {
    provide: AppStateService,
    useFactory: AppStateServiceFactory,
    deps: ['$injector']
};

export const CSVServiceProvider = {
    provide: CSVService,
    useFactory: CSVServiceServiceFactory,
    deps: ['$injector']
};

export const DatabasesDataServiceProvider = {
    provide: DatabasesDataService,
    useFactory: DatabasesDataServiceFactory,
    deps: ['$injector']
};

export const DataDownloadServiceProvider = {
    provide: DataDownloadService,
    useFactory: DataDownloadServiceFactory,
    deps: ['$injector']
};

export const ExportsDataServiceProvider = {
    provide: ExportsDataService,
    useFactory: ExportsDataServiceFactory,
    deps: ['$injector']
};

export const NavigationServiceProvider = {
    provide: NavigationService,
    useFactory: NavigationServiceFactory,
    deps: ['$injector']
};

export const ResolversServiceProvider: Provider = {
    provide: ResolversService,
    useFactory: ResolversServiceFactory,
    deps: ['$injector']
};

export const fdxUIServiceProvider = {
    provide: FdxUI,
    useFactory: fdxUIServiceFactory,
    deps: ['$injector']
};

export const fdxUtilsServiceProvider = {
    provide: FdxUtils,
    useFactory: fdxUtilsServiceFactory,
    deps: ['$injector']
};

export const ImpersonateUserServiceProvider = {
    provide: ImpersonateUserService,
    useFactory: ImpersonateUserServiceFactory,
    deps: ['$injector']
};

export const LogServiceProvider = {
    provide: LogService,
    useFactory: logServiceFactory,
    deps: ['$injector']
};

export const UIRouterState = new InjectionToken('UIRouterState');
export const uiRouterStateProvider = {
    provide: UIRouterState,
    useFactory: uiRouterStateServiceFactory,
    deps: ['$injector']
};

export const UIRouterStateParams = new InjectionToken('UIRouterStateParams');
export const uiRouterStateParamsProvider = {
    provide: UIRouterStateParams,
    useFactory: uiRouterStateParamsServiceFactory,
    deps: ['$injector']
};

export const $rootScope = new InjectionToken('$rootScope');
export const $rootScopeProvider = {
    provide: $rootScope,
    useFactory: ($injector: auto.IInjectorService): IRootScopeService => {
        return $injector.get('$rootScope');
    },
    deps: ['$injector']
};

export const $transitions = new InjectionToken('$transitions');
export const $transitionsProvider = {
    provide: $transitions,
    useFactory: (injector: auto.IInjectorService): TransitionService => {
        return injector.get('$transitions');
    },
    deps: ['$injector']
};
