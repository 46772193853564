import { Component, Input, TemplateRef } from '@angular/core';

@Component({
    selector: '[fdxInputGroupAddons]',
    templateUrl: './input-group-addons.component.html',
    styleUrls: ['./input-group-addons.component.scss']
})
export class InputGroupAddonsComponent {
    
    @Input() leftAddons: TemplateRef<any>[];
    @Input() input: TemplateRef<any>;
    @Input() rightAddons: TemplateRef<any>[];
    @Input() validation: TemplateRef<any>;

    constructor() { }
}
