import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { CellClassParams, CellRendererSelectorResult, ColDef, GetDetailRowDataParams, GridApi, GridOptions, GridReadyEvent, ICellRendererParams, IDetailCellRendererParams, IRowNode, Module, ValueFormatterParams } from '@ag-grid-community/core';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import AppStateService from '@ajs/services/AppStateService';
import CSVService from '@ajs/services/CSVService';
import FdxUI from '@ajs/services/fdxUI';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { deepCopy } from '@app/core/functions';
import { DateTimeService } from '@app/core/services/date-time.service';
import { FEED_ALERTS_DOWNLOAD_BLACKLIST } from '@app/logs/constants/logs.constants';
import { LogType } from '@app/logs/models/enums/log-type.enum';
import { IFeedAlertsLogErrorIssues } from '@app/logs/models/interfaces/log-feed-alerts.interfaces';
import { FeedAlertsLog } from '@app/logs/models/types/log-feed-alerts.types';
import { LogsDataFormatterService } from '@app/logs/services/logs-data-formatter.service';
import { LogsDataService } from '@app/logs/services/logs-data.service';
import { LogsStateService } from '@app/logs/services/logs-state.service';
import { LogsRequest } from '@app/logs/services/requests/logs-request.type';
import { FeedAlertsLogsResponse, IFeedAlertsLog } from '@app/logs/services/responses/logs-responses.types';
import { JSONCellRendererComponent } from '@app/standalone-components/ag-grid/cell-renderers/json-cell-renderer/json-cell-renderer.component';
import { LinkCellRendererComponent } from '@app/standalone-components/ag-grid/cell-renderers/link-cell-renderer/link-cell-renderer.component';
import { DateRange, Item } from '@feedonomics/frontend-components';
import { IconDefinition, faDownload } from '@fortawesome/pro-solid-svg-icons';
import { Subject, distinctUntilChanged, filter, takeUntil, tap } from 'rxjs';

@Component({
    selector: 'fdx-logs-feed-alerts',
    templateUrl: './logs-feed-alerts.component.html',
    styleUrls: ['./logs-feed-alerts.component.scss']
})
export class LogsFeedAlertsComponent implements OnInit, OnDestroy {
    readonly logType: LogType = LogType.FeedAlerts;

    private readonly unsubscribe$: Subject<void> = new Subject<void>();
    readonly downloadIcon: IconDefinition = faDownload;

    logs: FeedAlertsLog[] = [];
    quickFilter: string = null;

    // eslint-disable-next-line @typescript-eslint/typedef
    filters = new FormGroup({
        dateRange: new FormControl<DateRange>(null),
        status: new FormControl<string[]>(null)
    });

    feedAlertsStatuses: Item[] = [
        {
            value: 'Passed',
            label: 'Passed'
        },
        {
            value: 'Failed',
            label: 'Failed'
        }
    ];

    get databaseId(): string {
        return this.appStateService.getDatabaseId();
    }

    get timeZoneCode(): string {
        return this.dateTimeService.getTimezoneCode();
    }

    // #region AG_GRID
    private gridApi: GridApi<FeedAlertsLog>;

    public readonly modules: Module[] = [
        ClientSideRowModelModule,
        ClipboardModule,
        MasterDetailModule,
        RowGroupingModule
    ];

    private readonly defaultColDef: ColDef<FeedAlertsLog> = {
        minWidth: 100,
        wrapText: true,
        flex: 1
    };

    public readonly columnDefs: ColDef<FeedAlertsLog>[] = [
        {
            field: 'date',
            rowGroup: true,
            hide: true
        },
        {
            field: 'time',
            hide: true
        },
        {
            field: 'status',
            headerName: 'Status',
            cellClass: (params: CellClassParams<FeedAlertsLog>): string => {
                if (!params.data?.status) {
                    return;
                }

                switch (params.data.status) {
                    case 'Passed':
                        return 'text-success';
                    default:
                        return 'text-danger';
                }
            }
        },
        {
            field: 'channel',
            headerName: 'Channel'
        },
        {
            field: 'totalProducts',
            headerName: 'Total products'
        },
        {
            field: 'totalErrors',
            headerName: 'Total errors'
        },
        {
            field: 'errorRate',
            headerName: 'Error rate',
            valueFormatter: (params: ValueFormatterParams<FeedAlertsLog>): string => {
                if (!params.data?.errorRate) {
                    return;
                }
                return `${params.data.errorRate}%`;
            },
            cellClass: (params: CellClassParams<FeedAlertsLog>): string => {
                if (!params.data?.status) {
                    return;
                }

                switch (params.data.status) {
                    case 'Passed':
                        return 'text-success';
                    default:
                        return 'text-danger';
                }
            }
        }
    ];

    private readonly defaultDetailColDef: ColDef<FeedAlertsLog> = {
        minWidth: 100,
        wrapText: true,
        autoHeight: true
    };

    public readonly detailColDefs: ColDef<IFeedAlertsLogErrorIssues>[] = [
        {
            field: 'code',
            headerName: 'Internal code'
        },
        {
            field: 'servability',
            headerName: 'Servability'
        },
        {
            field: 'attributeName',
            headerName: 'Attribute'
        },
        {
            field: 'description',
            headerName: 'Description'
        },
        {
            field: 'detail',
            headerName: 'Detail',
            flex: 1
        },
        {
            field: 'documentation',
            headerName: 'Documentation',
            sortable: false,
            cellRendererSelector: (params: ICellRendererParams<IFeedAlertsLogErrorIssues>): CellRendererSelectorResult => {
                return {
                    component: LinkCellRendererComponent,
                    params: {
                        newTab: true,
                        externalLink: params.data.documentation,
                        valueFormatted: 'More info'
                    }
                }
            }
        },
        {
            field: 'numItems',
            headerName: 'Count'
        }
    ];

    public readonly gridOptions: GridOptions<FeedAlertsLog> = {
        context: this,
        masterDetail: true,
        domLayout: 'autoHeight',
        rowModelType: 'clientSide',
        pagination: true,
        autoGroupColumnDef: {
            field: 'time',
            headerName: `Date > time (${this.timeZoneCode})`
        },
        defaultColDef: this.defaultColDef,
        detailRowAutoHeight: true,
        groupDefaultExpanded: 1,
        columnDefs: this.columnDefs,
        isExternalFilterPresent: () => true,
        doesExternalFilterPass: (node: IRowNode<FeedAlertsLog>) => {
            if (!this.filters.value.status || this.filters.value.status.length === 0) {
                return true;
            }

            return this.filters.value.status.some((value: string) => node.data.status === value);
        },
        isRowMaster: (dataItem: FeedAlertsLog) => dataItem?.detail?.length > 0,
        detailCellRendererParams: (parentRowParams: IDetailCellRendererParams<FeedAlertsLog>) => {
            if (parentRowParams.data.exceptionJSON) {
                return {
                    detailGridOptions: {
                        defaultColDef: {
                            flex: 1,
                            autoHeight: true
                        },
                        columnDefs: [
                            {
                                field: 'exceptionJSON',
                                headerName: 'JSON error',
                                cellRenderer: JSONCellRendererComponent
                            }
                        ]
                    },
                    getDetailRowData: (getDetailRowParams: GetDetailRowDataParams<FeedAlertsLog, string>): void => {
                        getDetailRowParams.successCallback([getDetailRowParams.data.exceptionJSON]);
                    }
                }
            }

            return {
                detailGridOptions: {
                    defaultColDef: this.defaultDetailColDef,
                    columnDefs: this.detailColDefs
                },
                getDetailRowData: (getDetailRowParams: GetDetailRowDataParams<FeedAlertsLog, IFeedAlertsLogErrorIssues>): void => {
                    getDetailRowParams.successCallback(getDetailRowParams.data.detail);
                }
            }
        }
    };
    // #endregion

    constructor(
        private readonly appStateService: AppStateService,
        private readonly csvService: CSVService,
        private readonly dateTimeService: DateTimeService,
        private readonly fdxUI: FdxUI,
        private readonly logsDataFormatterService: LogsDataFormatterService,
        private readonly logsDataService: LogsDataService,
        private readonly logsStateService: LogsStateService
    ) {}

    ngOnInit(): void {
        this.initLogsStateService();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private initLogsStateService(): void {
        this.logsStateService.logsRequest$.pipe(
            filter((request: LogsRequest) => request !== null),
            tap((request: LogsRequest) => this.getLogs(request)),
            takeUntil(this.unsubscribe$)
        ).subscribe();

        this.logsStateService.logsQuickFilter$.pipe(
            tap((filter: string) => {
                this.quickFilter = filter;
                this.gridApi?.setGridOption('quickFilterText', filter);
            }),
            takeUntil(this.unsubscribe$)
        ).subscribe();

        this.logsStateService.logsDateRangeFilter$.pipe(
            filter((range: DateRange) => range !== null),
            distinctUntilChanged(),
            tap(() => this.fireRequest()),
            takeUntil(this.unsubscribe$)
        ).subscribe();
    }

    private getLogs(request: LogsRequest): void {
        request.page = this.gridApi?.paginationGetCurrentPage() + 1 || 1;

        this.logsDataService.getFeedAlertsLogs(this.databaseId, request).pipe(
            tap((response: FeedAlertsLogsResponse) => {
                if (request.download) {
                    this.handleDownload(response.logs);
                    return;
                }

                this.logs = this.logsDataFormatterService.formatFeedAlertsStructure(response.logs);

                this.gridApi.setGridOption('rowData', this.logs);
                this.showOrHideNoRowsOverlay();
            }),
            takeUntil(this.unsubscribe$)
        ).subscribe();
    }

    fireRequest(download: boolean = false): void {
        const range: DateRange = this.logsStateService.logsDateRangeFilter$.value;

        this.logsStateService.logsRequest$.next({
            sort: 'time',
            log_disabled: true,
            start_date: range.isoStartDate,
            end_date: range.isoEndDate,
            download: download,
            user_timezone: this.dateTimeService.getTimezone()
        });
    }

    filterChanged(): void {
        this.gridApi?.onFilterChanged();
    }

    public onGridReady(params: GridReadyEvent<FeedAlertsLog>): void {
        this.gridApi = params.api;

        // Apply any filters that were waiting to be set until the grid was ready
        this.gridApi.setGridOption('quickFilterText', this.quickFilter);
        this.gridApi.onFilterChanged();
    }

    showOrHideNoRowsOverlay(): void {
        if (this.gridApi.getDisplayedRowCount() === 0) {
            this.gridApi.showNoRowsOverlay();
        } else {
            this.gridApi.hideOverlay();
        }
    }

    handleDownload(logs: IFeedAlertsLog[]): void {
        if (logs.length === 0) {
            this.fdxUI.showToastError('There are no logs available to download');
            return;
        }

        const data: string[][] = [];

        /**
         * First, push the headers, removing any blacklisted header values
         */
        const headers = Object.keys(logs[0]).filter((val) => !FEED_ALERTS_DOWNLOAD_BLACKLIST.has(val));
        data.push(headers);

        /**
         * Next, push logs, correcting any data typing
         */
        logs.forEach((log: IFeedAlertsLog) => {
            const tempLog = deepCopy(log);
            const tempRow = [];

            headers.forEach((key: string) => {
                if (typeof tempLog[key] === 'object') {
                    tempRow.push(JSON.stringify(log[key]));
                } else if (key.indexOf('date') !== -1 || (key.indexOf('time') !== -1 && !isNaN(tempLog[key]))) {
                    const time = new Date(tempLog[key]);
                    tempRow.push(time.toLocaleString());
                } else if (typeof tempLog[key] === 'undefined') {
                    tempLog[key] = '';
                    tempRow.push(tempLog[key]);
                } else {
                    tempRow.push(tempLog[key]);
                }
            });

            data.push(tempRow);
        });

        this.csvService.download(`${this.logType}_logs.csv`, data);
        this.fdxUI.showToastSuccess('Successfully downloaded logs');
    }

    patchFromMultiselect(formControlName: string, event: unknown): void {
        this.filters.patchValue({ [formControlName]: event });
    }
}
