<div class="container">
    <fdx-page-title>Transformers field execution order</fdx-page-title>
    <fdx-alert
        *ngIf="requestedPrimaryKey"
        [isLoading]="true"
        title="System processing"
        [body]="'The system is currently processing the selection of primary key to ' + requestedPrimaryKey + ', so you cannot select another primary key at this time. Users who are on the alert email list will receive an email upon completion.'">
    </fdx-alert>

    <fdx-alert
        *ngIf="errorText"
        theme="danger"
        [body]="errorText">
    </fdx-alert>

    <div class="card">
        <div class="card-header fdx-list-table-header align-items-center">
            <div class="drag-handle-column"></div>
            <div class="w-100 d-flex align-items-center">
                <div class="col-2">
                    Primary key <fa-icon [icon]="iconInfo" [ngbTooltip]="primaryKeyTooltipText" class="text-secondary" size="xs"></fa-icon>
                </div>
                <div [ngClass]="{'col-5': isAdmin, 'col-8': !isAdmin}">Field name </div>
                <div
                    *ngIf="isAdmin"
                    class="col-3">
                    Field type
                </div>
                <div class="col-2 text-center">
                    Order <fa-icon [icon]="iconInfo" size="xs" [ngbTooltip]="orderTooltipText" class="text-secondary"></fa-icon>
                </div>
            </div>
        </div>
        <div class="card-body p-0">
            <div
                (cdkDropListDropped)="drop($event)"
                class="list-group"
                cdkDropList>
                <div
                    *ngFor="let dbField of dbFields; trackBy: trackDbField; let isLast = last"
                    [cdkDragData]="dbField"
                    class="fdx-list-table-item bg-white align-items-center"
                    cdkDrag
                    data-entity="field"
                    [attr.data-id]="dbField.id"
                    [attr.data-name]="dbField.field_name">
                    <div class="drag-handle-column"><fa-icon cdkDragHandle [icon]="iconGrip" size="2x" class="drag-handle fdx-drop-list-handle"></fa-icon></div>
                    <div class="w-100 d-flex align-items-center">
                        <div
                            class="col-2 align-pk"
                            *ngIf="requestedPrimaryKey; else notProcessingPrimaryKey">
                            <button
                                type="button"
                                name="select-pk"
                                disabled
                                class="unstyled-button p-2"
                                ngbTooltip="The system is currently processing the selection of primary key to {{requestedPrimaryKey}} so you cannot select another primary key at this time. Users who are on the alert email list will receive an email upon completion.">
                                <fa-icon
                                    [icon]="iconKey"
                                    class="text-primary opacity-25"
                                    [fixedWidth]="true">
                                </fa-icon>
                            </button>
                        </div>
                        <ng-template #notProcessingPrimaryKey>
                            <div class="col-2 align-pk">
                                <button
                                    type="button"
                                    name="select-pk"
                                    [disableTooltip]="dbField.isPrimaryKey"
                                    class="unstyled-button p-2"
                                    ngbTooltip="Click to select field as primary key"
                                    aria-label="Click to select field as primary key"
                                    (click)="openPrimaryKeyModal(dbField)">
                                    <fa-icon
                                        [icon]="iconKey"
                                        class="text-primary"
                                        [ngClass]="{ 'opacity-25': !dbField.isPrimaryKey }"
                                        [fixedWidth]="true">
                                    </fa-icon>
                                </button>
                            </div>
                        </ng-template>
    
                        <div [ngClass]="{'col-5': isAdmin, 'col-8': !isAdmin}">
                            <div
                                class="d-flex gap-3 align-items-center"
                                fdxStopPropagation>
                                <span class="fw-tritobold">{{dbField.field_name}}</span>
                            </div>
                        </div>
    
                        <div
                            *ngIf="isAdmin"
                            class="col-3"
                            fdxStopPropagation>
                            <ng-select
                                name="field-type"
                                [(ngModel)]="dbField.field_type"
                                [clearable]="false"
                                [items]="dbFieldTypes"
                                (change)="dbField.editing = true"
                                placeholder="Choose field type">
                            </ng-select>
                        </div>
    
                        <div
                            class="col-2 fdx-sort-order text-center"
                            fdxStopPropagation>
                            <button
                                *ngIf="!dbField.editing; else editing"
                                type="button"
                                name="edit-order"
                                (click)="dbField.editing = true"
                                class="unstyled-button py-2 px-4">
                                {{dbField.sort_order}}
                            </button>
    
                            <ng-template #editing>
                                <div class="row gx-2">
                                    <div class="col-6">
                                        <input
                                            type="text"
                                            name="order"
                                            class="form-control text-center"
                                            [(ngModel)]="dbField.sort_order">
                                    </div>
                                    <div class="col-6">
                                        <button
                                            type="button"
                                            name="update-order"
                                            class="btn btn-primary"
                                            (click)="reorderClicked(dbField, dbField.sort_order)">
                                            Update
                                        </button>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
