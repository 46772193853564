<modal-hoc
    [modalTheme]="modalTheme"
	[modalTitle]="title"
    [submitText]="submitText"
    [cancelText]="cancelText"
	(modalCancel)="onModalCancel($event)"
	(modalSubmit)="onModalSubmit($event)">
    Are you sure you wish to delete this transformer?
    <fdx-readonly-transformer
        [transformer]="transformer"
        [dbFields]="dbFields"
        [fieldName]="currentField?.displayName" >
    </fdx-readonly-transformer>
</modal-hoc>
