export default class ExportsDataService {
	static $inject = [
		'$http',
		'$q',
		'$sce'
	];

	// Services
	$http = null;
	$q = null;
	$sce = null;

	constructor($http, $q, $sce) {
		this.$http = $http;
		this.$q = $q;
		this.$sce = $sce;
	}

	getAll(idDatabase) {

		const def = this.$q.defer();

		const promise = this.$http.get('/api.php/dbs/' + idDatabase + '/exports');

		promise.then(
			(response) => {
				def.resolve(response.data);
			},
			(response) => {
				def.reject(response.data);
			}
		);

		return def.promise;

	}

	getFeedGrade(idDatabase, url) {

		const def = this.$q.defer();

		const feedGraderUrl = this.$sce.trustAsResourceUrl('https://feedgrader.feedonomics.com/feedgrader_v6.php');
		const promise = this.$http.jsonp(feedGraderUrl,
			{
				params:
				{
					feedgrader_url: url,
					db_id: idDatabase
				},
				format: 'jsonp'
			});

		promise.then(
			(response) => {
				def.resolve(response.data);
			},
			(response) => {
				def.reject(response.data);
			}
		);

		return def.promise;

	}

}