import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FdxApiResponse } from '@app/core/responses/fdx-api.response';
import { ClassificationDataModel } from '@app/feed-ai/models/classification.model';
import { ClassifierInfo, ClassifierInfoDataModel } from '@app/feed-ai/models/classifier-info.model';
import { Observable, map } from 'rxjs';

interface ClassificationParams {
    displaylimit: number;
    fulldist: boolean;
    phrases: string;
    smoothing: number;
}

@Injectable({
    providedIn: 'root'
})
export default class ClassificationDataService {
    constructor(private http: HttpClient) {}

    getByDbId(databaseId: string): Observable<ClassifierInfo> {
        return this.http.get<FdxApiResponse<ClassifierInfoDataModel>>(`/api.php/dbs/${databaseId}/classifier_info`).pipe(
            map(({ data }) => {
                return {
                    classifierFieldName: data.classifier_field_name,
                    dbId: data.db_id,
                    gtinFieldName: data.gtin_field_name,
                    pidFieldName: data.pid_field_name,
                    titleFieldName: data.title_field_name
                };
            })
        );
    }

    clearCache(databaseId: string): Observable<FdxApiResponse<void>> {
        return this.http.post<FdxApiResponse<void>>(`/api.php/dbs/${databaseId}/classifier_info/clear_cache`, null);
    }

    runClassification(databaseId: string, params: ClassificationParams): Observable<FdxApiResponse<ClassificationDataModel>> {
        return this.http.post<FdxApiResponse<ClassificationDataModel>>(`/api.php/dbs/${databaseId}/run_classify`, params);
    }

    update(databaseId: string, classifier: Omit<ClassifierInfo, 'dbId'>): Observable<FdxApiResponse<void>> {
        return this.http.post<FdxApiResponse<void>>(`/api.php/dbs/${databaseId}/classifier_info`, {
            classifier_field_name: classifier.classifierFieldName,
            gtin_field_name: classifier.gtinFieldName,
            pid_field_name: classifier.pidFieldName,
            title_field_name: classifier.titleFieldName
        });
    }
}
