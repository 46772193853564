
export default class FdxFileMapsFaqModalController {

	// from uibmodal
	private close: (result?: { $value }) => void;
	private dismiss: (result?: { $value: string }) => void;

	public simpleXmlExample: string = '<?xml version="1.0" encoding="utf-8"?>\n'
		+ '<items>\n'
		+ '   <item>\n'
		+ '      <id>123</id>\n'
		+ '      <g:title>Nike Shoes</g:title>\n'
		+ '      <url href="http://www.url.com/shoes"></url>\n'
		+ '      <attribute name="color">Red</attribute>\n'
		+ '      <attribute name="size">7</attribute>\n'
		+ '      <price>\n'
		+ '         <base_price>20.00</base_price>\n'
		+ '         <sale_price>15.00</sale_price>\n'
		+ '      </price>\n'
		+ '      <category>clothing</category>\n'
		+ '      <category>shoes</category>\n'

		+ '   </item>\n\n'

		+ '   <item>\n'
		+ '      ...\n'
		// + '      <id>124</id>\n'
		// + '      <sc:title>Shirt</sc:title>\n'
		// + '      <url>http://www.url.com/shirt</url>\n'
		// + '      <image_url>http://www.url.com/img/shirt</image_url>\n'
		// + '      <price>\n'
		// + '         <base_price>10.00</base_price>\n'
		// + '         <sale_price>5.00</sale_price>\n'
		// + '         <shipping_price>1.50</shipping_price>\n'
		// + '      </price>\n'
		+ '   </item>\n\n'

		// + '   <item>\n'
		// + '      <id>125</id>\n'
		// + '      <sc:title>Pants</sc:title>\n'
		// + '      <url>http://www.url.com/pants</url>\n'
		// + '      <image_url>http://www.url.com/img/pants</image_url>\n'
		// + '      <price>\n'
		// + '         <base_price>17.00</base_price>\n'
		// + '         <sale_price>15.00</sale_price>\n'
		// + '         <shipping_price>2.50</shipping_price>\n'
		// + '      </price>\n'
		// + '   </item>\n'
		+ '</items>';

	public complicatedXmlExample: string = '<?xml version="1.0" encoding="utf-8"?>\n'
		+ '<ProductFamily>\n'
		+ '   <parent_id>123</parent_id>\n'
		+ '   <title>Shoe</title>\n'
		+ '   <price>10.00</price>\n'
		+ '   <ProductVariant>\n'
		+ '      <variant_id>a512</variant_id>\n'
		+ '      <color>red</color>\n'
		+ '   </ProductVariant>\n\n'
		+ '   <ProductVariant>\n'
		+ '      <variant_id>a513</variant_id>\n'
		+ '      <color>blue</color>\n'
		+ '   </ProductVariant>\n'
		+ '</ProductFamily>';

	static $inject = [];

	constructor() { }

	public onCancel(): void {
		this.dismiss();
	}
}
