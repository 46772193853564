import { MODULE_NAME } from './config/constants';

// main module
import './modal.module';

// components, services, etc.
import './components';
import './services';

// prepare module exports
export { default as ModalService } from './services/ModalService';

export default MODULE_NAME;
