<ng-template #wrapper>
    <div
        [ngClass]="inputClasses"
        [ngStyle]="styles" >
        <ng-content></ng-content>
    </div>
</ng-template>

<ng-container *ngIf="isInputGroup; else notInputGroup">
    <div
        fdxInputGroupAddons
        [leftAddons]="leftAddons"
        [input]="wrapper"
        [rightAddons]="rightAddons"
        [validation]="validation">
    </div>
</ng-container>
<ng-template #notInputGroup>
    <ng-container *ngTemplateOutlet="wrapper"></ng-container>
    <ng-container *ngTemplateOutlet="validation"></ng-container>
</ng-template>
<ng-template #validation>
    <fdx-validation [control]="control"
        [labelText]="labelText"
        [customValidationText]="customValidationText"
        [submitted]="form.submitted"
        [ngClass]="{'invalid-feedback': !tooltipValidation, 'invalid-tooltip': tooltipValidation}"
    ></fdx-validation>
</ng-template>
