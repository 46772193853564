<ng-container *ngIf="params.routerLink; else external">
    <a
        [routerLink]="params.routerLink"
        [queryParams]="params.queryParams"
        [attr.target]="target ? target : null"
        [attr.rel]="target ? 'noopener' : null"
        [attr.referrerpolicy]="target ? 'no-referrer' : null"
        class="btn btn-link p-0 text-start"
        [ngClass]="params.classes">
        <ng-container *ngTemplateOutlet="linkContent"></ng-container>
    </a>
</ng-container>
<ng-template #external>
    <a
        [href]="params.externalLink"
        [attr.target]="target ? target : null"
        [attr.rel]="target ? 'noopener' : null"
        [attr.referrerpolicy]="target ? 'no-referrer' : null"
        class="btn btn-link p-0 text-start"
        [ngClass]="params.classes">
        <ng-container *ngTemplateOutlet="linkContent"></ng-container>
    </a>
</ng-template>

<ng-template #linkContent>
    <span>{{cellValue}}</span>
    <fa-icon
        *ngIf="params.icon"
        [icon]="params.icon"
        class="fa-icon-right"
        size="sm">
    </fa-icon>
</ng-template>
