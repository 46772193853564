<modal-hoc
    [cancelText]="cancelText"
    [modalTheme]="modalTheme"
    [modalTitle]="title"
    [submitText]="confirmText"
    (modalCancelByX)="onModalCancelByX($event)"
    (modalCancelByButton)="onModalCancelByButton($event)"
    (modalSubmit)="onModalSubmit($event)">
    <div [innerHTML]="body"></div>
</modal-hoc>
