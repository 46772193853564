<ng-template #heading>
    <h1 [ngClass]="classes">
        <ng-content></ng-content>
    </h1>
</ng-template>

<ng-container *ngIf="!beta" [ngTemplateOutlet]="heading"></ng-container>

<ng-container *ngIf="beta">
    <div class="d-flex align-items-start gap-2">
        <ng-container [ngTemplateOutlet]="heading"></ng-container>
        <span [ngClass]="betaBadgeClasses">BETA</span>
    </div>
</ng-container>
