import AppStateService from '@ajs/services/AppStateService';
import fdxUI from '@ajs/services/fdxUI';
import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AppMenuTab } from '@app/core/models/enums/app-menu-tab.enum';
import { ClassificationDataModel } from '@app/feed-ai/models/classification.model';
import ClassificationDataService from '@app/feed-ai/services/classification-data.service';
import { BasePageComponent } from '@app/modules/page/abstract/base-page.component';
import { finalize, takeUntil } from 'rxjs';

@Component({
    selector: 'fdx-classifier-page',
    templateUrl: './classifier-page.component.html',
    styleUrls: ['./classifier-page.component.scss']
})
export class ClassifierPageComponent extends BasePageComponent {
    appMenuTab: AppMenuTab = AppMenuTab.Transformers;
    classification: ClassificationDataModel = null;
    form = new UntypedFormGroup({
        phrases: new UntypedFormControl(''),
        smoothing: new UntypedFormControl(0.1)
    });
    title = 'Classify Title';

    constructor(
        private appStateService: AppStateService,
        private classificationDataService: ClassificationDataService,
        fdxUI: fdxUI
    ) {
        super(fdxUI);
    }

    classify(): void {
        this.fdxUI.startBlockUI();
        this.classification = null;

        this.classificationDataService.runClassification(this.appStateService.getDatabaseId(), {
            displaylimit: 10,
            fulldist: false,
            phrases: this.form.controls.phrases.value,
            smoothing: +this.form.controls.smoothing.value,
        })
            .pipe(
                finalize(() => {
                    this.fdxUI.stopBlockUI();
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(
                ({ data }) => {
                    this.classification = data;
                },
                (error: HttpErrorResponse) => {
                    this.fdxUI.showToastError(error.error);
                }
            );
    }
}
