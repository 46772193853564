import * as angular from 'angular';
import { MODULE_NAME } from '../config/constants';

angular.module(MODULE_NAME)
/** dt-delimited-file-map */
		.directive('dtDelimitedFileMap', ['$http', 'AppStateService', function ($http, AppStateService) {
			return {
				restrict   : 'E',
				scope      : {
					file        : '=',
					updateFields: '&'
				},
				template: require('./templates/delimited_map.html'),
				link       : function ($scope) {

                    const databaseId = AppStateService.getDatabaseId();

					$scope.hex2bin       = $scope.$parent.hex2bin;
					$scope.update_fields = $scope.$parent.update_fields;

					$scope.auto_detect = function (file) {
						file.delimiters_modified = true;
						file.auto_detect         = '';

						file.auto_detect_in_progress = true;
						file.auto_detect_finished    = false;

						if (typeof file.file_map === "undefined") {
							file.file_map = {
								separator: '',
								enclosure: '',
								escaper  : '',
								maps     : {}
							};
						}
						//@TODO: && Version !== Latest
						let promise;

						if (file.type == "import") {
							promise = $http.get('/api.php/dbs/' + databaseId + '/imports/' + file.id + '/auto_detect');
						} else {
							promise = $http.get('/api.php/dbs/' + databaseId + '/diff_tool/' + file.file_id + '/auto_detect');
						}

						promise
								.then(function (response) {
									var data                     = response.data;
									file.auto_detect_in_progress = false;
									file.auto_detect_finished    = true;

									// // timeout auto_detect_finished
									// $timeout(function(){
									// 	file.auto_detect_finished = false;
									// }, 1500);

									file.auto_detect = data;
									if (data.length > 0) {
										file.file_map.separator = data[0].field_separator;
										file.file_map.enclosure = data[0].enclosure_character;
										file.file_map.escaper   = data[0].escape_character;
										file.line_terminator    = data[0].line_terminator;

										file.file_map.encoding        = data[0].recommended_encoding;
										file.file_map.actual_encoding = data[0].actual_encoding;
										file.std                      = data[0].std;
									}
								});
					}

					$scope.parse_file = function (file) {
						var params = {
							file_map: file.file_map
						};

						//@TODO If import
						$http.post(
								'/api.php/dbs/' + databaseId + '/diff_tool/' + file.file_id + '/parse',
								params
						)
								 .then(response => {
									 file.delimiters_initially_set = true;
									 file.file                     = response.data;
									 file.name_based_maps          = 0;
									 // Generate Transpose Table, this should really be a directive
									 file.file_transpose           = file.file[0].map(function (col, i) {
										 return file.file.map(function (row) {
											 //call hex2bin once per field
											 return {
												 hex: row[i] ? row[i] : '',
												 bin: row[i] ? $scope.hex2bin(row[i]) : ''
											 };
										 });
									 });
								 });
					}

					// Auto Populate Variables
					$scope.confirm_auto_populate = function () {
						if ($scope.auto_populate.toggle) {
							for (var i = 0; i < $scope.file.file_transpose.length; i++) {
								var header_element     = $scope.file.file_transpose[i][0].bin,
										raw_header_element = $scope.file.file_transpose[i][0].hex;

								// Hex decode the feedonomics variable name
								header_element = header_element.replace(/[^\x00-\x7F]/g, "");
								// Remove whitespace, lowercase, and replace bad characters
								header_element =
										header_element.trim()
																	.toLowerCase()
																	.replace(/ /g, "_")
																	.replace(/-/g, "_");

								header_element += $scope.auto_populate.suffix;
								$scope.auto_populate.toggle = false;

								$scope.file.file_map.maps[i] = header_element;
							}
							$scope.update_fields();
						}
					};

					$scope.toggle_suffix_box = function (value) {
						$scope.auto_populate.toggle = value;
					};


					$scope.unset_name_based_map = function (col_name) {
						delete $scope.file.file_map.maps[col_name];
					};


					//The suffix field
					$scope.auto_populate = {
						toggle: false,
						suffix: '',
					};

				}
			};
		}])
		/** dt-xml-map */
		.directive('dtXmlMap', ['$http', '$timeout', 'AppStateService', function ($http, $timeout, AppStateService) {
			return {
				restrict   : 'E',
				scope      : {
					file        : '=',
					updateFields: '&'
				},
				template: require('./templates/xml_map.html'),
				link       : function ($scope) {

                    const databaseId = AppStateService.getDatabaseId();

					if (typeof $scope.file.file_map === "undefined") {
						$scope.file.file_map = {
							maps        : [
								{name: '', path: ''}
							],
							context_node: ''
						};
					}

					if (typeof $scope.file.xml_parse_recursively === "undefined") {
						$scope.file.xml_parse_recursively = true;
					}

					if (typeof $scope.file.xml_include_attrs === "undefined") {
						$scope.file.xml_include_attrs = false;
					}

					if (typeof $scope.file.xml_force_parse === "undefined") {
						$scope.file.xml_force_parse = 0;
					}

					$scope.hex2bin       = $scope.$parent.hex2bin;
					$scope.update_fields = $scope.$parent.update_fields;

					// Auto Populate XML Variables
					$scope.xml_auto_populate = function () {
						// Return if there is no context_node //
						if (!$scope.file.file_map.context_node) {
							$scope.file.error_xml_auto_populate_missing_context = true;
							return;
						}
						else {
							$scope.file.error_xml_auto_populate_missing_context = false;
							var context_node                                    = $scope.file.file_map.context_node;
						}

						if ($scope.file.file) {
							// file_map object for auto-populate //
							$scope.file.xml_auto_populate_file_map = {};

							// Parse XML //
							var all_context_nodes = [];
							try {
								// If XML is valid and complete
								var xmlDoc = $.parseXML($scope.file.file);
								$(xmlDoc).find(context_node).each(function () {
									all_context_nodes.push(this);
								});
							} catch (err) {
								// If XML is incomplete (We limit the bytes fetched)
								var parser        = new DOMParser();
								var xmlDom        = parser.parseFromString($scope.file.file, "application/xml");
								all_context_nodes = xmlDom.getElementsByTagName(context_node);
							}

							// Loop through each context node //
							if (all_context_nodes && typeof all_context_nodes == 'object') {
								Object.keys(all_context_nodes).forEach(function (key) {
									$(all_context_nodes[key]).children().each(function () {
										$scope.auto_populate_xml_node(this);
									});
								});
							}

							// Display XML auto populate on the UI //
							$scope.display_xml_auto_populate();
							$scope.update_fields();
						}
					};

					// Adds XML node and its attributes to XML file_map //
					$scope.auto_populate_xml_node = function (node, path = '') {
						var nodeChildren = $(node).children();
						var nodeTagName  = $(node).prop('tagName');
						var nodeTagPath  = path + nodeTagName + "/";

						// Ignore parsererror tag - It can be added to top-level node if XML is invalid by dom parser //
						if (nodeTagName == 'parsererror') {
							return;
						}

						// Get all attributes of this node //
						var node_attrs = $scope.get_xml_node_attrs(node);

						// If this node has nested nodes //
						if (nodeChildren.length > 0) {

							// Since it doesn't have a text node, attributes can be added to file_map as it can potentially be a field //
							for (let i = 0; i < node_attrs.length; i++) {
								$scope.add_attr_to_file_map(node_attrs[i], nodeTagPath);
							}

							// Call nested nodes recursively if enabeld //
							if ($scope.file.xml_parse_recursively) {
								nodeChildren.each(function () {
									$scope.auto_populate_xml_node(this, nodeTagPath);
								});
							}
						} else {
							// This is possibly a text node //
							var nodeText = $(node).text();

							if (!nodeText) {
								// Since it doesn't have text node, attributes can be added to file_map //
								for (let i = 0; i < node_attrs.length; i++) {
									$scope.add_attr_to_file_map(node_attrs[i], nodeTagPath);
								}
							} else {
								// This is a text node - Create path //
								var nodePath = path + nodeTagName;

								// If the text node has attributes, they can be possibly used to identify path - Add first attribute to the path //
								if (node_attrs.length > 0) {
									var nodePathFromAttr = $scope.create_file_map_from_attr(node_attrs[0], path, nodeTagName);
									if (nodePathFromAttr) {
										nodePath = nodePathFromAttr;
									}
								}

								// Add path to file_map //
								$scope.add_path_to_xml_file_map(nodePath);
							}
						}
					};

					// Returns string after replacing all non \w characters with underscore //
					$scope.clean_var_name = function (var_name) {
						return var_name.replace(/[^\w]+/g, '_')
													 .replace(/^\_|\_$/g, "")
													 .replace(/_+/, "_");
					};

					// Return all attributes of a node //
					$scope.get_xml_node_attrs = function (node) {
						var attrs = [];
						if ($scope.file.xml_include_attrs) {
							$.each(node.attributes, function (i, attr) {
								attrs.push({'name': attr.name, 'value': attr.value});
							});
						}
						return attrs;
					};

					// Add attribute to file_map //
					$scope.add_attr_to_file_map = function (attr_obj, nodeTagPath) {
						if (attr_obj.name) {
							var attrPath = nodeTagPath + "@" + attr_obj.name;
							$scope.add_path_to_xml_file_map(attrPath);
						}
					};

					// Add attribute to file_map path and return updated path //
					$scope.create_file_map_from_attr = function (attr_obj, path, nodeTagName) {
						if (attr_obj.name && attr_obj.value) {
							return path + nodeTagName + "[@" + attr_obj.name + "='" + attr_obj.value + "']";
						}
						return false;
					};

					// Add provided path to xml file_map object //
					$scope.add_path_to_xml_file_map = function (path) {
						$scope.file.xml_auto_populate_file_map[path] = 1;
					};

					// Auto-populate XML using xml auto-populate file_map object //
					$scope.display_xml_auto_populate = function () {
						// Clean up existing map //
						$scope.file.file_map.maps = [];

						// If there is mapping in xml_auto_populate_file_map obj //
						if (!$.isEmptyObject($scope.file.xml_auto_populate_file_map)) {
							// Object storing already added variable names so we don't repeat names //
							$scope.file.suggested_var_names = {};

							$.each($scope.file.xml_auto_populate_file_map, function (path, val) {
								// Get suggested variable name for this map path //
								var var_name = $scope.suggest_var_name_from_path(path);

								// Change camelCase to underscore and lowercase
								var_name = var_name.replace(/([A-Z]+)/g,
																						function (x, y) {
																							return "_" + y.toLowerCase()
																						})
																	 .replace(/^_/, "")
																	 .replace(/_+/, "_");

								$scope.file.file_map.maps.push({'name': var_name, 'path': path});
							});
						} else {
							$scope.file.file_map.maps.push({'name': '', 'path': ''});
						}
					};

					// Returns suggested variable name from path //
					$scope.suggest_var_name_from_path = function (path) {
						// Start with var_name as path //
						var var_name = $scope.clean_var_name(path);

						// Split path by "/"
						var path_vars = [];
						if (path.indexOf("/") !== -1) {
							path_vars = path.split("/");
						} else {
							path_vars.push(path);
						}

						var path_vars_length = path_vars.length

						if (path_vars_length > 0) {
							var path_vars_minus_offset = 1;
							// Set var_name as last element of path_vars //
							var_name                   = $scope.clean_var_name(path_vars[path_vars_length - path_vars_minus_offset]);

							// Keep reconstructing var_name if it's already taken AND there are more elements to cover in path_vars //
							while (typeof $scope.file.suggested_var_names[var_name] !== 'undefined'
										 && path_vars_minus_offset < path_vars_length
									) {
								path_vars_minus_offset++;
								// Set var_name by joining path_vars elements with "_" //
								var_name = $scope.clean_var_name(path_vars.slice(path_vars_length - path_vars_minus_offset + 1).join("_"));
							}
						}

						// If final var_name is also taken, append the sequential number to it to create a unique one //
						if (typeof $scope.file.suggested_var_names[var_name] !== 'undefined') {
							$scope.file.suggested_var_names[var_name]++;
							var_name += "_" + ($scope.file.suggested_var_names[var_name]);
						}

						// Mark var_name as taken //
						$scope.file.suggested_var_names[var_name] = 1;

						return var_name;
					};

					// XML Stuff
					$scope.add_xml_row = function () {
						var last_index = $scope.file.file_map.maps.length - 1;
						if ($scope.file.file_map.maps[last_index].name != ''
								|| $scope.file.file_map.maps[last_index].path != '') {
							$scope.file.file_map.maps.push({'name': '', 'path': ''});
						}
					}

					$scope.update_force_parse = function (file) {
						file.saving_xml_force_parse = true;
						var xml_force_parse_param   = {'xml_force_parse': file.xml_force_parse};
						$http.put('/api.php/dbs/' + databaseId + '/imports/' + file.id + '/xml_force_parse', xml_force_parse_param)
								 .then(function (response) {
									 var data                            = response.data;
									 file.xml_force_parse                = data.xml_force_parse;
									 file.saving_message_xml_force_parse = false;
									 file.saved_message_xml_force_parse  = true;

									 // timeout added message
									 $timeout(function () {
										 file.saved_message_xml_force_parse = false;
									 }, 1500);
								 }, function (response) {
									 file.saving_message_xml_force_parse = false;
									 if (file.xml_force_parse == '1') {
										 file.xml_force_parse = '0';
									 }
									 else {
										 file.xml_force_parse = '1';
									 }
								 })
					};


				}
			}
		}])
		.directive('dtJsonMap', ['$http', function ($http) {
			return {
				restrict   : 'E',
				scope      : {
					file        : '=',
					updateFields: '&'
				},
				template: require('./templates/json_map.html'),
				link       : function ($scope) {

					$scope.update_fields = $scope.$parent.update_fields;
					if (typeof $scope.file.file_map === "undefined") {
						$scope.file.file_map = {
							maps        : [
								{name: '', path: ''}
							],
							context_node: ''
						};
					}

					// JSON Stuff
					$scope.add_json_row = function () {
						console.log('adding json row');
						var last_index = $scope.file.file_map.maps.length - 1;
						if ($scope.file.file_map.maps[last_index].name != ''
								|| $scope.file.file_map.maps[last_index].path != '') {
							$scope.file.file_map.maps.push({'name': '', 'path': ''});
						}
					}
				}
			}
		}])
		.directive('dtNdjsonMap', ['$http', function ($http) {
			return {
				restrict   : 'E',
				scope      : {
					file        : '=',
					updateFields: '&'
				},
				template: require('./templates/ndjson_map.html'),
				link       : function ($scope) {

					$scope.update_fields = $scope.$parent.update_fields;
					if (typeof $scope.file.file_map === "undefined") {
						$scope.file.file_map = {
							maps        : [
								{name: '', path: ''}
							],
							context_node: ''
						};
					}

					// JSON Stuff
					$scope.add_json_row = function () {
						console.log('adding json row');
						var last_index = $scope.file.file_map.maps.length - 1;
						if ($scope.file.file_map.maps[last_index].name != ''
								|| $scope.file.file_map.maps[last_index].path != '') {
							$scope.file.file_map.maps.push({'name': '', 'path': ''});
						}
					}
				}
			}
		}]);