import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FdxApiResponse } from '@app/core/responses/fdx-api.response';
import { AttributionInfoDataModel } from '@app/feed-ai/models/attribution-info.model';
import { map, Observable } from 'rxjs';

interface AttributorParams {
    attributes: string[];
    description: string;
    googleProductCategory: string;
    gtin: string;
    title: string;
}

@Injectable({
    providedIn: 'root'
})
export default class AttributionDataService {
    constructor(private http: HttpClient) {}

    clearCache(databaseId: string): Observable<FdxApiResponse<void>> {
        return this.http.post<FdxApiResponse<void>>(`/api.php/dbs/${databaseId}/attribution_info/clear_cache`, null);
    }

    getByDbId(databaseId: string): Observable<AttributionInfoDataModel> {
        return this.http.get<FdxApiResponse<AttributionInfoDataModel>>(`/api.php/dbs/${databaseId}/attribution_info`).pipe(
            map(({ data }) => {
                return data;
            })
        );
    }

    runAttributor(databaseId: string, params: AttributorParams): Observable<Record<string, string[]>> {
        return this.http.post<FdxApiResponse<Record<string, string[]>>>(`/api.php/dbs/${databaseId}/run_attributor`, {
            attributes: params.attributes,
            description: params.description,
            google_product_category: params.googleProductCategory,
            gtin: params.gtin,
            title: params.title
        })
            .pipe(
                map(({ data }) => {
                    return data;
                })
            );
    }

    update(databaseId: string, attribution: Omit<AttributionInfoDataModel, 'db_id'>): Observable<void> {
        return this.http.post<void>(`/api.php/dbs/${databaseId}/attribution_info`, attribution);
    }
}
