import * as angular from 'angular';
import * as moment from 'moment';
import { MODULE_NAME } from '../../config/constants';

angular.module(MODULE_NAME).directive('feedwizardWoocommerce', ['$http', '$location', '$q', '$timeout', '$rootScope', 'AppStateService', 'FeedWizardService', 'ngToast', function ($http, $location, $q, $timeout, $rootScope, AppStateService, FeedWizardService, ngToast) {
  return {
    restrict: 'E',
    scope: true,
    template: require('./woocommerce.html'),
    link: function ($scope) {
      const account_id = AppStateService.getAccountId();
      $scope.step = 1;

      $scope.previous_step = function () {
        $scope.step--;
      };

      $scope.next_step = function () {
        $scope.step++;
      };

      $scope.data = {
        'store_url': '',
        'consumer_key': '',
        'consumer_secret': '',
        'catalog_visibility': ['visible'],
        'version': 'v2',
        'status': 'publish',
        'db_id': ''
      };

      var on_step_error = function (response) {
        $scope.previous_step();

        if (response && response.message) {
          ngToast.danger({
            'content': response.message
          });
        } else {
          ngToast.danger({
            'content': 'Error downloading feed! Please check your credentials and try again!'
          });
        }

        if ($scope.data.db_id) {
          $http.delete('/api.php/dbs/' + $scope.data.db_id);
        }

        FeedWizardService.popup.close();
      };

      var steps = [
        // Create DB
        (new FeedWizardStep())
          .setEndpoint(function () {
            return '/api.php/accounts/' + account_id + '/dbs';
          })
          .setMethod('post')
          .setParams(function () {
            return {
              'name': 'WooCommerce Wizard - ' +  moment().format('MM/DD LT')
            };
          })
          .setOnSuccess(function (data) {
            $scope.data.db_id = data.id;
          })
          .setOnError(on_step_error),

        // Generate Authentication URL
        (new FeedWizardStep())
          .setEndpoint(function () {
            return '/api.php/dbs/' + $scope.data.db_id + '/generate_authorization_url';
          })
          .setMethod('get')
          .setParams(function () {
            return {
              'params': {
                'channel': 'woocommerce',
                'store_url': $scope.data.store_url
              }
            };
          })
          .setOnSuccess(function (response) {
            $scope.data.oauth_url = response;

            FeedWizardService.popup.navigate(response);
          })
          .setOnError(on_step_error),

        // Create Import
        (new FeedWizardStep())
          .setEndpoint(function () {
            return '/api.php/dbs/' + $scope.data.db_id + '/imports';
          })
          .setMethod('post')
          .setParams(function () {
            var params = new URLSearchParams();

            params.append('connection_info[api_key]', $scope.data.consumer_key);
            params.append('connection_info[api_secret]', $scope.data.consumer_secret);
            params.append('connection_info[catalog_visibility]', $scope.data.catalog_visibility);
            params.append('connection_info[client]', 'woocommerce');
            params.append('connection_info[oauth_token]', '0');
            params.append('connection_info[protocol]', 'api');
            params.append('connection_info[status]', $scope.data.status);
            params.append('connection_info[store_url]', $scope.data.store_url);
            params.append('connection_info[version]', $scope.data.version);

            return {
              'file_location': 'url',
              'tags': {'platform': 'WooCommerce'},
              'file_type': 'delimited',
              'join_type': 'product_feed',
              'name': 'WooCommerce',
              'url': 'http://imports.feedonomics.com/preprocess/run_preprocess.php?' + params.toString()
            };
          })
          .setOnSuccess(function (response) {
            $scope.data.import_id = response.id;
          })
          .setOnError(on_step_error),

        // Update Import Delimiters
        (new FeedWizardStep())
          .setEndpoint(function () {
            return '/api.php/dbs/' + $scope.data.db_id + '/imports/' + $scope.data.import_id + '/delimiters';
          })
          .setParams(function () {
            return {
              'enclosure': '"',
              'encoding': '',
              'escaper': '"',
              'line_terminator': "\n",
              'separator': ','
            };
          })
          .setMethod('put')
          .setOnError(on_step_error),

        // Grab headers to create file map
        (new FeedWizardStep())
          .setEndpoint(function () {
            return '/api.php/dbs/' + $scope.data.db_id + '/imports/' + $scope.data.import_id  + '/file?format=parsed&limit=4';
          })
          .setMethod('get')
          .setOnSuccess(function (response) {
            $scope.data.file_map = {};

            response[0].forEach(function (field, index) {
              var header = FeedWizardService.hex2bin(response[0][index]);

              var name = header.replace(/[^\x00-\x7F]/g, "")
                .trim()
                .toLowerCase()
                .replace(/ /g, "_")
                .replace(/-/g, "_");

              $scope.data.file_map[response[0][index]] = name;
            });
          })
          .setOnError(on_step_error),

        // Update Import #1 file map
        (new FeedWizardStep())
          .setEndpoint(function () {
            return '/api.php/dbs/' + $scope.data.db_id + '/imports/' + $scope.data.import_id + '/file_map';
          })
          .setMethod('put')
          .setParams(function () {
            return {
              'maps': $scope.data.file_map,
              'name_based_maps': 1,
              'encode_source_file_keys': 1,
              'clean_file_headers': 0
            };
          })
          .setOnError(on_step_error)
      ];

      var wizard = (new FeedWizard($http, $q))
        .addSteps(steps);

      // This is used to grab data from the oauth popup
      var cancel_check_credentials = false;
      var oauthPopupEvent = function (e, data) {
        if (e.detail.success !== '1') {
          ngToast.danger({
            'content': 'Please accept the authorization'
          });

          return;
        }

        $scope.next_step();

        var check_credentials = function () {
          if (cancel_check_credentials === true) {
            return;
          }

          $http.get('/api.php/dbs/' + $scope.data.db_id + '/woocommerce_refresh_token').then(function (response) {
            if (!response.data || !response.data.iss) {
              setTimeout(check_credentials, 1000);

              return;
            }

            $scope.next_step();

            var token = JSON.parse(response.data.refresh_token);

            $scope.data.consumer_key = token.consumer_key;
            $scope.data.consumer_secret = token.consumer_secret;

            wizard.run().then(function () {
              $scope.next_step();

              ngToast.success({
                'content': 'Your build is complete!'
              });
            }, angular.noop, function (progress) {
              $scope.progress = progress;
            });
          });
        };

        check_credentials();
      };

      window.document.addEventListener('oauthPopupEvent', oauthPopupEvent);
      $scope.$on('$destroy', function () {
        cancel_check_credentials = true;
        window.document.removeEventListener('oauthPopupEvent', oauthPopupEvent);
      });

      $scope.open_window = function (url) {
        FeedWizardService.popup.open(url);
      };

      $scope.authorize = function () {
        FeedWizardService.popup.open();

        $scope.next_step();

        wizard.run(2).then(function () {
          $scope.next_step();
        }, angular.noop, function (progress) {
          $scope.progress = progress;
        });
      };
    }
  };
}]);