<div class="d-flex flex-column gap-2">    
    <div class="mb-1">
        <div class="fs-4 fw-bold">Description</div>
        <div [innerHTML]="data.description"></div>
    </div>
    <div *ngIf="data.parameters?.length > 0">
        <div class="fs-4 fw-bold">Parameters</div>
        <table class="table mb-1">
            <thead>
                <tr>
                    <th>Parameter Name</th>
                    <th>Required</th>
                    <th>Default Value</th>
                    <th>Description</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let param of data.parameters">
                    <td [innerHTML]="param.name"></td>
                    <td [innerHTML]="param.required"></td>
                    <td [innerHTML]="param.defaultValue"></td>
                    <td [innerHTML]="param.description"></td>
                </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="data.examples?.length > 0">
        <div class="fs-4 fw-bold">Examples</div>
        <table class="table mb-0">
            <thead>
                <tr>
                    <th *ngIf="data.examples[0].value">Field Value</th>
                    <th>Input</th>
                    <th>Output</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let example of data.examples">
                    <td *ngIf="example.value" [innerHTML]="example.value"></td>
                    <td [innerHTML]="example.input"></td>
                    <td [innerHTML]="example.output"></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>