import FdxError from '../models/FdxError';

export default class DatabasesDataService {
    // Services
    $http = null;
    $q = null;

    constructor($http, $q) {
        this.$http = $http;
        this.$q = $q;
    }

    getAll(idAccount) {

        const def = this.$q.defer();

        const promise = this.$http.get('/api.php/accounts/' + idAccount + '/dbs');

        promise.then(
            (response) => {
                def.resolve(response.data);
            },
            (response) => {
                def.reject(response.data);
            }
        );

        return def.promise;

    }

    create(idAccount, data) {

        const def = this.$q.defer();

        const promise = this.$http.post('/api.php/accounts/' + idAccount + '/dbs', data);

        promise.then(
            (response) => {
                def.resolve(response.data);
            },
            (response) => {
                def.reject(response.data);
            }
        );

        return def.promise;

    }

    update(idDatabase, data) {

        const def = this.$q.defer();

        const promise = this.$http.put('/api.php/dbs/' + idDatabase, data);

        promise.then(
            (response) => {
                def.resolve(response.data);
            },
            (response) => {
                def.reject(response.data);
            }
        );

        return def.promise;

    }

    getOneById(idDatabase) {

        const def = this.$q.defer();

        const promise = this.$http.get(`/api.php/dbs/${idDatabase}`);

        promise.then(
            (response) => {
                def.resolve(response.data);
            },
            (response) => {

                if (response.data?.status === 'fail') {

                    switch (response.data?.message) {
                        case 'insufficient_permission':
                            def.reject(new FdxError('DATABASE_INSUFFICIENT_PERMISSION', 'Insufficient permission'));
                            break;
                    }

                } else {
                    def.reject(response.data);
                }

            }
        );

        return def.promise;

    }

    copy(idDatabase, params) {

        const def = this.$q.defer();

        const promise = this.$http.post('/api.php/dbs/' + idDatabase + '/copy_db', params);

        promise.then(
            (response) => {
                def.resolve(response.data);
            }, (response) => {
                def.reject(response.data);
            });

        return def.promise;

    }

    delete(idDatabase) {

        const def = this.$q.defer();

        const promise = this.$http.delete('/api.php/dbs/' + idDatabase);

        promise.then(
            (response) => {
                def.resolve(response.data);
            },
            (response) => {
                def.reject(response.data);
            }
        );

        return def.promise;

    }

    moveToGroup(idDatabase, data) {

        const def = this.$q.defer();

        const promise = this.$http.post('/api.php/dbs/' + idDatabase + '/move_db_to_db_group', data);

        promise.then(
            (response) => {
                def.resolve(response.data);
            },
            (response) => {
                def.reject(response.data);
            }
        );

        return def.promise;

    }

    runScheduledImports(idDatabase) {

        const def = this.$q.defer();

        const promise = this.$http.post('/api.php/dbs/' + idDatabase + '/run_scheduled_imports');

        promise.then(
            (response) => {
                def.resolve(response.data);
            },
            (response) => {
                def.reject(response.data);
            }
        );

        return def.promise;

    }

    runScheduledExports(idDatabase) {

        const def = this.$q.defer();

        const promise = this.$http.post('/api.php/dbs/' + idDatabase + '/run_scheduled_exports');

        promise.then(
            (response) => {
                def.resolve(response.data);
            },
            (response) => {
                def.reject(response.data);
            }
        );

        return def.promise;

    }

    runParallelExport(idDatabase, idExport, params) {

        const def = this.$q.defer();

        const canceller = this.$q.defer();

        def.promise = this.$http.post(`/api.php/dbs/${idDatabase}/exports/${idExport}/run_parallel_export`,
            params, {
            timeout: canceller.promise
        });

        def.promise.then(
            (response) => {
                def.resolve(response.data);
            },
            (response) => {
                def.reject(response.data);
            },
            () => {
                def.promise.running = false;
            }
        );

        def.promise.cancel = () => {
            canceller.resolve();
            def.resolve();
        }

        def.promise.running = true;

        return def.promise;

    }

    getFields(idDatabase, params) {

        const def = this.$q.defer();

        const promise = this.$http.get(`/api.php/dbs/${idDatabase}/db_fields`, { params: params });

        promise.then(
            (response) => {
                def.resolve(response.data);
            },
            (response) => {
                def.reject(response.data);
            }
        );

        return def.promise;

    }

    getData(idDatabase, params) {

        const def = this.$q.defer();
        const cancel = (reason) => {
            def.reject(reason);
        };

        const promise = this.$http.get(`/api.php/dbs/${idDatabase}/data`, { params: params, timeout: def.promise });

        promise.then(
            (response) => {
                def.resolve(response.data);
            },
            (response) => {
                def.reject(response.data);
            }
        );

        return {
            promise: def.promise,
            cancel: cancel
        };

    }
    
    getDataForRow(idDatabase, row) {

        const def = this.$q.defer();

        const promise = this.$http.get(`/api.php/dbs/${idDatabase}/data_row/${row}}`);

        promise.then(
            (response) => {
                def.resolve(response.data);
            },
            (response) => {
                def.reject(response.data);
            }
        );

        return def.promise;
        
    }

    getTransformedData(idDatabase, idExport, params) {

        const def = this.$q.defer();

        const promise = this.$http.get(`/api.php/dbs/${idDatabase}/transformed_data/${idExport}`, { params: params });

        promise.then(
            (response) => {
                def.resolve(response.data);
            },
            (response) => {
                def.reject(response.data);
            }
        );

        return def.promise;

    }

    getIsRunning(idDatabase, pollDuration = null, isRunning = null) {

        const def = this.$q.defer();
        const options = { params : {} };
        if (pollDuration) {
            options.params.poll_duration = pollDuration;
        }
        if (!(typeof isRunning === 'undefined')) {
            options.params.is_running = isRunning;
        }

        const promise = this.$http.get(`/api.php/dbs/${idDatabase}/running`, options);

        promise.then(
            (response) => {
                def.resolve(response.data);
            },
            (response) => {
                def.reject(response.data);
            }
        );

        return def.promise;

    }

}

DatabasesDataService.$inject = ['$http', '$q'];
