import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { isEmpty } from '@app/core/functions/isEmpty';
import { BaseExportFormComponent } from '@app/exports/components/base-export-form/base-export-form.component';
import { ExistingExport } from '@app/exports/models/interfaces/existing-export.interface';
import { faXmarkLarge } from '@fortawesome/pro-regular-svg-icons';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { takeUntil } from 'rxjs';

interface DropdownOption {
    display: string;
    value: string;
}

interface SortableFieldOption extends DropdownOption {
    source: string;
}

@Component({
    selector: 'fdx-export-deduplicate-fields',
    templateUrl: './export-deduplicate-fields.component.html',
    styleUrls: ['./export-deduplicate-fields.component.scss']
})
export class ExportDeduplicateFieldsComponent extends BaseExportFormComponent implements OnChanges, OnInit {
    @Input() exportFields: { field_name: string; export_field_name: string; }[];
    @Input() exportItem: ExistingExport;
    @Output() readonly dedupeFieldsChange: EventEmitter<string[]> = new EventEmitter<string[]>();

    iconDelete: IconDefinition = faXmarkLarge;

    deduplicateLabels: Record<number, Record<string, string>> = {
        0: { num: '1', sup: 'st' },
        1: { num: '2', sup: 'nd' },
        2: { num: '3', sup: 'rd' },
        3: { num: '4', sup: 'th' },
        4: { num: '5', sup: 'th' },
        5: { num: '6', sup: 'th' },
        6: { num: '7', sup: 'th' },
        7: { num: '8', sup: 'th' },
        8: { num: '9', sup: 'th' },
        9: { num: '10', sup: 'th' }
    };
    dedupeFieldOptions: SortableFieldOption[] = [];
    sortableFieldOptions: SortableFieldOption[] = [
        { display: 'Default Sort', source: '', value: '' }
    ];

    get disableDeduplicate(): boolean {
        return this.exportFields?.length < 2;
    }

    constructor(
        private readonly fb: UntypedFormBuilder
    ) {
        super();
    }

    ngOnChanges({exportItem, exportFields}: SimpleChanges): void {
        super.ngOnChanges({ exportItem });

        if (exportFields?.currentValue) {
            this.sortableFieldOptions = this.extractSortableFieldOptions(exportFields.currentValue);
            this.dedupeFieldOptions = this.extractDedupeFieldOptions(this.sortableFieldOptions);
        }
        if (exportItem?.previousValue && exportItem?.currentValue?.id !== exportItem?.previousValue?.id) {
            this.sortableFieldOptions = this.extractSortableFieldOptions(exportItem?.currentValue?.export_fields);
            this.dedupeFieldOptions = this.extractDedupeFieldOptions(this.sortableFieldOptions);
        }
    }

    castAsFormGroup(group: AbstractControl): UntypedFormGroup {
        return group as UntypedFormGroup;
    }

    trackDeduplicateFieldName(index: number, _field: AbstractControl): number {
        return index;
    }

    extractDedupeFieldOptions([first, ...rest]: SortableFieldOption[]): SortableFieldOption[] {
        return [
            ...rest
        ];
    }

    extractSortableFieldOptions(exportFields: { field_name: string; export_field_name: string; }[]): SortableFieldOption[] {
        const fields: SortableFieldOption[] = [
            {
                display: 'Default sort',
                source: '',
                value: ''
            }
        ];

        const exportFieldNameSet = new Set();

        for (const { export_field_name } of exportFields) {
            if (!isEmpty(export_field_name)) {
                fields.push({
                    display: export_field_name,
                    source: 'Fields from export map',
                    value: export_field_name
                });

                exportFieldNameSet.add(export_field_name);
            }
        }

        return fields;
    }

    protected initForm(): void {
        this.form = this.fb.group({
            deduplicateFieldName: this.fb.array([])
        });

        this.initDedupeField(this.exportItem.deduplicate_field_name).forEach((value) => {
            const newGroup = this.fb.group({ name: value });
            this.deduplicateFieldName.push(newGroup);
        });
    }

    protected patchForm(exportItem: ExistingExport): void {
        const newArray = this.fb.array([]);

        const dedupeFields = this.initDedupeField(exportItem.deduplicate_field_name);
        dedupeFields.forEach((value) => {
            newArray.push(this.fb.group({ name: value }));
        });

        this.patchValue({
            deduplicateFieldName: newArray
        });
    }

    protected afterFormInit() {
        this.form.valueChanges
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((changes) => {
                this.dedupeFieldsChange.emit(this.extractDedupeFieldNames(changes.deduplicateFieldName));
            });

        this.formReady.emit(this.form);
    }

    removeDeduplicateField( index ): void {
        this.exportItem.deduplicate_field_name.splice(index, 1);
        this.patchForm(this.exportItem);
        this.dedupeFieldsChange.emit(this.exportItem.deduplicate_field_name);
    }

    addDeduplicateField(): void {
        this.exportItem.deduplicate_field_name.push(null);
        this.patchForm(this.exportItem);
    }

    get deduplicateFieldName(): UntypedFormArray {
        return <UntypedFormArray>this.form.get('deduplicateFieldName');
    }

    extractDedupeFieldNames(changes): string[] {
        return changes.map(value => value.name).filter(n => n);
    }

    get showingMultipleFields(): boolean {
        return this.deduplicateFieldName.controls.length > 1;
    }
    get showAddDedupeFieldBtn(): boolean {
        const currentValuesCount = this.extractDedupeFieldNames(this.deduplicateFieldName.value).length;
        return currentValuesCount > 0
            && this.deduplicateFieldName.controls.length === currentValuesCount
            && this.deduplicateFieldName.controls.length < 3;
    }

    // Initialize deduplicate
    initDedupeField(deduplicateFieldNameValue: string[]): string[] {
        if (deduplicateFieldNameValue.length === 0) {
            deduplicateFieldNameValue = [null];
        }
        return deduplicateFieldNameValue;
    }
}
