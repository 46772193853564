import { Pipe, PipeTransform } from '@angular/core';

/*
 * A pipe that can turn long numbers into shortened versions that use a suffix of some sort to show the number
 * i.e. 15123 to 14.1K.
 * @param {digits} number How many digits to show.  Defaults to 3. 
 * @returns {mappedValue} If the value is a string that can be converted to a number (positive or negative) after removing seperators, return
 * a shortened string for that number.  Otherwise, return the value given.
 * Limitations: Only shortens to billions.  Larger numbers will return as themselves.
 * Digits are not a precise length.  Make sure to account for the suffix, decimal point, and a negative sign as necessary.
 */
@Pipe({
    name: 'shortenNumber'
})
export class ShortenNumberPipe implements PipeTransform {
    transform<T>(value: T, digits: number = 3): string | T {
        if (typeof value !== 'string' && typeof value !== 'number') {
            return value;
        }

        // remove separators
        let number: number;
        if (typeof value === 'string') {
            number = Number(value.replace(/,/g, ''));
        } else {
            number = value;
        }
        if (isNaN(number)) return value;

        const suffixes: string[] = ['K', 'M', 'B'];
        const numberLength = Math.trunc(number).toString().length;
        
        if (number > 0 && numberLength > (9 + digits) || number < 0 && numberLength > (10 + digits)) {
            // As this only supports to billions, just return the number instead of risking bad output.
            return value;
        }

        let count = -1;
        while (Math.abs(number) >= 1000) {
            number = number / 1000;
            count++;
        }

        if (count === -1) {
            return value;
        }

        let formattedNumber: string = (number > 0 ? String(number).substring(0, digits + 1) : String(number).substring(0, digits + 2));
        // remove any end decimal points without removing others.
        if (formattedNumber.endsWith('.')) {
            formattedNumber = formattedNumber.substring(0, formattedNumber.length - 1);
        }

        return formattedNumber + suffixes[count];
    }
}
