import { NgModule } from '@angular/core';
import { AutoHeightDirective } from '@app/modules/directives/directives/auto-height/auto-height.directive';
import { DrawerDirective } from '@app/modules/directives/directives/drawer/drawer.directive';
import { DropdownHeightDirective } from '@app/modules/directives/directives/dropdown-height/dropdown-height.directive';
import { InlineDiffDirective } from '@app/modules/directives/directives/inline-diff/inline-diff.directive';
import { ScrollSpyDirective } from '@app/modules/directives/directives/scroll-spy/scroll-spy.directive';
import { StopPropagationDirective } from '@app/modules/directives/directives/stop-propagation/stop-propagation.directive';
import { TextOverflowWatcherDirective } from '@app/modules/directives/directives/text-overflow-watcher/text-overflow-watcher.directive';
import { TrackVisibilityDirective } from '@app/modules/directives/directives/track-visibility/track-visibility.directive';
import { PipesModule } from '@app/modules/pipes/pipes.module';

@NgModule({
    declarations: [
        AutoHeightDirective,
        DrawerDirective,
        DropdownHeightDirective,
        InlineDiffDirective,
        ScrollSpyDirective,
        StopPropagationDirective,
        TextOverflowWatcherDirective,
        TrackVisibilityDirective
    ],
    imports: [
        PipesModule
    ],
    exports: [
        AutoHeightDirective,
        DrawerDirective,
        DropdownHeightDirective,
        InlineDiffDirective,
        ScrollSpyDirective,
        StopPropagationDirective,
        TextOverflowWatcherDirective,
        TrackVisibilityDirective
    ]
})
export class DirectivesModule { }
