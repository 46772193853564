import { Directive, ElementRef, EventEmitter, HostBinding, Inject, Input, OnInit, Output } from '@angular/core';
import { WINDOW } from '@app/core/providers/window.provider';

/** Automatically sizes the element to fit the rest of the height of the screen, based on
 * a passed in offset. Optionally can pass in a minimum height as well.
 */

@Directive({
    selector: '[fdxAutoHeight]'
})
export class AutoHeightDirective implements OnInit {
    @Input() minHeight?: number = 0;
    @Input() offset: number = 24;  // default 1.5rem page padding bottom

    @Output() readonly heightChange: EventEmitter<number> = new EventEmitter<number>();

    private oldHeight: number;

    constructor(
        @Inject(WINDOW) private readonly window: Window,
        public readonly elementRef: ElementRef<HTMLElement>
    ) { }

    ngOnInit(): void {
        this.oldHeight = this.minHeight;
    }

    @HostBinding('style.height.px')
    public get hostHeight(): number {
        const el = this.elementRef?.nativeElement;

        if (!el) {
            return this.oldHeight;
        }

        const heightNumber: number = this.window.innerHeight - el.getBoundingClientRect().top - this.offset;

        const newHeight = Math.max(heightNumber, this.minHeight);

        if (this.oldHeight !== newHeight) {
            this.oldHeight = newHeight;
            this.heightChange.emit(newHeight);
        }

        return newHeight;
    }
}
