<div class="d-flex flex-column offcanvas-wrapper">
    <div class="offcanvas-header" [ngClass]="offcanvasHeaderClasses">
        <div class="d-flex align-items-center gap-2">
            <fa-icon *ngIf="offcanvasTitleIcon" [icon]="offcanvasTitleIcon" size="lg"></fa-icon>
            <h3 class="offcanvas-title">{{ offcanvasTitle }}</h3>
        </div>
        <div class="d-flex align-items-center">
            <ng-container *ngTemplateOutlet="headerAddon"></ng-container>
            <div *ngIf="headerAddon" class="fdx-vertical-line mx-3"></div>
            <button
                *ngIf="enableMinimize"
                type="button"
                class="btn btn-link header-btn"
                title="Minimize"
                aria-label="Minimize"
                (click)="offcanvasMinimized.emit()">
                <fa-icon class="no-margin" [icon]="minimizeIcon"></fa-icon>
            </button>
            <ng-container *ngIf="enableFullscreen">
                <button
                    *ngIf="showGoFullscreenIcon; else minimize"
                    type="button"
                    class="btn btn-link header-btn"
                    title="Go full screen"
                    aria-label="Go full screen"
                    (click)="onOffcanvasPanelSizeUpdate(offcanvasPanelSizeEnum.FullScreen)">
                    <fa-icon class="no-margin" [icon]="goFullScreenIcon"></fa-icon>
                </button>
                <ng-template #minimize>
                    <button
                        type="button"
                        class="btn btn-link header-btn"
                        title="Exit full screen"
                        aria-label="Exit full screen"
                        (click)="onOffcanvasPanelSizeUpdate(offcanvasPanelSizeEnum.Original)">
                        <fa-icon class="no-margin" [icon]="exitFullScreenIcon"></fa-icon>
                    </button>
                </ng-template>
            </ng-container>
            <button
                type="button"
                class="btn-close header-btn"
                data-bs-dismiss="offcanvas"
                title="Close"
                aria-label="Close"
                (click)="dismissOffcanvasWithX()"
                [disabled]="closeDisabled">
            </button>
        </div>
    </div>
    <div class="offcanvas-body" tabindex="-1" [ngClass]="bodyClasses">
        <ng-content></ng-content>
    </div>
</div>
