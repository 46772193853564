import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SelectMultipleLabelTemplateComponent } from '@app/modules/ng-select-templates/components/select-multiple-label-template/select-multiple-label-template.component';
import { NgSelectModule } from '@feedonomics/ng-select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
    declarations: [
        SelectMultipleLabelTemplateComponent
    ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        NgSelectModule
    ],
    exports: [
        SelectMultipleLabelTemplateComponent
    ]
})
export class NgSelectTemplatesModule { }
