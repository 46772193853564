import { ILogService } from 'angular';

import ArgumentRequiredException from '../exceptions/argument-required-exception';

enum LogLevel {
	Log = 'log',
	Info = 'info',
	Warn = 'warn',
	Error = 'error',
	Debug = 'debug'
}

export default class LogService {
	static readonly $inject: string[] = [
		'$log'
	];

	constructor(private readonly $log: ILogService) { }

	public log(parent: string, func: string, message: string | object): void {
		this.write(
			LogLevel.Log,
			parent,
			func,
			message
		);
	}

	public info(parent: string, func: string, message: string | object): void {
		this.write(
			LogLevel.Info,
			parent,
			func,
			message
		);
	}

	public warn(parent: string, func: string, message: string | object): void {
		this.write(
			LogLevel.Warn,
			parent,
			func,
			message
		);
	}

	public error(parent: string, func: string, message: string | object): void {
		this.write(
			LogLevel.Error,
			parent,
			func,
			message
		);
	}

	public debug(parent: string, func: string, message: string | object): void {
		this.write(
			LogLevel.Debug,
			parent,
			func,
			message
		);
	}

	private write(level: LogLevel, parent: string, func: string, message: string | object): void {
		if (!parent) {
			throw new ArgumentRequiredException('parent');
		}

		if (!func) {
			throw new ArgumentRequiredException('func');
		}

		if (!message) {
			throw new ArgumentRequiredException('message');
		}

		let msg: string;

		if (typeof (message) === 'string') {
			msg = message;
		} else {
			try {
				msg = JSON.stringify(message);
			} catch {
				msg = '[LOG FAILURE] Could not stringify message object.';
			}
		}

		const text = `[FDX-${parent}:${func}] ${msg}`;

		switch (level) {
			case LogLevel.Info:
				this.$log.info(text);
				break;

			case LogLevel.Warn:
				this.$log.warn(text);
				break;

			case LogLevel.Error:
				this.$log.error(text);
				break;

			case LogLevel.Debug:
				this.$log.debug(text);
				break;

			default:
				this.$log.log(text);
				break;
		}
	}
}

export { LogService };
