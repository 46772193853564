import { Component, Input } from '@angular/core';
import { QueryType } from '@app/modules/inputs/enums/query-type.enum';
import { QueryInputsComponent } from '@app/modules/query/components/query-inputs/query-inputs.component';
import { TransformerInputsComponent } from '@app/modules/query/components/transformer-inputs/transformer-inputs.component';
import { IconDefinition, faArrowRightArrowLeft } from '@fortawesome/pro-solid-svg-icons';

@Component({
    selector: 'fdx-query-type-buttons',
    templateUrl: './query-type-buttons.component.html',
    styleUrls: ['./query-type-buttons.component.scss']
})
export class QueryTypeButtonsComponent {

    @Input() queryInputs: QueryInputsComponent;
    @Input() transformerInputs?: TransformerInputsComponent;
    @Input() disabled?: boolean = false;

    readonly faArrowRightArrowleft: IconDefinition = faArrowRightArrowLeft;

    get disabledState(): boolean {
        if (!this.queryInputs?.basicQueryData) {
            return true;
        }

        if (this.queryInputs.basicQueryData?.queryType === QueryType.Advanced) {
            return this.disabled || this.queryInputs.basicQueryData?.loading || this.queryInputs.basicQueryData?.tooComplex || this.queryInputs.basicQueryData?.error;
        }

        return this.disabled;
    }

    get buttonText(): string {
        if (!this.queryInputs) {
            return null;
        }

        if (this.queryInputs.basicQueryData?.queryType === QueryType.Advanced) {
            return 'Switch to basic mode';
        }

        return 'Switch to advanced mode';
    }

    get tooltip(): string {
        if (!this.queryInputs) {
            return null;
        }

        let text = '';

        if (this.queryInputs.basicQueryData?.transformerMode) {
            text = 'Transformer';
        } else {
            text = 'Search';
        }

        if (this.queryInputs.basicQueryData?.tooComplex) {
            return `Query is too complex for Basic ${text}`;
        }

        if (this.queryInputs.basicQueryData?.error) {
            return `Please finish or clear your query before switching to Basic ${text}`;
        }

        return null;
    }

    changeDisplayedQuery(): void {
        // Call toggle on transformer first since it uses queryInputs reference that would be changed
        this.transformerInputs?.toggleQueryMode();
        this.queryInputs.toggleQueryMode();
    }
}
