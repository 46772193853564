import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
    transform(value: unknown, limit: number = 25, ellipsis: string = '...'): string {
        const stringValue = String(value);
        return stringValue?.length > limit ? stringValue.substring(0, limit) + ellipsis : stringValue;
    }
}
