/* eslint-disable @typescript-eslint/naming-convention */
import { CodeMirrorOptionsModel } from '@app/modules/inputs/models/code-mirror-options.model';
import { CodeMirrorModes } from '../enums/code-mirror-modes.enum';
import { CodeMirrorThemes } from '../enums/code-mirror-themes.enum';

export class CodeMirrorConstants {
    public static placeholder: string = 'e.g. [title] contains \'hello\'';

    public static defaultOptions: CodeMirrorOptionsModel = {
        extraKeys: {
            'Tab': false,
            'Shift-Tab': false
        },
        theme: CodeMirrorThemes.Transformer,
        mode: CodeMirrorModes.Transformer,
        placeholder: this.placeholder
    };
}
