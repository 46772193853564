import AppStateService from '@ajs/services/AppStateService';
import fdxUI from '@ajs/services/fdxUI';
import { Component } from '@angular/core';
import { AppMenuTab } from '@app/core/models/enums/app-menu-tab.enum';
import { DbFieldModel } from '@app/databases/models/db-field.model';
import { DatabasesDataService } from '@app/databases/services/databases-data.service';
import { FeedAiTab } from '@app/feed-ai/enums/feed-ai-tab.enum';
import { BasePageComponent } from '@app/modules/page/abstract/base-page.component';
import { takeUntil } from 'rxjs';

@Component({
    selector: 'fdx-feed-ai-page',
    templateUrl: './feed-ai-page.component.html',
    styleUrls: ['./feed-ai-page.component.scss']
})
export class FeedAiPageComponent extends BasePageComponent {
    feedAiTabs: typeof FeedAiTab = FeedAiTab;
    activeTab = FeedAiTab.Categorization;

    appMenuTab = AppMenuTab.Transformers;
    databaseFields: DbFieldModel[] = [];
    title = 'FeedAI™';

    get databaseId(): string {
        return this.appStateService.getDatabaseId();
    }

    get hasAttributionAccess(): boolean {
        return this.appStateService.getAccount().hasFeature('run_attribution', 'enabled')
            || this.appStateService.getUser().hasFeature('run_attribution', 'enabled');
    }

    constructor(
        private appStateService: AppStateService,
        private databasesDataService: DatabasesDataService,
        fdxUI: fdxUI
    ) {
        super(fdxUI);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.databasesDataService.getFields(this.databaseId)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((databaseFields) => {
                    this.databaseFields = databaseFields;
                },
                () => {
                    this.fdxUI.showToastError('Unable to load db fields');
                }
            );
    }
}
