import { Directive, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[fdxStopPropagation]'
})
export class StopPropagationDirective {
    @Input() stopPropogationBypass: boolean = false;

    @HostListener('click', ['$event'])
    onClick(event: Event): void {
        if (this.stopPropogationBypass) {
            return;
        }

        event.stopPropagation();
    }

    @HostListener('mousedown', ['$event'])
    onMouseDown(event: Event): void {
        if (this.stopPropogationBypass) {
            return;
        }

        event.stopPropagation();
    }
}
