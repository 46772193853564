export class BasicTransformerField {
    
    value: string;
    
    get isDefined(): boolean {
        return this.value !== undefined;
    }
    
    constructor(value: string) {
        this.value = value;
    }
}