import { Component } from '@angular/core';
import { BootstrapThemeProp } from '@app/core/services/bootstrap-theme.service';
import { Modal } from '@app/modules/modals/modals.module';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'fdx-exports-faq-modal',
    templateUrl: './exports-faq-modal.component.html',
    styleUrls: ['./exports-faq-modal.component.scss']
})
export class ExportsFaqModalComponent implements Modal {
    public modalTheme: BootstrapThemeProp = 'primary';

    constructor(
        private modal: NgbActiveModal,
    ) { }

    public onModalSubmit(event: MouseEvent): void {
        this.modal.close();
    }

    public onModalCancel(event: MouseEvent): void {
        this.modal.dismiss();
    }
}
