import FdxUI from '@ajs/services/fdxUI';
import { Component } from '@angular/core';
import { BootstrapThemeProp } from '@app/core/services/bootstrap-theme.service';
import { DbFieldModel } from '@app/databases/models/db-field.model';
import { Modal } from '@app/modules/modals/modals.module';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

interface ResolverInterface {
    exportId: string,
    exportFields: { field_name: string; export_field_name: string; required?: string; sort_order?: number; }[],
    dbFields: DbFieldModel[]
}
@Component({
    selector: 'fdx-import-template-string-modal',
    templateUrl: './import-template-string-modal.component.html'
})
export class ImportTemplateStringModalComponent implements Modal {

    exportId: string;
    exportFields: { field_name: string; export_field_name: string; note?: string; required?: string; sort_order?: number; }[];
    dbFields: DbFieldModel[];
    importString: string;
    modalTheme: BootstrapThemeProp = 'primary';

    constructor(
        private readonly fdxUI: FdxUI,
        private readonly modal: NgbActiveModal,
    ) { }

    onModalInit(resolve: ResolverInterface): void {
        this.exportId = resolve.exportId;
        this.exportFields = resolve.exportFields;
        this.dbFields = resolve.dbFields;
    }

    onModalSubmit(event: MouseEvent): void {
        let data: any;
        let newExportFields = [];

        try {
            data = JSON.parse(this.importString);
            if (!Array.isArray(data)) {
                this.fdxUI.showToastError('Import string is invalid!');
                return;
            }
        } catch (e) {
            this.fdxUI.showToastError('Import string isn\'t valid JSON!');
            return;
        }

        let output = [];

        data.forEach((field) => {
            if (typeof field === 'object') {
                const fieldVariable = field.variable && this.dbFields.find((dbField: DbFieldModel) => dbField.field_name === field.variable) ? field.variable : '';
                output.push({
                    field_name: fieldVariable,
                    export_field_name: field.field,
                    note: field.note
                });
            } else {
                output.push({
                    field_name: '',
                    export_field_name: field,
                    note: ''
                });
            }
        });

        if (this.exportId) {
            let found_fields = [];

            for (const new_export_field of output) {
                let found = false;

                this.exportFields.forEach(function (existing_export_field) {
                    if (existing_export_field.export_field_name === new_export_field.export_field_name) {
                        existing_export_field.note = new_export_field.note;
                        found_fields.push(new_export_field.export_field_name);
                        found = true;
                    }
                });

                if (found === false) {
                    this.exportFields.push(new_export_field);
                    found_fields.push(new_export_field.export_field_name);
                }
            }

            let updated_fields = [],
                removed_fields = [];

            for (const export_field of this.exportFields) {
                if (found_fields.indexOf(export_field.export_field_name) >= 0) {
                    updated_fields.push(export_field);
                } else {
                    removed_fields.push(export_field.export_field_name);
                }
            }

            if (removed_fields.length) {
                const answer = confirm("You are about to remove the following fields, are you sure?\n\n" + removed_fields.join('\n'));
                if (!answer) {
                    return;
                }
            }

            newExportFields = updated_fields;
        } else {
            newExportFields = output;
        }

        this.modal.close(newExportFields);
    }

    onModalCancel(event: MouseEvent): void {
        this.modal.dismiss();
    }
}
