
export default class ArgumentRequiredException extends Error {
	public name: string = 'ArgumentRequiredException';

	constructor(public arg: string) {
		super(`${arg} parameter is required.`);

		// Maintains proper stack trace for where our error was thrown (only available on V8)
		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, ArgumentRequiredException)
		}
	}
}
