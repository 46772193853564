import { Component } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { isEmpty } from '@app/core/functions/isEmpty';
import { BaseExportFormComponent } from '@app/exports/components/base-export-form/base-export-form.component';
import { ExistingExport } from '@app/exports/models/interfaces/existing-export.interface';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';

@Component({
    selector: 'fdx-export-tags-form',
    styleUrls: ['./export-tags-form.component.scss'],
    templateUrl: './export-tags-form.component.html'
})
export class ExportTagsFormComponent extends BaseExportFormComponent {

    iconHelp: IconDefinition = faQuestionCircle;

    get tags(): UntypedFormArray {
        return this.form.controls.tags as UntypedFormArray;
    }

    get tagGroups(): UntypedFormGroup[] {
        return this.tags.controls as UntypedFormGroup[];
    }

    constructor(
        private readonly fb: UntypedFormBuilder
    ) {
        super();
    }

    addNewTag(): void {
        const lastTagGroup = this.tagGroups[this.tagGroups.length - 1];

        if (isEmpty(lastTagGroup.controls.tag.value) && isEmpty(lastTagGroup.controls.value.value)) {
            return;
        }

        this.tags.push(this.fb.group({
            tag: [''],
            value: ['']
        }));
    }

    arraySeed(exportItem: ExistingExport): UntypedFormGroup[] {
        return exportItem?.tags?.length
            ? exportItem.tags.map(({ tag, value }) => this.fb.group({ tag: [tag], value: [value]}))
            : [this.fb.group({ tag: [''], value: ['']})];
    }

    protected initForm(): void {
        this.form = this.fb.group({
            tags: this.fb.array(this.arraySeed(this.exportItem))
        });
    }

    protected patchForm(exportItem: ExistingExport): void {
        const tags = exportItem.tags?.length
            ? exportItem.tags.map(({ tag, value }) => { return { tag, value }; })
            : [{ tag: '', value: '' }];

        const tagsControl = this.form?.controls.tags as UntypedFormArray;

        if (tagsControl) {
            tagsControl.clear({ emitEvent: false });

            tags.forEach(({ tag, value}) => {
                tagsControl.push(this.fb.group({ tag, value }), { emitEvent: false });
            });
        }
    }

    trackByIndex(index: number): number {
        return index;
    }
}
