import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

// Components
import { ConfirmationModalComponent } from '@app/modules/modals/components/confirmation-modal/confirmation-modal.component';
import { LockedAccessModalComponent } from '@app/modules/modals/components/locked-access-modal/locked-access-modal.component';
import { ModalHocComponent } from '@app/modules/modals/components/modal-hoc/modal-hoc.component';

// Exports
export { ModalOptions } from '@app/modules/modals/interfaces/modal-options.interface';
export { Modal } from '@app/modules/modals/interfaces/modal.interface';

@NgModule({
    declarations: [
        ConfirmationModalComponent,
        LockedAccessModalComponent,
        ModalHocComponent
    ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        NgbModalModule
    ],
    exports: [
        ConfirmationModalComponent,
        LockedAccessModalComponent,
        ModalHocComponent
    ]
})
export class ModalsModule {
}
