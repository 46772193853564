import * as angular from 'angular';
import { MODULE_NAME } from '../config/constants';

import { CheatSheetOffcanvasComponent } from '@app/modules/cheat-sheet/components/cheat-sheet-offcanvas/cheat-sheet-offcanvas.component';
import { componentName as deleteModalComponentName } from '../components/fdx-transformers-page/components/fdx-transformers-delete-modal/fdx-transformers-delete-modal.component';
import { componentName as downloadModalComponentName } from '../components/fdx-transformers-page/components/fdx-transformers-download-modal/fdx-transformers-download-modal.component';
import { componentName as faqModalComponentName } from '../components/fdx-transformers-page/components/fdx-transformers-faq-modal/fdx-transformers-faq-modal.component';
import { componentName as fieldDependencyTreeModalComponentName } from '../components/fdx-transformers-page/components/fdx-transformers-field-dependency-tree-modal/fdx-transformers-field-dependency-tree-modal.component';

angular.module(MODULE_NAME).controller('TransformersControllerOld', ['$scope', '$location', '$state', '$stateParams', '$cookies', '$http', '$timeout', 'ngToast', 'AppStateService', 'CSVService', 'fdxUI', 'ModalService', 'OffcanvasService', function($scope, $location, $state, $stateParams, $cookies, $http, $timeout, ngToast, AppStateService, CSVService, fdxUI, ModalService, OffcanvasService) {
	fdxUI.setTitle('Transformers');
	fdxUI.setActiveTab('transformers');

    const databaseId = AppStateService.getDatabaseId();
    const fieldName = $stateParams.field_name;

    $scope.current_db = {
        'id': databaseId
    };

	$scope.user_id = '';

	$scope.isPrivacyLevelAtLeastAnalyst = () => {
		return AppStateService.isPrivacyLevelAtLeastAnalyst();
	};
    $scope.accountHasFeature = (feature, value) => AppStateService.getAccount().hasFeature(feature, value);
    $scope.userHasFeature = (feature, value) => AppStateService.getUser().hasFeature(feature, value);
	$scope.enable_image_processing = AppStateService.getAccount().hasFeature('image_processing', 'enabled');
	$scope.enable_rakuten_transformer = AppStateService.getAccount().hasFeature('rakuten_properties_transformer', 'enabled');
	$scope.transformer_mode = 'power';
	$scope.hide_this_message = {pressed: false};

	$scope.set_transformer_mode = function(mode) {
		$scope.transformer_mode = mode;
	};

	// get account_id from cookies
	$scope.account_id = $cookies.get('account_id');

	$scope.transformers = [];
	$scope.transformer_field_dependency = [];
	if (fieldName) {
		const transformer_params = {'params': {'field_name': fieldName}}

		// grab transformer field dependencies
		$http.get('/api.php/dbs/' + databaseId + '/transformer_field_dependency', transformer_params)
		.then(function(response, status, headers, config) {
			$scope.transformer_field_dependency = response.data;
		}, function(response, status, headers, config) {
			$scope.transformer_field_dependency = [];
		});

		// grab the transformers
		$http.get('/api.php/dbs/' + databaseId + '/transformers', transformer_params)
			.then(function(response, status, headers, config) {
				$scope.transformers = response.data;
				for (let n=0; n<$scope.transformers.length; n++) {
					$scope.transformers[n].new_sort_order = $scope.transformers[n].sort_order;
					const temp_exports = JSON.parse($scope.transformers[n].exports);
					$scope.transformers[n].export_id = temp_exports.export_ids;

					// fail safe for bad data. Cannot have "all" selected and others too
					if(temp_exports.export_ids.includes("0")) {
						$scope.transformers[n].export_id = ["0"];
					}
					if($scope.transformers[n].transformer.includes('transform_image(')) {
						$scope.formatTransformer($scope.transformers[n]);
					}
				}
			});
	}

	// clean up view of transformer so that sub-functions are on new lines
	$scope.formatTransformer = function(trans) {
		let formattedTransformer = '';
		const spaceStr = ' ';
		let paranthesisCount = 0;
		let commaPosition = 0;
		let postComma = '';
		let temp_transformer = trans.transformer;

		// remove all spaces before formatting; ignore spaces that are in parameter strings
		temp_transformer = temp_transformer.replace(/\s+(?=(?:[^\'"]*[\'"][^\'"]*[\'"])*[^\'"]*$)/g,"");

		for (let i = 0; i < temp_transformer.length; i++) {
			if(temp_transformer[i] === '(') {
				paranthesisCount++;
				if(commaPosition !== 0) {
					formattedTransformer += '\n';
					formattedTransformer += spaceStr.repeat(paranthesisCount);
					formattedTransformer += postComma;

					postComma = '';
					commaPosition = 0;
				}
			}

			if(commaPosition !== 0) {
				postComma += temp_transformer[i];
			}
			else {
				formattedTransformer += temp_transformer[i];
			}

			if(temp_transformer[i] === ')') {
				paranthesisCount--;
				formattedTransformer += spaceStr.repeat(paranthesisCount);
				formattedTransformer += postComma;

				postComma = '';
				commaPosition = 0;
			}

			if(temp_transformer[i] === ',') {
				commaPosition = i;
			}

			// end of transformer
			if(i === temp_transformer.length-1) {
				formattedTransformer += postComma;
			}
		}

		trans.transformer = formattedTransformer;
	};
	$scope.cmInit = cm_variables.cmInit;

	let resize_debounce;
	function resize_header() {
		if (resize_debounce) {
			clearTimeout(resize_debounce);
		}

		resize_debounce = $timeout(() => {
			const transformerRow = document.getElementById('new_transformer_fixed');
            const ifThenRow = document.getElementById('power_if_then_row');


            const styleWrapper = document.querySelector('fdx-root > fdx-style-wrapper')
            if (!styleWrapper) {
                throw new Error('[TransformersPage] Unable to get styleWrapper');
            }

            const shadowRoot = styleWrapper.shadowRoot;
            if (!shadowRoot) {
                throw new Error('TransformersPage] Unable to get shadowRoot');
            }

			const navRow = shadowRoot.getElementById('fdx-main-nav');

			// Adjust transformers row
			transformerRow.style.marginTop = (navRow.offsetHeight - 40) + 'px';

			// Adjust if/then row
			ifThenRow.style.top = (navRow.offsetHeight + transformerRow.offsetHeight + 7) + 'px';

			// Calculate margin-top for transformer list
			let height = navRow.offsetHeight + transformerRow.offsetHeight + ifThenRow.offsetHeight;

			// Adjust value for floating if/then row when in power transformer mode
			if ($scope.transformer_mode === 'power') {
				height -= 63;
			}

			document.getElementById('fixed_header_margin').style.marginTop = height + 'px';
		}, 150);
	}

    $scope.showCheatSheet = function() {
        OffcanvasService.open(CheatSheetOffcanvasComponent, {
            position: 'end'
        });
    };

	// Dynamic height header after a second (things take some time to settle)
	$timeout(() => {
		$scope.$watch(() => {
			const elm = document.getElementById('new_transformer_fixed');
			if (!elm) {
				return 0;
			}

			// account for main-nav height changing
            const styleWrapper = document.querySelector('fdx-root > fdx-style-wrapper')
            if (!styleWrapper) {
                throw new Error('[TransformersPage] Unable to get styleWrapper');
            }

            const shadowRoot = styleWrapper.shadowRoot;
            if (!shadowRoot) {
                throw new Error('TransformersPage] Unable to get shadowRoot');
            }

			const navRow = shadowRoot.getElementById('fdx-main-nav');
			let height = elm.offsetHeight + navRow.offsetHeight;

			// Floating if/then row for power transformers
			height += document.getElementById('power_if_then_row').offsetHeight;

			// Return a mix of mode and height so if either change it will resize
			return $scope.transformer_mode + '#' + height;
		}, resize_header);
	});

	$scope.sort_db_field = function(db_field) {
		return parseInt(db_field.sort_order);
	};

	$scope.current_field = {'current_field': {}};
	$scope.db_fields = [];
	$scope.transformers_filter = {
		'query': '',
		'enabled': '',
		'status_options': [
			{
				'name': 'Transformer Status: All',
				'value': ''
			},
			{
				'name': 'Transformer Status: Enabled',
				'value': '1'
			},
			{
				'name': 'Transformer Status: Disabled',
				'value': '0'
			}
		],
		'export_ids': []
	};
	$scope.new_transformer = {selector:'true', transformer:'', export_id:['0'], mode: 'simple', enabled: 1, isExpanded: false};

	$scope.test = false;

	// CodeMirror Options
	$scope.code_mirror = {
		mode: '',
		extraKeys: {
			'Tab': false,
			'Shift-Tab': false,
		},
	};

	// Change field_name and route
	$scope.change_field_name = function () {
        $state.go($state.current, {field_name: $scope.current_field.current_field.displayName});
	};

	// Grab the db column display names
	let autocomplete_db_fields = [];
	let filter_transformers = [];
	const list = [];
	const params = {transformer_count : 1};
	$http.get('/api.php/dbs/' + databaseId + '/db_fields?'+$.param(params))
		.then(function(response, status, headers, config) {
			const data = response.data;
			for (let n=0; n<data.length; n++) {
				$scope.db_fields.push({
					'name': 'field_' + data[n].field_offset,
					'displayName': data[n].field_name,
					'offset': n+1,
					'sort_order': data[n].sort_order,
					'transformer_count': data[n].transformer_count
				});
				if ($scope.db_fields[n].displayName == fieldName) {
					$scope.current_field.current_field = $scope.db_fields[n];
					$scope.current_field.current_field_sort_order = $scope.db_fields[n].sort_order;
				}
			}
			$scope.current_field.current_field.name = fieldName;

			// sort db fields by displayName
			// $scope.db_fields = $filter('orderBy')($scope.db_fields, 'displayName');

			// If field name not set, choose first in list
			if (!$scope.current_field.current_field.name) {
				let url = $location.url();
				if (url.charAt(url.length-1) != '/') {
					url += '/';
				}
				url += $scope.db_fields[0].displayName
				$location.url(url);
			}

			// Based on the db_fields, set the mode
			const valid_db_fields = $scope.db_fields.map(function(value, index, arr) {
				return value.displayName;
			});

			// add [ and ] for all field names for autocomplete
			autocomplete_db_fields = $scope.db_fields.map(function(value, index, arr) {
				return '[' + value.displayName + ']';
			});

			if ($scope.enable_image_processing && $scope.enable_rakuten_transformer) {
				filter_transformers = ['image_processing','rakuten_properties_transformer'];
			} else if ($scope.enable_image_processing) {
				filter_transformers = ['image_processing'];
			} else if ($scope.enable_rakuten_transformer) {
				filter_transformers = ['rakuten_properties_transformer'];
			}

			// Initialize auto complete
			CodeMirror.hint.transformer_parser = cm_variables.hint_transformer_parser(autocomplete_db_fields, false, filter_transformers);


			// Initialize mode
			CodeMirror.defineSimpleMode(
				"transformer_parser",
				cm_variables.mode_transformer_parser(valid_db_fields)
			);

			$scope.code_mirror.mode = 'transformer_parser';
		});

	$scope.genName = function(db_field) {
		return db_field.displayName + ' (' + db_field.transformer_count + ')';
	}

	// Grab the exports
	$http.get('/api.php/dbs/' + databaseId + '/exports')
	.then(function(response, status, headers, config) {
		$scope.exports = response.data;
		$scope.exports.unshift({id:'0', name:'All Exports'});
	});


	$scope.set_last_modified_transformer = function(transformer) {
		Object.keys($scope.transformers).forEach( function(transformer_index) {
			$scope.transformers[transformer_index].last_modified = false;
		});
		transformer.last_modified = true;
		transformer.modified = true;
	}

	$scope.download_transformers = function(transformerSort = 'alphabetical') {
		window.open('/api.php/dbs/' + databaseId + '/transformers/download?sort=' + transformerSort);
	};

	$scope.download_current_view = function(transformers) {
		const rows = [];
		rows.push([
			'field_name',
			'export_id',
			'selector',
			'transformer',
			'sort_order',
			'enabled'
		]);

		transformers.forEach(function(transformer){
			rows.push([
				transformer.field_name,
				transformer.export_id,
				transformer.selector,
				transformer.transformer,
				transformer.sort_order,
				transformer.enabled
			]);
		});
		CSVService.download('transformers_current_view' + '.csv', rows);
	}

	$scope.rotateToggleExpanse = function(transformer) {
		// add rotation class
		transformer.rotated = !transformer.rotated;
		// when animation is over toggle isExpanded
		$timeout(function () {
			transformer.isExpanded=!transformer.isExpanded;
			transformer.rotated = !transformer.rotated;
		}, 300);
	}

	// modal for downloading selected field's transformers
	$scope.fields_for_download = function() {
		const currentField = $scope.current_field.current_field;

		const fieldList = [];

		for (const field of $scope.db_fields) {
			if (field.transformer_count > 0) {
				if (currentField.displayName === field.displayName) {
					fieldList.push(
						{
							'name': field.displayName,
							'display': $scope.genName(field),
							'selected': true
						}
					);
				} else {
					fieldList.push(
						{
							'name': field.displayName,
							'display': $scope.genName(field),
							'selected': false
						}
					);
				}
			}
		}

		fieldList[currentField.displayName] = true;

		ModalService
			.open(
				{
					component: downloadModalComponentName,
					windowClass: 'fdx-modal modal-dialog-centered select_fields_modal',
					backdropClass: 'fdx-modal',
					size: 'lg',
					resolve: {
						databaseId: () => (databaseId),
						fieldList: () => (fieldList),
						transformersFilter: () => ($scope.transformers_filter)
					}
				}
			)
			.then(angular.noop, angular.noop);
	};

	// modal for downloading selected field's transformers
	$scope.field_dependency_modal = function() {
		ModalService.open(
			{
				component: fieldDependencyTreeModalComponentName,
				windowClass: 'fdx-modal modal-dialog-centered',
				backdropClass: 'fdx-modal',
				size: 'lg',
				resolve: {
					transformerFieldDependency: () => ($scope.transformer_field_dependency),
					currentField: () => ($scope.current_field.current_field.name),
				}
			}
		).then(angular.noop, angular.noop);
	};

	$scope.exports_filter = function(transformer) {
        // Always keep edited items on the screen.
        if (transformer.modified) {
            return true;
        }
		let trans_export_ids = transformer.export_id;
		if(transformer.exports !== null) {
			trans_export_ids = JSON.parse(transformer.exports)['export_ids'];
		}

		// no filter selected, return all
		if($scope.transformers_filter.export_ids.length === 0) {
			return true;
		}

			// All exports
			if(
				trans_export_ids.indexOf("0") !== -1
				&&
				$scope.transformers_filter.export_ids.indexOf("0") !== -1
			) {
				return true;
			}
			else if(typeof $scope.transformers[0] !== 'undefined') {
				let exports_intersect = [];
				exports_intersect = trans_export_ids.filter(function(x) {
					return $scope.transformers_filter.export_ids.indexOf(x) !== -1;
				});
				if(exports_intersect.length > 0) {
					return true;
				}
			}

		return false;
	};

    $scope.query_filter = function(transformer) {
        const query_value = $scope.transformers_filter.query.toLowerCase();
        return transformer.modified || transformer.selector.toLowerCase().includes(query_value)
            || transformer.transformer.toLowerCase().includes(query_value);
    };

    $scope.enabled_filter = function(transformer) {
        return transformer.modified || $scope.transformers_filter.enabled === '' || transformer.enabled === $scope.transformers_filter.enabled;
    }

	$scope.debug_transformer = function() {
		console.log($scope.new_transformer);
	}

	$scope.add_transformer = function() {
		$scope.new_transformer.saving_message = true;

		$http.post('/api.php/dbs/' + databaseId + '/transformers', {
			field_name: $scope.current_field.current_field.name,
			selector: $scope.new_transformer.selector,
			transformer: $scope.new_transformer.transformer,
			export_id: $scope.new_transformer.export_id,
			enabled: $scope.new_transformer.enabled
		}).then(function(response) {
			const data = response.data;

			$timeout(function() {
				$scope.new_transformer.saving_message = false;
				$scope.new_transformer.added_message = true;
				$scope.new_transformer.message = true;

				// timeout added message
				$timeout(function () {
					$scope.new_transformer.added_message = false;
				}, 1500);

				$scope.current_field.current_field.transformer_count++;

				data.new_sort_order = data.sort_order;

				const temp_exports = JSON.parse(data.exports);
				data.export_id = temp_exports.export_ids;
				$scope.transformers.push(data);

				$scope.clearError($scope.new_transformer);
			});
		}, function(response, status, headers, config) {
			const data = response.data;

			$scope.new_transformer.saving_message = '';
			$scope.new_transformer.error = data;
		});
	};

	$scope.delete_transformer = function(transformer) {
        fdxUI.startBlockUI();
		const body_data = {'transformer_ids': [transformer.id]};
		$http.delete('/api.php/dbs/' + databaseId + '/transformers', {'data': body_data})
			.then(() => {
				$scope.current_field.current_field.transformer_count--;

				let n;
				for (n=0; n<$scope.transformers.length; n++) {
					if ($scope.transformers[n].id === transformer.id) {
						$scope.transformers.splice(n, 1);
						break;
					}
				}
				for (n=0; n<$scope.transformers.length; n++) {
					if ($scope.transformers[n].sort_order > transformer.sort_order) {
						$scope.transformers[n].sort_order--;
						$scope.transformers[n].new_sort_order--;
					}
				}
			})
            .finally(() => {fdxUI.stopBlockUI()});
	}
	$scope.delete_all_transformers_modal = function() {
		ModalService
			.open(
				{
					component: deleteModalComponentName,
					windowClass: 'fdx-modal modal-dialog-centered',
					backdropClass: 'fdx-modal',
					resolve: {
						databaseId: () => ($scope.current_db.id),
						transformerCount: () => ($scope.current_field.current_field.transformer_count),
						displayName: () => ($scope.current_field.current_field.displayName)
					}
				}
			)
			.then(
				() => {
					$scope.current_field.current_field.transformer_count = 0;
					$scope.transformers = [];
				},
				angular.noop
			);
	}
	$scope.change_exports = function(transformer, old_value) {
		if(transformer.export_id.includes("0")) {
			// transformer.export_id.length > 1
			if(old_value.includes("0") === false) {
				transformer.export_id = ["0"];
			}
			else {
				transformer.export_id.splice(transformer.export_id.indexOf("0"), 1);
			}
		}
		if(transformer.export_id.length === 0) {
			transformer.export_id = ["0"];
		}

		transformer.export_modified=true;
	};
    $scope.updateSelectedExports = function(transformer, oldValue) {
        $scope.set_last_modified_transformer(transformer);
        $scope.change_exports(transformer, oldValue);
    }
	$scope.edit_transformer = function(transformer) {
		transformer.saving_message = true;

		// api expects array
		if(Array.isArray(transformer.export_id) === false) {
			transformer.export_id = [transformer.export_id];
		}
		const params = {
			field_name: $scope.current_field.current_field.name,
			selector: transformer.selector,
			transformer: transformer.transformer,
			export_id: transformer.export_id,
			enabled: transformer.enabled
		};
		if(transformer.transformer.includes('transform_image(')) {
			$scope.formatTransformer(transformer);
		}
		$http.put('/api.php/dbs/' + databaseId + '/transformers/' + transformer.id , params)
			.then(function(response, status, headers, config) {

				const data = response.data;
				transformer.selector_modified=false;
				transformer.transformer_modified=false;
				transformer.export_modified=false;
				transformer.modified = false;
				transformer.error = '';
				// Todo: some sort of notification

				transformer.saving_message = false;
				transformer.added_message = true;

				// timeout added message
				$timeout(function(){
					transformer.added_message = false;
				}, 1500);

				transformer.message = true;

				transformer.new_sort_order = data.sort_order;
				transformer.sort_order = data.sort_order;
				transformer.export_id = JSON.parse(data.exports)['export_ids'];

				if(transformer.transformer.includes('transform_image(')) {
					$scope.formatTransformer(transformer);
				}
				// Cancel any existing timeout
				$timeout.cancel(transformer.message_timeout);

			}, function(response, status, headers, config) {
				transformer.error = response.data;
				transformer.saving_message = false;
				transformer.added_message = false;
			});
	};

	$scope.change_transformer_sort_order = function(transformer) {
        fdxUI.startBlockUI();
		const putParams = {sort_order: transformer.new_sort_order, return_all: true};
		$http.put('/api.php/dbs/' + databaseId + '/transformers/' + transformer.id + '/sort_order', putParams)
			.then((data) => {
                const updatedTransformerOrders = data.data;
                updatedTransformerOrders.forEach((trns) => {
                    const index = $scope.transformers.findIndex((t) => t.id === trns.id);
                    if (index !== -1) {
                        $scope.transformers[index].sort_order = trns.sort_order;
                        $scope.transformers[index].new_sort_order = trns.sort_order;
                    }
                })

				transformer.show_order_input = false;
				transformer.modified = false;
			})
            .finally(() => {fdxUI.stopBlockUI()});
	};

	$scope.orderForTransformers = function(transformer) {
		return parseInt(transformer.sort_order);
	};

	$scope.show_help = function() {
		ModalService.open(
			{
				component: faqModalComponentName,
				windowClass: 'fdx-modal modal-dialog-centered',
				backdropClass: 'fdx-modal',
				size: 'lg'
			}
		).then(angular.noop, angular.noop);
	};

	$scope.clearError = function(transformer) {
		transformer.error = '';
	};

    /** Start new version of Transformers page notification **/
    $scope.showNewTransformerNotification = AppStateService.getUser().hasFeature('new_transformers_page','enabled');

    $scope.goToNewTransformers = () => {
        $state.go('app.transformers-new', { id: $scope.current_db.id });
    }

    $scope.closeNewTransformerNotification = () => {
        $scope.showNewTransformerNotification = false;
    }
    /** End new version of Transformers page notification **/
}]);
