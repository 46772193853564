import { Injectable, OnDestroy } from '@angular/core';
import { LogsRequest } from '@app/logs/services/requests/logs-request.type';
import { DateRange } from '@feedonomics/frontend-components';
import { BehaviorSubject } from 'rxjs';


@Injectable({
    providedIn: null
})
export class LogsStateService implements OnDestroy {
    readonly logsRequest$: BehaviorSubject<LogsRequest> = new BehaviorSubject<LogsRequest>(null);
    readonly logsQuickFilter$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    readonly logsDateRangeFilter$: BehaviorSubject<DateRange> = new BehaviorSubject<DateRange>(null);

    ngOnDestroy(): void {
        this.logsRequest$.complete();
        this.logsQuickFilter$.complete();

        this.logsDateRangeFilter$.complete();
    }
}
