import { Component } from '@angular/core';
import { LogType } from '@app/logs/models/enums/log-type.enum';

@Component({
    selector: 'fdx-logs-edrts',
    templateUrl: './logs-edrts.component.html',
    styleUrls: ['./logs-edrts.component.scss']
})
export class LogsEDRTSComponent {
    readonly logType: LogType = LogType.EDRTS;
}
