export enum ValidationText {
    Pattern = 'must match the following pattern:',
    Min = 'must be greater than or equal to',
    Max = 'must be less than or equal to',
    Required = 'is required',
    MinlengthStart = 'must be at least',
    MaxlengthStart = 'must be at most',
    LengthEnd = 'characters long',
    Email = 'must be an email',
    EmailDelimited = 'must be a list of emails delimited by',
    RequiredTrue = 'must be an checked',
}
