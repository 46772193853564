<modal-hoc
    modalTitle="Import template string"
    submitText="Import"
    (modalSubmit)="onModalSubmit($event)"
    (modalCancel)="onModalCancel($event)">
    <fdx-alert *ngIf="exportId" theme="info" title="Heads up!">
        Merging a template string can cause you to lose fields! How merging works:
        <ul class="ps-3">
            <li>If the Export Name already exists it will update the note</li>
            <li>If the Export Name doesn't already exist then it will add it to the bottom of the list</li>
            <li>If an Export Name currently exists but is NOT in the template string it will be removed</li>
        </ul>
    </fdx-alert>

    <div class="form-group mb-0">
        <label for="textArea">Enter template string</label>
        <textarea id="textArea" class="form-control" rows="10" [(ngModel)]="importString"></textarea>
    </div>
</modal-hoc>
