import { BootstrapThemeProp } from '@app/core/services/bootstrap-theme.service';

export default class FdxLockedAccessModalController {

	public modalTheme: BootstrapThemeProp = 'primary';
	public modalTitle: string = 'Title';
	public submitButton: boolean = true;
	public cancelButton: boolean = true;
	public submitText: string = 'Submit';
	public cancelText: string = 'Cancel';
	public submitDisabled: boolean = false;
	public cancelDisabled: boolean = false;

	private onSubmit: () => void;
	private onCancel: () => void;

	static $inject = [];

	constructor() { }

	public get modalHeaderClasses(): Array<string> {
		if (this.modalTheme === 'danger') {
			return ['fdx-header-bg-danger'];
		}

		return [];
	}

	public get submitButtonClasses(): Array<string> {
		if (this.modalTheme === 'danger') {
			return ['btn-danger'];
		}

		return ['btn-primary'];
	}

	public get showModalFooter(): boolean {
		return this.submitButton || this.cancelButton;
	}

	public submit(): void {
		if (typeof (this.onSubmit) === 'function') {
			this.onSubmit();
		}
	}

	public cancel(): void {
		if (typeof (this.onCancel) === 'function') {
			this.onCancel()
		}
	}
}
