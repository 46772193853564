import FdxError from '../models/FdxError';

export default class VaultDataService {

  // Services
  $http = null;
  $q = null;

  constructor($http, $q) {
    this.$http = $http;
    this.$q = $q;
  }


  getAll(dbId) {

    const def = this.$q.defer();

    const promise = this.$http.get("/api.php/dbs/" + dbId + "/vault");

    promise.then(
      (response) => {
        def.resolve(this.cleanData(response.data.data.vault_rows));
      },
      (response) => {

        if (response.data === null) {
          def.reject(new FdxError('UNKNOWN_ERROR', 'Error retrieving data'));
          return;
        }

        def.reject(new FdxError('NON_GROUPED_DB', response.data.data.error));

      });

    return def.promise;

  }

  create(dbId, data) {

    const def = this.$q.defer();

    const promise = this.$http.post("/api.php/dbs/" + dbId + "/vault", data);

    promise.then(
      (response) => {
        def.resolve(response.data.data);
      },
      (response) => {

        if (response.data === null) {
          def.reject(new FdxError('UNKNOWN_ERROR', 'Error saving data'));
          return;
        }

        def.reject(new FdxError(response.data.message, response.data.data.error));

      }
    );

    return def.promise;

  }

  update(dbId, vaultId, data) {

    const def = this.$q.defer();

    const promise = this.$http.put("/api.php/dbs/" + dbId + "/vault/" + vaultId, data);

    promise.then(
      (response) => {
        def.resolve(response.data.data);
      },
      (response) => {

        if (response.data === null) {
          def.reject(new FdxError('UNKNOWN_ERROR', 'Error updating data'));
          return;
        }

        def.reject(new FdxError(response.data.message, response.data.data.error));

      }
    );

    return def.promise;

  }

  delete(dbId, vaultId) {

    const def = this.$q.defer();

    const promise = this.$http.delete("/api.php/dbs/" + dbId + "/vault/" + vaultId);

    promise.then(
      (response) => {
        def.resolve(response.data.data);
      },
      (response) => {

        if (response.data === null) {
          def.reject(new FdxError('UNKNOWN_ERROR', 'Error deleting data'));
          return;
        }

        def.reject(new FdxError(response.data.message, response.data.data.error));

      }
    );

    return def.promise;

  }

  // The backend converts the dates from NULL to 0000-00-00, we want them as NULL.
  cleanData(data) {
    data.map(row => {
      if (row.expiration === '0000-00-00') {
        row.expiration = null;
      }
      return row;
    });

    return data;

  }

}

VaultDataService.$inject = ['$http', '$q'];