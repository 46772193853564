import { MODULE_NAME } from './config/constants';

// Libs
import 'moment';

// Main module
import './app.module.ajs';

// components, services, filters etc
import './app-controller';
import './app-directive';
import './app-filter';
import './components';
import './controllers';
import './directives';
import './filters';
import './services';
import './vendor/ui-codemirror/ui-codemirror';

// downgraded components
import '@app/ajs-downgraded-components';

export default MODULE_NAME;
