import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    standalone: true,
    selector: 'fdx-bs3-container',
    templateUrl: './bs3-container.component.html',
    styleUrls: ['./bs3-container.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class Bs3ContainerComponent { }
