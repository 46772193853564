import * as angular from 'angular';
import FdxLockedAccessModalController from './fdx-locked-access-modal.controller';
import { MODULE_NAME } from '../../config/constants';

import './fdx-locked-access-modal.styles.scss';

export const componentName = 'fdxLockedAccessModal';

angular.module(MODULE_NAME).component(componentName, {
	template: require('./fdx-locked-access-modal.template.html'),
	controller: FdxLockedAccessModalController,
	transclude: true,
	bindings: {
		modalTheme: '@?',
		modalTitle: '@?',
		submitButton: '<?',
		cancelButton: '<?',
		submitText: '@?',
		cancelText: '@?',
		submitDisabled: '<?',
		cancelDisabled: '<?',
		onSubmit: '&?',
		onCancel: '&?'
	}
});
