import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    standalone: true,
    selector: 'fdx-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
    imports: [
        NgClass
    ]
})
export class SpinnerComponent {

    @Input() size?: 'sm' | 'md';

    get classes(): Record<string, boolean> {
        if (this.size) {
            return {
                [`spinner-border-${this.size}`]: true
            }
        }
        return {};
    }
}
