<div class="d-flex gap-1">
    <button
        *ngIf="hasErrorIconClickCallback"
        type="button"
        class="unstyled-button"
        (click)="errorIconClick($event)"
        fdxStopPropagation>
        <fa-icon [icon]="errorIcon" class="no-margin text-danger" size="sm"></fa-icon>
    </button>
    <span class="text-truncate text-break" [ngClass]="{ 'fst-italic': displayedValue === blankItem }"
    [title]="displayedValue">{{ displayedValue }}</span>
    <button
        *ngIf="shouldDisplayPathModal"
        type="button"
        class="unstyled-button"
        (click)="openPathModal()"
        title="Open corresponding paths"
        fdxStopPropagation>
        <fa-icon [icon]="pathIcon" class="no-margin text-secondary" size="sm"></fa-icon>
    </button>
</div>
<div *ngIf="shouldDisplayPathInline" class="fdx-category-path text-truncate text-break" [title]="displayedPath">{{ displayedPath }}</div>
