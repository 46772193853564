import * as angular from 'angular';
import { MODULE_NAME } from '../../../config/constants';

angular.module(MODULE_NAME)
.directive('feedsupportViewTicket', function() {
    return {
        restrict: 'E',
        template: require('./feedsupport-view-ticket.template.html'),
        controller : 'FeedsupportViewTicket',
    };
})
.directive('feedsupportSingleTicket', function() {
    return {
        restrict: 'E',
        template: require('./feedsupport-single-ticket.template.html'),
        // controller : 'FeedsupportViewTicket',
    };
})
.directive('feedsupportTicketDetail', function() {
    return {
        restrict: 'E',
        template: require('./feedsupport-ticket-detail.template.html'),
        // controller : 'FeedsupportViewTicket',
    };
});