import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { faClock, IconDefinition } from '@fortawesome/pro-solid-svg-icons';

@Component({
    selector: 'fdx-schedule-cron-viewer',
    templateUrl: './schedule-cron-viewer.component.html',
    styleUrls: ['./schedule-cron-viewer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleCronViewerComponent {

    @Input() cron: string = '';
    @Input() cronTimezone: string = '';

    readonly faClockIcon: IconDefinition = faClock;

}
