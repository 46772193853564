import * as angular from 'angular';
import { MODULE_NAME } from '../config/constants';

// fdxEbaySelection
(() => {
    'use strict';

    angular.module(MODULE_NAME).component('fdxEbaySelection', {
        template: require('./templates/fdx-ebay-selection-page.template.html'),
        controller: controller
    });

    controller.$inject = [
        '$state',
        'AppStateService',
        'DatabasesDataService',
        'ExportsDataService',
        'fdxUI',
        'LogService'
    ];

    function controller(
        $state,
        AppStateService,
        DatabasesDataService,
        ExportsDataService,
        fdxUI,
        LogService
    ) {

        const $ctrl = this;

        const idAccount = AppStateService.getAccountId();

        $ctrl.allDatabases = [];
        $ctrl.selectDatabase = selectDatabase;
        $ctrl.$onInit = onInit;

        function onInit() {
            fdxUI.setTitle('Database List');
            fdxUI.setActiveTab('data');
            AppStateService.setDatabase(null); // Unselect Database

            getDatabases();
        }

        function selectDatabase(database) {
            fdxUI.startBlockUI();

            const promise = ExportsDataService.getAll(database.id);

            promise.then(
                (data) => {
                    let foundExport = null;

                    data.forEach((exprt) => {
                        const found = exprt?.protocol_info?.http_body?.includes('eBayAPIProductFeed');

                        // Ensure overrite of highest export id if multiple match
                        if (found) {
                            foundExport = exprt;
                        }
                    });

                    fdxUI.stopBlockUI();

                    if (foundExport) {
                        $state.go('app.data', {id: database.id, export_id: foundExport.id, analyze: 'validation'});

                    } else {
                        $state.go('app.data', {id: database.id, export_id: '0', analyze: 'validation'});
                    }
                },
                (error) => {
                    fdxUI.showToastError('Error retrieving the databases');
                    fdxUI.stopBlockUi();
                    LogService.error('fdxEbaySelection', 'selectDatabase', error);
                },
            );
        }

        function getDatabases() {

            const promise = DatabasesDataService.getAll(idAccount);

            promise.then(
                (data) => {
                    $ctrl.allDatabases = data;

                    if ($ctrl.allDatabases.length === 1) {
                        selectDatabase($ctrl.allDatabases[0]);
                    }
                },
                (error) => {
                    fdxUI.showToastError('Error retrieving the databases');
                    LogService.error('fdxEbaySelection', 'getDatabases', error);
                },
            );

        }

    }

})();
