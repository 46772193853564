eventsHandler.$inject = ['$location', '$rootScope']

export default function eventsHandler($location, $rootScope) {

    $rootScope.$on('maintenanceMode', () => {
        redirectToAuth('login');
    });

    function redirectToAuth(endpoint) {
        const redirect = encodeURIComponent($location.url());
        $location.path('/auth_redirect').search({redirect: redirect, endpoint: endpoint});
    }

}

