
export default class FdxTreeController {
    // #region BINDINGS
    public data?: Record<string, string[]>;
    public field?: string;
    public maxNodes: number = 20;
    public chunkSize: number = 100;
    // #endregion

    public view?: Record<string, string[]>;

    public truncated: boolean = false;

    public $onInit(): void {
        this.updateView();
    }

    public onShowMore(): void {
        this.maxNodes += this.chunkSize;
        this.updateView();
    }

    private updateView(): void {
        this.truncated = false;

        if (!this.data || !this.field) {
            this.view = undefined;
            return;
        }

        let totalNodes = 0;

        const view: Record<string, string[]> = {};

        const addFieldToView = (field: string): void => {
            const dependencies = this.data[field];

            if (!Array.isArray(dependencies)) {
                view[field] = null;
                return;
            }

            view[field] = [];

            for (const currentField of dependencies) {
                if (!currentField) {
                    continue;
                }

                if (totalNodes >= this.maxNodes) {
                    this.truncated = true;
                    return;
                }

                totalNodes++;

                view[field].push(currentField);

                addFieldToView(currentField);
            }
        };

        addFieldToView(this.field);

        this.view = view;
    }
}
