import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@app/core/providers/document.provider';

@Injectable({
    providedIn: 'root'
})
export class ContainerService {
    private static readonly LOG_SERVICE_NAME: string = 'ContainerService';

    constructor(
        @Inject(DOCUMENT) private readonly document: Document
    ) { }

    getShadowRoot(serviceName: string = ContainerService.LOG_SERVICE_NAME): ShadowRoot {
        const styleWrapper = this.document.querySelector('fdx-root > fdx-style-wrapper');

        if (!styleWrapper) {
            throw new Error(`[${serviceName}:getContainer] Unable to get styleWrapper`);
        }

        const shadowRoot = styleWrapper.shadowRoot;

        if (!shadowRoot) {
            throw new Error(`[${serviceName}:getContainer] Unable to get shadowRoot`);
        }

        return shadowRoot;
    }

    getContainer(serviceName: string = ContainerService.LOG_SERVICE_NAME): HTMLElement {
        const container = this.getShadowRoot(serviceName)?.getElementById('bs5-container');

        if (!container) {
            throw new Error(`[${serviceName}:getContainer] Unable to get container`);
        }

        return container;
    }

    getLightDomContainer(): HTMLElement {
        const app = this.document.querySelector('fdx-bs3-container');
        const query = 'div';

        return app.querySelector(query);
    }

    getAppNav(serviceName: string = ContainerService.LOG_SERVICE_NAME): HTMLElement {
        return this.getShadowRoot(serviceName)?.querySelector('#fdx-main-nav');
    }

    getAppMenuComponent(serviceName: string = ContainerService.LOG_SERVICE_NAME): HTMLElement | null {
        return this.getShadowRoot(serviceName)?.querySelector('fdx-app-main-menu,fdx-app-ebay-menu');
    }
}
