import * as angular from 'angular';
import { MODULE_NAME } from '../../../config/constants';

import './fdx-collapsible-code-old.styles.scss';
import Controller from "./fdx-collapsible-code-old.controller";

angular.module(MODULE_NAME).component('fdxCollapsibleCodeOld', {
    template: require('./fdx-collapsible-code-old.template.html'),
    controller: Controller,
    transclude: true,
    bindings: {
        content: '<',
        isCollapsed: '=',
    }
});