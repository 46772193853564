import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { AggregatedDbFieldsType } from '@app/modules/inputs/types/aggregated-db-fields.type';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { IconDefinition, faPlus } from '@fortawesome/pro-solid-svg-icons';

@Component({
    selector: 'fdx-basic-query-inner',
    templateUrl: './basic-query-inner.component.html',
    styleUrls: ['./basic-query-inner.component.scss']
})
export class BasicQueryInnerComponent {

    @Input() dbFields: AggregatedDbFieldsType;
    @Input() inner: string;
    @Input() innerGroup: UntypedFormGroup;

    @Output() removeRowClick: EventEmitter<string> = new EventEmitter<string>();
    @Output() addNewRowGroupClick: EventEmitter<void> = new EventEmitter<void>();
    @Output() removeInnerClick: EventEmitter<void> = new EventEmitter<void>();

    @Input() disabled: boolean;

    iconPlus: IconDefinition = faPlus;
    xIcon: IconDefinition = faTimes;

    constructor() { }

    addNewRowGroup(): void {
        this.addNewRowGroupClick.emit();
    }

    removeRowClicked(row: string): void {
        this.removeRowClick.emit(row);
    }

    get showRemoveInner(): boolean {
        return (Object.keys(this.innerGroup.parent.controls)).length > 1;
    }

    removeInner(): void {
        this.removeInnerClick.emit();
    }

    controlAsFormGroup(rowGroup: AbstractControl): UntypedFormGroup {
        return rowGroup as UntypedFormGroup;
    }
}
