import FdxUI from '@ajs/services/fdxUI';
import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@app/core-legacy/abstract/base.component';
import { AppMenuTab } from '@app/core/models/enums/app-menu-tab.enum';

@Component({
    selector: 'fdx-base-page-component',
    template: ''
})
export abstract class BasePageComponent extends BaseComponent implements OnInit {
    protected abstract appMenuTab: AppMenuTab;
    protected abstract title: string;

    constructor(protected readonly fdxUI: FdxUI) {
        super();
    }

    ngOnInit(): void {
        this.fdxUI.setTitle(this.title);
        this.fdxUI.setActiveTab(this.appMenuTab);
    }
}
