import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ImportFtpTriggerService {

    hasFtpTriggers(importId: string, triggers: any[]): boolean {
        return triggers.some((trigger) => trigger.import_id_to_trigger === importId);
    }

    isFtpImportTrigger(importId: string, triggers: any[]): boolean {
        return triggers.some((trigger) => trigger.import_id === importId);
    }
}
