<div
    [formGroup]="form">
    <!-- Start of Form Array -->
    <ng-container formArrayName="deduplicateFieldName">
        <ng-container
            *ngFor="let group of deduplicateFieldName.controls; let first = first; let i = index; trackBy: trackDeduplicateFieldName;"
            [formGroup]="castAsFormGroup(group)">
            <div [ngClass]="{'mt-1': first, 'mt-3': !first}">
                <fdx-form-field
                    formControlName="name">
                    <fdx-input-wrapper
                        [labelText]="deduplicateLabels[i]['num'] + deduplicateLabels[i]['sup'] + ' deduplicate field select'"
                        [readonly]="disableDeduplicate"
                        [classes]="{'is-readonly': disableDeduplicate}"
                        [inputGroupLeftAddons]="leftAddon"
                        [inputGroupRightAddons]="rightAddon">
                        <ng-select
                            formControlName="name"
                            [id]="'deduplicate_field_' + exportItem.id + '_' + i"
                            [readonly]="disableDeduplicate"
                            [clearable]="!showingMultipleFields"
                            [items]="dedupeFieldOptions"
                            bindValue="value"
                            bindLabel="display"
                            placeholder="Select / search for a field"
                            [virtualScroll]="true">
                        </ng-select>
                    </fdx-input-wrapper>

                    <ng-template #leftAddon>
                        <span
                            class="input-group-text"
                            *ngIf="showingMultipleFields">
                            {{ deduplicateLabels[i]['num'] }}
                            <sup>{{ deduplicateLabels[i]['sup'] }}</sup>
                        </span>
                    </ng-template>
                    <ng-template #rightAddon>
                        <button
                            class="btn shadow-none"
                            type="submit"
                            *ngIf="showingMultipleFields"
                            (click)="removeDeduplicateField(i)">
                            <fa-icon
                                [icon]="iconDelete"
                                class="no-margin"></fa-icon>
                        </button>
                    </ng-template>
                </fdx-form-field>
            </div>
        </ng-container>
    </ng-container>
    <div
        class="mt-3"
        *ngIf="showAddDedupeFieldBtn">
        <button
            (click)="addDeduplicateField()"
            type="button"
            class="btn btn-outline-primary">
            Add another field
        </button>
    </div>
</div>
