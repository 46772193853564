import * as angular from 'angular';
import { MODULE_NAME } from '../../../config/constants';

import './fdx-toggle.styles.scss';
import Controller from "./fdx-toggle.controller";

angular.module(MODULE_NAME).component('fdxToggle', {
    template: require('./fdx-toggle.template.html'),
    controller: Controller,
    bindings: {
        ngModel: '=?',
        ngChange: '&?',
        primaryColor: '@?',
        useTransitions: '<'
    }
});