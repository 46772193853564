<nav aria-label="breadcrumb">
    <ol class="breadcrumb gap-2">
        <ng-container *ngFor="let crumb of breadcrumbs; trackBy: trackByIndex">
            <li class="breadcrumb-item">
                <a [routerLink]="crumb.route" class="btn-link text-secondary">{{ crumb.title }}</a>
            </li>
            <fa-icon [icon]="dividerIcon" class="text-secondary"></fa-icon>
        </ng-container>
        <li class="breadcrumb-item active fw-medium" aria-current="page">
            {{ currentPageTitle }}
        </li>
    </ol>
</nav>
