<offcanvas-hoc
    [offcanvasTitle]="title"
    [offcanvasTitleIcon]="icon"
    panelClassWidth="w-40"
    [panelMinWidth]="750"
    (offcanvasDismissed)="onOffcanvasDismissed($event)">
    <fdx-alert theme="danger" [dismissible]="true" *ngIf="missingFields.length > 0">
        <ng-container *ngIf="missingFields.length > 1 else singleField">
            {{ missingFields.length }} required fields are missing from the export.
        </ng-container>
        <ng-template #singleField>
            {{ missingFields.length }} required field is missing from the export.
        </ng-template>
    </fdx-alert>
    <div class="position-relative vstack" fdxAutoHeight [offset]="16">
        <form [formGroup]="formGroup" #form="ngForm" (ngSubmit)="onLoadCategoriesClick()" class="mb-1">
            <fdx-channel-and-region-inputs
                [form]="form"
                [group]="formGroup"
                labelClass="col-5"
                inputClass="col-7"
                (loadedChannels)="onLoadedChannels()"
                [hideNoTaxonomy]="true">
            </fdx-channel-and-region-inputs>

            <fdx-form-field
                [classes]="{'d-flex': true}"
                formControlName="channelCategoryField"
                [form]="form" >
                <fdx-label class="col-5" labelText="Channel category field">Channel category field<span class="text-danger ps-1">*</span></fdx-label>
                <fdx-input-wrapper class="col-7">
                    <ng-select
                        formControlName="channelCategoryField"
                        id="channelCategoryField"
                        placeholder="Select"
                        [items]="columns">
                    </ng-select>
                </fdx-input-wrapper>
            </fdx-form-field>

            <div class="d-flex justify-content-end mt-1">
                <button class="btn btn-link d-flex" type="submit" [disabled]="form.invalid">
                    <fa-icon [icon]="listIcon"></fa-icon>
                    Load categories
                    <fa-icon [icon]="arrowRightIcon" class="fa-icon-right"></fa-icon>
                </button>
            </div>
        </form>

        <form
            *ngIf="initColumnStatsGrid"
            [formGroup]="conditions"
            #conditionForm="ngForm"
            class="flex-grow-1 vstack negative-offcanvas-margin border-top px-4 pt-3 force-validation"
            (ngSubmit)="addExportFieldsClicked()">

            <div class="col-form-label pt-0">Categories<span class="text-danger ps-1">*</span></div>

            <fdx-search-bar
                [value]="searchValue"
                (valueChange)="onSearchChange($event)">
            </fdx-search-bar>

            <div class="d-flex gap-2 py-2">
                <small [ngClass]="{ 'invisible': loadingColumnStatsResults, 'text-danger': !categoriesSelectedText }" class="white-space-pre">
                    {{ categoriesSelectedText ? categoriesSelectedText : 'No categories available for this selection' }}
                </small>
                <small *ngIf="shouldDisplaySelectCategoriesError" class="text-danger">
                    <fa-icon [icon]="errorIcon"></fa-icon>
                    You must select at least 1 category
                </small>
            </div>

            <ag-grid-angular
                class="ag-theme-alpine ag-theme-data no-row-selection-highlight h-100 rounded"
                [rowData]="rowData$ | async"
                [modules]="modules"
                [gridOptions]="gridOptions"
                (gridReady)="onGridReady($event)"
                (rowDataUpdated)="onRowDataUpdated($event)">
            </ag-grid-angular>

            <div class="row g-0 conditions-row mt-2">
                <div class="fw-semibold col-5 align-self-center">Attribute condition<span class="text-danger ps-1">*</span></div>
                <div class="col-7 hstack justify-content-between">
                    <fdx-form-field
                        [formControlName]="conditionType.REQUIRED"
                        [form]="conditionForm"
                        [classes]="{'mb-0 d-flex align-items-center': true}">
                        <fdx-input-checkbox></fdx-input-checkbox>
                        <fdx-label labelText="Required">
                            <fdx-icon-badge-button
                                theme="danger"
                                text="Required"
                                [iconOverride]="requiredIcon"
                                (buttonClick)="toggleCondition(conditionType.REQUIRED)">
                            </fdx-icon-badge-button>
                        </fdx-label>
                    </fdx-form-field>
                    <fdx-form-field
                        [formControlName]="conditionType.RECOMMENDED"
                        [form]="conditionForm"
                        [classes]="{'mb-0 d-flex align-items-center': true}">
                        <fdx-input-checkbox></fdx-input-checkbox>
                        <fdx-label labelText="Recommended">
                            <fdx-icon-badge-button
                                theme="warning"
                                text="Recommended"
                                [iconOverride]="recommendedIcon"
                                (buttonClick)="toggleCondition(conditionType.RECOMMENDED)">
                            </fdx-icon-badge-button>
                        </fdx-label>
                    </fdx-form-field>
                    <fdx-form-field
                        [formControlName]="conditionType.OPTIONAL"
                        [form]="conditionForm"
                        [classes]="{'mb-0 d-flex align-items-center': true}">
                        <fdx-input-checkbox></fdx-input-checkbox>
                        <fdx-label labelText="Optional">
                            <fdx-icon-badge-button
                                theme="info"
                                text="Optional"
                                [iconOverride]="optionalIcon"
                                (buttonClick)="toggleCondition(conditionType.OPTIONAL)">
                            </fdx-icon-badge-button>
                        </fdx-label>
                    </fdx-form-field>
                </div>
            </div>

            <div class="row g-0">
                <div class="offset-5 col-7">
                    <fdx-validation
                        [control]="conditions"
                        customValidationText="You must select at least 1 condition"
                        [submitted]="form.submitted"
                        class="invalid-feedback"
                        [showIcon]="true">
                    </fdx-validation>
                </div>
            </div>

            <div
                class="negative-offcanvas-margin hstack justify-content-end gap-3 border-top mt-3 pt-3 px-4">
                <button
                    type="submit"
                    class="btn btn-primary"
                    [disabled]="shouldDisableAddExportButton">
                    <fa-icon [icon]="icon"></fa-icon>
                    <span>Add export fields</span>
                </button>
                <button
                    type="button"
                    class="btn btn-outline-secondary"
                    (click)="cancelClicked()">
                    Cancel
                </button>
            </div>
        </form>

        <div *ngIf="loadingColumnStatsResults || loadingChannels" class="bg-white opacity-50 d-block position-absolute top-0 end-0 bottom-0 start-0">
            <fdx-spinner class="position-absolute top-50 start-50 translate-middle"></fdx-spinner>
        </div>
    </div>
</offcanvas-hoc>
