<div class="d-flex" [ngClass]="positionClass">
    <ngb-pagination [(page)]="page"
                    [boundaryLinks]="true"
                    [collectionSize]="collectionSizeUpperBound"
                    [pageSize]="pageSize"
                    [size]="size"
                    [maxSize]="maxSize"
                    (pageChange)="updatePage($event)">
        <ng-template *ngIf="withLabels" class="first" ngbPaginationFirst>
                First
        </ng-template>
        <ng-template *ngIf="withLabels" class="last" ngbPaginationLast>
                Last
        </ng-template>
        <ng-template *ngIf="withLabels" class="ellipsis" ngbPaginationEllipsis>
            <fa-icon [icon]="iconEllipsis"></fa-icon>
        </ng-template>
        <ng-template *ngIf="withLabels" class="previous" ngbPaginationPrevious>
            <fa-icon [icon]="iconChevronLeft"></fa-icon>
        </ng-template>
        <ng-template *ngIf="withLabels" class="next" ngbPaginationNext>
            <fa-icon [icon]="iconChevronRight"></fa-icon>
        </ng-template>

        <ng-template *ngIf="withInput" ngbPaginationPages let-page let-pages="pages">
            <li class="ngb-custom-pages-item" *ngIf="pages.length > 0">
                <div class="d-flex flex-nowrap align-items-center justify-content-evenly">
                    <label
                            id="paginationInputLabel"
                            for="paginationInput"
                            class="col-form-label px-2"
                    >Page</label>
                    <input #i
                        type="text"
                        inputmode="numeric"
                        pattern="[0-9]*"
                        class="form-control custom-pages-input text-center"
                        id="paginationInput"
                        [value]="page"
                        (keyup.enter)="updatePage(parseInt(i.value))"
                        (input)="formatInput($event)"
                    />
                    <span id="paginationDescription" class="col-form-label text-nowrap px-2">
                    of {{pages.length}}</span>
                </div>
            </li>
        </ng-template>
    </ngb-pagination>
</div>
