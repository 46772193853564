import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ValidateTaxonomySummaryRequest } from '@app/analyze-data/services/requests/validate-taxonomy-summary.request';
import { ValidateTaxonomyCSVRequest, ValidateTaxonomyRequest } from '@app/analyze-data/services/requests/validate-taxonomy.request';
import { DataValidationResponse, DataValidationWithSummaryResponse } from '@app/analyze-data/services/responses/data-validation.response';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DataValidationApiService {
    constructor(private readonly http: HttpClient) { }

    private getBaseUrl(databaseId: string): string {
        return `/api.php/dbs/${databaseId}`;
    }

    /**
     * Retrieves data validation with data summary.
     *
     * @param databaseId - the database id we are currently viewing
     * @param exportId - the export id we are currently viewing
     * @param requestParams - The Api request parameters to filter and sort the data request
     */
    validateTaxonomyWithSummary(databaseId: string, exportId: string, requestParams: ValidateTaxonomySummaryRequest): Observable<DataValidationWithSummaryResponse> {
        const url = `${this.getBaseUrl(databaseId)}/validate_taxonomy/${exportId}`;

        const params = this.getValidateSummaryParams(requestParams);

        return this.http.get<DataValidationWithSummaryResponse>(url, { params: params });
    }

    /**
     * Retrieves only the data validation.
     *
     * @param databaseId - the database id we are currently viewing
     * @param exportId - the export id we are currently viewing
     * @param requestParams - The Api request parameters to filter the data request
     */
    validateTaxonomy(databaseId: string, exportId: string, requestParams: ValidateTaxonomyRequest): Observable<DataValidationResponse | unknown> {
        const url = `${this.getBaseUrl(databaseId)}/validate_taxonomy_data/${exportId}`;

        const params = this.getValidateParams(requestParams);

        return this.http.get<DataValidationResponse>(url, { params: params });
    }

    /**
     * Retrieves only the data validation in a csv format
     *
     * @param databaseId - the database id we are currently viewing
     * @param exportId - the export id we are currently viewing
     * @param requestParams - The Api request parameters to filter the data request
     * @returns the CSV in string format
     */
    validateTaxonomyCSV(databaseId: string, exportId: string, requestParams: ValidateTaxonomyCSVRequest): Observable<string> {
        const url = `${this.getBaseUrl(databaseId)}/validate_taxonomy_data/${exportId}`;

        let params = this.getValidateParams(requestParams);

        if (requestParams.output_format) {
            params = params.append('output_format', requestParams['output_format']);
        }

        if (requestParams.selected_columns) {
            params = params.append('selected_columns', requestParams['selected_columns']);
        }

        if (requestParams.with_helper_column) {
            params = params.append('with_helper_column', requestParams['with_helper_column']);
        }

        return this.http.get(url, { params: params, responseType: 'text' });
    }

    getValidateSummaryParams(requestParams: ValidateTaxonomySummaryRequest): HttpParams {
        let params = new HttpParams()
            .set('offset', requestParams['offset'])
            .set('limit', requestParams['limit']);

        if (requestParams.selector) {
            params = params.append('selector', requestParams['selector']);
        }

        if (requestParams.summary_sort_order) {
            params = params.append('summary_sort_by', requestParams['summary_sort_by']);
        }

        if (requestParams.summary_sort_by) {
            params = params.append('summary_sort_order', requestParams['summary_sort_order']);
        }

        if (requestParams.sort_field) {
            params = params.append('sort_field', requestParams['sort_field']);
        }

        if (requestParams.sort_direction) {
            params = params.append('sort_direction', requestParams['sort_direction']);
        }

        if (requestParams.last_sort_value) {
            params = params.append('last_sort_value', requestParams['last_sort_value']);
        }

        if (requestParams.last_row_id) {
            params = params.append('last_row_id', requestParams['last_row_id']);
        }

        return params;
    }

    getValidateParams(requestParams: ValidateTaxonomyRequest): HttpParams {
        let params = this.getValidateSummaryParams(requestParams);

        if (requestParams.filter_by_error_type) {
            params = params.append('filter_by_error_type', requestParams['filter_by_error_type']);
        }

        if (requestParams.filter_by_column_type) {
            params = params.append('filter_by_column_type', requestParams['filter_by_column_type']);
        }

        if (requestParams.filter_by_field) {
            params = params.append('filter_by_field', requestParams['filter_by_field']);
        }

        if (requestParams?.filter_by_specific_rows?.length) {
            const specificRows = requestParams.filter_by_specific_rows;
            const encodedSpecificRows = encodeURIComponent(JSON.stringify(specificRows));
            params = params.append('filter_by_specific_rows', encodedSpecificRows);
        }

        return params;
    }
}
