import * as angular from 'angular';
import * as moment from 'moment';
import { MODULE_NAME } from '../../config/constants';

angular.module(MODULE_NAME).directive('feedwizardMagento', ['$http', '$location', '$q', '$timeout', 'AppStateService', 'FeedWizardService', 'ngToast', function($http, $location, $q, $timeout, AppStateService, FeedWizardService, ngToast) {
	return {
		restrict: 'E',
		scope: true,
		template: require('./magento.html'),
		link: function ($scope) {
			const account_id = AppStateService.getAccountId();
			$scope.step = 1;

			$scope.previous_step = function () {
				$scope.step--;
			};

			$scope.next_step = function () {
				$scope.step++;
			};

			$scope.default_attributes = {
				'media_gallery_images': 'Media Gallery Images',
				'is_in_stock': 'Is In Stock',
				'backorders': 'Backorders',
				'categories': 'Categories',
				'image_link': 'Image Link',
				'quantity': 'Quantity',
				'min_qty': 'Minimum Quantity',
				'link': 'Link'
			};

			$scope.available_attributes = {
				'configurable_parent_ids': 'Configurable Parent IDs',
				'grouped_parent_ids': 'Grouped Parent IDs',
				'bundle_parent_ids': 'Bundle Parent IDs',
				'category_ids': 'Category IDs',
				'visibility': 'Visibility',
				'entity_id': 'Entity ID',
				'store_ids': 'Store IDs',
				'type_id': 'Type ID',
			};

			$scope.data = {
				'db_id': '',
				'import_id': '',
				'file_map': [],
				'store_url': 'http://66.206.4.114/magento1/magento/feedonomics_magento1_products_new.php',
				'store_id': 0,
				'types': {},
				'store_details': {}
			};

			$scope.setActiveAttribute = function(attribute) {
				$scope.has_super_attribute = false;

				attribute.attributes.forEach(function(attr) {
					if(attr.is_super_attribute === 1) {
						$scope.has_super_attribute = true;
					}

					if(attr['enabled'] === undefined) {
						attr.enabled = true;
					}
				});

				$scope.activeAttribute = attribute;
			};

			$scope.cloneMap = function(activeAttribute, cloneFromAttribute) {
				activeAttribute.undo_attributes = [];

				activeAttribute.attributes.forEach(function(activeAttr) {
					cloneFromAttribute.attributes.forEach(function(cloneAttr) {
						if(activeAttr.attribute_id === cloneAttr.attribute_id) {
							activeAttribute.undo_attributes.push(angular.copy(activeAttr));

							activeAttr.map_to = cloneAttr.map_to;
						}
					});
				});
			};

			$scope.undoClone = function(activeAttribute) {
				var confirmed = confirm('Are you sure you want to undo the map clone?');
				if(!confirmed) {
					return;
				}

				$scope.$applyAsync(function() {
					activeAttribute.undo_attributes.forEach(function(undoAttr) {
						activeAttribute.attributes.forEach(function(attr) {
							if(undoAttr.attribute_id === attr.attribute_id) {
								attr.map_to = undoAttr.map_to;
							}
						});
					});

					activeAttribute.undo_attributes = [];
				});
			};

			$scope.toggleAttributeEnabled = function(attribute) {
				attribute.enabled = !attribute.enabled;
			};

			$scope.getAttributeStatus = function(attribute) {
				var total = 0,
					complete = 0;

				attribute.attributes.forEach(function(attr) {
					if(attr.enabled !== false) {
						total += 1;
					}

					if(!attr.is_super_attribute && attr.enabled !== false && (attr.map_to && attr.map_to.length)) {
						complete += 1;
					}
				});

				return complete +'/'+ total;
			};

			$scope.process_attribute_sets = function(attribute_sets) {
				attribute_sets.forEach(function(attribute_set) {
					var attributes = [];

					attribute_set.attributes.forEach(function (attr) {
						attributes.push(attr.name);
					});

					// Add Default Attributes
					Object.keys($scope.default_attributes).forEach(function (attribute_key) {
						if (attributes.indexOf(attribute_key) === -1) {
							attribute_set.attributes.push({
								'default_attribute': true,
								'enabled': true,
								'frontend_label': $scope.default_attributes[attribute_key],
								'is_super_attribute': 0,
								'name': attribute_key
							});

							attributes.push(attribute_key);
						}
					});
				});
			};

			$scope.finish_build = function() {
				$scope.next_step();

				wizard.run().then(function () {
					$scope.next_step();
				}, angular.noop, function (progress) {
					$scope.progress = progress;
				});
			};

			var on_step_error = function (response) {
				$scope.previous_step();

				if (response && response.message) {
					ngToast.danger({
						'content': response.message
					});
				}

				if($scope.data.db_id) {
					$http.delete('/api.php/dbs/' + $scope.data.db_id);
				}
			};

			var steps = [
				// Grab store name
				(new FeedWizardStep())
					.setEndpoint(function () {
						return '/api.php/accounts/' + account_id + '/feedwizard/magento/get_store_details';
					})
					.setMethod('get')
					.setParams(function () {
						return {
							'params': {
								'store_url': $scope.data.store_url
							}
						};
					})
					.setOnSuccess(function (response) {
						$scope.data.store_details = response.data;

						if($scope.data && $scope.data.store_details && $scope.data.store_details.info_data) {
							$scope.process_attribute_sets($scope.data.store_details.info_data.attribute_sets);
						}
					})
					.setOnError(on_step_error),

				// Create DB
				(new FeedWizardStep())
					.setEndpoint(function () {
						return '/api.php/accounts/' + account_id + '/dbs';
					})
					.setMethod('post')
					.setParams(function () {
						return {
							'name': 'Magento Wizard - ' +  moment().format('MM/DD LT')
						};
					})
					.setOnSuccess(function (data) {
						$scope.data.db_id = data.id;
					})
					.setOnError(on_step_error),

				// Create Import
				(new FeedWizardStep())
					.setEndpoint(function () {
						return '/api.php/dbs/' + $scope.data.db_id + '/imports';
					})
					.setMethod('post')
					.setParams(function () {
						var payload = {
							'maps': {},
							'type': [],
						};

						// Add Types to Payload
						Object.keys($scope.data.types).forEach(function(type) {
							if($scope.data.types[type] === true) {
								payload.type.push(type);
							}
						});

						// Add Maps to Payload
						$scope.data.store_details.info_data.attribute_sets.forEach(function(attribute_set) {
							var data = {
								'as_name': attribute_set.name,
								'attribute_maps': {},
								'super_attributes': {},
								'fdx_furnished': {}
							};

							attribute_set.attributes.forEach(function(attribute, index) {
								if(attribute.enabled === true) {
									if(!attribute.map_to) {
										attribute.map_to = 'temp_' + index;
									}

									if(attribute.default_attribute === true) {
										data.fdx_furnished[attribute.map_to] = attribute.name;
									} else if (attribute.is_super_attribute === 1) {
										data.super_attributes[attribute.map_to] = attribute.name;
									} else {
										data.attribute_maps[attribute.map_to] = attribute.name;
									}
								}
							});

							payload.maps[attribute_set.id] = data;
						});

						var url_params = new URLSearchParams();
						url_params.append('connection_info[base_url]', $scope.data.store_url);
						url_params.append('connection_info[client]', 'magento');
						url_params.append('connection_info[maps]', JSON.stringify(payload.maps));
						url_params.append('connection_info[oauth_token]', '0');
						url_params.append('connection_info[protocol]', 'api');
						url_params.append('connection_info[status]', 'enabled');
						url_params.append('connection_info[store_id]', $scope.data.store_id);
						url_params.append('connection_info[type]', JSON.stringify(payload.type));

						return {
							'file_location': 'url',
							'file_type': 'delimited',
							'join_type': 'product_feed',
							'name': 'Magento',
							'url': 'http://imports.feedonomics.com/preprocess/run_preprocess.php?'+ url_params.toString()
						};
					})
					.setOnSuccess(function (response) {
						$scope.data.import_id = response.id;
					})
					.setOnError(on_step_error),

				// Detect Import #1 Delimiters
				(new FeedWizardStep())
					.setEndpoint(function () {
						return '/api.php/dbs/' + $scope.data.db_id + '/imports/' + $scope.data.import_id + '/auto_detect';
					})
					.setMethod('get')
					.setOnSuccess(function (data) {
						$scope.data.delimiters = data[0];
					})
					.setOnError(on_step_error),

				// Update Import Delimiters
				(new FeedWizardStep())
					.setEndpoint(function () {
						return '/api.php/dbs/' + $scope.data.db_id + '/imports/' + $scope.data.import_id + '/delimiters';
					})
					.setParams(function () {
						return {
							'enclosure': $scope.data.delimiters['enclosure_character'],
							'encoding': $scope.data.delimiters['recommended_encoding'],
							'escaper': $scope.data.delimiters['escape_character'],
							'line_terminator': $scope.data.delimiters['line_terminator'],
							'separator': $scope.data.delimiters['field_separator']
						};
					})
					.setMethod('put')
					.setOnError(on_step_error),

				// Grab headers to create file map
				(new FeedWizardStep())
					.setEndpoint(function () {
						return '/api.php/dbs/' + $scope.data.db_id + '/imports/' + $scope.data.import_id + '/file?format=parsed&limit=4';
					})
					.setMethod('get')
					.setOnSuccess(function (response) {
						$scope.data.file_map = {};

						response[0].forEach(function (field, index) {
							var header = FeedWizardService.hex2bin(response[0][index]);

							var name = header.replace(/[^\x00-\x7F]/g, "")
								.trim()
								.toLowerCase()
								.replace(/ /g, "_")
								.replace(/-/g, "_");

							$scope.data.file_map[response[0][index]] = name;
						});
					})
					.setOnError(on_step_error),

				// Update Import #1 file map
				(new FeedWizardStep())
					.setEndpoint(function () {
						return '/api.php/dbs/' + $scope.data.db_id + '/imports/' + $scope.data.import_id + '/file_map';
					})
					.setMethod('put')
					.setParams(function () {
						return {
							'maps': $scope.data.file_map,
							'name_based_maps': 1,
							'encode_source_file_keys': 1,
							'clean_file_headers': 0
						};
					})
					.setOnError(on_step_error),
			];

			var wizard = (new FeedWizard($http, $q))
				.addSteps(steps);

			$scope.build = function () {
				$scope.next_step();

				wizard.run(1).then(function () {
					$scope.next_step();
				}, angular.noop, function (progress) {
					$scope.progress = progress;
				});
			};
		}
	};
}]);