
/**
 * An enum of acceptable inputs for InputComponent
 * Use AcceptedFdxInputTypes for typechecking.  Use this enum for runtime
 * enforcement
 */
export enum AcceptedInputs {
    Color = 'color',
    DatetimeLocal = 'datetime-local',
    Email = 'email',

    Month = 'month',
    Password = 'password',
    Range = 'range',
    Search = 'search',
    Tel = 'tel',
    Text = 'text',
    Time = 'time',
    Url = 'url',
    Week = 'week'
}

/**
 * An enum of valid html inputs that have their own bespoke component or are
 * otherwise unacceptable for InputComponent.
 * Use CustomControlInputTypes for typechecking.  Use this enum for runtime
 * enforcement.
 */
export enum CustomInputs {
    Checkbox = 'checkbox',
    Datepicker = 'datepicker',
    File = 'file',
    InputFieldOrValue = 'input-field-or-value',
    Number = 'number',
    Multiselect = 'multiselect',
    Radio = 'radio',
    Select = 'select'
}

// When adding or removing custom components, modify the above enums instead of these types.
// If there's more input types that fit none of the above categories, add an enum so
// checks can be enforced at runtime for dynamically generated components.
export type AcceptedFdxInputTypes = `${AcceptedInputs}`;
export type CustomControlInputTypes = `${CustomInputs}`;

export type InputTypes = AcceptedFdxInputTypes | CustomControlInputTypes;
