import * as angular from 'angular';
import { MODULE_NAME } from '../../config/constants';

angular.module(MODULE_NAME).directive('recursiveDirective', function () {
    return {
        restrict: 'E',
        scope: {
            data: '=',
            field: '='
        },
        template: require('./recursive-directive.template.html')
    };
});
