export enum TokenizerSelectorTokenEnum {
    OpenParen = 'open_paren',
    FunctionPrefix = 'function_prefix',
    BLiteral = 'b_literal',
    Variable = 'variable',
    Relop = 'relop',
    FunctionInfix = 'function_infix',
    FunctionOneside = 'function_oneside',
    Comma = 'comma',
    String = 'string',
    Num = 'num',
    And = 'and',
    Or = 'or',
    CloseParen = 'close_paren',
    End = 'end',
    Error = 'error',
}