import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { LogService } from '@app/core/services/log.service';
import { BaseInputComponent } from '@app/modules/inputs/components/base-input/base-input.component';
import { InputsUtilitiesService } from '@app/modules/inputs/services/inputs-utilities.service';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faCalendarAlt } from '@fortawesome/pro-solid-svg-icons';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'fdx-datepicker',
    providers: [{
        provide: BaseInputComponent,
        useExisting: DatepickerComponent
    }],
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent extends BaseInputComponent implements OnInit {

    public faCalendarIcon: IconDefinition = faCalendarAlt;

    dateValue: NgbDateStruct;
    @ViewChild('datePickerControl') datePickerControl: AbstractControl;

    constructor(
        protected readonly element: ElementRef<HTMLElement>,
        protected readonly inputsUtilitiesService: InputsUtilitiesService,
        protected readonly logService: LogService,
        public ngbDateParserFormatter: NgbDateParserFormatter
    ) {
        super(element, inputsUtilitiesService, logService, ngbDateParserFormatter);
    }

    ngOnInit(): void {
        if (this.classes['form-control'] === undefined) {
            this.initClasses['form-control'] = true;
        }
    }

    setValue(value: NgbDateStruct | Date | string | null): void {
        /**
         * Normalize to NgbDateStruct first
         */
        let struct: NgbDateStruct;
        if (this.inputsUtilitiesService.isNgbDateStruct(value)) {
            struct = value;
        } else if (value instanceof Date) {
            struct = this.ngbDateParserFormatter.parse(value.toISOString());
        } else {
            struct = this.ngbDateParserFormatter.parse(value);
        }

        /**
         * Then determine has value class based on formatted stuct
         */
        const formatted = this.formatControlValue(struct);
        this.updateHasValueClass(formatted);

        /**
         * Then set the dateValue as the struct
         */
        this.dateValue = struct;
    }

    onChange($event: string | NgbDateStruct): void {
        const formatted = this.formatControlValue($event);
        this.updateHasValueClass(formatted);
        this.formField.handleChange(formatted);
    }

    formatControlValue(value: NgbDateStruct | string | null): string {
        if (this.inputsUtilitiesService.isNgbDateStruct(value)) {
            return this.ngbDateParserFormatter.format(value);
        }
        return value;
    }

    get iconSize(): SizeProp {
        if (this.classes['form-control-sm']) {
            return 'sm';
        } else if (this.classes['form-control-lg']) {
            return 'lg';
        }
        return '1x';
    }

    /**
     * Combine super's is-invalid class with the datePickerControl's invalid logic
     */
    get inputClasses(): Record<string, boolean> {
        const parentInputClasses = super.inputClasses;

        return {
            ...parentInputClasses,
            'is-invalid': parentInputClasses['is-invalid'] ||
                (this.datePickerControl?.invalid && (this.datePickerControl?.dirty || this.datePickerControl?.touched))
        };
    }
}
