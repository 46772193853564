import { AppStateService } from '@ajs/services/AppStateService';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BaseExportFormComponent } from '@app/exports/components/base-export-form/base-export-form.component';
import { ExistingExport } from '@app/exports/models/interfaces/existing-export.interface';
import { ExportSchedule } from '@app/exports/models/interfaces/export-schedule.interface';
import { ExportUpdateScheduleRequestInterface } from '@app/exports/models/interfaces/export-update-schedule-request.interface';
import { ExportsDataService } from '@app/exports/services/exports-data.service';
import { CronInterface } from '@app/modules/cron/models/interfaces/cron.interface';
import { ScheduleOption } from '@app/modules/cron/models/types/schedule-option.type';
import { CronService } from '@app/modules/cron/services/cron.service';
import { ModalService } from '@app/modules/modals/services/modal.service';
import { TimeZoneDropdownOptionInterface } from '@app/modules/timezone/models/interfaces/time-zone-dropdown-option.interface';
import { TimeZoneService } from '@app/modules/timezone/services/time-zone.service';
import { takeUntil } from 'rxjs';

interface MainFormValuesInterface {
    day: string[];
    hour: string[],
    minute: string[],
    timezone: string | null
}

@Component({
    selector: 'fdx-export-schedule-form',
    styleUrls: ['./export-schedule-form.component.scss'],
    templateUrl: './export-schedule-form.component.html'
})
export class ExportScheduleFormComponent extends BaseExportFormComponent implements OnInit {

    // eslint-disable-next-line @typescript-eslint/typedef
    form = new FormGroup({
        day: new FormControl<string[]>([]),
        hour: new FormControl<string[]>([]),
        minute: new FormControl<string[]>([]),
        timezone: new FormControl<string | null>(null)
    });

    scheduleUpdateError: string = '';

    @Input() exportItem: ExistingExport;
    @Output() scheduleUpdated: EventEmitter<ExportSchedule> = new EventEmitter<ExportSchedule>();

    get databaseId(): string {
        return this.appStateService.getDatabaseId();
    }

    get cron(): string {
        return this.exportItem?.cron;
    }

    get cronTimezone(): string {
        return this.exportItem?.cron_timezone;
    }

    get timeZonesList(): TimeZoneDropdownOptionInterface[] {
        return this.timezoneService.getTimezonesDropdownValues();
    }

    ngOnInit(): void {
        this.initForm();
        this.patchForm(this.exportItem);

        this.subscriptionHandler(this.form.valueChanges
            .pipe(takeUntil(this.unsubscribe$)))
            .subscribe((changes) => {
                if (changes) {

                    const cronData: CronInterface = {
                        day: changes.day,
                        hour: changes.hour,
                        minute: changes.minute
                    }

                    const cronComponents: { day: ScheduleOption[], hour: ScheduleOption[], minute: ScheduleOption[] } = {day: [], hour: [], minute: []};

                    cronComponents.day = this.exportItem._schedules.days.filter((i) => cronData.day.includes(i.value));
                    cronComponents.hour = this.exportItem._schedules.hours.filter((i) => cronData.hour.includes(i.value));
                    cronComponents.minute = this.exportItem._schedules.minutes.filter((i) => cronData.minute.includes(i.value));

                    this.exportItem.cron_components = cronComponents;

                    this.exportItem.cron = this.cronService.getCronDataAsString(cronData);
                    this.exportItem.cron_timezone = changes.timezone;

                }
            });
    }

    constructor(
        private readonly appStateService: AppStateService,
        private readonly cronService: CronService,
        private readonly exportsDataService: ExportsDataService,
        private readonly modalService: ModalService,
        private readonly timezoneService: TimeZoneService
    ) {
        super();
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    protected initForm(): void {
    }

    protected patchForm(exportItem: ExistingExport): void {

        const day = Array.isArray(exportItem.cron_components?.day)
            ? exportItem.cron_components?.day.map(({value}) => value)
            : exportItem.cron_components?.day;

        const hour = Array.isArray(exportItem.cron_components?.hour)
            ? exportItem.cron_components?.hour.map(({value}) => value)
            : exportItem.cron_components?.hour;

        const minute = Array.isArray(exportItem.cron_components?.minute)
            ? exportItem.cron_components?.minute.map(({value}) => value)
            : exportItem.cron_components?.minute;

        const timezone = exportItem.cron_timezone;

        this.patchValue({
            day,
            hour,
            minute,
            timezone
        });
    }

    openUpdateScheduleModal(): void {

        const hasMultipleMinuteSelections = this.form.controls.minute.value?.length > 1;

        if (!hasMultipleMinuteSelections) {
            this.updateSchedule();
        } else {
            this.modalService.showConfirmationModal(
                'Confirm Multiple Time Export Schedule',
                'Setting up multiple export schedules can cause a subsequent export to be skipped if the previous export is still running. Proceed with scheduling?',
                'Confirm'
            )
                .closed
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(() => {
                    this.updateSchedule();
                });
        }
    }

    validate(selections: { display_name: string; value: string; }[], controlName: string): void {
        const lastEntry = selections[selections.length - 1];
        const firstEntry = selections[0];

        if (lastEntry?.value === '-1') {
            // If the user chooses 'Unscheduled', clear out all previously selected options
            this.form.get(controlName).patchValue([]);
        } else if (lastEntry?.value === '*') {
            // Else if the user chooses 'Every', clear out all other components, add * as the chosen componenet
            this.form.get(controlName).patchValue(['*']);
        } else if (firstEntry?.value === '*') {
            // Else If the user has already chosen *, then replace * with the new selection
            this.form.get(controlName).patchValue([lastEntry.value]);
        }
    }

    updateSchedule(): void {

        function processArray(val: string[]): string {
            return val.length === 0 ? '-1' : val.join(',');
        }

        this.scheduleUpdateError = '';

        const formValue: MainFormValuesInterface = this.form.getRawValue();

        const params: ExportUpdateScheduleRequestInterface = {
            day: processArray(formValue.day),
            hour: processArray(formValue.hour),
            minute: processArray(formValue.minute),
            timezone: formValue.timezone
        };

        this.exportsDataService.updateSchedule(this.databaseId, this.exportItem.id, params)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: ({cron}) => {
                    this.exportItem.cron = cron;
                    this.exportItem.cron_components = {
                        day: formValue.day.map((dayEntry) => ({ display_name: null, value: dayEntry })),
                        hour: formValue.hour.map((hourEntry) => ({ display_name: null, value: hourEntry })),
                        minute: formValue.minute.map((minuteEntry) => ({ display_name: null, value: minuteEntry }))
                    };
                    this.exportItem.cron_timezone = formValue.timezone;

                    this.scheduleUpdated.emit({
                        cron,
                        cron_components: this.exportItem.cron_components,
                        cron_timezone: formValue.timezone
                    });
                },
                error: (error: unknown) => {

                    if (error instanceof HttpErrorResponse) {
                        this.scheduleUpdateError = error.message;
                    }

                },
                complete: () => {
                    this.form.markAsPristine();
                }
            });
    }

}
