import { CategoryPathCellType } from '@app/analyze-data/components/category-paths-cell-renderer/category-paths-cell-renderer.component';

export enum ColumnStatsColumnEnum {
    UniqueValue = 'unique value',
    Count = 'count',
    Invalid = 'invalid'
}

export type ColumnStatsParentRowData = {
    [ColumnStatsColumnEnum.UniqueValue]: CategoryPathCellType,
    [ColumnStatsColumnEnum.Count]: number;
    [ColumnStatsColumnEnum.Invalid]?: boolean;
    children?: ColumnStatsChildRowData[];
};

export type ColumnStatsChildRowData = {
    [ColumnStatsColumnEnum.UniqueValue]: string,
    [ColumnStatsColumnEnum.Count]: number;
};
