import { Injectable, OnDestroy } from '@angular/core';
import { AppLayout } from '@app/core/models/enums/app-layout.enum';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: null
})
export class AppLayoutService implements OnDestroy {
    private readonly _layout$: BehaviorSubject<AppLayout> = new BehaviorSubject<AppLayout>(AppLayout.Normal);

    readonly layout$: Observable<AppLayout> = this._layout$.asObservable();

    get layout(): AppLayout {
        return this._layout$.value;
    }

    ngOnDestroy(): void {
        this._layout$.complete();
    }

    setLayout(value: AppLayout): void {
        this._layout$.next(value);
    }

    resetLayout(): void {
        this._layout$.next(AppLayout.Normal);
    }
}
