import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BootstrapThemeProp, BootstrapThemeService, BootstrapThemeType } from '@app/core/services/bootstrap-theme.service';
import { IconDefinition, faClose } from '@fortawesome/pro-solid-svg-icons';

@Component({
    selector: 'fdx-popover-title',
    templateUrl: './popover-title.component.html',
    styleUrls: ['./popover-title.component.scss']
})
export class PopoverTitleComponent implements OnChanges {

    @Input() theme: BootstrapThemeProp = 'default';
    themeType: BootstrapThemeType;

    @Input() dismissible?: boolean = false;
    @Input() showIcon: boolean = true;
    @Output() readonly dismissed?: EventEmitter<void> = new EventEmitter<void>();

    @Input() titleText: string;
    closeIcon: IconDefinition = faClose;

    get shouldSpin(): boolean {
        return this.theme === 'downloading';
    }

    constructor(
        private bootstrapThemeService: BootstrapThemeService,
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if ('theme' in changes) {
            this.themeType = this.bootstrapThemeService.getThemeType(changes.theme.currentValue);
        }
    }

    close(): void {
        this.dismissed.emit();
    }
}
