import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@feedonomics/ng-select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// Modules
import { CoreLegacyModule } from '@app/core-legacy/core-legacy.module';
import { AlertsModule } from '@app/modules/alerts/alerts.module';
import { InputsModule } from '@app/modules/inputs/inputs.module';
import { ModalsModule } from '@app/modules/modals/modals.module';
import { NgbDatepickerModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

// Pages
import { ReportsSkuPageComponent } from '@app/reports-sku/pages/reports-sku/reports-sku-page.component';

// Components
import { PageModule } from '@app/modules/page/page.module';
import { EmptyStateModule } from '@feedonomics/frontend-components';

@NgModule({
    declarations: [
        ReportsSkuPageComponent
    ],
    imports: [
        AlertsModule,
        CommonModule,
        CoreLegacyModule,
        EmptyStateModule,
        FontAwesomeModule,
        InputsModule,
        ModalsModule,
        NgbDatepickerModule,
        NgbDropdownModule,
        NgSelectModule,
        PageModule,
        ReactiveFormsModule
    ]
})
export class ReportsSkuModule {
}
