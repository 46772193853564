export default class TransformersDataService {

  // Services
  $http = null;
  $q = null;

  constructor($http, $q) {
    this.$http = $http;
    this.$q = $q;
  }

  getAll(idDatabase) {

    const def = this.$q.defer();

    const promise = this.$http.get('/api.php/dbs/' + idDatabase + '/transformers');

    promise.then(
      (response) => {
        def.resolve(response.data);
      },
      (response) => {
        def.reject(response.data);
      }
    );

    return def.promise;

  }


}

TransformersDataService.$inject = ['$http', '$q'];