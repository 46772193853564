
import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input, } from '@angular/core';
import { CheatSheetContentType } from '@app/modules/cheat-sheet/models/cheat-sheet-content.type';
import { CheatSheetService } from '@app/modules/cheat-sheet/services/cheat-sheet.service';
import { IconDefinition, faCopy } from '@fortawesome/pro-regular-svg-icons';
import { NgbPanel, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'fdx-cheat-sheet-panel-header',
    templateUrl: './cheat-sheet-panel-header.component.html',
    styleUrls: ['./cheat-sheet-panel-header.component.scss']
})
export class CheatSheetPanelHeaderComponent {

    iconCopy: IconDefinition = faCopy;
    copied: boolean;

    @Input() data: CheatSheetContentType;
    @Input() panel: NgbPanel;

    get titleOnly(): boolean {
        return this.cheatSheetService.isTitleOnly(this.data);
    }

    constructor(
        private readonly cheatSheetService: CheatSheetService,
        private readonly clipboard: Clipboard
    ) { }

    copyToClipboard(tooltip: NgbTooltip): void {
        this.copied = this.clipboard.copy(this.data.title);
        if (this.copied) {
            tooltip.open({text: 'Copied to clipboard'});
        } else {
            tooltip.open({text: 'Failed to copy to clipboard'});
        }
        // eslint-disable-next-line angular/timeout-service
        setTimeout(() => {
            tooltip.close()
        }, 1000);
    }
}
