
import fdxUI from '@ajs/services/fdxUI';
import { Component, Inject, OnInit } from "@angular/core";
import { WINDOW } from '@app/core/providers/window.provider';

@Component({
    selector: 'fdx-feedtelligence-page',
    templateUrl: './feedtelligence-page.component.html',
    styleUrls: ['./feedtelligence-page.component.scss']
})
export class FeedtelligencePageComponent implements OnInit {
    loading: boolean = false;
    pageTitle: string = 'FeedTelligence™ Report';
    showReport: boolean = true;
    title: string = 'FeedTelligence™ Insights';
    timeout: number = 0;

    constructor(
        private fdxUI: fdxUI,
        @Inject(WINDOW) private window: Window,
    ) {
    }

    ngOnInit(): void {
        this.fdxUI.setTitle(this.title);
        this.fdxUI.setActiveTab('reports');
    }

    runReport(): void {
        this.loading = true;
        this.showReport = false;

        this.fdxUI.startBlockUI();

        if (this.timeout > 0) {
            this.window.clearTimeout(this.timeout);
            this.timeout = 0;
        }

        this.timeout = this.window.setTimeout(() => {
            this.loading = false;
            this.showReport = true;

            this.fdxUI.stopBlockUI();
        }, 3000);
    }
}
