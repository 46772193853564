import { Component } from '@angular/core';
import { Modal } from '@app/modules/modals/modals.module';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'fdx-transformers-download-modal',
    templateUrl: './transformers-download-modal.component.html',
    styleUrls: ['./transformers-download-modal.component.scss']
})
export class TransformersDownloadModal implements Modal {

    constructor(
        private modal: NgbActiveModal
    ) { }

    onModalInit(data?: any): void {

    }

    onModalSubmit(e?: MouseEvent): void {

    }

    onModalCancel(event: Event): void {
        this.modal.dismiss();
    }
}
