<fdx-well>
    <fdx-well-header>Choose FeedAI™ Categorization fields</fdx-well-header>

    <form [formGroup]="form">
        <div *ngFor="let section of formSections; let first = first" [ngClass]="{'mt-3': !first}">
            <label class="form-label">{{section.label}}
                <fa-icon [icon]="faQuestionCircle"
                         [ngbPopover]="section.tooltip"
                         placement="right"
                         [popoverTitle]="section.tooltipTitle"
                         triggers="mouseenter:mouseleave">
                </fa-icon>
            </label>

            <ng-select
                    [items]="databaseFieldNames"
                    [formControlName]="section.formControlName"
                    placeholder="Select / search for a field">
            </ng-select>
        </div>
    </form>

    <fdx-well-footer>
        <div class="d-flex gap-2">
            <button (click)="save()" class="btn btn-primary">Save</button>
            <button (click)="clearCache()" class="btn btn-link">Clear Cache</button>
        </div>
    </fdx-well-footer>
</fdx-well>
