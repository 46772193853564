import * as angular from 'angular';
import { MODULE_NAME } from './config/constants';

// Resize textarea within ng-repeat
angular.module(MODULE_NAME).directive('onFinalRepeat', ['$timeout', function($timeout) {
	return function(scope, element, attrs) {
		if (scope.$last){
			$timeout(function() {
				$('textarea').autosize();
			}, 0);
		}
	};
}]);

angular.module(MODULE_NAME).directive('datepickerPopup', function (){
	return {
	  restrict: 'EAC',
	  require: 'ngModel',
	  link: function(scope, element, attr, controller) {
		//remove the default formatter from the input directive to prevent conflict
		controller.$formatters.shift();
	  }
	}
  });

angular.module(MODULE_NAME).directive('loadingSpinner', function() {
	return {
		restrict: 'E',
		template: function() {
			return '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>';
		}
	};
});

angular.module(MODULE_NAME).directive('treatValueAsEmpty', function() {
	return {
		restrict: 'A',
		scope: {
			'ngModel': '=',
			'treatValueAsEmpty': '@'
		},
		link: function($scope) {
			$scope.$watch('ngModel', function(newVal) {
				if(newVal == $scope.treatValueAsEmpty) {
					$scope.ngModel = '';
				}
			});
		}
	};
});

angular.module(MODULE_NAME).directive('wysiwygEditor', ['$http', '$timeout', function($http, $timeout) {
	return {
		restrict: 'E',
		scope: {
			'config': '=',
			'ngModel': '=',
			'height': '@',
			'ngChange': '='

		},
		template: function() {
			return '<div id="wysiwyg-{{$id}}" style="height: {{height || \'230px\'}};"></div>';
		},
		link: function ($scope) {
			let updating = false;
			$timeout(function() {
				const config = {
					modules: {
						toolbar: [
							[{ header: [1, 2, false] }],
							['bold', 'italic', 'underline'],
							[{ 'list': 'ordered'}, { 'list': 'bullet' }],
							['code-block'] //'image', 
						],
						imageDropAndPaste: {
							handler: function(dataUrl, type, imageData) {
								let filename;

								switch (type) {
									case 'image/png':
										filename = 'image.png';
										break;

									case 'image/jpeg':
										filename = 'image.jpg';
										break;

									default:
										alert('Only JPG and PNG images are allowed to be uploaded!');
										return;
								}

								var fd = new FormData();
								fd.append('file', imageData.toFile(filename));

								$http.post('/api.php/zoho/upload_image', fd, {
									headers: {
										'Content-Type': undefined
									},
									transformRequest: angular.identity
								}).then(function(resp) {
									//console.log(resp);
									var index = (quill.getSelection() || {}).index;
									if (index < 0) {
										index = quill.getLength();
									}

									quill.insertEmbed(index, 'image', resp.data.data.url, 'user')
								}, function(resp) {
									alert('There was an error uploading your image!');
								});
							}
						}
					},
					theme: 'snow'
				};

				if (typeof $scope.config === 'object') {
					$.extend(config, $scope.config);
				}

				// Register image paste event module
				Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste);

				var quill = new Quill('#wysiwyg-' + $scope.$id, config);

				// Listen for text changes to update model
				quill.on('text-change', function() {
					$scope.$applyAsync(function() {
						updating = true;
						$scope.ngModel = $('.ql-editor', '#wysiwyg-' + $scope.$id).html();
						if(typeof $scope.ngChange == 'function'){
							$scope.ngChange($scope.ngModel);
						}
					});
				});

				// When the model gets cleared from outside the directive we want to clear the contents
				$scope.$watch('ngModel', function(newVal, oldVal) {
					if(updating === true){
						updating = false;
						return;
					}
					if(!newVal && oldVal) {
						quill.setContents([]);
					}
					else{
						if (newVal === undefined || newVal === null) {
						  newVal = "";
						}
						quill.container.firstChild.innerHTML = newVal;
					}
				});
			});
		}
	};
}]);

angular.module(MODULE_NAME).directive('numbersOnly', function() {
	return {
		require: 'ngModel',
		link: function (scope, element, attr, ngModelCtrl) {
			function fromUser(text) {
				if (text) {
					var transformedInput = text.replace(/[^0-9]/g, '');

					if (transformedInput !== text) {
						ngModelCtrl.$setViewValue(transformedInput);
						ngModelCtrl.$render();
					}

					return transformedInput;
				}

				return undefined;
			}

			ngModelCtrl.$parsers.push(fromUser);
		}
	};
});

angular.module(MODULE_NAME).directive('datePicker', ['$timeout', function($timeout) {
	return {
		restrict: 'A',
		scope: {
			ngModel: '=',
			config: '='
		},
		link: function($scope, elm) {
			$timeout(function() {
				var config = {
					dateFormat: 'm/d/Y',
					onChange: [],
					onOpen: []
				};

				if($scope.config !== undefined) {
					$.extend(config, $scope.config);
				}

				// If time is enabled make time selector always visible
				if(config['enableTime'] === true) {
					config.onOpen.push(function(selectedDates, dateStr, instance) {
						$(instance.calendarContainer).addClass('showTimeInput');
					});
				}

				config.onChange.push(function(selectedDates, dateStr) {
					$scope.$applyAsync(function() {
						$scope.ngModel = dateStr;
					});
				});

				flatpickr(elm, config);
			});
		}
	}
}]);

angular.module(MODULE_NAME).directive('hideOnError', function() {
	return {
		restrict: 'A',
		link: function($scope, elm) {
			elm.bind('error', function() {
				elm.css('display', 'none');
			});
		}
	};
});

angular.module(MODULE_NAME).directive('preventSubmit', function() {
	return {
		restrict: 'A',
		scope: true,
		link: function($scope, elm) {
			$(elm).on('keydown', function(event){
				if(event.key == 'Enter'){
					event.preventDefault();
				}
			})
		}
	};
});

angular.module(MODULE_NAME).directive('highlightNonprintable', ['$sce', '$filter', 'fdxUtils', function($sce, $filter, fdxUtils) {
	return {
		restrict: 'E',
		template: function() {
			return '<span ng-bind-html="updated_value"></span>';
		},
		scope: {
			disable: '=',
			value: '='
		},
		link: function ($scope) {
			function init() {
				if($scope.disable === true) {
					$scope.updated_value = fdxUtils.escapeHTML($scope.value);
				} else {
					$scope.updated_value = $filter('NonprintableCharacters')($scope.value);
				}
			}

			$scope.$watchGroup([
				'disable',
				'value'
			], init);

			init();
		}
	};
}]);

angular.module(MODULE_NAME).directive('diffString', ['$filter', 'fdxUtils', function($filter, fdxUtils) {
	return {
		restrict: 'E',
		template: function() {
			return '<span class="diff-string" ng-bind-html="value"></span>';
		},
		scope: {
			disable: '=',
			newstr: '=',
			nonprintable: '=',
			oldstr: '=',
			skipescape: '='
		},
		link: function($scope) {
			function init() {
				['newstr', 'oldstr'].forEach(function(key) {
					if ($scope[key] === undefined || $scope[key] === null) {
						$scope[key] = ' ';
					}

					if (typeof $scope[key] !== 'string') {
						$scope[key] += '';
					}
				});

				if ($scope.disable === true || !$scope.oldstr || !$scope.newstr) {
					let value;

					if ($scope.skipescape === true) {
						value = $scope.newstr;
					}
					else {
						value = fdxUtils.escapeHTML($scope.newstr);
					}

					if ($scope.nonprintable === true) {
						value = $filter('NonprintableCharacters')(value, true);
					}

					$scope.value = value;
					return;
				}

				var dmp = new diff_match_patch();

				dmp.Diff_Timeout = 0;
				dmp.Diff_EditCost = 4;

				var d = dmp.diff_main($scope.oldstr, $scope.newstr);

				dmp.diff_cleanupEfficiency(d);

				var value = dmp.diff_prettyHtml(d, $scope.skipescape);
				
				if($scope.nonprintable === true) {
					value = $filter('NonprintableCharacters')(value, true);
				}
				
				$scope.$applyAsync(function() {
					$scope.value = value;
				});
			}

			$scope.$watchGroup([
				'disable',
				'newstr',
				'nonprintable',
				'oldstr',
				'skipescape'
			], init);

			init();
		}
	};
}]);

angular.module(MODULE_NAME).directive('autoSize', function() {
	return {
		restrict: 'A',
		link: function($scope, elm) {
			elm.autosize();
		}
	};
});

angular.module(MODULE_NAME).directive('shopifyInstructions', function() {
	return {
		retrict: 'e',
		scope: {
			inputBinding:'='
		},
		template: require('./directives/shopify-instructions.html')
	};
});

/**
 * $transition service provides a consistent interface to trigger CSS 3 transitions and to be informed when they complete.
 * @param  {DOMElement} element  The DOMElement that will be animated.
 * @param  {string|object|function} trigger  The thing that will cause the transition to start:
 *   - As a string, it represents the css class to be added to the element.
 *   - As an object, it represents a hash of style attributes to be applied to the element.
 *   - As a function, it represents a function to be called that will cause the transition to occur.
 * @return {Promise}  A promise that is resolved when the transition finishes.
 */
angular.module(MODULE_NAME).factory('$transition', ['$q', '$timeout', '$rootScope', function ($q, $timeout, $rootScope) {

    const $transition = function (element, trigger, options) {
        options = options || {};
        const deferred = $q.defer();
        const endEventName = $transition[options.animation ? 'animationEndEventName' : 'transitionEndEventName'];

        const transitionEndHandler = function () {
            $rootScope.$apply(() => {
                element.unbind(endEventName, transitionEndHandler);
                deferred.resolve(element);
            });
        };

        if (endEventName) {
            element.bind(endEventName, transitionEndHandler);
        }

        // Wrap in a timeout to allow the browser time to update the DOM before the transition is to occur
        $timeout(() => {
            if (angular.isString(trigger)) {
                element.addClass(trigger);
            } else if (angular.isFunction(trigger)) {
                trigger(element);
            } else if (angular.isObject(trigger)) {
                element.css(trigger);
            }
            // If browser does not support transitions, instantly resolve
            if (!endEventName) {
                deferred.resolve(element);
            }
        });

        // Add our custom cancel function to the promise that is returned
        // We can call this if we are about to run a new transition, which we know will prevent this transition from ending,
        // i.e. it will therefore never raise a transitionEnd event for that transition
        deferred.promise.cancel = function () {
            if (endEventName) {
                element.unbind(endEventName, transitionEndHandler);
            }
            deferred.reject('Transition cancelled');
        };

        return deferred.promise;
    };

    // Work out the name of the transitionEnd event
    const transElement = document.createElement('trans');
    const transitionEndEventNames = {
        'WebkitTransition': 'webkitTransitionEnd',
        'MozTransition': 'transitionend',
        'OTransition': 'oTransitionEnd',
        'transition': 'transitionend'
    };
    const animationEndEventNames = {
        'WebkitTransition': 'webkitAnimationEnd',
        'MozTransition': 'animationend',
        'OTransition': 'oAnimationEnd',
        'transition': 'animationend'
    };
    function findEndEventName(endEventNames) {
        for (const name in endEventNames) {
            if (transElement.style[name] !== undefined) {
                return endEventNames[name];
            }
        }
    }
    $transition.transitionEndEventName = findEndEventName(transitionEndEventNames);
    $transition.animationEndEventName = findEndEventName(animationEndEventNames);
    return $transition;
}]);

angular.module(MODULE_NAME).directive('collapseWidth', ['$transition', function ($transition) {

    return {
        link: function (scope, element, attrs) {

            let initialAnimSkip = true;
            let currentTransition;

            function doTransition(change) {
                const newTransition = $transition(element, change);
                if (currentTransition) {
                    currentTransition.cancel();
                }
                currentTransition = newTransition;
                // eslint-disable-next-line no-use-before-define
                newTransition.then(newTransitionDone, newTransitionDone);
                return newTransition;

                function newTransitionDone() {
                    // Make sure it's this transition, otherwise, leave it alone.
                    if (currentTransition === newTransition) {
                        currentTransition = undefined;
                    }
                }
            }

            function expand() {
                if (initialAnimSkip) {
                    initialAnimSkip = false;
                    // eslint-disable-next-line no-use-before-define
                    expandDone();
                } else {
                    element.removeClass('collapse').addClass('collapsing-width');
                    // eslint-disable-next-line no-use-before-define
                    doTransition({ width: element[0].scrollWidth + 'px' }).then(expandDone);
                }
            }

            function expandDone() {
                element.removeClass('collapsing-width');
                element.addClass('collapse in');
                element.css({ width: 'auto' });
            }

            function collapse() {
                if (initialAnimSkip) {
                    initialAnimSkip = false;
                    // eslint-disable-next-line no-use-before-define
                    collapseDone();
                    element.css({ width: 0 });
                } else {
                    // CSS transitions don't work with height: auto, so we have to manually change the height to a specific value
                    element.css({ width: element[0].scrollWidth + 'px' });
                    // trigger reflow so a browser realizes that height was updated from auto to a specific value
                    // eslint-disable-next-line no-unused-vars
                    const x = element[0].offsetHeight;

                    element.removeClass('collapse in').addClass('collapsing-width');

                    // eslint-disable-next-line no-use-before-define
                    doTransition({ width: 0 }).then(collapseDone);
                }
            }

            function collapseDone() {
                element.removeClass('collapsing-width');
                element.addClass('collapse');
            }

            scope.$watch(attrs.collapseWidth, (shouldCollapse) => {
                if (shouldCollapse) {
                    collapse();
                } else {
                    expand();
                }
            });
        }
    };
}]);
