import * as angular from 'angular';
import { MODULE_NAME } from '../../../config/constants';

angular.module(MODULE_NAME)
.directive('feedsupportMainNav', function() {
    return {
        restrict: 'E',
        template: require('./feedsupport-main-nav.template.html'),
        controller : 'FeedSupportMainNav'
    };
});

