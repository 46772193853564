import { Component, Input } from '@angular/core';

/**
 * @deprecated remove instances of the well footer component and just use <div class="card-footer">
 * https://getbootstrap.com/docs/5.2/components/card/
 */
@Component({
    selector: 'fdx-well-footer',
    templateUrl: './well-footer.component.html',
    styleUrls: ['./well-footer.component.scss']
})
export class WellFooterComponent {

    @Input() classes: Record<string, boolean>;
    @Input() styles: Record<string, any>;

}
