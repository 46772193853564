import { Component, Input } from '@angular/core';

@Component({
    selector: 'fdx-page-title',
    templateUrl: './page-title.component.html',
    styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent {
    @Input() classes: Record<string, boolean> = null;
    
    @Input() beta?: boolean = false;
    @Input() darkBackground?: boolean = false;
    
    get betaBadgeClasses(): string {
        if (this.darkBackground) {
            return 'badge fdx-header-bg-discovery border border-discovery';
        }
        return 'badge text-bg-discovery'
    }
}
