import FdxError from "../models/FdxError";

transitionsHandler.$inject = ['$transitions', '$location', '$window', 'fdxUI', 'LogService', 'NavigationService', 'AnalyticsService']

export default function transitionsHandler($transitions, $location, $window, fdxUI, LogService, NavigationService, AnalyticsService) {

    $transitions.onStart({}, (transition) => {
        if (NavigationService.isLoginPendingRestore()) {
            NavigationService.restoreAfterLogin();
        }

        if (transition.to().name !== 'app.accounts') {
            if (NavigationService.isAccountSelectionPendingRestore()) {
                NavigationService.restoreAfterAccountSelection();
            }
        }

        if (transition.to().name === 'app.auth_redirect') {
            NavigationService.redirectToLogin();
        }

    });

    $transitions.onError({}, (transition) => {
        handleRejections(transition);
    });

    $transitions.onEnter({}, (transition) => {
        AnalyticsService.identify();

        if (fdxUI.showMinimalEbayUI()) {
            let transitionTo = transition.to();
            if (transitionTo.data?.allowEbay !== true) {
                return transition.router.stateService.target('app.ebay-selection');
            }
        }
    });

    function handleRejections(transition) {
        const error = transition.error();

        const rejectionData = error.detail;

        if (rejectionData === 'Not Logged In') {
            NavigationService.redirectToLogin();
        }

        if (rejectionData instanceof FdxError) {

            switch (rejectionData.key) { // Add more as needed

                case 'ACCOUNT_INSUFFICIENT_PERMISSION':
                case 'USER_HAS_MULTIPLE_ACCOUNTS':
                case 'USER_HAS_NO_ACCOUNT':
                    NavigationService.handleSelectAccount();
                    break;

                case 'DATABASE_INSUFFICIENT_PERMISSION':
                    $location.path('databases');
                    break;

                default:
                    LogService.error('transitionsHandler', 'handleRejections', error.message);
                    break;
            }

        } else {
            LogService.error('transitionsHandler', 'handleRejections', error.message);
        }

    }

}
