import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { isEmpty } from '@app/core/functions/isEmpty';
import { DISPLAY_DATE_FORMAT, NGB_DATE_FORMAT } from '@app/core/models/constants/date-time';
import { DateTimeService } from '@app/core/services/date-time.service';
import { LogType } from '@app/logs/models/enums/log-type.enum';
import { LogsStateService } from '@app/logs/services/logs-state.service';
import { FilterChip } from '@app/standalone-components/buttons/filter-chips/filter-chips.component';
import { DateRange } from '@feedonomics/frontend-components';
import { IconDefinition, faFilter, faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { NgbCollapse, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs';

@Component({
    selector: 'fdx-logs-filters',
    templateUrl: './logs-filters.component.html',
    styleUrls: ['./logs-filters.component.scss']
})
export class LogsFiltersComponent implements OnInit {

    @Input() logType: LogType;
    /**
     * TODO: strictly type this input in the future
     */
    @Input() formGroup: FormGroup;

    @Output() readonly submitFilters: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild('filtersCollapse') filtersCollapse: NgbCollapse;

    readonly filterIcon: IconDefinition = faFilter;
    readonly warningIcon: IconDefinition = faTriangleExclamation;

    collapsed: boolean = false;
    minDate: NgbDateStruct;
    maxDate: NgbDateStruct;
    defaultFilters: string = null;
    previousFilters: string = null;
    showWarning: boolean = false;

    previousQuery: string = '';
    query: string = '';

    filterChips: FilterChip[] = [];

    get filtersActive(): boolean {
        return this.filterChips.length > 0 && this.filterChips.some((chip) => !chip.permanent);
    }

    get timeZoneCode(): string {
        return this.dateTimeService.getTimezoneCode();
    }

    constructor(
        private readonly dateTimeService: DateTimeService,
        private readonly logsStateService: LogsStateService,
        private readonly route: ActivatedRoute,
        private readonly router: Router
    ) {}

    ngOnInit(): void {
        this.initDateRange();
        this.initQueryParams();
        this.buildFilterChips();
    }

    initDateRange(): void {
        const today = dayjs(new Date());
        const monthAgo = today.subtract(1, 'month');
        this.minDate = this.dateTimeService.dayJStoNgbDateStruct(today.subtract(1, 'year'));
        this.maxDate = this.dateTimeService.dayJStoNgbDateStruct(today);

        // Grab the dateRange from the state service if it exists, otherwise initialize it
        let range = this.logsStateService.logsDateRangeFilter$.value;
        if (!range) {
            range = new DateRange(
                this.dateTimeService.dayJSToNGBDate(monthAgo),
                this.dateTimeService.dayJSToNGBDate(today)
            );

            // Set the date range in the
            this.logsStateService.logsDateRangeFilter$.next(range);
        }

        // Then patch the value
        this.formGroup.patchValue({
            dateRange: range
        });
    }

    initQueryParams(): void {
        const params = this.route.snapshot.queryParamMap;

        const query = params.get('query');

        if (query) {
            this.query = decodeURIComponent(query);
            this.previousQuery = this.query;
        } else {
            this.previousQuery = '';
        }

        this.logsStateService.logsQuickFilter$.next(this.query);
    }

    onSearchChange($event: string): void {
        // Null out query if it's empty so we can immediately remove it from the query params
        this.query = !isEmpty($event) ? $event : null;

        if (this.previousQuery !== this.query) {
            this.previousQuery = this.query;

            // keep current route but update query params
            void this.router.navigate(
                [],
                {
                    relativeTo: this.route,
                    replaceUrl: true,
                    queryParamsHandling: 'merge',
                    queryParams: {
                        query: this.query
                    }
                }
            );
        }

        this.logsStateService.logsQuickFilter$.next(this.query);
    }

    /**
     * On collapse, check the new filter values against the previous filter values. If they're different,
     * show the warning message.
     */
    toggleCollapse(): void {
        this.filtersCollapse.toggle();
        if (this.previousFilters !== JSON.stringify(this.formGroup.value)) {
            this.showWarning = true;
        }
    }

    /**
     * When we apply filters, set the previous filters to the new value, toggle off any warning message,
     * then update the state service.
     */
    applyFilters(): void {
        this.previousFilters = JSON.stringify(this.formGroup.value);
        this.showWarning = false;

        this.collapsed = true;
        this.buildFilterChips();
        this.onSearchChange(this.query);

        this.submitFilters.emit();
    }

    /**
     * When we clear all filters, reset everything but the date range, apply the new
     * filters, then reset the query params
     */
    clearAllFilters(): void {
        this.previousFilters = JSON.stringify(this.formGroup.value);
        this.showWarning = false;

        const oldDateRange = this.formGroup.value.dateRange;
        this.formGroup.reset({
            dateRange: oldDateRange
        }, { emitEvent: false });

        this.applyFilters();

        const queryParams: Params = {
            query: null
        };

        // keep current route but update query params
        void this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams
            }
        );
    }

    private buildFilterChips(): void {
        const newFilterChips: FilterChip[] = [];
        const controls = this.formGroup.controls;
        const values = this.formGroup.value;

        if (values.dateRange) {
            const startDateValue = values.dateRange.isoStartDate;
            const endDateValue = values.dateRange.isoEndDate;
            newFilterChips.push({
                label: 'Date range',
                control: controls.dateRange,
                display: this.getFormattedTimeFromNGB(startDateValue) + ' - ' + this.getFormattedTimeFromNGB(endDateValue) + ` (${this.timeZoneCode})`,
                permanent: true
            });
        }

        values.operation?.forEach((value: string) => {
            newFilterChips.push({
                label: 'Operation',
                control: controls.operation,
                display: value
            });
        });

        values.action?.forEach((value: string) => {
            newFilterChips.push({
                label: 'Action',
                control: controls.action,
                display: value
            });
        });

        values.field?.forEach((value: string) => {
            newFilterChips.push({
                label: 'Field',
                control: controls.field,
                display: value
            });
        });

        values.user?.forEach((value: string) => {
            newFilterChips.push({
                label: 'User',
                control: controls.user,
                display: value
            });
        });

        values.status?.forEach((value: string) => {
            newFilterChips.push({
                label: 'Status',
                control: controls.status,
                display: value
            });
        });

        values.module?.forEach((value: string) => {
            newFilterChips.push({
                label: 'Module',
                control: controls.module,
                display: value
            });
        });

        /* values.changeLogFilter?.forEach((value: string) => {
            newFilterChips.push({
                label: 'Change log filter',
                control: controls.changeLogFilter,
                display: value
            });
        }); */

        this.filterChips = newFilterChips;
    }

    clearFilter(filterToClear: FilterChip): void {
        const value: unknown = filterToClear.control.value;

        if (Array.isArray(value)) {
            const index = value.indexOf(filterToClear.display);
            if (index > -1) {
                value.splice(index, 1);
                if (value.length === 0) {
                    filterToClear.control.reset();
                } else {
                    filterToClear.control.patchValue(value);
                }
            }
        } else {
            filterToClear.control.reset();
        }

        this.applyFilters();
    }

    getFormattedTimeFromNGB(dateTime: string): string {
        return this.dateTimeService.getFormattedTime(dateTime, DISPLAY_DATE_FORMAT, NGB_DATE_FORMAT, this.dateTimeService.getTimezone());
    }
}
