import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CheatSheetOffcanvasComponent } from '@app/modules/cheat-sheet/components/cheat-sheet-offcanvas/cheat-sheet-offcanvas.component';
import { CheatSheetPanelContentComponent } from '@app/modules/cheat-sheet/components/cheat-sheet-panel-content/cheat-sheet-panel-content.component';
import { CheatSheetPanelHeaderComponent } from '@app/modules/cheat-sheet/components/cheat-sheet-panel-header/cheat-sheet-panel-header.component';
import { CheatSheetComponent } from '@app/modules/cheat-sheet/components/cheat-sheet/cheat-sheet.component';
import { DirectivesModule } from '@app/modules/directives/directives.module';
import { OffcanvasModule } from '@app/modules/offcanvas/offcanvas.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbAccordionModule, NgbOffcanvasModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [
    CheatSheetComponent,
    CheatSheetOffcanvasComponent,
    CheatSheetPanelContentComponent,
    CheatSheetPanelHeaderComponent
  ],
  imports: [
    CommonModule,
    DirectivesModule,
    FontAwesomeModule,
    NgbAccordionModule,
    NgbOffcanvasModule,
    NgbTooltipModule,
    OffcanvasModule,
    RouterModule
  ],
  exports: [
    CheatSheetComponent,
    CheatSheetOffcanvasComponent
  ]
})
export class CheatSheetModule { }
