import { Component, Input } from '@angular/core';
import { IconDefinition, faAngleRight } from '@fortawesome/pro-solid-svg-icons';

export type Breadcrumb = {
    title: string,
    route: string[]
};

@Component({
    selector: 'fdx-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent {

    @Input() breadcrumbs: Breadcrumb[];
    @Input() currentPageTitle: string;

    dividerIcon: IconDefinition = faAngleRight;

    trackByIndex(index: number): number {
        return index;
    }
}
