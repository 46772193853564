httpConfig.$inject = ['$httpProvider'];

export default function httpConfig($httpProvider) {

    $httpProvider.interceptors.push(['$q', '$rootScope', 'NavigationService', function ($q, $rootScope, NavigationService) {

        return {
            'responseError': function (rejection) {
                if (rejection.data === 'Not Logged In') {
                    NavigationService.handleNotLoggedIn();
                }

                if (rejection.data === 'Maintenace Mode') {
                    $rootScope.$broadcast('maintenanceMode');
                }

                return $q.reject(rejection);

            }
        };
    }]);

}
