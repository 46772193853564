import fdxUI from '@ajs/services/fdxUI';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DbFieldModel } from '@app/databases/models/db-field.model';
import ClassificationDataService from '@app/feed-ai/services/classification-data.service';
import { ModalService } from '@app/modules/modals/services/modal.service';
import { faQuestionCircle, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { finalize, Subject, switchMap, takeUntil } from 'rxjs';

interface FormSection {
    label: string;
    formControlName: string;
    tooltip: string;
    tooltipTitle: string;
}

@Component({
    selector: 'fdx-feed-ai-classification-tab',
    templateUrl: './feed-ai-classification-tab.component.html',
    styleUrls: ['./feed-ai-classification-tab.component.scss']
})
export class FeedAiClassificationTabComponent implements OnChanges, OnDestroy, OnInit {
    @Input() databaseFields: DbFieldModel[] = [];
    @Input() databaseId: string;

    private readonly unsubscribe$: Subject<void> = new Subject<void>();

    databaseFieldNames: string[] = [];
    faQuestionCircle:IconDefinition = faQuestionCircle;
    form: UntypedFormGroup = new UntypedFormGroup({
        classifierFieldName: new UntypedFormControl(''),
        gtinFieldName: new UntypedFormControl(''),
        pidFieldName: new UntypedFormControl(''),
        titleFieldName: new UntypedFormControl('')
    });
    formSections: FormSection[] = [
        {
            label: 'FeedAI™ Output Field Name',
            formControlName: 'classifierFieldName',
            tooltip: 'Tooltip: FeedAI™ will automatically place the categorized Google Product Category here.',
            tooltipTitle: 'FeedAI™ Output Field Name'
        },
        {
            label: 'ID Field Name',
            formControlName: 'pidFieldName',
            tooltip: 'Tooltip: Your product ID (should be unique for each product).',
            tooltipTitle: 'ID Field Name'
        },
        {
            label: 'GTIN Field Name',
            formControlName: 'gtinFieldName',
            tooltip: 'Tooltip: Your GTIN or UPC',
            tooltipTitle: 'GTIN Field Name'
        },
        {
            label: 'Title Field Name',
            formControlName: 'titleFieldName',
            tooltip: 'Tooltip: Your product title. This should be your optimized product title, not raw.',
            tooltipTitle: 'Title Field Name'
        }
    ];

    constructor(
        private classificationDataService: ClassificationDataService,
        private fdxUI: fdxUI,
        private modalService: ModalService
    ) { }

    ngOnChanges({ databaseFields }: SimpleChanges): void {
        if (databaseFields.currentValue !== databaseFields.previousValue) {
            this.databaseFieldNames = databaseFields.currentValue
                .map(({ field_name }) => {
                    return field_name;
                })
                .sort();
        }
    }

    ngOnInit(): void {
        this.fdxUI.startBlockUI();

        this.classificationDataService.getByDbId(this.databaseId)
            .pipe(
                finalize(() => {
                    this.fdxUI.stopBlockUI();
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(({ classifierFieldName, gtinFieldName, pidFieldName, titleFieldName }) => {
                    this.form.patchValue({
                        classifierFieldName: classifierFieldName || null,
                        gtinFieldName: gtinFieldName || null,
                        pidFieldName: pidFieldName || null,
                        titleFieldName: titleFieldName || null
                    });
                },
                ({ error }: HttpErrorResponse) => {
                    this.fdxUI.showToastError(error);
                }
            );
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    clearCache(): void {
        this.modalService.showDangerConfirmationModal(
            'Confirm Clear Cache',
            'Are you sure you want to clear the classifier cache?',
            'Confirm'
        )
            .closed
            .pipe(
                switchMap(() => {
                    this.fdxUI.startBlockUI();

                    return this.classificationDataService.clearCache(this.databaseId);
                }),
                finalize(() => {
                    this.fdxUI.stopBlockUI();
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => {
                    this.fdxUI.showToastSuccess('The classification cache has been cleared');
                },
                (error: HttpErrorResponse) => {
                    this.fdxUI.showToastError(error.error || 'System error while clearing classifier cache');
                }
            );
    }

    save(): void {
        this.fdxUI.startBlockUI();

        this.classificationDataService.update(this.databaseId, {
            classifierFieldName: this.form.get('classifierFieldName').value || '',
            gtinFieldName: this.form.get('gtinFieldName').value || '',
            pidFieldName: this.form.get('pidFieldName').value || '',
            titleFieldName: this.form.get('titleFieldName').value || '',
        })
            .pipe(
                finalize(() => {
                    this.fdxUI.stopBlockUI();
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => {
                    this.fdxUI.showToastSuccess('The classification has been updated');
                },
                (error: HttpErrorResponse) => {
                    this.fdxUI.showToastError(error.error.message);
                }
            );
    }
}
