import * as angular from 'angular';
import { MODULE_NAME } from '../../../config/constants';
import FdxDiffTableController from './fdx-diff-table.controller';

import './fdx-diff-table.styles.scss';

export const componentName = 'fdxDiffTable';

angular.module(MODULE_NAME).component(componentName, {
    bindings: {
        source: '<',
        sourceHeader: '@',
        target: '<',
        targetHeader: '@',
        title: '@'
    },
    controller: FdxDiffTableController,
    template: require('./fdx-diff-table.template.html')
});
