import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'search'
})
export class SearchPipe implements PipeTransform {
    transform(items: string[], query: string): string[] {
        if (!items) {
            return [];
        }

        if (!query) {
            return items;
        }

        const queryTerms: string[] = query.toLowerCase().split(/\s+/);

        return queryTerms.reduce((filteredItems, queryTerm) => {
            const negate = queryTerm[0] === '!';
            const searchTerm = negate ? queryTerm.slice(1) : queryTerm;

            return filteredItems.filter((item) => {
                const lowercaseItem = item.toLowerCase();

                if (negate) {
                    return !lowercaseItem.includes(searchTerm);
                }

                return lowercaseItem.includes(searchTerm);
            });
        }, items);
    }
}