import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { LogsInfoModalComponent } from '@app/logs/components/logs-info-modal/logs-info-modal.component';
import { ModalService } from '@app/modules/modals/services/modal.service';

@Component({
    selector: 'fdx-log-entry-cell-renderer',
    templateUrl: './log-entry-cell-renderer.component.html',
    styleUrls: ['./log-entry-cell-renderer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogEntryCellRendererComponent implements ICellRendererAngularComp {

    params: ICellRendererParams;

    constructor(
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly modalService: ModalService
    ) { }

    public agInit(params: ICellRendererParams): void {
        this.bindParams(params);
        this.changeDetectorRef.markForCheck();
    }

    public refresh(params: ICellRendererParams): boolean {
        this.bindParams(params);
        this.changeDetectorRef.markForCheck();
        return true;
    }

    private bindParams(params: ICellRendererParams): void {
        this.params = params;
    }

    openRawLog(): void {
        this.modalService.open(LogsInfoModalComponent, {
            resolve: {
                raw: this.params.data
            },
            size: 'lg',
            scrollable: true
        });
    }
}
