import { UserModel } from '../models/user';

export default class ImpersonateUserService {

    static $inject = [
        '$cookies',
        '$log',
        'UserAccessService',
        'fdxUtils'
    ];

    user = null;
    
    constructor(
        private $cookies,
        private $log,
        private UserAccessService,
        private fdxUtils
    ) { }

    setUser(impersonatedUserId: string, loggedInUser: UserModel=null, accountId: string=null): void {
        
        if (impersonatedUserId === null || loggedInUser === null || accountId === null) {
            this.user = null;
            this.$cookies.remove('impersonated_user_id');
            return;
        }

        const promise = this.UserAccessService.getUserById(accountId, impersonatedUserId);
        promise.then(
            data => {
                // make sure we don't turn client_demo_view on
                if (!this.fdxUtils.isEmpty(data.settings_json)) {
                    const settings = JSON.parse(data.settings_json);
                    settings['user.client_demo_view'] = false;
                    data.settings_json = JSON.stringify(settings);
                }

                const impersonatedUser = new UserModel({
                    ...loggedInUser,
                    settings: data.settings_json,
                    features: data.features,
                    impersonated_user_name: data.user_name,
                    impersonated_user_id: data.user_id,
                });

                this.user = impersonatedUser;
                this.$cookies.put('impersonated_user_id', impersonatedUserId);
            },
            error => {
                this.$log.error(error);
            }
        )
    }

    getUser(): UserModel {
        return this.user;
    }

}