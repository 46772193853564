import AppStateService from '@ajs/services/AppStateService';
import FdxUI from '@ajs/services/fdxUI';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppMenuTab } from '@app/core/models/enums/app-menu-tab.enum';
import { LinkService } from '@app/core/services/link.service';
import { DbFieldModel } from '@app/databases/models/db-field.model';
import { DatabasesDataService } from '@app/databases/services/databases-data.service';
import { BasePageComponent } from '@app/modules/page/abstract/base-page.component';
import { DateRangeModel } from '@app/reports-sku/models/date-range.model';
import { JoinPerformanceReportType } from '@app/reports-sku/types/join-performance-report.type';
import { EmptyStateBodyTextSize, EmptyStateImageType } from '@feedonomics/frontend-components';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { distinctUntilChanged, takeUntil } from 'rxjs';

@Component({
    selector: 'fdx-reports-sku-page',
    templateUrl: './reports-sku-page.component.html',
    styleUrls: ['./reports-sku-page.component.scss']
})
export class ReportsSkuPageComponent extends BasePageComponent implements OnInit {

    appMenuTab: AppMenuTab = AppMenuTab.Reports;
    title: string = 'Reports SKU';

    dbFields: DbFieldModel[] = [];

    error: string = null;

    dynamicDateRanges: DateRangeModel[] = [
        { label: 'Last Week', value: '-1 week -1 day' },
        { label: 'Last Month', value: '-1 month -1 day' },
        { label: 'Last Year', value: '-1 year -1 day' },
        { label: 'Custom', value: 'custom' }
    ];

    reportsSkuForm: UntypedFormGroup = new UntypedFormGroup({
        channel: new UntypedFormControl(null, [Validators.required]),
        idFieldName: new UntypedFormControl(null, [Validators.required]),
        dateType: new UntypedFormControl(null, [Validators.required]),
        staticDateRangeStart: new UntypedFormControl(null),
        staticDateRangeEnd: new UntypedFormControl(null),
        dynamicDate: new UntypedFormControl(null),
        customDateRangeStart: new UntypedFormControl(null),
        customDateRangeEnd: new UntypedFormControl(null)
    });

    get isAuthorized(): boolean {
        return this.appStateService.getDatabase().oauth_id !== '0';
    }

    emptyStateImageType: typeof EmptyStateImageType = EmptyStateImageType;
    emptyStateBodyTextSize: typeof EmptyStateBodyTextSize = EmptyStateBodyTextSize;

    constructor(
        protected fdxUI: FdxUI,
        private readonly appStateService: AppStateService,
        private readonly databasesDataService: DatabasesDataService,
        private readonly ngbDateParserFormatter: NgbDateParserFormatter,
        private readonly router: Router,
        private readonly linkService: LinkService
    ) {
        super(fdxUI);
    }

    ngOnInit(): void {
        super.ngOnInit();

        /* Dynamic validation based on what is shown */
        this.reportsSkuForm.valueChanges.pipe(
            distinctUntilChanged(),
            takeUntil(this.unsubscribe$)
        ).subscribe(() => {
            if (this.isShowingStatic()) {
                this.staticDateRangeStart.addValidators([Validators.required]);
                this.staticDateRangeEnd.addValidators([Validators.required]);

                this.resetDynamic();
                this.resetDynamicCustom();
            } else if (this.isShowingDynamic()) {
                this.dynamicDate.addValidators([Validators.required]);

                if (this.isShowingDynamicCustom()) {
                    this.customDateRangeStart.addValidators([Validators.required]);
                    this.customDateRangeEnd.addValidators([Validators.required]);
                } else {
                    this.resetDynamicCustom();
                }

                this.resetStatic();
            }
            this.reportsSkuForm.updateValueAndValidity({ emitEvent: false });
        });
        /* Dynamic validation based on what is shown */

        // Set default values
        const endDate = new Date();
        const startDate = new Date(endDate.getTime() - (1000 * 60 * 60 * 24 * 30)); // milisecs * secs * mins * hours * days (in a month)

        this.channel.patchValue('google_shopping');
        this.dateType.patchValue('static');

        this.staticDateRangeStart.patchValue({
            year: startDate.getUTCFullYear(),
            month: startDate.getUTCMonth() + 1,
            day: startDate.getUTCDate()
        });

        this.staticDateRangeEnd.patchValue({
            year: endDate.getUTCFullYear(),
            month: endDate.getUTCMonth() + 1,
            day: endDate.getUTCDate()
        });

        this.getDBFields();
    }

    resetStatic(): void {
        this.staticDateRangeStart.clearValidators();
        this.staticDateRangeEnd.clearValidators();

        this.staticDateRangeStart.setErrors(null);
        this.staticDateRangeEnd.setErrors(null);
    }

    resetDynamic(): void {
        this.dynamicDate.clearValidators();

        this.dynamicDate.setErrors(null);
    }

    resetDynamicCustom(): void {
        this.customDateRangeStart.clearValidators();
        this.customDateRangeEnd.clearValidators();

        this.customDateRangeStart.setErrors(null);
        this.customDateRangeEnd.setErrors(null);
    }

    getDBFields(): void {
        this.databasesDataService.getFields(this.appStateService.getDatabaseId())
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((data) => {
                this.dbFields = data;
            });
    }

    isShowingStatic(): boolean {
        return this.dateType.value === 'static';
    }

    isShowingDynamic(): boolean {
        return this.dateType.value === 'dynamic';
    }

    isShowingDynamicCustom(): boolean {
        return this.isShowingDynamic() && this.dynamicDate.value === 'custom';
    }

    buildParams(): JoinPerformanceReportType {
        const params: JoinPerformanceReportType = {
            'channel': this.channel.value,
            'id_field_name': this.idFieldName.value,
            'start_date': null,
            'end_date': null
        };

        if (this.isShowingStatic()) {
            params.start_date = this.ngbDateParserFormatter.format(this.staticDateRangeStart.value);
            params.end_date = this.ngbDateParserFormatter.format(this.staticDateRangeEnd.value);
        } else if (this.isShowingDynamic()) {
            // Non custom pre-selected date range (week, month, year...)
            if (this.isShowingDynamicCustom()) {
                params.start_date = this.customDateRangeStart.value;
                params.end_date = this.customDateRangeEnd.value;
            } else {
                params.start_date = this.dynamicDate.value;
                params.end_date = '-1 day';
            }
        }

        return params;
    }

    joinPerformanceReport(): void {
        if (this.reportsSkuForm.valid) {
            this.fdxUI.showToastInfo('Fetching Report...');

            const params = this.buildParams();

            this.databasesDataService.getJoinPerformanceReport(this.appStateService.getDatabaseId(), params)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe({
                    next: () => {
                        this.fdxUI.showToastSuccess('The SKU Report has been created');
                        this.error = null;
                    },
                    error: (error: HttpErrorResponse) => {
                        this.error = error.error;
                    }
                });
        }
    }

    navigateToAuthorization(): void {
        void this.router.navigate([...this.linkService.reportsAuthorizationLink, 'google-adwords']);
    }

    get channel(): UntypedFormControl {
        return this.reportsSkuForm.controls.channel as UntypedFormControl;
    }

    get idFieldName(): UntypedFormControl {
        return this.reportsSkuForm.controls.idFieldName as UntypedFormControl;
    }

    get dateType(): UntypedFormControl {
        return this.reportsSkuForm.controls.dateType as UntypedFormControl;
    }

    get staticDateRangeStart(): UntypedFormControl {
        return this.reportsSkuForm.controls.staticDateRangeStart as UntypedFormControl;
    }

    get staticDateRangeEnd(): UntypedFormControl {
        return this.reportsSkuForm.controls.staticDateRangeEnd as UntypedFormControl;
    }

    get dynamicDate(): UntypedFormControl {
        return this.reportsSkuForm.controls.dynamicDate as UntypedFormControl;
    }

    get customDateRangeStart(): UntypedFormControl {
        return this.reportsSkuForm.controls.customDateRangeStart as UntypedFormControl;
    }

    get customDateRangeEnd(): UntypedFormControl {
        return this.reportsSkuForm.controls.customDateRangeEnd as UntypedFormControl;
    }
}
