export const HOSTS = [
    'sftpgo.feedonomics.com',
    'sftpgo1.feedonomics.com',
    'sftpgo2.feedonomics.com',
    'sftpgo3.feedonomics.com',
    'sftp.feedonomics.com',
    'app.feedonomics.com',
    'ftp2.feedonomics.com',
    'ftp3.feedonomics.com',
    'ebaymotors.feedonomics.com'
]
