import { getEncodedUrlPath } from '@app/core/functions';
import { StateService } from '@uirouter/angularjs';
import * as angular from 'angular';
import 'angular-cookies';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare const config_destination_url: string; // config_destination_url is a global variable defined on index.php

export default class NavigationService {
    static readonly $inject: string[] = [
        '$location',
        '$state',
        '$window',
        '$cookies'
    ];

    readonly saveKeyLogin: string = 'return_login';
    readonly saveKeyAccountSelection: string = 'return_account';

    constructor(
        private readonly $location: angular.ILocationService,
        private readonly $state: StateService,
        private readonly $window: angular.IWindowService,
        private readonly $cookies: angular.cookies.ICookiesService
    ) {
    }

    isLoginPendingRestore(): boolean {
        return this.getLoginPathToRestore() !== null;
    }

    getLoginPathToRestore(): string | null {
        const state = this.$cookies.get(this.saveKeyLogin);
        return !angular.isUndefined(state) ? state : null;
    }

    restoreAfterLogin(): void {
        const loginPathToRestore = this.getLoginPathToRestore();
        this.setLoginDone();
        this.redirectToPath(loginPathToRestore);
    }

    setLoginDone(): void {
        this.$cookies.remove(this.saveKeyLogin);
    }

    setLoginPending(value: string): void {
        this.$cookies.put(this.saveKeyLogin, value);
    }

    isAccountSelectionPendingRestore(): boolean {
        return this.getAccountSelectionPathToRestore() !== null;
    }

    getAccountSelectionPathToRestore(): string | null {
        const state = this.$cookies.get(this.saveKeyAccountSelection);
        return !angular.isUndefined(state) ? state : null;
    }

    restoreAfterAccountSelection(): void {
        const pathToRestore = this.getAccountSelectionPathToRestore();
        this.setAccountSelectionDone();
        this.redirectToPath(pathToRestore);
    }

    setAccountSelectionDone(): void {
        this.$cookies.remove(this.saveKeyAccountSelection);
    }

    setAccountSelectionPending(value: string): void {
        this.$cookies.put(this.saveKeyAccountSelection, value);
    }

    handleNotLoggedIn(): void {
        this.setLoginPending(this.getCurrentPath());
        this.redirectToLogin();
    }

    handleSelectAccount(): void {
        this.setAccountSelectionPending(this.getCurrentPath());
        this.redirectToAccountSelection();
    }

    getCurrentPath(): string {
        return getEncodedUrlPath() ?? '';
    }

    redirectToLogin(): void {
        let queryParams = '';
        const loginPath = this.getLoginPathToRestore();
        console.log(loginPath);
        if (loginPath) {
            const path = loginPath;
            queryParams = `app=feedonomics&redirect=${path}`;
        } else {
            // sent here from the logout button in the app main menu
            queryParams = this.$window.location.href.split('?')[1] ?? '';
        }
        setTimeout(() => {
            this.$window.location.href = `${config_destination_url}/logout?${queryParams}`;
        });
    }

    redirectToPath(path: string): void {
        this.$location.path(path);
    }

    redirectToAccountSelection(): void {
        void this.$state
            .go(
                'app.accounts',
                undefined,
                {
                    location: 'replace',
                    reload: true,
                    supercede: true
                }
            );
    }

    goToUrl(url: string): void {
        this.$location.url(url);
    }

}

export { NavigationService };
