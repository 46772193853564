import { Injectable } from '@angular/core';
import { Export } from '@app/exports/services/responses/get-exports.response';
import { FtpTriggerFieldModel } from '@app/ftp-trigger/models/ftp-trigger-field.model';

@Injectable({
    providedIn: 'root'
})
export class ExportsFormatService {
    exportHasFtpTrigger(exportItem: Export, triggers: FtpTriggerFieldModel[]): boolean {
        if (typeof exportItem === 'undefined' && triggers.length === 0) {
            return false;
        }

        return triggers.some((trigger) => trigger.export_id === exportItem.id);
    }
}
