<div class="hstack gap-2">
    <button
        name="search"
        type="button"
        class="btn flex-grow-1"
        [ngClass]="mainButtonClass"
        (click)="onSearch()"
        [disabled]="disableSearchButton">
        {{mainButtonText}}
    </button>
    <button
        name="clear"
        type="button"
        class="btn btn-outline-secondary flex-grow-1"
        (click)="onClear()"
        [disabled]="disableClearButton">
        Clear
    </button>
</div>
