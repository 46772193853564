import FdxError from '../models/FdxError';

export default class EventAlertsDataService {

  // Services
  $http = null;
  $q = null;
  fdxUtils = null;

  constructor($http, $q, fdxUtils) {
    this.$http = $http;
    this.$q = $q;
    this.fdxUtils = fdxUtils;
  }


  getAll(idAccount) {

    const def = this.$q.defer();

    const promise = this.$http.get('/api.php/accounts/' + idAccount + '/event_alert_lists/list');

    promise.then(
      (response) => {
        def.resolve(response.data.data);
      },
      (response) => {
        def.reject(new FdxError(response.data.error.code, response.data.error.message));
      }
    );

    return def.promise;

  }

  create(idAccount, data) {

    const def = this.$q.defer();

    const promise = this.$http.post('/api.php/accounts/' + idAccount + '/event_alert_lists', data);

    promise.then(
      (response) => {
        def.resolve(response.data.data);
      },
      (response) => {
        def.reject(new FdxError(response.data.error.code, response.data.error.message));
      }
    );

    return def.promise;

  }

  update(idAccount, eventAlertId, data) {

    const def = this.$q.defer();

    const promise = this.$http.put('/api.php/accounts/' + idAccount + '/event_alert_lists/' + eventAlertId, data);

    promise.then(
      (response) => {
        def.resolve(response.data.data);
      },
      (response) => {
        def.reject(new FdxError(response.data.error.code, response.data.error.message));
      }
    );

    return def.promise;

  }

  delete(idAccount, eventAlertId) {

    const def = this.$q.defer();

    const promise = this.$http.delete("/api.php/accounts/" + idAccount + "/event_alert_lists/" + eventAlertId);

    promise.then(
      (response) => {
        def.resolve(response.data.data);
      },
      (response) => {
        def.reject(new FdxError(response.data.error.code, response.data.error.message));
      }
    );

    return def.promise;

  }

  getEventAlertTypes(idAccount) {

    const def = this.$q.defer();

    const promise = this.$http.get("/api.php/accounts/" + idAccount + "/event_alert_lists/all/list");

    promise.then(
      (response) => {
        def.resolve(response.data.data);
      },
      (response) => {
        def.reject(new FdxError(response.data.error.code, response.data.error.message));
      }
    );

    return def.promise;

  }


}

EventAlertsDataService.$inject = ['$http', '$q', 'fdxUtils'];