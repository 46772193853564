import * as angular from 'angular';
import FdxConfirmationModalController from './fdx-confirmation-modal.controller';
import { MODULE_NAME } from '../../config/constants';

import './fdx-confirmation-modal.styles.scss';

export const componentName = 'fdxConfirmationModal';

angular.module(MODULE_NAME).component(componentName, {
	template: require('./fdx-confirmation-modal.template.html'),
	controller: FdxConfirmationModalController,
	bindings: {
		// from uibmodal
		close: '&',
		dismiss: '&',
		resolve: '<?'
	}
});
