import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { BootstrapThemeProp, BootstrapThemeService, BootstrapThemeType } from '@app/core/services/bootstrap-theme.service';
import { ClassInput, normalizeClassInput } from '@feedonomics/frontend-components';
import { IconDefinition, faSpinner } from '@fortawesome/pro-solid-svg-icons';

@Component({
    selector: 'fdx-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit, OnChanges {

    @Input() theme: BootstrapThemeProp = 'info';
    @Input() isLoading: boolean = false;
    @Input() isLargeAlert: boolean = false;

    /**
     * Optional, will be bolded text on the first row
     */
    @Input() title?: string | null;

    /**
     * Optional, will be placed to the right of the title on the first row, could use ng-content instead
     */
    @Input() body?: string | null;

    /**
     * Optional, will be content placed under the first row of text
     */
    @Input() extraContent?: TemplateRef<unknown>;

    @Input() iconOverride?: IconDefinition;

    @Input() classes?: ClassInput;

    @Input() clickable?: boolean = false;
    @Output() readonly alertClick?: EventEmitter<void> = new EventEmitter<void>();

    @Input() dismissible?: boolean = false;
    @Output() readonly dismissed?: EventEmitter<void> = new EventEmitter<void>();

    readonly spinnerIcon: IconDefinition = faSpinner;

    protected themeType: BootstrapThemeType;

    get alertClasses(): Record<string, boolean> {
        return {
            'align-items-start': this.isLargeAlert,
            'align-items-center': !this.isLargeAlert,
            'p-0': this.clickable,
            ...normalizeClassInput(this.classes)
        };
    }

    constructor(
        private readonly bootstrapThemeService: BootstrapThemeService,
        private readonly host: ElementRef<HTMLElement>
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if ('title' in changes) {
            if (!this.endsWithPunctuation(this.title)) {
                this.title = `${this.title}:`;
            }
        }
    }

    ngOnInit(): void {
        this.themeType = this.bootstrapThemeService.getThemeType(this.theme);
    }

    endsWithPunctuation(str: string): boolean {
        return /[.;:!?]$/.test(str);
    }

    alertClicked(): void {
        this.alertClick.emit();
    }

    close(): void {
        this.dismissed.emit();
        this.host.nativeElement.remove();
    }

}
