import { InjectionToken } from '@angular/core';

/**
 * Injection token for global window object.
 * 
 * Can be injected into components using:
 * ```typescript
 * constructor(@Inject(WINDOW) private window: Window)
 * ```
 * 
 * Can be mocked in unit tests:
 * ```typescript
 * TestBed.configureTestingModule({
 *   providers: [
 *     {
 *       provide: WINDOW,
 *       useValue: mockWindow
 *     }
 *   ]
 * });
 * ```
 */
export const WINDOW = new InjectionToken<Window>(
    'Window',
    {
        providedIn: 'root',
        factory(): Window {
            return window;
        }
    }
);
