import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogType } from '@app/logs/models/enums/log-type.enum';
import { LogsRequest } from '@app/logs/services/requests/logs-request.type';
import { ChangeLogsResponse, EDRTSLogsResponse, ExportEventQueueLogsReponse, FeedAlertsLogsResponse, ImportExportLogsResponse, TransformerLogsResponse } from '@app/logs/services/responses/logs-responses.types';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LogsDataService {

    constructor(
        private readonly http: HttpClient
    ) { }

    public getImportsExportLogs(databaseId: string, params: LogsRequest): Observable<ImportExportLogsResponse> {
        return this.http.get<ImportExportLogsResponse>(`/api.php/dbs/${databaseId}/logs`, {
            params: {
                ...params,
                log_type: LogType.ImportExport,
            }
        });
    }

    public getTransformerLogs(databaseId: string, params: LogsRequest): Observable<TransformerLogsResponse> {
        return this.http.get<TransformerLogsResponse>(`/api.php/dbs/${databaseId}/logs`, {
            params: {
                ...params,
                log_type: LogType.Transformer,
            }
        });
    }

    public getFeedAlertsLogs(databaseId: string, params: LogsRequest): Observable<FeedAlertsLogsResponse> {
        return this.http.get<FeedAlertsLogsResponse>(`/api.php/dbs/${databaseId}/logs`, {
            params: {
                ...params,
                log_type: LogType.FeedAlerts,
            }
        });
    }

    public getChangeLogs(databaseId: string, params: LogsRequest): Observable<ChangeLogsResponse> {
        return this.http.get<ChangeLogsResponse>(`/api.php/dbs/${databaseId}/logs`, {
            params: {
                ...params,
                log_type: LogType.Change,
            }
        });
    }

    public getExportEventQueueLogs(databaseId: string, exportId: string): Observable<ExportEventQueueLogsReponse> {
        return this.http.get<ExportEventQueueLogsReponse>(`/api.php/dbs/${databaseId}/exports/${exportId}/export_queue_logs`);
    }

    public getEDRTSLogs(databaseId: string, params: LogsRequest): Observable<EDRTSLogsResponse> {
        return this.http.get<EDRTSLogsResponse>(`/api.php/dbs/${databaseId}/logs`, {
            params: {
                ...params,
                log_type: LogType.EDRTS,
            }
        });
    }
}
