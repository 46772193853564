import FdxError from '../models/FdxError';
import { isEmpty } from '@app/core/functions/isEmpty';

export default class ImportsValidationService {

  // Services
  preprocessRegexURL = /https:\/\/preprocess[\d]+.feedonomics[.]com/;

  validateImport(importData) {

    if (isEmpty(importData.name)) {
      throw new FdxError('IMPORT_NAME_EMPTY', 'Import Name cannot be empty');
    }

    switch (importData.file_location) { // TODO: Move all the validations to this service and add unit test.
      case "local":
        this.validateDatasourceLocal(importData);
        break;

      case "ftp":
        break;
      case "url":
        this.validateUrlImportUrl(importData);
        // TODO: IMPLEMENT ME
        break;
      case "preprocess_script":
        this.validatePreprocessScriptImportUrl(importData);
        break
    }

  }

  validateDatasourceLocal(importData) {

    if (!importData.hasOwnProperty('file') || importData.file.length < 1) {
      throw new FdxError('NO_FILE_PROVIDED', 'No File Provided');
    }

  }

  validateUrlImportUrl(importData) {
    let url = importData.import_info.url;

    if (isEmpty(url)) {
      throw new FdxError('MISSING_URL', 'Import URL is missing');
    } else {
      if (url.match(this.preprocessRegexURL)) {
        throw new FdxError('INVALID_IMPORT_URL', 'Invalid import URL');
      }
    }

  }

  validatePreprocessScriptImportUrl(importData) {
    let url = importData.import_info.preprocess_info.connection_info.url;
    let protocol = importData.import_info.preprocess_info.connection_info.protocol;

    if (protocol == 'url') {
      if (isEmpty(url)) {
        throw new FdxError('MISSING_URL', 'Import URL is missing');
      } else {
        if (url.match(this.preprocessRegexURL)) {
          throw new FdxError('INVALID_IMPORT_URL', 'Invalid import URL');
        }
      }
    }

    // Aggregate/Delta requires a value for dedupe fields
    let request_type = importData.import_info.preprocess_info?.file_info?.request_type;
    if (request_type === 'aggregate_delta') {
      importData.import_info.preprocess_info?.actions.forEach((action, index)=> {
        if (action.id === 'dedupe' &&
          (  (action.options?.delimiter??'') === ''
          || (action.options?.enclosure??'') === ''
          || (action.options?.escape??'') === ''
          || (action.options?.field??'') === ''
          )
        ){
          throw new FdxError('MISSING_DELTA_DEDUPE', 'Deduplicate rows cannot be empty');
        }
      })
    }
  }

}
