interface Row {
    created: boolean;
    deleted: boolean;
    source: string;
    target: string;
}

export default class FdxDiffTableController {
    rows: Row[] = [];
    source: string[] = [];
    sourceHeader = '';
    target: string[] = [];
    targetHeader = '';

    $onChanges() {
        this.initHeaders();
        this.initRows();
    }

    initHeaders(): void {
        this.sourceHeader = this.sourceHeader || 'Source A';
        this.targetHeader = this.targetHeader || 'Source B';
    }

    initRows(): void {
        const set1 = new Set(this.source);
        const set2 = new Set(this.target);
        const union = this.union(set1, set2);

        this.rows = [];

        for (const value of union) {
            const created = !set1.has(value) && set2.has(value);
            const deleted = set1.has(value) && !set2.has(value);
            const unchanged = !created && !deleted;

            const source = deleted || unchanged ? value : '';
            const target = created || unchanged ? value: '';

            this.rows.push({
                created,
                deleted,
                source,
                target
            });
        }
    }

    statusClasses(row: Row): Record<string, boolean> {
        return {
            'item-created': row.created,
            'item-deleted': row.deleted
        };
    }

    union<T>(a: Set<T>, b: Set<T>): Set<T> {
        let unionSet = new Set(a);

        for (const item of b) {
            unionSet.add(item);
        }

        return unionSet;
    }
}