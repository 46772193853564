import { Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'durationFormat'
})
export class DurationPipe implements PipeTransform {

    transform(duration: number): string {
        const hrs: number = ~~(duration / 3600);
        const min: number = ~~((duration % 3600)/60);
        const sec: number = Math.round(duration % 60);

        return `${hrs ? hrs + ' hr ': ''} ${min ? min + ' min ' : ''} ${sec  ? sec + ' sec ': ''}`
    }
}
