import * as angular from 'angular';
import { MODULE_NAME } from '../config/constants';

angular.module(MODULE_NAME).controller('DiffToolController', ['$scope', '$http', '$timeout', '$location', '$window', '$document', 'AppStateService', 'fdxUI', 'ngToast', '$upload', function ($scope, $http, $timeout, $location, $window, $document, AppStateService, fdxUI, ngToast, $upload) {
	fdxUI.setTitle('Diff Tool');
	fdxUI.setActiveTab('data');

	const databaseId = AppStateService.getDatabaseId();

	$scope.current_db = AppStateService.getDatabase();

	$scope.selected = {
		//source          : {},
		source               : {
			type : "url",
			name : "left_file",
			group: "Others",
		},
		source_file_type     : "delimited",
		source_url           : "",
		source_file			 : null,
		source_contents      : "",
		source_map           : {},
		source_fetch_disabled: false,

		target               : {
			type : "url",
			name : "right_file",
			group: "Others",
		},
		target_file_type     : "delimited",
		target_url           : "",
		target_file			 : null,
		target_contents      : "",
		target_map           : {},
		target_fetch_disabled: false,
	};

	$scope.summary = {
		primary_key          : "",
		selectable_fields    : [],
		fields               : [],
		download_full_results: true,
		download_join_file   : true
	};

	$scope.files_dropdown = [
		{
			type : "url",
			name : "URL",
			group: "Others",
		}
	];

	$scope.file_types = [
		'delimited',
		'xml',
		'json',
		'ndjson'
	];

	$scope.file_location_options = [
		{
			'display_name': 'URL',
			'group': 'Generic',
			'value': 'url',
			'api_integration': false,
			'enabled': function() {return true;}
		},
		{
			'display_name': 'Local',
			'group': 'Generic',
			'value': 'local',
			'api_integration': false,
			'enabled': function() {return true;}
		}
	];

	$scope.resultsFilterOptions = [];
	$scope.resultsFilterOption = 'all';

	$scope.filterResults = function(filterValue) {
		if (filterValue === 'all') {
			$scope.details = $scope.results.details;
		} else {
			$scope.details = $scope.results.details.filter(detail => {return detail.status_detail.indexOf(filterValue) !== -1});
		}
	};
	$scope.source_fields = [];
	$scope.target_fields = [];

	$scope.columnStats = {};
	$scope.attributes = {};

    $scope.diffLoading = false;

	var add_file = function (file_obj, type) {
		var file     = file_obj;
		file.type    = type;
		file.fetched = false;

		if (type == "import") {

			file.group = "Import";

			if (file_obj.join_type == 'product_feed') {
				file.group += " - Product Feeds";
			} else {
				file.group += " - Joins";
			}
			file.name        = file_obj.name;
			file.resource_id = file_obj.id;
			file.fetched     = true;

			get_import_versions(file);
		}

		$scope.files_dropdown.push(file);
	};

	var get_import_versions = function (imprt) {
		imprt.version = {
			list    : [
				{
					display_name: 'Latest',
					group       : '',
					value       : 'latest'
				},
			],
			selected: 'latest'
		};

		var skip_latest_s3_version = false;
		if (
			typeof imprt.last_backup_time != 'undefined'
			&& typeof imprt.last_download_time != 'undefined'
			&& imprt.last_backup_time != ''
			&& imprt.last_download_time != ''
		) {
			if (new Date(imprt.last_backup_time) >= new Date(imprt.last_download_time)) {
				skip_latest_s3_version = true;
			}
		}

		$http.get('/api.php/dbs/' + databaseId + '/imports/' + imprt.id + '/get_s3_versions')
			.then(function (response) {
				var data = response.data;
				if (typeof data == 'object') {
					var ver = 0;
					Object.keys(data).forEach(function (key) {
						if (!skip_latest_s3_version || ver > 0) {
							imprt.version.list.push({display_name: data[key] * 1000, group: 'Previous Versions', value: key});
						}
						ver++;
					});
				}
			});
	}

	var get_file_contents = function (file_id, type, pull_type) { // note: type here refers to  source/destination. pull_type has values like "url","local"


		var contents_var  = type + "_contents",
			map_var       = type + '_map',
			file_type_var = type + '_file_type',
			params        = {
				format   : "raw",
				limit    : 20000,
				file_type: $scope.selected[file_type_var]
			};

		$http({
			method           : 'GET',
			url              : '/api.php/dbs/' + databaseId + '/diff_tool/' + file_id,
			params           : params,
			// responseType: 'text',
			transformResponse: []
		})
			.then(response => {

                const textArea = document.createElement('textarea');
                textArea.innerHTML = response.data;
                const data = textArea.value;
				$scope.selected[contents_var] = data;
				$scope.selected[map_var].file = data;
				$scope.selected[map_var].type = pull_type;

				console.log('got ' + type + ' file contents');
				if ($scope.selected[file_type_var] === 'xml') {
					$scope.selected[map_var].file = $scope.selected[map_var].file.replace(/<!\[CDATA\[([\s\S]*?)\]\]>/g, '$1');
					$scope.selected[map_var].file = vkbeautify.xml(vkbeautify.xmlmin($scope.selected[map_var].file));

				}
			})
	};


	$scope.file_changed = function (destination) {
		/**
		 * Copy to another attribute to make the original immutable since they will be shared
		 * @type {*|boolean}
		 */

		var map_var              = destination + "_map",
			contents_var         = destination + "_contents";
		$scope.selected[map_var] = angular.copy($scope.selected[destination]);

		if ($scope.selected[destination].type === 'url') {
			$scope.selected[map_var] = angular.copy($scope.selected[destination]);
			$scope.selected[map_var].file = $scope.selected[contents_var];
			$scope.selected[map_var].type = $scope.selected[destination].type;
		}

		if($scope.selected[destination].type === 'local') {
			$scope.selected[map_var].file = $scope.selected[contents_var];
			$scope.selected[map_var].type = $scope.selected[destination].type;
		}
	};

	$scope.file_changed('source');
	$scope.file_changed('target');

	$scope.local_file_upload = function (element, whichab) {
		var name = whichab + '_map';
		$scope.selected[whichab].location = "local";
	};

	// like fetch file, only we have the file locally and are PUTTING it.
	$scope.putch_file = function (file_object, type) { // type here is source/dest
		var params = {
			type: 'local',
			url: 'local',
			suffix: type
		};
		$upload.upload({
			url: '/api.php/dbs/' + databaseId + '/diff_tool/fetch',
			method: 'POST',
			data: params,
			file: file_object[0]
		})
			.then(function (response) {
				var data = response.data;
				if (data.status === 'success') {
					$scope.selected[type + '_file'] = file_object[0]['name'];
					$scope.selected[type + '_fetch_disabled'] = true;
					$scope.selected[type + "_map"].file_id    = data.data.file_id;

					$scope.selected[type].file                = data.data.contents;


					get_file_contents(data.data.file_id, type, params['type']);
					ngToast.success("Fetch " + type + " file success!");
				} else {
					ngToast.danger("Fetch " + type + " file failed");
				}
			});


	};

	$scope.fetch_file = function (url, type) { // type here is source/dest
		var params = {
			type: 'url',
			url   : url,
			suffix: type
		};

		$http.post('/api.php/dbs/' + databaseId + '/diff_tool/fetch', params)
			.then(function (response) {
				var data = response.data;
				if (data.status === 'success') {
					$scope.selected[type + '_fetch_disabled'] = true;
					$scope.selected[type + "_map"].file_id    = data.data.file_id;
					$scope.selected[type].file                = data.data.contents;
					get_file_contents(data.data.file_id, type, params['type']);
					ngToast.success("Fetch " + type + " file success!");
				} else {
					ngToast.danger("Fetch " + type + " file failed");
				}

			})
	};
	$scope.change_file_location = function(whichab, file_location_option, is_new) {
		if(is_new) {
			whichab.timeout_hours = 0;
			whichab.timeout_seconds = 0;
			whichab.timeout_minutes = 15;
		}

	}
	$scope.update_url_from_params_list = function(whichab) {

		var http_protocol_missing =
			!whichab.import_info.url.startsWith('http://')
			&& !whichab.import_info.url.startsWith('https://');

		// updates the current url with any changes to the url parameters
		if (http_protocol_missing) {
			whichab.import_info.url = "http://" + whichab.import_info.url;
		}

		try{
			var url_to_search = new URL(whichab.import_info.url);

		} catch(err){
			whichab.import_info.url_params = [{'name':'', 'value':''}];
			return;
		}

		var modified_url = url_to_search.origin + url_to_search.pathname;
		var param_list = whichab.import_info.url_params;

		for (var param of param_list){
			let param_name = "", param_value = "";
			if (param.name != ""){
				param_name = encodeURIComponent(param.name);
			}
			if (param.value != ""){
				if (param.value.startsWith("{{")) {
					param_value = param.value;
				}
				else {
					param_value = encodeURIComponent(param.value);
				}
			}

			var query_param = param_name + "=" + param_value;
			modified_url = $scope.append_identifying_query_param(modified_url,query_param);
		}
		whichab.import_info.url = modified_url;
		return;
	}

	$scope.append_identifying_query_param = function(url, query_param) {
		$scope.parser = document.createElement('a');
		$scope.parser.href = url;

		var query_string = $scope.parser.search;
		var split = query_string.split('?');

		var has_question_mark = url.indexOf('?') !== -1;
		var has_query_string = split.length > 1;
		var has_identifying_query_param = url.indexOf(query_param) !== -1;

		// var current_query_string = current_query_string[0]==='?' ? current_query_string.substr(1) : current_query_string;

		// if no question mark or stuff after question mark is whitespace
		if (!has_question_mark) {
			url = url + '?' + query_param;
		}
		else if (has_question_mark && !has_query_string ) {
			url = url + query_param;
		}
		else if (has_question_mark && has_query_string && !has_identifying_query_param) {
			url = url + '&' + query_param;
		}
		else if (has_question_mark && has_query_string && has_identifying_query_param) {
			// nothing. leave as is.
		}
		else {
			// console.log('something went completely wrong with the URL');
		}

		return url;
	}
	$scope.primary_key_changed = function () {
		$scope.summary.fields = $scope.summary.selectable_fields.filter(field => {
			return field !== $scope.summary.primary_key;
		})
	};

	$scope.update_fields = function () {
		if (
			typeof $scope.selected.source_map.file_map !== "undefined"
			&& $scope.selected.source_map.file_map.maps
			&& typeof $scope.selected.target_map.file_map !== "undefined"
			&& $scope.selected.target_map.file_map.maps
		) {
			let source_fields;

			if ($scope.selected.source_file_type === 'delimited') {
				source_fields = Object.values($scope.selected.source_map.file_map.maps);
			} else {
				source_fields = $scope.selected.source_map.file_map.maps.filter(file_map => file_map.name).map(file_map => file_map.name);
			}

			let target_fields;

			if ($scope.selected.target_file_type === 'delimited') {
				target_fields = Object.values($scope.selected.target_map.file_map.maps);
			} else {
				target_fields = $scope.selected.target_map.file_map.maps.filter(file_map => file_map.name).map(file_map => file_map.name);
			}

			$scope.summary.selectable_fields = source_fields.filter(
                (field, index, array) => {
                    return target_fields.indexOf(field) !== -1 &&
                        // filter duplicates
                        array.indexOf(field) === index;
                }
            );

			$scope.source_fields = source_fields;
			$scope.target_fields = target_fields;
		}
	};

	$scope.submit_diff = function () {
        $scope.diffLoading = true;

        const summaryElement = document.getElementById('fdx-difftool-summaryOptionsScrollTarget');
        summaryElement.scrollIntoView();


		var source_map_copy = {

		};
		Object.assign(source_map_copy , $scope.selected.source_map);
		delete source_map_copy.auto_detect;
		delete source_map_copy.auto_detect_finished;
		delete source_map_copy.auto_detect_in_progress;
		delete source_map_copy.delimiters_initially_set;
		delete source_map_copy.file;
		delete source_map_copy.file_transpose;
		delete source_map_copy.group;
		delete source_map_copy.name_based_maps;
		delete source_map_copy.std;


		var target_map_copy = {

		};
		Object.assign(target_map_copy , $scope.selected.target_map);
		delete target_map_copy.auto_detect;
		delete target_map_copy.auto_detect_finished;
		delete target_map_copy.auto_detect_in_progress;
		delete target_map_copy.delimiters_initially_set;
		delete target_map_copy.file;
		delete target_map_copy.file_transpose;
		delete target_map_copy.group;
		delete target_map_copy.name_based_maps;
		delete target_map_copy.std;

		var params = {
			source_file_type     : $scope.selected.source_file_type,
			source_url           : $scope.selected.source_url,
			source_file			 : $scope.selected.source_file,
			source_map           : source_map_copy,
			target_file_type     : $scope.selected.target_file_type,
			target_url           : $scope.selected.target_url,
			target_file			 : $scope.selected.target_file,
			target_map           : target_map_copy,
			summary_fields       : $scope.summary.fields,
			primary_key          : $scope.summary.primary_key,
			download_join_file   : $scope.summary.download_join_file,
			download_full_results: $scope.summary.download_full_results
		};

		$http.post(
			'/api.php/dbs/' + databaseId + '/diff_tool',
			params
		)
		.then((response) => {
			const data = response.data;
			ngToast.success('Files diffed successfully');
			if (data.status === 'success') {
				$scope.results = data.data;
				$scope.details = data.data.details;
				$scope.attributes = data.data.summary.attributes;

                // $scope.columnStats = $scope.parseColumnStats($scope.results);

                if ($scope.results.download.join_file) {

					const join_file_iframe = document.createElement('iframe');
					join_file_iframe.style.display = 'none';
					document.body.appendChild(join_file_iframe);
					join_file_iframe.src = $scope.results.download.join_file;

				}

				if ($scope.results.download.full_results) {

					const full_results_iframe = document.createElement('iframe');
					full_results_iframe.style.display = 'none';
					document.body.appendChild(full_results_iframe);
					full_results_iframe.src = $scope.results.download.full_results;

				}

				$scope.column_diff_table = {
					source: $scope.source_fields.filter((field) => field !== $scope.summary.primary_key),
					target: $scope.target_fields.filter((field) => field !== $scope.summary.primary_key)
				};

				// limit filterOptions to available result types
				$scope.resultsFilterOptions = [
					{display_name: 'Created', value: 'created'},
					{display_name: 'Deleted', value: 'deleted'},
					{display_name: 'Value Modified', value: 'value_modified'},
					{display_name: 'Pop. Val to Null', value: 'val_to_null'},
					{display_name: 'Null to Pop. Val', value: 'null_to_val'}
				];
				// const uniqueStatus = [...new Set($scope.results.status_details.map(detail => detail.status_detail)), 'all'];
				$scope.resultsFilterOptions = $scope.resultsFilterOptions.filter((option) => $scope.results.summary.status_details.includes(option.value));
				$scope.resultsFilterOptions = [{display_name: 'All', value: 'all'}, ...$scope.resultsFilterOptions];

			} else {
				ngToast.danger('Failed diffing files');
			}

		}).finally(() => {
            $scope.diffLoading = false;
        })
	};


	/*
	 Since api.php passes around hex-encoded strings this
	 function decodes to "UTF8" for two-byte chars.
	 String.fromCharCode is intended for UTF16.
	 */
	$scope.hex2bin = function (hex) {
		var bytes = [];
		for (var i = 0; i < hex.length - 1; i += 2) {
			bytes.push(parseInt(hex.substr(i, 2), 16));
		}
		return String.fromCharCode.apply(String, bytes);
	}

	$scope.parseColumnStats = function (diffResults) {
		const allColumns = Object.keys(diffResults.summary.attributes);

		return diffResults.details.reduce((stats, row) => {
			let affectedColumns = [];

			switch (row.status) {
				case 'created':
				case 'deleted':
					affectedColumns = allColumns;
					break;
				case 'updated':
					affectedColumns = row.columns;
					break;
			}

			affectedColumns.forEach((column) => {
				if (stats[column] === undefined) {
					stats[column] = {
						created: 0,
						deleted: 0,
						updated: 0,
						total: 0
					};
				}

				stats[column][row.status]++;
				stats[column].total++;
			});

			return stats;
		}, {});
	}

}]);
