import fdxUI from '@ajs/services/fdxUI';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DbFieldModel } from '@app/databases/models/db-field.model';
import { AttributionOutputFieldDataModel } from '@app/feed-ai/models/attribution-info.model';
import AttributionDataService from '@app/feed-ai/services/attribution-data.service';
import { ModalService } from '@app/modules/modals/services/modal.service';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { Subject, finalize, switchMap, takeUntil } from 'rxjs';

@Component({
    selector: 'fdx-feed-ai-attribution-tab',
    templateUrl: './feed-ai-attribution-tab.component.html',
    styleUrls: ['./feed-ai-attribution-tab.component.scss']
})
export class FeedAiAttributionTabComponent implements OnChanges, OnDestroy, OnInit {
    @Input() databaseFields: DbFieldModel[] = [];
    @Input() databaseId: string;

    private readonly unsubscribe$: Subject<void> = new Subject<void>();

    attributes = [
        'age_group',
        'color',
        'gender',
        'material',
        'pattern',
        'product_dimension',
        'size'
    ];
    databaseFieldNames: string[] = [];
    iconPlus = faPlus;
    iconTrashAlt = faTrashAlt;
    form = new UntypedFormGroup({
        inputFields: new UntypedFormGroup({}),
        outputFields: new UntypedFormArray([])
    });
    inputFields: { control: UntypedFormControl; fieldName: string; formattedFieldName: string; }[] = [];
    outputFields: { attribute: UntypedFormControl; fieldName: UntypedFormControl; }[] = [];

    get inputFieldsForm(): UntypedFormGroup {
        return this.form.controls.inputFields as UntypedFormGroup;
    }

    get outputFieldsForm(): UntypedFormArray {
        return this.form.controls.outputFields as UntypedFormArray;
    }

    constructor(
        private attributionDataService: AttributionDataService,
        private fdxUI: fdxUI,
        private modalService: ModalService
    ) { }

    ngOnChanges({ databaseFields }: SimpleChanges): void {
        if (databaseFields.currentValue !== databaseFields.previousValue) {
            this.databaseFieldNames = databaseFields.currentValue
                .map(({ field_name }) => {
                    return field_name;
                })
                .sort();
        }
    }

    ngOnInit(): void {
        this.fdxUI.startBlockUI();

        this.attributionDataService.getByDbId(this.databaseId)
            .pipe(
                finalize(() => {
                    this.fdxUI.stopBlockUI();
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(({ data }) => {
                    const { input_fields, output_fields } = data;

                    this.initInputFieldsForm(input_fields);
                    this.initOutputFieldsForm(output_fields);
                },
                ({ error }: HttpErrorResponse) => {
                    this.fdxUI.showToastError(error.message);
                }
            );
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    addAttribute(): void {
        this.outputFieldsForm.push(new UntypedFormGroup({
            attribute: new UntypedFormControl(null),
            fieldName: new UntypedFormControl(null)
        }));

        this.outputFields = this.outputFieldsForm.controls.map((formGroup: UntypedFormGroup) => {
            return {
                attribute: formGroup.controls.attribute as UntypedFormControl,
                fieldName: formGroup.controls.fieldName as UntypedFormControl
            };
        });
    }

    clearCache(): void {
        this.modalService.showDangerConfirmationModal(
            'Confirm Clear Cache',
            'Are you sure you want to clear the attribution cache?',
            'Confirm'
        )
            .closed
            .pipe(
                switchMap(() => {
                    this.fdxUI.startBlockUI();

                    return this.attributionDataService.clearCache(this.databaseId);
                }),
                finalize(() => {
                    this.fdxUI.stopBlockUI();
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => {
                    this.fdxUI.showToastSuccess('The attribution cache has been cleared');
                },
                (error: HttpErrorResponse) => {
                    this.fdxUI.showToastError(error.error || 'System error while clearing attribution cache');
                }
            );
    }

    formatFieldName(fieldName: string): string {
        let name = fieldName + '';

        if (name === 'product_dimension') {
            name = 'product_dimensions';
        }

        return name.replace(/\_/ig, ' ');
    }

    initInputFields(): void {
        this.inputFields = Object.entries(this.inputFieldsForm.controls).map(([fieldName, control]) => {
            return {
                control: control as UntypedFormControl,
                fieldName,
                formattedFieldName: this.formatFieldName(fieldName)
            };
        });
    }

    initInputFieldsForm(inputFields: Record<string, string>): void {
        Object.entries(inputFields).forEach(([fieldName, value]) => {
            this.inputFieldsForm.addControl(fieldName, new UntypedFormControl(value || null));
        });

        this.initInputFields();
    }

    initOutputFields(): void {
        this.outputFields = this.outputFieldsForm.controls.map((formGroup: UntypedFormGroup) => {
            return {
                attribute: formGroup.controls.attribute as UntypedFormControl,
                fieldName: formGroup.controls.fieldName as UntypedFormControl
            };
        });
    }

    initOutputFieldsForm(outputFields: AttributionOutputFieldDataModel[]): void {
        outputFields.forEach(({ attribute, field_name }) => {
            this.outputFieldsForm.push(new UntypedFormGroup({
                attribute: new UntypedFormControl(attribute || null),
                fieldName: new UntypedFormControl(field_name || null)
            }));
        });

        this.initOutputFields();
    }

    removeAttribute(index: number): void {
        this.modalService
            .showDangerConfirmationModal(
                'Confirm Remove Attribute',
                'Are you sure you want to remove this attribute?',
                'Confirm'
            )
            .closed
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.outputFieldsForm.removeAt(index);
                this.initOutputFields();
            });
    }

    save(): void {
        this.fdxUI.startBlockUI();

        this.attributionDataService.update(this.databaseId, {
            data: {
                input_fields: this.inputFields.reduce((allFields, { control, fieldName }) => {
                    allFields[fieldName] = control.value || '';
                    return allFields;
                }, {}),
                output_fields: this.outputFields.map(({ attribute, fieldName }) => {
                    return {
                        attribute: attribute.value,
                        field_name: fieldName.value
                    };
                })
            }
        })
            .pipe(
                finalize(() => {
                    this.fdxUI.stopBlockUI();
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => {
                    this.fdxUI.showToastSuccess('The attribution has been updated');
                },
                (error: HttpErrorResponse) => {
                    this.fdxUI.showToastError(error.error);

                }
            );
    }
}
