import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgModule } from '@angular/core';
import { NgbOffcanvasModule } from '@ng-bootstrap/ng-bootstrap';

// Components
import { OffcanvasHocComponent } from './components/offcanvas-hoc/offcanvas-hoc.component';

// Exports
export { Offcanvas } from './interfaces/offcanvas.interface';
export { OffcanvasOptions } from './interfaces/offcanvas-options.interface';

@NgModule({
    declarations: [
        OffcanvasHocComponent
    ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        NgbOffcanvasModule
    ],
    exports: [
        OffcanvasHocComponent
    ]
})
export class OffcanvasModule {
}
