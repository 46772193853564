import { BootstrapThemeProp } from '@app/core/services/bootstrap-theme.service';

export default class FdxModalHocController {

	public modalTheme: BootstrapThemeProp = 'primary';
	public modalTitle: string = 'Title';

	public submitButton: boolean = true;
	public secondaryButton: boolean = false;
	public cancelButton: boolean = true;

	public submitText: string = 'Submit';
	public secondaryText: string = 'Share / Copy';
	public cancelText: string = 'Cancel';

	public submitDisabled: boolean = false;
	public secondaryDisabled: boolean = false;
	public cancelDisabled: boolean = false;

	private onSubmit: () => void;
	private onSecondary: () => void;
	private onCancel: () => void;

	static $inject = [];

	constructor() { }

	public get modalHeaderClasses(): Array<string> {
		if (this.modalTheme === 'danger') {
			return ['fdx-header-bg-danger'];
		}

		return [];
	}

	public get submitButtonClasses(): Array<string> {
		if (this.modalTheme === 'danger') {
			return ['btn-danger'];
		}

		return ['btn-primary'];
	}

	public get showModalFooter(): boolean {
		return this.submitButton || this.secondaryButton || this.cancelButton;
	}

	public submit(): void {
		if (typeof (this.onSubmit) === 'function') {
			this.onSubmit();
		}
	}

	public secondary(): void {
		if (typeof (this.onSecondary) === 'function') {
			this.onSecondary();
		}
	}

	public cancel(): void {
		if (typeof (this.onCancel) === 'function') {
			this.onCancel()
		}
	}
}
