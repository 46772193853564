class FdxDisablePasteDirective {
    constructor() {
        this.restrict = 'A';
    }

    link($scope, elm) {
        elm[0].addEventListener('paste', e => e.preventDefault());
    }

    static directiveFactory() {
        return new FdxDisablePasteDirective();
    }
}

FdxDisablePasteDirective.$inject = [];

export default FdxDisablePasteDirective.directiveFactory;