import { IHttpService } from 'angular';

import fdxUI from '../../../../services/fdxUI';
import LogService from '../../../../services/LogService';

interface ResolverInterface {
	databaseId: number | string,
	transformerIds: Array<string | number>,
	transformerCount: number,
	displayName: string
}

export default class FdxTransformersDeleteModalController {

	// from uibmodal
	private close: (result?: { $value }) => void;
	private dismiss: (result?: { $value: string }) => void;
	private resolve: ResolverInterface;

	public transformerCount: number | string = '';
	public displayName: string = '';

	private databaseId: number | string = null;
	private transformerIds: Array<string | number> = null;

	static $inject = [
		'$http',
		'fdxUI',
		'LogService'
	];

	constructor(
		private $http: IHttpService,
		private fdxUI: fdxUI,
		private LogService: LogService
	) { }

	$onInit() {
		this.databaseId = this.resolve.databaseId;
		this.transformerCount = this.resolve.transformerCount;
		this.displayName = this.resolve.displayName;
	}

	public onSubmit(): void {
		this.fdxUI.startBlockUI('Deleting...');

		this.$http
			.delete(
				`/api.php/dbs/${this.databaseId}/transformers/${this.displayName}`
			)
			.then(
				() => {
					this.fdxUI.showToastSuccess('Transformers have been deleted');
					this.close();
				},
				(error: any) => {
					this.fdxUI.showToastError('Unable to delete transformers');
					this.LogService.error('FdxTransformersDeleteModalController', 'onSubmit', error);
				}
			)
			.finally(() => this.fdxUI.stopBlockUI());
	}

	public onCancel(): void {
		this.dismiss();
	}
}
