import * as angular from 'angular';

import { MODULE_NAME } from './config/constants';

// NPM AngularJS modules
import * as angularUIBootstrapModuleName from 'angular-ui-bootstrap';

angular.module(
	MODULE_NAME,
	[
		angularUIBootstrapModuleName
	]
);

export default MODULE_NAME;
