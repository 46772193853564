import { MODULE_NAME } from '@ajs/config/constants';
import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';

import { AppComponent } from '@app/app.component';
import { WellFooterComponent } from '@app/core-legacy/components/well-footer/well-footer.component';
import { WellHeaderComponent } from '@app/core-legacy/components/well-header/well-header.component';
import { WellComponent } from '@app/core-legacy/components/well/well.component';
import { FdxUtilsService } from '@app/core/services/fdx-utils.service';
import { ErrorOverviewPageComponent } from '@app/error-overview/pages/error-overview-page/error-overview-page.component';
import { ExportDeduplicateFieldsComponent } from '@app/exports/components/export-deduplicate-fields/export-deduplicate-fields.component';
import { ExportFieldsComponent } from '@app/exports/components/export-fields/export-fields.component';
import { ExportsPageComponent } from '@app/exports/pages/exports-page/exports-page.component';
import { AttributorPageComponent } from '@app/feed-ai/pages/attributor-page/attributor-page.component';
import { ClassifierPageComponent } from '@app/feed-ai/pages/classifier-page/classifier-page.component';
import { FeedAiPageComponent } from '@app/feed-ai/pages/feed-ai-page/feed-ai-page.component';
import { EffectiveUserSelectComponent } from '@app/feed-support/components/effective-user-select/effective-user-select.component';
import { FeedtelligencePageComponent } from '@app/feedtelligence/pages/feedtelligence-page/feedtelligence-page.component';
import { AlertComponent } from '@app/modules/alerts/components/alert/alert.component';
import { BasicTransformerFieldComponent } from '@app/modules/inputs/components/basic-transformer-field/basic-transformer-field.component';
import { BasicTransformerComponent } from '@app/modules/inputs/components/basic-transformer/basic-transformer.component';
import { CodeInputComponent } from '@app/modules/inputs/components/code-input/code-input.component';
import { CollapsibleCodeComponent } from '@app/modules/inputs/components/collapsible-code/collapsible-code.component';
import { ExportSelectComponent } from '@app/modules/inputs/components/export-select/export-select.component';
import { FormFieldComponent } from '@app/modules/inputs/components/form-field/form-field.component';
import { InputWrapperComponent } from '@app/modules/inputs/components/input-wrapper/input-wrapper.component';
import { BreadcrumbsComponent } from '@app/modules/nav/components/breadcrumbs/breadcrumbs.component';
import { SelectMultipleLabelTemplateComponent } from '@app/modules/ng-select-templates/components/select-multiple-label-template/select-multiple-label-template.component';
import { PageTitleComponent } from '@app/modules/page/components/page-title/page-title.component';
import { PaginationComponent } from '@app/modules/pagination/components/pagination/pagination.component';
import { PopoverTitleComponent } from '@app/modules/popovers/components/popover-title/popover-title.component';
import { AdvancedQueryComponent } from '@app/modules/query/components/advanced-query/advanced-query.component';
import { QueryButtonsComponent } from '@app/modules/query/components/query-buttons/query-buttons.component';
import { QueryInputsComponent } from '@app/modules/query/components/query-inputs/query-inputs.component';
import { QueryTypeButtonsComponent } from '@app/modules/query/components/query-type-buttons/query-type-buttons.component';
import { ReportsSkuPageComponent } from '@app/reports-sku/pages/reports-sku/reports-sku-page.component';
import { Bs3ContainerComponent } from '@app/standalone-components/bs3-container/bs3-container.component';
import { SpinnerComponent } from '@app/standalone-components/spinner/spinner.component';
import { StickyHeaderComponent } from '@app/standalone-components/sticky-header/sticky-header.component';
import { StyleWrapperComponent } from '@app/standalone-components/style-wrapper/style-wrapper.component';
import { TransformersBulkImportPageComponent } from '@app/transformers/pages/transformers-bulk-import-page/transformers-bulk-import-page.component';
import { TransformersFieldOrderPageComponent } from '@app/transformers/pages/transformers-field-order-page/transformers-field-order-page.component';
import { TransformersPageComponent } from '@app/transformers/pages/transformers-page/transformers-page.component';
import { EmptyStateComponent } from '@feedonomics/frontend-components';
import * as angular from 'angular';

angular
    .module(MODULE_NAME)
    .directive(
        'fdxRoot',
        downgradeComponent({ component: AppComponent }) as angular.IDirectiveFactory
    );

const components = [
    {
        directive: 'fdxStyleWrapper',
        component: StyleWrapperComponent
    },
    {
        directive: 'fdxFeedAiPage',
        component: FeedAiPageComponent
    },
    {
        directive: 'fdxAttributorPage',
        component: AttributorPageComponent
    },
    {
        directive: 'fdxExportsPage',
        component: ExportsPageComponent
    },
    {
        directive: 'fdxClassifierPage',
        component: ClassifierPageComponent
    },
    {
        directive: 'fdxFeedtelligencePage',
        component: FeedtelligencePageComponent
    },
    {
        directive: 'fdxBs3Container',
        component: Bs3ContainerComponent
    },
    {
        directive: 'fdxTransformersBulkImportPage',
        component: TransformersBulkImportPageComponent
    },
    {
        directive: 'fdxTransformersFieldOrderPage',
        component: TransformersFieldOrderPageComponent
    },
    {
        directive: 'fdxTransformersPage',
        component: TransformersPageComponent
    },
    {
        directive: 'fdxReportsSkuPage',
        component: ReportsSkuPageComponent
    },
    {
        directive: 'fdxErrorOverviewPage',
        component: ErrorOverviewPageComponent
    }
];

components.forEach(({ directive, component }) => {
    angular.module(MODULE_NAME).directive(directive, downgradeComponent({ component }) as angular.IDirectiveFactory);
});

// non-page components

// TODO: Remove AdvancedQueryComponent from here after deleting legacy data page
angular.module(MODULE_NAME).directive('fdxAdvancedQuery', downgradeComponent({
    component: AdvancedQueryComponent,
    inputs: [],
    outputs: []
}) as angular.IDirectiveFactory);

angular.module(MODULE_NAME).directive('fdxBasicTransformer', downgradeComponent({
    component: BasicTransformerComponent,
    inputs: [],
    outputs: []
}) as angular.IDirectiveFactory);

angular.module(MODULE_NAME).directive('fdxBasicTransformerFieldComponent', downgradeComponent({
    component: BasicTransformerFieldComponent,
    inputs: [],
    outputs: []
}) as angular.IDirectiveFactory);

angular.module(MODULE_NAME).directive('fdxCodeInput', downgradeComponent({
    component: CodeInputComponent,
    inputs: [],
    outputs: []
}) as angular.IDirectiveFactory);

angular.module(MODULE_NAME).directive('fdxCollapsibleCode', downgradeComponent({
    component: CollapsibleCodeComponent,
    inputs: [],
    outputs: []
}) as angular.IDirectiveFactory);

// TODO: Remove QueryButtonsComponent from here after deleting legacy data page
angular.module(MODULE_NAME).directive('fdxQueryButtons', downgradeComponent({
    component: QueryButtonsComponent,
    inputs: [],
    outputs: []
}) as angular.IDirectiveFactory);

angular.module(MODULE_NAME).directive('fdxPageTitle', downgradeComponent({
    component: PageTitleComponent,
    inputs: [],
    outputs: []
}) as angular.IDirectiveFactory);

// TODO: Remove QueryInputsComponent from here after deleting legacy data page
angular.module(MODULE_NAME).directive('fdxQueryInputs', downgradeComponent({
    component: QueryInputsComponent,
    inputs: [],
    outputs: []
}) as angular.IDirectiveFactory);

// TODO: Remove QueryTypeButtonsComponent from here after deleting legacy data page
angular.module(MODULE_NAME).directive('fdxQueryTypeButtons', downgradeComponent({
    component: QueryTypeButtonsComponent,
    inputs: [],
    outputs: []
}) as angular.IDirectiveFactory);

angular.module(MODULE_NAME).directive('fdxSelectMultipleLabelTemplate', downgradeComponent({
    component: SelectMultipleLabelTemplateComponent,
    inputs: [],
    outputs: []
}) as angular.IDirectiveFactory);

angular.module(MODULE_NAME).directive('fdxSpinner', downgradeComponent({
    component: SpinnerComponent,
    inputs: [],
    outputs: []
}) as angular.IDirectiveFactory);

angular.module(MODULE_NAME).directive('fdxAlert', downgradeComponent({
    component: AlertComponent,
    inputs: [],
    outputs: []
}) as angular.IDirectiveFactory);

angular.module(MODULE_NAME).directive('fdxStickyHeader', downgradeComponent({
    component: StickyHeaderComponent,
    inputs: [],
    outputs: []
}) as angular.IDirectiveFactory);

angular.module(MODULE_NAME).directive('fdxPagination', downgradeComponent({
    component: PaginationComponent,
    inputs: ['pageCount', 'pageSize', 'page', 'size'],
    outputs: ['selectPage']
}) as angular.IDirectiveFactory);

angular.module(MODULE_NAME).directive('fdxPopoverTitle', downgradeComponent({
    component: PopoverTitleComponent,
    inputs: [],
    outputs: []
}) as angular.IDirectiveFactory);

angular.module(MODULE_NAME).directive('fdxEffectiveUserSelect', downgradeComponent({
    component: EffectiveUserSelectComponent,
    inputs: [],
    outputs: []
}) as angular.IDirectiveFactory);

angular.module(MODULE_NAME).directive('fdxExportDeduplicateFields', downgradeComponent({
    component: ExportDeduplicateFieldsComponent,
    inputs: [],
    outputs: []
}) as angular.IDirectiveFactory);

angular.module(MODULE_NAME).directive('fdxExportFields', downgradeComponent({
    component: ExportFieldsComponent,
    inputs: [],
    outputs: []
}) as angular.IDirectiveFactory);

angular.module(MODULE_NAME).directive('fdxExportSelect', downgradeComponent({
    component: ExportSelectComponent,
    inputs: [],
    outputs: []
}) as angular.IDirectiveFactory);

angular.module(MODULE_NAME).directive('fdxFormField', downgradeComponent({
    component: FormFieldComponent,
    inputs: [],
    outputs: []
}) as angular.IDirectiveFactory);

angular.module(MODULE_NAME).directive('fdxInputWrapper', downgradeComponent({
    component: InputWrapperComponent,
    inputs: [],
    outputs: []
}) as angular.IDirectiveFactory);

angular.module(MODULE_NAME).directive('fscEmptyState', downgradeComponent({
    component: EmptyStateComponent,
    inputs: [],
    outputs: []
}) as angular.IDirectiveFactory);

angular.module(MODULE_NAME).directive('fdxWell', downgradeComponent({
    component: WellComponent,
    inputs: [],
    outputs: []
}) as angular.IDirectiveFactory);

angular.module(MODULE_NAME).directive('fdxWellHeader', downgradeComponent({
    component: WellHeaderComponent,
    inputs: [],
    outputs: []
}) as angular.IDirectiveFactory);

angular.module(MODULE_NAME).directive('fdxWellFooter', downgradeComponent({
    component: WellFooterComponent,
    inputs: [],
    outputs: []
}) as angular.IDirectiveFactory);

angular.module(MODULE_NAME).directive('fdxBreadcrumbs', downgradeComponent({
    component: BreadcrumbsComponent
}) as angular.IDirectiveFactory);

angular.module(MODULE_NAME).factory('fdxUtilsService', downgradeInjectable(FdxUtilsService));
