import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AlertsModule } from '@app/modules/alerts/alerts.module';
import { AppEbayMenuComponent } from '@app/modules/nav/components/app-ebay-menu/app-ebay-menu.component';
import { AppMainMenuComponent } from '@app/modules/nav/components/app-main-menu/app-main-menu.component';
import { BreadcrumbsComponent } from '@app/modules/nav/components/breadcrumbs/breadcrumbs.component';
import { UserInitialsComponent } from '@app/modules/nav/components/user-initials/user-initials.component';
import { PipesModule } from '@app/modules/pipes/pipes.module';
import { DropdownsModule } from '@feedonomics/frontend-components';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbDropdownModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [
        AppEbayMenuComponent,
        AppMainMenuComponent,
        BreadcrumbsComponent,
        UserInitialsComponent
    ],
    exports: [
        AppEbayMenuComponent,
        AppMainMenuComponent,
        BreadcrumbsComponent,
        UserInitialsComponent
    ],
    imports: [
        AlertsModule,
        CommonModule,
        DropdownsModule,
        FontAwesomeModule,
        NgbDropdownModule,
        NgbNavModule,
        PipesModule,
        RouterModule
    ]
})
export class NavModule { }
