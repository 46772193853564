import * as angular from 'angular';
import FdxTimeZoneSelectController from './fdx-time-zone-select.controller';
import { MODULE_NAME } from '../../../config/constants';

export const componentName = 'fdxTimeZoneSelect';

angular.module(MODULE_NAME).component(componentName, {
    controller: FdxTimeZoneSelectController,
    template: require('./fdx-time-zone-select.template.html'),
    bindings: {
        'timeZone': '='
    }
});