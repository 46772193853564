import { AfterViewInit, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { BaseComponent } from '@app/core-legacy/abstract/base.component';
import { escapeSingleQuotes } from '@app/core/functions/escapeSingleQuotes';
import { isEmpty } from '@app/core/functions/isEmpty';
import { FdxUtilsService } from '@app/core/services/fdx-utils.service';
import { BasicQueryRow } from '@app/modules/inputs/classes/basic-query-row.class';
import { OperatorConstants } from '@app/modules/inputs/constants/operator.constants';
import { BasicQueryUtilities } from '@app/modules/inputs/services/basic-query-utilities.service';
import { AggregatedDbFieldsType } from '@app/modules/inputs/types/aggregated-db-fields.type';
import { BasicQueryData } from '@app/modules/inputs/types/basic-query-data.type';
import { IconDefinition, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { takeUntil } from 'rxjs';

@Component({
    selector: 'fdx-basic-query',
    templateUrl: './basic-query.component.html',
    styleUrls: ['./basic-query.component.scss']
})
export class BasicQueryComponent extends BaseComponent implements AfterViewInit, OnChanges {

    @Input() queryForm: UntypedFormGroup;

    @Input() queryString: string;

    @Input() disabled?: boolean = false;

    @Input() basicQueryData: BasicQueryData;

    @Input() dbFields: AggregatedDbFieldsType;

    formReady: boolean = false;

    totalInnerGroups: number = 0;

    iconPlus: IconDefinition = faPlus;

    get shouldShowSpinner(): boolean {
        return !this.formReady || this.basicQueryData?.loading;
    }

    constructor(
        private basicQueryUtilities: BasicQueryUtilities,
        private fdxUtils: FdxUtilsService
    ) {
        super();
    }

    ngAfterViewInit(): void {
        this.queryForm.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe({
            next: () => {
                this.checkAndSetRequiredValidators();
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        // Setup form as long as it wasn't previously setup, or if it has been setup, ensure there are no errors (no reason to setup while typing in advanced mode)
        if (changes.basicQueryData && this.basicQueryData &&
            (changes.basicQueryData.previousValue === undefined || (!this.basicQueryData.error && !this.basicQueryData.tooComplex))) {
            this.setupForm();
        }

        if (changes.disabled && this.formReady) {
            Object.values(this.queryForm.controls).forEach((inner: UntypedFormGroup) => {
                Object.values(inner.controls).forEach((row: UntypedFormGroup) => {
                    Object.values(row.controls).forEach((control: UntypedFormControl) => {
                        if (this.disabled) {
                            control.disable();
                        } else {
                            control.enable();
                        }
                    });
                });
            });
        }
    }

    setupForm(): void {
        this.formReady = false;

        this.totalInnerGroups = this.basicQueryData.selector.length;

        Object.keys(this.queryForm.controls).forEach((groupName: string) => {
            this.queryForm.removeControl(groupName);
        });

        this.basicQueryData.selector.forEach((innerData: BasicQueryRow[], index: number) => {
            this.queryForm.addControl(
                String(index),
                this.getInnerFormGroup(String(index), innerData)
            );
        });

        this.formReady = true;
    }

    checkAndSetRequiredValidators(): void {
        let validators: ValidatorFn[] = [Validators.required];
        const innerKeyArray = Object.keys(this.queryForm.value);
        innerKeyArray.forEach((innerKey: string) => {
            const rowKeyArray = Object.keys(this.queryForm.value[innerKey]);
            rowKeyArray.forEach((rowKey: string) => {
                let emptyRowFields: number = 0;
                Object.values(this.queryForm.value[innerKey][rowKey]).forEach((fieldValue: string) => {
                    if (isEmpty(fieldValue)) {
                        emptyRowFields++;
                    }
                });
                if (innerKeyArray.length === 1 && rowKeyArray.length === 1 && emptyRowFields === 3 && !this.basicQueryData.transformerMode) {
                    validators = [];
                }
            });
        });

        Object.values(this.queryForm.controls).forEach((innerControls: UntypedFormGroup) => {
            Object.values(innerControls.controls).forEach((rowControls: UntypedFormGroup) => {
                Object.values(rowControls.controls).forEach((control: UntypedFormControl, controlIndex: number) => {
                    if (controlIndex !== 2) {
                        control.setValidators(validators);
                        control.updateValueAndValidity({emitEvent: false});
                    }
                });
            });
        });
    }

    valuesToQuery(): string {
        const innersToRemove: string[] = [];

        // First loop to determine if we can remove any fields
        Object.keys(this.queryForm.value).forEach((innerKey: string, innerKeyIndex: number) => {
            const rowsToRemove: {inner: string, row: string}[] = [];
            const rowKeyArray = Object.keys(this.queryForm.value[innerKey]);
            rowKeyArray.forEach((rowKey: string, rowKeyIndex: number) => {
                if (innerKeyIndex > 0 || rowKeyIndex > 0) {   // Not first row in first inner group
                    let emptyRowFields: number = 0;
                    let hideValue: boolean = false;
                    let multiple: boolean = false;
                    Object.values(this.queryForm.value[innerKey][rowKey]).forEach((fieldValue: string | string[], fieldIndex: number) => {
                        if (fieldIndex === 1 && OperatorConstants.allOperators[fieldValue as string]?.multiple) {
                            multiple = true;
                        }

                        if (!multiple) {
                            if (isEmpty(fieldValue as string)) {
                                emptyRowFields++;
                            } else if (fieldIndex === 1 && OperatorConstants.allOperators[fieldValue as string]?.hideValue) {
                                hideValue = true;
                            }
                        }
                    });

                    /**
                     * Any rows with a multiple operator will not be removed
                     * Any rows with 3 empty fields OR hideValue fields with 2 empty rows will be removed
                     */
                    if (!multiple && (emptyRowFields === 3 || (hideValue && emptyRowFields === 2))) {
                        rowsToRemove.push({inner: innerKey, row: rowKey});
                    }
                }
            });
            if (rowsToRemove.length === rowKeyArray.length) {
                innersToRemove.push(innerKey);  // Remove the whole inner if every row is empty
            } else {
                rowsToRemove.forEach((remove: {inner: string, row: string}) => {
                    this.removeRowClick(remove.inner, remove.row, false);   // Or remove the individual empty rows
                });
            }
        });

        innersToRemove.forEach((inner: string) => {
            this.removeInnerClick(inner, false);
        });

        this.checkAndSetRequiredValidators();

        // Second loop to actually build the query string with the new values
        let newQueryString: string = '';

        Object.values(this.queryForm.value).forEach((innerValue, innerIndex: number, innerArray) => {
            const orNeeded: boolean = innerArray.length > 1;

            Object.values(innerValue).forEach((rowValue, rowIndex: number, rowArray) => {
                const andNeeded: boolean = rowArray.length > 1;

                if (andNeeded && orNeeded && rowIndex === 0) {
                    newQueryString += '(';
                }

                const rowValues: Array<string | string[]> = Object.values(rowValue);
                const multiple: boolean = OperatorConstants.allOperators[rowValues[1] as string]?.multiple;
                const hideValue: boolean = OperatorConstants.allOperators[rowValues[1] as string]?.hideValue;

                const fieldDefined: boolean = !isEmpty(rowValues[0]);
                const operatorDefined: boolean = !isEmpty(rowValues[1]);
                if (hideValue) {
                    rowValues[2] = '';
                } else {
                    let value: string | string[] = rowValues[2];
                    if (multiple) {     // Convert each value if multiple type operator
                        const convertedValueArray: string[] = (value as string[]).map((val: string) => this.convertValue(val, fieldDefined, operatorDefined));
                        value = convertedValueArray.join(', ');
                    } else {
                        value = this.convertValue(value as string, fieldDefined, operatorDefined);
                    }
                    rowValues[2] = value;
                }

                if (multiple) {
                    newQueryString += `${rowValues[0]} ${rowValues[1]} (${rowValues[2]})`;   // Multiple type operators wrap values in parenthesis like a function
                } else {
                    newQueryString += rowValues.join(' ').trim();
                }

                if (andNeeded) {
                    if (rowIndex < rowArray.length - 1) {
                        newQueryString += ' AND ';
                    } else if (orNeeded && rowIndex === rowArray.length - 1) {
                        newQueryString += ')';
                    }
                }
            });
            if (orNeeded && innerIndex < innerArray.length - 1) {
                newQueryString += ' OR '
            }
        });

        this.queryString = newQueryString;
        return this.queryString;
    }

    convertValue(value: string, fieldDefined: boolean, operatorDefined: boolean): string {
        if (value && !this.fdxUtils.isField(value)) {                                   // If value is not a field
            value = escapeSingleQuotes(value);                                           // re-escape apostraphes
            value = `'${value}'`;                                                       // add single quotes around string
        } else if (isEmpty(value) && fieldDefined && operatorDefined) {   // If field and operator is defined and value is not
            value = '\'\'';                                                             // make value empty quotes
        }
        return value;
    }

    getRowFormGroup(group: string, row: string, rowData: BasicQueryRow = new BasicQueryRow()): UntypedFormGroup {
        const multiple: boolean = OperatorConstants.allOperators[rowData.operator]?.multiple;
        let value: string | string[];
        if (multiple) {
            value = (rowData.value as string[]).map((val: string) => val.replace(/\\'/g, '\''));
        } else {
            value = !isEmpty(rowData.value as string) ? (rowData.value as string).replace(/\\'/g, '\'') : null;
        }
        return new UntypedFormGroup({
            [`${this.basicQueryUtilities.getFieldPrefix(group, row)}field`]: new UntypedFormControl({value: rowData.field, disabled: this.disabled}),
            [`${this.basicQueryUtilities.getFieldPrefix(group, row)}operator`]: new UntypedFormControl({value: rowData.operator, disabled: this.disabled}),
            [`${this.basicQueryUtilities.getFieldPrefix(group, row)}value`]: new UntypedFormControl({value: value, disabled: this.disabled})
        });
    }

    getInnerFormGroup(group: string, groupData: BasicQueryRow[] = [new BasicQueryRow()]): UntypedFormGroup {
        const formGroup = new UntypedFormGroup({});

        groupData.forEach((rowData: BasicQueryRow, row: number) => {
            formGroup.addControl(
                String(row),
                this.getRowFormGroup(group, String(row), rowData)
            );
        });

        return formGroup;
    }

    removeInnerClick(inner: string, emit: boolean = true): void {
        this.queryForm.removeControl(inner, {emitEvent: emit});
    }

    removeRowClick(inner: string, row: string, emit: boolean = true): void {
        (this.queryForm.controls[inner] as UntypedFormGroup).removeControl(row, {emitEvent: emit});
    }

    addNewInnerGroup(): void {
        this.queryForm.addControl(
            String(this.totalInnerGroups),
            this.getInnerFormGroup(String(this.totalInnerGroups))
        );
        this.totalInnerGroups++;
    }

    addNewRowGroup(innerGroupName: string): void {
        const innerForm: UntypedFormGroup = this.queryForm.controls[innerGroupName] as UntypedFormGroup;
        const newRow: string = String(+Object.keys(innerForm.controls)[Object.keys(innerForm.controls).length - 1] + 1);
        innerForm.addControl(
            newRow,
            this.getRowFormGroup(innerGroupName, newRow)
        );
    }

    controlAsFormGroup(innerGroup: AbstractControl): UntypedFormGroup {
        return innerGroup as UntypedFormGroup;
    }
}
