blockUIConfig.$inject = ['blockUIConfig'];

export default function blockUIConfig(blockUIConfig) {

    blockUIConfig.autoBlock = false;
    blockUIConfig.autoInjectBodyBlock = false;
    blockUIConfig.delay = 50;
    blockUIConfig.message = 'Processing...';
    blockUIConfig.template = require('./blockUI.template.html');

}
