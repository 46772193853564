<div class="container p-4">
    <fdx-page-title>{{title}}</fdx-page-title>

    <div class="card">
        <div class="list-group">
            <div class="list-group-item header">
                <div class="row">
                    <div class="col-3">
                        Keyboard Combinations
                    </div>
                    <div class="col-6">
                        Actions
                    </div>
                </div>
            </div>

            <div *ngFor="let keyboard_shortcut of getShortcuts(); trackBy: trackByKeyboardShortcut" class="list-group-item hover-effect">
                <div class="row">
                    <div class="col-3">
                        {{keyboard_shortcut.combination}}
                    </div>
                    <div class="col-6">
                        {{keyboard_shortcut.description}}
                    </div>
                </div>
            </div>

            <div class="list-group-item footer">
                <div class="row">
                    <div class="col">
                        Add Shift to any shortcut to open the page in a new browser tab
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
