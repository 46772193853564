export default class TitleService {

    // Injections
    $rootScope = null
    AppStateService = null

    // Local properties
    titleElement = null;
    title = null;

    constructor($rootScope, AppStateService) {

        this.$rootScope = $rootScope;
        this.AppStateService = AppStateService;

        this.titleElement = angular.element("title");

        this.setUpWatchers();

    }

    setTitle(title) {
        this.title = title;
        this._setTitle();
    }

    setUpWatchers() {

        // If the database changes, we need to update the title.
        this.$rootScope.$watch(() => {
            return this.AppStateService.getDatabase()
        }, () => {
            this._setTitle();
        });

    }

    _setTitle() {

        const databaseName = this.AppStateService.getDatabaseName();

        let title = this.title;
        if (databaseName) {
            if (title) {
                title += ' - ';
            }

            title += databaseName;
        }

        if (!title) {
            title = 'Feedonomics';
        }

        this.titleElement.text(title);

    }

}

TitleService.$inject = ['$rootScope', 'AppStateService'];
