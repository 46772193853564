import * as angular from 'angular';
import * as moment from 'moment';
import { MODULE_NAME } from '../../config/constants';

angular.module(MODULE_NAME).directive('feedwizardKenshoo', ['$http', '$location', '$q', '$timeout', 'AppStateService', 'ngToast', function($http, $location, $q, $timeout, AppStateService, ngToast) {
	return {
		restrict: 'E',
		scope: true,
		template: require('./kenshoo.html'),
		link: function ($scope) {
			const account_id = AppStateService.getAccountId();
			$scope.step = 1;

			$scope.previous_step = function () {
				$scope.step--;
			};

			$scope.next_step = function () {
				$scope.step++;
			};

			$scope.data = {
				'step1': {
					'client_name': 'HM',
					'ibc_name': 'Popcorn_UK'
				},
				'step2': {
					'import_name': '',
					'import_type': 'Product Feed',
					'file_location': 'FTP',
					'file_type': 'delimited',
					'ftp_protocol': 'FTP',
					'file_name': 'popcorn_ibc_feed.csv',
					'ftp_host': '',
					'ftp_username': '',
					'ftp_password': ''
				},
				'step3': {
					'lines': []
				},
				'step4': {
					'transformers': []
				}
			};

			$http.get('/api.php/accounts/' + account_id + '/kenshoo/ftp_credentials')
				.then(function(response) {
					var data = response.data;
					$.extend($scope.data.step2, data);
				}, function(response) {
					var data = response.data;
					ngToast.danger({
						'content': data.message
					});

					$location.path('/');
				});

			var step_cache = {
				// DB #1
				'db_1_id': 0,
				'db_1_import_1_id': 0,
				'db_1_import_1_delimiters': {},
				'db_1_import_2_id': 0,
				'db_1_import_2_delimiters': {},
				'db_1_fields': [],
				'db_1_all_fields': [],
				'db_1_export_1_id': 0,

				// DB #2
				'db_2_id': 0,
				'db_2_import_1_id': 0,
				'db_2_import_1_delimiters': {},
				'db_2_import_2_id': 0,
				'db_2_import_2_delimiters': {},
				'db_2_import_2_headers': [],
				'db_2_fields': [],
				'db_2_all_fields': [],
				'db_2_export_1_id': 0
			};

			var hex2bin = function (hex) {
				var bytes = [];
				for (var i = 0; i < hex.length - 1; i += 2) {
					bytes.push(parseInt(hex.substr(i, 2), 16));
				}

				return String.fromCharCode.apply(String, bytes);
			};

			var on_step_error = function (response) {
				$scope.previous_step();

				if (response && response.message) {
					ngToast.danger({
						'content': response.message
					});
				} else {
					switch (response) {
						case 'The field `transformer` cannot be empty': {
							ngToast.danger({
								'content': 'One of your transformers is empty!'
							});
						}
							break;
						default: {
							ngToast.danger({
								'content': response
							});
						}
					}
				}

				// Delete db1 and db2 if we got that far...
				var promises = [];
				[step_cache['db_1_id'], step_cache['db_2_id']].forEach(function (db_id) {
					if (db_id) {
						promises.push($http.delete('/api.php/dbs/' + db_id));
					}
				});

				Promise.all(promises).then(function (responses) {
					// Do something? Silent for now
				});
			};

			var steps = [
				// Create DB #1
				(new FeedWizardStep())
					.setEndpoint(function () {
						return `/api.php/accounts/${account_id}/dbs`;
					})
					.setMethod('post')
					.setParams(function () {
						return {
							'name': `IBC 2.0 - ${$scope.data.step1.client_name} - Step 1 ${moment().format('MM/DD LT')}`
						};
					})
					.setOnSuccess(function (data) {
						step_cache['db_1_id'] = data.id;
					})
					.setOnError(on_step_error),

				// Create Import #1: Client Feed Import
				(new FeedWizardStep())
					.setEndpoint(function () {
						return `/api.php/dbs/${step_cache.db_1_id}/imports`
					})
					.setMethod('post')
					.setParams(function () {
						return {
							'file_location': 'ftp',
							'file_name': `${$scope.data.step1.client_name}/${$scope.data.step1.ibc_name}/${$scope.data.step2.file_name}`,
							'file_type': 'delimited',
							'tags': {'platform': 'Other', 'platform_other': 'Kenshoo'},
							'host': $scope.data.step2.ftp_host,
							'join_type': 'product_feed',
							'name': 'Client Feed',
							'password': $scope.data.step2.ftp_password,
							'protocol': 'ftp',
							'username': $scope.data.step2.ftp_username,
							'update_threshold': 0,
							'load_threshold': 0
						};
					})
					.setOnSuccess(function (data) {
						step_cache.db_1_import_1_id = data.id;
					})
					.setOnError(on_step_error),

				// Detect Import #1 Delimiters
				(new FeedWizardStep())
					.setEndpoint(function () {
						return `/api.php/dbs/${step_cache.db_1_id}/imports/${step_cache.db_1_import_1_id}/auto_detect`;
					})
					.setMethod('get')
					.setOnSuccess(function (data) {
						step_cache.db_1_import_1_delimiters = data[0];
					})
					.setOnError(on_step_error),

				// Update Import #1 Delimiters
				(new FeedWizardStep())
					.setEndpoint(function () {
						return `/api.php/dbs/${step_cache.db_1_id}/imports/${step_cache.db_1_import_1_id}/delimiters`;
					})
					.setParams(function () {
						return {
							'enclosure': step_cache.db_1_import_1_delimiters['enclosure_character'],
							'encoding': step_cache.db_1_import_1_delimiters['recommended_encoding'],
							'escaper': step_cache.db_1_import_1_delimiters['escape_character'],
							'line_terminator': step_cache.db_1_import_1_delimiters['line_terminator'],
							'separator': step_cache.db_1_import_1_delimiters['field_separator']
						};
					})
					.setMethod('put')
					.setOnError(on_step_error),

				// Grab headers to create file map
				(new FeedWizardStep())
					.setEndpoint(function () {
						return `/api.php/dbs/${step_cache.db_1_id}/imports/${step_cache.db_1_import_1_id}/file?format=parsed&limit=4`;
					})
					.setMethod('get')
					.setOnSuccess(function (response) {
						$scope.data.step3.lines = [];

						response[0].forEach(function (field, index) {
							var header = hex2bin(response[0][index]),
								example1 = hex2bin(response[1][index]),
								example2 = hex2bin(response[2][index]);

							var name = header.replace(/[^\x00-\x7F]/g, "")
								.trim()
								.toLowerCase()
								.replace(/ /g, "_")
								.replace(/-/g, "_");

							$scope.data.step3.lines.push({
								'name': name,
								'header': header,
								'example_1': example1,
								'example_2': example2
							});
						});

						$scope.loading_file_map = false;
					})
					.setOnError(function (response) {
						ngToast.danger({
							'content': response.message
						});

						$scope.previous_step();
					}),

				// Grab DB fields for file map
				(new FeedWizardStep())
					.setEndpoint(function () {
						return `/api.php/dbs/${step_cache.db_1_id}/db_fields`
					})
					.setMethod('get')
					.setOnSuccess(function (data) {
						step_cache['db_1_fields'] = data;
					})
					.setOnError(on_step_error),

				// Update Import #1 file map
				(new FeedWizardStep())
					.setEndpoint(function () {
						return `/api.php/dbs/${step_cache.db_1_id}/imports/${step_cache.db_1_import_1_id}/file_map`
					})
					.setMethod('put')
					.setParams(function () {
						var map = [];
						$scope.data.step3.lines.forEach(function (line) {
							map.push(line.name);
						});

						return {
							'maps': map,
							'name_based_maps': 0
						};
					})
					.setOnError(on_step_error),

				// Create Import #2: Channels JOIN
				(new FeedWizardStep())
					.setEndpoint(function () {
						return `/api.php/dbs/${step_cache.db_1_id}/imports`
					})
					.setMethod('post')
					.setParams(function () {
						return {
							'file_location': 'ftp',
							'file_name': `${$scope.data.step1.client_name}/${$scope.data.step1.ibc_name}/ibc_profiles_and_channels.csv`,
							'file_type': 'delimited',
							'tags': {'platform': 'Other', 'platform_other': 'Kenshoo'},
							'host': $scope.data.step2.ftp_host,
							'join_type': 'join_info',
							'name': 'Participating Channels',
							'password': $scope.data.step2.ftp_password,
							'protocol': 'ftp',
							'username': $scope.data.step2.ftp_username,
							'update_threshold': 0,
							'load_threshold': 0
						};
					})
					.setOnSuccess(function (data) {
						step_cache.db_1_import_2_id = data.id;
					})
					.setOnError(on_step_error),

				// Detect Import #2 Delimiters
				(new FeedWizardStep())
					.setEndpoint(function () {
						return `/api.php/dbs/${step_cache.db_1_id}/imports/${step_cache.db_1_import_2_id}/auto_detect`;
					})
					.setMethod('get')
					.setOnSuccess(function (data) {
						step_cache.db_1_import_2_delimiters = data[0];
					})
					.setOnError(on_step_error),

				// Update Import #2 Delimiters
				(new FeedWizardStep())
					.setEndpoint(function () {
						return `/api.php/dbs/${step_cache.db_1_id}/imports/${step_cache.db_1_import_2_id}/delimiters`;
					})
					.setParams(function () {
						return {
							'enclosure': step_cache.db_1_import_2_delimiters['enclosure_character'],
							'encoding': step_cache.db_1_import_2_delimiters['recommended_encoding'],
							'escaper': step_cache.db_1_import_2_delimiters['escape_character'],
							'line_terminator': step_cache.db_1_import_2_delimiters['line_terminator'],
							'separator': step_cache.db_1_import_2_delimiters['field_separator']
						};
					})
					.setMethod('put')
					.setOnError(on_step_error),

				// Update Import #2 file map
				(new FeedWizardStep())
					.setEndpoint(function () {
						return `/api.php/dbs/${step_cache.db_1_id}/imports/${step_cache.db_1_import_2_id}/file_map`
					})
					.setMethod('put')
					.setParams(function () {
						return {
							'maps': [
								'profilename',
								'profile_id_from_kenshoo',
								'channel_from_kenshoo',
								'channel_account_id_from_kenshoo'
							],
							'name_based_maps': 0
						};
					})
					.setOnError(on_step_error),

				// Create Extra Fields
				(new FeedWizardStep())
					.setEndpoint(function () {
						return `/api.php/dbs/${step_cache.db_1_id}/extra_import_fields`
					})
					.setMethod('put')
					.setParams(function () {
						return {
							'extra_fields': [
								'K_Dimension_IBC_ID',
								'K_Channel',
								'K_Channel_Account_ID',
								'K_Profile_ID',
								'K_Profile_Name',
								'K_Campaign_ID',
								'K_Campaign_Name',
								'K_Bid_Strategy',
								'K_Status'
							]
						};
					})
					.setOnError(on_step_error),

				// Create Transformers
				(new FeedWizardStep())
					.setEndpoint(function () {
						return `/api.php/dbs/${step_cache.db_1_id}/bulk_transformers`
					})
					.setMethod('post')
					.setParams(function () {
						return {
							'bulk_transformers': $scope.data.step4.transformers,
							'data_type': 'json'
						};
					})
					.setOnError(on_step_error),

				// Grab All DB fields for export
				(new FeedWizardStep())
					.setEndpoint(function () {
						return `/api.php/dbs/${step_cache.db_1_id}/db_fields`
					})
					.setMethod('get')
					.setOnSuccess(function (data) {
						step_cache['db_1_all_fields'] = data;
					})
					.setOnError(on_step_error),

				// Create Export
				(new FeedWizardStep())
					.setEndpoint(function () {
						return `/api.php/dbs/${step_cache.db_1_id}/exports`
					})
					.setMethod('post')
					.setParams(function () {
						var export_fields = [];

						step_cache['db_1_all_fields'].forEach(function (field) {
							export_fields.push({
								'field_name': field['field_name'],
								'export_field_name': field['field_name']
							});
						});

						return {
							'name': 'Webhook Export',
							'file_name': 'webhook_export.txt',
							'protocol': 'webhook',
							'protocol_info': {
								'http_method': 'POST',
								'http_url': 'http://imports.feedonomics.com/export/run_webhook.php',
								'http_headers': 'Content-Type: application/json',
								'http_body': `{\n\t\"data_url\": feedonomics::data_url::json,\n\t\"protocol\": \"ftp\",\n\t\"hostname\": \"${$scope.data.step2.ftp_host}\",\n\t\"username\": \"${$scope.data.step2.ftp_username}\",\n\t\"password\": \"${$scope.data.step2.ftp_password}\",\n\t\"upload_path\": \"${$scope.data.step1.client_name}/${$scope.data.step1.ibc_name}/ibc_kenshoo_structured_feed.csv\",\n\t\"client\": \"kenshoo\"\n}`
							},
							'host': '',
							'username': '',
							'password': '',
							'export_fields': export_fields,
							'export_selector': 'true',
							'file_header': '',
							'file_footer': '',
							'threshold': '0',
							'delimiter': 'tab',
							'compression': '',
							'quoted_fields': '0',
							'deduplicate_field_name': '',
							'export_format': 'delimited',
							'include_column_names': '1',
							'export_encoding': '',
							'enclosure': "\"",
							'escape': "\\",
							'strip_characters': ["\r", "\n", "\t"],
							'show_empty_tags': '0',
							'use_cdata': '0',
							'xml_write_document_tag': '1',
							'zip_inner_file_name': '',
							'time_between_attempts': 1,
							'max_attempts': 1
						};
					})
					.setOnSuccess(function (data) {
						step_cache['db_1_export_1_id'] = data.id;
					})
					.setOnError(on_step_error),

				// Run export
				(new FeedWizardStep())
					.setEndpoint(function () {
						return `/api.php/dbs/${step_cache.db_1_id}/exports/${step_cache.db_1_export_1_id}/run_parallel_export`
					})
					.setMethod('post')
					.setParams(function () {
						return {
							'push': true
						};
					})
					.setOnError(on_step_error),

				// Create DB #2
				(new FeedWizardStep())
					.setEndpoint(function () {
						return `/api.php/accounts/${account_id}/dbs`;
					})
					.setMethod('post')
					.setParams(function () {
						return {
							'name': `IBC 2.0 - ${$scope.data.step1.client_name} - Step 2 ${moment().format('MM/DD LT')}`
						};
					})
					.setOnSuccess(function (data) {
						step_cache['db_2_id'] = data.id;
					})
					.setOnError(on_step_error),

				// Create Import #1: Kenshoo Structured Feed from Step 1
				(new FeedWizardStep())
					.setEndpoint(function () {
						return `/api.php/dbs/${step_cache.db_2_id}/imports`
					})
					.setMethod('post')
					.setParams(function () {
						return {
							'file_location': 'ftp',
							'file_name': `${$scope.data.step1.client_name}/${$scope.data.step1.ibc_name}/ibc_kenshoo_structured_feed.csv`,
							'file_type': 'delimited',
							'tags': {'platform': 'Other', 'platform_other': 'Kenshoo'},
							'host': $scope.data.step2.ftp_host,
							'join_type': 'product_feed',
							'name': 'Kenshoo Structured Feed from Step 1',
							'password': $scope.data.step2.ftp_password,
							'protocol': 'ftp',
							'username': $scope.data.step2.ftp_username,
							'update_threshold': 0,
							'load_threshold': 0
						};
					})
					.setOnSuccess(function (data) {
						step_cache.db_2_import_1_id = data.id;
					})
					.setOnError(on_step_error),

				// Detect Import #1 Delimiters
				(new FeedWizardStep())
					.setEndpoint(function () {
						return `/api.php/dbs/${step_cache.db_2_id}/imports/${step_cache.db_2_import_1_id}/auto_detect`;
					})
					.setMethod('get')
					.setOnSuccess(function (data) {
						step_cache.db_2_import_1_delimiters = data[0];
					})
					.setOnError(on_step_error),

				// Update Import #1 Delimiters
				(new FeedWizardStep())
					.setEndpoint(function () {
						return `/api.php/dbs/${step_cache.db_2_id}/imports/${step_cache.db_2_import_1_id}/delimiters`;
					})
					.setParams(function () {
						return {
							'enclosure': step_cache.db_2_import_1_delimiters['enclosure_character'],
							'encoding': step_cache.db_2_import_1_delimiters['recommended_encoding'],
							'escaper': step_cache.db_2_import_1_delimiters['escape_character'],
							'line_terminator': step_cache.db_2_import_1_delimiters['line_terminator'],
							'separator': step_cache.db_2_import_1_delimiters['field_separator']
						};
					})
					.setMethod('put')
					.setOnError(on_step_error),

				// Update Import #1 file map
				(new FeedWizardStep())
					.setEndpoint(function () {
						return `/api.php/dbs/${step_cache.db_2_id}/imports/${step_cache.db_2_import_1_id}/file_map`
					})
					.setMethod('put')
					.setParams(function () {
						var map = [];
						step_cache['db_1_all_fields'].forEach(function (field) {
							map.push(field['field_name']);
						});

						return {
							'maps': map,
							'name_based_maps': 0
						};
					})
					.setOnError(on_step_error),

				// Create Import #2: JOIN current state
				(new FeedWizardStep())
					.setEndpoint(function () {
						return `/api.php/dbs/${step_cache.db_2_id}/imports`
					})
					.setMethod('post')
					.setParams(function () {
						return {
							'file_location': 'ftp',
							'file_name': `${$scope.data.step1.client_name}/${$scope.data.step1.ibc_name}/ibc_kenshoo_structured_state.csv`,
							'file_type': 'delimited',
							'tags': {'platform': 'Other', 'platform_other': 'Kenshoo'},
							'host': $scope.data.step2.ftp_host,
							'join_type': 'join_info_full',
							'name': 'JOIN current state',
							'password': $scope.data.step2.ftp_password,
							'protocol': 'ftp',
							'username': $scope.data.step2.ftp_username,
							'update_threshold': 0,
							'load_threshold': 0
						};
					})
					.setOnSuccess(function (data) {
						step_cache.db_2_import_2_id = data.id;
					})
					.setOnError(on_step_error),

				// Detect Import #2 Delimiters
				(new FeedWizardStep())
					.setEndpoint(function () {
						return `/api.php/dbs/${step_cache.db_2_id}/imports/${step_cache.db_2_import_2_id}/auto_detect`;
					})
					.setMethod('get')
					.setOnSuccess(function (data) {
						step_cache.db_2_import_2_delimiters = data[0];
					})
					.setOnError(on_step_error),

				// Update Import #2 Delimiters
				(new FeedWizardStep())
					.setEndpoint(function () {
						return `/api.php/dbs/${step_cache.db_2_id}/imports/${step_cache.db_2_import_2_id}/delimiters`;
					})
					.setParams(function () {
						return {
							'enclosure': step_cache.db_2_import_2_delimiters['enclosure_character'],
							'encoding': step_cache.db_2_import_2_delimiters['recommended_encoding'],
							'escaper': step_cache.db_2_import_2_delimiters['escape_character'],
							'line_terminator': step_cache.db_2_import_2_delimiters['line_terminator'],
							'separator': step_cache.db_2_import_2_delimiters['field_separator']
						};
					})
					.setMethod('put')
					.setOnError(on_step_error),

				// Get Import #2 Headers
				(new FeedWizardStep())
					.setEndpoint(function () {
						return `/api.php/dbs/${step_cache.db_2_id}/imports/${step_cache.db_2_import_2_id}/file?format=parsed&limit=4`;
					})
					.setMethod('get')
					.setOnSuccess(function (data) {
						step_cache.db_2_import_2_headers = data[0];
					})
					.setOnError(on_step_error),

				// Update Import #2 file map
				(new FeedWizardStep())
					.setEndpoint(function () {
						return `/api.php/dbs/${step_cache.db_2_id}/imports/${step_cache.db_2_import_2_id}/file_map`
					})
					.setMethod('put')
					.setParams(function () {
						var map = [];
						step_cache['db_2_import_2_headers'].forEach(function (field) {
							map.push(hex2bin(field));
						});

						return {
							'maps': map,
							'name_based_maps': 0
						};
					})
					.setOnError(on_step_error),

				// Grab All DB fields for export
				(new FeedWizardStep())
					.setEndpoint(function () {
						return `/api.php/dbs/${step_cache.db_2_id}/db_fields`
					})
					.setMethod('get')
					.setOnSuccess(function (data) {
						step_cache['db_2_all_fields'] = data;
					})
					.setOnError(on_step_error),

				// Create Export
				(new FeedWizardStep())
					.setEndpoint(function () {
						return `/api.php/dbs/${step_cache.db_2_id}/exports`
					})
					.setMethod('post')
					.setParams(function () {
						var export_fields = [];

						step_cache['db_2_all_fields'].forEach(function (field) {
							export_fields.push({
								'field_name': field['field_name'],
								'export_field_name': field['field_name']
							});
						});

						return {
							'name': 'PUSH TO KENSHOO',
							'file_name': `${$scope.data.step1.client_name}/${$scope.data.step1.ibc_name}/ibc_import_to_kenshoo_YYYY-MM-dd-HH:mm:ss.csv`,
							'protocol': 'ftp',
							'host': $scope.data.step2.ftp_host,
							'username': $scope.data.step2.ftp_username,
							'password': $scope.data.step2.ftp_password,
							'export_fields': export_fields,
							'export_selector': 'true',
							'file_header': '',
							'file_footer': '',
							'threshold': '0',
							'delimiter': 'tab',
							'compression': '',
							'quoted_fields': '0',
							'deduplicate_field_name': '',
							'export_format': 'delimited',
							'include_column_names': '1',
							'export_encoding': '',
							'enclosure': "\"",
							'escape': "\\",
							'strip_characters': ["\r", "\n", "\t"],
							'show_empty_tags': '0',
							'use_cdata': '0',
							'xml_write_document_tag': '1',
							'zip_inner_file_name': '',
							'time_between_attempts': 1,
							'max_attempts': 1
						};
					})
					.setOnSuccess(function (data) {
						step_cache['db_1_export_1_id'] = data.id;
					})
					.setOnError(on_step_error)
			];

			var wizard = (new FeedWizard($http, $q))
				.addSteps(steps);

			$scope.build = function () {
				$scope.next_step();

				wizard.run().then(function () {
					$scope.next_step();

					ngToast.success({
						'content': 'Your build is complete!'
					});
				}, angular.noop, function (progress) {
					$scope.progress = progress;
				});
			};

			$scope.$watch('data.step1.client_name', function (value) {
				$scope.data.step2.import_name = value ? 'IBC 2.0 - ' + value + ' - Step 1' : '';
			});

			$scope.$watch('step', function (step, last_step) {
				if (step === 4 && last_step === 3) {
					var codemirror_headers = [];
					var min_transformers = [
						'K_Dimension_IBC_ID',
						'K_Channel',
						'K_Channel_Account_ID',
						'K_Profile_ID',
						'K_Profile_Name',
						'K_Campaign_ID',
						'K_Campaign_Name',
						'K_Bid_Strategy',
						'K_Status'
					];

					$scope.data.step4.transformers = [];
					$scope.headers = angular.copy(min_transformers);

					$scope.data.step3.lines.forEach(function (line) {
						$scope.headers.push(line.name);
						codemirror_headers.push('[' + line.name + ']');
					});

					CodeMirror.hint.transformer_parser = cm_variables.hint_transformer_parser(codemirror_headers);
					CodeMirror.defineSimpleMode('transformer_parser', cm_variables.mode_transformer_parser($scope.headers));

					$scope.code_mirror = {
						mode: 'transformer_parser',
						extraKeys: {
							'Tab': false,
							'Shift-Tab': false,
						},
					};

					min_transformers.forEach(function (field_name) {
						$scope.add_transformer(field_name, 'true', '\'abc\'');
					});
				}
			});

			$scope.add_transformer = function (field_name, selector, transformer) {
				$timeout(function () {
					$scope.data.step4.transformers.push({
						'export_id': 0,
						'field_name': field_name || $scope.headers[0],
						'selector': selector || '',
						'transformer': transformer || ''
					});
				});
			};

			$scope.remove_transformer = function ($index) {
				$scope.data.step4.transformers.splice($index, 1);
			};

			$scope.cmInit = cm_variables.cmInit;

			$scope.generate_file_map = function () {
				$scope.next_step();
				$scope.loading_file_map = true;

				wizard
					.setCurrentStepIndex(0)
					.run(5)
					.then(function () {
						$scope.loading_file_map = false;
					});
			};

			$scope.$on('$locationChangeStart', function (e) {
				if ($scope.step > 2 && $scope.step < 6) {
					var resp = confirm('Leaving this page will interupt your kenshoo import. Are you sure?');
					if (!resp) {
						e.preventDefault();
					} else {
						// Delete dbs
						on_step_error();
					}
				}
			});
		}
	};
}]);