<div class="container">
    <fdx-page-title>{{ pageTitle }}</fdx-page-title>
    <fdx-well>
        <p class="text-center">Enter the FTP location of your FeedTelligence Output File.</p>
        <div class="row mb-3">
            <label class="col-md-4 col-form-label text-end">File Name</label>
            <div class="col-md-4">
                <input type="text" placeholder="path/to/export_file.txt" class="form-control" />
            </div>
        </div>
        <div class="row mb-3">
            <label class="col-md-4 col-form-label text-end">Host</label>
            <div class="col-md-4">
                <input type="text" placeholder="ftp2.feedonomics.com" class="form-control" />
            </div>
        </div>
        <div class="row mb-3">
            <label class="col-md-4 col-form-label text-end">Username</label>
            <div class="col-md-4">
                <input type="text" placeholder="Username" class="form-control" />
            </div>
        </div>
        <div class="row mb-3">
            <label class="col-md-4 col-form-label text-end">Password</label>
            <div class="col-md-4">
                <input type="text" placeholder="Password" class="form-control" />
            </div>
        </div>

        <div class="text-center">
            <button (click)="runReport()" class="btn btn-primary" [disabled]="loading">Run Report!</button>
        </div>
    </fdx-well>

    <fdx-well class="mt-3" *ngIf="showReport">
        <p class="text-center">
            These insights are automatically generated by analyzing actual search terms that
            converted and your Google Merchant Center Feed.
        </p>
        <table class="table mb-0">
            <thead class="thead">
                <tr>
                    <th>SKU</th>
                    <th>Missing Words</th>
                    <th>Search Query</th>
                    <th>Current Feed Title</th>
                    <th>Revenue</th>
                    <th>Conversions</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>B04 1101 EPOCH</td>
                    <td>
                        <span class="missing_word">James Harold, Titanium Pocket Knife</span>
                    </td>
                    <td>
                        <span class="missing_word">James Harold</span> Pikatti Epoch <span class="missing_word">Titanium Pocket Knife</span>
                    </td>
                    <td>Pikatti Epoch</td>
                    <td>$174,878</td>
                    <td>352</td>
                </tr>
                <tr>
                    <td>Nike Air 1225</td>
                    <td><span class="missing_word">Basketball Sneakers</span></td>
                    <td>Nike Air Jordan <span class="missing_word">Basketball Sneakers</span> Size 10</td>
                    <td>Nike Air Jordan Keds Size 10</td>
                    <td>$103,455</td>
                    <td>592</td>
                </tr>
                <tr>
                    <td>EFisher53mblack</td>
                    <td>
                        <span class="missing_word">V-Neck, With Pockets</span>
                    </td>
                    <td>
                        Eileen Fisher Silk <span class="missing_word">V-Neck</span> Medium Dress <span class="missing_word">With Pockets</span>
                    </td>
                    <td>Eileen Fisher Silk Shift Dress Size Medium</td>
                    <td>$83,924</td>
                    <td>108</td>
                </tr>
                <tr>
                    <td>DFSAVANNA</td>
                    <td><span class="missing_word">backyard</span></td>
                    <td>Oriflamme <span class="missing_word">Backyard</span> Fire Pit</td>
                    <td>Oriflamme Gas Fire Pit Table Savanna Stone</td>
                    <td>$29,398</td>
                    <td>33</td>
                </tr>
            </tbody>
        </table>
    </fdx-well>
</div>
