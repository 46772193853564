import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreLegacyModule } from '@app/core-legacy/core-legacy.module';
import { InputsModule } from '@app/modules/inputs';
import { ModalsModule } from '@app/modules/modals/modals.module';
import { NgSelectModule } from '@feedonomics/ng-select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbNavModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

// Components
import { FeedAiAttributionTabComponent } from '@app/feed-ai/components/feed-ai-attribution-tab/feed-ai-attribution-tab.component';
import { FeedAiClassificationTabComponent } from '@app/feed-ai/components/feed-ai-classification-tab/feed-ai-classification-tab.component';
import { AttributorPageComponent } from '@app/feed-ai/pages/attributor-page/attributor-page.component';
import { ClassifierPageComponent } from '@app/feed-ai/pages/classifier-page/classifier-page.component';
import { FeedAiPageComponent } from '@app/feed-ai/pages/feed-ai-page/feed-ai-page.component';
import { NgSelectTemplatesModule } from '@app/modules/ng-select-templates/ng-select-templates.module';
import { PageModule } from '@app/modules/page/page.module';

@NgModule({
    declarations: [
        AttributorPageComponent,
        ClassifierPageComponent,
        FeedAiAttributionTabComponent,
        FeedAiClassificationTabComponent,
        FeedAiPageComponent
    ],
    imports: [
        CommonModule,
        CoreLegacyModule,
        FontAwesomeModule,
        InputsModule,
        ModalsModule,
        NgSelectModule,
        NgSelectTemplatesModule,
        NgbNavModule,
        NgbPopoverModule,
        PageModule,
        ReactiveFormsModule,
    ]
})
export class FeedAiModule {
}
