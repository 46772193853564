import FdxUI from '@ajs/services/fdxUI';
import { DecimalPipe } from '@angular/common';
import { Component, OnDestroy, OnInit, Self, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppMenuTab } from '@app/core/models/enums/app-menu-tab.enum';
import { LogsFiltersComponent } from '@app/logs/components/logs-filters/logs-filters.component';
import { LOG_TYPE_MAP } from '@app/logs/constants/logs.constants';
import { LogTypeOption } from '@app/logs/models/types/log-type-option.type';
import { LogsDataFormatterService } from '@app/logs/services/logs-data-formatter.service';
import { LogsStateService } from '@app/logs/services/logs-state.service';
import { DateRange } from '@feedonomics/frontend-components';
import { Subject, takeUntil, tap } from 'rxjs';

export type LogsPageFilters = {
    dateRange: DateRange
    operation?: string[]
    action?: string[]
    field?: string[]
    user?: string[]
    status?: string[]
    module?: string[]
    /* changeLogFilter?: string[] */
};

@Component({
    selector: 'fdx-logs-page',
    templateUrl: './logs-page.component.html',
    styleUrls: ['./logs-page.component.scss'],
    providers: [
        DecimalPipe,
        LogsDataFormatterService,
        LogsStateService
    ]
})
export class LogsPageComponent implements OnInit, OnDestroy {
    appMenuTab: AppMenuTab = AppMenuTab.None;
    title: string = 'Logs';

    private readonly unsubscribe$: Subject<void> = new Subject<void>();
    @ViewChild(LogsFiltersComponent) logsFilters: LogsFiltersComponent;

    logTypes: LogTypeOption[] = Array.from(LOG_TYPE_MAP.values());

    // eslint-disable-next-line @typescript-eslint/typedef
    logTypeForm = new FormGroup({
        logType: new FormControl<LogTypeOption>(this.logTypes[0])
    });

    constructor(
        private readonly fdxUI: FdxUI,
        @Self() private readonly logsStateService: LogsStateService,    // TODO: maybe don't need this
        private readonly route: ActivatedRoute,
        private readonly router: Router
    ) {}

    ngOnInit(): void {
        this.fdxUI.setActiveTab(this.appMenuTab);
        this.fdxUI.setTitle(this.title);

        this.initLogType();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    initLogType(): void {
        const routePath = this.route.snapshot.firstChild.routeConfig.path;
        const urlLogType = this.logTypes.find((option) => option.route === routePath);
        if (urlLogType) {
            this.logTypeForm.patchValue({
                logType: urlLogType
            });
        }

        this.logTypeForm.valueChanges.pipe(
            tap((values) => {
                const newRoutePath: string = values.logType.route;
                void this.router.navigate(
                    [newRoutePath],
                    {
                        relativeTo: this.route,
                        replaceUrl: true
                    }
                );
            }),
            takeUntil(this.unsubscribe$)
        ).subscribe();
    }
}
