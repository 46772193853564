import * as angular from 'angular';
import { MODULE_NAME } from '../config/constants';

angular.module(MODULE_NAME)
    .controller('FeedSupportRedirectController', ['$state', 'AppStateService',
        function ($state, AppStateService) {
            if ((AppStateService.getUser().hasFeature('new_feedsupport', 'enabled') ||
                AppStateService.getAccount().hasFeature('new_feedsupport', 'enabled')) &&
                !AppStateService.getAccount().hasFeature('feedsupport_categories')) {
                $state.go('app.acct-feedsupport-new', {account_id: AppStateService.getAccountId()}, {location: 'replace'})
            } else {
                $state.go('app.acct-feedsupport-legacy', {account_id: AppStateService.getAccountId()}, {location: 'replace'})
            }
        }
    ]);
