import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserDatabasePermissionsDataModel } from '@app/accounts/models/user-database-permissions-data.model';
import { UserDatabasePermissionsModel } from '@app/accounts/models/user-database-permissions.model';
import { isEmpty } from '@app/core/functions/isEmpty';
import { FlatParams } from '@app/core/models/interfaces/params.interface';
import { DataPageExport } from '@app/data/enums/data-page-export.enum';
import { DbFieldUniqueValueModel } from '@app/databases/models/db-field-unique-value.model';
import { DbFieldModel } from '@app/databases/models/db-field.model';
import { DbModel } from '@app/databases/models/db.model';
import { AnalyzeFieldsRequest } from '@app/databases/services/requests/analyze-fields.request';
import { DatabaseSearchRequest } from '@app/databases/services/requests/database-search.request';
import { AnalyzeFieldsByNameResponse } from '@app/databases/services/responses/analyze-fields-by-name.response';
import { CopyDatabaseResponse } from '@app/databases/services/responses/copy-database.response';
import { RawDataResponse, RawDataRowResponse, SetPrimaryKeyResponse, TransformedDataLogsResponse, TransformedDataResponse } from '@app/databases/services/responses/data.response';
import { DbExportCountsResponse } from '@app/databases/services/responses/db-export-counts.response';
import { SearchDatabaseResponse } from '@app/databases/services/responses/search-database.response';
import { TransformedDataDownloadResponse } from '@app/databases/services/responses/transformed-data-download.response';
import { JoinPerformanceReportType } from '@app/reports-sku/types/join-performance-report.type';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DatabasesDataService {
    constructor(
        private readonly http: HttpClient
    ) { }

    getDatabases(accountId: string): Observable<DbModel[]> {
        return this.http.get<DbModel[]>(`/api.php/accounts/${accountId}/dbs`);
    }

    searchDatabases(accountId: string, params: Partial<DatabaseSearchRequest> = {}): Observable<SearchDatabaseResponse> {
        return this.http.get<SearchDatabaseResponse>(`/api.php/accounts/${accountId}/databases`, { params });
    }

    getDatabaseExportCounts(accountId: string, params: Record<string, string | number | boolean>): Observable<DbExportCountsResponse> {
        return this.http.get<DbExportCountsResponse>(`/api.php/accounts/${accountId}/dbs/export_counts`, { params });
    }

    create(accountId: string, params: Record<string, string | number | boolean>): Observable<DbModel> {
        return this.http.post<DbModel>(`/api.php/accounts/${accountId}/dbs`, params);
    }

    update(databaseId: string, params: Record<string, string | number | boolean>): Observable<DbModel> {
        return this.http.put<DbModel>(`/api.php/dbs/${databaseId}`, params);
    }

    getOneById(databaseId: string): Observable<DbModel> {
        return this.http.get<DbModel>(`/api.php/dbs/${databaseId}`);
    }

    copy(databaseId: string, params: Record<string, string | number | boolean>): Observable<CopyDatabaseResponse> {
        return this.http.post<CopyDatabaseResponse>(`/api.php/dbs/${databaseId}/copy_db`, params);
    }

    delete(databaseId: string): Observable<void> {
        return this.http.delete<void>(`/api.php/dbs/${databaseId}`);
    }

    moveToGroup(databaseId: string, params: Record<string, string | number | boolean>): Observable<void> {
        return this.http.post<void>(`/api.php/dbs/${databaseId}/move_db_to_db_group`, params);
    }

    runScheduledImports(databaseId: string): Observable<any> {
        return this.http.post(`/api.php/dbs/${databaseId}/run_scheduled_imports`, null);
    }

    runScheduledExports(databaseId: string): Observable<any> {
        return this.http.post(`/api.php/dbs/${databaseId}/run_scheduled_exports`, null);
    }

    runParallelExport(databaseId: string, exportId: string, params: FlatParams): Observable<TransformedDataDownloadResponse> {
        return this.http.post<TransformedDataDownloadResponse>(`/api.php/dbs/${databaseId}/exports/${exportId}/run_parallel_export`,
            params
        );
    }

    getJoinPerformanceReport(databaseId: string, params: JoinPerformanceReportType): Observable<any> {
        return this.http.post(`/api.php/dbs/${databaseId}/join_performance_report`, params);
    }

    getUniqueValues(databaseId: string, fieldName: string): Observable<DbFieldUniqueValueModel[]> {
        return this.http.get<DbFieldUniqueValueModel[]>(`/api.php/dbs/${databaseId}/unique_values`, {
            params: {
                field_name: fieldName
            }
        });
    }

    postUniqueValues(databaseId: string, fieldName: string, maps: DbFieldUniqueValueModel[]): Observable<any> {
        return this.http.post<any>(`/api.php/dbs/${databaseId}/unique_values`, {
            field_name: fieldName,
            maps: maps
        });
    }

    resendInvite({ rawData }: UserDatabasePermissionsModel): Observable<string> {
        return this.http.post(
            `/api.php/dbs/${rawData.db_id}/resend_invite`,
            {
                username: rawData.user_name,
                permissions: rawData.permissions
            },
            {
                responseType: 'text'
            }
        );
    }

    getFields(databaseId: string): Observable<DbFieldModel[]> {
        return this.http.get<DbFieldModel[]>(`/api.php/dbs/${databaseId}/db_fields`);
    }

    getFieldsWithParams<T>(
        databaseId: string,
        params: {
            augment_with_marketplace_data?: string;
            transformer_count?: number;
        } = {}
    ): Observable<T[]> {
        return this.http.get<T[]>(`/api.php/dbs/${databaseId}/db_fields`, { params });
    }

    updateField(databaseId: string, params: Partial<DbFieldModel> = {}): Observable<DbFieldModel[]> {
        return this.http.put<DbFieldModel[]>(`/api.php/dbs/${databaseId}/db_fields`, params);
    }

    createUserDatabasePermissions(databaseId: string, username: string, permissions: string): Observable<UserDatabasePermissionsDataModel> {
        return this.http.post<UserDatabasePermissionsDataModel>(`/api.php/dbs/${databaseId}/user_db_permissions`, {
            permissions,
            username
        });
    }

    deleteUserDatabasePermissions(udp: UserDatabasePermissionsModel): Observable<unknown> {
        return this.http.delete<unknown>(`/api.php/dbs/${udp.rawData.db_id}/user_db_permissions/${udp.rawData.id}`);
    }

    updateUserDatabasePermissions(udp: UserDatabasePermissionsModel): Observable<UserDatabasePermissionsDataModel> {
        return this.http.put<UserDatabasePermissionsDataModel>(`/api.php/dbs/${udp.rawData.db_id}/user_db_permissions`, {
            udp_id: udp.rawData.id,
            user_id: udp.rawData.user_id,
            permissions: udp.newPermission
        });
    }

    getRawData(databaseId: string, params: Record<string, any>): Observable<RawDataResponse> {
        return this.http.get<RawDataResponse>(`/api.php/dbs/${databaseId}/data`, { params: params });
    }

    getRawDataForRow(databaseId: string, rowId: string): Observable<RawDataRowResponse> {
        return this.http.get<RawDataRowResponse>(`/api.php/dbs/${databaseId}/data_row/${rowId}}`);
    }

    getTransformedData(databaseId: string, exportId: string, params: Record<string, any>): Observable<TransformedDataResponse | TransformedDataLogsResponse> {
        return this.http.get<TransformedDataResponse | TransformedDataLogsResponse>(`/api.php/dbs/${databaseId}/transformed_data/${exportId}`, { params: params });
    }

    getIsRunning(databaseId: string, pollDuration: number = null, isRunning: boolean = null): Observable<any> {

        const options = { params: {} };
        if (pollDuration) {
            options.params['poll_duration'] = pollDuration;
        }
        if (!(typeof isRunning === 'undefined')) {
            options.params['is_running'] = isRunning;
        }

        return this.http.get(`/api.php/dbs/${databaseId}/running`, options);
    }

    setPrimaryKey(databaseId: string, fieldName: string): Observable<any> {
        return this.http.post<SetPrimaryKeyResponse>(`/api.php/dbs/${databaseId}/primary_key`, {
            primary_key: fieldName
        });
    }

    getAnalyzedFieldByName(databaseId: string, exportId: string, columnName1: string, columnName2: string = null, fqlFilter: string = null, requestParams: AnalyzeFieldsRequest = {}): Observable<AnalyzeFieldsByNameResponse> {
        if (isEmpty(columnName2)) {
            columnName2 = '__no_column_name2';
        }

        let params = new HttpParams()
            .set('column_name1', columnName1)
            .set('column_name2', columnName2);

        if (fqlFilter) {
            params = params.append('selector', fqlFilter);
        }

        if (requestParams.marketplace) {
            params = params.append('marketplace', requestParams['marketplace']);
        }

        if (requestParams.region) {
            params = params.append('region', requestParams['region']);
        }

        if (requestParams.max_data_limit) {
            params = params.append('max_data_limit', requestParams['max_data_limit']);
        }

        if (requestParams.max_unique_values) {
            params = params.append('max_unique_values', requestParams['max_unique_values']);
        }

        if (requestParams.refresh) {
            params = params.append('refresh', requestParams['refresh']);
        }

        if (exportId === DataPageExport.RawData) {
            return this.http.get<AnalyzeFieldsByNameResponse>(`/api.php/dbs/${databaseId}/data_analyze`, { params: params });
        }

        return this.http.post<AnalyzeFieldsByNameResponse>(`/api.php/dbs/${databaseId}/transformed_data_analyze/${exportId}`, params);
    }
}
