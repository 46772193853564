import { IActionMapping, ITreeOptions, KEYS } from '@ali-hm/angular-tree-component';
import { Component, Input } from '@angular/core';
import { TransformerFieldDependencyModel } from '@app/transformers/models/transformer-field-dependency.model';

const readOnlyActionMapping: IActionMapping = {
    mouse: {
        click: () => null,
        dblClick: () => null,
        contextMenu: () => null,
        expanderClick: () => null,
        checkboxClick: () => null,
        drop: () => null
    },
    keys: {
        [KEYS.RIGHT]: () => null,
        [KEYS.LEFT]: () => null,
        [KEYS.DOWN]: () => null,
        [KEYS.UP]: () => null,
        [KEYS.SPACE]: () => null,
        [KEYS.ENTER]: () => null
    }
};

@Component({
    selector: 'fdx-transformers-field-dependency-tree-content',
    templateUrl: './transformers-field-dependency-tree-content.component.html',
    styleUrls: ['./transformers-field-dependency-tree-content.component.scss']
})
export class TransformersFieldDependencyTreeContentComponent {
    @Input() transformerFieldDependency: TransformerFieldDependencyModel;

    options: ITreeOptions = {
        actionMapping: readOnlyActionMapping
    };
}
