import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FdxApiResponse } from '@app/core/responses/fdx-api.response';
import { WebhookTypeInterface } from '@app/exports/models/interfaces/webhook-type.interface';
import { BehaviorSubject, Observable, skipWhile, take } from 'rxjs';

enum StatusEnum {
    INITIAL,
    LOADING,
    LOADED
}

@Injectable({
    providedIn: 'root'
})
export class WebhookTypesService {

    private status: StatusEnum = StatusEnum.INITIAL;

    private readonly webhookTypes$: BehaviorSubject<WebhookTypeInterface[] | null> = new BehaviorSubject<WebhookTypeInterface[]>(null);

    constructor(
        private readonly http: HttpClient
    ) {

    }

    getWebhookTypes(): Observable<WebhookTypeInterface[]> {

        this.retrieveData();

        return this.webhookTypes$.pipe(
            skipWhile((value) => value === null),
            take(1)
        );

    }

    retrieveData(): void {

        if ( this.status === StatusEnum.LOADING || this.status === StatusEnum.LOADED) {
            return;
        }

        this.status = StatusEnum.LOADING;

        this.http.get<FdxApiResponse<WebhookTypeInterface[]>>('/api.php/webhook_type').subscribe({
            next: (response) => {
                this.status = StatusEnum.LOADED;
                this.webhookTypes$.next(response.data);
            }
        });

    }

}
