<div class="d-flex justify-content-between align-items-center gap-2">
    <div class="d-flex align-items-center gap-1">
        <ng-container *ngIf="!hidePerPageSelector">
            <span class="me-1">Rows per page:</span>
            <ng-select
                [items]="itemsPerPageList"
                bindLabel="display_name"
                bindValue="value"
                [clearable]="false"
                [(ngModel)]="perPage"
                (change)="onPerPageChange($event)"
                name="itemsPerPage">
            </ng-select>
        </ng-container>
        <span class="ms-4">
            Showing {{pageStartRecord}}-{{pageEndRecord}} of {{recordsCount}}
        </span>
    </div>

    <fdx-pagination
        [withInput]="false"
        [withLabels]="true"
        [maxSize]="2"
        (pageChange)="changePage($event)"
        [(page)]="currentPage"
        [pageCount]="pageCount"
        [pageSize]="perPage">
    </fdx-pagination>
</div>
