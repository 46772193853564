<fdx-form-section header="Options">
    <form [formGroup]="form" #mainForm="ngForm">
        <div class="row">
            <div class="col-md-12">
                <label class="form-label">File Header</label>
                <textarea class="form-control" formControlName="fileHeader" placeholder="File Header"></textarea>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-md-12">
                <label class="form-label">File Footer</label>
                <textarea class="form-control" formControlName="fileFooter" placeholder="File Footer"></textarea>
            </div>
        </div>

        <fieldset class="row mt-3">
            <div class="col-md-6">
                <div class="form-check">
                    <label>
                        <input class="form-check-input" type="checkbox" formControlName="includeColumnNames">
                        Put column names in first row
                    </label>
                </div>

                <div class="form-check mt-3">
                    <label>
                        <input class="form-check-input" type="checkbox" formControlName="quotedFields">
                        Force Quoted Fields
                    </label>
                </div>

                <div class="mt-3">
                    <label class="form-label">
                        Delta Export <fa-icon
                                        [icon]="iconHelp"
                                        ngbPopover="Push only the updated/deleted products since the previous export."
                                        triggers="mouseenter:mouseleave"
                                        popoverTitle="Delta Export"></fa-icon>
                    </label>
                    <ng-select [clearable]="false"
                                [items]="deltaExportOptions"
                                [readonly]="!canEditDeltaExport"
                                bindLabel="display"
                                bindValue="value"
                                formControlName="deltaExport">
                    </ng-select>
                </div>

                <!-- DEDUPLICATE FIELDS -->
                <div class="col-form-label pb-0">Deduplicate on</div>
                <small class="text-muted">
                    Deduplications will execute in order from top to bottom
                </small>
                <div class="form-check mt-2 mb-3">
                    <label>
                        <input
                            class="form-check-input"
                            type="checkbox"
                            formControlName="deduplicatePreserveNulls">
                        Keep blank values
                    </label>
                </div>
                <fdx-export-deduplicate-fields
                    class="mt-3"
                    [exportItem]="exportItem"
                    [exportFields]="exportItem.export_fields"
                    (dedupeFieldsChange)="handleDeduplicateFieldChange($event)">
                </fdx-export-deduplicate-fields>

                <div class="mt-3">
                    <label class="form-label">Transliterate to:</label>
                    <ng-select [clearable]="false" [items]="exportEncodingOptions" bindLabel="display" bindValue="value" formControlName="exportEncoding"></ng-select>
                </div>

                <div class="row mt-3">
                    <div class="col-md-8">
                        <label class="form-label">Sort Column</label>
                        <ng-select [clearable]="false"
                                   [readonly]="disableSortColumn"
                                   [items]="sortableFieldOptions"
                                   bindLabel="display"
                                   bindValue="value"
                                   formControlName="rowSort"
                                   groupBy="source"
                                   placeholder="Select / search for a field"
                                   [virtualScroll]="true">
                        </ng-select>
                    </div>

                    <div class="col-md-4">
                        <label class="form-label">Order</label>
                        <ng-select [clearable]="false" [readonly]="disableRowOrder" formControlName="rowOrder">
                            <ng-option value="ASC">ASC</ng-option>
                            <ng-option value="DESC">DESC</ng-option>
                        </ng-select>
                    </div>
                </div>

                <fieldset class="row mt-3">
                    <div class="col-md-12">
                        <label class="form-label">Row Limit</label>
                        <input class="form-control" formControlName="rowLimit" placeholder="Unlimited" type="number" min="1">
                    </div>
                </fieldset>
            </div>

            <div class="col-md-6">
                <fieldset>
                    <label class="form-label">Export format</label>

                    <ng-select [clearable]="false"
                               [items]="exportFormatOptions"
                               bindLabel="display"
                               bindValue="value"
                               formControlName="exportFormat">
                    </ng-select>
                </fieldset>

                <fieldset *ngIf="showItemGroupID" class="mt-3">
                    <label class="form-label">Item Group ID</label>
                    <ng-select [clearable]="false"
                               [items]="itemGroupIDOptions"
                               groupBy="source"
                               bindLabel="display"
                               bindValue="value"
                               formControlName="itemGroupID">
                    </ng-select>
                </fieldset>

                <fieldset *ngIf="showJsonMinifyType" class="mt-3">
                    <label class="form-label">Minify JSON Type</label>
                    <ng-select [clearable]="false"
                               [items]="jsonMinifyTypeOptions"
                               bindLabel="display"
                               bindValue="value"
                               formControlName="jsonMinifyType">
                    </ng-select>
                </fieldset>

                <fieldset *ngIf="isExportFormatDelimited" class="mt-3">
                    <label class="form-label">Field Separator</label>
                    <ng-select [clearable]="false"
                               [items]="delimiterOptions"
                               bindLabel="display"
                               bindValue="value"
                               formControlName="delimiter">
                    </ng-select>
                </fieldset>

                <fieldset *ngIf="isExportFormatDelimited" class="mt-3">
                    <label class="form-label">Enclosure Character</label>
                    <input type="text" class="form-control" formControlName="enclosure" maxlength="1">
                </fieldset>

                <fieldset *ngIf="isExportFormatDelimited" class="mt-3">
                    <label class="form-label">Escape Character</label>
                    <input type="text" class="form-control" formControlName="escape" maxlength="1">
                </fieldset>

                <fieldset class="mt-3">
                    <label class="form-label">Strip Characters</label>
                    <ng-select [clearable]="false"
                               [items]="stripCharacterOptions"
                               [multiple]="true"
                               bindLabel="display"
                               bindValue="value"
                               formControlName="stripCharacters">
                               <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                   <fdx-select-multiple-label-template [item]="item" [clear]="clear">{{ item.display }}</fdx-select-multiple-label-template>
                               </ng-template>
                    </ng-select>
                </fieldset>

                <ng-container *ngIf="isExportFormatXml">
                    <fieldset class="form-check mt-3">
                        <label>
                            <input class="form-check-input" type="checkbox" formControlName="showEmptyTags">
                            Show Empty Tags
                        </label>
                    </fieldset>

                    <fieldset class="form-check mt-3">
                        <label>
                            <input class="form-check-input" type="checkbox" formControlName="showEmptyParentTags">
                            Show Parents of Empty Tags
                        </label>
                    </fieldset>

                    <fieldset class="form-check mt-3">
                        <label>
                            <input class="form-check-input" type="checkbox" formControlName="useCdata">
                            Wrap content in CDATA
                        </label>
                    </fieldset>

                    <fieldset class="form-check mt-3">
                        <label>
                            <input class="form-check-input" type="checkbox" formControlName="xmlWriteDocumentTag">
                            XML Write Document Tag
                        </label>
                    </fieldset>

                </ng-container>
            </div>
        </fieldset>
    </form>

</fdx-form-section>
