/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-require-imports */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import * as angular from 'angular';
import { MODULE_NAME } from './config/constants';

// NPM AngularJS modules
import '@pioug/md-virtual-repeater';
import angularUIRouterModuleName from '@uirouter/angularjs';
import * as angularBlockUIModuleName from 'angular-block-ui';
import * as angularCookiesModuleName from 'angular-cookies';
import * as angularSanitizeUIModuleName from 'angular-sanitize';
import * as angularUIBootstrapModuleName from 'angular-ui-bootstrap';

// Fdx AngularJS Modules
import fdxModalModuleName from '../modules/modal';

// AngularJS Modules non-NPM
import './vendor/angular-file-upload.js';
import './vendor/angularUtils/pagination/dirPagination.js';
import './vendor/ngToast/ngToast.min.js';
import './vendor/ui-codemirror/ui-codemirror.js';
import './vendor/ui-select/select.min.js';
import './vendor/ui-sortable/sortable.js';

// Misc
import './vendor/feedonomics/utilities.js';

angular.module(MODULE_NAME,
    [
        angularSanitizeUIModuleName,
        angularUIBootstrapModuleName,
        angularUIRouterModuleName,
        angularCookiesModuleName,
        angularBlockUIModuleName,
        fdxModalModuleName,
        'ui.select',
        'angularFileUpload',
        'ui.codemirror',
        'angularUtils.directives.dirPagination',
        'ui.sortable',
        'ngToast',
        'virtualRepeat'
    ]
);
// Config blocks
angular.module(MODULE_NAME).config(require('./config/compile').default);
angular.module(MODULE_NAME).config(require('./config/blockUI').default);
angular.module(MODULE_NAME).config(require('./config/http').default);
angular.module(MODULE_NAME).config(require('./config/routes').default);
angular.module(MODULE_NAME).config(require('./config/uiSelect').default);

// Run blocks
angular.module(MODULE_NAME).run(require('./run/events').default);
angular.module(MODULE_NAME).run(require('./run/transitions').default);
