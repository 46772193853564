<fdx-well>
    <fdx-well-header>Choose FeedAI™ Attribution fields</fdx-well-header>

    <form [formGroup]="form">
        <div>
            <h5>Input Fields</h5>
            <div *ngFor="let inputField of inputFields" class="row px-3 mt-3">
                <label class="form-label text-capitalize">{{inputField.formattedFieldName}} Field Name</label>
                <ng-select [items]="databaseFieldNames"
                           [formControl]="inputField.control"
                           placeholder="Select / search for a field">
                </ng-select>
            </div>
        </div>

        <div class="mt-4">
            <h5>Output Fields</h5>

            <div class="row px-3 column-headers">
                <div class="col-5 form-label">Field</div>
                <div class="col-5 form-label">Attribute</div>
            </div>


            <div class="row px-3 mt-3" *ngFor="let outputField of outputFields; index as idx">
                <ng-select [clearable]="false"
                           [items]="databaseFieldNames"
                           [formControl]="outputField.fieldName"
                           class="col-5"
                           placeholder="Select / search for a field">
                </ng-select>

                <ng-select [clearable]="false"
                           [formControl]="outputField.attribute"
                           class="col-5"
                           placeholder="Select / search for an attribute">
                    <ng-option *ngFor="let attr of attributes" [value]="attr"><span class="text-capitalize">{{ formatFieldName(attr) }}</span></ng-option>
                </ng-select>

                <div class="col text-end">
                    <button type="button" class="btn btn-outline-primary" (click)="removeAttribute(idx)" ngbPopover="Remove attribute">
                        <fa-icon [icon]="iconTrashAlt" class="no-margin"></fa-icon>
                    </button>
                </div>
            </div>

            <div class="mt-3 px-3">
                <button type="button" class="btn btn-outline-primary" (click)="addAttribute()"><fa-icon [icon]="iconPlus"></fa-icon>Add Attribute</button>
            </div>
        </div>
    </form>

    <fdx-well-footer>
        <div class="d-flex gap-2">
            <button (click)="save()" class="btn btn-primary">Save</button>
            <button (click)="clearCache()" class="btn btn-link">Clear Cache</button>
        </div>
    </fdx-well-footer>
</fdx-well>
