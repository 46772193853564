import { ExportDestination } from '@app/exports/models/types/export-destination.type';

export const OTHER_EXPORT_DESTINATION: [string, ExportDestination] = [
    'Other', {
        value: 'Other',
        group: 'default',
        image: '/dist/app/assets/brands/icons/other.svg'
    }
];

const DEFAULT_EXPORT_DESTINATIONS: Map<string, ExportDestination> = new Map([
    ['Not applicable', {
        value: 'Not applicable',
        group: 'default',
        image: '/dist/app/assets/brands/icons/not_applicable.svg'
    }],
    [...OTHER_EXPORT_DESTINATION]
]);

const COMMON_EXPORT_DESTINATIONS: Map<string, ExportDestination> = new Map([
    ['Amazon Seller Central (FBA & FBM)', {
        value: 'Amazon Seller Central (FBA & FBM)',
        group: 'mostCommon',
        image: '/dist/app/assets/brands/icons/amazon.svg'
    }],
    ['Amazon Seller Central (FBA)', {
        value: 'Amazon Seller Central (FBA)',
        group: 'mostCommon',
        image: '/dist/app/assets/brands/icons/amazon.svg'
    }],
    ['Amazon Seller Central (FBM)', {
        value: 'Amazon Seller Central (FBM)',
        group: 'mostCommon',
        image: '/dist/app/assets/brands/icons/amazon.svg'
    }],
    ['Criteo', {
        value: 'Criteo',
        group: 'mostCommon',
        image: '/dist/app/assets/brands/icons/criteo.svg'
    }],
    ['eBay', {
        value: 'eBay',
        group: 'mostCommon',
        image: '/dist/app/assets/brands/icons/ebay.svg'
    }],
    ['Google Dynamic Text Ads', {
        value: 'Google Dynamic Text Ads',
        group: 'mostCommon',
        image: '/dist/app/assets/brands/icons/google.svg'
    }],
    ['Google Local Inventory Ads', {
        value: 'Google Local Inventory Ads',
        group: 'mostCommon',
        image: '/dist/app/assets/brands/icons/google.svg'
    }],
    ['Google Manufacturer Center', {
        value: 'Google Manufacturer Center',
        group: 'mostCommon',
        image: '/dist/app/assets/brands/icons/google.svg'
    }],
    ['Google Marketing Platform', {
        value: 'Google Marketing Platform',
        group: 'mostCommon',
        image: '/dist/app/assets/brands/icons/dv360_programmatic_ads.svg'
    }],
    ['Google Regional Availability and Pricing', {
        value: 'Google Regional Availability and Pricing',
        group: 'mostCommon',
        image: '/dist/app/assets/brands/icons/google.svg'
    }],
    ['Google Shopping Ads', {
        value: 'Google Shopping Ads',
        group: 'mostCommon',
        image: '/dist/app/assets/brands/icons/google.svg'
    }],
    ['Google Vehicle Ads', {
        value: 'Google Vehicle Ads',
        group: 'mostCommon',
        image: '/dist/app/assets/brands/icons/google.svg'
    }],
    ['Meta - Local Inventory Ads', {
        value: 'Meta - Local Inventory Ads',
        group: 'mostCommon',
        image: '/dist/app/assets/brands/icons/fb.svg'
    }],
    ['Meta - Facebook Automotive Inventory Ads', {
        value: 'Meta - Facebook Automotive Inventory Ads',
        group: 'mostCommon',
        image: '/dist/app/assets/brands/icons/fb.svg'
    }],
    ['Meta - Facebook/Instagram Ads', {
        value: 'Meta - Facebook/Instagram Ads',
        group: 'mostCommon',
        image: '/dist/app/assets/brands/icons/fb.svg'
    }],
    ['Meta - Facebook/Instagram Shops', {
        value: 'Meta - Facebook/Instagram Shops',
        group: 'mostCommon',
        image: '/dist/app/assets/brands/icons/fb.svg'
    }],
    ['Microsoft - Local Inventory Ads', {
        value: 'Microsoft - Local Inventory Ads',
        group: 'mostCommon',
        image: '/dist/app/assets/brands/icons/microsoft.svg'
    }],
    ['Microsoft Ads', {
        value: 'Microsoft Ads',
        group: 'mostCommon',
        image: '/dist/app/assets/brands/icons/microsoft.svg'
    }],
    ['Microsoft Automotive Ads', {
        value: 'Microsoft Automotive Ads',
        group: 'mostCommon',
        image: '/dist/app/assets/brands/icons/microsoft.svg'
    }],
    ['Pinterest', {
        value: 'Pinterest',
        group: 'mostCommon',
        image: '/dist/app/assets/brands/icons/pinterest.svg'
    }],
    ['Snapchat', {
        value: 'Snapchat',
        group: 'mostCommon',
        image: '/dist/app/assets/brands/icons/snapchat.svg'
    }],
    ['Target Plus', {
        value: 'Target Plus',
        group: 'mostCommon',
        image: '/dist/app/assets/brands/icons/target.svg'
    }],
    ['TikTok Ads', {
        value: 'TikTok Ads',
        group: 'mostCommon',
        image: '/dist/app/assets/brands/icons/tiktok.svg'
    }],
    ['TikTok Shop', {
        value: 'TikTok Shop',
        group: 'mostCommon',
        image: '/dist/app/assets/brands/icons/tiktok.svg'
    }],
    ['Walmart', {
        value: 'Walmart',
        group: 'mostCommon',
        image: '/dist/app/assets/brands/icons/walmart.svg'
    }]
]);

const GENERAL_EXPORT_DESTINATIONS: Map<string, ExportDestination> = new Map([
    ['1-800-Flowers.com', {
        value: '1-800-Flowers.com',
        group: 'general',
        image: '/dist/app/assets/brands/icons/1_800_flowers.svg'
    }],
    ['4Cite', {
        value: '4Cite',
        group: 'general',
        image: '/dist/app/assets/brands/icons/4cite.svg'
    }],
    ['About You', {
        value: 'About You',
        group: 'general',
        image: '/dist/app/assets/brands/icons/about_you.svg'
    }],
    ['Adacado', {
        value: 'Adacado',
        group: 'general',
        image: '/dist/app/assets/brands/icons/adacado.svg'
    }],
    ['Adcrowd', {
        value: 'Adcrowd',
        group: 'general',
        image: '/dist/app/assets/brands/icons/adcrowd.svg'
    }],
    ['Adobe Target', {
        value: 'Adobe Target',
        group: 'general',
        image: '/dist/app/assets/brands/icons/adobe.svg'
    }],
    ['AdRoll', {
        value: 'AdRoll',
        group: 'general',
        image: '/dist/app/assets/brands/icons/adroll.svg'
    }],
    ['Affiliate Future', {
        value: 'Affiliate Future',
        group: 'general',
        image: '/dist/app/assets/brands/icons/affiliate_future.svg'
    }],
    ['Afound', {
        value: 'Afound',
        group: 'general',
        image: '/dist/app/assets/brands/icons/afound.svg'
    }],
    ['Agorapulse', {
        value: 'Agorapulse',
        group: 'general',
        image: '/dist/app/assets/brands/icons/agorapulse.svg'
    }],
    ['Åhléns', {
        value: 'Åhléns',
        group: 'general',
        image: '/dist/app/assets/brands/icons/ahlens.svg'
    }],
    ['Alltricks', {
        value: 'Alltricks',
        group: 'general',
        image: '/dist/app/assets/brands/icons/alltricks.svg'
    }],
    ['Altitude Sports', {
        value: 'Altitude Sports',
        group: 'general',
        image: '/dist/app/assets/brands/icons/altitude_sports.svg'
    }],
    ['Amazon Local Selling', {
        value: 'Amazon Local Selling',
        group: 'general',
        image: '/dist/app/assets/brands/icons/amazon.svg'
    }],
    ['Amazon Vendor Central', {
        value: 'Amazon Vendor Central',
        group: 'general',
        image: '/dist/app/assets/brands/icons/amazon.svg'
    }],
    ['Ammobuy.com', {
        value: 'Ammobuy.com',
        group: 'general',
        image: '/dist/app/assets/brands/icons/ammobuy.com.svg'
    }],
    ['AmmoNow', {
        value: 'AmmoNow',
        group: 'general',
        image: '/dist/app/assets/brands/icons/ammonow.svg'
    }],
    ['AmmoSeek', {
        value: 'AmmoSeek',
        group: 'general',
        image: '/dist/app/assets/brands/icons/ammoseek.svg'
    }],
    ['Ashley HomeStore', {
        value: 'Ashley HomeStore',
        group: 'general',
        image: '/dist/app/assets/brands/icons/ashley_homestore.svg'
    }],
    ['Atlas For Men', {
        value: 'Atlas For Men',
        group: 'general',
        image: '/dist/app/assets/brands/icons/atlas_for_men.svg'
    }],
    ['Attentive', {
        value: 'Attentive',
        group: 'general',
        image: '/dist/app/assets/brands/icons/attentive.svg'
    }],
    ['Auchan', {
        value: 'Auchan',
        group: 'general',
        image: '/dist/app/assets/brands/icons/auchan.svg'
    }],
    ['Aurgi', {
        value: 'Aurgi',
        group: 'general',
        image: '/dist/app/assets/brands/icons/aurgi.svg'
    }],
    ['AvantLink', {
        value: 'AvantLink',
        group: 'general',
        image: '/dist/app/assets/brands/icons/avantlink.svg'
    }],
    ['AWIN', {
        value: 'AWIN',
        group: 'general',
        image: '/dist/app/assets/brands/icons/awin.svg'
    }],
    ['B&Q', {
        value: 'B&Q',
        group: 'general',
        image: '/dist/app/assets/brands/icons/b&q.svg'
    }],
    ['B2W Digital', {
        value: 'B2W Digital',
        group: 'general',
        image: '/dist/app/assets/brands/icons/b2w.svg'
    }],
    ['Basketful', {
        value: 'Basketful',
        group: 'general',
        image: '/dist/app/assets/brands/icons/basketful.svg'
    }],
    ['BazaarVoice', {
        value: 'BazaarVoice',
        group: 'general',
        image: '/dist/app/assets/brands/icons/bazaarvoice.svg'
    }],
    ['Bazar de l\'Hôtel de Ville', {
        value: 'Bazar de l\'Hôtel de Ville',
        group: 'general',
        image: '/dist/app/assets/brands/icons/bhv.svg'
    }],
    ['Belboon', {
        value: 'Belboon',
        group: 'general',
        image: '/dist/app/assets/brands/icons/belboon.svg'
    }],
    ['Beslist.nl', {
        value: 'Beslist.nl',
        group: 'general',
        image: '/dist/app/assets/brands/icons/beslist.nl.svg'
    }],
    ['Best Buy CA', {
        value: 'Best Buy CA',
        group: 'general',
        image: '/dist/app/assets/brands/icons/best_buy.svg'
    }],
    ['Better Homes & Gardens', {
        value: 'Better Homes & Gardens',
        group: 'general',
        image: '/dist/app/assets/brands/icons/better_homes_&_gardens.svg'
    }],
    ['BigCommerce', {
        value: 'BigCommerce',
        group: 'general',
        image: '/dist/app/assets/brands/icons/bigcommerce.svg'
    }],
    ['Billiger.de', {
        value: 'Billiger.de',
        group: 'general',
        image: '/dist/app/assets/brands/icons/billiger.de.svg'
    }],
    ['Blokker', {
        value: 'Blokker',
        group: 'general',
        image: '/dist/app/assets/brands/icons/blokker.svg'
    }],
    ['Bloomingdales', {
        value: 'Bloomingdales',
        group: 'general',
        image: '/dist/app/assets/brands/icons/bloomindales.svg'
    }],
    ['Bloomreach', {
        value: 'Bloomreach',
        group: 'general',
        image: '/dist/app/assets/brands/icons/bloomreach.svg'
    }],
    ['Bob\'s Discount Furniture', {
        value: 'Bob\'s Discount Furniture',
        group: 'general',
        image: '/dist/app/assets/brands/icons/bobs_discount_furniture.svg'
    }],
    ['Bol.com', {
        value: 'Bol.com',
        group: 'general',
        image: '/dist/app/assets/brands/icons/bol.com.svg'
    }],
    ['Boulanger', {
        value: 'Boulanger',
        group: 'general',
        image: '/dist/app/assets/brands/icons/boulanger.svg'
    }],
    ['Brico Privé', {
        value: 'Brico Privé',
        group: 'general',
        image: '/dist/app/assets/brands/icons/brico_prive.svg'
    }],
    ['Bricomarche', {
        value: 'Bricomarche',
        group: 'general',
        image: '/dist/app/assets/brands/icons/bricomarche.svg'
    }],
    ['Bulevip', {
        value: 'Bulevip',
        group: 'general',
        image: '/dist/app/assets/brands/icons/bulevip.svg'
    }],
    ['BulkAmmo', {
        value: 'BulkAmmo',
        group: 'general',
        image: '/dist/app/assets/brands/icons/bulkammo.svg'
    }],
    ['Bunnings', {
        value: 'Bunnings',
        group: 'general',
        image: '/dist/app/assets/brands/icons/bunnings.svg'
    }],
    ['Campadre', {
        value: 'Campadre',
        group: 'general',
        image: '/dist/app/assets/brands/icons/campadre.svg'
    }],
    ['Carethy', {
        value: 'Carethy',
        group: 'general',
        image: '/dist/app/assets/brands/icons/carethy.svg'
    }],
    ['Carrefour', {
        value: 'Carrefour',
        group: 'general',
        image: '/dist/app/assets/brands/icons/carrefour.svg'
    }],
    ['Cartful', {
        value: 'Cartful',
        group: 'general',
        image: '/dist/app/assets/brands/icons/cartful.svg'
    }],
    ['Casa del Libro', {
        value: 'Casa del Libro',
        group: 'general',
        image: '/dist/app/assets/brands/icons/casa_del_libro.svg'
    }],
    ['Catch', {
        value: 'Catch',
        group: 'general',
        image: '/dist/app/assets/brands/icons/catch.svg'
    }],
    ['Ceneo', {
        value: 'Ceneo',
        group: 'general',
        image: '/dist/app/assets/brands/icons/ceneo.svg'
    }],
    ['Cenowarka', {
        value: 'Cenowarka',
        group: 'general',
        image: '/dist/app/assets/brands/icons/cenowarka.svg'
    }],
    ['Certona', {
        value: 'Certona',
        group: 'general',
        image: '/dist/app/assets/brands/icons/certona.svg'
    }],
    ['Choozen', {
        value: 'Choozen',
        group: 'general',
        image: '/dist/app/assets/brands/icons/choozen.svg'
    }],
    ['CJ Affiliate', {
        value: 'CJ Affiliate',
        group: 'general',
        image: '/dist/app/assets/brands/icons/cj_affiliate.svg'
    }],
    ['Coin', {
        value: 'Coin',
        group: 'general',
        image: '/dist/app/assets/brands/icons/coin.svg'
    }],
    ['Commerce Connector', {
        value: 'Commerce Connector',
        group: 'general',
        image: '/dist/app/assets/brands/icons/commerce_connector.svg'
    }],
    ['Comparer.be', {
        value: 'Comparer.be',
        group: 'general',
        image: '/dist/app/assets/brands/icons/comparer.be.svg'
    }],
    ['Conforama', {
        value: 'Conforama',
        group: 'general',
        image: '/dist/app/assets/brands/icons/conforama.svg'
    }],
    ['Connexity', {
        value: 'Connexity',
        group: 'general',
        image: '/dist/app/assets/brands/icons/connexity.svg'
    }],
    ['Coppel', {
        value: 'Coppel',
        group: 'general',
        image: '/dist/app/assets/brands/icons/coppel.svg'
    }],
    ['Coravin', {
        value: 'Coravin',
        group: 'general',
        image: '/dist/app/assets/brands/icons/coravin.svg'
    }],
    ['Cordial', {
        value: 'Cordial',
        group: 'general',
        image: '/dist/app/assets/brands/icons/cordial.svg'
    }],
    ['Creatable', {
        value: 'Creatable',
        group: 'general',
        image: '/dist/app/assets/brands/icons/creatable.svg'
    }],
    ['Creavea', {
        value: 'Creavea',
        group: 'general',
        image: '/dist/app/assets/brands/icons/creavea.svg'
    }],
    ['Crobox', {
        value: 'Crobox',
        group: 'general',
        image: '/dist/app/assets/brands/icons/crobox.svg'
    }],
    ['Crowdfox', {
        value: 'Crowdfox',
        group: 'general',
        image: '/dist/app/assets/brands/icons/crowdfox.svg'
    }],
    ['Cultura', {
        value: 'Cultura',
        group: 'general',
        image: '/dist/app/assets/brands/icons/cultura.svg'
    }],
    ['Curalate', {
        value: 'Curalate',
        group: 'general',
        image: '/dist/app/assets/brands/icons/curalate.svg'
    }],
    ['Daisycon', {
        value: 'Daisycon',
        group: 'general',
        image: '/dist/app/assets/brands/icons/daisycon.svg'
    }],
    ['Darty', {
        value: 'Darty',
        group: 'general',
        image: '/dist/app/assets/brands/icons/darty.svg'
    }],
    ['Debenhems', {
        value: 'Debenhems',
        group: 'general',
        image: '/dist/app/assets/brands/icons/debenhems.svg'
    }],
    ['Decathlon', {
        value: 'Decathlon',
        group: 'general',
        image: '/dist/app/assets/brands/icons/decathalon.svg'
    }],
    ['Destini', {
        value: 'Destini',
        group: 'general',
        image: '/dist/app/assets/brands/icons/destini.svg'
    }],
    ['Dia & Co', {
        value: 'Dia & Co',
        group: 'general',
        image: '/dist/app/assets/brands/icons/dia_&_co.svg'
    }],
    ['Digi-Key', {
        value: 'Digi-Key',
        group: 'general',
        image: '/dist/app/assets/brands/icons/digi_key.svg'
    }],
    ['Domodi', {
        value: 'Domodi',
        group: 'general',
        image: '/dist/app/assets/brands/icons/domodi.svg'
    }],
    ['Doordash', {
        value: 'Doordash',
        group: 'general',
        image: '/dist/app/assets/brands/icons/doordash.svg'
    }],
    ['Dooyoo', {
        value: 'Dooyoo',
        group: 'general',
        image: '/dist/app/assets/brands/icons/dooyoo.svg'
    }],
    ['Dormify', {
        value: 'Dormify',
        group: 'general',
        image: '/dist/app/assets/brands/icons/dormify.svg'
    }],
    ['Dotdash Meredith', {
        value: 'Dotdash Meredith',
        group: 'general',
        image: '/dist/app/assets/brands/icons/dotdash_meredith.svg'
    }],
    ['Douglas Group', {
        value: 'Douglas Group',
        group: 'general',
        image: '/dist/app/assets/brands/icons/douglas_group.svg'
    }],
    ['Dynamic Yield', {
        value: 'Dynamic Yield',
        group: 'general',
        image: '/dist/app/assets/brands/icons/dynamic_yield.svg'
    }],
    ['eBay Commerce Network', {
        value: 'eBay Commerce Network',
        group: 'general',
        image: '/dist/app/assets/brands/icons/ebay.svg'
    }],
    ['eBay Motors', {
        value: 'eBay Motors',
        group: 'general',
        image: '/dist/app/assets/brands/icons/ebay.svg'
    }],
    ['El Corte Inglés', {
        value: 'El Corte Inglés',
        group: 'general',
        image: '/dist/app/assets/brands/icons/el_corte_ingles.svg'
    }],
    ['Elkjøp', {
        value: 'Elkjøp',
        group: 'general',
        image: '/dist/app/assets/brands/icons/elkjop.svg'
    }],
    ['EmarSys', {
        value: 'EmarSys',
        group: 'general',
        image: '/dist/app/assets/brands/icons/emarsys.svg'
    }],
    ['Empik', {
        value: 'Empik',
        group: 'general',
        image: '/dist/app/assets/brands/icons/empik.svg'
    }],
    ['Emplifi', {
        value: 'Emplifi',
        group: 'general',
        image: '/dist/app/assets/brands/icons/emplifi.svg'
    }],
    ['Empreintes', {
        value: 'Empreintes',
        group: 'general',
        image: '/dist/app/assets/brands/icons/empreintes.svg'
    }],
    ['Eobuwie', {
        value: 'Eobuwie',
        group: 'general',
        image: '/dist/app/assets/brands/icons/eobuwie.svg'
    }],
    ['ePRICE', {
        value: 'ePRICE',
        group: 'general',
        image: '/dist/app/assets/brands/icons/eprice.svg'
    }],
    ['Etsy', {
        value: 'Etsy',
        group: 'general',
        image: '/dist/app/assets/brands/icons/etsy.svg'
    }],
    ['Express', {
        value: 'Express',
        group: 'general',
        image: '/dist/app/assets/brands/icons/express.svg'
    }],
    ['FamilyBlend', {
        value: 'FamilyBlend',
        group: 'general',
        image: '/dist/app/assets/brands/icons/familyblend.svg'
    }],
    ['Fanatics', {
        value: 'Fanatics',
        group: 'general',
        image: '/dist/app/assets/brands/icons/fanatics.svg'
    }],
    ['Fashionchick', {
        value: 'Fashionchick',
        group: 'general',
        image: '/dist/app/assets/brands/icons/fashionchick.svg'
    }],
    ['FeelUnique', {
        value: 'FeelUnique',
        group: 'general',
        image: '/dist/app/assets/brands/icons/feelunique.svg'
    }],
    ['FindGift.com', {
        value: 'FindGift.com',
        group: 'general',
        image: '/dist/app/assets/brands/icons/findgift.com.svg'
    }],
    ['flexEngage', {
        value: 'flexEngage',
        group: 'general',
        image: '/dist/app/assets/brands/icons/flexengage.svg'
    }],
    ['Fnac', {
        value: 'Fnac',
        group: 'general',
        image: '/dist/app/assets/brands/icons/fnac.svg'
    }],
    ['fonQ', {
        value: 'fonQ',
        group: 'general',
        image: '/dist/app/assets/brands/icons/fonq.svg'
    }],
    ['Fruugo', {
        value: 'Fruugo',
        group: 'general',
        image: '/dist/app/assets/brands/icons/fruugo.svg'
    }],
    ['Fyndiq', {
        value: 'Fyndiq',
        group: 'general',
        image: '/dist/app/assets/brands/icons/fyndiq.svg'
    }],
    ['Galeries Lafayette', {
        value: 'Galeries Lafayette',
        group: 'general',
        image: '/dist/app/assets/brands/icons/galeries_lafayette.svg'
    }],
    ['Geizhals', {
        value: 'Geizhals',
        group: 'general',
        image: '/dist/app/assets/brands/icons/geizhals.svg'
    }],
    ['Getprice', {
        value: 'Getprice',
        group: 'general',
        image: '/dist/app/assets/brands/icons/getprice.svg'
    }],
    ['Giant Eagle', {
        value: 'Giant Eagle',
        group: 'general',
        image: '/dist/app/assets/brands/icons/giant_eagle.svg'
    }],
    ['Gilt', {
        value: 'Gilt',
        group: 'general',
        image: '/dist/app/assets/brands/icons/gilt.svg'
    }],
    ['Gun Coyote', {
        value: 'Gun Coyote',
        group: 'general',
        image: '/dist/app/assets/brands/icons/gun_coyote.svg'
    }],
    ['Gun Made', {
        value: 'Gun Made',
        group: 'general',
        image: '/dist/app/assets/brands/icons/gun_made.svg'
    }],
    ['Gun.Deals', {
        value: 'Gun.Deals',
        group: 'general',
        image: '/dist/app/assets/brands/icons/gun.deals.svg'
    }],
    ['GunCritic.com', {
        value: 'GunCritic.com',
        group: 'general',
        image: '/dist/app/assets/brands/icons/guncritic.com.svg'
    }],
    ['High Cap Deals', {
        value: 'High Cap Deals',
        group: 'general',
        image: '/dist/app/assets/brands/icons/high_cap_deal.svg'
    }],
    ['Holt Renfrew', {
        value: 'Holt Renfrew',
        group: 'general',
        image: '/dist/app/assets/brands/icons/holt_renfrew.svg'
    }],
    ['Houzz', {
        value: 'Houzz',
        group: 'general',
        image: '/dist/app/assets/brands/icons/houzz.svg'
    }],
    ['iAdvize', {
        value: 'iAdvize',
        group: 'general',
        image: '/dist/app/assets/brands/icons/iadvize.svg'
    }],
    ['Impact Radius', {
        value: 'Impact Radius',
        group: 'general',
        image: '/dist/app/assets/brands/icons/impact_radius.svg'
    }],
    ['Increasingly', {
        value: 'Increasingly',
        group: 'general',
        image: '/dist/app/assets/brands/icons/increasingly.svg'
    }],
    ['Instacart', {
        value: 'Instacart',
        group: 'general',
        image: '/dist/app/assets/brands/icons/instacart.svg'
    }],
    ['Kenshoo', {
        value: 'Kenshoo',
        group: 'general',
        image: '/dist/app/assets/brands/icons/kenshoo.svg'
    }],
    ['Kijiji', {
        value: 'Kijiji',
        group: 'general',
        image: '/dist/app/assets/brands/icons/kijiji.svg'
    }],
    ['Klarna', {
        value: 'Klarna',
        group: 'general',
        image: '/dist/app/assets/brands/icons/klarna.svg'
    }],
    ['Klaviyo', {
        value: 'Klaviyo',
        group: 'general',
        image: '/dist/app/assets/brands/icons/klaviyo.svg'
    }],
    ['Kogan', {
        value: 'Kogan',
        group: 'general',
        image: '/dist/app/assets/brands/icons/kogan.svg'
    }],
    ['Kohl\'s', {
        value: 'Kohl\'s',
        group: 'general',
        image: '/dist/app/assets/brands/icons/kohls.svg'
    }],
    ['Kroger', {
        value: 'Kroger',
        group: 'general',
        image: '/dist/app/assets/brands/icons/kroger.svg'
    }],
    ['La Redoute', {
        value: 'La Redoute',
        group: 'general',
        image: '/dist/app/assets/brands/icons/la_redoute.svg'
    }],
    ['Lily AI', {
        value: 'Lily AI',
        group: 'general',
        image: '/dist/app/assets/brands/icons/lily_ai.svg'
    }],
    ['LinkConnector', {
        value: 'LinkConnector',
        group: 'general',
        image: '/dist/app/assets/brands/icons/linkconnector.svg'
    }],
    ['Lisa', {
        value: 'Lisa',
        group: 'general',
        image: '/dist/app/assets/brands/icons/lisa.svg'
    }],
    ['Listrak', {
        value: 'Listrak',
        group: 'general',
        image: '/dist/app/assets/brands/icons/listrack.svg'
    }],
    ['LiveClicker', {
        value: 'LiveClicker',
        group: 'general',
        image: '/dist/app/assets/brands/icons/liveclicker.svg'
    }],
    ['Locally', {
        value: 'Locally',
        group: 'general',
        image: '/dist/app/assets/brands/icons/locally.svg'
    }],
    ['Lyst', {
        value: 'Lyst',
        group: 'general',
        image: '/dist/app/assets/brands/icons/lyst.svg'
    }],
    ['Macy\'s', {
        value: 'Macy\'s',
        group: 'general',
        image: '/dist/app/assets/brands/icons/macys.svg'
    }],
    ['Mercado Libre', {
        value: 'Mercado Libre',
        group: 'general',
        image: '/dist/app/assets/brands/icons/mercado_libre.svg'
    }],
    ['MikMak', {
        value: 'MikMak',
        group: 'general',
        image: '/dist/app/assets/brands/icons/mikmak.svg'
    }],
    ['Moloco', {
        value: 'Moloco',
        group: 'general',
        image: '/dist/app/assets/brands/icons/moloco.svg'
    }],
    ['Monetate', {
        value: 'Monetate',
        group: 'general',
        image: '/dist/app/assets/brands/icons/monetate.svg'
    }],
    ['Movable Ink', {
        value: 'Movable Ink',
        group: 'general',
        image: '/dist/app/assets/brands/icons/movable_ink.svg'
    }],
    ['Narrativ', {
        value: 'Narrativ',
        group: 'general',
        image: '/dist/app/assets/brands/icons/narrativ.svg'
    }],
    ['Narvar', {
        value: 'Narvar',
        group: 'general',
        image: '/dist/app/assets/brands/icons/narvar.svg'
    }],
    ['NewEgg', {
        value: 'NewEgg',
        group: 'general',
        image: '/dist/app/assets/brands/icons/newegg.svg'
    }],
    ['Nextag', {
        value: 'Nextag',
        group: 'general',
        image: '/dist/app/assets/brands/icons/nextag.svg'
    }],
    ['Nordstrom', {
        value: 'Nordstrom',
        group: 'general',
        image: '/dist/app/assets/brands/icons/nordstrom.svg'
    }],
    ['Oracle Responsys', {
        value: 'Oracle Responsys',
        group: 'general',
        image: '/dist/app/assets/brands/icons/oracle.svg'
    }],
    ['OrderGroove', {
        value: 'OrderGroove',
        group: 'general',
        image: '/dist/app/assets/brands/icons/ordergroove.svg'
    }],
    ['Oriental Trading', {
        value: 'Oriental Trading',
        group: 'general',
        image: '/dist/app/assets/brands/icons/oriental_trading.svg'
    }],
    ['Overstock', {
        value: 'Overstock',
        group: 'general',
        image: '/dist/app/assets/brands/icons/overstock.svg'
    }],
    ['Partnerize', {
        value: 'Partnerize',
        group: 'general',
        image: '/dist/app/assets/brands/icons/partnerize.svg'
    }],
    ['Parts Town', {
        value: 'Parts Town',
        group: 'general',
        image: '/dist/app/assets/brands/icons/parts_town.svg'
    }],
    ['Peapod', {
        value: 'Peapod',
        group: 'general',
        image: '/dist/app/assets/brands/icons/peapod.svg'
    }],
    ['Pepperjam', {
        value: 'Pepperjam',
        group: 'general',
        image: '/dist/app/assets/brands/icons/pepperjam.svg'
    }],
    ['Pricegrabber', {
        value: 'Pricegrabber',
        group: 'general',
        image: '/dist/app/assets/brands/icons/pricegrabber.svg'
    }],
    ['Pricesearcher', {
        value: 'Pricesearcher',
        group: 'general',
        image: '/dist/app/assets/brands/icons/latestdeals.svg'
    }],
    ['PriceSpider', {
        value: 'PriceSpider',
        group: 'general',
        image: '/dist/app/assets/brands/icons/pricespider.svg'
    }],
    ['PRINTEMPS', {
        value: 'PRINTEMPS',
        group: 'general',
        image: '/dist/app/assets/brands/icons/printemps.svg'
    }],
    ['ProductsUp', {
        value: 'ProductsUp',
        group: 'general',
        image: '/dist/app/assets/brands/icons/productsup.svg'
    }],
    ['Rakuten Advertising', {
        value: 'Rakuten Advertising',
        group: 'general',
        image: '/dist/app/assets/brands/icons/rakuten_purple.svg'
    }],
    ['Raptive', {
        value: 'Raptive',
        group: 'general',
        image: '/dist/app/assets/brands/icons/raptive.svg'
    }],
    ['Reddit', {
        value: 'Reddit',
        group: 'general',
        image: '/dist/app/assets/brands/icons/reddit.svg'
    }],
    ['Refersion', {
        value: 'Refersion',
        group: 'general',
        image: '/dist/app/assets/brands/icons/refersion.svg'
    }],
    ['Reflektion', {
        value: 'Reflektion',
        group: 'general',
        image: '/dist/app/assets/brands/icons/reflektion.svg'
    }],
    ['Reitmans', {
        value: 'Reitmans',
        group: 'general',
        image: '/dist/app/assets/brands/icons/reitmans.svg'
    }],
    ['Rithum (fka ChannelAdvisor)', {
        value: 'Rithum (fka ChannelAdvisor)',
        group: 'general',
        image: '/dist/app/assets/brands/icons/rithum.svg'
    }],
    ['Sailthru', {
        value: 'Sailthru',
        group: 'general',
        image: '/dist/app/assets/brands/icons/sailthru.svg'
    }],
    ['Saks Fifth Avenue', {
        value: 'Saks Fifth Avenue',
        group: 'general',
        image: '/dist/app/assets/brands/icons/saks_fifth_avenue.svg'
    }],
    ['SalonCentric', {
        value: 'SalonCentric',
        group: 'general',
        image: '/dist/app/assets/brands/icons/saloncentric.svg'
    }],
    ['Salsify', {
        value: 'Salsify',
        group: 'general',
        image: '/dist/app/assets/brands/icons/salsify.svg'
    }],
    ['Searchspring', {
        value: 'Searchspring',
        group: 'general',
        image: '/dist/app/assets/brands/icons/searchspring.svg'
    }],
    ['ShareASale', {
        value: 'ShareASale',
        group: 'general',
        image: '/dist/app/assets/brands/icons/shareasale.svg'
    }],
    ['Shein', {
        value: 'Shein',
        group: 'general',
        image: '/dist/app/assets/brands/icons/shein.svg'
    }],
    ['Shopify', {
        value: 'Shopify',
        group: 'general',
        image: '/dist/app/assets/brands/icons/shopify.svg'
    }],
    ['ShopStyle', {
        value: 'ShopStyle',
        group: 'general',
        image: '/dist/app/assets/brands/icons/shopstyle.svg'
    }],
    ['SIMON Shop Premium Outlets (SPO)', {
        value: 'SIMON Shop Premium Outlets (SPO)',
        group: 'general',
        image: '/dist/app/assets/brands/icons/shop_premium_outlets.svg'
    }],
    ['SmartCommerce', {
        value: 'SmartCommerce',
        group: 'general',
        image: '/dist/app/assets/brands/icons/smartcommerce.svg'
    }],
    ['Social Native', {
        value: 'Social Native',
        group: 'general',
        image: '/dist/app/assets/brands/icons/social_native.svg'
    }],
    ['Sociomantic', {
        value: 'Sociomantic',
        group: 'general',
        image: '/dist/app/assets/brands/icons/sociomantic.svg'
    }],
    ['Southern Glazer\'s Wine & Spirits', {
        value: 'Southern Glazer\'s Wine & Spirits',
        group: 'general',
        image: '/dist/app/assets/brands/icons/southern_glazers.svg'
    }],
    ['StitcherAds', {
        value: 'StitcherAds',
        group: 'general',
        image: '/dist/app/assets/brands/icons/stitcherads.svg'
    }],
    ['Stylitics', {
        value: 'Stylitics',
        group: 'general',
        image: '/dist/app/assets/brands/icons/stylitics.svg'
    }],
    ['SundaySky', {
        value: 'SundaySky',
        group: 'general',
        image: '/dist/app/assets/brands/icons/sundaysky.svg'
    }],
    ['The Bay', {
        value: 'The Bay',
        group: 'general',
        image: '/dist/app/assets/brands/icons/hudsons_bay.svg'
    }],
    ['Tiendamia', {
        value: 'Tiendamia',
        group: 'general',
        image: '/dist/app/assets/brands/icons/tiendamia.svg'
    }],
    ['Trademe', {
        value: 'Trademe',
        group: 'general',
        image: '/dist/app/assets/brands/icons/trademe.svg'
    }],
    ['Trustpilot', {
        value: 'Trustpilot',
        group: 'general',
        image: '/dist/app/assets/brands/icons/trustpilot.svg'
    }],
    ['Uber', {
        value: 'Uber',
        group: 'general',
        image: '/dist/app/assets/brands/icons/uber.svg'
    }],
    ['Urban Outfitters', {
        value: 'Urban Outfitters',
        group: 'general',
        image: '/dist/app/assets/brands/icons/urban_outfitters.svg'
    }],
    ['Vivino', {
        value: 'Vivino',
        group: 'general',
        image: '/dist/app/assets/brands/icons/vivino.svg'
    }],
    ['Walmart Localfinds', {
        value: 'Walmart Localfinds',
        group: 'general',
        image: '/dist/app/assets/brands/icons/walmart.svg'
    }],
    ['Walmart WFS', {
        value: 'Walmart WFS',
        group: 'general',
        image: '/dist/app/assets/brands/icons/walmart.svg'
    }],
    ['WhatCounts', {
        value: 'WhatCounts',
        group: 'general',
        image: '/dist/app/assets/brands/icons/whatcounts.svg'
    }],
    ['WikiArms', {
        value: 'WikiArms',
        group: 'general',
        image: '/dist/app/assets/brands/icons/wikiarms.svg'
    }],
    ['Wish', {
        value: 'Wish',
        group: 'general',
        image: '/dist/app/assets/brands/icons/wish.svg'
    }],
    ['Wunderkind', {
        value: 'Wunderkind',
        group: 'general',
        image: '/dist/app/assets/brands/icons/wunderkind.svg'
    }],
    ['X', {
        value: 'X',
        group: 'general',
        image: '/dist/app/assets/brands/icons/x.svg'
    }],
    ['Yahoo Advertising', {
        value: 'Yahoo Advertising',
        group: 'general',
        image: '/dist/app/assets/brands/icons/yahoo.svg'
    }],
    ['Yotpo', {
        value: 'Yotpo',
        group: 'general',
        image: '/dist/app/assets/brands/icons/yotpo.svg'
    }],
    ['YouTube Shopping', {
        value: 'YouTube Shopping',
        group: 'general',
        image: '/dist/app/assets/brands/icons/youtube.svg'
    }],
    ['Zalando', {
        value: 'Zalando',
        group: 'general',
        image: '/dist/app/assets/brands/icons/zalando.svg'
    }],
    ['ZipRecruiter', {
        value: 'ZipRecruiter',
        group: 'general',
        image: '/dist/app/assets/brands/icons/ziprecruiter.svg'
    }]
]);

export const EXPORT_DESTINATION_MAP: Map<string, ExportDestination> = new Map([
    ...DEFAULT_EXPORT_DESTINATIONS.entries(),
    ...COMMON_EXPORT_DESTINATIONS.entries(),
    ...GENERAL_EXPORT_DESTINATIONS.entries()
]);
