import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { API_TIMEZONE } from '@app/core/models/constants/date-time';
import { DateTimeService } from '@app/core/services/date-time.service';
import dayjs, { Dayjs } from 'dayjs';


@Component({
    selector: 'fdx-date-time-localized',
    templateUrl: './date-time-localized.component.html',
    styleUrls: ['./date-time-localized.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateTimeLocalizedComponent implements OnChanges {

    @Input() dateTime: string | null = null;
    @Input() displayLocalTimezone: boolean = false;
    @Input() timezone: string | null = null;

    timeToDisplay: string = '';

    constructor(
        private readonly dateTimeService: DateTimeService
    ) {

    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes.dateTime || changes.displayLocalTimezone || changes.timezone) {
            this.processDateTime();
        }

    }

    private processDateTime(): void {

        const apiTime = this.dateTimeService.getDateTimeFromAPIValue(this.dateTime);

        if (this.displayLocalTimezone) {
            const time = dayjs.tz(apiTime).tz(dayjs.tz.guess());
            this.timeToDisplay = this.dateTimeService.getFormattedDateTime(time);
            return;
        }

        let time: Dayjs;

        if (this.timezone !== null) {
            time = dayjs.tz(apiTime).tz(this.timezone);
        } else {
            time = dayjs.tz(apiTime).tz(API_TIMEZONE);
        }

        this.timeToDisplay = this.dateTimeService.getFormattedDateTime(time);

    }

}
