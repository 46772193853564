import { IPromise } from 'angular';
import { ModalService } from '../../modules/modal';

import { componentName as shopifyInstructionsModalComponentName } from '../components/fdx-imports-page/components/fdx-imports-shopify-instructions-modal/fdx-imports-shopify-instructions-modal.component';

export default class ShopifyService {
    static $inject = [
        'ModalService'
    ];

    constructor(
        private ModalService: ModalService
    ) { }

    openHelpModal(): IPromise<any> {
        return this.ModalService.open(
            {
                component: shopifyInstructionsModalComponentName,
                windowClass: 'fdx-modal modal-dialog-centered',
                backdropClass: 'fdx-modal'
            }
        );
    }

    parseExampleUrl(url: string): { store_url: string, app_password: string } {
        let ret = {
            'store_url': '',
            'app_password': ''
        };

        try {
            const parsedUrl = new URL(url);

            if (!parsedUrl.origin || !parsedUrl.password) {
                throw 'Invalid URL';
            }

            ret['store_url'] = parsedUrl.origin;
            ret['app_password'] = parsedUrl.password;
        } catch (e) {
            // Silent
        }

        return ret;
    }

    showNewUI(accountId: number): boolean {
        return accountId > 2988;
    }
}