<div
    class="code-container"
    [ngClass]="classes">
    <ng-content></ng-content>
    <div class="fdx-code-toggle">
        <button
            *ngIf="showToggle"
            type="button"
            class="btn btn-outline-secondary"
            triggers="mouseenter:mouseleave"
            placement="right"
            [ngbTooltip]="toggleText"
            (click)="toggle()">
            <fa-icon
                class="no-margin"
                [icon]="icon">
            </fa-icon>
        </button>
    </div>
</div>
