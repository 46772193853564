import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'prettyHtml'
})
export class PrettyHtmlPipe implements PipeTransform {
    transform(html: string): string {
        if (!html.startsWith('<html>')) {
            return html;
        }

        const tab = '\t';
        let result = '';
        let indent = '';

        html.split(/>\s*</).forEach((element) => {
            if (element.match(/^\/\w/)) {
                indent = indent.substring(tab.length);
            }

            result += indent + '<' + element + '>\r\n';

            if (element.match(/^<?\w[^>]*[^\/]$/)) {
                indent += tab;
            }
        });

        return result.substring(1, result.length - 3);
    }
}