import { UserModel } from '@ajs/models/user';
import ImpersonateUserService from '@ajs/services/ImpersonateUserService';
import { Component, Input } from '@angular/core';
import { IconDefinition, faCircle } from '@fortawesome/pro-solid-svg-icons';

@Component({
    selector: 'fdx-user-initials',
    templateUrl: './user-initials.component.html',
    styleUrls: ['./user-initials.component.scss']
})
export class UserInitialsComponent {
    readonly avatarIcon: IconDefinition = faCircle;

    @Input() user?: UserModel;
    @Input() hasNotifications: boolean = false;

    get initials(): string {
        if (!this.user?.user_fetched) {
            return '';
        }

        return (this.user.first_name.substring(0, 1) + this.user.last_name.substring(0, 1)).toUpperCase();
    }

    get initialsClass(): string {
        if (!this.user?.user_fetched) {
            return 'user-not-fetched';
        }

        if (this.impersonateUserService.getUser()) {
            return 'external-user';
        }

        return this.user.isInternal() ? 'internal-user' : 'external-user';
    }

    constructor(
        private readonly impersonateUserService: ImpersonateUserService   // TODO: Upgrade to Angular
    ) { }
}
