<div [formGroup]="queryForm" *ngIf="formReady" class="position-relative">
    <div *ngFor="let innerGroup of queryForm.controls | keyvalue; let last = last">
        <fdx-basic-query-inner
            [dbFields]="dbFields"
            [inner]="innerGroup.key"
            [innerGroup]="controlAsFormGroup(innerGroup.value)"
            (addNewRowGroupClick)="addNewRowGroup(innerGroup.key)"
            (removeRowClick)="removeRowClick(innerGroup.key, $event)"
            (removeInnerClick)="removeInnerClick(innerGroup.key)"
            [disabled]="disabled" >
        </fdx-basic-query-inner>
        <div *ngIf="!last; else isLast" class="my-2 text-primary ms-2 ps-3 fdx-grouping-label-or-button">OR</div>
        <ng-template #isLast>
            <div class="mt-3 ms-3">
                <button
                    type="button"
                    class="btn btn-outline-primary fdx-grouping-label-or-button"
                    (click)="addNewInnerGroup()"
                    [disabled]="disabled" >
                    <fa-icon [icon]="iconPlus"></fa-icon>OR
                </button>
            </div>
        </ng-template>
    </div>
    <div *ngIf="shouldShowSpinner" class="position-absolute top-0 w-100 h-100 bg-white opacity-50">
        <fdx-spinner class="position-absolute top-50 start-50 translate-middle"></fdx-spinner>
    </div>
</div>
