import { Injectable } from '@angular/core';
import { ValidateTaxonomySummaryRequest } from '@app/analyze-data/services/requests/validate-taxonomy-summary.request';
import { FilterBySpecificRow, ValidateTaxonomyRequest } from '@app/analyze-data/services/requests/validate-taxonomy.request';
import { DataValidationFilteredRow, DataValidationSummarySearchFilters } from '@app/analyze-data/types/data-validation-filters';
import { DataParams } from '@app/data/types/data-page.types';

@Injectable({
    providedIn: 'root'
})
export class DvApiRequestFormatterService {
    /**
     * This method takes a filter object containing filter key value pairs where the values are booleans.
     * If the value is true, it gets added to the formatted request string.
     *
     * This formatted value is sent to the API
     *
     *  Example result: 'required,recommended,optional'
     * @param filter
     * @private
     */
    formatFilterRequestParam(filter: Record<string, boolean>): string {
        const results: string[] = [];
        for(const key of Object.keys(filter)) {
            if (filter[key]) { // if value is true, the filter has been applied
                results.push(key);
            }
        }

        return results.join(',');
    }

    /**
     * Format the API request param for the `filter_by_specific_rows` property
     * @param filteredRows
     */
    formatFilteredRowRequestParam(filteredRows: DataValidationFilteredRow[]): FilterBySpecificRow[] {
        return filteredRows.map((filteredRow) => {
            const result: FilterBySpecificRow = {
                column_name: filteredRow.name,
                column_type: filteredRow.column,
                issue_type: filteredRow.issue
            };
            return result;
        })
    }

    getValidateTaxonomyRequestParams(summaryFilter: DataValidationSummarySearchFilters, dataParams: DataParams): ValidateTaxonomyRequest {
        return {
            ...this.getValidateTaxonomyWithSummaryRequestParams(dataParams),
            ...(summaryFilter && { filter_by_field: summaryFilter.searchFields }),
            ...(summaryFilter?.filters && // filters are not sent to the api if we are filtering by specific rows
                {
                    filter_by_error_type: this.formatFilterRequestParam(summaryFilter.filters.issue),
                    filter_by_column_type: this.formatFilterRequestParam(summaryFilter.filters.condition)
                }
            ),
            ...(summaryFilter?.filteredRows && // filtered rows are not sent over if general filters are applied
                {
                    filter_by_specific_rows: this.formatFilteredRowRequestParam(summaryFilter.filteredRows)
                }
            )
        };
    }

    getValidateTaxonomyWithSummaryRequestParams(dataParams: DataParams): ValidateTaxonomySummaryRequest {
        return {
            ...(dataParams.selector && { selector: dataParams.selector }), // conditionally add the selector to the request
            offset: dataParams.offset,
            limit: dataParams.limit,
            sort_field: dataParams.sort_field,
            sort_direction: dataParams.sort_direction,
            last_sort_value: dataParams.last_sort_value,
            last_row_id: dataParams.last_row_id
        };
    }
}
