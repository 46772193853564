import AppStateService from '@ajs/services/AppStateService';
import DataDownloadService from '@ajs/services/DataDownloadService';
import { fdxUI as FdxUI } from '@ajs/services/fdxUI';
import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FdxUtilsService } from '@app/core/services/fdx-utils.service';
import { DelimiterType } from '@app/data/enums/DelimiterType';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs';

type Resolver = {
    dataParam: {
        channel: string;
    };
    filteredData: string[][];
    allData: string[][];
    header: string[];
    exportFields: string[];
    csvHeader: string[];
}

enum DownloadType {
    VISIBLE = 'visible',
    ALL = 'all'
}

type DataDownloadFormType = {
    delimiter: DelimiterType;
    downloadType: DownloadType;
}

@Component({
    selector: 'fdx-error-overview-download-modal',
    templateUrl: './error-overview-download-modal.component.html'
})
export class ErrorOverviewDownloadModalComponent {

    loading: boolean = false;

    resolve: Resolver;
    delimiterType: typeof DelimiterType = DelimiterType;

    get databaseId(): string {
        return this.appStateService.getDatabaseId();
    }

    readonly dataDownloadForm: UntypedFormGroup = new UntypedFormGroup({
        'delimiter': new UntypedFormControl(DelimiterType.Tab, Validators.required),
        'downloadType': new UntypedFormControl(DownloadType.VISIBLE, Validators.required),
    });

    get modalTitle(): string {
        return `Download error overview data for ${this.resolve.dataParam.channel}`;
    }

    constructor(
        private readonly fdxUI: FdxUI,
        private readonly dataDownloadService: DataDownloadService,
        private readonly fdxUtilsService: FdxUtilsService,
        private readonly appStateService: AppStateService,
        private readonly modal: NgbActiveModal,
    ) { }

    onModalSubmit(): void {
        const { delimiter, downloadType } = this.dataDownloadForm.value as DataDownloadFormType;
        const exprt: Resolver = this.resolve;

        this.loading = true;

        const fileExtenstion: string = this.fdxUtilsService.getFileExtensionFromDelimiter(delimiter);
        const filename: string = `${this.databaseId}_${dayjs().format('YYYYMMDD_HHmmss')}.${fileExtenstion}`;
        const data: string[][] = downloadType === DownloadType.VISIBLE ? exprt.filteredData : exprt.allData;
        const columns: string[] = exprt.header;


        this.dataDownloadService.downloadCSV(
            filename,
            delimiter,
            columns,
            data,
        );

        this.loading = false;

        this.modal.close();
        this.fdxUI.showToastSuccess('Download Successful');
    }

    onModalCancel(): void {
        this.modal.dismiss();
    }

    trackByIndex(index: number): number {
        return index;
    }
}
