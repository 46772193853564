
<div
    class="user-select-container"
    ngbTooltip="The user selected must have access to the store you want to submit a ticket for."
    placement="end">
    <label class="col-form-label fw-medium text-secondary position-absolute pe-1" for="effectiveUser">Submit request on behalf of:</label>
    <ng-select
        class="effective-user"
        id="effectiveUser"
        [items]="analystUsers"
        [loading]="loadingUsers"
        loadingText="loading contacts..."
        bindLabel="user_name"
        [(ngModel)]="selectedUser"
        (change)="onUserChange()"
        [clearable]="false"
        placeholder="select contact">
    </ng-select>
</div>
