import { Injectable } from '@angular/core';
import { CategoryPathCellType } from '@app/analyze-data/components/category-paths-cell-renderer/category-paths-cell-renderer.component';
import { CountryDropdownOption } from '@app/analyze-data/components/column-stats/column-stats.component';
import { COLUMN_STATS_EMPTY } from '@app/analyze-data/constants/analyze-data.constants';
import { CategoryPath } from '@app/category-taxonomy/services/responses/category-paths.response';
import { isEmpty } from '@app/core/functions/isEmpty';
import { ChannelClient } from '@app/core/models/enums/channel-client.enum';
import { IconDefinition, faCircleInfo, faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';

@Injectable({
    providedIn: 'root'
})
export class CategoryPathsUtilitiesService {

    pathIcon: IconDefinition = faCircleInfo;
    errorIcon: IconDefinition = faExclamationCircle;

    clientIsAmazon(client: string): boolean {
        return client === ChannelClient.Amazon;
    }

    clientIsGoogle(client: string): boolean {
        return client === ChannelClient.Google;
    }

    getDisplayedValue(config: CategoryPathCellType): string {
        return config.value === '' ? COLUMN_STATS_EMPTY : config.value;
    }

    getDisplayedPath(categoryPath: CategoryPath): string {
        return `${categoryPath.path}${(categoryPath.query ? ` | ${categoryPath.query}` : '')}`;
    }

    /** Should we display paths at all? In the case of Google, the paths are the same as the values.
     */
    shouldDisplayPath(country: CountryDropdownOption): boolean {
        return country ? !this.clientIsGoogle(country.client) : false;
    }

    /** Should we display the path along with the value? This is good for cases when there's only one path,
     * unlike with Amazon.
     */
    shouldDisplayPathInline(country: CountryDropdownOption, config: CategoryPathCellType): boolean {
        return this.shouldDisplayPath(country)
            && !isEmpty(config.categoryPaths)
            && !this.clientIsAmazon(country.client)
            && config.categoryPaths.length === 1;
    }

    /** Should we just display the path in a modal format? This is good for cases when there are multiple
     * paths, like with Amazon.
     */
    shouldDisplayPathModal(country: CountryDropdownOption, config: CategoryPathCellType): boolean {
        return this.shouldDisplayPath(country)
            && !isEmpty(config.categoryPaths)
            && (this.clientIsAmazon(country.client) || config.categoryPaths.length > 1);
    }

    getComparatorFn(): (valueA: CategoryPathCellType, valueB: CategoryPathCellType) => number {
        return (valueA: CategoryPathCellType, valueB: CategoryPathCellType): number => {
            const a: string = valueA.value.toLowerCase();
            const b: string = valueB.value.toLowerCase();
            if (a === b) {
                return 0;
            }
            return (a > b) ? 1 : -1;
        }
    }
}
