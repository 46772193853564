import FdxError from "../models/FdxError";

export default class AccountsDataService {
    // Services
    $http = null;
    $q = null;

    constructor($http, $q) {
        this.$http = $http;
        this.$q = $q;
    }


    getOneById(idAccount) {

        const def = this.$q.defer();

        const promise = this.$http.get('/api.php/accounts/' + idAccount);

        promise.then(
            (response) => {
                def.resolve(response.data);
            },
            (response) => {
                if (response.data?.status === 'fail') {
                    switch (response.data?.message) {
                        case 'insufficient_permission':
                            def.reject(new FdxError('ACCOUNT_INSUFFICIENT_PERMISSION', 'Insufficient permission'));
                            break;
                    }
                } else {
                    def.reject(response.data);
                }
            }
        );

        return def.promise;
    }

    getUserAccountPermissions(idAccount) {
        const def = this.$q.defer();

        const promise = this.$http.get('/api.php/accounts/' + idAccount + '/user_account_permissions');

        promise.then(
            (response) => {
                def.resolve(response.data);
            },
            (response) => {
                if (response.data?.status === 'fail') {
                    switch (response.data?.message) {
                        case 'insufficient_permission':
                            def.reject(new FdxError('ACCOUNT_INSUFFICIENT_PERMISSION', 'Insufficient permission'));
                            break;
                    }
                } else {
                    def.reject(response.data);
                }
            }
        );

        return def.promise;
    }

    getUserDbPermissions(idAccount) {
        const def = this.$q.defer();

        const promise = this.$http.get('/api.php/accounts/' + idAccount + '/user_db_permissions');

        promise.then(
            (response) => {
                def.resolve(response.data);
            },
            (response) => {
                if (response.data?.status === 'fail') {
                    switch (response.data?.message) {
                        case 'insufficient_permission':
                            def.reject(new FdxError('ACCOUNT_INSUFFICIENT_PERMISSION', 'Insufficient permission'));
                            break;
                    }
                } else {
                    def.reject(response.data);
                }
            }
        );

        return def.promise;
    }
}

AccountsDataService.$inject = ['$http', '$q'];
