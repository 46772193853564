/* eslint-disable @typescript-eslint/no-explicit-any */
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { JsonFormatterComponent, JsonFormatterModule } from '@feedonomics/frontend-components';

@Component({
    standalone: true,
    selector: 'fdx-json-cell-renderer',
    templateUrl: './json-cell-renderer.component.html',
    styleUrls: ['./json-cell-renderer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        JsonFormatterModule
    ]
})
export class JSONCellRendererComponent implements ICellRendererAngularComp, AfterViewInit {
    public params: ICellRendererParams;

    @ViewChild(JsonFormatterComponent) formatter: JsonFormatterComponent;

    constructor(private readonly changeDetectorRef: ChangeDetectorRef) { }

    ngAfterViewInit(): void {
        /**
         * Must let JSON Formatter component know that this view has been initialized
         * so it can re-attempt to determine if the JSON is overflowing, which will then
         * show the expand/collapse UI. For some reason, it doesn't work without this.
         */
        setTimeout(() => {
            this.changeDetectorRef.markForCheck();
        });
    }

    public agInit(params: ICellRendererParams<any, any, any>): void {
        this.bindParams(params);
        this.changeDetectorRef.markForCheck();
    }

    public refresh(params: ICellRendererParams<any, any, any>): boolean {
        this.bindParams(params);
        this.changeDetectorRef.markForCheck();
        return true;
    }

    private bindParams(params: ICellRendererParams): void {
        this.params = params;
    }
}
