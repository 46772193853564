import * as angular from 'angular';
import FdxInfoModal from './fdx-info-modal.controller';
import { MODULE_NAME } from '../../config/constants';

import './fdx-info-modal.styles.scss';

export const componentName = 'fdxInfoModal';

angular.module(MODULE_NAME).component(componentName, {
	template: require('./fdx-info-modal.template.html'),
	controller: FdxInfoModal,
	bindings: {
		// from uibmodal
		close: '&',
		dismiss: '&',
		resolve: '<?'
	}
});
