export interface TestFtpAccountResultErrorResponseInterface {
    action_message: string;
    long_message: string;
    short_message: string;
    success: boolean;
}

export class TestFtpAccountResultErrorResponse implements TestFtpAccountResultErrorResponseInterface {
    action_message: string = null;
    long_message: string = null;
    short_message: string = null;
    success: boolean = null;

    constructor(data: TestFtpAccountResultErrorResponseInterface) {

        if (typeof data !== 'undefined') {
            for (const prop in data) {
                if (this.hasOwnProperty(prop)) {
                    this[prop] = data[prop];
                }
            }
        }
    }

}
