import { Component } from '@angular/core';
import { CategoryPathCellType, ICategoryPathsCellRendererParams } from '@app/analyze-data/components/category-paths-cell-renderer/category-paths-cell-renderer.component';
import { ChannelOptionsType } from '@app/analyze-data/types/channel-options.type';
import { BootstrapThemeProp } from '@app/core/services/bootstrap-theme.service';
import { Modal } from '@app/modules/modals/modals.module';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'fdx-category-paths-modal',
    templateUrl: './category-paths-modal.component.html',
    styleUrls: ['./category-paths-modal.component.scss']
})
export class CategoryPathsModalComponent implements Modal {

    modalTheme: BootstrapThemeProp = 'secondary';
    channel: ChannelOptionsType;
    config: CategoryPathCellType;

    get title(): string {
        return `Corresponding ${this.channel?.display} paths`;
    }

    constructor(
        private readonly modal: NgbActiveModal
    ) {}

    onModalInit(params: ICategoryPathsCellRendererParams): void {
        this.channel = params.channel;
        this.config = params.config;
    }

    onModalSubmit(_event: MouseEvent): void {
        this.modal.close();
    }

    onModalCancel(_event: MouseEvent): void {
        this.modal.dismiss();
    }
}
