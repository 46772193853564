import { DbModel } from '@app/databases/models/db.model';
import { PrivacyLevel } from '@app/user/enums/privacy-level.enum';
import { UserDataModel } from '@app/user/models/user-data.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { AccountDataModel, AccountModel } from '../models/account';
import { UserModel } from '../models/user';
import DatabasesDataService from './DatabasesDataService';
import ImpersonateUserService from './ImpersonateUserService';
import LogService from './LogService';
import UsersDataService from './UsersDataService';

export default class AppStateService {

    static $inject = [
        '$cookies',
        '$q',
        '$timeout',
        '$window',
        'DatabasesDataService',
        'LogService',
        'UsersDataService',
        'ImpersonateUserService'
    ];

    // Local Attributes
    account = null;
    activeTab = '';
    database: DbModel | null = null;
    pageTitle = '';
    user: UserModel = null;

    // Todo: this will be updated in the future
    private readonly _userLoaded$: BehaviorSubject<UserModel | null> = new BehaviorSubject<UserModel | null>(null);
    userLoaded$: Observable<UserModel | null> = this._userLoaded$.asObservable();

    constructor(
        private $cookies,
        private $q,
        private $timeout,
        private $window: Window,
        private DatabasesDataService: DatabasesDataService,
        private LogService: LogService,
        private UsersDataService: UsersDataService,
        private ImpersonateUserService: ImpersonateUserService
    ) { }

    getAccountId() {
        return this.account?.id;
    }

    setAccount(value: AccountDataModel | null): void {
        if (!value) {
            this.account = null;
        } else {
            this.account = new AccountModel(value);
        }
    }

    getAccount(): AccountModel | null {
        return this.account;
    }

    saveAccountId(accountId) {

        const expirationDate = new Date();
        expirationDate.setTime(expirationDate.getTime() + 30 * (60 * 60 * 24) * 1000);

        this.$cookies.put('account_id', accountId, { path: '/', expires: expirationDate.toUTCString() })

    }

    getSavedAccountId() {
        return this.$cookies.get('account_id');
    }

    setDatabase(value: DbModel): void {
        this.database = value;
    }

    getDatabase(): DbModel | null {
        return this.database;
    }

    getDatabaseId(): string {
        return this.database?.id;
    }

    getDatabaseName(): string {
        return this.database?.name;
    }

    setUser(value: UserDataModel): void {
        this.user = new UserModel(value);
        this._userLoaded$.next(this.user);
    }

    reloadUser() {
        const def = this.$q.defer();

        const promise = this.UsersDataService.retrieveCurrentUser();

        promise.then(
            (user) => {
                this.setUser(user);
                def.resolve(user);
            },
            () => {
                this.setUser(null);
                def.reject(null);
            }
        );

        return def.promise;
    }

    getUser(): UserModel {
        return this.ImpersonateUserService.getUser() || this.user;
    }

    getLoggedInUser(): UserModel {
        return this.user;
    }

    getUserId(): string {
        return this.getUser().user_id;
    }

    getImpersonatedUserId() {
        return this.$cookies.get('impersonated_user_id') ?? null;
    }

    updateUserSetting(key: string, value: string | boolean): Promise<any> {
        return this.UsersDataService.updateUserSetting(
            this.user.settings,
            key,
            value
        );
    }

    isPrivacyLevelAtLeastAnalyst(): boolean {
        return this.userHasMinPrivacyLevel(PrivacyLevel.Analyst);
    }

    isPrivacyLevelAtLeastAdmin(): boolean {
        return this.userHasMinPrivacyLevel(PrivacyLevel.Admin);
    }

    private userHasMinPrivacyLevel(minPrivacyLevel: PrivacyLevel): boolean {
        return this.user?.hasMinimumPrivacyLevel(minPrivacyLevel, this.getAccountId());
    }

    isDbTemplateAccount(): boolean {
        return this.account.features.rakuten_rad_feed_build_template === 'enabled';
    }

    isAllowReadOnlyAccount(): boolean {
        return this.account.features.rakuten_read_only_permission === 'enabled';
    }

    getAccountPermissions() {
        if (this.account?.permissions) {
            return this.account.permissions;
        }

        return null;

    }

    isPrimaryUser(): boolean {
        return this.account?.permissions === 'primary';
    }

    loadDatabase(databaseId) {
        const deferred = this.$q.defer();

        this.DatabasesDataService.getOneById(databaseId).then(
            db => {
                this.setDatabase(db);
                deferred.resolve(db);
            },
            error => {
                this.LogService.error('AppStateService', 'loadDatabase', error);
                deferred.reject(error);
            }
        );

        return deferred.promise;
    }
}

export { AppStateService };
