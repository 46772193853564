<nav id="fdx-main-nav" class="navbar navbar-expand-md py-0">
    <div class="container-fluid">

        <div class="navbar-brand">
            <a ngbDropdownItem [routerLink]="linkService.ebaySelectionLink" aria-label="Navigate to ebay selection">
                <img [src]="logo" alt="Feedonomics for eBay" width="38">
            </a>
        </div>

        <div class="collapse navbar-collapse">
            <ul ngbNav #nav="ngbNav" class="navbar-nav nav-tabs w-100">
                <!-- Products -->
                <li
                    class="nav-item">
                    <a
                        class="nav-link"
                        [ngClass]="{ 'active': (isTabActive(appMenuTab.Dashboards) || isTabActive(appMenuTab.Data)) && !isFeedSupport }"
                        [routerLink]="linkService.ebaySelectionLink">
                        <fa-icon [icon]="productsIcon" size="lg" class="me-1"></fa-icon>
                        Products
                    </a>
                </li>
                <!-- FeedSupport -->
                <li
                    class="nav-item">
                    <a
                        class="nav-link"
                        [ngClass]="{ 'active': isFeedSupport }"
                        [routerLink]="linkService.feedSupportLink">
                        <fa-icon [icon]="feedSupportIcon" size="lg" class="me-1"></fa-icon>
                        FeedSupport™
                    </a>
                </li>
                <!-- Right nav -->
                <li
                    ngbDropdown
                    fscHoverDropdown
                    [closeDelay]="0"
                    class="nav-item fdx-flex-1 ms-auto mt-auto right-nav-dropdown d-flex justify-content-end">
                    <button
                        ngbDropdownToggle
                        class="unstyled-button ms-auto right-nav-toggle no-icon text-truncate d-flex align-items-center gap-2"
                        type="button"
                        id="dropdownRight">
                        <fa-icon
                            *ngIf="hasPendingInvitations"
                            [icon]="notificationIcon"
                            size="lg"
                            class="fa-shake">
                        </fa-icon>
                        <span class="text-truncate">
                            {{ accountName ? (accountName | truncate: 25) : shortenEmail(userName)}}
                            <ng-container
                                *ngIf="databaseName">
                                <fa-icon
                                    [icon]="chevronRightIcon"
                                    size="xs"
                                    class="mx-2">
                                </fa-icon>
                                {{ databaseName ? (databaseName | truncate: 35) : '' }}
                            </ng-container>
                        </span>
                        <fdx-user-initials
                            [user]="user">
                        </fdx-user-initials>
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownRight" class="dropdown-menu-end">
                        <ng-container
                            *ngIf="accountName">
                            <button
                                type="button"
                                class="dropdown-header text-wrap"
                                ngbDropdownItem
                                (mouseenter)="dbAndAccountNameHovered = true"
                                (mouseleave)="dbAndAccountNameHovered = false"
                                (focus)="dbAndAccountNameHovered = true"
                                (blur)="dbAndAccountNameHovered = false"
                                (click)="copyDBAndAccountName()">
                                {{ accountName }}
                                <ng-container
                                    *ngIf="databaseName">
                                    <fa-icon
                                        [icon]="chevronRightIcon"
                                        size="xs"
                                        class="mx-1">
                                    </fa-icon>
                                    {{ databaseName }}
                                    <fa-icon
                                        [icon]="copyIcon"
                                        class="ms-1 copy-names"
                                        [ngClass]="{ 'invisible': !dbAndAccountNameHovered }">
                                    </fa-icon>
                                </ng-container>
                            </button>
                            <div class="dropdown-divider"></div>
                        </ng-container>
                        <!-- ACCOUNT -->
                        <div class="dropdown-header">
                            Account <span *ngIf="accountId">(ID: {{accountId}})</span>
                        </div>
                        <a ngbDropdownItem [routerLink]="linkService.accountsLink">
                            <span>Choose account</span>
                            <fa-icon
                                *ngIf="hasPendingInvitations"
                                [icon]="notificationIcon"
                                class="ms-2">
                            </fa-icon>
                        </a>
                        <a ngbDropdownItem [routerLink]="linkService.profileLink">Profile</a>
                        <div class="dropdown-divider"></div>
                        <!-- USER -->
                        <div class="dropdown-header">User</div>
                        <div class="dropdown-header">{{ userName }}</div>
                        <ng-container *ngIf="(isPrivacyLevelAtLeastAnalyst || isClientDemoView) && !isImpersonatingUser">
                            <button
                                ngbDropdownItem
                                type="button"
                                (click)="toggleClientDemoView()">
                                {{ isClientDemoView ? 'Return to default view' : 'Toggle client demo view' }}
                            </button>
                        </ng-container>
                        <a ngbDropdownItem [routerLink]="linkService.logoutLink" [queryParams]="{'app': 'feedonomics', 'redirect': currentPath}">Logout</a>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</nav>
