<main class="container-fluid py-4">

<div class="d-flex align-items-center mb-3 gap-2">
	<fdx-page-title [classes]="{'mb-0': true}">Add export</fdx-page-title>
	<button type="button" (click)="showHelp()" class="ms-auto btn unstyled-button">
		<fa-icon [icon]="iconHelp" class="no-margin"></fa-icon>
	</button>
</div>
<div class="card">
    <div class="card-body">
        <fdx-export-config-form-new
            [addExportError]="newExport.error"
            [exportItem]="newExport"
            [dbFields]="dbFields"
            [dbSortableFields]="dbFieldsSortable"
            [exportItems]="exports"
            [triggers]="triggers"
            (addExport)="addExport()"
            (exportChange)="mergeWithExport($event, false)"
            (exportFieldsChange)="mergeExportFields($event, false)"
            (webhookTypeChange)="changeWebhookType(newExport, { webhookType: $event })">
        </fdx-export-config-form-new>
    </div>
</div>

<div class="d-flex justify-content-center align-items-end position-relative mb-2 mt-4" #existingExportTitle>
	<h2 class="text-center">Existing exports</h2>
	<button *ngIf="exports.length > 0" type="button" name="download-export-summary" class="btn btn-outline-secondary download_export_summary" (click)="downloadExportsSummary()">Download report - all exports</button>
</div>

<!-- Existing Export -->
<div class="card">
    <div class="card-body" *ngIf="currentExport && currentExport.hasOwnProperty('id'); else exportsLoading">

        <div class="border-bottom">

            <div class="row" >

                <!-- Choose export -->
                <div class="offset-3 col-6">
                    <div
                        [formGroup]="exportSelectForm">
                        <fdx-form-field
                            formControlName="existingExport"
                            [classes]="{'row': true}" >
                            <fdx-label class="col-3" [classes]="{'keep-padding': true}">Choose export:</fdx-label>
                            <fdx-input-wrapper
                                class="col">
                                <fdx-export-select
                                    controlName="existingExport"
                                    [formGroup]="exportSelectForm"
                                    [exports]="exports"
                                    [triggers]="triggers"
                                    [isEBSDatabase]="isEBSDatabase" >
                                </fdx-export-select>
                            </fdx-input-wrapper>
                        </fdx-form-field>
                    </div>

                    <div class="row mt-2">
                        <div class="offset-3 col text-secondary" *ngIf="showExportTriggersMessage()" >
                            This export is being used in export triggers.
                        </div>
                    </div>

                </div>

                <!-- Button Group -->
                <div class="col-3" >

                    <div class="row">
                        <div class="action-group col d-flex justify-content-end" >
                            <span class="last-run-label me-3" *ngIf="currentExport.last_run_duration !== '0'">Last run time: {{ currentExport.last_run_duration | formatSeconds }}</span>

                            <!-- Reset Export -->
                            <button
                                type="button"
                                name="reset-export"
                                class="btn btn-outline-primary me-2"
                                ngbPopover="Reset Export"
                                aria-label="Reset Export"
                                triggers="mouseenter:mouseleave"
                                (click)="resetExport(currentExport)"
                                *ngIf="showResetExportButton"
                                [disabled]="currentExport.disable_reset_button">
                                <fa-icon [icon]="iconRedo" class="no-margin"></fa-icon>
                            </button>

                            <!-- Local Export -->
                            <button
                                type="button"
                                name="local-export"
                                class="btn btn-outline-primary me-2"
                                ngbPopover="Local Export"
                                aria-label="Local Export"
                                triggers="mouseenter:mouseleave"
                                (click)="runParallelExport(currentExport, 'false' , false)"
                                [disabled]="currentExport.export_running">
                                <fa-icon [icon]="iconDownload" class="no-margin"></fa-icon>
                            </button>

                            <!-- Simulated Local Export -->
                            <button
                                type="button"
                                name="time-simulated-local-export"
                                class="btn btn-outline-primary me-2"
                                ngbPopover="Time-Simulated Local Export"
                                aria-label="Time-Simulated Local Export"
                                triggers="mouseenter:mouseleave"
                                (click)="runParallelExportSimulated(currentExport, 'false' , false)"
                                [disabled]="currentExport.export_running">
                                <span class="simulated_download_export"></span>
                            </button>

                            <!-- Webhook Export For Postman -->
                            <button
                                type="button"
                                name="download-webhook"
                                class="btn btn-outline-primary me-2"
                                ngbPopover="Download webhook for Postman"
                                aria-label="Download webhook for Postman"
                                triggers="mouseenter:mouseleave"
                                *ngIf="showDownloadWebhookForPostmanButton"
                                (click)="downloadWebhookForPostman(currentExport)"
                                [disabled]="currentExport.export_running">
                                <fa-icon [icon]="iconPostman" class="no-margin"></fa-icon>
                            </button>

                            <!-- Copy Export -->
                            <button
                                type="button"
                                name="copy-export"
                                class="btn btn-outline-primary me-2"
                                [ngbPopover]="disableFtpCopy ? 'Copy disabled for FTP exports.' : 'Copy'"
                                aria-label="Copy Export"
                                triggers="mouseenter:mouseleave"
                                [disabled]="disableFtpCopy"
                                (click)="copyExportFields(currentExport)">
                                <fa-icon [icon]="iconCopy" class="no-margin"></fa-icon>
                            </button>

                            <!-- Delete Export -->
                            <button
                                type="button"
                                name="delete-export"
                                class="btn btn-outline-primary me-2"
                                ngbPopover="Delete"
                                aria-label="Delete Export"
                                triggers="mouseenter:mouseleave"
                                (click)="deleteModal(currentExport)">
                                <fa-icon [icon]="iconTrash" class="no-margin"></fa-icon>
                            </button>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col" >

                            <div class="row">
                                <div class="col d-flex justify-content-end">
                                    <small class="mb-2 me-2 fw-bold" >Last run:</small>

                                    <div class="d-flex"
                                        role="button"
                                        [ngbPopover]="lastRunDateTimePopoverTemplate"
                                        [popoverTitle]="lastRunDateTimeTitleTemplate"
                                        placement="bottom auto">

                                        <fdx-date-time-localized
                                            class="small"
                                            [dateTime]="currentExport.timestamp">

                                            <fa-icon
                                                sufix
                                                class="text-muted"
                                                size="sm"
                                                [icon]="clockIcon">
                                            </fa-icon>

                                        </fdx-date-time-localized>

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div>

        <div class="row">
            <!-- Submit -->
            <div class="form-group text-center mt-3">
                <!-- Parallel Exports -->
                <span>
                    <!-- Parallel Push Export -->
                    <div class="btn-group">
                        <button
                            type="button"
                            name="push-export"
                            (click)="confirmPushExportModal()"
                            [disabled]="currentExport.export_running"
                            class="btn btn-primary">Push export</button>
                        <button
                                type="button"
                                name="push-export-with-notification"
                                class="btn btn-primary"
                                (click)="confirmNotificationExportModal()"
                                *ngIf="isAnalyst"
                                [disabled]="currentExport.export_running"
                                ngbPopover="Push export with notification"
                                aria-label="Push export with notification"
                                triggers="mouseenter:mouseleave">
                            <fa-icon [icon]="iconBell" class="no-margin"></fa-icon>
                        </button>
                    </div>

                    <!-- Post Download Button -->
                    <a class="btn btn-primary ms-1"
                        target="_blank"
                        *ngIf="currentExport.last_download_link !== undefined"
                        href="{{currentExport.last_download_link}}">
                        Download last run</a>
                </span>
            </div>

            <!-- Export is running -->
            <div *ngIf="currentExport.export_running" class="form-group text-center">
                <img src="/app/images/feeding_in_progress.gif"
                alt="Feed processing indicator">
                    We are attempting to process your data.  Some exports may take longer than others.  You can leave this page anytime.

            </div>

            <!-- Push Error -->
            <fdx-alert *ngIf="currentExport.run_error" theme="danger" [body]="currentExport.run_error"></fdx-alert>

            <!-- Export Information -->
            <div
                class="p-2"
                [class.modified]="currentExport.modified">
                <fdx-export-config-form-existing
                    [editExportError]="currentExport.error"
                    [exportItem]="currentExport"
                    [dbFields]="dbFields"
                    [dbSortableFields]="dbFieldsSortable"
                    [exportSaved]="currentExport.added_message"
                    (editExport)="editExport(currentExport)"
                    (exportChange)="mergeWithExport($event, true)"
                    (exportFieldsChange)="mergeExportFields($event, true)"
                    (webhookTypeChange)="changeWebhookType(currentExport, { webhookType: $event })"
                    (scheduleUpdated)="scheduleUpdateOnExportList($event)">
                </fdx-export-config-form-existing>
            </div>
            <div *ngIf="currentExport.saving_message" class="text-center">Saving export</div>
            <div *ngIf="currentExport.added_message" class="animate-show text-center">Export saved!</div>
        </div>
    </div>

    <ng-template #exportsLoading>
        <div class="card-body" *ngIf="this.exports.length !== 0">
            <fdx-spinner></fdx-spinner>
        </div>
    </ng-template>
</div>

</main>

<ng-template #lastRunDateTimeTitleTemplate>
    <fdx-popover-title theme="primary" titleText="Compare time zones">
    </fdx-popover-title>
</ng-template>

<ng-template #lastRunDateTimePopoverTemplate >
    <fdx-date-time-localized
        [dateTime]="currentExport.timestamp" >
    </fdx-date-time-localized>
    <fdx-date-time-localized
        class="text-muted"
        [dateTime]="currentExport.timestamp"
        [displayLocalTimezone]="true" >
    </fdx-date-time-localized>
</ng-template>
