/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Clipboard } from '@angular/cdk/clipboard';
import { Injectable } from '@angular/core';
import { BaseInputComponent } from '@app/modules/inputs/components/base-input/base-input.component';
import { CheckboxType } from '@app/modules/inputs/types/checkbox.type';
import { NgbDateStruct, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
    providedIn: 'root'
})
export class InputsUtilitiesService {
    constructor(
        private readonly clipboard: Clipboard
    ) { }

    public isCheckboxArray(array: (CheckboxType | any)[]): array is CheckboxType[] {
        return array.every((element) => {
            const el = element as CheckboxType;
            return el.value !== undefined && el.id !== undefined && el.label !== undefined && el.checked !== undefined;
        });
    }

    public isNgbDateStruct(obj: (NgbDateStruct | any)): obj is NgbDateStruct {
        // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
        return obj && obj.year !== undefined && obj.month !== undefined && obj.day !== undefined;
    }

    public copyToClipboard(input: BaseInputComponent, tooltip: NgbTooltip): void {
        const copied = this.clipboard.copy(input.value);
        if (copied) {
            tooltip.open({ text: 'Copied to clipboard' });
        } else {
            tooltip.open({ text: 'Failed to copy to clipboard' });
        }
        // eslint-disable-next-line angular/timeout-service
        setTimeout(() => {
            tooltip.close();
        }, 1000);
    }
}
