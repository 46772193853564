import { AppStateService } from '@ajs/services/AppStateService';
import { fdxUI as FdxUI } from '@ajs/services/fdxUI';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppMenuTab } from '@app/core/models/enums/app-menu-tab.enum';
import { DbFieldModel } from '@app/databases/models/db-field.model';
import { DbModel } from '@app/databases/models/db.model';
import { DatabasesDataService } from '@app/databases/services/databases-data.service';
import { SetPrimaryKeyResponse } from '@app/databases/services/responses/data.response';
import { ModalService } from '@app/modules/modals/services/modal.service';
import { IconDefinition, faCircleInfo, faGripDotsVertical, faKey, faSortAlt } from '@fortawesome/pro-solid-svg-icons';
import { Observable, Subject, finalize, from, switchMap, tap, throwError } from 'rxjs';
import { map, mergeMap, takeUntil } from 'rxjs/operators';

enum PageStatus {
    Initial,
    Loading,
    Error,
    Done
}

export interface DatabaseFieldAugmented extends DbFieldModel {
    editing: boolean;
    isPrimaryKey: boolean;
}

@Component({
    selector: 'fdx-transformers-field-order-page',
    styleUrls: ['./transformers-field-order-page.component.scss'],
    templateUrl: './transformers-field-order-page.component.html'
})
export class TransformersFieldOrderPageComponent implements OnInit, OnDestroy {

    title: string = 'Field execution order';
    appMenuTab: AppMenuTab = AppMenuTab.DataSources;

    dbFieldTypes: string[] = ['TEXT', 'MEDIUMTEXT'];
    dbFields: DatabaseFieldAugmented[] = [];

    iconKey: IconDefinition = faKey;
    iconSort: IconDefinition = faSortAlt;
    iconGrip: IconDefinition = faGripDotsVertical;
    iconInfo: IconDefinition = faCircleInfo;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    pageStatus: PageStatus = PageStatus.Initial;
    errorText: string = '';

    updateStatus: string = '';

    readonly primaryKeyTooltipText: string = `Select a unique identifier for Primary key by clicking 
    on the key icon for a row.  Various features (Summary Governance, Data Governance, etc.) depend on the
    Primary key.`;

    readonly orderTooltipText: string = `Drag and drop a row or click on a number in the Order column
    to change the field execution order.  Transformers run according to the field execution order.`;

    get database(): DbModel {
        return this.appStateService.getDatabase();
    }

    get databaseId(): string {
        return this.appStateService.getDatabaseId();
    }

    get isAdmin(): boolean {
        return this.appStateService.isPrivacyLevelAtLeastAdmin();
    }

    get primaryKey(): string {
        return this.database.primary_db_field_key;
    }

    get requestedPrimaryKey(): string {
        return this.database.requested_primary_key;
    }

    private readonly unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        private readonly appStateService: AppStateService,
        private readonly databasesDataService: DatabasesDataService,
        private readonly modalService: ModalService,
        private readonly fdxUI: FdxUI
    ) { }

    ngOnInit(): void {
        this.fdxUI.setTitle(this.title);
        this.fdxUI.setActiveTab(this.appMenuTab);

        this.pageStatus = PageStatus.Loading;

        this.fetchDbFields()
            .pipe(
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: () => {
                    this.pageStatus = PageStatus.Done;
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    trackDbField(_index: number, field: DatabaseFieldAugmented): string {
        return field.id;
    }

    drop(event: CdkDragDrop<DatabaseFieldAugmented[], DatabaseFieldAugmented[], DatabaseFieldAugmented>): void {
        if (event.previousIndex === event.currentIndex) {
            return;
        }

        moveItemInArray(this.dbFields, event.previousIndex, event.currentIndex);

        const modalRef = this.modalService.showConfirmationModal(
            'Update Sort Order',
            `Are you sure you want to change the order of ${event.item.data.field_name} from ${event.previousIndex} to ${event.currentIndex}?`
        );

        modalRef.closed
            .pipe(
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => {
                this.reorderClicked(this.dbFields[event.currentIndex], `${event.currentIndex}`);
            });

        modalRef.dismissed
            .pipe(
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => {
                moveItemInArray(this.dbFields, event.currentIndex, event.previousIndex);
            });
    }

    fetchDbFields(): Observable<DatabaseFieldAugmented[]> {
        this.fdxUI.startBlockUI();

        return this.databasesDataService.getFields(this.databaseId)
            .pipe(
                map((fields: DbFieldModel[]) =>
                    fields.map((field: DbFieldModel): DatabaseFieldAugmented => {
                        return {
                            ...field,
                            editing: false,
                            isPrimaryKey: this.isPrimaryKey(field)
                        };
                    })
                ),
                tap((fields: DatabaseFieldAugmented[]) => {
                    this.dbFields = fields;
                }),
                finalize(() => {
                    this.fdxUI.stopBlockUI();
                })
            );
    }

    isPrimaryKey(dbField: DbFieldModel): boolean {
        return dbField.field_name === this.primaryKey;
    }

    openPrimaryKeyModal(dbField: DatabaseFieldAugmented): void {
        if (dbField.isPrimaryKey) {
            return;
        }

        const titleText = this.primaryKey ? `Change Primary Key to ${dbField.field_name}?` : `Select ${dbField.field_name} as Primary Key?`;

        const warningText = this.primaryKey
            ? `Are you sure you want to change the primary key from <strong>${this.primaryKey}</strong> to <strong>${dbField.field_name}</strong>?`
            : `Are you sure you want to select <strong>${dbField.field_name}</strong> as the primary key?`;


        let bodyText = 'Primary Key is typically a unique identifier such as SKU, ID, etc. If it is not, this may lead to unexpected behavior across the platform.';
        bodyText += `<br /><br /><span class="text-warning">${warningText}</span><br /><br />`;

        const confirmText = this.primaryKey
            ? 'Confirm change'
            : 'Confirm selection';

        const modalRef = this.modalService.showWarningConfirmationModal(
            titleText,
            bodyText,
            confirmText
        );

        modalRef.closed
            .pipe(
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => {
                this.fdxUI.startBlockUI();

                this.errorText = '';

                this.databasesDataService.setPrimaryKey(this.databaseId, dbField.field_name)
                    .pipe(
                        switchMap((res: SetPrimaryKeyResponse) => {
                            if (res.update_status !== undefined) {
                                this.updateStatus = res.update_status;
                            }
                            if (res?.status === 'fail') {
                                return throwError(res);
                            }

                            return from(
                                this.appStateService.loadDatabase(this.databaseId)
                            );
                        }),
                        takeUntil(this.unsubscribe$)
                    )
                    .subscribe(
                        {
                            next: () => {
                                this.fetchDbFields()
                                    .pipe(
                                        takeUntil(this.unsubscribe$)
                                    )
                                    .subscribe(
                                        () => {
                                            if (this.updateStatus === 'updated') {
                                                this.fdxUI.showToastSuccess(this.primaryKey ? `Primary key has been changed to ${dbField.field_name}!` : `Primary key has been set to: ${dbField.field_name}!`);
                                            } else if (this.updateStatus === 'requested') {
                                                this.fdxUI.showToastInfo('The system is processing your selection of primary key. You will receive an email upon completion.');
                                            } else if (this.updateStatus === 'duplicate') {
                                                this.fdxUI.showToastError('The selected field is not unique, please select a field that has unique values.');
                                            } else {
                                                this.fdxUI.showToastError('Primary key update failed');
                                            }
                                        }
                                    );
                            },
                            complete: () => {
                                this.fdxUI.stopBlockUI();
                            },
                            error: (_error: HttpErrorResponse) => {
                                this.fdxUI.stopBlockUI();
                                this.fdxUI.showToastError('Primary key update failed');
                            }
                        }
                    );
            });
    }

    reorderClicked(dbField: DatabaseFieldAugmented, sortOrder: `${number}`): void {
        this.fdxUI.startBlockUI();

        this.errorText = '';

        const params = {
            field_name: dbField.field_name,
            field_type: dbField.field_type,
            sort_order: sortOrder
        };

        this.databasesDataService.updateField(this.databaseId, params)
            .pipe(
                mergeMap(() => this.fetchDbFields()),
                finalize(() => {
                    this.fdxUI.stopBlockUI();
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                error: (error: HttpErrorResponse) => {
                    this.errorText = error.error?.message;
                }
            });
    }
}
