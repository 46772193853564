<!-- eslint-disable @angular-eslint/template/no-inline-styles -->
<!-- eslint @angular-eslint/template/use-track-by-function:warn -->
<div class="d-flex" #container>
    <nav class="h-100 flex-column align-items-stretch pe-5 border-end">
        <nav class="nav nav-pills flex-column">
            <ng-container *ngFor="let tab of tabs">
                <button
                    type="button"
                    class="nav-link text-start"
                    [ngClass]="tabClass(tab.title)"
                    (click)="scrollTo(tab.title)">
                    {{ tab.title }}
                </button>
                <nav *ngFor="let group of tab.data" class="nav nav-pills flex-column">
                    <button
                        type="button"
                        class="nav-link ms-3 my-1 text-start"
                        [ngClass]="groupClass(tab.title + '_' + group.title)"
                        (click)="scrollTo(tab.title + '_' + group.title)">
                        {{ group.title }}
                    </button>
                </nav>
            </ng-container>
        </nav>
    </nav>

    <div class="flex-grow-1 ms-5">
        <div class="d-flex flex-column pe-2 overflow-auto" [ngStyle]="{'height': height}" scrollSpy [spiedTags]="['DIV', 'NGB-ACCORDION']" (sectionChange)="onSectionChange($event)">
            <ng-container *ngFor="let tab of tabs; let first = first">
                <div class="d-flex align-items-center mb-1">
                    <div class="fs-3 fw-bold flex-grow-1" [ngClass]="{'mt-3': !first}" [attr.id]="encodeString(tab.title)">{{ tab.title }}</div>
                    <div *ngIf="first && !onCheatSheetPage">
                        <a target="_blank" class="btn btn-link" [routerLink]="['/', 'help', 'cheat-sheet']">
                            Open in new tab
                            <fa-icon [icon]="newTabIcon" class="fa-icon-right"></fa-icon>
                        </a>
                    </div>
                </div>
                <div class="text-muted mb-3" [innerHTML]="tab.description"></div>
                <ngb-accordion *ngFor="let group of tab.data; let last = last" [attr.id]="encodeString(tab.title + '_' + group.title)" [ngClass]="{'mb-3': !last}">
                    <ngb-panel><ng-template ngbPanelHeader><div>{{ group.title }}</div></ng-template></ngb-panel>
                    <ngb-panel *ngFor="let item of group.data">
                        <ng-template ngbPanelHeader let-panel>
                            <fdx-cheat-sheet-panel-header [data]="item" [panel]="panel"></fdx-cheat-sheet-panel-header>
                        </ng-template>
                        <ng-template ngbPanelContent *ngIf="!isTitleOnly(item)">
                            <fdx-cheat-sheet-panel-content [data]="item"></fdx-cheat-sheet-panel-content>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </ng-container>
        </div>
    </div>
</div>
