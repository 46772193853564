/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { LogService } from '@app/core/services/log.service';
import { BaseInputComponent } from '@app/modules/inputs/components/base-input/base-input.component';
import { AcceptedInputs, CustomInputs } from '@app/modules/inputs/enums/input-types.enum';
import { InputsUtilitiesService } from '@app/modules/inputs/services/inputs-utilities.service';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { faCopy, faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'fdx-input',
    providers: [{
        provide: BaseInputComponent,
        useExisting: InputComponent
    }],
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss']
})
export class InputComponent extends BaseInputComponent implements OnInit, AfterViewInit {

    @ViewChild('inputElement') inputElement: ElementRef<HTMLInputElement>;
    @ViewChild('passwordToggle') passwordToggleRef: TemplateRef<any>;
    @ViewChild('copyButton') copyButtonRef: TemplateRef<any>;

    iconCopy: IconDefinition = faCopy;

    /** Password type resources **/
    @Input() showCopyButton: boolean = false;
    showPasswordButton: boolean = false;
    iconEye: IconDefinition = faEye;
    iconEyeSlash: IconDefinition = faEyeSlash;

    constructor(
        protected readonly element: ElementRef<HTMLElement>,
        protected readonly inputsUtilitiesService: InputsUtilitiesService,
        protected readonly logService: LogService,
        protected readonly ngbDateParserFormatter: NgbDateParserFormatter
    ) {
        super(element, inputsUtilitiesService, logService, ngbDateParserFormatter);
    }

    public get passwordVisibilityIcon(): IconDefinition {
        return this.isPasswordHidden ? this.iconEye : this.iconEyeSlash;
    }

    public get isPasswordHidden(): boolean {
        return this.inputType === AcceptedInputs.Password;
    }

    ngOnInit(): void {
        if (this.inputType === AcceptedInputs.Range) {
            this.initClasses['form-range'] = true;
            if (this.classes['floating-range-input']) {
                this.initClasses['form-control'] = true;
            }
        } else if (this.readonly === true && this.classes['form-control-plaintext']) {
            // if we're using the readonly form-control-plaintext class, don't add the standard form-control style
            this.initClasses['form-control'] = false;
        } else if (this.classes['form-control'] === undefined) {
            this.initClasses['form-control'] = true;
        }
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this.validateConfiguration();

        if (this.inputType === AcceptedInputs.Password) {
            this.showPasswordButton = true;
            this.inputGroupRightAddons = this.concatDynamicRightAddon(this.passwordToggleRef, this.inputGroupRightAddons);
        }

        if (this.showCopyButton) {
            this.inputGroupRightAddons = this.concatDynamicRightAddon(this.copyButtonRef, this.inputGroupRightAddons);
        }
    }

    private validateConfiguration(): string[] {
        const errors: string[] = [];

        switch (this.inputType) {
            case CustomInputs.Number:
                errors.push('number type should use fdx-input-number');
                break;
            case CustomInputs.File:
                errors.push('file type should use fdx-input-file');
                break;
            default: {
                const set = new Set(Object.values<string>(AcceptedInputs));
                if (!set.has(this.inputType)) {
                    errors.push(`'${this.inputType}' is not a valid input type for this component.`);
                }
            }
        }

        errors.forEach((error) => {
            this.logService.error(error);
        });

        return errors;
    }

    private concatDynamicRightAddon(templateRefToAppend: TemplateRef<any>, inputGroup?: TemplateRef<any> | TemplateRef<any>[]): TemplateRef<any>[] {
        let tempInputGroup: TemplateRef<any>[] = [];
        if (inputGroup) {
            if (!Array.isArray(inputGroup)) {
                tempInputGroup.push(inputGroup);
            } else {
                tempInputGroup = [...inputGroup];
            }
        }

        return [...tempInputGroup, templateRefToAppend];
    }

    togglePasswordVisibility(): void {
        this.inputType = this.inputType === AcceptedInputs.Password
            ? AcceptedInputs.Text
            : AcceptedInputs.Password;
    }

    inputFocus(): void {
        this.inputElement.nativeElement.focus();
    }
}
