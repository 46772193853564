import FdxError from '../models/FdxError';
import { isEmpty } from '@app/core/functions/isEmpty';

export default class CSVService {
  static $inject = ['$q'];

  $q = null;

  constructor($q) {
    this.$q = $q;
  }

  download(filename, rows, delimiter = ',') {
    const that = this;

    const processRow = function(row, f_delimiter) {
      let finalVal = '';
      for (let j = 0; j < row.length; j++) {
        let innerValue = isEmpty(row[j]) ? '' : row[j].toString();
        if (row[j] instanceof Date) {
          innerValue = row[j].toLocaleString();
        };

        let result = innerValue.replace(/"/g, '""');
        if (result.search(/("|,|\n)/g) >= 0) {
          result = '"' + result + '"';
        }

        if (j > 0) {
          // finalVal += ',';
          finalVal += f_delimiter;
        }

        finalVal += result;
      }

      return finalVal + '\n';
    }

    let csvFile = '';
    for (let i = 0; i < rows.length; i++) {
      csvFile += processRow(rows[i], delimiter);
    }

    const blob = new Blob([csvFile], {
      type: 'text/csv;charset=utf-8;'
    });

    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.click();
      }
    }
  }
  downloadRawCSV(filename, csvFile) {
    const def = this.$q.defer();
    const blob = new Blob([csvFile], {
      type: 'text/csv;charset=utf-8;'
    });

    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, filename);
      def.resolve('success');
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.click();
        if(link.download !== undefined) {
          def.resolve('success');
        }
        else {
          def.reject(new FdxError('UNKNOWN_ERROR', 'file download failed'));
        }
      }
    }
    return def.promise;
  }

}
