import { Export } from '@app/exports/services/responses/get-exports.response';

export class ExportConstants {
    public static allExports: Export = {
        id: '0',
        name: 'All exports',
        cron: null,
        cron_timezone: null,
        time_running: '',
        db_id: '',
        file_name: '',
        username: '',
        password: '',
        ftp_url: '',
        next_run_time: '',
        export_selector: '',
        running: '0',
        cxn_id: 0,
        export_format: '',
        protocol: '',
        protocol_info: {},
        last_run_duration: '',
        host: '',
        file_header: '',
        file_footer: '',
        threshold: 0,
        include_column_names: 0,
        item_group_id: '',
        json_minify_type: '',
        export_encoding: '',
        delimiter: '',
        compression: '',
        quoted_fields: 0,
        deduplicate_field_name: [],
        timestamp: '',
        enclosure: '',
        escape: '',
        strip_characters: '',
        show_empty_tags: 0,
        show_empty_parent_tags: 0,
        xml_write_document_tag: 0,
        use_cdata: 0,
        zip_inner_file_name: '',
        max_attempts: 0,
        time_between_attempts: 0,
        row_limit: 0,
        row_sort: '',
        row_order: '',
        legacy_workers: 0,
        timeout: 0,
        worker_pid: 0,
        worker_hostname: '',
        blocked: 0,
        export_index_field: '',
        deduplicate_preserve_nulls: 0,
        created_at: '',
        paused: '0',
        ftp_trigger: false,
        export_triggered: false
    }
}
