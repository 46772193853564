
interface ResolverInterface {
	transformerFieldDependency: any,
	currentField: string
}

export default class FdxTransformersFieldDependencyTreeModalController {

	// from uibmodal
	private close: (result?: { $value }) => void;
	private dismiss: (result?: { $value: string }) => void;
	private resolve: ResolverInterface;

	public transformerFieldDependency: any = null;
	public currentField: string = '';

	static $inject = [];

	constructor() { }

	$onInit() {
		this.transformerFieldDependency = this.resolve.transformerFieldDependency;
		this.currentField = this.resolve.currentField;
	}

	public onCancel(): void {
		this.dismiss();
	}
}
