import { Pipe, PipeTransform } from '@angular/core';
/*
 * Example usage:
 *  Basic Array of single type:                         *ngFor="let listItem of todoService.list | orderBy : '-'"
 *  Multidimensional Array Sort on single column:       *ngFor="let listItem of todoService.list | orderBy : ['-status']"
 *  Multidimensional Array Sort on multiple columns:    *ngFor="let listItem of todoService.list | orderBy : ['status', '-title']"
 */

@Pipe({ name: 'orderBy', pure: false })
export class OrderByPipe implements PipeTransform {

    private static debug: boolean = false;

    static _orderByComparator(a: any, b: any): number {

        if (OrderByPipe.debug) {
            console.log('\n_orderByComparator');
            console.log('a\t\t\t\t\t', a);
            console.log('b\t\t\t\t\t', b);
        }

        if ((isNaN(parseFloat(a)) || !isFinite(a)) || (isNaN(parseFloat(b)) || !isFinite(b))) {
            //Isn't a number so lowercase the string to properly compare
            if (a.toLowerCase() < b.toLowerCase()) return -1;
            if (a.toLowerCase() > b.toLowerCase()) return 1;
        }
        else {
            //Parse strings as numbers to compare properly
            if (parseFloat(a) < parseFloat(b)) return -1;
            if (parseFloat(a) > parseFloat(b)) return 1;
        }

        return 0; //equal each other
    }

    transform<T>(input: T[], config: string[] | string = '+'): T[] {
        if (OrderByPipe.debug) {
            console.log('input\t\t\t\t', input);
            console.log('config\t\t\t\t', config);
        }

        if (!Array.isArray(input)) return input;

        if (!Array.isArray(config) || (Array.isArray(config) && config.length === 1)) {
            if (OrderByPipe.debug) {
                console.log('config is not an array');
            }

            const propertyToCheck: string = !Array.isArray(config) ? config : config[0];
            const desc = propertyToCheck.substring(0, 1) === '-';

            if (OrderByPipe.debug) {
                console.log('propertyToCheck\t\t', propertyToCheck);
                console.log('desc\t\t\t\t', desc);
            }

            //Basic array
            if (!propertyToCheck || propertyToCheck === '-' || propertyToCheck === '+') {
                return !desc ? input.sort() : input.sort().reverse();
            }

            const property: string = propertyToCheck.substring(0, 1) === '+' || propertyToCheck.substring(0, 1) === '-'
                ? propertyToCheck.substring(1)
                : propertyToCheck;

            const isExpression: boolean = new RegExp('=|>|<|!').test(property);

            if (OrderByPipe.debug) {
                console.log('property\t\t\t', property);
                console.log('isExpression\t\t', isExpression);
            }

            return input.sort(function (a: T, b: T) {
                if (isExpression) {
                    return OrderByPipe._evaluateExpression(a, b, property);
                }

                const compared: number = OrderByPipe._orderByComparator(a[property], b[property]);
                if (OrderByPipe.debug) {
                    console.log('compared\t\t\t', compared);
                }
                return !desc ? compared : -compared;
            });

        }

        //Loop over property of the array in order and sort
        return input.sort(function (a: T, b: T) {
            if (OrderByPipe.debug) {
                console.log('config is an array');
            }
            for (let i: number = 0; i < config.length; i++) {
                const desc = config[i].substring(0, 1) === '-';
                const property = config[i].substring(0, 1) === '+' || config[i].substring(0, 1) === '-'
                    ? config[i].substring(1)
                    : config[i];

                const isExpression: boolean = new RegExp('=|>|<|!').test(property);

                if (OrderByPipe.debug) {
                    console.log('config[i]\t\t\t', config[i]);
                    console.log('desc\t\t\t\t', desc);
                    console.log('property\t\t\t', property);
                    console.log('isExpression\t\t', isExpression);
                }

                let comparison;
                if (isExpression) {
                    comparison = OrderByPipe._evaluateExpression(a, b, property);
                } else {
                    const compared: number = OrderByPipe._orderByComparator(a[property], b[property]);
                    comparison = !desc ? compared : -compared;
                    if (OrderByPipe.debug) {
                        console.log('compared\t\t\t', compared);
                        console.log('comparison\t\t\t', comparison);
                    }
                }
                //Don't return 0 yet in case of needing to sort by next property
                if (comparison !== 0) return comparison;

            }

            return 0; //equal each other
        });

    }

    static _evaluateExpression(a, b, property): number {
        // Must be formatted like "<property> <expression> <comparison>", ie "id === 0"
        const firstSpaceIndex: number = OrderByPipe._getPosition(property, ' ', 1);
        const secondSpaceIndex: number = OrderByPipe._getPosition(property, ' ', 2);
        const leftProperty: string = property.substring(0, firstSpaceIndex);
        const centerExpression: string = property.substring(firstSpaceIndex + 1, secondSpaceIndex);
        const rightComparison: string = property.substring(secondSpaceIndex + 1, property.length);

        if (OrderByPipe.debug) {
            console.log('\n_orderByComparator');
            console.log('property:\t\t\t', property);
            console.log('firstSpaceIndex:\t', firstSpaceIndex);
            console.log('secondSpaceIndex:\t', secondSpaceIndex);
            console.log('leftProperty:\t\t', leftProperty);
            console.log('centerExpression:\t', centerExpression);
            console.log('rightComparison:\t', rightComparison);
        }

        const valueA = a[leftProperty];
        const valueB = b[leftProperty];

        let matchA = false;
        let matchB = false;

        switch(centerExpression) {
            case '==':
                matchA = valueA == rightComparison;
                matchB = valueB == rightComparison;
                break;
            case '===':
                matchA = valueA === rightComparison;
                matchB = valueB === rightComparison;
                break;
            case '!=':
                matchA = valueA != rightComparison;
                matchB = valueB != rightComparison;
                break;
            case '!==':
                matchA = valueA !== rightComparison;
                matchB = valueB !== rightComparison;
                break;
            case '>':
                matchA = valueA > rightComparison;
                matchB = valueB > rightComparison;
                break;
            case '>=':
                matchA = valueA >= rightComparison;
                matchB = valueB >= rightComparison;
                break;
            case '<':
                matchA = valueA < rightComparison;
                matchB = valueB < rightComparison;
                break;
            case '<=':
                matchA = valueA <= rightComparison;
                matchB = valueB <= rightComparison;
                break;
        }

        if (OrderByPipe.debug) {
            console.log('valueA:\t\t\t\t', valueA);
            console.log('valueB:\t\t\t\t', valueB);
            console.log('matchA:\t\t\t\t', matchA);
            console.log('matchB:\t\t\t\t', matchB);
        }

        if (matchA) {
            return -1;
        } else if (matchB) {
            return 1;
        }
        return 0;
    }

    static _getPosition(string: string, pattern: string, index: number): number {
        const length = string.length;
        let i = -1;
        while(index-- && i++ < length){
            i = string.indexOf(pattern, i);
            if (i < 0) break;
        }
        return i;
    }
}

export const ORDERBY_PROVIDERS = [
    OrderByPipe
];
