import { IPromise } from 'angular';
import FdxError from '../models/FdxError';

export default class AuthorizationDataService {
	static $inject = ['$http', '$q'];

	constructor(
        private $http: any,
        private $q: any
    ) { }

	public generateAuthUrl(idDatabase: number, params: Record<string, any>): IPromise<string> {
		const def = this.$q.defer();

		const promise = this.$http.get(`/api.php/dbs/${idDatabase}/generate_authorization_url`, {
            'params': params
        });

		promise.then(
			(response: any) => {
				def.resolve(response.data);
			},
			(response: any) => {
				if (response.data === null) {
					def.reject(new FdxError('UNKNOWN_ERROR', 'Error getting auth url'));
					return;
				}

				def.reject(new FdxError('BAD_RESPONSE', response.data));
			}
		);

		return def.promise;
	}

	public getMiraklCredentials(idDatabase: number): Promise<Record<string, string>> {
		const def = this.$q.defer();

		const promise = this.$http.get(`/api.php/dbs/${idDatabase}/mirakl_credentials`);

		promise.then(
			(response: any) => {
				def.resolve(response.data.data);
			},
			(response: any) => {
				if (response.data === null) {
					def.reject(new FdxError('UNKNOWN_ERROR', 'Error getting mirakl credentials'));
					return;
				}

				def.reject(new FdxError('BAD_RESPONSE', response.data.message));
			}
		);

		return def.promise;
	}

	public getEbayCredentials(idDatabase: number): Promise<Record<string, string>> {
		const def = this.$q.defer();

		const promise = this.$http.get(`/api.php/dbs/${idDatabase}/ebay_credentials`);

		promise.then(
			(response: any) => {
				def.resolve(response.data);
			},
			(response: any) => {
				if (response.data === null) {
					def.reject(new FdxError('UNKNOWN_ERROR', 'Error getting ebay credentials'));
					return;
				}

				def.reject(new FdxError('BAD_RESPONSE', response.data.message));
			}
		);

		return def.promise;
	}

	public getEbayMipCredentials(idDatabase: number): Promise<Record<string, string>> {
		const def = this.$q.defer();

		const promise = this.$http.get(`/api.php/dbs/${idDatabase}/ebaymip_credentials`);

		promise.then(
			(response: any) => {
				def.resolve(response.data);
			},
			(response: any) => {
				if (response.data === null) {
					def.reject(new FdxError('UNKNOWN_ERROR', 'Error getting ebaymip credentials'));
					return;
				}

				def.reject(new FdxError('BAD_RESPONSE', response.data.message));
			}
		);

		return def.promise;
	}
}
