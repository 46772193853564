
export default class FdxMapFieldFaqModalController {

	// from uibmodal
	private close: (result?: { $value }) => void;
	private dismiss: (result?: { $value: string }) => void;

	static $inject = [];

	constructor() { }

	public onCancel(): void {
		this.dismiss();
	}
}
