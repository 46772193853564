import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BootstrapThemeProp } from '@app/core/services/bootstrap-theme.service';
import { Modal } from '@app/modules/modals/modals.module';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

interface ResolverInterface {
    derivedChannelDestination: string;
}

@Component({
    selector: 'exports-notification-modal',
    templateUrl: './exports-notification-modal.component.html',
    styleUrls: ['./exports-notification-modal.component.scss']
})
export class ExportsNotificationModalComponent implements Modal {
    public modalTheme: BootstrapThemeProp = 'primary';

    public iconEnvelope = faEnvelope;
    resolve: ResolverInterface;

    public notificationAddressForm: UntypedFormGroup = new UntypedFormGroup({
        notificationAddress: new UntypedFormControl('')
    });

    get derivedChannelDestination(): string {
        return this.resolve.derivedChannelDestination;
    }

    constructor(
        private modal: NgbActiveModal,
    ) { }

    public onModalSubmit(event: MouseEvent): void {
        if (!this.notificationAddressForm.get('notificationAddress')?.value) {
            return;
        }
        this.modal.close(this.notificationAddressForm.get('notificationAddress').value);
    }

    public onModalCancel(event: MouseEvent): void {
        this.modal.dismiss();
    }
}
