import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BaseComponent } from '@app/core-legacy/abstract/base.component';
import { ExistingExport } from '@app/exports/models/interfaces/existing-export.interface';
import { takeUntil } from 'rxjs';

@Component({
    template: ''
})
export abstract class BaseExportFormComponent extends BaseComponent implements OnChanges, OnInit {
    @Input() exportItem: ExistingExport;
    @Output() change = new EventEmitter<Record<string, any>>();
    @Output() formReady = new EventEmitter<UntypedFormGroup>();

    form: UntypedFormGroup;

    ngOnChanges({ exportItem }: SimpleChanges): void {
        const didExportItemChange = exportItem?.currentValue && exportItem.currentValue !== exportItem.previousValue;

        if (didExportItemChange) {
            this.patchForm(exportItem.currentValue);
        }
    }

    ngOnInit(): void {
        this.initForm();
        this.afterFormInit();
    }

    protected afterFormInit(): void {
        this.form.valueChanges.pipe(takeUntil(this.unsubscribe$))
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((changes) => {
                this.change.emit(changes);
            });

        this.formReady.emit(this.form);
    }

    protected patchValue(value: { [key: string]: any; }): void {
        if (value.deduplicateFieldName && this.form?.controls.deduplicateFieldName !== undefined) {
            const newArray = value.deduplicateFieldName;
            delete value.deduplicateFieldName;

            this.form?.setControl('deduplicateFieldName', newArray, {emitEvent: false});
        }

        // emitEvent is false for now to prevent valueChanges emitting every time exportItem changes
        // not doing so creates an issue where changing the export in the existing export dropdown does not update
        // all fields
        this.form?.patchValue(value, { emitEvent: false });
    }

    protected abstract initForm(): void;
    protected abstract patchForm(exportItem: ExistingExport): void;
}
