import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BaseComponent } from '@app/core-legacy/abstract/base.component';
import { CheatSheetOffcanvasComponent } from '@app/modules/cheat-sheet/components/cheat-sheet-offcanvas/cheat-sheet-offcanvas.component';
import { CodeMirrorOptionsModel } from '@app/modules/inputs/models/code-mirror-options.model';
import { AggregatedDbFieldsType } from '@app/modules/inputs/types/aggregated-db-fields.type';
import { OffcanvasService } from '@app/modules/offcanvas/services/offcanvas.service';
import { environment } from '@environments/environment';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { takeUntil } from 'rxjs';

@Component({
    selector: 'fdx-advanced-query',
    templateUrl: './advanced-query.component.html',
    styleUrls: ['./advanced-query.component.scss']
})
export class AdvancedQueryComponent extends BaseComponent implements OnInit, OnChanges {

    @Input() queryForm: UntypedFormGroup;
    @Input() controlName?: string = 'advancedQueryControl';
    @Input() id?: string = this.controlName;

    @Input() queryString: string = '';
    @Output() readonly queryStringChange: EventEmitter<string> = new EventEmitter<string>();

    @Input() disabled?: boolean = false;

    @Input() dbFields: AggregatedDbFieldsType;
    @Input() codeMirrorOptions: CodeMirrorOptionsModel;

    @Input() labelText?: string = 'Query input';

    @Input() doubleMinHeight?: boolean = false;

    @Input() cheatSheet?: boolean = false;

    @Input() leadingIcon?: IconDefinition;
    @Input() overflowScroll: boolean = false;

    readonly helpIcon: IconDefinition = faQuestionCircle;

    deactivateCodeMirror: boolean = environment.deactivateCodeMirror;

    get advancedQuery(): UntypedFormControl {
        return this.queryForm.controls[this.controlName] as UntypedFormControl;
    }

    get isReadOnly(): boolean {
        return Boolean(this.codeMirrorOptions.readOnly);
    }

    constructor(
        private readonly offcanvasService: OffcanvasService
    ) {
        super();
    }

    ngOnInit(): void {
        this.advancedQuery.valueChanges
            .pipe(
                takeUntil(this.unsubscribe$)
            )
            .subscribe(
                {
                    next: (value: string) => {
                        this.queryString = value;
                        this.queryStringChange.emit(this.queryString);
                    }
                }
            );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.queryRunning && this.advancedQuery) {
            if (this.disabled) {
                this.advancedQuery.disable();
            } else {
                this.advancedQuery.enable();
            }
        }

        // Special code to handle updating initial value load, queryString changing (being cleared) from outside control, and firing emitter only once
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (changes.queryString && changes.queryString.currentValue !== this.queryForm.value[this.controlName]) {
            this.queryForm.patchValue(
                {
                    [this.controlName]: this.queryString
                }
            );
        }
    }

    showCheatSheet(): void {
        this.offcanvasService.open(
            CheatSheetOffcanvasComponent,
            {
                position: 'end'
            }
        );
    }
}
