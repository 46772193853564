import { Component } from '@angular/core';
import { LogType } from '@app/logs/models/enums/log-type.enum';

@Component({
    selector: 'fdx-logs-change',
    templateUrl: './logs-change.component.html',
    styleUrls: ['./logs-change.component.scss']
})
export class LogsChangeComponent {
    readonly logType: LogType = LogType.Change;
}
