import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BulkTransformersService {
    constructor(
        private http: HttpClient
    ) { }

    addCsvBulkTransformer(databaseId: string, csv: File, options: { delimiter: string; enclosure: string; escape: string; }): Observable<any> {
        return this.http.post(`/api.php/dbs/${databaseId}/bulk_transformers`, this.buildFileFormData(csv, options));
    }

    addRawBulkTransformer(databaseId: string, text: string): Observable<any> {
        return this.http.post(`/api.php/dbs/${databaseId}/bulk_transformers`, {
            bulk_transformers: text
        });
    }

    private buildFileFormData(file: File, data: Record<string, any>): FormData {
        const formData = new FormData();

        formData.append('file', file);

        Object.entries(data).forEach(([key, val]) => {
            formData.append(key, val);
        });

        return formData;
    }
}