import { Component, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';

/**
 * @deprecated Make all components handle their own unsubscribe$ subjects
 * https://feedonomics.atlassian.net/browse/FP-9543
 */
@Component({
    selector: 'fdx-base-component',
    template: ''
})
export abstract class BaseComponent implements OnDestroy {
    protected readonly unsubscribe$: Subject<void> = new Subject<void>();

    subscriptionHandler<T>(observable: BehaviorSubject<T> | Observable<T>): Observable<T> {
        return observable.pipe(
            takeUntil(this.unsubscribe$)
        );
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
