/**
 * @deprecated Use `this.errorService.handleError()` instead
 */
export default class FdxError extends Error {
    public key: string = null;
    public description: string = null;

    constructor(key: string, description: string) {
        super(description);

        if (key) {
            this.key = key;
        }

        if (description) {
            this.description = description;
        }
    }
}

export { FdxError };
