import { AppStateService } from '@ajs/services/AppStateService';
import { fdxUI as FdxUI } from '@ajs/services/fdxUI';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AppMenuTab } from '@app/core/models/enums/app-menu-tab.enum';
import { WINDOW } from '@app/core/providers/window.provider';
import { BootstrapThemeProp } from '@app/core/services/bootstrap-theme.service';
import { ErrorOverviewDownloadModalComponent } from '@app/error-overview/components/error-overview-download-modal/error-overview-download-modal.component';
import { ErrorTypeFDX } from '@app/error-resolution/enums/fdx-error-code.enum';
import { ErrorResolutionDataService } from '@app/error-resolution/services/error-resolution.service';
import { ChannelsResponse, ErrorCode, ErrorCodesResponse } from '@app/error-resolution/services/responses/error-resolution.response';
import { ModalService } from '@app/modules/modals/services/modal.service';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'fdx-error-overview-page',
    templateUrl: './error-overview-page.component.html',
    styleUrls: ['./error-overview-page.component.scss']
})
export class ErrorOverviewPageComponent implements OnDestroy, OnInit {
    private readonly unsubscribe$: Subject<void> = new Subject<void>();

    public static readonly DEFUALT_START_PAGE: number = 1;
    public static readonly DEFUALT_END_PAGE: number = 20;
    public static readonly DEFUALT_PAGE_SIZE: number = 20;

    protected appMenuTab: AppMenuTab = AppMenuTab.Data;
    protected title: string = 'Error overview';

    search: string = '';

    readonly iconDownload: IconDefinition = faDownload;

    list: ErrorCode[] = [];
    allDataList: ErrorCode[] = [];
    filteredLists: ErrorCode[] = [];
    channels: string[] = [];

    loading: boolean = true;
    initialized: boolean = false;
    errorLoading: boolean = true;

    form: UntypedFormGroup;

    startRecord: number = ErrorOverviewPageComponent.DEFUALT_START_PAGE;
    endRecord: number = ErrorOverviewPageComponent.DEFUALT_END_PAGE;
    pageSize: number = ErrorOverviewPageComponent.DEFUALT_PAGE_SIZE;

    databaseId: string;

    constructor(
        private readonly appStateService: AppStateService,
        private readonly errorResolutionDataService: ErrorResolutionDataService,
        @Inject(WINDOW) private readonly window: Window,
        private readonly router: Router,
        private readonly fb: UntypedFormBuilder,
        private readonly modalService: ModalService,
        private readonly fdxUI: FdxUI
    ) {

    }

    ngOnInit(): void {

        this.fdxUI.setTitle(this.title);
        this.fdxUI.setActiveTab(this.appMenuTab);

        this.databaseId = this.appStateService.getDatabaseId();
        this.getChannels();
        this.initForm();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    initForm(): void {
        this.form = this.fb.group({
            channel: ['']
        });
    }

    getErrorCodes(channelName: string): void {
        this.initialized = true;
        this.errorResolutionDataService
            .getErrorCodes(this.databaseId, channelName)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (errorCodes: ErrorCodesResponse) => {
                    this.allDataList = (errorCodes.data || [])
                        .sort((a: ErrorCode, b: ErrorCode) => {
                            if (a.error_type_fdx !== b.error_type_fdx) {
                                return a.error_type_fdx.localeCompare(b.error_type_fdx);
                            }
                            return b.count - a.count;
                        });
                    this.list = [...this.allDataList];
                    this.filteredLists = [...this.allDataList];
                },
                complete: () => {
                    this.initialized = false;
                }
            });
    }

    getChannels(): void {
        this.loading = true;
        this.errorResolutionDataService
            .getChannels(this.databaseId)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (channels: ChannelsResponse) => {
                    this.channels = channels.data;
                    if (this.channels.length > 0) {
                        const channel = this.channels[0]; // default selection
                        this.form.setValue({ channel: channel });
                        this.getErrorCodes(channel);
                    }
                },
                complete: () => {
                    this.loading = false;
                }
            });
    }

    onSearch(searchText: string): void {
        this.resetPagination();
        this.allDataList = this.list.filter((item) => {
            if (!searchText.replace(/\s/g, '').length) {
                return true;
            }
            const isMessageSame = item.error_message
                .toLowerCase()
                .includes(searchText.toLowerCase());

            const isCodeSame = item.error_code
                .toString()
                .toLowerCase()
                .includes(searchText.toLowerCase());

            return isMessageSame || isCodeSame;
        });

        this.filteredLists = this.allDataList.slice(
            this.startRecord - 1,
            this.endRecord
        );
    }

    onPageChange(page: { startRecord: number, endRecord: number; }): void {
        this.startRecord = page.startRecord;
        this.endRecord = page.endRecord;
        this.filteredLists = this.allDataList.slice(
            this.startRecord - 1,
            this.endRecord
        );
        this.window.scrollTo(0, 0);
    }

    onDownload(): void {
        const fieldMappings = (fields: ErrorCode): (string | number)[] =>
            [fields.count, fields.error_code, fields.error_message, fields.error_type_fdx, fields.error_type_mp, fields.query];
        const { channel } = this.form.value;
        this.modalService.open(ErrorOverviewDownloadModalComponent, {
            resolve: {
                dataParam: {
                    channel: channel
                },
                filteredData: this.filteredLists.map(fieldMappings),
                allData: this.list.map(fieldMappings),
                header: ['count', 'error_code', 'error_message', 'error_type_fdx', 'error_type_mp', 'query']
            }
        });
    }

    resetPagination(): void {
        this.startRecord = ErrorOverviewPageComponent.DEFUALT_START_PAGE;
        this.endRecord = ErrorOverviewPageComponent.DEFUALT_END_PAGE;
        this.pageSize = ErrorOverviewPageComponent.DEFUALT_PAGE_SIZE;
    }

    onChannelChanged(channel: string): void {
        this.getErrorCodes(channel);
        this.resetPagination();
    }

    goToErrorResolution(errorCode: string): void {

        const { channel } = this.form.value;

        void this.router.navigate(
            [
                '/',
                this.databaseId,
                'error-resolution'
            ],
            {
                queryParams: {
                    query: errorCode,
                    channel: channel
                }
            }
        );

    }

    goToAddExport(): void {

        void this.router.navigate(
            [
                '/',
                this.databaseId,
                'exports'
            ]
        );

    }

    trackByIndex(index: number): number {
        return index;
    }

    getTheme(item: ErrorCode): BootstrapThemeProp {
        if (item.error_type_fdx === ErrorTypeFDX.Warning) {
            return 'warning';
        }
        return 'danger';
    }
}
