<div class="container">
    <fdx-page-title>FeedAI™ - Automatically Categorize Your Products</fdx-page-title>

    <ul ngbNav #nav="ngbNav" class="nav-tabs">
        <li [ngbNavItem]="feedAiTabs.Categorization">
            <a ngbNavLink>Categorization</a>
            <ng-template ngbNavContent>
                <fdx-feed-ai-classification-tab
                    [databaseFields]="databaseFields"
                    [databaseId]="databaseId">
                </fdx-feed-ai-classification-tab>
            </ng-template>
        </li>
        <li [ngbNavItem]="feedAiTabs.Attribution">
            <a ngbNavLink>Attribute Extraction</a>
            <ng-template ngbNavContent>
                <fdx-feed-ai-attribution-tab
                    [databaseFields]="databaseFields"
                    [databaseId]="databaseId">
                </fdx-feed-ai-attribution-tab>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-3"></div>
</div>
