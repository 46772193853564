import * as angular from 'angular';
import { MODULE_NAME } from '../config/constants';

import EscapeHtmlFilter from './escapeHtml.filter';
import FileSizeFilter from './filesize.filter.js';
import FormatSecondsFilter from './formatSeconds.filter';
import UniqueFilter from './unique.filter.js';

angular.module(MODULE_NAME)
    .filter('escapeHtml', EscapeHtmlFilter)
    .filter('filesize', FileSizeFilter)
    .filter('formatSeconds', FormatSecondsFilter)
    .filter('unique', UniqueFilter);
